import React, { Component, Suspense } from "react";

import * as ReactDOM from "react-dom";
import { Link, Redirect } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import "bootstrap/dist/css/bootstrap.css";
import "../css/style.css";
import "../css/purpleresponsive.css";
import phone from "../image/theme/phoneg.png";
import video from "../image/theme/videog.png";
import chat from "../image/theme/chatg.png";
import booking from "../image/theme/inperson.png";
import $ from "jquery";

import { apiCalling } from "../apiService";
var favorateArray = new Array();
export default class DoctorListElastic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callADoctor: "",
      bookApp: "",
      messaging: "",
      emailConsult: "",
      vcChat: "",
      drServiceList: [],
      loginValidateFlag: 0,
      checkboxflag: "",
    };
    window.sessionStorage.setItem("AppFlag", "NA");
  }
  isLoginCheck() {
    let flag = true;
    if (!window.sessionStorage.getItem("isLogin")) {
      $("#ForgotPasswordWrap").hide();
      $("#SignupWrap").hide();
      $("#signInWrapDiv").show();
      flag = false;
    }
    return flag;
  }
  addToFavourites(e, docId) {
    if (e.target.checked) {
      favorateArray.push(docId);
    } else {
      let index = favorateArray.indexOf(docId);
      favorateArray.splice(index, 1);
    }
    if (favorateArray.length > 0) {
      if (this.isLoginCheck() === true) {
        const jsonData = {
          method: "POST",
          body: JSON.stringify({
            functionName: "addToFavourites",
            siteId: window.sessionStorage.getItem("siteId"),
            userId: window.sessionStorage.getItem("userId"),
            favouriteUserId: docId,
            removeFavourites: e.target.checked,
            favouriteUserDetails: favorateArray.toString().replaceAll(",", "|"),
          }),
        };
        // console.log(jsonData.body);
        apiCalling(jsonData).then((data) => {
          if (data.success === "1") {
            if (data.successMessage === "Added To Favourites") {
              $("#addedToFav" + docId)
                .fadeIn(200)
                .attr("hidden", false);
              $("#addedToFav" + docId).fadeOut(2000);
            } else {
              $("#removedFromFav" + docId)
                .fadeIn(200)
                .attr("hidden", false);
              $("#removedFromFav" + docId).fadeOut(2000);
            }
          }
        });
      }
    } else {
      alert("All Favourites Removed");
      // $("#removedFromFav" + docId)
      //   .fadeIn(700)
      //   .attr("hidden", false);
      // // setTimeout(2000)
      // $("#removedFromFav" + docId).fadeOut(2000);
    }
  }
  componentDidMount() {
    // var isFavourite =
    //   this.props.favouriteDoctorsList.filter(function (id) {
    //     return id.doctorId === this.props.docData.section_id;
    //   }).length > 0;
    //   console.log("isFavourite",isFavourite)
    /* if (this.props.docData.services) {
            const obj = this.props.docData.services;

            if (obj.includes("Messaging")) {
                $("#chatDr" + this.props.consti).show();
            } else {
                $("#chatDr" + this.props.consti).hide();
            }

            if (obj.includes("Call a Doctor")) {
                $("#callDr" + this.props.consti).show();
            } else {
                $("#callDr" + this.props.consti).hide();
            }
            if (obj.includes("Video Chat")) {
                $("#vcDr" + this.props.consti).show();
            } else {
                $("#vcDr" + this.props.consti).hide();
            }
            if (obj.includes("Email Consult")) {
                $("#emailDr" + this.props.consti).show();
            } else {
                $("#emailDr" + this.props.consti).hide();
            }
            if (obj.includes("Book Appointment")) {
                $("#bookDr" + this.props.consti).show();
            } else {
                $("#bookDr" + this.props.consti).hide();
            }
        }*/
    if (this.props.docData.isLogged === "1") {
      $("#isLogged" + this.props.docData.section_id).show();
    } else {
      $("#isLogged" + this.props.docData.section_id).hide();
    }
    if(this.props.favouriteDoctorsList){
      for(let i=0;i<this.props.favouriteDoctorsList.length;i++){
        if(this.props.docData.section_id === this.props.favouriteDoctorsList[i].doctorId.toString()){
          $("#fav"+this.props.docData.section_id).attr("checked",true)
          break;
        }
      }
    }
  }

  textchatpaymentClick(doctorId, hospitalId, chatDoctorPanelId, textChatPrice) {
    let LazyPage;
    // LazyPage = React.lazy(() => import("../patient/textchatpayment"));

    ReactDOM.render(
      <div>
        <Suspense fallback={<div></div>}>
          <section>
            <LazyPage
              doctorId={doctorId}
              hospitalId={hospitalId}
              chatDoctorPanelId={chatDoctorPanelId}
              textChatPrice={textChatPrice}
            />
          </section>
        </Suspense>
      </div>,
      document.getElementById("lazyHomeBody")
    );
  }
  chatmessageClickValidate(
    doctorId,
    hospitalId,
    chatDoctorPanelId,
    textChatPrice
  ) {
    $(".loader").show();
    const jsonData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "messageChatFree",
        chatDoctorPanelId: chatDoctorPanelId,
        memberId: window.sessionStorage.getItem("userId"),
        doctorId: doctorId,
        hospitalId: hospitalId,
      }),
    };
    // console.log(jsonData.body);
    apiCalling(jsonData).then((data) => {
      if (data.success === "1") {
        window.sessionStorage.setItem("API_KEY", data.API_KEY);
        window.sessionStorage.setItem("sessionId", data.sessionId);
        window.sessionStorage.setItem("token", data.token);
        window.sessionStorage.setItem("appDocId", doctorId);
        window.sessionStorage.setItem("appHosId", hospitalId);
        window.sessionStorage.setItem("chatDoctorPanelId", data.chatGroupId); //chatDoctorPanelId);
        window.location.href = "/chat/chatmessage";
      } else {
        this.chatmessageClickValidate(
          doctorId,
          hospitalId,
          chatDoctorPanelId,
          textChatPrice
        );
        //console.log("Error: API : messageChatFree");
      }
    });
  }
  chatmessageClick(doctorId, hospitalId, chatDoctorPanelId, textChatPrice) {
    if (this.isLoginCheck() === true) {
      this.chatmessageClickValidate(
        doctorId,
        hospitalId,
        chatDoctorPanelId,
        textChatPrice
      );
    } else {
      window.sessionStorage.setItem("loginValidate", "TextChatFree");
      window.sessionStorage.setItem("appDocId", doctorId);
      window.sessionStorage.setItem("appHosId", hospitalId);
      window.sessionStorage.setItem("chatDoctorPanelId", chatDoctorPanelId);
      $("#ForgotPasswordWrap").hide();
      $("#SignupWrap").hide();
      $("#signInWrapDiv").show();
    }
  }
  isLoginValidate(doctorId, hospitalId, flagOption, bySlot) {
    if (this.isLoginCheck() === true) {
      window.sessionStorage.setItem("loginValidate", "");
      window.sessionStorage.setItem("appDocId", doctorId);
      window.sessionStorage.setItem("appHosId", hospitalId);
      window.sessionStorage.setItem("flagOption", flagOption);
      window.sessionStorage.setItem("bySlot", bySlot);
      window.location.href = "/patient/bookappointment";
    } else {
      window.sessionStorage.setItem("loginValidate", "book");
      window.sessionStorage.setItem("appDocId", doctorId);
      window.sessionStorage.setItem("appHosId", hospitalId);
      window.sessionStorage.setItem("flagOption", flagOption);
      window.sessionStorage.setItem("bySlot", bySlot);
      $("#ForgotPasswordWrap").hide();
      $("#SignupWrap").hide();
      $("#signInWrapDiv").show();
    }
  }
  isLoginValidateTextChatWithPayment(
    doctorId,
    hospitalId,
    chatdoctorpanelid,
    messaging_cost
  ) {
    if (this.isLoginCheck() === true) {
      window.sessionStorage.setItem("loginValidate", "");
      // alert(doctorId);
      // alert(hospitalId);
      // alert(chatdoctorpanelid);
      // alert(messaging_cost);
      window.sessionStorage.setItem("appDocId", doctorId);
      window.sessionStorage.setItem("appHosId", hospitalId);
      window.sessionStorage.setItem("chatDoctorPanelId", chatdoctorpanelid);
      window.sessionStorage.setItem("messaging_cost", messaging_cost);
      window.location.href = "/patient/textchatpayment";
    } else {
      window.sessionStorage.setItem("loginValidate", "TextChatPay");
      window.sessionStorage.setItem("appDocId", doctorId);
      window.sessionStorage.setItem("appHosId", hospitalId);
      window.sessionStorage.setItem("chatDoctorPanelId", chatdoctorpanelid);
      window.sessionStorage.setItem("messaging_cost", messaging_cost);
      $("#ForgotPasswordWrap").hide();
      $("#SignupWrap").hide();
      $("#signInWrapDiv").show();
    }
  }
  render() {
    //console.log('this.props.docData.ishosonline........',this.props.docData.ishosonline)
    //console.log('this.props.doctorServices........',this.props.doctorServices)
    return (
      <div className="doctorDetailProfile doctorListProfile doctor-topheart">
        <div className="doctorDetailLeft">
          <label className="drListHeart imgshowhide">
            <input
              id={"fav"+this.props.docData.section_id}
              type="checkbox"
              onClick={(e) =>
                this.addToFavourites(e, this.props.docData.section_id)
              }
            />

            <span class="checkmark"></span>
            <span
              id={"addedToFav" + this.props.docData.section_id}
              className="added-fav-text"
              hidden
            >
              Added to Favourites
            </span>
            <span
              id={"removedFromFav" + this.props.docData.section_id}
              className="remove-fav-text"
              hidden
            >
              Removed from Favourites
            </span>
          </label>
          <Link
            to={{
              pathname: "/Doctor-" + this.props.docData.slug,
              doctorId: this.props.docData.section_id,
              hospitalId: this.props.docData.hospital_id,
              doctorList:this.props.favouriteDoctorsList
            }}
          >
            <div className="doctorDetailProfileImg">
              <img
                src={this.props.docData.section_image_with_path}
                width="110"
                height="110"
                alt=""
              ></img>
            </div>
            <div class="drName">
              <a data-tip data-for={"drName" + this.props.consti}>
                {this.props.docData.section_name}
                <ReactTooltip
                  id={"drName" + this.props.consti}
                  className="presctooltip"
                  effect="float"
                  type="success"
                  textColor="black"
                >
                  {this.props.docData.section_name}
                </ReactTooltip>
              </a>
            </div>
          </Link>
          <span class="drspecialist text-truncate">
            <a data-tip data-for={"speciality" + this.props.consti}>
              {this.props.docData.speciality}
              <ReactTooltip
                id={"speciality" + this.props.consti}
                className="presctooltip"
                effect="float"
                type="success"
                textColor="black"
              >
                {this.props.docData.speciality}
              </ReactTooltip>
            </a>
          </span>
          <p className="drspecialist-text mb-0 text-truncate">
            <a data-tip data-for={"hospitalname" + this.props.consti}>
              <strong>{this.props.docData.hospitalname}</strong>
              <ReactTooltip
                id={"hospitalname" + this.props.consti}
                className="presctooltip"
                effect="float"
                type="success"
                textColor="black"
              >
                {this.props.docData.hospitalname}
              </ReactTooltip>
            </a>
          </p>
          <p class="drspecialist-text mb-0 mt-2 text-truncate-2line">
            <a data-tip data-for={"address_city" + this.props.consti}>
              {this.props.docData.haddress}, {this.props.docData.city}
              <ReactTooltip
                id={"address_city" + this.props.consti}
                className="presctooltip"
                effect="float"
                type="success"
                textColor="black"
              >
                {this.props.docData.haddress}, {this.props.docData.city}
              </ReactTooltip>
            </a>
          </p>
        </div>
        <div className="drServiceMain" id={"drServiceMain" + this.props.consti}>
          <div className="drService-subhead">SERVICES:</div>

          {this.props.docData.ishosonline === "1" &&
            this.props.doctorServices.toString().includes("4") ? (
            <div
              className="drServiceSp drServiceSp-book"
              id={"bookDr" + this.props.consti}
            >
              <span
                className="text-decoration-none drServiceSpSpan"
                onClick={(e) => {
                  this.isLoginValidate(
                    this.props.docData.section_id,
                    this.props.docData.hospital_id,
                    "p",
                    "No"
                  );
                }}
              >
                <img src={booking} alt=""></img> In-Person
                <span className="drService-rate">
                  {parseFloat(this.props.docData.consultation_price).toFixed(
                    2
                  ) !== "0.00"
                    ? "₹" +
                    parseFloat(this.props.docData.consultation_price).toFixed(
                      2
                    )
                    : "NA"}
                </span>
              </span>
            </div>
          ) : (
            ""
          )}

          {this.props.docData.is_video_enabled === "1" &&
            this.props.doctorServices.toString().includes("1") ? (
            <div
              className="drServiceSp drServiceSp-videochat"
              id={"vcDr" + this.props.consti}
            >
              <span
                onClick={(e) => {
                  this.isLoginValidate(
                    this.props.docData.section_id,
                    this.props.docData.hospital_id,
                    "v",
                    "No"
                  );
                }}
                className="text-decoration-none drServiceSpSpan"
              >
                <img src={video} alt=""></img> Video Chat
                <span className="drService-rate">
                  {parseFloat(this.props.docData.rate_per_session).toFixed(
                    2
                  ) !== "0.00"
                    ? "₹ " +
                    parseFloat(this.props.docData.rate_per_session).toFixed(2)
                    : "Free"}
                </span>
              </span>
            </div>
          ) : (
            ""
          )}

          {this.props.docData.is_call_enabled === "1" &&
            this.props.doctorServices.toString().includes("5") ? (
            <div
              className="drServiceSp drServiceSp-call"
              id={"callDr" + this.props.consti}
            >
              <span
                className="text-decoration-none drServiceSpSpan"
                onClick={(e) => {
                  this.isLoginValidate(
                    this.props.docData.section_id,
                    this.props.docData.hospital_id,
                    "c",
                    "No"
                  );
                }}
              >
                <img src={phone} alt=""></img> Audio Chat
                <span className="drService-rate">
                  {parseFloat(this.props.docData.phone_consult_price).toFixed(
                    2
                  ) != "0.00"
                    ? "₹ " +
                    parseFloat(
                      this.props.docData.phone_consult_price
                    ).toFixed(2)
                    : "Free"}
                </span>
              </span>
            </div>
          ) : (
            ""
          )}

          {this.props.docData.is_chat_enabled === "1" &&
            this.props.doctorServices.toString().includes("2") ? (
            <div
              className="drServiceSp drServiceSp-chat"
              id={"chatDr" + this.props.consti}
            >
              {this.props.docData.messaging_cost > 0 ? (
                <span
                  onClick={(e) => {
                    this.isLoginValidateTextChatWithPayment(
                      this.props.docData.section_id,
                      this.props.docData.hospital_id,
                      this.props.docData.chatdoctorpanelid,
                      this.props.docData.messaging_cost
                    );
                  }}
                  className="text-decoration-none drServiceSpSpan"
                >
                  <img src={chat} alt="" />
                  Text Chat
                  <span className="drService-rate">
                    {parseFloat(this.props.docData.messaging_cost).toFixed(2) !=
                      "0.00"
                      ? "₹ " +
                      parseFloat(this.props.docData.messaging_cost).toFixed(2)
                      : "Free"}
                  </span>
                  <span className="expireDay">
                    {" "}
                    {this.props.docData.daysofexpiry != null &&
                      " Days of Expiry : " + this.props.docData.daysofexpiry}
                  </span>
                </span>
              ) : (
                <span
                  onClick={() =>
                    this.chatmessageClick(
                      this.props.docData.section_id,
                      this.props.docData.hospital_id,
                      this.props.docData.chatdoctorpanelid,
                      this.props.docData.messaging_cost
                    )
                  }
                  className="text-decoration-none drServiceSpSpan"
                >
                  <img src={chat} alt=""></img> Text Chat
                  <span className="drService-rate">
                    {parseFloat(this.props.docData.messaging_cost).toFixed(2) !=
                      "0.00"
                      ? "₹ " +
                      parseFloat(this.props.docData.messaging_cost).toFixed(2)
                      : "Free"}
                  </span>
                  <span className="expireDay">
                    {this.props.docData.daysofexpiry != null &&
                      " Days of Expiry : " + this.props.docData.daysofexpiry}
                  </span>
                </span>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
