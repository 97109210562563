import React, { Component, Suspense } from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Dashboardleftmenu from "./dashboardleftmenu";
import { apiCalling } from "../apiService";
import $ from "jquery";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class CouponList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 15
    };
  }
 
  handlePageChange(pageNumber) {
   // console.log(`active page is ${pageNumber}`);
    this.setState({activePage: pageNumber});
  }
  componentDidMount() {
    $("#changePassword").addClass("dashBoardLeftMenu-active");
  }
  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container-fluid">
          <div class="main-wrapper1 mt-0 pt-0 hspmain-wrapper-promo">
            <div class="row">
              <div class="col-lg-3 p-0">
                <Dashboardleftmenu></Dashboardleftmenu>
              </div>
              <div class="col-lg-9">
              <div className="dashboard-right dashbrd-rght-bottom-padng-hsp">
                  <div class="tabmain_list">
                    <a class="list_tab active">Coupons </a>
                    <a class="list_tab">
                        <span class="videoSpan">Policies</span> 
                      </a>
                    <a class="list_tab">Reports </a>
                  </div>
                  <Accordion allowZeroExpanded='true'>
                  <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Member Details
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                      <div class="row px-2">
                <div className="col-md-12">
                  <Link to="/" href="#">Go Back</Link>
                </div>
        <div class="col-sm-6">
          <div class="row my-2">
            <label class="col-sm-12 label-text px-0 px-sm-3">Coupon Name<span className="star-red">*</span></label>
            <div class="col-sm-12  px-0 px-sm-3">
              <input type="text" className="input-design"/>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row my-2">
            <label class="col-sm-12 label-text px-0 px-sm-3">RGI Share<span className="star-red">*</span></label>
            <div class="col-sm-12  px-0 px-sm-3 d-flex align-items-center">
              <input type="text" className="input-design mb-0 border_change"/>
              <div  className="custome_rgi d-flex">
              <label className="">
                <input type="radio" name="rgi_value"/>
                <span>₹</span>
              </label>
              <label className="">
                <input type="radio" name="rgi_value"/>
                <span>%</span>
              </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row my-2">
            <label class="col-sm-12 label-text px-0 px-sm-3">Created Date <span className="star-red">*</span></label>
            <div class="col-sm-12  px-0  px-sm-3">
              <input type="date" className="input-design"/>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row my-2">
            <label class="col-sm-12 label-text px-0 px-sm-3">Valid From <span className="star-red">*</span></label>
            <div class="col-sm-12  px-0  px-sm-3">
              <input type="date" className="input-design"/>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row my-2">
            <label class="col-sm-12 label-text px-0 px-sm-3">Expiry Date <span className="star-red">*</span></label>
            <div class="col-sm-12  px-0  px-sm-3">
              <input type="date" className="input-design"/>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class=" my-2 d-flex align-items-center">
            <label class=" label-text px-0 px-sm-3 mb-0 d-flex align-items-center">
              <input type="radio" name="radType"/><span className="ml-2 label-text">Single</span>
            </label>
            <label class=" label-text px-0 px-sm-3 mb-0 d-flex align-items-center">
              <input type="radio" name="radType" /><span className="ml-2 label-text"> Multiple</span>
            </label>
          </div>
        </div>
        <div class="col-sm-12">
          <input type="submit" className="formButton" value="Save"/>
          <input type="button" className="formButton" value="Cancel"/>
          </div>
         
      </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                    </Accordion>
                    <div className="col-sm-12">
              <div className="row align-items-center my-4">
                <div className="col-md-3 d-flex align-items-center">
                  <input type="text" className="input-design mb-0" placeholder="Coupon Name"/>
                </div>
                <div className="col-md-6 coupon_btn d-flex">
                <input type="submit" className="formButtonBg mr-1" value="Search"/>
                <input type="submit" className="formButtonBg mr-1" value="Refresh"/>
                <input type="submit" className="formButtonBg" value="Download History"/>
                </div>
                <div className="col-md-3"><p className="mb-0"><b>Total Coupon Count : 114</b></p></div>
              </div>
          </div>
          <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>Modify</th>
                                                    <th>Sr.No.</th>
                                                    <th> Version</th>
                                                    <th>RGI Share</th>
                                                    <th>Share Value </th>
                                                    <th>Created Date</th>
                                                    <th> Valid From Date </th>
                                                    <th >Expiry Date</th>
                                                    <th> Remarks</th>
                                                    <th>Status Change</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ fontSize: "12px" }}>
                                              <tr>
                                              <td> <Link to="/"><i class="fa fa-pencil text-primary"/></Link></td>
                                              <td>1</td>
                                              <td>1.0</td>
                                              <td>Amount</td>
                                              <td>₹ 10.00</td>
                                              <td>24-Jan-2022</td>
                                              <td>24-Jan-2022</td>
                                              <td>31-Jan-2022</td>
                                              <td>Single</td>
                                              <td>Active</td>
                                              <td><Link to="/"><i class="fa fa-times-circle-o text-danger"></i></Link></td>
                                              </tr>
                                              <tr>
                                              <td> <i class="fa fa-pencil text-primary"/></td>
                                              <td>1</td>
                                              <td>1.0</td>
                                              <td>Amount</td>
                                              <td>₹ 10.00</td>
                                              <td>24-Jan-2022</td>
                                              <td>24-Jan-2022</td>
                                              <td>31-Jan-2022</td>
                                              <td>Single</td>
                                              <td>Active</td>
                                              <td><i class="fa fa-times-circle-o text-danger"></i></td>
                                              </tr>
                                              </tbody>
                                            </table>
                                            <div className="text-right">
                                              <Pagination
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={10}
                                                totalItemsCount={40}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange.bind(this)}
                                              />
                                            </div>
                                            </div>
                  </div>
              </div>
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
