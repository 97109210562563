import React, { Component, Suspense } from "react";
import Dashboardleftmenu from "../GroupAdmin/dashboardleftmenu";
import { apiCalling } from "../../src/apiService";
const folderName = window.sessionStorage.getItem("homeName");

const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
var mapKey = ""
export default class home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //siteId: this.props.match.params.siteId,
    };
    this.changeTheme = this.changeTheme.bind(this);
    this.ChangeHeader = this.ChangeHeader.bind(this);
    this.ChangeReports = this.ChangeReports.bind(this);
  }

  componentDidMount() {
    this.getMapKey();
  }
  getMapKey(){
    const apiData = {
      method:"POST",
      body:JSON.stringify({
        functionName:"imagePath"
      })
    }
    apiCalling(apiData).then((data)=>{
      if(data.success==="1"){
      mapKey=data.configPathDetails[0].googleMapKey
      window.sessionStorage.setItem("googleMapKey", mapKey)
      }
    })
  }
  changeTheme = (e) => {
    window.location.href = "/home/themeEdit/0";
  };
  changeThemeColor = (e) => {
    window.location.href = "/themeColor";
  };

  ChangeHeader = (e) => {
    window.location.href = "/dashboard/HeaderLinks";
  };

  ChangeReports = (e) => {
    window.location.href =
      "/dashboard/reportDashboard/" +
      window.sessionStorage.getItem("groupHospitalAdminId");
  };

  promoCodeClick = (e) => {
    window.location.href = "/hospital/promocodeDetails";
  };

  ChangeIDABanner = (e) => {
    window.location.href = "/dashboard/BannerIDAListing";
  };
  HospitalOffsetPageNumberReset = () => {window.sessionStorage.setItem('hospitalpageNumber',1)
  window.sessionStorage.setItem('hospitaloffset',0)}
  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />

            <div
              className="container dashbord-grpadmin-wraper"
              id="row-col-width-leftmenu"
            >
                <div class="main-wrapper1  hspmain-wrapper-promo" id="row-col-width-leftmenu">
            <div class="row mx-0">
              <div class="col-lg-1  d-sm-none d-lg-block d-md-none d-xl-block d-none">
              {window.sessionStorage.getItem("role") === "ROLE_ADMIN" ? (""):(
                <Dashboardleftmenu></Dashboardleftmenu>
              )}
              </div>
              <div class="col-lg-11">
                <div className="sectionItemDiv sectionItemDivBorder grpadmindshbordTop">
                  {window.sessionStorage.getItem("role") === "ROLE_ADMIN" && (
                    <div className="">
                      {/* <span className="sectionTag" onClick={this.changeTheme}>
                        Theme
                      </span>
                      <span
                        className="sectionTag"
                        onClick={this.changeThemeColor}
                      >
                        Theme Color
                      </span>
                      <span className="sectionTag" onClick={this.ChangeHeader}>
                        Header / New Page
                      </span>
                      <span className="sectionTag" onClick={this.ChangeReports}>
                        Reports
                      </span>
                      <span
                        className="sectionTag"
                        onClick={this.ChangeIDABanner}
                      >
                        IDA Banner
                      </span> */}
                      <span
                        className="sectionTag"
                        onClick={(e) =>
                          (window.location.href = "/admin/dataMigration")
                        }
                      >
                        Data Migration
                      </span>
                   
                   
                      {/* <span
                        className="sectionTag"
                        onClick={this.promoCodeClick}
                      >
                        Promocode
                      </span> */}

                      <span
                        className="sectionTag"
                        onClick={() => {
                          window.location.href =
                            "/adminManagement/doctorManagementList/doctorManagement";
                        }}
                      >
                        Doctor Management
                      </span>

                      <span
                        className="sectionTag"
                        onClick={()=>{this.HospitalOffsetPageNumberReset(); 
                          window.location.href =
                          "/adminManagement/hospitalManagementList/hospitalManagementList"}}

                      >
                        Hospital Management
                      </span>

                      <span
                        className="sectionTag"
                        onClick={() => {
                          window.location.href =
                            "/adminManagement/smsSettings/sms-settings";
                        }}
                      >
                        SMS Settings
                      </span>

                      <span
                        className="sectionTag"
                        onClick={() => {
                          window.location.href =
                            "/adminManagement/siteSettings";
                        }}
                      >
                        Site Settings
                      </span>

                      <span
                        className="sectionTag"
                        onClick={() => {
                          window.location.href = "/adminManagement/messaging";
                        }}
                      >
                        Messaging
                      </span>

                      <span
                        className="sectionTag"
                        onClick={() => {
                          window.location.href =
                            "/adminDashboard/doctorWorkdashboard";
                        }}
                      >
                        Doctor Work
                      </span>

                      <span
                        className="sectionTag"
                        onClick={() => {
                          window.location.href =
                            "/adminManagement/medicineBranch";
                        }}
                      >
                        Medicine Branch
                      </span>

                      <span
                        className="sectionTag"
                        onClick={() => {
                          window.location.href =
                            "/adminDashboard/pharmaDashboard";
                        }}
                      >
                        Pharma Ad Module
                      </span>

                      <span
                        className="sectionTag"
                        onClick={() => {
                          window.location.href =
                            "/adminDashboard/mobileappdashboard";
                        }}
                      >
                        Mobile App Dashboard
                      </span>

                      <span
                        className="sectionTag"
                        onClick={() => {
                          window.location.href =
                            "/adminManagement/healthInformation";
                        }}
                      >
                        Health Information
                      </span>

                      <span
                        className="sectionTag"
                        onClick={() => {
                          window.location.href =
                            "/adminManagement/productDashboard";
                        }}
                      >
                        Product
                      </span>

                      <span
                        className="sectionTag"
                        onClick={() => {
                          window.location.href =
                            "/adminManagement/manageLocations";
                        }}
                      >
                        Manage Locations
                      </span>
                    </div>
                  )}
                </div>
              </div>
              </div>
              </div>
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
