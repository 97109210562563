import React, { Component } from 'react';
import Slider from "react-slick";
import $ from "jquery";
import { apiCalling, awsAPIUrlcommon } from "../apiService";
import moment from "moment";
export default class trustedBy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appointmentSlotDay:[],
            hospialId:this.props.hospialId,
            doctorId:this.props.doctorId
        };
      }

      componentDidMount(){
        
          this.getCalenderData();
      }
      
  isLoginCheck() {
    let flag = true;
    if (!window.sessionStorage.getItem("isLogin")) {
      $("#ForgotPasswordWrap").hide();
      $("#SignupWrap").hide();
      $("#signInWrapDiv").show();
      flag = false;
    }
    return flag;
  }
      moreItemLoad = (index) => {
        $("#"+this.state.hospialId+"_"+ index).addClass("timeListOpen");
      };
      isLoginValidate(doctorId, hospitalId, flagOption, bySlot, selDate, selTime) {
        if (this.isLoginCheck() === true) {
          if (doctorId) {
            // alert("inside" + doctorId);
            window.sessionStorage.setItem("loginValidate", "");
            window.sessionStorage.setItem("appDocId", doctorId);
            window.sessionStorage.setItem("appHosId", hospitalId);
            window.sessionStorage.setItem("flagOption", flagOption);
            window.sessionStorage.setItem("bySlot", bySlot);
            window.sessionStorage.setItem("selDate", selDate);
            window.sessionStorage.setItem("selTime", selTime);
            window.sessionStorage.setItem("doctor_details", "1");
            //alert();
            window.location.href = "/patient/bookappointment";
          } else if (window.sessionStorage.getItem("appDocId")) {
            window.location.href = "/patient/bookappointment";
          } else {
          }
        } else {
          window.sessionStorage.setItem("loginValidate", "book");
          window.sessionStorage.setItem("appDocId", doctorId);
          window.sessionStorage.setItem("appHosId", hospitalId);
          window.sessionStorage.setItem("flagOption", flagOption);
          window.sessionStorage.setItem("bySlot", bySlot);
          window.sessionStorage.setItem("selDate", selDate);
          window.sessionStorage.setItem("selTime", selTime);
          window.sessionStorage.setItem("doctor_details", "1");
          $("#ForgotPasswordWrap").hide();
          $("#SignupWrap").hide();
          $("#signInWrapDiv").show();
        }
      }
      getCalenderData() {
        const today = moment();
    
        
        const jsonData = {
          method: "POST",
          body: JSON.stringify({
            functionName: "appointmentSlot",
            appoinmentType: "p",
            doctorId: this.state.doctorId,
            hospitalId: this.state.hospialId,
            appointmentDate: today.format("DD/MM/YYYY"),
          }),
        };
        console.log(">>>>>>>>>>>>>>.." + jsonData.body);
        $(".calendarDiv").addClass("calendarLoading");
        //console.log("111111",jsonData.body);
        awsAPIUrlcommon(jsonData).then((data) => {
          if (data.success === "1") {
            $(".calendarDiv").removeClass("calendarLoading");
            this.setState({ appointmentSlotDay: data.result[0] });
            this.setState({ calFlag: "OK" });
            this.setState({ calFlag: "OK" });
          }
        });
      }
    render() {
        var dateSettings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 4,
                  swipeToSlide: true,
                },
              },
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 2,
                  swipeToSlide: true,
                },
              },
            ],
          };
        return (
          <div>
              <div className="customCalenderDiv">
                    <Slider {...dateSettings}>
                      {this.state.appointmentSlotDay.map((d, index) => {
                        let dateFrom = moment()
                          .add(index, "d")
                          .format("DD/MM/YYYY");
                        let slots = d.slotVal;

                        return (
                          <div>
                             
                             <div className="dateListItem">
                              <div className="dateHeadListCvr">
                                <div className="dateHeadList">
                                  <span>{d.day}</span>
                                  <p>{dateFrom}</p>
                                </div>
                              </div>
                              <div
                                className="timeListWrapInner"
                                id={this.state.hospialId+"_"+index}
                              >
                                <div className="timeListWrap">
                                  {slots.map((s) => {
                                    return (
                                      <span
                                        to={{
                                          pathname:
                                            "../patient/bookappointment",
                                          state: {
                                            doctorId: this.state.doctorId,
                                            hospitalId: this.state.hospialId,
                                            flagOption: "p",
                                            selDate: dateFrom,
                                            selTime: s,
                                            bySlot: "Yes",
                                          },
                                        }}
                                        className="text-decoration-none"
                                        onClick={(e) => {
                                          this.isLoginValidate(
                                            this.state.doctorId,
                                            this.state.hospialId,
                                            "p",
                                            "Yes",
                                            dateFrom,
                                            s
                                          );
                                        }}
                                      >
                                        <span className="timeList">{s}</span>
                                      </span>
                                    );
                                  })}
                                  <span
                                    className="timeListMore"
                                    onClick={() => this.moreItemLoad(index)}
                                  >
                                    MORE
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
        </div>
          )
        }
      }
      