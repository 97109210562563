import React, { Component, Suspense } from "react";
import * as ReactDOM from "react-dom";
import { matchPath } from "react-router";
import { Link, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import chat from "../image/theme/text2.png";
import Favicon from "react-favicon";
import "bootstrap/dist/css/bootstrap.css";
import "../css/slick.css";
import "../css/slick-theme.css";
import "./reliance.css";
import "./reliance-responsive.css";
import $ from "jquery";
import { apiCalling } from "../apiService";
import Login from "../layout/login";

import Signup from "../layout/signup";
import NotificationPopup from "../home/notificationPopup";
import ForgotPassword from "../layout/forgot-password";

import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { S3ImagePath } from "../config";

ReactGA.initialize(window.sessionStorage.getItem("googleId"));
ReactGA.pageview(window.location.pathname + window.location.search);
ReactPixel.init(window.sessionStorage.getItem("fbPixel"));
ReactPixel.pageView();
const imgPath = S3ImagePath();
const PatientMenu = React.lazy(() => import("../home/patientMenu"));

const DoctorMenu = React.lazy(() => import("../home/doctorMenu"));
const LoginL = React.lazy(() => import("../layout/login"));
const SignupL = React.lazy(() => import("../layout/signup"));
const NotificationPopUp = React.lazy(() => import("../home/notificationPopup"));

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerData: [],
      userdropVisible: false,
      textChatCount: "0",
    };
    this.userdropClick = this.userdropClick.bind(this);
    this.userOutsideClick = this.userOutsideClick.bind(this);
  }
  doctorDashboard() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorDashboard",
        doctorId: window.sessionStorage.getItem("userId"),
        siteId: window.sessionStorage.getItem("siteId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          videoOrAudioChatCount: data.videoOrAudioChatCount,
          pulseSiteUrl: data.pulseSiteUrl,
          pulsePaymentDone: data.pulsePaymentDone,
          newsFeedCount: data.newsFeedCount,
        });
      }
    });
    const textChatJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "chat/getChatQuestions",
        siteId: window.sessionStorage.getItem("siteId"),
        hospitalId: "", //window.sessionStorage.getItem("appHosId"),
        userId: window.sessionStorage.getItem("userId"), //"19906",
        bundleId: "",
      }),
    };
    apiCalling(textChatJson).then((data) => {
      let count = 0;
      if (data.success === "1") {
        if (data.resultArray.activeChats.length > 0) {
          for (let i = 0; i < data.resultArray.activeChats.length; i++) {
            count =
              count + parseInt(data.resultArray.activeChats[i].unreadCount);
          }
          this.setState({ textChatCount: count.toString() });
        } else {
          this.setState({ textChatCount: "0" });
        }
        // this.setState({
        //   textChatCount: data.result[0].textChatCount
        // });
      }
    });
  }
  userDashboard() {
    // const apiJson = {
    //   method: "POST",
    //   body: JSON.stringify({
    //     functionName: "userDashboard",
    //     userId: window.sessionStorage.getItem("userId"),
    //     siteId:window.sessionStorage.getItem("siteId"),
    //   }),
    // };
    // apiCalling(apiJson).then((data) => {

    //   if (data.success === "1") {
    //     this.setState({
    //       textChatCount: data.result[0].textChatCount
    //     });
    //   }
    // });
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "chat/getChatQuestions",
        siteId: window.sessionStorage.getItem("siteId"),
        hospitalId: "", //window.sessionStorage.getItem("appHosId"),
        userId: window.sessionStorage.getItem("userId"), //"19906",
        bundleId: "",
      }),
    };
    apiCalling(apiJson).then((data) => {
      let count = 0;
      if (data.success === "1") {
        if (data.resultArray.activeChats.length > 0) {
          for (let i = 0; i < data.resultArray.activeChats.length; i++) {
            count =
              count + parseInt(data.resultArray.activeChats[i].unreadCount);
          }
          this.setState({ textChatCount: count.toString() });
        } else {
          this.setState({ textChatCount: "0" });
        }
        // this.setState({
        //   textChatCount: data.result[0].textChatCount
        // });
      }
    });
  }
  userdropClick() {
    if (!this.state.userdropVisible) {
      // attach/remove event handler
      document.addEventListener("click", this.userOutsideClick, false);
    } else {
      document.removeEventListener("click", this.userOutsideClick, false);
    }
    this.setState((prevState) => ({
      userdropVisible: !prevState.userdropVisible,
    }));
  }
  userOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.userNode) {
      if (this.userNode.contains(e.target)) {
        return;
      }
      this.userdropClick();
    }
  }
  componentWillMount() {
    const doctorDetails = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getSiteDetails",
        siteId: window.sessionStorage.getItem("siteId"),
        siteUrl: window.sessionStorage.getItem("siteUrl"),
      }),
    };
    apiCalling(doctorDetails).then((data) => {
      // console.log("body", data);
      if (data.success === "1") {
        // console.log("asdasd", data.siteDetailsData[0].hospitalData);
        window.sessionStorage.setItem(
          "dashboard_html",
          data.siteDetailsData[0].siteData[0].dashboard_html
        );
        window.sessionStorage.setItem(
          "footerhospitalData",
          JSON.stringify(data.siteDetailsData[0].hospitalData)
        );
        this.setState({ headerData: data.siteDetailsData[0].headerData });
      }
    });
  }
  menuClick() {
    $(".menu ul").slideToggle();
  }
  loginMenu() {
    $("#ForgotPasswordWrap").hide();
    $("#SignupWrap").hide();
    $("#signInWrapDiv").show();
    const LazyPage = React.lazy(() => import("../layout/login"));
    ReactDOM.render(
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <section>
            <LazyPage />
          </section>
        </Suspense>
      </div>,
      document.getElementById("signInWrapDiv")
    );
    if ($(window).width() < 992) {
      $(".mentalMenu").slideUp();
    } else {
    }
  }
  // readMessage() {
  //   const jsontest = {
  //     method: "POST",
  //     body: JSON.stringify({
  //       functionName: "chat/markChatIsRead",
  //       chatGroupId: window.sessionStorage.getItem("chatDoctorPanelId"),
  //       userId: "",
  //       bundleId: "",
  //       siteId: window.sessionStorage.getItem("siteId"),
  //     }),
  //   };
  //   apiCalling(jsontest).then((data) => {});
  // }
  componentDidMount() {
    $("#textChatCount").text("0");
    if (window.sessionStorage.getItem("role") === "ROLE_DOCTOR") {
      this.doctorDashboard();
    } else if (window.sessionStorage.getItem("role") === "ROLE_USER") {
      this.userDashboard();
    }
    setInterval(async () => {
      if (window.sessionStorage.getItem("role") === "ROLE_DOCTOR") {
        this.doctorDashboard();
      } else if (window.sessionStorage.getItem("role") === "ROLE_USER") {
        this.userDashboard();
      }
    }, 3000);  

    $(".footerWrapper").parent().css({
      "margin-top": "auto",
    });
    setInterval(async () => {
      $(".showMyName").html(window.sessionStorage.getItem("userName"));
      $(".showMyName").text(window.sessionStorage.getItem("userName"));
    }, 2000);

    $("html, body").animate({ scrollTop: 0 }, "slow");
    $(".patientMenuTop").hide();
    $(".doctorMenuTop").hide();

    $("#forgotPswd").click(function () {
      $("#SignupWrap").hide();
      $("#signInWrapDiv").hide();
      $("#ForgotPasswordWrap").show();
    });
    $("#signUp").click(function () {
      $("#signInWrapDiv").hide();
      $("#ForgotPasswordWrap").hide();
      $("#SignupWrap").show();
    });

    $(".signInWrap,#loginMenu").click(function (event) {
      event.stopPropagation();
    });
    if (window.sessionStorage.getItem("isLogin") === "true") {
      if (window.sessionStorage.getItem("role") === "ROLE_DOCTOR") {
        $(".doctorMenuTop").show();
      }
      if (window.sessionStorage.getItem("role") === "ROLE_USER") {
        $(".patientMenuTop").show();
      }
    }

    $("#dropArrow").click(function () {
      $(".mentalSubMenu").toggle();
    });
    $(".mentalToggle").click(function () {
      $(".mentalMenu").slideToggle();
    });

    if (window.sessionStorage.getItem("reload") === "1") {
      window.sessionStorage.setItem("reload", "0");
    }
    $(".menu_item").click(function () {
      if ($(window).width() < 992) {
        $(this).children(".mentalSubMenu").slideToggle();
      } else {
      }
    });
  }

  menuClick() {
    $(".menu ul").slideToggle();
  }

  focusSession(focusString) {
    var scrollPos = $("#" + focusString).offset().top;
    $(window).scrollTop(scrollPos);
  }
  signOutPatientClick() {
    $(".loader").show();
    const logoutReq = {
      method: "POST",
      body: JSON.stringify({
        functionName: "logout",
        siteId: window.sessionStorage.getItem("siteId"),
        userId: window.sessionStorage.getItem("userId"),
      }),
    };

    apiCalling(logoutReq).then((data) => {
      if (data.success === "1") {
        const siteId = window.sessionStorage.getItem("siteId");
        localStorage.clear();
        window.sessionStorage.setItem("userId", "");
        window.sessionStorage.setItem("emailId", "");
        window.sessionStorage.setItem("userName", "");
        window.sessionStorage.setItem("isLogin", "");
        window.sessionStorage.setItem("role", "");
        const urlPath = window.location.origin.toString();
        window.sessionStorage.setItem("siteId", siteId);
        window.location.href = urlPath + "/SITEID-" + siteId;

        alert(data.successMessage);
      } else {
        alert("Logout Failed");
      }
      $(".loader").hide();
    });
  }
  render() {
    const urlPath = window.location.origin.toString();
    return (
      <div>
        <div className="mentalHeader">
          <Favicon url={window.sessionStorage.getItem("fav_icon")} />
          <Helmet>
            <title></title>
          </Helmet>
          <div className="mentalHeaderActive"></div>
          <Suspense fallback={<div></div>}>
            <section>
              <NotificationPopUp />
            </section>
          </Suspense>
          <span className="mentalToggle">
            <span></span>
          </span>
          {/* <div className="headerTop">
            For Appointment Call<a href="tel:+918078811101">+91 8078811101</a>
          </div> */}
          <div className=" container mentalLogo d-flex align-items-center">
            <a
              href={
                window.sessionStorage.getItem("role") === "ROLE_DOCTOR"
                  ? (urlPath, "/doctor/dashboard")
                  : (urlPath, "/")
              }
            >
              <img
                src={imgPath + "/rHealthAssistant/relianceLogo.png"}
                alt=""
              />
            </a>
            <h4 className="mb-0 ml-auto support_div">
              support@rhealthassist.com
            </h4>
          </div>
          <div className="mentalMenu" onClick={() => this.menuClick()}>
            <div className="container">
              <ul className="menu-ul d-flex">
                {window.sessionStorage.getItem("role") === "ROLE_DOCTOR" && (
                  <li className="mr-auto rheathassist">
                    <a
                      href="/doctor/dashboard"
                      className="rhealth-headTexttrnfrm"
                    >
                      RHealthAssist
                    </a>
                  </li>
                )}
                {window.sessionStorage.getItem("role") === "ROLE_DOCTOR" && (
                  <li className="chat_li">
                    <a href="/chat/chatmessagegroup">
                      <img src={chat} alt="" />
                      {/* {this.state.textChatCount !== "" &&
                        this.state.textChatCount !== "0" && (
                          <span
                            id="textChatCount"
                            className="dashMenuNotfcn d-flex align-items-center justify-content-center mr-2"
                          >
                            {this.state.textChatCount}
                          </span>
                        )} */}
                        <span
                            id="textChatCount"
                            className="dashMenuNotfcn d-flex align-items-center justify-content-center mr-2"
                          >
                            {this.state.textChatCount}
                          </span>
                    </a>
                  </li>
                )}
                {window.sessionStorage.getItem("role") === "ROLE_USER" && (
                  <li className="chat_li ml-auto">
                    <a href="/chat/chatmessagegroup">
                      <img src={chat} alt="" />
                      {/* {this.state.textChatCount !== "" &&
                        this.state.textChatCount !== "0" && (
                          <span className="dashMenuNotfcn d-flex align-items-center justify-content-center mr-2">
                            {this.state.textChatCount}
                          </span>
                        )} */}
                        <span className="dashMenuNotfcn d-flex align-items-center justify-content-center mr-2">
                            {this.state.textChatCount}
                          </span>
                    </a>
                  </li>
                )}
                {window.sessionStorage.getItem("role") === "ROLE_DOCTOR" ? (
                  <li className="">
                    <a href={(urlPath, "/doctor/dashboard")}>Home</a>
                  </li>
                ) : window.sessionStorage.getItem("role") === "ROLE_ADMIN" ||
                  window.sessionStorage.getItem("role") ===
                    "ROLE_HOSPITALADMIN" ||
                  window.sessionStorage.getItem("role") ===
                    "ROLE_GROUPHOSPITALADMIN" ? (
                  <li className="ml-auto">
                    {window.sessionStorage.getItem("role") ===
                    "ROLE_GROUPHOSPITALADMIN" ? (
                      <a href={(urlPath, "/Groupadmin/dashboard")}>Home</a>
                    ) : (
                      <a href={(urlPath, "/admin/dashboard")}>Home</a>
                    )}
                  </li>
                ) : (
                  <li className="">
                    <a href={(urlPath, "/")}>Home</a>
                  </li>
                )}
                {window.sessionStorage.getItem("role") === "ROLE_DOCTOR" ? (
                  <a href={(urlPath, "/doctor/doctorinbox")}></a>
                ) : (
                  <>
                    <li>
                      <a href={(urlPath, "/HealthInfo")}>Health Blog</a>
                    </li>
                  </>
                )}

                {window.sessionStorage.getItem("role") === "ROLE_USER" && (
                  <li className="menu_item dropDownArrow service-menublock">
                    <a href="#">
                      Services
                      <i
                        className="fa fa-chevron-down ml-1"
                        style={{ fontSize: "10px" }}
                      ></i>
                    </a>

                    <ul className="mentalSubMenu">
                      {/* <li className="">
                      <a href="/home/doctor-list-main/1">Book Appointment</a>
                    </li> */}
                      <li className="">
                        <a href="/home/doctor-list-main/3">Video Chat</a>
                      </li>
                      {/* <li className="">
                      <a href="/home/doctor-list-main/4">Audio Chat</a>
                    </li> */}
                      <li className="">
                        <a href="/home/doctor-list-main/2">Text Chat</a>
                      </li>
                      <li className="">
                        <a href="https://www.reliancegeneral.co.in/insurance/insurance-information/wellness/r-healthbeat.aspx?hA00MEt">RHealth Beat</a>
                      </li>
                      <li className="">
                        <a href="https://www.reliancegeneral.co.in/insurance/knowledge-center.aspx">Health-O-Pedia</a>
                      </li>
                      <li className="">
                        <a href="https://www.reliancegeneral.co.in/insurance/insurance-information/wellness/value-deals.aspx?wellness=Wellness">Deals and discounts</a>
                      </li>
                    </ul>
                  </li>
                )}
                {window.sessionStorage.getItem("role") ===
                  "ROLE_HOSPITALADMIN" &&
                  window.sessionStorage.getItem("isLogin") === "true" && (
                    <li className="menu_item dropDownArrow service-menublock">
                      <a href="#">
                        Hospital Admin
                        <i
                          className="fa fa-chevron-down ml-1"
                          style={{ fontSize: "10px" }}
                        ></i>
                      </a>
                      {/* <span className="dropArrow"></span> */}
                      <ul className="mentalSubMenu">
                        <li className="">
                          <a href="/GroupAdmin/Profile">My Profile</a>
                        </li>
                        <li className="">
                          <a href="/admin/dashboard">My dashboard</a>
                        </li>
                        <li className="">
                          <a
                            href="#"
                            className="signOutPatientClick"
                            onClick={() => this.signOutPatientClick()}
                          >
                            {" "}
                            Sign Out
                          </a>
                        </li>
                      </ul>
                    </li>
                  )}

                {window.sessionStorage.getItem("role") ===
                  "ROLE_GROUPHOSPITALADMIN" &&
                  window.sessionStorage.getItem("isLogin") === "true" && (
                    <li className="menu_item dropDownArrow service-menublock">
                      <a href="#">
                        Group Admin
                        <i
                          className="fa fa-chevron-down ml-1"
                          style={{ fontSize: "10px" }}
                        ></i>
                      </a>
                      {/* <span className="dropArrow"></span> */}
                      <ul className="mentalSubMenu">
                        <li className="">
                          <a href="/GroupAdmin/Profile">My Profile</a>
                        </li>
                        <li className="">
                          <a href="/Groupadmin/dashboard">My dashboard</a>
                        </li>
                        <li className="">
                          <a
                            href="#"
                            className="signOutPatientClick"
                            onClick={() => this.signOutPatientClick()}
                          >
                            {" "}
                            Sign Out
                          </a>
                        </li>
                      </ul>
                    </li>
                  )}

                {window.sessionStorage.getItem("role") === "ROLE_ADMIN" &&
                  window.sessionStorage.getItem("isLogin") === "true" && (
                    <li className="menu_item dropDownArrow service-menublock">
                      <a href="#">
                        Admin
                        <i
                          className="fa fa-chevron-down ml-1"
                          style={{ fontSize: "10px" }}
                        ></i>
                      </a>
                      {/* <span className="dropArrow"></span> */}
                      <ul className="mentalSubMenu">
                        <li className="">
                          <a href="/admin/dashboard">My dashboard</a>
                        </li>
                        <li className="">
                          <a
                            href="#"
                            className="signOutPatientClick"
                            onClick={() => this.signOutPatientClick()}
                          >
                            {" "}
                            Sign Out
                          </a>
                        </li>
                      </ul>
                    </li>
                  )}
                {/* <li className="service-menublock">
                <a href="#">Main Menu</a>
                <ul>
                  <li>Menu1</li>
                  <li>Menu1</li>
                </ul>
              </li> */}
                <li className=" patientMenuTop menu-role">
                  <div
                    className="imgshowhide menu-role-link"
                    onClick={this.userdropClick}
                    ref={(userNode) => {
                      this.userNode = userNode;
                    }}
                  >
                    <span className="">
                      <span className="text-capitalize">Hi</span>{" "}
                      <span className="showMyName">
                        {window.sessionStorage.getItem("userName")}
                      </span>
                    </span>
                    <i
                      className="fa fa-chevron-down ml-1"
                      style={{ fontSize: "10px" }}
                    ></i>
                  </div>
                  {/* <span className=""></span> */}
                  {this.state.userdropVisible && (
                    <div className="">
                      <Suspense fallback={<div></div>}>
                        <section>
                          <PatientMenu />
                        </section>
                      </Suspense>
                    </div>
                  )}
                </li>
                {/* <li className=" patientMenuTop menu-role">
                <div
                  className="imgshowhide menu-role-link"
                  onClick={this.userdropClick}
                  ref={(userNode) => {
                    this.userNode = userNode;
                  }}
                >
                  <span >
                    {" "}
                SERVICES
                  </span>
                  <i
                    className="fa fa-chevron-down ml-1"
                    style={{ fontSize: "10px" }}
                  ></i>
                </div>
            
                {this.state.userdropVisible && (
                  <div className="">
                    <Suspense fallback={<div></div>}>
                      <section>
                        <PatientMenuNew />
                      </section>
                    </Suspense>
                  </div>
                )}
              </li> */}

                <li className="doctorMenuTop menu-role">
                  <Link
                    className="imgshowhide menu-role-link"
                    onClick={this.userdropClick}
                    ref={(userNode) => {
                      this.userNode = userNode;
                    }}
                  >
                    <span className="">
                      <span className="text-capitalize">Hi</span>{" "}
                      <span className="text-capitalize">
                        {window.sessionStorage.getItem("userName")}
                      </span>
                    </span>
                    <i
                      className="fa fa-chevron-down ml-1"
                      style={{ fontSize: "10px" }}
                    ></i>
                  </Link>
                  {/* <span className=""></span> */}
                  {this.state.userdropVisible && (
                    <div className="">
                      <Suspense fallback={<div></div>}>
                        <section>
                          <DoctorMenu />
                        </section>
                      </Suspense>
                    </div>
                  )}
                </li>

                {window.sessionStorage.getItem("isLogin") !== "true" ? (
                  <li>
                    <a href="#" id="loginSession" onClick={this.loginMenu}>
                      Login
                    </a>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div>
          <div className=" signInWrap" id="signInWrapDiv">
            <Suspense fallback={<div></div>}>
              <section>
                <LoginL />
              </section>
            </Suspense>
          </div>
          <div className=" signInWrap" id="SignupWrap">
            <Signup></Signup>
          </div>
          <div className=" signInWrap " id="ForgotPasswordWrap">
            <ForgotPassword></ForgotPassword>
          </div>
          <div className="loader"></div>
          <div id="lazyHomeBody_"></div>
        </div>
      </div>
    );
  }
}
