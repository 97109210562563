import React, { Component, Suspense } from 'react';
import { apiCalling } from "../apiService";
import $ from "jquery";
const minOffset = 0;
const maxOffset = 60;
const thisYear = (new Date()).getFullYear();

export default class EducationalDetails extends Component {

  constructor(props) {
    super(props);

    this.state = {
      graduation:[],
      graduationType: '',
      postgraduation: [],
      postgraduationType: '',
      stateData: [],
      specialization: [],
      specializationType: '',
      college: '',
      collegePost: '',
      collegeSpec: '',
      medicalReg: '',
      thisYear: thisYear,
      registrationState:'',
      selectedYear: [],
      selectedYearP: '',
      selectedYearS: '',

    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.changeInputHandle = this.changeInputHandle.bind(this);
    this.onHandleChangeDate = this.onHandleChangeDate.bind(this);
    this.onHandleDateChange = this.onHandleDateChange.bind(this);
    this.saveHandle = this.saveHandle.bind(this);
  }
// componentWillReceiveProps(){
//   this.getDetails();
// }
  componentDidMount() {
  
    this.getDetails();

    const getData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "masterDetails",
      }),
    };
    apiCalling(getData).then  ((data) => {
      if (data.success == "1") {
        this.setState({ graduation: data.masterData[0].educationData });
        this.setState({ postgraduation: data.masterData[0].educationData });
        this.setState({ stateData: data.masterData[0].stateData });
        this.setState({ specialization: data.masterData[0].educationData });
      }
    });
  }

  getDetails(){

    const getDetails = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDoctorDetails",
        siteId: window.sessionStorage.getItem("siteId"),
        doctorId: window.sessionStorage.getItem("userId"),
      }),
    }
    apiCalling(getDetails).then((data) => {
      if (data.success === "1") {        
        if(data.doctorData[0].education[0] != undefined) {
          //console.log('educationList aaa ',educationList[0])
          this.setState({graduationType:data.doctorData[0].education[0].degreeName})
          this.setState({selectedYear:data.doctorData[0].education[0].year})
          this.setState({college:data.doctorData[0].education[0].college})
          this.setState({medicalReg:data.doctorData[0].education[0].medicalRegNumber})
          this.setState({registrationState:data.doctorData[0].education[0].registrationstate})
        }
        if(data.doctorData[0].education[1] != undefined) {
         // console.log('educationList vvbgfhhg ',educationList[1])
          this.setState({postgraduationType:data.doctorData[0].education[1].degreeName})
          this.setState({selectedYearP:data.doctorData[0].education[1].year})
          this.setState({collegePost:data.doctorData[0].education[1].college})
        }
        if(data.doctorData[0].education[2] != undefined) {
          //console.log('educationList vvvvvv ',educationList[2])
          this.setState({specializationType:data.doctorData[0].education[2].degreeName})
          this.setState({selectedYearS:data.doctorData[0].education[2].year})
          this.setState({collegeSpec:data.doctorData[0].education[2].college})
        }
      }
    });
}
    
  saveHandle() {

    let year1 = $("#year1").val() ;
    let college1 = $("#college1").val();
    let errormessage="";
    if($("#graduation").val() == "0"){
      errormessage="Select Graduation"
      $("#graduation").focus();
    }else if(!year1){
      errormessage="Please Select Year"
      $("#year1").focus();
    }else if (!college1){
      errormessage="Please Enter the College"
      $("#college1").focus();
    }else{  
  }
    var educationType =  $("#graduation").val();
    educationType =  $("#postgraduation").val() ? educationType  + ',' + $("#postgraduation").val() :educationType
    educationType =  $("#specialization").val() ? educationType  + ',' + $("#specialization").val() :educationType

     var yearType =  $("#year1").val();
     yearType =  $("#year2").val() ? yearType  + ',' + $("#year2").val() :yearType
     yearType =  $("#year3").val() ? yearType  + ',' + $("#year3").val() :yearType
     
      var college = $("#college1").val();
      college =  $("#college2").val() ? college  + ',' + $("#college2").val() :college
      college =  $("#college3").val() ? college  + ',' + $("#college3").val() :college

      if(errormessage==""){
      const saveDatas = {
        method: 'POST',
        body: JSON.stringify({
          functionName: "updateDoctorDetails",
          subFunctionName: "updateEducationDetails",
          siteId: window.sessionStorage.getItem("siteId"),
          doctorId: window.sessionStorage.getItem("userId"),
          emailId: window.sessionStorage.getItem("emailId"),
          year: yearType,
          educationCategoryId:educationType,
          college: college,
          medicalRegNo: $("#medicalno").val(),
          registrationstate:$("#stateType").val()
        })
      }
      // console.log("zzzzzzzzzz", saveDatas)
      $(".loader").show();
      apiCalling(saveDatas).then((data) => {
        $(".loader").hide();
      //  console.log("educationt=====", data)
        if (data.success == "1") {
          alert("Details updated successfully")
          window.location.reload(false);
        } 
      })
    }
    else{
      $("#messageEducationEdit").text(errormessage);
    
      $("#messageEducationEdit").css("color", "red");
        }
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
    //console.log("111111111", value)
  };

  changeInputHandle = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
    //console.log("111111111", value)
  };

  onHandleChange = (event) => {
    this.setState({ selectedYear: event.target.value });
    //console.log(".....", event.target.value)
  };
  onHandleChangeDate = (event) => {
    this.setState({ selectedYearP: event.target.value });
    //console.log(".....", event.target.value)
  };
  onHandleDateChange = (event) => {
    this.setState({ selectedYearS: event.target.value });
    //console.log(".....", event.target.value)
  };

  render() {

    const { thisYear } = this.state;
    const Years = [];
    for (let i = minOffset; i <= maxOffset; i++) {
      const year = thisYear - i;
      Years.push(<option value={year}>{year}</option>);
    }
    return (

      <div class="row px-2">
          <div class="col-sm-12">
         <div class="col-sm-12 my-2">
            <p id="messageEducationEdit"style={{textAlign:'center'}}></p>
            </div>
            </div>
        <div class="col-sm-6">
          <div class="row my-2">
            <label class="col-sm-12 label-text">Graduation <span class="star-red">*</span></label>
            <div class="col-sm-12 mb-3">
              <select class="input-design"
                id="graduation"
                name="graduation"
               //  value={this.state.graduation}
              //   onChange={this.handleInputChange}
              >
                <option value="0">Graduation</option>
                {this.state.graduation.map((obj) => (
                  obj.description == this.state.graduationType ?
                    <option value={obj.educationId} selected>{this.state.graduationType}</option>
                  : 
                    <option value={obj.educationId}>{obj.description}</option>
                ))}
              </select>
            </div>
            <div class="col-sm-12 mb-3">
              <select class="input-design"
                id="year1"
                name="year1"
                value={this.state.selectedYear}
                onChange={this.onHandleChange}
              >
                <option value="">Year</option>
                {Years}
              </select>
            </div>
            <div class="col-sm-12 mb-3">
              <input type="text" class="input-design" placeholder="College" id="college1" name="college"  value={this.state.college} onChange={this.handleInputChange} />
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row my-2">
            <label class="col-sm-12 label-text">Post Graduation</label>
            <div class="col-sm-12 mb-3">
              <select class="input-design"
                id="postgraduation"
                name="postgraduation"
              // value={this.state.postgraduationType}
              // onChange={this.onHandleChange}
              >
                <option value="">Post Graduation</option>
                {this.state.postgraduation.map((obj) => (
                    obj.description == this.state.postgraduationType ?
                  <option value={obj.educationId} selected>{this.state.postgraduationType}</option>
                  : 
                  <option value={obj.educationId}>{obj.description}</option>
                ))}
              </select>
            </div>
            <div class="col-sm-12 mb-3">
              <select class="input-design"
                id="year2"
                name="year2"
                value={this.state.selectedYearP}
                onChange={this.onHandleChangeDate}
              >
                <option value="">Year</option>
                {Years}
              </select>
            </div>
            <div class="col-sm-12 mb-3">
              <input type="text" class="input-design" placeholder="College" id="college2" name="collegePost"  value={this.state.collegePost} onChange={this.handleInputChange} />
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row my-2">
            <label class="col-sm-12 label-text">Medical Registration </label>
            <div class="col-sm-12 mb-3">
              <select class="input-design"
                id="stateType"
                name="stateType"
                onChange={this.handleInputChange}
              >
                <option value="">Reg.State</option>
                {this.state.stateData.map((obj) => (
                  obj.stateName === this.state.registrationState ?
                  <option value={obj.stateName} selected>{this.state.registrationState}</option>
                : 
                  <option value={obj.stateName}>{obj.stateName}</option>
                ))}
              </select>
            </div>
            <div class="col-sm-12 mb-3">
              <input type="text" class="input-design" placeholder="Medical Reg. No." id="medicalno"  name="medicalReg" value={this.state.medicalReg} onChange={this.handleInputChange} />
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row my-2">
            <label class="col-sm-12 label-text">Super Specialization </label>
            <div class="col-sm-12 mb-3">
              <select class="input-design"
                id="specialization"
                name="specialization"
              // value={this.state.specializationType}
              // onChange={this.onHandleChange}
              >
                <option value="">Select</option>
                {this.state.specialization.map((obj) => (
                 obj.description == this.state.specializationType ?
                 <option value={obj.educationId} selected>{this.state.specializationType}</option>
                 : 
                 <option value={obj.educationId}>{obj.description}</option>
               ))}
              </select>
            </div>
            <div class="col-sm-12 mb-3">
              <select class="input-design"
                id="year3"
                name="year3"
                value={this.state.selectedYearS}
                onChange={this.onHandleDateChange}
              >
                <option value="">Year</option>
                {Years}
              </select>
            </div>
            <div class="col-sm-12 mb-3">
              <input type="text" class="input-design" placeholder="College" id="college3" name="collegeSpec"  value={this.state.collegeSpec} onChange={this.handleInputChange} />
            </div>
          </div>
        </div>

        <div className="col-sm-12">
          <input type="submit" value="Save" className="formButton" onClick={() => this.saveHandle()} ></input>
        </div>

      </div>
    )
  }
}
