import React, { Component } from 'react';
import Header from "../Reliance/header"
import Footer from "../Reliance/footer"

export default class EmailConfirm extends Component {

  render() {
    return (
      <div class="purpleWrap">
        <Header></Header>
        <div class="container">
          <div class="main-wrapper-smwidth">
                <div class="form-head text-center">Ask the Doctor - Dr.  Video schedule doc MBBS</div>
              <div class="form-section-bgcolor form-section-smwidth px-2 px-md-0">
              <div class="form-subhead-brdr text-center">Confirmation</div>
                <div class="row row-margin">
                  <label class="col-4 label-text-right label-text-cln">Question</label>
                  <label class="col-8 ">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</label>
                </div>

                <div class="row row-margin">
                  <label class="col-4 label-text-right label-text-cln">Amount(Rs)</label>
                  <label class="col-8 ">Free</label>
                </div>
              </div>

              <div className="col-sm-12 text-center mt-4">
                <a href="../patient/emailsuccess">
                  <input type="submit" value="Send" className="formButton formButtonBold"></input>
                </a>
                <input type="button" value="Cancel" className="formButtonBold cancelButton"></input>
              </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    )
  }
}
