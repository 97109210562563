import React, { Component } from 'react';
import { apiCalling } from "../apiService";
import $ from "jquery";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
export default class SpecialityDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {

      specializationType: [],
      specializationDetails: [],
      specP: [],
      specS: [],
      errorMessage:''
    }
    this.saveHandle = this.saveHandle.bind(this);
  }

  componentDidMount() {
    this.getDetails();
    this.masterDetails();
  }
  
  masterDetails() {
// console.log("master data")
    const getData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "masterDetails",
      }),
    };
    var pCheck=""
    apiCalling(getData).then((data) => {
      // console.log("data", data)
      if (data.success == "1") {
        this.setState({ specializationType: data.masterData[0].specializationData })
        data.masterData[0].specializationData.map((obj, i) => {         
          this.state.specializationDetails.map((d, j) => {
            //alert(d.specializationId +"==="+ obj.specialityId)
            if (d.specializationId === obj.specialityId && d.primarySpecialization === 1) {
            //  alert();
           
            }
          });
        });
      }
    });
  }

  getDetails() {
 
    const getDetails = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDoctorDetails",
        siteId: window.sessionStorage.getItem("siteId"),
        doctorId: window.sessionStorage.getItem("userId"),
      }),
    }
    // console.log(getDetails.body)
    apiCalling(getDetails).then((data) => {
      if (data.success === "1") {
        this.setState({ specializationDetails: data.doctorData[0].specialization });
       
      }
    });
  }

  saveHandle() {
    let errormessage = "";
    var specialityP = "";
    var specialityS = "";
    let specMain = [];
    let specSec = [];
    let speciality = this.state.specializationType
    this.state.specializationType.map((obj) => {
      if ($("#chkp" + obj.specialityId).is(":checked") === true) {
        var a = $("#chkp" + obj.specialityId).val()
        specMain.push(a);
        specSec.push('1');
        specialityP = specMain.toString().replace(/[\[\]']+/g, '')
        specialityS = specSec.toString().replace(/[\[\]']+/g, '')
       // alert("p")
      }
      if ($("#chk" + obj.specialityId).is(":checked") === true) {
        var b = $("#chk" + obj.specialityId).val()
        specMain.push(b);
        specSec.push('0');
        specialityP = specMain.toString().replace(/[\[\]']+/g, '')
        specialityS = specSec.toString().replace(/[\[\]']+/g, '')
       // alert("s")
      }
    });
    // if ($("#chkp").val() == "") {
    //   alert("Select ")
    //   // $("#").focus();
    // } else {
    // if (typeof $('input[name="checkType"]:checked').val() === "undefined") {
      if(specialityP.trim()===""){
        this.setState({errorMessage:"Primary Specialization Required"});
      }
      if (specialityP.length===0) {
      // errormessage = "Select the Required Specialization"
      this.setState({errorMessage:"Select the Required Specialization"});
    }
       else if(specialityS==="0"){
       this.setState({errorMessage:"Primary Specialization Required"});
       $("#TabPanel1").focus();
     }
    else if (specialityS.length > 3) {
      this.setState({errorMessage:"Only one Primary Specialization Required"});
       specialityS = "";
      $("#TabPanel1").focus();
  }else{  
      const saveDatas = {
        method: 'POST',
        body: JSON.stringify({
          functionName: "updateDoctorDetails",
          subFunctionName: "updateSpecialityDetails",
          siteId: window.sessionStorage.getItem("siteId"),
          doctorId: window.sessionStorage.getItem("userId"),
          emailId: window.sessionStorage.getItem("emailId"),
          specializationId: specialityP,
          isPrimarySpecialization: specialityS,
        })
      }
        $(".loader").show();
      apiCalling(saveDatas).then((data) => {
        if (data.success === "1") {
          $(".loader").hide();
          alert("Details updated successfully")
           window.location.reload(false);
        }
      })
    } 
  }
  primary() {
    
    $("#TabPanel1").show();
    $("#TabPanel2").hide();
  }
  secondary() {
   
    $("#TabPanel1").hide();
    $("#TabPanel2").show();
  }
  render() {
    let pCheck = ""
    let sCheck = ""

    return (
      <div>
        <div className='loader'></div>
        <div class="col-sm-12 p-0 mb-3">
          <div class="col-sm-12">
            <div class="col-sm-12 my-2">
              <p style={{fontSize:14, color:"red",textAlign:'center'}}>{this.state.errorMessage}</p>
            </div>
          </div>
          <Tabs className="video-appntmnt-list">
            <TabList>
              <Tab onClick={() => this.primary()} >
                <div className="drDetSpanUnderline">
                  Primary Specialization

                  </div>
              </Tab>
              <Tab onClick={() => this.secondary()}>
                <div className="drDetSpanUnderline">
                  Secondary Specialization
                  </div>
              </Tab>
            </TabList>
            <div id="TabPanel1">
              <h6>Medicine Branch* </h6>
              {this.state.specializationType.length >0 && this.state.specializationDetails.length > 0 ?
              <div class="row px-2 scrollDiv">
                {this.state.specializationType.map((obj, i) => {
                  pCheck = ""
                  this.state.specializationDetails.map((d, j) => {                 
                    if (d.specializationId === obj.specialityId && d.primarySpecialization === 1) {
                      pCheck = "true";                   
                    }
                  });
                  return (
                    <div class="col-sm-6 mb-2 d-flex">
                      <input type="checkbox" name="checkType"  id={"chkp" + obj.specialityId} defaultChecked={pCheck} class="mr-2" value={obj.specialityId} />
                      <label className="labelField" style={{ marginTop: "-3px" }}><span className="star-red">*</span>{obj.specialization}</label>
                    </div>)
                })}             
              </div>:
              <div class="row px-2 scrollDiv">
              {this.state.specializationType.map((obj, i) => {              
                return (
                  <div class="col-sm-6 mb-2 d-flex">
                    <input type="checkbox" name="checkType"  id={"chkp" + obj.specialityId}  class="mr-2" value={obj.specialityId} />
                    <label className="labelField" style={{ marginTop: "-3px" }}><span className="star-red">*</span>{obj.specialization}</label>
                  </div>)
              })}             
            </div>
              }
            </div>
            <div id="TabPanel2" style={{ display: "none" }}>
              <h6>Medicine Branch  </h6>
              {this.state.specializationType.length >0 && this.state.specializationDetails.length > 0 ?
              <div class="row px-2 scrollDiv">
                {this.state.specializationType.map((obj, i) => {
                  sCheck = ""
                  this.state.specializationDetails.map((d, j) => {

                    if (d.specializationId === obj.specialityId && d.primarySpecialization === 0) {
                      sCheck = "true"
                    }
                  });
                  return (
                    <div class="col-sm-6 mb-2">
                      <input type="checkbox" name="checkType"   id={"chk" + obj.specialityId} class="mr-2" defaultChecked={sCheck} value={obj.specialityId} />
                      <label className="labelField">{obj.specialization}</label>
                    </div>)
                })}
              </div>:
              <div class="row px-2 scrollDiv">
              {this.state.specializationType.map((obj, i) => {
                               return (
                  <div class="col-sm-6 mb-2">
                    <input type="checkbox" name="checkType"  id={"chk" + obj.specialityId} class="mr-2"  value={obj.specialityId} />
                    <label className="labelField">{obj.specialization}</label>
                  </div>)
              })}
            </div>
              }
            </div>
          </Tabs>
        </div>
        <div className="col-sm-12">
          <input type="submit" value="Save" className="formButton" onClick={() => this.saveHandle()} ></input>
        </div>
      </div>
    )
  }
}
