import React, { Component, Suspense } from "react";
import DataMigrationFileUpload from "./dataMigrationFileUpload";
import DataMigrationList from "./dataMigrationList";
import {
  apiCalling,
  awsAPIUrlcommon,
  asyncAwsAPIUrlcommon,
} from "../../apiService";
import $ from "jquery";

const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../../" + folderName + "/header"));
const Footer = React.lazy(() => import("../../" + folderName + "/footer"));

class dataMigration extends React.Component {
  state = {
    mode: "listView",
    patientMigrationMainId: 0,
    currentPage: "Patient Data Migration",
    navigation: [
      { pageName: "Dashboard", url: "/admin/dashboard" },
      {
        pageName: "Data Migration",
        url: "/admin/dataMigration",
        current: true,
      },
    ],
    imagePathData: {},
    subDataList: [],
    mainDataList: [],
    fileName: "",

    mainDataListTotalCount: 0,
    subDataListTotalCount: 0,
    activePage: 1,
    offset: "0",
    maxRow: "10",
    hospitalId: 0,
    hospitalData: JSON.parse(
      window.sessionStorage.getItem("footerhospitalData")
    ),
    status: 2,
  };

  componentDidMount() {
    this.getImagePath();
    this.getMainAndSubData();
  }

  handlePageChange = (pageNumber) => {
    this.setState(
      {
        activePage: pageNumber,
        offset: (parseInt(pageNumber) - 1) * 10,
      },
      () => {
        this.getMainAndSubData();
      }
    );
  };

  changeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  getMainAndSubData = () => {
    $(".loader").show();
    const getMainAndSubData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getMainAndSubData",
        patientMigrationMainId: this.state.patientMigrationMainId,
        max: this.state.patientMigrationMainId > 0 ? "" : this.state.maxRow,
        Offset: this.state.patientMigrationMainId > 0 ? 0 : this.state.offset,
      }),
    };

    awsAPIUrlcommon(getMainAndSubData).then((data) => {
      if (data.success === "1") {
        this.setState({
          subDataList:
            this.state.patientMigrationMainId == 0 ? [] : data.subDataList,
          mainDataList: data.mainDataList,
          mainDataListTotalCount: data.mainDataListTotalCount,
          subDataListTotalCount: data.subDataListTotalCount,
        });
      } else if (data.success === "0") {
        this.setState({ subDataList: [] });
      }
      $(".loader").hide();
    });
  };

  cancel = () => {
    this.setState({
      status: 2,
      fileName: "",
      hospitalId: 0,
      patientMigrationMainId: 0,
    });
  };

  saveHandle = (subDataList, fileName) => {
    if (subDataList.length > 0) {
      
      let body = {
        functionName: "saveMainData",
        fileName: fileName,
        fileCreatedBy: "1",
        fileStatus: "0",
        patientMigrationSubData: subDataList,
      };

      const saveMainData = {
        method: "POST",
        body: JSON.stringify(body),
      };

      let navigationFileUpload = [
        { pageName: "Dashboard", url: "/admin/dashboard" },
        {
          pageName: "Data Migration",
          url: "/admin/dataMigration",
        },
        {
          pageName: "File Upload",
          url: "#",
          current: true,
        },
      ];
      $(".loader").show();
      asyncAwsAPIUrlcommon(saveMainData).then((data) => {
        if (data.success === "1") {
          alert(data.successMessage);
        } else {
          alert(data.errorMessage);
        }
        this.cancel();
        this.handleModeChange(
          "listView",
          navigationFileUpload,
          0,
          "Patient Data Migration"
        );

        //$(".loader").hide();
      });
    } else {
      alert("No Records found for saving");
      $(".loader").hide();
    }
  };

  async processDta(processData) {
    // $(".loader").show();
    await asyncAwsAPIUrlcommon(processData).then((data) => {
      // if (data.success === "1") {
      //   alert(data.successMessage);
      // } else {
      //   alert(data.errorMessage);
      // }
      // $(".loader").hide();
    });
  }

  processData = (subDataList) => {
    let navigationFileUpload = [
      { pageName: "Dashboard", url: "/admin/dashboard" },
      {
        pageName: "Patient Data Migration",
        url: "/admin/dataMigration",
        current: true,
      },
    ];

    if (this.state.hospitalId == 0) {
      alert("Select Hospital");
    } else if (subDataList.length > 0) {
      let body = {
        functionName: "processData",
        hospitalId: this.state.hospitalId,
        patientMigrationMainId: this.state.patientMigrationMainId,
        patientMigrationSubData: subDataList,
      };

      const processData = {
        method: "POST",
        body: JSON.stringify(body),
      };
      console.log(body);

      this.processDta(processData);
      this.cancel();
      this.handleModeChange(
        "listView",
        navigationFileUpload,
        0,
        "Patient Data Migration"
      );
    } else {
      alert("No Records found for saving");
    }
  };

  setSubDataList = (subDataList) => {
    this.setState({ subDataList });
  };

  handleView = () => {
    if (this.state.mode == "fileUploadView") {
      return (
        <DataMigrationFileUpload
          changeMode={this.handleModeChange}
          setSubDataList={this.setSubDataList}
          saveHandle={this.saveHandle}
          setFileName={(fileName) => this.setState({ fileName })}
          setHospital={(hospitalId) => this.setState({ hospitalId })}
          changeHandler={this.changeHandler}
          state={this.state}
          processData={this.processData}
        />
      );
    } else if (this.state.mode == "edit") {
      return (
        this.state.subDataList.length > 0 && (
          <DataMigrationFileUpload
            changeMode={this.handleModeChange}
            setSubDataList={this.setSubDataList}
            saveHandle={this.saveHandle}
            setFileName={(fileName) => this.setState({ fileName })}
            setHospital={(hospitalId) => this.setState({ hospitalId })}
            changeHandler={this.changeHandler}
            state={this.state}
            processData={this.processData}
          />
        )
      );
    } else if (this.state.mode == "listView") {
      return (
        <DataMigrationList
          changeMode={this.handleModeChange}
          patientMigrationMainId={this.state.patientMigrationMainId}
          imagePathData={this.state.imagePathData}
          getMainAndSubData={this.getMainAndSubData}
          mainDataList={this.state.mainDataList}
          handlePageChange={this.handlePageChange}
          changeHandler={this.changeHandler}
          state={this.state}
        />
      );
    } else {
      return <div>Error occured..</div>;
    }
  };

  handleModeChange = (
    mode,
    navigation,
    patientMigrationMainId = 0,
    currentPage = "",
    fileName = "",
    status = 2
  ) => {
    this.setState(
      {
        mode,
        navigation,
        patientMigrationMainId,
        currentPage,
        fileName,
        status,
      },
      () => {
        this.getMainAndSubData();
      }
    );
  };

  getImagePath() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
        patientMigrationMainId: window.sessionStorage.getItem(
          "patientMigrationMainId"
        ),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          imagePathData: data.configPathDetails[0],
        });
      }
    });
  }

  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead footer_flex">
        <Suspense fallback={<div>Waiting...</div>}>
          <section>
            <Header />
            <div className="loader"></div>
            <div className="container">
              <div>
                <ul className="breadcrum-adminMangmnt">
                  {this.state.navigation.map((nav) => (
                    <li>
                      <a
                        href={nav.url}
                        className={nav.current ? "current" : null}
                      >
                        {nav.pageName}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="head-hospMangmntList">
                {this.state.currentPage}
              </div>
              {this.handleView()}
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}

export default dataMigration;
