import React, { Component, Suspense } from "react";
import Closebtn from "../../image/icons/close-sm.png";
import AddImg from "../../image/icons/addbtn.png";

import $ from "jquery";
export default class ConsultingTime extends Component {
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     consultingDetailsArray: [],
  //   };
  // }

  // componentDidMount() {
  //   if (this.props.hospitalConsultingTimeEdit.length > 0) {
  //     this.setState({
  //       consultingDetailsArray: this.props.hospitalConsultingTimeEdit,
  //     });
  //   }
  // }

  addConsultingTime() {
    let a = {
      consultingTimeFrom: $("#consultingTimeFrom").val(),
      consultingTimeTo: $("#consultingTimeTo").val(),
    };
    var showalertforempty =(a.consultingTimeFrom ==='From' || a.consultingTimeFrom === null) ||(a.consultingTimeTo==="To" || a.consultingTimeTo===null)
    if(showalertforempty){
      alert("Consulting Time Required")
      return ;
    }
    let _data = this.props.hospitalConsultingTimeEdit;
    _data.push(a);
    this.props.setHospitalConsultingTimeEdit(_data);

    // this.setState({
    //   consultingDetailsArray: this.state.consultingDetailsArray,
    // });

    $("#consultingTimeFrom").val("0");
    $("#consultingTimeTo").val("0");
  }

  removeConsultingTime(consultingTimeTo) {
    if (this.props.hospitalConsultingTimeEdit.length > 0) {
      let data = $.grep(this.props.hospitalConsultingTimeEdit, function (e) {
        return e.consultingTimeTo !== consultingTimeTo;
      });
      this.props.setHospitalConsultingTimeEdit(data);

      // this.state.consultingDetailsArray = data;

      // this.setState({
      //   consultingDetailsArray: this.state.consultingDetailsArray,
      // });
    }
  }

  // consultingArrayDetails() {
  //   var f = {
  //     consultingArray: this.state.consultingDetailsArray,
  //   };

  //   return f;
  // }

  setConsultingData(value, name, i, mode) {
    if (mode === "consultingTimeFrom") {
      this.props.hospitalConsultingTimeEdit[i].consultingTimeFrom = $(
        "#" + mode + i
      ).val();
    } else if (mode === "consultingTimeTo") {
      this.props.hospitalConsultingTimeEdit[i].consultingTimeTo = $(
        "#" + mode + i
      ).val();
    }

    this.setState({
      [name]: value,
      consultingDetailsArray: this.state.consultingDetailsArray,
    });
  }

  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead mb-4">
        <Suspense fallback={<div></div>}>
          <section>
            <div className="consultngTime-wrapper">
              <h2 className="hspInfoSubhead">Consulting Time</h2>

              <div className="hsptaltimeHead">
                <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                  <label className="tmelabel-hspadmin">Timing From</label>

                  <select name="consultingTimeFrom" id="consultingTimeFrom">
                    <option selected="From">From</option>

                    <option value="01:00 AM">01:00 AM</option>

                    <option value="01:30 AM">01:30 AM</option>

                    <option value="02:00 AM">02:00 AM</option>

                    <option value="02:30 AM">02:30 AM</option>

                    <option value="03:00 AM">03:00 AM</option>

                    <option value="03:30 AM">03:30 AM</option>

                    <option value="04:00 AM">04:00 AM</option>

                    <option value="04:30 AM">04:30 AM</option>

                    <option value="05:00 AM">05:00 AM</option>

                    <option value="05:30 AM">05:30 AM</option>

                    <option value="06:00 AM">06:00 AM</option>

                    <option value="06:30 AM">06:30 AM</option>

                    <option value="07:00 AM">07:00 AM</option>

                    <option value="07:30 AM">07:30 AM</option>

                    <option value="08:00 AM">08:00 AM</option>

                    <option value="08:30 AM">08:30 AM</option>

                    <option value="09:00 AM">09:00 AM</option>

                    <option value="09:30 AM">09:30 AM</option>

                    <option value="10:00 AM">10:00 AM</option>

                    <option value="10:30 AM">10:30 AM</option>

                    <option value="11:00 AM">11:00 AM</option>

                    <option value="11:30 AM">11:30 AM</option>

                    <option value="12:00 AM">12:00 AM</option>

                    <option value="12:30 AM">12:30 AM</option>

                    <option value="01:00 PM">01:00 PM</option>

                    <option value="01:30 PM">01:30 PM</option>

                    <option value="02:00 PM">02:00 PM</option>

                    <option value="02:30 PM">02:30 PM</option>

                    <option value="03:00 PM">03:00 PM</option>

                    <option value="03:30 PM">03:30 PM</option>

                    <option value="04:00 PM">04:00 PM</option>

                    <option value="04:30 PM">04:30 PM</option>

                    <option value="05:00 PM">05:00 PM</option>

                    <option value="05:30 PM">05:30 PM</option>

                    <option value="06:00 PM">06:00 PM</option>

                    <option value="06:30 PM">06:30 PM</option>

                    <option value="07:00 PM">07:00 PM</option>

                    <option value="07:30 PM">07:30 PM</option>

                    <option value="08:00 PM">08:00 PM</option>

                    <option value="08:30 PM">08:30 PM</option>

                    <option value="09:00 PM">09:00 PM</option>

                    <option value="09:30 PM">09:30 PM</option>

                    <option value="10:00 PM">10:00 PM</option>

                    <option value="10:30 PM">10:30 PM</option>

                    <option value="11:00 PM">11:00 PM</option>

                    <option value="11:30 PM">11:30 PM</option>

                    <option value="12:00 PM">12:00 PM</option>

                    <option value="12:30 PM">12:30 PM</option>

                    <option value="12:00 AM">12:00 AM </option>
                  </select>
                </div>

                <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                  <label className="tmelabel-hspadmin-to">Timing To</label>

                  <select name="consultingTimeTo" id="consultingTimeTo">
                    <option selected="From">To</option>

                    <option value="01:00 AM">01:00 AM</option>

                    <option value="01:30 AM">01:30 AM</option>

                    <option value="02:00 AM">02:00 AM</option>

                    <option value="02:30 AM">02:30 AM</option>

                    <option value="03:00 AM">03:00 AM</option>

                    <option value="03:30 AM">03:30 AM</option>

                    <option value="04:00 AM">04:00 AM</option>

                    <option value="04:30 AM">04:30 AM</option>

                    <option value="05:00 AM">05:00 AM</option>

                    <option value="05:30 AM">05:30 AM</option>

                    <option value="06:00 AM">06:00 AM</option>

                    <option value="06:30 AM">06:30 AM</option>

                    <option value="07:00 AM">07:00 AM</option>

                    <option value="07:30 AM">07:30 AM</option>

                    <option value="08:00 AM">08:00 AM</option>

                    <option value="08:30 AM">08:30 AM</option>

                    <option value="09:00 AM">09:00 AM</option>

                    <option value="09:30 AM">09:30 AM</option>

                    <option value="10:00 AM">10:00 AM</option>

                    <option value="10:30 AM">10:30 AM</option>

                    <option value="11:00 AM">11:00 AM</option>

                    <option value="11:30 AM">11:30 AM</option>

                    <option value="12:00 AM">12:00 AM</option>

                    <option value="12:30 AM">12:30 AM</option>

                    <option value="01:00 PM">01:00 PM</option>

                    <option value="01:30 PM">01:30 PM</option>

                    <option value="02:00 PM">02:00 PM</option>

                    <option value="02:30 PM">02:30 PM</option>

                    <option value="03:00 PM">03:00 PM</option>

                    <option value="03:30 PM">03:30 PM</option>

                    <option value="04:00 PM">04:00 PM</option>

                    <option value="04:30 PM">04:30 PM</option>

                    <option value="05:00 PM">05:00 PM</option>

                    <option value="05:30 PM">05:30 PM</option>

                    <option value="06:00 PM">06:00 PM</option>

                    <option value="06:30 PM">06:30 PM</option>

                    <option value="07:00 PM">07:00 PM</option>

                    <option value="07:30 PM">07:30 PM</option>

                    <option value="08:00 PM">08:00 PM</option>

                    <option value="08:30 PM">08:30 PM</option>

                    <option value="09:00 PM">09:00 PM</option>

                    <option value="09:30 PM">09:30 PM</option>

                    <option value="10:00 PM">10:00 PM</option>

                    <option value="10:30 PM">10:30 PM</option>

                    <option value="11:00 PM">11:00 PM</option>

                    <option value="11:30 PM">11:30 PM</option>

                    <option value="12:00 PM">12:00 PM</option>

                    <option value="12:30 PM">12:30 PM</option>

                    <option value="12:00 AM">12:00 AM </option>
                  </select>
                </div>

                <div className="addimgeDivHsp">
                  <img
                    src={AddImg}
                    alt="Add"
                    onClick={() => this.addConsultingTime()}
                  />
                </div>
              </div>

              {this.props.hospitalConsultingTimeEdit.map((Obj, i) => {
                return (
                  <div className="hsptaltimeHead">
                    <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                      <label className="educationdoctrlabelwidth tmelabel-hspadmin">
                        Timing From <span className="hspifoMantory"></span>
                      </label>

                      <select
                        name={"consultingTimeFrom" + i}
                        id={"consultingTimeFrom" + i}
                        value={Obj.consultingTimeFrom}
                        onChange={(e) =>
                          this.setConsultingData(
                            e,
                            "consultingTimeFrom" + i,
                            i,
                            "consultingTimeFrom"
                          )
                        }
                      >
                        <option selected="From">From</option>

                        <option value="01:00 AM">01:00 AM</option>

                        <option value="01:30 AM">01:30 AM</option>

                        <option value="02:00 AM">02:00 AM</option>

                        <option value="02:30 AM">02:30 AM</option>

                        <option value="03:00 AM">03:00 AM</option>

                        <option value="03:30 AM">03:30 AM</option>

                        <option value="04:00 AM">04:00 AM</option>

                        <option value="04:30 AM">04:30 AM</option>

                        <option value="05:00 AM">05:00 AM</option>

                        <option value="05:30 AM">05:30 AM</option>

                        <option value="06:00 AM">06:00 AM</option>

                        <option value="06:30 AM">06:30 AM</option>

                        <option value="07:00 AM">07:00 AM</option>

                        <option value="07:30 AM">07:30 AM</option>

                        <option value="08:00 AM">08:00 AM</option>

                        <option value="08:30 AM">08:30 AM</option>

                        <option value="09:00 AM">09:00 AM</option>

                        <option value="09:30 AM">09:30 AM</option>

                        <option value="10:00 AM">10:00 AM</option>

                        <option value="10:30 AM">10:30 AM</option>

                        <option value="11:00 AM">11:00 AM</option>

                        <option value="11:30 AM">11:30 AM</option>

                        <option value="12:00 AM">12:00 AM</option>

                        <option value="12:30 AM">12:30 AM</option>

                        <option value="01:00 PM">01:00 PM</option>

                        <option value="01:30 PM">01:30 PM</option>

                        <option value="02:00 PM">02:00 PM</option>

                        <option value="02:30 PM">02:30 PM</option>

                        <option value="03:00 PM">03:00 PM</option>

                        <option value="03:30 PM">03:30 PM</option>

                        <option value="04:00 PM">04:00 PM</option>

                        <option value="04:30 PM">04:30 PM</option>

                        <option value="05:00 PM">05:00 PM</option>

                        <option value="05:30 PM">05:30 PM</option>

                        <option value="06:00 PM">06:00 PM</option>

                        <option value="06:30 PM">06:30 PM</option>

                        <option value="07:00 PM">07:00 PM</option>

                        <option value="07:30 PM">07:30 PM</option>

                        <option value="08:00 PM">08:00 PM</option>

                        <option value="08:30 PM">08:30 PM</option>

                        <option value="09:00 PM">09:00 PM</option>

                        <option value="09:30 PM">09:30 PM</option>

                        <option value="10:00 PM">10:00 PM</option>

                        <option value="10:30 PM">10:30 PM</option>

                        <option value="11:00 PM">11:00 PM</option>

                        <option value="11:30 PM">11:30 PM</option>

                        <option value="12:00 PM">12:00 PM</option>

                        <option value="12:30 PM">12:30 PM</option>

                        <option value="12:00 AM">12:00 AM </option>
                      </select>
                    </div>

                    <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                      <label className="educationlabelwidthyear tmelabel-hspadmin-to">
                        Timing To
                      </label>

                      <select
                        name={"consultingTimeTo" + i}
                        id={"consultingTimeTo" + i}
                        value={Obj.consultingTimeTo}
                        onChange={(e) =>
                          this.setConsultingData(
                            e,
                            "consultingTimeTo" + i,
                            i,
                            "consultingTimeTo"
                          )
                        }
                      >
                        <option selected="From">To</option>

                        <option value="01:00 AM">01:00 AM</option>

                        <option value="01:30 AM">01:30 AM</option>

                        <option value="02:00 AM">02:00 AM</option>

                        <option value="02:30 AM">02:30 AM</option>

                        <option value="03:00 AM">03:00 AM</option>

                        <option value="03:30 AM">03:30 AM</option>

                        <option value="04:00 AM">04:00 AM</option>

                        <option value="04:30 AM">04:30 AM</option>

                        <option value="05:00 AM">05:00 AM</option>

                        <option value="05:30 AM">05:30 AM</option>

                        <option value="06:00 AM">06:00 AM</option>

                        <option value="06:30 AM">06:30 AM</option>

                        <option value="07:00 AM">07:00 AM</option>

                        <option value="07:30 AM">07:30 AM</option>

                        <option value="08:00 AM">08:00 AM</option>

                        <option value="08:30 AM">08:30 AM</option>

                        <option value="09:00 AM">09:00 AM</option>

                        <option value="09:30 AM">09:30 AM</option>

                        <option value="10:00 AM">10:00 AM</option>

                        <option value="10:30 AM">10:30 AM</option>

                        <option value="11:00 AM">11:00 AM</option>

                        <option value="11:30 AM">11:30 AM</option>

                        <option value="12:00 AM">12:00 AM</option>

                        <option value="12:30 AM">12:30 AM</option>

                        <option value="01:00 PM">01:00 PM</option>

                        <option value="01:30 PM">01:30 PM</option>

                        <option value="02:00 PM">02:00 PM</option>

                        <option value="02:30 PM">02:30 PM</option>

                        <option value="03:00 PM">03:00 PM</option>

                        <option value="03:30 PM">03:30 PM</option>

                        <option value="04:00 PM">04:00 PM</option>

                        <option value="04:30 PM">04:30 PM</option>

                        <option value="05:00 PM">05:00 PM</option>

                        <option value="05:30 PM">05:30 PM</option>

                        <option value="06:00 PM">06:00 PM</option>

                        <option value="06:30 PM">06:30 PM</option>

                        <option value="07:00 PM">07:00 PM</option>

                        <option value="07:30 PM">07:30 PM</option>

                        <option value="08:00 PM">08:00 PM</option>

                        <option value="08:30 PM">08:30 PM</option>

                        <option value="09:00 PM">09:00 PM</option>

                        <option value="09:30 PM">09:30 PM</option>

                        <option value="10:00 PM">10:00 PM</option>

                        <option value="10:30 PM">10:30 PM</option>

                        <option value="11:00 PM">11:00 PM</option>

                        <option value="11:30 PM">11:30 PM</option>

                        <option value="12:00 PM">12:00 PM</option>

                        <option value="12:30 PM">12:30 PM</option>

                        <option value="12:00 AM">12:00 AM </option>
                      </select>
                    </div>

                    <div className="addimgeDivHsp">
                      <img
                        src={Closebtn}
                        alt="Close"
                        onClick={() =>
                          this.removeConsultingTime(Obj.consultingTimeTo)
                        }
                        width="20"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        </Suspense>

        <Suspense fallback={<div></div>}></Suspense>
      </div>
    );
  }
}
