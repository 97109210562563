import React, { Component } from 'react';
import { apiCalling } from "../apiService";
import $ from "jquery";

export default class Achievements extends Component {

  constructor(props) {
    super(props);

    this.state = {

      achievementList: [],
      achievementCategory: []
    }

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {

    const getData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "masterDetails",
      }),
    };
    apiCalling(getData).then((data) => {
      if (data.success === "1") {
        this.setState({ achievementList: data.masterData[0].achievementData });
      }
     // console.log("iii",this.state.achievementList)
    });
    this.getDetails();
    //$("#saveData").hide();
  }

  getDetails() {

    const getDetails = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDoctorDetails",
        siteId: window.sessionStorage.getItem("siteId"),
        doctorId: window.sessionStorage.getItem("userId"),
      }),
    }
    apiCalling(getDetails).then((data) => {
      if (data.success === "1"   &&  data.doctorData[0].achieveMentData.length > 0) {        
        this.setState({ achievementCategory: data.doctorData[0].achieveMentData});
      }
     // console.log("achievementCategory",this.state.achievementCategory)
    });
  }
    // let achievementsData = this.props.achievementsData
    // //console.log("iiiiii", achievementsData)
    // achievementsData.map((obj) => {
    //   var data = {
    //     "achieveMentId": obj.achieveMentId,
    //     "achieveTypeName": obj.achieveTypeName,
    //     "description": obj.description
    //   }
    //   this.state.achievementCategory.push(data)
    //   this.setState({ achievementCategory: this.state.achievementCategory })
    // })

  saveHandle = () => {

    var achievement = "";
    var descriptionCat = "";
    var catAppnddData = [];
    var appendData = [];

    for (var j = 0; j < this.state.achievementCategory.length; j++) {
      var a = this.state.achievementCategory[j].achievementTypeId
      catAppnddData.push(a)
      achievement = catAppnddData.toString().replace(/[\[\]']+/g, '')
      var b = this.state.achievementCategory[j].description
      appendData.push(b)
      descriptionCat = appendData.toString().replace(/[\[\]']+/g, '')
    }
    let errormessage="";
      if ($("#achievementsxxx").val() === "0" && achievement.length === 0) {
        errormessage="Please Select the Required Type"
        $("#achievementsxxx").focus();
      } else if ($("#descriptionxx").val()==="" && descriptionCat.length === 0) {
        errormessage="Please Enter the Description"
        $("#descriptionxx").focus();
      } else {  
        }
    
    
      if(errormessage===""){
    const saveData = {
      method: 'POST',
      body: JSON.stringify({
        functionName: "updateDoctorDetails",
        siteId: window.sessionStorage.getItem("siteId"),
        doctorId: window.sessionStorage.getItem("userId"),
        emailId: window.sessionStorage.getItem("emailId"),
        subFunctionName: "updateAchievementDetails",
        achievementDescription: descriptionCat,
        achievementTypeId: achievement,
      })
    }
    $(".loader").show();
    apiCalling(saveData).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
        alert("Details updated successfully")
        window.location.reload(false);
      }
     
    })
    }
    else{
      $("#messageAchievementEdit").text(errormessage);
    
      $("#messageAchievementEdit").css("color", "red");
        }
  }

  handleChange = (event) => {

    const { value, name } = event.target;
    this.setState({
      [name]: value,
    })
    //console.log(value);
  }

  handleAppend() {
    let errormessage="";
    if ($("#achievementsxxx").val() === "0") {
      errormessage="Please Select the Required Type"
      $("#achievementsxxx").focus();
    } else if ($("#descriptionxx").val()==="") {
      errormessage="Please Enter the Description"
      $("#descriptionxx").focus();
    } else {
    }
    if(errormessage===""){
    var data = {
      "achievementTypeId": $("#achievementsxxx").val(),
      "achieveTypeName": $("#achievementsxxx option:selected").text(),
      "description": $("#descriptionxx").val()
    }
    $('#achievementsxxx').prop('selectedIndex',0);
    $('#descriptionxx').val("");
    
    this.state.achievementCategory.push(data)
    this.setState({ achievementCategory: this.state.achievementCategory })
     //console.log('..........', this.state.achievementCategory)
    $("#saveData").show();
  }
  else{
    $("#messageAchievementEdit").text(errormessage);
  
    $("#messageAchievementEdit").css("color", "red");
      }
  }

  categoryRemove(index) {

    const list = this.state.achievementCategory;
    list.splice(index, 1)
    this.setState({ achievementCategory: list })
    //console.log("....", this.state.achievementCategory)
  }

  render() {
    return (
      <div class="row px-2">
          <div class="col-sm-12">
        <div class="col-sm-12 my-2">
            <p id="messageAchievementEdit" style={{textAlign:'center'}}></p>
            </div>
      </div>
        <div class="col-sm-6 px-0  px-sm-3">
          <div class="row my-2">
            <label class="col-sm-12 label-text">Type<span className="star-red">*</span></label>
            <div class="col-sm-12  px-0  px-sm-3">
              <select class="input-design"
                id="achievementsxxx"
                name="achievements"
              >
                <option value="0">Select </option>
                {this.state.achievementList.map((obj) => (
                  <option value={obj.achievementId}>{obj.achievementName}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div class="col-sm-12  px-0  px-sm-3">
          <div class="row my-2">
            <label class="col-sm-12 label-text">Description<span className="star-red">*</span></label>
            <div class="col-sm-12  px-0  px-sm-3">
              <textarea class="input-design textarea" style={{ fontSize: "14px" }} placeholder="Description" id="descriptionxx" name="description" onChange={this.handleChange} ></textarea>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table" id="tblLocation">
            <thead>
              <tr>
                <th class="">Achievement Type</th>
                <th class=""> Description</th>
              </tr>
            </thead>
            <tbody>
              {this.state.achievementCategory.map((data, index) => (
                <tr>
                  <td class="" id={index}>
                    <input disabled="disabled" placeholder="Certifications" type="text" value={data.achieveTypeName} class="input-design" />
                  </td>
                  <td class="" id={index}>
                    <input disabled="disabled" type="text" class="input-design" value={data.description} style={{ width: "80%" }} />
                    <span class=" fa fa-trash color-maintext ml-3" onClick={(e) => this.categoryRemove(index)}  ></span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="col-sm-12">
          <input type="submit" value="Add" className="formButton" onClick={() => this.handleAppend()} ></input>
          <input type="submit" value="Save" className="formButton" id="saveData" onClick={() => this.saveHandle()}></input>
        </div>
      </div>
    )
  }
}
