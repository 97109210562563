import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import Close from "../.././image/icons/close-mark.png"


export default class addHeaderLinkPopup extends Component {
  state = {
    headerLinksId:
      this.props.headerDeatils != ""
        ? this.props.headerDeatils.headerLinkId
        : "",
    url: this.props.headerDeatils != "" ? this.props.headerDeatils.url : "",
    name: this.props.headerDeatils != "" ? this.props.headerDeatils.name : "",
    editorState: EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(
          this.props.headerDeatils != "" ? this.props.headerDeatils.content : ""
        )
      )
    ),
    content:
      this.props.headerDeatils != "" ? this.props.headerDeatils.content : "",
  };

  changeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  cancelPopup() {
    this.props.showAddHeaderLinkPopup(false);
  }

  onContentChange = (editorState) => {
    let content = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    this.setState({ content, editorState });
  };

  handleSave = () => {
    if (this.state.name == "") {
      alert("Name required.");
    }
    else{
      this.props.saveHeaderLink(this.state)
    }
  };

  render() {
    const { editorState } = this.state;
    return (
      <div class="popup-overlay headerPopup" style={{ display: "block" }}>
        <div className="popup-contentDiv">
          <div className="modal-popup">
            <span
              className="closepopupqual"
              onClick={() => this.cancelPopup()}
            >
              <img src={Close} alt="Close" className="img-fluid" width="25" />
              {/* &times;  */}
            </span>
            <div className="header border-0">Add Header link</div>
            <div className="content">
              <div class="row">
                <div class="row-margin col-md-6">
                  <label class="col-md-12 label-text">
                    Name <span className="hspifoMantory">*</span>
                  </label>
                  <div class="col-md-12">
                    <input
                      type="text"
                      class="qualifcatin-popupInput"
                      id="name"
                      name="name"
                      value={this.state.name}
                      onInput={this.changeHandler}
                    />
                  </div>
                </div>
                <div class="row-margin col-md-6">
                  <label class="col-md-12 label-text">URL</label>
                  <div class="col-md-12">
                    <input
                      type="text"
                      class="qualifcatin-popupInput"
                      id="url"
                      name="url"
                      value={this.state.url}
                      onInput={this.changeHandler}
                    />
                  </div>
                </div>
                <div class="row-margin col-md-12">
                  <label class="col-md-12 label-text">HTML Content</label>
                  <div class="col-md-12">
                    <Editor
                      editorState={this.state.editorState}
                      initialEditorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="redactor-wrapper"
                      editorClassName="editorClassName"
                      onEditorStateChange={this.onContentChange}
                      handlePastedText={() => false}
                    />
                  </div>
                </div>
              </div>
              <div className="text-center">
                <span
                  className="sitesetng-svebtn"
                  onClick={this.handleSave}
                >
                  Save
                </span>
                <input
                  type="button"
                  value="cancel"
                  className="sitesetng-cancelbtn"
                  onClick={() => this.cancelPopup()}
                ></input>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    );
  }
}
