import React, { Component } from "react";
import $ from "jquery";
import acceptCallImg from "../image/icons/accepts.png";
import declineCallImg from "../image/icons/decline.png";
import { apiCalling } from "../apiService";
// import InnerHTML from "dangerously-set-html-content";
// import sound from "../Js/pomodoro-times-up.mp3";
import sound from "../Js/You-Have-A-Call.mp3";
let countRing = 0;
export default class PatientMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointments: [],
      doctorInitiatedAppointment: [],
      videoAppointments: [],
      doctorId: "",
      hospitalId: "",
      doctorVideoScheduleId: "",
      isSessionBased: "",
      isFree: "",
      memberId: "",
      sessionChatNo: "",
      scheduled_time_slot_id: "",
    };
  }

  componentDidMount() {
    if (window.sessionStorage.getItem("role") != "ROLE_ADMIN")
      setInterval(async () => {
        this.getDataFromAPI();
      }, 5000);

    $("#decline").click(function () {
      $("#term-mainNot").hide();
    });
    $(".popup-hide").click(function () {
      $(".popup-overlay").hide();
    });
  }
  AcceptCall() {
    $(".loader").show();
    $("#term-mainNot").hide();
    this.chatNowFinal(
      $("#doctorId_n").val(),
      $("#hospitalId_n").val(),
      $("#doctorVideoScheduleId_n").val(),
      $("#isSessionBased_n").val(),
      $("#isFree_n").val(),
      $("#memberId_n").val(),
      $("#sessionChatNo_n").val(),
      $("#scheduled_time_slot_id_n").val()
    );
  }
  disconnectVideoCall(
    scheduleTimeSlotId,
    vcPatientId,
    vcHospitalId,
    vcDoctorVideoScheduleId,
    vcDoctorId,
    vcSessionChatNo
  ) {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "disconnectVideoCall",
        patientId: vcPatientId,
        siteId: window.sessionStorage.getItem("siteId"),
        billingEndTime: new Date(),
        hospitalId: vcHospitalId,
        doctorId: vcDoctorId,
        type: "completed",
        doctorVideoScheduleId: vcDoctorVideoScheduleId,
        scheduleTimeSlotId: scheduleTimeSlotId,
        isSessionVideoEnded: "false",
        sessionChatNo: vcSessionChatNo,
        chatComments: "",
        bundleId: "",
      }),
    };
    //console.log(apiJson.body)
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
      }
    });
  }
  chatNow(
    doctorId,
    hospitalId,
    doctorVideoScheduleId,
    isSessionBased,
    isFree,
    memberId,
    sessionChatNo,
    scheduled_time_slot_id
  ) {
    this.checkingAcceptCall(
      doctorId,
      hospitalId,
      doctorVideoScheduleId,
      isSessionBased,
      isFree,
      memberId,
      sessionChatNo,
      scheduled_time_slot_id
    );
  }

  chatNowFinal(
    doctorId,
    hospitalId,
    doctorVideoScheduleId,
    isSessionBased,
    isFree,
    memberId,
    sessionChatNo,
    scheduled_time_slot_id
  ) {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "acceptCall",
        doctorVideoScheduleId: doctorVideoScheduleId,
        patientId: memberId,
        hospitalId: hospitalId,
        doctorId: doctorId,
        videoChatRoomId: sessionChatNo,
        siteId: window.sessionStorage.getItem("siteId"),
        bundleId: "",
      }),
    };
    //alert(sessionChatNo);
    // console.log(apiJson.body);

    window.sessionStorage.setItem("vcPatientId", memberId);
    window.sessionStorage.setItem(
      "vcDoctorVideoScheduleId",
      doctorVideoScheduleId
    );
    window.sessionStorage.setItem("vcHospitalId", hospitalId);
    window.sessionStorage.setItem("vcDoctorId", doctorId);
    window.sessionStorage.setItem(
      "vcScheduledTimeSlotId",
      scheduled_time_slot_id
    );
    window.sessionStorage.setItem("vcSessionChatNo", sessionChatNo);

    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        window.sessionStorage.setItem("apiKey", data.result[0].apiKey);
        window.sessionStorage.setItem("sessionId", data.result[0].sessionId);
        window.sessionStorage.setItem("token", data.result[0].token);
        window.sessionStorage.setItem("startChatData", JSON.stringify(data));
        window.sessionStorage.setItem(
          "vc_showName1",
          data.result[0].loggedinDisplayName
        );

        window.sessionStorage.setItem(
          "vc_showName2",
          data.result[0].specialization
        );
        window.sessionStorage.setItem(
          "vc_showName3",
          data.result[0].qualification
        );
        window.sessionStorage.setItem("drcallType", data.result[0].calltype);
        //window.sessionStorage.setItem("chatGroupData", data.result[0].chatGroup);
        //window.location.href = "/patient/Videocall";
        window.location.href = "/doctor/videocall";
      } else {
        alert(data.errorMessage);
        $(".loader").hide();
      }
    });
  }

  checkingAcceptCall(
    doctorId,
    hospitalId,
    doctorVideoScheduleId,
    isSessionBased,
    isFree,
    memberId,
    sessionChatNo,
    scheduled_time_slot_id
  ) {
    $("#doctorId_n").val(doctorId);
    $("#hospitalId_n").val(hospitalId);
    $("#doctorVideoScheduleId_n").val(doctorVideoScheduleId);
    $("#isSessionBased_n").val(isSessionBased);
    $("#isFree_n").val(isFree);
    $("#memberId_n").val(memberId);
    $("#sessionChatNo_n").val(sessionChatNo);
    $("#scheduled_time_slot_id_n").val(scheduled_time_slot_id);

    // $("#term-mainNot").show();
    this.AcceptCall();
  }
  getDataFromAPI() {
    if (window.sessionStorage.getItem("isLogin") === "true") {
      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "videoAudioAppointment",
          userId: window.sessionStorage.getItem("userId"),
          siteId: window.sessionStorage.getItem("siteId"),
        }),
      };
      apiCalling(apiJson).then((data) => {
        if (data.success === "1") {
          $(".loader").hide();
          this.setState({
            doctorInitiatedAppointment: data.doctorInitiatedAppointment,
            videoAppointments: data.videoAppointments,
            appointments: data.appointments,
          });
          for (let i = 0; i < data.doctorInitiatedAppointment.length; i++) {
            if (data.doctorInitiatedAppointment[i].chatInitiated === true) {
              countRing = countRing === 0 ? 1 : 2;
              this.playAudio();
              break;
            }
          }
          // if (
          //   data.doctorInitiatedAppointment.length !== 0 &&
          //   data.appointments.length !== 0
          // ) {
          //   countRing = countRing === 0 ? 1 : 2;
          //   // this.playAudio();
          // }
        }
      });
    }
  }
  ringingTone() {
    // alert(countRing);
    // if (countRing === 1) {
    //  this.playAudio();
    //alert();
    // alert("ringing");
    // countRing = 2;
    // var audioPlayer = document.getElementById("alertAud");
    // audioPlayer.volume = 0.9;
    // audioPlayer.play();
    // console.log("");
    // }
  }
  playAudio() {
    const audioEl = document.getElementsByClassName("audio-element")[0];
    audioEl.play();
  }
  closeAlert() {
    $("#alertPopup").hide();
  }
  render() {
    return (
      <div>
        <input type="hidden" id="hospitalId_n" />
        <input type="hidden" id="doctorId_n" />
        <input type="hidden" id="doctorVideoScheduleId_n" />
        <input type="hidden" id="isSessionBased_n" />
        <input type="hidden" id="isFree_n" />
        <input type="hidden" id="memberId_n" />
        <input type="hidden" id="sessionChatNo_n" />
        <input type="hidden" id="scheduled_time_slot_id_n" />
        {this.state.doctorInitiatedAppointment.map((obj) => {
          let showStatus = "show";
          // $("#buttonRing").click();
          if (obj.chatInitiated === true) {
            this.playAudio();
          }
          return (
            <>
              {obj.chatInitiated === true ? (
                <div className="notificationCallMain">
                  <div className="alert alert-success">
                    <span>
                      Doctor calling...
                      <span className="notDrName">
                        Dr.{obj.drFirstName} {obj.drLastName}
                      </span>
                      <div className="col-lg-12 d-flex mt-2">
                        <img
                          src={declineCallImg}
                          className="mr-3 blink-notfctn declineImg"
                          alt=""
                          onClick={() =>
                            this.disconnectVideoCall(
                              obj.scheduled_time_slot_id,
                              obj.member_id,
                              obj.hospitalId,
                              obj.doctorVideoScheduleId,
                              obj.doctorId,
                              obj.room
                            )
                          }
                        ></img>
                        <img
                          src={acceptCallImg}
                          className="mr-3 ml-5 blink-notfctn acceptImg"
                          alt=""
                          title="Accept call (start chat)"
                          onClick={() =>
                            this.chatNow(
                              obj.doctorId,
                              obj.hospitalId,
                              obj.doctorVideoScheduleId,
                              obj.isSessionBased,
                              obj.isFree,
                              obj.member_id,
                              obj.room,
                              obj.scheduled_time_slot_id
                            )
                          }
                        ></img>
                      </div>
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          );
        })}
        {/* {this.state.appointments.map((obj) => {
          let showStatus = "hide";
          let roomId = "";
          if (
            obj.isActive === 1 &&
            obj.isLogged === 1 &&
            obj.appointmentStatus === 2 &&
            obj.isDoctorInAnotherChat === 0
          ) {
            this.state.videoAppointments.map((vid, vin) => {
              if (
                obj.schStartTime === vid.startTime &&
                obj.schEndTime === vid.endTime &&
                vid.active === true
              ) {
                showStatus = "show";
                roomId = vid.room;
              }
            });
          }
          if (showStatus === "show") {
            this.playAudio();
          }
          return (
            <>
             {showStatus === "show" ? (
            <div className="notificationCallMain">
             
                <div className="alert alert-success">
                  <span>
                    Doctor calling...
                    <span className="notDrName">
                      Dr.{obj.doctorFirstName} {obj.doctorLastName}
                    </span>
                    <div className="col-lg-12">
                      <img
                        src={declineCallImg}
                        className="mr-3 blink-notfctn declineImg"
                        alt=""
                        onClick={() => this.disconnectVideoCall(obj.scheduledTimeSlotId,obj.memberId,obj.hospitalId,obj.doctorVideoScheduleId,obj.doctorId,roomId)}
                      ></img>
                      <img
                        src={acceptCallImg}
                        className="mr-3 blink-notfctn"
                        alt=""
                        id="notoficationAcceptCall"
                        title="Accept call (start chat)"
                        onClick={() =>
                          this.chatNow(
                            obj.doctorId,
                            obj.hospitalId,
                            obj.doctorVideoScheduleId,
                            obj.isSessionBased,
                            obj.isFree,
                            obj.memberId,
                            roomId,
                            obj.scheduledTimeSlotId
                          )
                        }
                      ></img>
                    </div>
                  </span>
                </div>
              
            </div>
) : (
  ""
)}
            </>
          );
        })} */}
        <div>
          <button
            onClick={() => this.playAudio()}
            id="buttonRing"
            style={{ display: "none" }}
          >
            <span>Play Audio</span>
          </button>
          <audio className="audio-element" allow="autoplay">
            <source src={sound}></source>
          </audio>
        </div>

        <div class="popup-overlay" id="alertPopup" style={{ display: "none" }}>
          <div className="popup-content">
            <div className="modal-popup text-center">
              <a
                className="close-popup  popup-hide"
                onClick={() => this.closeAlert()}
              >
                &times;
              </a>
              <p className="mb-2" id="alertPopupTitle"></p>
              <p
                className="p-0"
                style={{ fontSize: "85%" }}
                id="alertPopupSubTitle"
              ></p>
              <p>
                <button
                  class="formButtonBg mr-2"
                  onClick={() => this.closeAlert()}
                >
                  Ok
                </button>
                {/* <button
                  class="formButtonBg cancelButtonBg popup-hide"
                  onClick={() => this.closeAlert()}
                >
                  Cancel
                </button> */}
              </p>
            </div>
          </div>
        </div>
        <div
          class="popup-overlay"
          id="term-mainNot"
          style={{ display: "none" }}
        >
          <div className="popup-content">
            <div className="modal-popup">
              <span className="close-popup  popup-hide">&times;</span>
              <div className="header border-0"> Terms and Conditions</div>
              <div className="content">
                <div className="col-md-12 text-center">
                  <p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          window.sessionStorage.getItem("video_chat_terms") ===
                          ""
                            ? `<span className="text-center d-block">
                            No, Terms and Condition
                          </span>`
                            : window.sessionStorage.getItem("video_chat_terms"),
                      }}
                    />
                  </p>
                  <span>
                    <input
                      type="button"
                      value="Accept"
                      className="formButton formButton-sm"
                      id="AcceptCall"
                      onClick={() => this.AcceptCall()}
                    />
                  </span>
                  <span style={{ display: "none" }}>
                    <input type="checkbox" id="invalidCheckNot" />
                  </span>
                  <span>
                    <input
                      type="button"
                      value="Decline"
                      className="formButton-sm cancelButton"
                      id="decline"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
