import React, { Component, Suspense } from "react";
import Doctorinformation from "./doctorinformation";
import DoctorOffcicehours from "./doctorOffcicehours";
import DoctorSpecialisation from "./doctorSpecialisation";
import EducationDetails from "./educationDetails";
import Achievementsdetails from "./achievementsdetails";
import Proceduredetails from "./proceduredetails";
import OfficialInformation from "./officialInformation";
import SearchOffceService from "./searchoffceservice";
import Doctorseo from "./doctorseo";
import DoctorPhoto from "./doctorPhoto";
import { apiCalling } from "../../apiService";
import $ from "jquery";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link } from "react-router-dom";
import Header from "../../Reliance/header";
import Footer from "../../Reliance/footer";
const folderName = window.sessionStorage.getItem("homeName");
//const Header = React.lazy(() => import("../../" + folderName + "/header"));
//const Footer = React.lazy(() => import("../../" + folderName + "/footer"));

export default class AddnewHsp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      languageData: [],
      timeFilterData: [],
      dateOfBirth: "",
      doctorId: this.props.location.state.doctorId,
      doctorInformationArray: [],
      doctorDataArray: [],
      educationData: [],
      achievementsData: [],
      proceduresData: [],
      doctorOfficeHours: [],
      isData: 0,
      languageEdit: [],
      associations: [],
      specialisationEdit: [],
      statementProf: "",
    };
    this.editDoctorData = this.editDoctorData.bind(this);
    this.proffesionalStatementSetting =
      this.proffesionalStatementSetting.bind(this);
    this.saveDoctor = this.saveDoctor.bind(this);
  }
  componentDidMount() {
    if (
      typeof this.props.location.state !== "undefined" &&
      this.props.location.state.doctorId !== ""
    ) {
      if (this.props.location.state.doctorId !== "") {
        this.editDoctorData(this.state.doctorId);
      }
    } else {
      this.setState({
        isData: 1,
      });
    }
  }

  editDoctorData(doctorId) {
    $(".loader").show();
    $("#btnSave").hide();
    $("#btnNewSave").hide();
    $("#btnUpdate").show();
    $("#btnNewUpdate").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "editDoctorDetails",
        doctorId: doctorId,
      }),
    };

    apiCalling(apiJson).then((data) => {
      var doctorOfficeHrs = [];
      var schedule = [];
      let doctorScheduleData =
        data.doctorScheduleData == undefined ? [] : data.doctorScheduleData;
      let languages = data.languages == undefined ? [] : data.languages;
      let associationData =
        data.associationData == undefined ? [] : data.associationData;

      for (var i = 0; i < doctorScheduleData.length; i++) {
        var s = {
          sundayTo: doctorScheduleData[i].sundayTo,
          sundayFrom: doctorScheduleData[i].sundayFrom,
          mondayTo: doctorScheduleData[i].mondayTo,
          mondayFrom: doctorScheduleData[i].mondayFrom,
          tuesdayTo: doctorScheduleData[i].tuesdayTo,
          tuesdayFrom: doctorScheduleData[i].tuesdayFrom,
          wednesdayTo: doctorScheduleData[i].wednesdayTo,
          wednesdayFrom: doctorScheduleData[i].wednesdayFrom,
          thursdayTo: doctorScheduleData[i].thursdayTo,
          thursdayFrom: doctorScheduleData[i].thursdayFrom,
          fridayTo: doctorScheduleData[i].fridayTo,
          fridayFrom: doctorScheduleData[i].fridayFrom,
          saturdayTo: doctorScheduleData[i].saturdayTo,
          saturdayFrom: doctorScheduleData[i].saturdayFrom,
        };
        schedule.push(s);

        let obj = data.doctorData[0].hospitalData.filter(
          (o) => o.hospitalId === doctorScheduleData[i].hospitalId
        );

        if (i % 2 === 1) {
          var a = {
            hospitalId: doctorScheduleData[i].hospitalId,
            hasOwner: obj[0].hasOwner,
            schedule: schedule,
          };
          doctorOfficeHrs.push(a);
          schedule = [];
        }
      }

      let languageEdit = [];
      for (let i = 0; i < languages.length; i++) {
        languageEdit.push(languages[i].Id);
      }

      let associationEdit = [];
      for (let i = 0; i < associationData.length; i++) {
        associationEdit.push(associationData[i].associationId);
      }

      if (data.success === "1") {
        this.setState(
          {
            languageEdit: languageEdit,
            associations: associationEdit,
            doctorOfficeHours: doctorOfficeHrs,
            doctorDataArray:
              data.doctorData == undefined ? [] : data.doctorData,
            educationData: data.education == undefined ? [] : data.education,
            achievementsData:
              data.achievements == undefined ? [] : data.achievements,
            proceduresData: data.procedures == undefined ? [] : data.procedures,
            specialisationEdit:
              data.specialization == undefined ? [] : data.specialization,
            doctorId:
              data.doctorData == undefined ? "" : data.doctorData[0].doctorId,
            // associationData:data.associationData,
            isData: 1,
          },
          () => {
            let dta = this.state.doctorDataArray;
            if (dta.length > 0) {
              window.sessionStorage.setItem("seoDoctorId", dta[0].doctorId);
              window.sessionStorage.setItem("seoTitle", dta[0].doctorTitle);
              window.sessionStorage.setItem(
                "seoDisplaytName",
                dta[0].doctorFirstName + dta[0].doctorLastName
              );
              window.sessionStorage.setItem("seoState", dta[0].state);
            }
            $(".loader").hide();
          }
        );
      }
    });
  }

  isEmail = (email) => {
    var isMailOk = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
      email
    );
    return isMailOk;
  };

  saveDoctor() {
    var specialisations = this.refs.specialisationElement.specialisations();
    var educations = this.refs.educationElements.education();
    var achievementsarray = this.refs.achievementsElements.achievements();
    var procedurearray = this.refs.procedureElements.procedures();
    var officeHoursArray = this.refs.officeHoursElements.officeHourse();
    var servicesArray = this.refs.officeServiceElements.searchOfficeServices();
    var doctorInformationImage =
      this.refs.doctorInformationElement.doctorinformation();
    var officialInfoDrImage =
      this.refs.officialInformationElement.officialInformationData();
    if (this.state.doctorId !== "") {
      var doctorPhoto = this.refs.doctorPhotoElement.doctorPhoto();
    } else {
      var doctorPhoto = "";
    }

    var flag = true;
    var email = $("#txtEmail").val();
    if ($("#txtFirstName").val() === "") {
      alert("First Name Required");
      setTimeout(() => {
        $("#txtFirstName").focus();
      }, 20);
      flag = false;
    } else if ($("#cmbTitle").val() === "") {
      alert("Title Required");
      $("#cmbTitle").focus();
      flag = false;
    } else if (
      $("#rdoMale").is(":checked") === false &&
      $("#rdoFemale").is(":checked") === false
    ) {
      alert("Gender Required");
      $("#rdoMale").focus();
      flag = false;
    } else if ($("#txtPassword").val() === "") {
      alert("Password Required");
      $("#txtPassword").focus();
      flag = false;
    } else if ($("#txtPassword").val().length < 6) {
      alert("Password must contain at least 6 characters");
      $("#txtPassword").focus();
      flag = false;
    } else if ($("#txtConfirmPassword").val() === "") {
      alert("Confirm Password Required");
      $("#txtConfirmPassword").focus();
      flag = false;
    } else if ($("#txtPassword").val() !== $("#txtConfirmPassword").val()) {
      alert("Password and confirm password does not match ");
      $("#txtConfirmPassword").val("");
      $("#txtPassword").val("");
      $("#txtPassword").focus();
      flag = false;
    } else if ($("#cmbLanguage").val().length === 0) {
      alert("Select Languages");
      $("#cmbLanguage").focus();
      flag = false;
    } else if ($("#cmbWorkTimeFilter").val().length === 0) {
      alert("Select Work Time");
      $("#cmbWorkTimeFilter").focus();
      flag = false;
    } else if (officeHoursArray.officeHoursDetails.length === 0) {
      alert("Select Hospital Names");
      $("#cmbHospitalName").focus();
      flag = false;
    } else if ($("#txtEmail").val() === "") {
      alert("Doctor Email Required");
      $("#txtEmail").focus();
      flag = false;
    } else if (this.isEmail(email) === false) {
      alert("Invalid Email id");
      $("#txtEmail").focus();
      flag = false;
    } else if ($("#txtAddress").val() === "") {
      alert("Address Required");
      $("#txtAddress").focus();
      flag = false;
    } else if ($("#cmbState").val() === "0") {
      alert("State Required");
      $("#cmbState").focus();
      flag = false;
    } else if ($("#cmbCity").val() === "0") {
      alert("City Required");
      $("#cmbCity").focus();
      flag = false;
    } else if ($("#cmbCountry").val() === "0") {
      alert("Country Required");
      $("#cmbCountry").focus();
      flag = false;
    } else if ($("#txtPinCode").val() === "") {
      alert("Pin Code Required");
      $("#txtPinCode").focus();
      flag = false;
    } else if ($("#txtMobile").val() === "") {
      alert("Doctor Mobile Number Required");
      $("#txtMobile").focus();
      flag = false;
    } else if ($("#cmbMedicineBranch").val() === "") {
      alert("Medicine Branch Name Required");
      $("#cmbMedicineBranch").focus();
      flag = false;
    } else if ($("#txtProfessionalStatement").val() === "") {
      alert("Brief Description about yourself Required");
      $("#txtProfessionalStatement").focus();
      flag = false;
    } else if (educations.educationDetails.length === "0") {
      alert("Education Details Required");
      $("#cmbEducation").focus();
      flag = false;
    } else if (officeHoursArray.officeHoursDetails.length === 0) {
      alert("Hospital Details Required");
      $("#cmbEducation").focus();
      flag = false;
    }

    var specializations = specialisations.specialization;
    if (specializations.length > 0 && flag === true) {
      var isPrimary = "0";
      var multiple = 0;
      for (var i = 0; i < specializations.length; i++) {
        if (
          $("#chkPrimarySpecialisation" + specializations[i].specialityId).is(
            ":checked"
          ) === true
        ) {
          multiple = parseInt(multiple) + 1;
        }
      }
      for (var i = 0; i < specializations.length; i++) {
        if (
          $("#chkPrimarySpecialisation" + specializations[i].specialityId).is(
            ":checked"
          ) === true &&
          $("#chkSpecialisation" + specializations[i].specialityId).is(
            ":checked"
          ) === true
        ) {
          isPrimary = "1";
          flag = true;
          break;
        }
      }
      if (isPrimary === "0" && specializations.length > 0) {
        alert("Atleast one Primary Specialisation Required");
        flag = false;
      } else if (multiple > 1) {
        alert("Only one Primary Specialisation Required");
        flag = false;
        $("#chkPrimarySpecialisation" + i).focus();
      }
    }

    if (flag === true) {
      var hospitalDoctorDetails = [];
      var hospitalDetails = officeHoursArray.officeHoursDetails;
      for (var i = 0; i < hospitalDetails.length; i++) {
        if (hospitalDetails[i].hospitalId !== "") {
          var s = {
            hospitalId: hospitalDetails[i].hospitalId,
            hasOwner: hospitalDetails[i].hasOwner,
          };
          hospitalDoctorDetails.push(s);
        }
      }

      var specilisationArray = [];
      var specialization = specialisations.specialization;
      for (var i = 0; i < specialization.length; i++) {
        if (
          $("#chkSpecialisation" + specialization[i].specialityId).is(
            ":checked"
          ) === true
        ) {
          var s = {
            specializationIds: $(
              "#chkSpecialisation" + specialization[i].specialityId
            ).val(),
            isPrimarySpecialisation:
              $(
                "#chkPrimarySpecialisation" + specialization[i].specialityId
              ).is(":checked") === true
                ? "true"
                : "false",
          };
          specilisationArray.push(s);
        }
      }
      if (specilisationArray.length === 0) {
        alert("Please enter atleast One Specialization");
        setTimeout(() => {
          $("#cmbMedicineBranch").focus();
        }, 20);
        return false;
      }
      var educationArray = [];
      for (var i = 0; i < educations.educationDetails.length; i++) {
        if (educations.educationDetails[i].Id !== "") {
          var b = {
            educationId: educations.educationDetails[i].Id,
            year: educations.educationDetails[i].year,
            college: educations.educationDetails[i].college.replace(/'/g, "''"),
          };
          educationArray.push(b);
        }
      }
      if (educationArray.length === 0) {
        alert("Please enter Education Details");
        setTimeout(() => {
          $("#cmbEducation").focus();
        }, 20);
        return false;
      }
      var achievementDetailsArray = [];
      for (var i = 0; i < achievementsarray.achievementArray.length; i++) {
        if (achievementsarray.achievementArray[i].Id !== "") {
          var b = {
            achievementId: achievementsarray.achievementArray[i].Id,
            description: achievementsarray.achievementArray[i].description,
          };
          achievementDetailsArray.push(b);
        }
      }

      var procedureDetails = [];
      for (var i = 0; i < procedurearray.procedureDetailsArray.length; i++) {
        if (procedurearray.procedureDetailsArray[i].Id !== "") {
          var b = {
            procedureId: procedurearray.procedureDetailsArray[i].Id,
            minPrice: procedurearray.procedureDetailsArray[i].minPrice,
            maxPrice: procedurearray.procedureDetailsArray[i].maxPrice,
            buyPrice: procedurearray.procedureDetailsArray[i].buyPrice,
            mtMinPrice: procedurearray.procedureDetailsArray[i].mtMinPrice,
            mtMaxPrice: procedurearray.procedureDetailsArray[i].mtMaxPrice,
            mtBuyPrice: procedurearray.procedureDetailsArray[i].mtBuyPrice,
            isActive: procedurearray.procedureDetailsArray[i].isActive,
          };
          procedureDetails.push(b);
        }
      }

      let associationId = this.state.associations
        .toString()
        .replace(/[\[\]']/g, "");

      let timeFilter = $("#cmbWorkTimeFilter")
        .val()
        .toString()
        .replace(/[\[\]']/g, "");

      let languageIds = $("#cmbLanguage")
        .val()
        .toString()
        .replace(/[\[\]']/g, "");

      let seoDescription =
        $("#txtDescriptionNew").val() === ""
          ? ""
          : $("#txtDescriptionNew").val();

      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "saveDoctor",
          activeDate: $("#activeDate").val(),
          address: $("#txtAddress").val(),
          advertisementId: $("#txtAdvertisementId").val(),
          city: $("#cmbCity option:selected").text(),
          country: $("#cmbCountry option:selected").text(),
          createdBy: $("#txtCreatedBy").val(),
          createdOn: $("#txtCreatedOn").val(),
          dateOfBirth: $("#txtDateOfBirth").val(),
          deactiveDate: $("#deactiveDate").val(),
          doctorFirstName: $("#txtFirstName").val(),
          doctorLastName: $("#txtLastName").val(),
          gender: $("input:radio[name=rdoGenderType]:checked").val(),
          isAcceptOnline:
            $("#chkAcceptOnlineAppointment").is(":checked") === true
              ? "true"
              : "false",
          isAssistedEnabled: $("#cmbIsAssisted").val(),
          isBackgroundVerified: false,
          isEhrEnabled: $("#cmbIsEHREnabled").val(),
          isMedicalTourism: $("#cmbMedTourism").val(),
          isVaasEnabled: $("#cmbIsVaasEnabled").val(),
          isVideoAccountCreated: false,
          isVideoEnabled: $("#cmbIsVideoorAudio").val(),
          keyWords: $("#txtKeyword").val() === "" ? "" : $("#txtKeyword").val(),

          medicalRegNumber: $("#txtMedRegistrationNo").val(),
          medicineBranchId: specialisations.medicineBranchId,
          mobileNumber: $("#txtMobile").val(),
          modifiedBy: $("#txtModifiedBy").val(),
          modifiedOn: $("#txtModifiedOn").val(),
          phone: $("#txtPhone").val(),
          practiceStartYear: $("#cmbPracticeStartYear").val(),
          professionalStatement: $("#txtProfessionalStatement")
            .val()
            .replace(/'/g, "''"),
          ratePerMinute: 0,
          ratePerSession: 0,
          rating: $("#txtRating").val(),
          regDate: $("#registrationDate").val(),
          languageIds: languageIds,
          description: seoDescription,
          registrationstate:
            $("#cmbRegistrationState").val() === "0"
              ? ""
              : $("#cmbRegistrationState option:selected").text(),
          routeId: "22",
          searchKeywords: $("#txtAreaSearchKeyword").val(),
          seoTitle: $("#txtTitle").val() === "" ? "" : $("#txtTitle").val(),
          slug: $("#txtSlug").val() === "" ? "" : $("#txtSlug").val(),
          sortOrder: $("#txtSortOrder").val(),
          state: $("#cmbState option:selected").text(),
          subCity:
            $("#cmbSubCity").val() === "0"
              ? ""
              : $("#cmbSubCity option:selected").text(),
          timeFilter: timeFilter,
          doctorTitle: $("#cmbTitle").val(),
          doctorSignature: doctorInformationImage,
          doctorEmail: $("#txtEmail").val(),
          associationId: associationId,
          doctorImage: officialInfoDrImage,
          doctorPhoto: doctorPhoto,
          zip: $("#txtPinCode").val(),
          isExcludeFromPh: false,
          hasInitialEvaluation: false,
          isAgreeTermsAndCondition: false,
          fromNetwork: false,
          networkServiceRequest: null,
          pmPaymentDone:
            $("#chkPulsePaymentDone").is(":checked") === true
              ? "true"
              : "false",
              mobileAppOnly:  $("#chkAcceptMobileAppOnly").is(":checked") === true
              ? "true"
              : "false",
          lastFeedViewedTime: "",
          lastalertViewedTime: "",
          fromDoctorRegistration: false,
          password: $("#txtPassword").val(),
          username: $("#txtEmail").val(),
          version: 0,
          aqMakePublic: false,
          aqNumFollowUp: $("#cmbAQNumFollowUp").val(),
          aqPrice: $("#cmbAQPrice").val(),
          askQuestion: $("#cmbAskQuestion").val(),
          freeAqAvailCount: $("#txtFreeAQAvailableCount").val(),
          isFreeAq: $("#cmbIsFreeAQ").val(),
          isPhoneConsultAvail: $("#cmbPhoneConsulting").val(),
          officeDetails: servicesArray.content1.replace(/'/g, "''"),
          phoneConsultPrice: $("#txtPhoneConsultingPrice").val(),
          serviceOffered: servicesArray.content2,
          college: "",
          year: "",
          doctorId: $("#txtDoctorId").val(),
          consultationPrice: $("#txtInPersonConsultationPrice").val(),
          ispmmodeWrite: false,
          pmCalenderMode: "",
          hasInPersonAppointment: $("#cmbInPersonAppoinment").val(),
          hospitalId: $("#cmbHospitalName").val(),
          hospitalDoctorDetails: hospitalDoctorDetails,
          doctorOfficeHours: officeHoursArray.officeHoursDetails,
          isAccountLocked: $("#cmbIsAccountLocked").val(),
          isVerified: $("#cmbIsVerified").val(),
          status: $("#cmbNewStatus").val(),
          specializationIds: specilisationArray,
          educationIds: educationArray,
          achievementIds: achievementDetailsArray,
          procedures: procedureDetails,
        }),
      };
      // console.log("apiJson", apiJson.body);
      apiCalling(apiJson).then((data) => {
        if (data.success === "1") {
          if ($("#txtDoctorId").val() === "") {
            alert("Doctor Details Saved Successfully");
          } else {
            alert("Doctor Details Updated Successfully");
          }

          $("#txtCancel").click();
        } else {
          alert(data.errorMessage);
        }
      });
    }
  }
  doctorDetails() {
    $("#doctorDetailsDiv").show();
    $("#seoDetailsDiv").hide();
    $("#doctorPhotoDiv").hide();
    $("#doctorDetails").addClass("active");
    $("#seoDetails").removeClass("active");
    $("#photoDetails").removeClass("active");
  }
  seoDetails() {
    $("#doctorDetailsDiv").hide();
    $("#seoDetailsDiv").show();
    $("#doctorPhotoDiv").hide();
    $("#seoDetails").addClass("active");
    $("#photoDetails").removeClass("active");
    $("#doctorDetails").removeClass("active");
  }
  photoDetails() {
    $("#doctorDetailsDiv").hide();
    $("#seoDetailsDiv").hide();
    $("#doctorPhotoDiv").show();
    $("#photoDetails").addClass("active");
    $("#seoDetails").removeClass("active");
    $("#doctorDetails").removeClass("active");
  }
  proffesionalStatementSetting(statement1) {
    var statement = statement1;
    this.setState({
      statementProf: statement,
    });
  }
  render() {
    return (
      <div className="bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header /> <div className="loader"></div>
          </section>
        </Suspense>

        <div className="container mb-5">
          <div>
            <ul className="breadcrum-adminMangmnt">
              <li>
                <a href="/admin/dashboard">Dashboard</a>
              </li>
              <li>
                <a href="/adminManagement/doctorManagementList/doctorManagement">
                  Doctor Management
                </a>
              </li>
              <li>
                <a href="" className="current">
                  Doctor Details
                </a>
              </li>
            </ul>
          </div>

          <div className="smssetng-btn mb-4 mt-4">
            <input
              type="submit"
              value="Save"
              id="btnNewSave"
              className="smssetng-svebtn"
              onClick={() => this.saveDoctor()}
            />
            <input
              type="submit"
              style={{ display: "none" }}
              id="btnNewUpdate"
              value="Update"
              className="smssetng-svebtn"
              onClick={() => this.saveDoctor()}
            />
            <Link
              to={{
                pathname:
                  "/adminManagement/doctorManagementList/doctorManagement",
              }}
            >
              <input
                type="submit"
                id="txtCancel"
                value="Cancel"
                className="smssetng-cancelbtn"
              />
            </Link>
          </div>

          <div className="addHspPage-Wrapper">
            <div className=" ">
              <div className="ph_drmngmnt ">
                <button
                  id="doctorDetails"
                  className="active smssetng-svebtn"
                  onClick={() => this.doctorDetails()}
                >
                  Doctor Details
                </button>
                <button
                  id="seoDetails"
                  onClick={() => this.seoDetails()}
                  className="smssetng-svebtn"
                >
                  {" "}
                  Seo
                </button>
                {this.state.doctorId !== "" ? (
                  <button
                    id="photoDetails"
                    onClick={() => this.photoDetails()}
                    className="smssetng-svebtn"
                  >
                    Photo
                  </button>
                ) : (
                  ""
                )}
              </div>
              <div className="doctr-wraper-border" id="doctorDetailsDiv">
                {this.state.isData === 1 ? (
                  <Doctorinformation
                    ref="doctorInformationElement"
                    doctorId={this.state.doctorId}
                    doctorData={this.state.doctorDataArray}
                    languageEdit={this.state.languageEdit}
                  />
                ) : (
                  ""
                )}
                {this.state.isData === 1 ? (
                  <DoctorOffcicehours
                    ref="officeHoursElements"
                    doctorOfficeHours={this.state.doctorOfficeHours}
                    doctorData={this.state.doctorDataArray}
                  />
                ) : (
                  ""
                )}
                {this.state.isData === 1 ? (
                  <DoctorSpecialisation
                    ref="specialisationElement"
                    specialisationEdit={this.state.specialisationEdit}
                    doctorData={this.state.doctorDataArray}
                    proffesionalStatementSetting={
                      this.proffesionalStatementSetting
                    }
                  />
                ) : (
                  ""
                )}
                {this.state.isData === 1 ? (
                  <EducationDetails
                    ref="educationElements"
                    educationData={this.state.educationData}
                  />
                ) : (
                  ""
                )}
                {this.state.isData === 1 ? (
                  <Achievementsdetails
                    ref="achievementsElements"
                    achievementsData={this.state.achievementsData}
                  />
                ) : (
                  ""
                )}
                {this.state.isData === 1 ? (
                  <Proceduredetails
                    ref="procedureElements"
                    proceduresData={this.state.proceduresData}
                  />
                ) : (
                  ""
                )}
                {this.state.isData === 1 ? (
                  <OfficialInformation
                    ref="officialInformationElement"
                    doctorData={this.state.doctorDataArray}
                    associationEdit={this.state.associations}
                    setAssociations={(associations) => {
                      this.setState({ associations });
                    }}
                  />
                ) : (
                  ""
                )}
                {this.state.isData === 1 ? (
                  <SearchOffceService
                    ref="officeServiceElements"
                    statement={this.state.statementProf}
                    doctorData={this.state.doctorDataArray}
                  />
                ) : (
                  ""
                )}

                <div className="smssetng-btn text-left pt-2">
                  <input
                    type="submit"
                    value="Save"
                    id="btnSave"
                    className="smssetng-svebtn"
                    onClick={() => this.saveDoctor()}
                  />
                  <input
                    type="submit"
                    style={{ display: "none" }}
                    id="btnUpdate"
                    value="Update"
                    className="smssetng-svebtn"
                    onClick={() => this.saveDoctor()}
                  />
                  <Link
                    to={{
                      pathname:
                        "/adminManagement/doctorManagementList/doctorManagement",
                    }}
                  >
                    <input
                      type="submit"
                      value="Cancel"
                      id="txtCancel"
                      className="smssetng-cancelbtn"
                    />
                  </Link>
                </div>
              </div>
              <div className=" " id="seoDetailsDiv" style={{ display: "none" }}>
                {this.state.isData === 1 ? (
                  <Doctorseo doctorData={this.state.doctorDataArray} />
                ) : (
                  ""
                )}
              </div>
              {this.state.doctorId !== "" ? (
                <div
                  className=" "
                  id="doctorPhotoDiv"
                  style={{ display: "none" }}
                >
                  {this.state.isData === 1 ? (
                    <DoctorPhoto
                      ref="doctorPhotoElement"
                      doctorData={this.state.doctorDataArray}
                    />
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
