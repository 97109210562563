import React, { Component, Suspense } from "react";
import { Link } from "react-router-dom";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class PatientProfile extends Component {
    render() {
        return (
            <div class="purpleWrap">
                <Suspense fallback={<div></div>}>
                    <section>
                        <Header />
                    </section>
                </Suspense>
                <div class="container">
                    <div class="main-wrapper1 mt-0 pt-0 hspmain-wrapper-promo" id="row-col-width-leftmenu">
                        <div class="row">
                            <div class="col-lg-10 pt-4 patient-profleWraper">
                                <div className="dashbrd-rght-bottom-padng-hsp">
                                    <div class="tabmain_list">
                                        <h6 className="mb-0">Member Profile</h6>
                                    </div>

                                    <div className="col-sm-12 pt-4 pb-4 prflefrm-border">
                                        <div class="patientprf-subhead mb-3">
                                             <h6 className="mb-0">Member Details</h6>
                                        </div>

                                        <div className="row pl-3 pt-2">
                                            <div className="col-lg-6 row">
                                                <div className="col-lg-4 lbltxt-patent">
                                                    <label className="label-text-prfhsp">First Name</label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <input type="text" className="input-design" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 row">
                                                <div className="col-lg-4 lbltxt-patent">
                                                    <label className="label-text-prfhsp">Last Name</label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <input type="text" className="input-design" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="patientprf-subhead mb-3">
                                             <h6 className="mb-0">Account Details</h6>
                                        </div>

                                        <div className="row pl-3 pt-2">
                                            <div className="col-lg-6 row">
                                                <div className="col-lg-4 lbltxt-patent">
                                                    <label className="label-text-prfhsp">Policy Number</label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <input type="text" className="input-design" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 row">
                                                <div className="col-lg-4 lbltxt-patent">
                                                    <label className="label-text-prfhsp">Email ID</label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <input type="text" className="input-design" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 row">
                                                <div className="col-lg-4 lbltxt-patent">
                                                    <label className="label-text-prfhsp">Mobile Number</label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <input type="text" className="input-design" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row py-3 px-3">
                                                <button className="formButton formButtonBold">Update Profile</button>
                                                <button className="formButtonBold formButton-grey">Cancel</button>
                                           </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Suspense fallback={<div></div>}>
                    <section>
                        <Footer />
                    </section>
                </Suspense>
            </div>
        );
    }
}
