import React, { Component, Suspense } from "react";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import { apiCalling } from "../apiService";
import DatePicker from "react-datepicker";
import ReschedulePopup from "../doctor/reschedule-popup";
import moment from "moment";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class InpersonAppointment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inpersonAppointment: [],
            toDate: "",
            fromDate: "",
            showMessage: "Waiting for data... "
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeTo = this.handleChangeTo.bind(this);
    }
    // dateTimePopup(id, startTime) {
    //     window.sessionStorage.setItem("confirmId", id);
    //     window.sessionStorage.setItem("confirmStartTime", startTime);
    //     $("#dateTime-popup" + id).show();
    //     $("body").addClass("overflow-hidden");
    //   }
    componentDidMount() {

        $("body").removeClass("body-ovrflw-hdn-menu");
        $("#appointment-edit-main").hide();

        this.personAppointmentHistory();
    }

    personAppointmentHistory() {
        $(".loader").show();
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "videoAudioDoctorAppointment",
                siteId: window.sessionStorage.getItem("siteId"),
                doctorId: window.sessionStorage.getItem("userId"),
                fromDate: "",
                toDate: "",
            }),
        };
        apiCalling(apiJson).then((data) => {
            //console.log("inper",data)
            $(".loader").hide();
            if (data.success == "1") {
                this.setState({
                    inpersonAppointment: data.inpersonAppointment,
                    showMessage: data.inpersonAppointment.length > 0 ? "" : "No data found"
                });
            }
        });
    }

    searchHandler() {

        let todateT = moment(this.state.toDate);
        let fromdateT = moment(this.state.fromDate);
        if (todateT === "" && fromdateT === "") {
            this.personAppointmentHistory();
        } else {
            const apiJson = {
                method: "POST",
                body: JSON.stringify({
                    functionName: "videoAudioDoctorAppointment",
                    siteId: window.sessionStorage.getItem("siteId"),
                    doctorId: window.sessionStorage.getItem("userId"),
                    fromDate: fromdateT.format("DD/MM/YYYY") == "Invalid date"
                        ? ""
                        : fromdateT.format("DD/MM/YYYY"),
                    toDate: todateT.format("DD/MM/YYYY") == "Invalid date"
                        ? ""
                        : todateT.format("DD/MM/YYYY"),
                }),
            };
            $(".loader").show();
           //console.log("apiiii", apiJson.body)
            apiCalling(apiJson).then((data) => {
                //console.log("searchdata", data)
                $(".loader").hide();
                this.setState({ toDate: "", fromDate: "" })
                if (data.success == "1") {
                    this.setState({
                        inpersonAppointment: data.inpersonAppointment,
                        showMessage: data.inpersonAppointment.length > 0 ? "" : "No data found"
                    });
                }
            });
        }
    }

    confirmInPersonAppointment(data) {
        $(".loader").show();
        let appDate = moment(data.appointmentDate,"DD-MM-YYYY").format("DD/MM/YYYY");
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                "functionName": "confirmInpersonAppointment",
                "emrPatientAppointmentId":data.emrPatientAppointmentId,
                "requestAppointmentId": data.appointmentId,
                "appointmentDate": appDate,
                "appointmentTime":data.appointmentTime,
                "siteId":window.sessionStorage.getItem("siteId"),
                "bundleId": ""
            }),
        };
       // console.log(apiJson.body);
        apiCalling(apiJson).then((data) => {
            if (data.success === "1") {
                $(".loader").show();
                alert(data.successMessage);
                this.personAppointmentHistory();
            } else {
                $(".loader").hide();
                alert(data.errorMessage);
            }
        });
    }

    rejectInpersonAppointment(data) {
        $(".loader").show();
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                "functionName": "rejectInpersonAppointment",
                "emrPatientAppointmentId":data.emrPatientAppointmentId,
                "requestAppointmentId": data.appointmentId,
                "siteId": window.sessionStorage.getItem("siteId"),
                "bundleId": ""
            }),
        };
        //console.log(apiJson.body)
        apiCalling(apiJson).then((data) => {
            if (data.success === "1") {
                $(".loader").show();
            alert(data.successMessage);
             this.personAppointmentHistory()
            } else {
                $(".loader").hide();
                alert(data.errorMessage);
            }
        });
    }

    handleChange = (date) => {
        this.setState({
            fromDate: date,
        });
    };

    handleChangeTo = (date) => {
        this.setState({
            toDate: date,
        });
    };

    rescheduleEdit(data) {
    
        $("#appointment-edit-main"+data.appointmentId).show();
       
      }

    render() {
        return (
            <div class="purpleWrap">
                <Suspense fallback={<div >Waiting...</div>}>
                    <section>
                        <Header />
                        <div className='loader'></div>
                        <div class="container">
                            <div class="main-wrapper1">
                                <div class="col-md-12 p-0">
                                    <ul class="breadcrumb">
                                        <li>
                                            <a href="/doctor/dashboard">Dashboard</a>
                                            <i class="fa fa-chevron-right"></i>
                                        </li>
                                        <li>Inperson Appointment</li>
                                    </ul>
                                </div>
                                <div class="form-head"> Inperson Appointment </div>
                                <div class="row">
                                    <div class="col-md-4 col-12 mb-3">
                                        <DatePicker
                                           placeholderText="From Date"
                                            id="fromDate"
                                            name="fromDate"
                                            selected={this.state.fromDate}
                                            onChange={this.handleChange}
                                            dateFormat="dd/MM/yyyy"
                                            calendarClassName="react-date-picker"
                                        />
                                    </div>
                                    <div class="col-md-4 col-12 mb-3">
                                        <DatePicker
                                            placeholderText="To Date"
                                            id="toDate"
                                            name="toDate"
                                            selected={this.state.toDate}
                                            onChange={this.handleChangeTo}
                                            dateFormat="dd/MM/yyyy"
                                            calendarClassName="react-date-picker"
                                        />
                                    </div>
                                    <div class="col-md-4 col-12 mb-3">
                                        <input
                                            type="submit"
                                            value="Search"
                                            class="formButton m-0"
                                            onClick={() => this.searchHandler()}
                                        />
                                    </div>
                                </div>
                                {this.state.inpersonAppointment.length > 0 ?
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>Appointment Date</th>
                                                    <th>AppointmentTime</th>
                                                    <th> Transaction ID</th>
                                                    <th>Patient Name</th>
                                                    <th>Reason </th>
                                                    <th>Hospital Name</th>
                                                    <th> Status </th>
                                                    <th >Reschedule</th>
                                                    <th> </th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ fontSize: "12px" }}>
                                                {this.state.inpersonAppointment.map((data) => {
                                                     let appointmentDate =moment(data.appointmentDate, "DD-MM-YYYY").format("DD-MMM-YYYY");
                                                    return (
                                                        <tr>
                                                            <td>{appointmentDate}</td>
                                                            <td className="whitespace-nowrap">{data.appointmentTime}</td>
                                                            <td>{data.transactionId}</td>
                                                            <td>{data.patientName}</td>
                                                            <td>{data.reasonForAppointment}</td>
                                                            <td>{data.hospitalName}</td>
                                                            <td>{data.appointmentStatusName}</td>
                                                            <td className="text-center">
                                                                <p className="d-flex m-0">
                                                                <i class="fa fa-edit mr-2 mt-1" title="Reshedule"
                                                                onClick={() =>this.rescheduleEdit(data)}>  
                                                                </i>
                                                                 <ReschedulePopup resheduleObj={data}></ReschedulePopup>
                                                                {data.appointmentStatusName === 'Open' ?  (
                                                                 <button onClick={() =>this.confirmInPersonAppointment(data)} className="formButton formButton-sm m-0"> Confirm </button>
                                                                 ) : ''}
                                                                 {data.appointmentStatusName === 'Open' ? (
                                                                 <button onClick={() => this.rejectInpersonAppointment(data)} className="cancelButton formButton-sm m-0 ml-2"> Reject</button>
                                                                     ) : ''}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <p className="error-msg text-center mb-0"> {this.state.showMessage} </p>
                                }
                            </div>
                        </div>
                        <Footer />
                    </section>
                </Suspense>
            </div>
        );
    }
}
