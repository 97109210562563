import React, { Component, Suspense } from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { apiCalling } from "../apiService";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import $ from "jquery";
// import InnerHTML from "dangerously-set-html-content";
import docsImg from "../image/icons/google-docs.png";
import pdfImg from "../image/icons/pdf.png";
import attachImg from "../image/media/attachgrey.png";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class VideocallList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      failedAppointments: [],
      pendingAppointments: [],
      missedAppointments: [],
      appointments: [],
      doctorInitiatedAppointment: [],
      videoAppointments: [],
      doctorId: "",
      hospitalId: "",
      doctorVideoScheduleId: "",
      isSessionBased: "",
      isFree: "",
      memberId: "",
      sessionChatNo: "",
      scheduled_time_slot_id: "",
    };

    this.chatNow = this.chatNow.bind(this);
  }

  chatNow(
    doctorId,
    hospitalId,
    doctorVideoScheduleId,
    isSessionBased,
    isFree,
    memberId,
    sessionChatNo,
    scheduled_time_slot_id
  ) {
    // $("#term-main").show();

    this.setState({
      doctorId: doctorId,
      hospitalId: hospitalId,
      doctorVideoScheduleId: doctorVideoScheduleId,
      isSessionBased: isSessionBased,
      isFree: isFree,
      memberId: memberId,
      sessionChatNo: sessionChatNo,
      scheduled_time_slot_id: scheduled_time_slot_id,
    });
    //if ($("#invalidCheck").is(":checked") === true) {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "acceptCall",
        doctorVideoScheduleId: doctorVideoScheduleId,
        patientId: memberId,
        hospitalId: hospitalId,
        doctorId: doctorId,
        videoChatRoomId: sessionChatNo,
        siteId: window.sessionStorage.getItem("siteId"),
        bundleId: "",
      }),
    };
    //alert(sessionChatNo);
    //  console.log(apiJson.body);

    window.sessionStorage.setItem("vcPatientId", memberId);
    window.sessionStorage.setItem(
      "vcDoctorVideoScheduleId",
      doctorVideoScheduleId
    );
    window.sessionStorage.setItem("vcHospitalId", hospitalId);
    window.sessionStorage.setItem("vcDoctorId", doctorId);
    window.sessionStorage.setItem(
      "vcScheduledTimeSlotId",
      scheduled_time_slot_id
    );
    window.sessionStorage.setItem("vcSessionChatNo", sessionChatNo);

    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      if (data.success == "1") {
        $(".loader").hide();
        window.sessionStorage.setItem("apiKey", data.result[0].apiKey);
        window.sessionStorage.setItem("sessionId", data.result[0].sessionId);
        window.sessionStorage.setItem("token", data.result[0].token);
        window.sessionStorage.setItem("startChatData", JSON.stringify(data));
        window.sessionStorage.setItem(
          "vc_showName1",
          data.result[0].loggedinDisplayName
        );
        window.sessionStorage.setItem(
          "vc_showName2",
          data.result[0].specialization
        );
        window.sessionStorage.setItem(
          "vc_showName3",
          data.result[0].qualification
        );
        window.sessionStorage.setItem("drcallType", data.result[0].calltype);
        window.sessionStorage.setItem(
          "vcchatGroupId",
          data.result[0].chatGroupId
        );
        window.location.href = "../doctor/videocall";
      } else {
        // alert(data.errorMessage);
        $(".loader").hide();
      }
    });
    //} else {
    //alert("Please agree with terms and conditions");
    // $("#term-main").show();

    //}
  }
  componentDidMount() {
    $("body").removeClass("body-ovrflw-hdn-menu");
    let self = this;
    self.getDataFromAPI();
    $(".PAppointment").hide();
    $(".FailedAppointment").hide();
    $(".PendingAppointment").hide();
    $(".MissedAppointment").hide();
    let flag = 0;
    if (flag == 0) {
      $(".loader").show();
    }
    // alert(window.sessionStorage.getItem("userId"));
    if (window.sessionStorage.getItem("role") != "ROLE_ADMIN")
      setInterval(async () => {
        self.getDataFromAPI();
      }, 3000);

    $(".popup-hide").click(function () {
      $(".popup-overlay").hide();
    });
    // $("html").click(function () {
    //   $(".popup-overlay").hide();
    // });

    $(".popup-content").click(function (event) {
      event.stopPropagation();
    });

    $("#AcceptCall").click(function () {
      $("#invalidCheck").prop("checked", true);
      self.chatNow(
        self.state.doctorId,
        self.state.hospitalId,
        self.state.doctorVideoScheduleId,
        self.state.isSessionBased,
        self.state.isFree,
        self.state.memberId,
        self.state.sessionChatNo,
        self.state.scheduled_time_slot_id
      );
    });
    $("#decline").click(function () {
      $("#term-main").hide();
    });
  }
  cancelAttachment() {
    $("#showMyAttachments").hide();
  }
  showAttachments(appId) {
    $("#showMyAttachments").show();
    const LazyPage = React.lazy(() => import("../doctor/showAttachments"));
    ReactDOM.render(
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <section>
            <LazyPage appId={appId} />
          </section>
        </Suspense>
      </div>,
      document.getElementById("attachmentCnt")
    );
  }
  getDataFromAPI() {
    if (window.sessionStorage.getItem("isLogin") == "true") {
      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "videoAudioAppointment",
          userId: window.sessionStorage.getItem("userId"),
          siteId: window.sessionStorage.getItem("siteId"),
        }),
      };
      //console.log(apiJson.body);
      apiCalling(apiJson).then((data) => {
        if (data.success == "1") {
          $(".loader").hide();
          this.setState({
            failedAppointments: data.failedAppointments,
            pendingAppointments: data.pendingAppointments,
            missedAppointments: data.missedAppointments,
            appointments: data.appointments,
            doctorInitiatedAppointment: data.doctorInitiatedAppointment,
            videoAppointments: data.videoAppointments,
          });

          this.setState({
            pendingAppointments: data.pendingAppointments,
          });
          if (data.failedAppointments.length != 0) {
            $(".FailedAppointment").show();
          }
          if (data.pendingAppointments.length != 0) {
            $(".PendingAppointment").show();
          }
          if (data.missedAppointments.length != 0) {
            $(".MissedAppointment").show();
          }
          if (data.appointments.length != 0) {
            //$("#TermsandConditions").show();
            $(".PAppointment").show();
          }
          if (data.doctorInitiatedAppointment.length != 0) {
            // $("#TermsandConditions").show();
            $(".doctorInitiatedAppointment").show();
          }
          if (data.videoAppointments.length != 0) {
            $(".videoAppointments").show();
          }
          //alert(data.appointments);
        } else {
          // alert("Appointment List Error: " + data.errorMessage);
          $(".loader").hide();
        }
      });
    }
  }
  termcondtn() {
    $("#term-main").show();
  }

  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container">
          <div class="main-wrapper1">
            <Tabs className="video-appntmnt-list">
              <TabList>
                <Tab>
                  <div className="drDetSpanUnderline">
                    Appointment(s)
                    <div className="notification-tag">
                      {this.state.doctorInitiatedAppointment.length}
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="drDetSpanUnderline">
                    Missed Appointment(s)
                    <div className="notification-tag ">
                      {this.state.missedAppointments.length}
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="drDetSpanUnderline">
                    Failed Appointment(s)
                    <div className="notification-tag notification-tag-red">
                      {this.state.failedAppointments.length}
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="drDetSpanUnderline">
                    Pending Appointment(s)
                    <div className="notification-tag ">
                      {this.state.pendingAppointments.length}
                    </div>
                  </div>
                </Tab>
              </TabList>

              <div
                class="term-condtn-main-postn term-condtn-main"
                for="invalidCheck"
                id="TermsandConditions"
                style={{ display: "none" }}
              >
                <label className="checkbox-main">
                  <input type="checkbox" id="invalidCheck" />
                  <span class="checkbox"></span>
                </label>
                I agree with{" "}
                <a
                  href="#"
                  onClick={this.termcondtn}
                  style={{ color: "#464646" }}
                >
                  Terms and Conditions
                </a>
              </div>
              <TabPanel>
                <div class="pendingLink">
                  <Link to="/patient/appointmentlist">All Appointments</Link>
                </div>
                <div class="form-head mt-md-4"> Appointment(s) </div>

                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <th>Appointment Date & Time</th>
                      <th>Doctor Name</th>
                      <th>Hospital</th>
                      <th>Service</th>
                      <th>Status</th>
                      <th></th>
                    </thead>

                    <tbody>
                      {/* {this.state.appointments.map((obj, index) => {
                        let dates = new Date(obj.startTime);
                        let schStartTime = obj.schStartTime;
                        let schEndTime = obj.schEndTime;

                        let curDate = new Date();
                        let showStatus = "hide";
                        let showStatus_AVL = "hide";
                        let roomId = "";

                        //if (curDate > schStartTime && curDate < schEndTime) {
                        //showStatus = "show";
                        //}
                        //alert(obj.isDoctorInAnotherChat);
                        if (
                          obj.isActive === 1 &&
                          //  obj.isLogged === 1 &&
                          obj.appointmentStatus === 2// &&
                          // obj.isDoctorInAnotherChat === 0
                        ) {
                          this.state.videoAppointments.map((vid, vin) => {
                            if (
                              obj.schStartTime === vid.startTime &&
                              obj.schEndTime === vid.endTime &&
                              vid.active === true
                            ) {
                              showStatus = "show";
                              roomId = vid.room;
                            }
                          });
                        }
                        if (
                          obj.isActive === "1" &&
                          obj.isLogged === "1" &&
                          obj.appointmentStatus === "2" &&
                          obj.isDoctorInAnotherChat === "1"
                        ) {
                          showStatus_AVL = "show";
                        }
                        //alert(".." + roomId);
                        return (
                          <tr>
                            <td class="whitespace-nowrap">
                              {dates.toDateString()}{" "}
                              {dates.toLocaleTimeString("en-IN", {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              })}
                            </td>
                            <td class="whitespace-nowrap">
                              Dr.{obj.doctorFirstName}
                              {obj.doctorLastName}
                            </td>
                            <td>{obj.hospitalName} </td>
                            <td class="whitespace-nowrap">
                              {obj.appointmentType}{" "}
                            </td>

                            <td>{obj.status} </td>

                            <td class="whitespace-nowrap">
                              {showStatus === "show" ? (
                                <span
                                  class=" cursor-pointer"
                                  onClick={() =>
                                    this.chatNow(
                                      obj.doctorId,
                                      obj.hospitalId,
                                      obj.doctorVideoScheduleId,
                                      obj.isSessionBased,
                                      obj.isFree,
                                      obj.memberId,
                                      roomId,
                                      obj.scheduledTimeSlotId
                                    )
                                  }
                                >
                                  <span class="formButtonBg formButton-sm">
                                    Accept Call
                                  </span>
                                </span>
                              ) : (
                                ""
                              )}
                              {showStatus_AVL === "show" ? (
                                <span class="formButton formButton-sm">
                                  Available Soon
                                </span>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        );
                      })} */}
                      {this.state.doctorInitiatedAppointment.map(
                        (obj, index) => {
                          let dates = new Date(obj.appoDate);
                          //alert(obj.sessionChatNo);
                          let schStartTime = obj.schStartTime;
                          let schEndTime = obj.schEndTime;

                          let curDate = new Date();
                          let nowDate = curDate.getMilliseconds();

                          let showStatus = "show";
                          let showStatusElse = "hide";

                          return (
                            <tr>
                              <td class="whitespace-nowrap">
                                {dates.toDateString()}{" "}
                                {dates.toLocaleTimeString("en-IN")}
                              </td>
                              <td class="whitespace-nowrap">
                                Dr.{obj.drFirstName} {obj.drLastName}
                              </td>
                              <td>{obj.hospitalName} </td>
                              <td class="whitespace-nowrap">
                                {obj.appointmentType}{" "}
                              </td>

                              <td> {obj.status} </td>

                              <td class="whitespace-nowrap">
                                {obj.chatInitiated === true ? (
                                  <span
                                    className="cursor-pointer"
                                    onClick={() =>
                                      this.chatNow(
                                        obj.doctorId,
                                        obj.hospitalId,
                                        obj.doctorVideoScheduleId,
                                        obj.isSessionBased,
                                        obj.isFree,
                                        obj.member_id,
                                        obj.room,
                                        obj.scheduled_time_slot_id
                                      )
                                    }
                                  >
                                    <span
                                      class="formButtonBg formButton-sm"
                                      //onClick={this.chatNow()}
                                    >
                                      Accept Call
                                    </span>
                                  </span>
                                ) : (
                                  ""
                                )}
                                {showStatusElse === "show" ? (
                                  <span>
                                    <span class="formButtonBg formButton-sm">
                                      Available Soon
                                    </span>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </TabPanel>
              <TabPanel>
                <div class="table-section mb-3 MissedAppointment">
                  <div class="form-head mb-2 d-flex align-items-center">
                    <div className="">Missed Appointment(s)</div>
                    <div class="pendingLink ml-auto mb-0 mr-2">
                      <Link to="/patient/appointmentlist">
                        All Appointments
                      </Link>
                    </div>
                    <Link to="/home/doctor-list-main/0" class="addNew-btn my-0">
                      <span className="add-new-plus">+</span>Create New
                      Appointments
                    </Link>
                  </div>
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <th>Appointment Date & Time</th>
                        <th>Doctor Name</th>
                        <th>Hospital</th>
                        <th>Service</th>
                        <th>Requested Date</th>

                        <th>Status</th>
                      </thead>
                      <tbody>
                        {this.state.missedAppointments.map((obj, index) => {
                          let dates = new Date(obj.appointmentDate);
                          return (
                            <tr>
                              <td class="whitespace-nowrap">
                                {dates.toDateString()}{" "}
                                {dates.toLocaleTimeString("en-IN")}
                              </td>
                              <td class="whitespace-nowrap">
                                Dr. {obj.doctorFirstName} {obj.doctorLastName}
                              </td>
                              <td>{obj.hospitalName} </td>
                              <td class="whitespace-nowrap">
                                {obj.appointmentType}{" "}
                              </td>
                              <td>{obj.requestedDate}</td>

                              <td>{obj.status}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div class="table-section mb-3  FailedAppointment">
                  <div className="d-flex align-items-center mb-2">
                    <div class="form-head mb-0">Failed Appointment(s) </div>
                    <div class="pendingLink ml-auto mb-0">
                      <Link to="/patient/appointmentlist">
                        All Appointments
                      </Link>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <th>Appointment Date &amp; Time</th>
                        <th>Doctor Name</th>
                        <th>Hospital</th>
                        <th>Service</th>

                        <th>Charge Per Session/Minute</th>
                        <th>Status</th>
                      </thead>
                      <tbody>
                        {this.state.failedAppointments.map((obj, index) => {
                          let dates = new Date(obj.startTime);
                          return (
                            <tr>
                              <td class="whitespace-nowrap">
                                {dates.toDateString()}{" "}
                                {dates.toLocaleTimeString("en-IN")}{" "}
                              </td>
                              <td class="whitespace-nowrap">
                                {obj.displayName}
                              </td>
                              <td>{obj.hospitalName} </td>
                              <td class="whitespace-nowrap">
                                {obj.appointmentType}{" "}
                              </td>

                              <td>
                                {obj.ratePerSession != "0.00"
                                  ? "Rs " + obj.ratePerSession
                                  : "Free"}
                              </td>

                              <td class="whitespace-nowrap">
                                <span
                                  class="formButtonBg formButton-sm"
                                  //onClick={this.chatNow()}
                                >
                                  Accept Call
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div class="table-section mt-3 mb-3 PendingAppointment">
                  <div className="d-flex align-items-center mb-2">
                    <div class="form-head mb-0">Pending Appointment(s) </div>
                    <div class="pendingLink ml-auto mb-0">
                      <Link to="/patient/appointmentlist">
                        All Appointments
                      </Link>
                    </div>
                  </div>
                  <div class="col-sm-12 text-right px-0"></div>
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <th>Appointment Date &amp; Time</th>
                        <th>Doctor Name</th>
                        <th>Hospital</th>
                        <th>Service</th>

                        <th>Charge Per Session/Minute</th>
                        <th></th>
                        <th>Status</th>
                      </thead>
                      <tbody>
                        {this.state.pendingAppointments.map((obj, index) => {
                          let dates = new Date(obj.appTime);
                          return (
                            <tr>
                              <td class="whitespace-nowrap">
                                {dates.toDateString()}{" "}
                                {dates.toLocaleTimeString("en-IN")}
                              </td>
                              <td class="whitespace-nowrap">
                                {obj.displayName}
                              </td>
                              <td>{obj.hospitalName} </td>
                              <td class="whitespace-nowrap">
                                {obj.appointmentType}
                              </td>

                              <td>
                                {obj.ratePerSession != "0.00"
                                  ? "Rs " + obj.ratePerSession
                                  : "Free"}
                              </td>
                              <td>
                                {obj.attachmentCount != "0" && (
                                  <img
                                    src={attachImg}
                                    className="cursor-pointer"
                                    width="15"
                                    alt=""
                                    title="File attachment"
                                    onClick={() =>
                                      this.showAttachments(
                                        obj.requestAppointmentId
                                      )
                                    }
                                    // title="attachment"
                                  ></img>
                                )}
                              </td>
                              <td>
                                <span className="pending-text">Pending</span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>

        {/* Popup */}
        <div class="popup-overlay" id="term-main" style={{ display: "none" }}>
          <div className="popup-content">
            <div className="modal-popup">
              <span className="close-popup  popup-hide">&times;</span>
              <div className="header border-0"> Terms and Conditions</div>
              <div className="content">
                <div className="col-md-12 text-center">
                  <p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          window.sessionStorage.getItem("video_chat_terms") ===
                          "" ? (
                            `<span className="text-center d-block">No, Terms and Condition</span>`
                          ) : (
                            window.sessionStorage.getItem("video_chat_terms")
                          ),
                      }}
                    />
                  </p>
                  <span>
                    <input
                      type="button"
                      value="Accept"
                      className="formButton formButton-sm"
                      id="AcceptCall"
                    />
                  </span>
                  <span>
                    <input
                      type="button"
                      value="Decline"
                      className="formButton-sm cancelButton"
                      id="decline"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="popup-overlay"
          id="showMyAttachments"
          style={{ display: "none" }}
        >
          <div className="popup-attach">
            <div className="modal-popup">
              <a
                className="close-popup  popup-hide"
                style={{ zIndex: "1" }}
                onClick={() => this.cancelAttachment()}
              >
                &times;
              </a>
              <div
                className="content"
                id="attachmentCnt"
                style={{ maxHeight: "fit-content" }}
              ></div>
            </div>
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
