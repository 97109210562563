import React, { Component, Suspense } from "react";
import $ from "jquery";
import { apiCalling } from "../../apiService";
import HelthInfoList from "./healthInfo-list";
import HelthinfoAddEdit from "./helthInfoAddEdit";

import Header from "../../Reliance/header";
import Footer from "../../Reliance/footer";

class helthInfo extends Component {
  state = {
    mode: "list",
    currentPage: "Health Info",
    navigation: [
      { pageName: "Dashboard", url: "/admin/dashboard" },
      {
        pageName: "Health info Dashboard",
        url: "/adminManagement/healthInformation",
      },
      {
        pageName: "Health Info",
        url: "/adminManagement/healthInfo",
        current: true,
      },
    ],
    healthInfoId: "",
    imagePath: {},
  };

  handleView() {
    if (this.state.mode == "list") {
      return <HelthInfoList changeMode={this.handleModeChange} />;
    } else if (this.state.mode == "addNew") {
      return (
        <HelthinfoAddEdit
          changeMode={this.handleModeChange}
          healthInfoId={this.state.healthInfoId}
          imagePath={this.state.imagePath}
        />
      );
    } else if (this.state.mode == "edit") {
      return (
        <HelthinfoAddEdit
          changeMode={this.handleModeChange}
          healthInfoId={this.state.healthInfoId}
          imagePath={this.state.imagePath}
        />
      );
    } else {
      return <div>Error occured..</div>;
    }
  }
  componentDidMount() {
    this.getImagePath();
  }

  getImagePath() {
    $(".loader").show();
    const imagePath = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
        siteId: "1",
      }),
    };
    apiCalling(imagePath).then((data) => {
      if (data.success === "1") {
        this.setState({
          imagePath: data.configPathDetails[0],
        });
      }
      $(".loader").hide();
    });
  }

  handleModeChange = (mode, navigation, currentPage = "", healthInfoId = 0) => {
    this.setState({
      mode,
      navigation,
      currentPage,
      healthInfoId,
    });
  };

  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div>Waiting...</div>}>
          <section>
            <Header />
            <div className="loader"></div>
            <div className="container">
              <div>
                <ul className="breadcrum-adminMangmnt">
                  {this.state.navigation.map((nav) => (
                    <li>
                      <a
                        href={nav.url}
                        className={nav.current ? "current" : null}
                      >
                        {nav.pageName}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="head-hospMangmntList py-2">
                {this.state.currentPage}
              </div>
              {this.handleView()}
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
export default helthInfo;
