import React, { Component, Suspense } from "react";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class HealthRecord extends Component {


  render() {
    return (
      <div class="purpleWrap  ">
          <Suspense fallback={<div ></div>}>
          <section>
          <div className='loader'></div>
            <Header />
            <div class="container container-auto">
              <div class="main-wrapper1">
                <div className="col-md-12 mb-3">
                  <h5 className="mb-0">Health Records</h5>
                </div>
                <div className="col-md-12  position-relative">
                  <div className="health_record">
                    <h5 className="text-primary">Help us to Make your data secure !</h5>
                    <p className="mb-5">Your One Time PIN (OTP) has been sent to your Mobile Number
                        Please enter the OTP below</p>
                        <div className="row">
                          <div className="col-md-6 d-flex align-items-center mb-3">
                            <label className="mb-0">Mobile Number</label><span className="ml-auto">:</span>
                          </div>
                          <div className="col-md-6 mb-3">
                            <span>******6789</span>
                          </div>
                          <div className="col-md-6 d-flex align-items-center  mb-3">
                          <label className="mb-0">One time Pin</label><span className="ml-auto">:</span>
                          </div>
                          <div className="col-md-6  mb-3 d-flex align-items-center">
                              <input type="text" className="input-design mb-0"/><i className="fa fa-refresh ml-2 text-primary"></i>
                          </div>
                          <div className="col-md-12 text-right">
                            <input type="submit" className="formButtonBg mb-0" value="Verify"/>
                          </div>
                        </div>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
