import React, { Component, Suspense } from "react";
import Closebtn from "../../image/icons/close-sm.png";
import AddImg from "../../image/icons/addbtn.png";
import { apiCalling } from "../../apiService";
import $ from "jquery";
const folderName = window.sessionStorage.getItem("homeName");
// const Header = React.lazy(() => import("../" + folderName + "/header"));
// const Footer = React.lazy(() => import("../" + folderName + "/footer"));


export default class EducationDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      educationDetailsArray: [],
      educationData: [],
    }

  }
  componentDidMount() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        "functionName": "getHospitalDropdown"
       
      })
    };
    // console.log("listinggg.222222..", apiJson)
    apiCalling(apiJson).then((data) => {
      // console.log("listinggg.1111..", data)

      if (data.success === "1") {
        this.setState({
          educationData: data.hospitalData[0].education,
        }
        ,()=>{
          if(this.props.educationData.length > 0 ){
            this.setState({
              educationDetailsArray:this.props.educationData
            },()=>{
              for(var i=0; i<this.props.educationData.length;i++){
                this.yearDropdown(i)
              }   
              this.setState({
                educationDetailsArray:this.props.educationData
              })           
            })
            
          }
          // else{
          //   this.setState({
          //     educationDetailsArray:[]
          //   }) 
          // }

        }
        )
      }
     
    });
    this.yearDropdown("")
    this.yearDropdownDefault()
  }

  yearDropdown(index) {
    var ddlYears = document.getElementById("cmbYear"+index)
    var currentYear = (new Date()).getFullYear();
    var option1 = document.createElement("OPTION");
    option1.innerHTML = "Select";
    option1.value = "0";    
    if(ddlYears !== null && ddlYears !== "null"){
      ddlYears.appendChild(option1);
      for (var i = currentYear; i >= 1950; i--) {
        var option = document.createElement("OPTION");
        option.innerHTML = i;
        option.value = i;
        ddlYears.appendChild(option);
      }
    }    
  }
  yearDropdownDefault() {
    var year = []
    var currentYear = (new Date()).getFullYear();
    for (var i = currentYear; i >= 1950; i--) {
      year.push(i)
    }
    this.setState({
      year: year
    })
  }
  addEducationDetails(){
   
    var flag = false
    // if (this.state.educationDetailsArray.length > 0) {
      for (var i = 0; i <= this.state.educationDetailsArray.length; i++) {
        if ($("#cmbEducation"+i).val() === "0") {
          alert("Education Required")
          $("#cmbEducation"+i).focus();
          flag = true;
          break;
        }
      }
    // }
   
    if (flag === false) {
      var a = {
        "Id": $("#cmbEducation").val(),
        "year": $("#cmbYear").val(),
        "college": $("#txtCollege").val().replace(/'/g, "''")
      }
      this.state.educationDetailsArray.push(a)
      // console.log("educationDetailsArray....", this.state.educationDetailsArray)
      this.setState({
        educationDetailsArray: this.state.educationDetailsArray
       }, () => {
        this.yearDropdown(this.state.educationDetailsArray.length-1)
      })   
    }
    $("#cmbEducation").val('0')
    $("#cmbYear").val('0')
    $("#txtCollege").val('')

  }

  setEducationData(value,name,i, mode) {
    if (mode === "cmbEducation") {
      this.state.educationDetailsArray[i].Id = $("#" + mode + i).val()
    } else if (mode === "cmbYear") {
      this.state.educationDetailsArray[i].year = $("#" + mode + i).val()
    } else if (mode === "txtCollege") {
      this.state.educationDetailsArray[i].college = $("#" + mode + i).val()
    }
    // console.log("educationDetailsArray...set......", this.state.educationDetailsArray)
    this.setState({
      [name]: value,
      educationDetailsArray: this.state.educationDetailsArray
    })
   
  }

  removeEducationDetails(Id) {
    if(this.state.educationDetailsArray.length >1){
    var data = $.grep(this.state.educationDetailsArray, function (e) {
      return e.Id !== Id;
    });
    this.state.educationDetailsArray = data
    this.setState({
      educationDetailsArray: this.state.educationDetailsArray
    })
  }else{
    alert("Atleast One Education Required")
  }
  }
  education(){
    var b = {
      educationDetails:this.state.educationDetailsArray
    }
    return b;
  }
 
  render() {
    return (
      <div className="bg-clr-wthHead mb-4">
        <Suspense fallback={<div></div>}>
          <section>
            <div className="consultngTime-wrapper">
              <h2 className="hspInfoSubhead">Education Details</h2>

              <div className="hsptaltimeHead">
                <div className="educationdoctr-contentdiv">
                  <label className="educationdoctrlabelwidth">Education <span className="hspifoMantory">*</span></label>
                  <select id="cmbEducation" name="cmbEducation" className="hspInfofieldinput eductninputwidthdctr" >
                    <option value="0">Select</option>
                    {this.state.educationData.map((eduObj, s) => {
                       return (
                         <option key={eduObj.educationCategoryId} value={eduObj.educationCategoryId}>{eduObj.educationCategory}</option>
                       )
                    })}
                  </select>
                </div>
                <div className="hsptimefieldDiv hsptimefieldDivMob-time " >
                  <label className="educationlabelwidthyear">Year</label>
                  <select name="cmbYear" id="cmbYear">
                   
                  </select>
                </div>

                <div className="hsptimefieldDiv pl-2 hsptimefieldDivMob-time">
                  <label className="educationlabelwidthdctr">College</label>
                  <input type="text" id="txtCollege" name="txtCollege" className="hspInfofieldinput eductninputwidthdctr" placeholder="College"  />
                </div>
                <div className="addimgeDivHsp">
                  <img src={AddImg} alt="Add" onClick={() => this.addEducationDetails()}/>
                </div>
              </div>

              {this.state.educationDetailsArray.map((Obj, i) => {
              return (
              <div key={Obj.Id} className="hsptaltimeHead">
                <div className="educationdoctr-contentdiv">
                  <label className="educationdoctrlabelwidth">Education <span className="hspifoMantory">*</span></label>
                  <select id={"cmbEducation"+ i} name={"cmbEducation"+ i} value={Obj.Id} className="hspInfofieldinput eductninputwidthdctr" onChange={(e) => this.setEducationData(e,"cmbEducation"+i,i, "cmbEducation")}>
                    <option value="0">Select</option>
                    {this.state.educationData.map((eduObj, s) => {
                       return (
                         <option key={eduObj.educationCategoryId} value={eduObj.educationCategoryId}>{eduObj.educationCategory}</option>
                       )
                    })}
                  </select>
                </div>
                <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                  <label className="educationlabelwidthyear">Year</label>
                  <select name={"cmbYear" + i} id={"cmbYear" + i} value={Obj.year} onChange={(e) => this.setEducationData(e, "cmbYear" + i, i, "cmbYear")} >
                        {this.state.year.map((yearObj) => {
                          return (<option value={yearObj}>{yearObj}</option>)
                        })}
                      </select>
                </div>

                <div className="hsptimefieldDiv pl-2 hsptimefieldDivMob-time">
                  <label className="educationlabelwidthdctr">College</label>
                  <input type="text" value={Obj.college} id={"txtCollege"+i} name={"txtCollege"+i} className="hspInfofieldinput eductninputwidthdctr" placeholder="College" onChange={(e) => this.setEducationData(e,"txtCollege"+i,i, "txtCollege")} />
                </div>
                <div className="addimgeDivHsp">
                  <img src={Closebtn} alt="Close" onClick={() => this.removeEducationDetails(Obj.Id)} width="20"/>
                </div>
              </div>
                )
              })}

            </div>

          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
        </Suspense>
      </div>
    );
  }
}
