import React, { Component, Suspense } from "react";
//import Header from "../"+window.sessionStorage.getItem("homeName")+"header"//"../layout/header";
import * as ReactDOM from "react-dom";
import TrustedBy from "../home/trustedBy";
import { Link } from "react-router-dom";
import ContactForm from "../home/contactus-form";
// import Contactus from "./contactus"
// import Telehealth from "./telehealth"
// import Aboutus from "./aboutus"
import Favicon from "react-favicon";
import { Helmet } from "react-helmet";
//import homeImg4 from "../image/theme/home4.png";
//import homeImg1 from "../image/theme/home1.png";
//import homeImg3 from "../image/theme/home3.png";
//import homeImg2 from "../image/theme/home4.png";
//import whyicon1 from "../image/theme/why-icon1.png";
//import whyicon2 from "../image/theme/why-icon2.png";
//import whyicon3 from "../image/theme/why-icon3.png";
import loaderImg from "../image/theme/loader.gif";
//import "../css/style.css";
import { apiCalling, apiUrlRelianceFunction } from "../apiService";
import $ from "jquery";

let folderName = "Reliance"; //window.sessionStorage.getItem("homeName");
let Homepage = React.lazy(() => import("../" + folderName + "/home"));
let Header = React.lazy(() => import("../" + folderName + "/header"));
let Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteId: "1", //window.sessionStorage.getItem("siteId"),
      siteUrl: "", // window.location.host,
      isLoader: false,
    };
  }
  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    var id = queryParams.get("P");
    window.sessionStorage.setItem("siteId", "1");
    // !this.props.match.params.token && this.getLoadHomePage();
    if (id) {
      while (id.includes(" ")) {
        id = id.replace(" ", "+");
      }
      //console.log("props--->"+id)
      const jsonLoginReq = {
        method: "POST",
        body: JSON.stringify({
          functionName: "RHPatient",
          subFunction: "PolicyEncryption",
          siteId: "1",
          P: id,
        }),
      };

      // alert(jsonLoginReq.body);
      apiUrlRelianceFunction(jsonLoginReq).then((data) => {
        // console.log(data,"ghghghghghghghghghg")
        if (data.success === "1") {
          window.sessionStorage.setItem("userId", data.username);
          window.sessionStorage.setItem("PolicyNo", data.PolicyNo);
          window.sessionStorage.setItem("emailId", data.Email);
          window.sessionStorage.setItem("Lastname", data.Lastname);
          window.sessionStorage.setItem(
            "userName",
            data.FirstName + " " + data.Lastname
          );
          window.sessionStorage.setItem("Firstname", data.FirstName);
          // window.sessionStorage.setItem("isLogin", "true");
          // window.sessionStorage.setItem("siteId", "1");
          window.sessionStorage.setItem("mobileNumber", data.Mobile);
          // window.sessionStorage.setItem("role", "ROLE_USER");
          $(".patientMenuTop").show();
          if (data.successMessage == "Login Success") {
            //alert("Login Success")
            window.sessionStorage.setItem("isLogin", "true");
            window.sessionStorage.setItem("siteId", "1");
            window.sessionStorage.setItem("role", "ROLE_USER");
            window.location.href = "/";
          } else {
            window.location.href = "/JoinAsMember";
          }
        }
      });
    }
    window.sessionStorage.setItem("homeName", "Reliance");
    //this.getLoadHomePage();
    //if(window.sessionStorage.getItem("pathname")!="null"){
    // window.location.href = window.sessionStorage.getItem("pathname")
    // }
    window.sessionStorage.setItem("pathname", window.location.pathname);

    if (folderName) {
      this.setState({ isLoader: true });
    }
    !this.props.match.params.token && this.getLoadHomePage();
    this.props.match.params.token && this.decryptPulseURL();
  }
  getLoadHomePage() {
    if (!this.state.siteId) {
      //alert();
      //alert(window.location.host);
      const jsonReq = {
        method: "POST",
        body: JSON.stringify({
          functionName: "getSiteDetails",
          siteId: "1",
          siteUrl: window.location.host,
        }),
      };

      apiCalling(jsonReq).then((data) => {
        if (data.success === "1") {
          // console.log(data.siteDetailsData[0].siteData[0]);
          var location = data.siteDetailsData[0].siteData[0].siteName;
          let is_theme_based =
            data.siteDetailsData[0].siteData[0].is_theme_based;
          let project_folder =
            data.siteDetailsData[0].siteData[0].project_folder;
          //alert(project_folder);
          var folder = "Reliance";
          // if (is_theme_based === false && project_folder !== "") {
          //   folder = project_folder;
          // } else {
          //   folder = data.siteDetailsData[0].siteData[0].themeName;
          // }
          //folder = data.siteDetailsData[0].siteData[0].title;
          //alert(folder);
          folderName = folder;
          window.sessionStorage.setItem(
            "groupHospitalAdminId",
            data.siteDetailsData[0].groupHospitalAdminData[0]
              .groupHospitalAdminId
          );
          window.sessionStorage.setItem(
            "themeId",
            data.siteDetailsData[0].siteData[0].themeId
          );
          window.sessionStorage.setItem("siteId", "1");
          window.sessionStorage.setItem("themeVersion", "0");
          window.sessionStorage.setItem("homeName", folder);
          window.sessionStorage.setItem("reload", "1");
          window.sessionStorage.setItem(
            "dashboard_html",
            data.siteDetailsData[0].siteData[0].dashboard_html
          );
          window.sessionStorage.setItem(
            "theme_custom_color",
            data.siteDetailsData[0].siteData[0].theme_custom_color
          );
          window.sessionStorage.setItem(
            "contact_emailId",
            data.siteDetailsData[0].siteData[0].emailId
          );
          window.sessionStorage.setItem(
            "contact_no",
            data.siteDetailsData[0].siteData[0].contact_no
          );
          window.sessionStorage.setItem(
            "fav_icon",
            data.siteDetailsData[0].siteData[0].fav_icon
          );
          window.sessionStorage.setItem(
            "seo_description",
            data.siteDetailsData[0].siteData[0].seo_description
          );
          window.sessionStorage.setItem(
            "seo_keywords",
            data.siteDetailsData[0].siteData[0].seo_keywords
          );
          window.sessionStorage.setItem(
            "seo_title",
            data.siteDetailsData[0].siteData[0].seo_title
          );
          window.sessionStorage.setItem(
            "slug",
            data.siteDetailsData[0].siteData[0].slug
          );
          window.sessionStorage.setItem(
            "googleId",
            data.siteDetailsData[0].siteData[0].google_analytics_script_id
          );
          window.sessionStorage.setItem(
            "siteName",
            data.siteDetailsData[0].siteData[0].siteName
          );
          window.sessionStorage.setItem(
            "book_appointment_terms",
            data.siteDetailsData[0].siteData[0].book_appointment_terms
          );
          window.sessionStorage.setItem(
            "video_chat_terms",
            data.siteDetailsData[0].siteData[0].video_chat_terms
          );
          window.sessionStorage.setItem(
            "hospital_working_time",
            data.siteDetailsData[0].siteData[0].hospital_working_time
          );
          window.sessionStorage.setItem(
            "site_url",
            data.siteDetailsData[0].siteData[0].site_url
          );
          window.sessionStorage.setItem(
            "fbPixel",
            data.siteDetailsData[0].siteData[0].fbPixel
          );
          window.sessionStorage.setItem(
            "hasHealthTracker",
            data.siteDetailsData[0].siteData[0].hasHealthTracker
          );
          window.sessionStorage.setItem(
            "footerData",
            JSON.stringify(data.siteDetailsData[0].footerData)
          );

          window.sessionStorage.setItem(
            "siteLogo",
            data.siteDetailsData[0].siteData[0].logo_path
          );
          this.setState({ isLoader: true });
          window.sessionStorage.setItem(
            "siteId",
            data.siteDetailsData[0].siteData[0].id
          );
          const hosDataVar = JSON.stringify(
            data.siteDetailsData[0].hospitalData
          );
          window.sessionStorage.setItem(
            "siteServices",
            JSON.stringify(data.siteDetailsData[0].services)
          );

          if (hosDataVar) {
            window.sessionStorage.setItem(
              "footerhospitalData",
              JSON.stringify(data.siteDetailsData[0].hospitalData)
            );
            window.sessionStorage.setItem(
              "footerHospitalName",
              data.siteDetailsData[0].hospitalData.hospitalName
            );
            window.sessionStorage.setItem(
              "footerHospitalcity",
              data.siteDetailsData[0].hospitalData.city
            );
            window.sessionStorage.setItem(
              "footerHospitaladdress",
              data.siteDetailsData[0].hospitalData.address
            );
            window.sessionStorage.setItem(
              "footerHospitalstateName",
              data.siteDetailsData[0].hospitalData.stateName
            );
            window.sessionStorage.setItem(
              "footerHospitalzipCode",
              data.siteDetailsData[0].hospitalData.zipCode
            );
          }
          //alert(data.siteDetailsData[0].themeSectionList.length);
          for (
            let i = 0;
            i < data.siteDetailsData[0].themeSectionList.length;
            i++
          ) {
            window.sessionStorage.setItem(
              data.siteDetailsData[0].themeSectionList[i].themeSectionName,
              JSON.stringify(
                data.siteDetailsData[0].themeSectionList[i].themeSectionContent
              )
            );
          }
          window.sessionStorage.setItem(
            "doctorDetails",
            JSON.stringify(data.siteDetailsData[0].doctorDetails)
          );
          // alert(window.sessionStorage.getItem("pathname"))
          if (String(window.sessionStorage.getItem("pathname")) != "null") {
            window.location.href = window.sessionStorage.getItem("pathname");
            //window.location.href="/maintenance"
          } else {
            // window.location.href ="/"
            // window.location.href="/maintenance"
          }
        }
      });
    }
  }
  videoAudioRedirectFunction() {
    //alert();
    //alert(window.location.host);
    const jsonReq = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getSiteDetails",
        siteId: "1",
        siteUrl: "",
      }),
    };
    apiCalling(jsonReq).then((data) => {
      if (data.success == "1") {
        // console.log(data.siteDetailsData[0].siteData[0]);
        var location = data.siteDetailsData[0].siteData[0].siteName;
        let is_theme_based = data.siteDetailsData[0].siteData[0].is_theme_based;
        let project_folder = data.siteDetailsData[0].siteData[0].project_folder;
        //alert(project_folder);
        var folder = "Reliance";
        // if (is_theme_based === false && project_folder !== "") {
        //   folder = project_folder;
        // } else {
        //   folder = data.siteDetailsData[0].siteData[0].themeName;
        // }
        //folder = data.siteDetailsData[0].siteData[0].title;
        //alert(folder);
        folderName = folder;
        window.sessionStorage.setItem(
          "groupHospitalAdminId",
          data.siteDetailsData[0].groupHospitalAdminData[0].groupHospitalAdminId
        );
        window.sessionStorage.setItem(
          "themeId",
          data.siteDetailsData[0].siteData[0].themeId
        );
        window.sessionStorage.setItem("siteId", "1");
        window.sessionStorage.setItem("themeVersion", "0");
        window.sessionStorage.setItem("homeName", folder);
        window.sessionStorage.setItem("reload", "1");
        window.sessionStorage.setItem(
          "dashboard_html",
          data.siteDetailsData[0].siteData[0].dashboard_html
        );
        window.sessionStorage.setItem(
          "theme_custom_color",
          data.siteDetailsData[0].siteData[0].theme_custom_color
        );
        window.sessionStorage.setItem(
          "contact_emailId",
          data.siteDetailsData[0].siteData[0].emailId
        );
        window.sessionStorage.setItem(
          "contact_no",
          data.siteDetailsData[0].siteData[0].contact_no
        );
        window.sessionStorage.setItem(
          "fav_icon",
          data.siteDetailsData[0].siteData[0].fav_icon
        );
        window.sessionStorage.setItem(
          "seo_description",
          data.siteDetailsData[0].siteData[0].seo_description
        );
        window.sessionStorage.setItem(
          "seo_keywords",
          data.siteDetailsData[0].siteData[0].seo_keywords
        );
        window.sessionStorage.setItem(
          "seo_title",
          data.siteDetailsData[0].siteData[0].seo_title
        );
        window.sessionStorage.setItem(
          "slug",
          data.siteDetailsData[0].siteData[0].slug
        );
        window.sessionStorage.setItem(
          "googleId",
          data.siteDetailsData[0].siteData[0].google_analytics_script_id
        );
        window.sessionStorage.setItem(
          "siteName",
          data.siteDetailsData[0].siteData[0].siteName
        );
        window.sessionStorage.setItem(
          "book_appointment_terms",
          data.siteDetailsData[0].siteData[0].book_appointment_terms
        );
        window.sessionStorage.setItem(
          "video_chat_terms",
          data.siteDetailsData[0].siteData[0].video_chat_terms
        );
        window.sessionStorage.setItem(
          "hospital_working_time",
          data.siteDetailsData[0].siteData[0].hospital_working_time
        );
        window.sessionStorage.setItem(
          "site_url",
          data.siteDetailsData[0].siteData[0].site_url
        );
        window.sessionStorage.setItem(
          "fbPixel",
          data.siteDetailsData[0].siteData[0].fbPixel
        );
        window.sessionStorage.setItem(
          "hasHealthTracker",
          data.siteDetailsData[0].siteData[0].hasHealthTracker
        );
        window.sessionStorage.setItem(
          "footerData",
          JSON.stringify(data.siteDetailsData[0].footerData)
        );

        window.sessionStorage.setItem(
          "siteLogo",
          data.siteDetailsData[0].siteData[0].logo_path
        );
        this.setState({ isLoader: true });
        window.sessionStorage.setItem(
          "siteId",
          data.siteDetailsData[0].siteData[0].id
        );
        const hosDataVar = JSON.stringify(data.siteDetailsData[0].hospitalData);
        window.sessionStorage.setItem(
          "siteServices",
          JSON.stringify(data.siteDetailsData[0].services)
        );

        if (hosDataVar) {
          window.sessionStorage.setItem(
            "footerhospitalData",
            JSON.stringify(data.siteDetailsData[0].hospitalData)
          );
          window.sessionStorage.setItem(
            "footerHospitalName",
            data.siteDetailsData[0].hospitalData.hospitalName
          );
          window.sessionStorage.setItem(
            "footerHospitalcity",
            data.siteDetailsData[0].hospitalData.city
          );
          window.sessionStorage.setItem(
            "footerHospitaladdress",
            data.siteDetailsData[0].hospitalData.address
          );
          window.sessionStorage.setItem(
            "footerHospitalstateName",
            data.siteDetailsData[0].hospitalData.stateName
          );
          window.sessionStorage.setItem(
            "footerHospitalzipCode",
            data.siteDetailsData[0].hospitalData.zipCode
          );
        }
        //alert(data.siteDetailsData[0].themeSectionList.length);
        for (
          let i = 0;
          i < data.siteDetailsData[0].themeSectionList.length;
          i++
        ) {
          window.sessionStorage.setItem(
            data.siteDetailsData[0].themeSectionList[i].themeSectionName,
            JSON.stringify(
              data.siteDetailsData[0].themeSectionList[i].themeSectionContent
            )
          );
        }
        window.sessionStorage.setItem(
          "doctorDetails",
          JSON.stringify(data.siteDetailsData[0].doctorDetails)
        );
        // alert(window.sessionStorage.getItem("pathname"))
        if (String(window.sessionStorage.getItem("pathname")) != "null") {
          window.location.href = window.sessionStorage.getItem("pathname");
          //window.location.href="/maintenance"
        } else {
          // window.location.href ="/"
          // window.location.href="/maintenance"
        }
        window.location.href = "/doctor/videoSchedule";
      }
    });
  }

  decryptPulseURL = () => {
    // console.log("inside redirection");
    // console.log(this.props);
    const decryptPulseJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "decryptPulseUrl",
        encryptedData: this.props.match.params.token,
      }),
    };
    apiCalling(decryptPulseJson).then((data) => {
      if (data.success == "1") {
        // console.log("data", data);
        window.sessionStorage.setItem("userId", data.userData[0].userId);
        window.sessionStorage.setItem("emailId", data.userData[0].emailId);
        window.sessionStorage.setItem("userName", data.userData[0].userName);
        window.sessionStorage.setItem(
          "logedSiteId",
          data.userData[0].siteUrl.split("/").pop()
        );
        window.sessionStorage.setItem("isLogin", "true");
        $("#showMyName").text(data.userData[0].userName);
        window.sessionStorage.setItem(
          "mobileNumber",
          data.userData[0].mobileNumber
        );
        window.sessionStorage.setItem("role", data.userData[0].role);
        $(".doctorMenuTop").show();
        this.videoAudioRedirectFunction();
        // this.setState(
        //   {
        //     siteId: data.userData[0].siteUrl.split("/").pop(),
        //   },
        //   () => {
        //     // console.log("this.state.siteId", this.state.siteId);
        //     // window.location.host.includes("localhost") &&
        //     //   this.callSiteRequest();
        //     // this.props.match.params.siteId &&

        //   }
        // );
      }
    });
  };

  render() {
    return (
      // <div className="purpleWrap home bg-clr-wthHead">
      <div>
        <Favicon url={window.sessionStorage.getItem("fav_icon")} />
        <Helmet>
          <title></title>
          <meta http-equiv="cache-control" content="no-cache" />
          <meta http-equiv="expires" content="0" />
          <meta http-equiv="pragma" content="no-cache" />
        </Helmet>
        {this.state.isLoader === false ? (
          <div
            style={{ padding: "30vh", textAlign: "center", display: "none" }}
          >
            <img src={loaderImg} alt="" />
          </div>
        ) : (
          ""
        )}
        {folderName && (
          <Suspense fallback={<div></div>}>
            <section>
              <Header />
              <Homepage />
              <Footer />
            </section>
          </Suspense>
        )}
      </div>
    );
  }
}
