import React, { Component, Suspense } from "react";
import * as ReactDOM from "react-dom";
import bolgtemp from "../image/icons/blogging.png";
import AccCore from "opentok-accelerator-core";
import "opentok-solutions-css";
import { Link } from "react-router-dom";
import "../doctor/App.css";
import $ from "jquery";
import moment from "moment";
import classNames from "classnames";
import { apiCalling } from "../apiService";
import avatar from "../image/media/avatar.png";
import microphoneImg from "../image/icons/microphone.png";
import recordGif from "../image/icons/audio.gif";
import pauseImg from "../image/icons/stop-icon.png";
import pdfImg from "../image/icons/pdf.png";
import deleteImg from "../image/theme/closered.png";
import avatarImg from "../image/media/profile.png";
import bokenImg from "../image/icons/bokenImg.png";
import AWS from "aws-sdk";
import MicRecorder from "mic-recorder-to-mp3";
import { S3ImagePath } from "../config";
const imgPath = S3ImagePath();
const endchatmsg = "";
const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
let otCore;
var s3;
const otCoreOptions = {
  credentials: {
    apiKey: window.sessionStorage.getItem("API_KEY"),
    sessionId: window.sessionStorage.getItem("sessionId"),
    token: window.sessionStorage.getItem("token"),
  },
  limitCharacterMessage: 250,
  controlsContainer: "#controls",
  packages: ["textChat"],
  communication: {
    callProperties: null, // Using default
  },
  textChat: {
    name: [window.sessionStorage.getItem("userName")], // eslint-disable-line no-bitwise
    waitingMessage: "",
    container: "#chat",
    alwaysOpen: true,
  },
};

/**
 * Build classes for container elements based on state
 * @param {Object} state
 */
const containerClasses = (state) => {
  const { active } = state;
  return {
    controlClass: classNames("App-control-container", { hidden: !active }),

    localCallClass: classNames("ots-video-control circle end-call", {
      hidden: !active,
    }),
  };
};

export default class Chatmessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      connected: false,
      active: false,
      publishers: null,
      subscribers: null,
      meta: null,
      localAudioEnabled: false,
      localVideoEnabled: false,
      file: "",
      s3bucketAccessKey: "",
      AWSAccessKeyId: "",
      bucket: "",
      fullFilePath: "",
      region: "us-east-1",
      question: "",
      questionAns: "",
      questionId: "",
      questionFlag: "0",
      oldMessage: [],
      isExpired: false,
      headerMessage: "",
      doctorId: "",
      memberId: "",
      chatDoctorPanelId: "",
      messaging: "",
      payAmount: "",
      docImgLink:
        "https://purplehealthfiles.s3.amazonaws.com/test/3c8b7d84-d3a1-4773-9ba4-8a2e3174fb3bgoogle-docs.png",
      errorOccurred: false,
      docImgPath: "https://rhealthfiles.s3.amazonaws.com/test/doctorimages",
      docImgName: "",
      docImgNameValue: "",
      isRecording: false,
      blobURL: "",
      isBlocked: false,
      voiceRecordFileName: "",
      voiceRecordFile: "",
      profileImagedoc: "",
    };
    this.startCall = this.startCall.bind(this);
    this.endCall = this.endCall.bind(this);
    this.fileChange = this.fileChange.bind(this);
    this.sendAsMail = this.sendAsMail.bind(this);
    this.endChat = this.endChat.bind(this);
    $(".loader").show();
  }

  fileChange(e) {
    var imageFile = e.target.files[0];
    if (imageFile) {
      if (!imageFile.name.match(/\.(jpg|jpeg|png|pdf)$/)) {
        alert("Only accept PDF,JPG,JPEG,PNG formats");
        e.target.value = null;
        return false;
      } else {
        $(".loader").show();
        this.setState({ file: e.target.files[0] });
        this.fileUpload(e.target.files[0]);
        //console.log("1111111", e.target.files[0]);
        $("#attachImg").text(e.target.files[0].name);
        $("#aattachImg").attr("href", URL.createObjectURL(e.target.files[0]));
        $(".uploadattach").hide();
        $(".uploadattachBtn").show();
      }
    }
  }
  SendvoiceRecord() {
    $(".recording-delete").hide();
    let file = this.state.voiceRecordFile;
    let fileName = this.state.voiceRecordFileName;

    AWS.config.update({
      accessKeyId: this.state.s3bucketAccessKey,
      secretAccessKey: this.state.AWSAccessKeyId,
      region: this.state.region,
    });
    var bucketParams = {
      Bucket: this.state.bucket,
    };
    s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    let S3BucketMainFolder = this.state.bucketFolder + "/practicemanagment";
    //let fileName = this.imagepath1() + file.name;
    let fullFilePath = S3BucketMainFolder + "/" + fileName;
    //alert(fullFilePath);
    this.setState({ fileName: fileName });

    s3.upload(
      {
        Key: fullFilePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        $(".loader").hide();
        if (err) {
          alert("There was an error uploading your photo: ", err.message);
        } else if (data) {
          //console.log(data);
          this.setState({ fullFilePath: data.Location });
          //alert("successfully uploaded", data.message);
          $("#messageBox").val(data.Location);
          $("#sendMessage").click();
          $(".App-header").hide();
          $("#voiceSend").hide();

          return null;
        }
      }
    );
  }
  fileUpload(file) {
    AWS.config.update({
      accessKeyId: this.state.s3bucketAccessKey,
      secretAccessKey: this.state.AWSAccessKeyId,
      region: this.state.region,
    });
    var bucketParams = {
      Bucket: this.state.bucket,
    };
    s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    let S3BucketMainFolder = this.state.bucketFolder + "/practicemanagment";
    let fileName = this.imagepath1() + file.name;
    let fullFilePath = S3BucketMainFolder + "/" + fileName;
    //alert(fullFilePath);
    this.setState({ fileName: fileName });

    s3.upload(
      {
        Key: fullFilePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        $(".loader").hide();
        if (err) {
          alert("There was an error uploading your photo: ", err.message);
        } else if (data) {
          //console.log(data);
          this.setState({ fullFilePath: data.Location });
          //alert("successfully uploaded", data.message);
          this.onChangeFileUpload();
          return null;
        }
      }
    );
  }
  imagepath1 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };
  doSomething() {
    //alert();
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", this.doSomething(), false);
  }
  componentDidUpdate() {
    $("#messageBox").prop("maxlength", 250);
    if ($(".ots-character-count").html()) {
      $(".ots-character-count").html(
        $(".ots-character-count").html().replace("/160", "/250")
      );
    }

    //alert()

    //$(".loader").show();
    this.scrollToBottom();
  }
  messageReceived(fromMobileMessage, createdDate, senderName) {
    let recMsg = "";
    let messages = "";
    let curdateTime = moment(createdDate).format("DD MMM YYYY hh:mm A");
    // alert(curdateTime);
    let recMessage = fromMobileMessage;
    $(".ots-message-item").hide();
    //$(".ots-message-item.ots-message-sent").show();
    $(".ots-message-item.my-chat").show();
    $(".my-chat-span").next().hide();
    //$("#questionDiv1").show();
    var errorImg = bokenImg;
    let str = "";
    let checkme = "";
    if (recMessage.includes("https://")) {
      let checkmemp3 = this.checkURLMP3(recMessage);
      let checkWav = this.checkURLWAV(recMessage);
      let newmessages = recMessage
        .toString()
        .replace(".ap-south-1", "")
        .replace(".ap-south?", "");
      if (checkmemp3 === true || checkWav === true) {
        str = "<audio src=" + newmessages + " controls='controls' />";
      } else {
        checkme = this.checkURL(recMessage);
        if (checkme === true) {
          str =
            "<a href=" +
            newmessages +
            " ><img src=" +
            newmessages +
            " onError=" +
            errorImg +
            "></a>";
        } else {
          str =
            "<a href=" +
            recMessage
              .toString()
              .replace(".ap-south-1", "")
              .replace(".ap-south/?", "") +
            " ><img src=" +
            pdfImg +
            " onError=" +
            errorImg +
            "></a>";
        }
      }
    } else {
      str = recMessage;
      messages = str.replace("?-0", "?");
      messages = messages.replace("?-1", "?").replace("-1", "?");
      str = messages;
    }

    $("#messagesHolder").append(
      //recieve message
      '<div class="ots-message-item  my-chat">' +
        '<div class="ots-user-name-initial">' +
        // "<img src="+this.state.docImgName +" width='20'/>"+
        "<img src=" +
        avatarImg +
        " width='20'/>" +
        "</div>" +
        '<div class="ots-item-timestamp ">' +
        senderName +
        "," +
        curdateTime +
        " <span>" +
        "</span>" +
        "</div>" +
        '<div class="ots-item-text my-chat">' +
        "<span class='my-chat-span text-left'> " +
        str +
        "</span>" +
        "</div>" +
        "</div>"
    );

    $("#messagesHolder").scrollTop($("#messagesHolder")[0].scrollHeight);

    if (window.sessionStorage.getItem("role") === "ROLE_USER") {
      recMsg = recMessage.split(/\s*-\s*/);
      this.setState({ question: recMsg[1] });
      this.setState({ questionId: recMsg[0] });
      this.setState({ questionFlag: recMsg[2] });
      //alert(recMsg[1]);
      //this.setState({question:recMsg[1]})
      if (recMsg.length === 2) {
        // recMsg = recMessage.split(/\s*-\s*/);
        if (recMsg[1] === "0") {
          $(".ots-message-item:last-child .ots-item-text").append(
            $("#questionDiv0")
          );
          $(".my-chat-span").next("#questionDiv0").show();
        } else {
          $(".ots-message-item:last-child .ots-item-text").append(
            $("#questionDiv1")
          );
          $(".my-chat-span").next("#questionDiv1").show();
        }
      }
    }
  }
  startTextChat() {
    const json = {
      method: "POST",
      body: JSON.stringify({
        functionName: "startTextChat",
        chatGroupId: window.sessionStorage.getItem("chatDoctorPanelId"),
        userId: window.sessionStorage.getItem("userId"),
        hospitalId: window.sessionStorage.getItem("appHosId"),
      }),
    };
    // console.log(json.body);
    apiCalling(json).then((data, i) => {
      $(".loader").hide();
      let array = [];

      if (data.success === "1" && data.result[0].oldMessages.length > 0) {
        //   for (let i = 0; i < data.result[0].oldMessages.length ; i++) {

        // array.push(data.result[0].oldMessages[i].role)
        //     if(data.result[0].oldMessages[i].role ==="ROLE_DOCTOR"){
        //       this.setState({docImgName:avatarImg})
        //     }
        //     else{
        //       this.setState({docImgName:pdfImg})
        //     }
        //   }

        if (data.result[0].oldMessages.pop().role === "ROLE_USER") {
          this.setState({ docImgName: avatarImg });
        } else {
          this.setState({ docImgName: pdfImg });
        }

        // if(this.state.docImgName === false){
        //   this.setState({ docImgNameValue:data.result[0].oldMessages.pop().imageName })
        // }

        //         let existObj = [];
        //                           existObj = data.result[0].oldMessages.filter((x,i)=>{
        //                           return (x.role)

        //                         })

        //              if(existObj.pop().role === "ROLE_DOCTOR"){
        //                this.setState({docImgName:avatarImg})
        //              }

        //              else{
        //               this.setState({docImgName:pdfImg})
        //             }
      }
    });
  }
  componentDidMount() {
    // this.startTextChat()

    $(".loader").show();
    $(".uploadattachBtn").hide();
    $("#promoApplySection").hide();
    $("#chat-upload").hide();
    $(".messageFileUpload").hide();
    $("#startRecord").hide();
    window.addEventListener("hashchange", this.doSomething(), false);
    // setInterval(async () => {
    this.readMessage();
    // }, 10000);
    setInterval(async () => {
      this.readMessage();
    }, 5000);
    if (window.sessionStorage.getItem("role") === "ROLE_DOCTOR") {
      let defaultMessages = window.sessionStorage.getItem("defaultMessages");
      let defaultSplit = defaultMessages.split(/\s*,\s*/);
      let subSplit = "";

      defaultSplit.map((data) => {
        subSplit = data.split(/\s*-\s*/);
        $("#defaultQust").append(
          $("<option />").val([subSplit[2]]).text(subSplit[1])
        );
      });
    }
    this.awsCredentials();

    otCore = new AccCore(otCoreOptions);

    otCore.connect().then(() => {
      $(".loader").show();
      this.setState({ connected: true });
      this.startCall();
      //this.setState({ localVideoEnabled: false });
    });
    otCore.on("signal", (event) => {
      if (event.data.includes("sender")) {
        // alert("if")
        // let text = JSON.parse(event.data);
        //       let  textReceiver = text.text;
        //       alert(textReceiver)
      } else {
        //console.log("else....")
        let fromMobileMessage = event.data;
        let createdDate = event.from.creationTime;
        let who = event.from.data;
        let senderName = who.split(/\s*,\s*/);
        senderName = senderName[0];
        if (fromMobileMessage !== endchatmsg) {
          this.messageReceived(fromMobileMessage, createdDate, senderName);
        } else {
          //this.onRefresh()
        }

        //***************************************** */
      }
    });

    otCore.on("messageSent", (event) => {
      $(".ots-item-text").find(":contains('purplehealthfiles')").hide();
      let curdateTime = moment().format("DD MMM YYYY hh:mm A");
      var d = new Date();
      let messages = "";
      var milliseconds = d.getTime();
      //alert(milliseconds);
      $(".ots-message-item.ots-message-sent").hide();
      $(".my-chat-span").next().hide();
      let str = "";
      let checkme = "";
      if (event.message.includes("https://")) {
        let checkmemp3 = this.checkURLMP3(event.message);
        let checkWav = this.checkURLWAV(event.message);
        let newmessages = event.message
          .toString()
          .replace(".ap-south-1", "")
          .replace(".ap-south?", "");
        if (checkmemp3 === true || checkWav === true) {
          str = "<audio src=" + newmessages + " controls='controls' />";
        } else {
          checkme = this.checkURL(event.message);
          if (checkme === true) {
            str =
              "<a href=" + newmessages + " ><img src=" + newmessages + "></a>";
          } else {
            str =
              "<a href=" +
              event.message
                .toString()
                .replace(".ap-south-1", "")
                .replace(".ap-south/?", "") +
              " ><img src=" +
              pdfImg +
              "></a>";
          }
        }
      } else {
        str = event.message;
        messages = str
          .replace("?-0", "?")
          .replace("?-1", "?")
          .replace("-1", "?");
        str = messages;
      }

      $("#messagesHolder").append(
        //send message
        '<div class="ots-message-item ots-message-sent my-chat">' +
          '<div class="ots-user-name-initial">' +
          // "<img src="+this.state.docImgName  +" width='20'/>"+
          "<img src=" +
          avatarImg +
          " width='20'/>" +
          "</div>" +
          '<div class="ots-item-timestamp"> <span class="text-left">' +
          window.sessionStorage.getItem("userName") +
          "</span>, <span> " +
          curdateTime +
          "</span></div>" +
          '<div class="ots-item-text my-chat">' +
          "<span class='my-chat-span text-left'> " +
          str +
          "</span>" +
          "</div>" +
          "</div>"
      );

      $(".ots-message-item.ots-message-sent.my-chat").show();
      $("#messagesHolder").scrollTop($("#messagesHolder")[0].scrollHeight);
      // if (event.message.includes("https://")) {
      //   let checkme = this.checkURL(event.message);
      //   if (checkme === true) {
      //     $(".ots-message-sent:last-child .ots-item-text").append(
      //       "<a href=" + event.message + " ><img src=" + event.message + "></a>"
      //     );
      //   } else {
      //     $(".ots-message-sent:last-child .ots-item-text").append(
      //       "<a href=" +
      //         event.message +
      //         " ><img src='https://purplehealthfiles.s3.amazonaws.com/test/3c8b7d84-d3a1-4773-9ba4-8a2e3174fb3bgoogle-docs.png'/></a>"
      //     );
      //   }
      // }
      //alert("save data start.")
      const saveData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "chat/saveChat",
          siteId: window.sessionStorage.getItem("siteId"),
          hospitalId: window.sessionStorage.getItem("appHosId"),
          chatGroupId: window.sessionStorage.getItem("chatDoctorPanelId"),
          userId: window.sessionStorage.getItem("userId"),
          message: event.message,
          currentTimeMillsec: milliseconds,
          bundleId: "",
        }),
      };
      //console.log(saveData.body);
      apiCalling(saveData).then((data) => {
        this.startTextChat();
        //alert(data.success);
      });
    });

    $("#myClickEvent").click(function (e) {
      alert("myClickEvent");
    });

    otCore.on("messageReceived", (event) => {
      // console.log(event);
      let recMsg = "";
      let messages = "";
      //alert("event.data..." + event.data);
      let dataSet = JSON.parse(event.data);
      let sender = JSON.stringify(dataSet.sender);
      //alert("dataSet..." + sender);
      let dataSet2 = JSON.parse(sender);
      let senderName = dataSet2.alias;
      let curdateTime = moment(dataSet2.sentOn).format("DD MMM YYYY hh:mm A");
      //alert(curdateTime);
      let recMessage = dataSet.text;
      if (recMessage !== endchatmsg) {
        this.messageReceived(recMessage, dataSet2.sentOn, senderName);
      } else {
        this.onRefresh();
      }
    });

    // setInterval(async () => {
    $(".cancelPrescription").click(function () {
      $("#prescription").val("");
      $("#callcompltd_text").hide();
    });
    $(".voiceRecord_close").click(function () {
      $("#callcompltd_text_r").hide();
    });

    $("#savePrescription").click(function () {
      $("#savePrescription").prop("disabled", true);
      $(".loader").show();
      if ($("#prescription").val() !== "") {
        const apiJson = {
          method: "POST",
          body: JSON.stringify({
            functionName: "prescribedMedicineContent",
            siteId: window.sessionStorage.getItem("siteId"),
            doctorId: window.sessionStorage.getItem("userId"),
            userId: $("#pre_memberId").val(),
            hospitalId: $("#pre_hospitalId").val(),
            prescription: $("#prescription").val(),
          }),
        };
        // console.log(apiJson.body);
        apiCalling(apiJson).then((data) => {
          $(".loader").hide();
          if (data.success === "1") {
            alert("Send Successfully");
            $("#prescription").val("");
            $("#callcompltd_text").hide();
            $("#savePrescription").prop("disabled", false);
          }
        });
      } else {
        alert("Please enter prescription field.");
        $("#prescription").focus();
        $("#savePrescription").prop("disabled", false);
      }
    });
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;

    if (typeof navigator.mediaDevices.getUserMedia === "undefined") {
      navigator.getUserMedia(
        {
          audio: true,
        },
        () => {
          // console.log('Permission Granted');
          this.setState({ isBlocked: false });
        },
        () => {
          // console.log('Permission Denied');
          this.setState({ isBlocked: true });
        }
      );
    } else {
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
        })
        .then(() => {
          // console.log('Permission Granted');
          this.setState({ isBlocked: false });
        })
        .catch(() => {
          // console.log('Permission Denied');
          this.setState({ isBlocked: true });
        });
    }

    // navigator.getUserMedia({ audio: true },
    //   () => {
    //    // console.log('Permission Granted');
    //     this.setState({ isBlocked: false });
    //   },
    //   () => {
    //    // console.log('Permission Denied');
    //     this.setState({ isBlocked: true })
    //   },
    // );
  }
  start = () => {
    $(".App-header").show();
    $(".recording-delete").show();
    $("#recStop").show();

    if (this.state.isBlocked) {
      // console.log('Permission Denied');
    } else {
      Mp3Recorder.start()
        .then(() => {
          $(".recording-icon").show();
          $("#recordedAudio").hide();
          this.setState({ isRecording: true });
          $("#voiceSend").hide();
        })
        .catch((e) => console.error(e));
    }
  };

  stop = () => {
    $("#recStop").hide();
    $(".recording-icon").hide();
    $(".recording-delete").show();
    $("#recordedAudio").show();
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        this.setState({ blobURL, isRecording: false });
        var d = new Date();
        var newDate = d.getTime();
        let fileName = newDate + ".wav";
        const file = new File(buffer, fileName, {
          type: blob.type,
          lastModified: Date.now(),
        });
        this.setState({ voiceRecordFile: file });
        this.setState({ voiceRecordFileName: fileName });
        $("#voiceSend").show();
      })
      .catch((e) => console.log(e));
  };
  deleteRecord() {
    this.setState({ blobURL: "" });
    $(".App-header").hide();
  }
  endChat() {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "completeChatStatus",
        requestVideoAppointmentId: window.sessionStorage.getItem(
          "doctorVideoScheduleId"
        ),
        appointmentStatus: "Call Completed",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        alert("Call ended");
      }
      $(".loader").hide();
    });
  }

  startCall() {
    this.readMessage();
    let oldData = [];
    $(".loader").show();
    let messages = "";
    let str = "";
    const json = {
      method: "POST",
      body: JSON.stringify({
        functionName: "startTextChat",
        chatGroupId: window.sessionStorage.getItem("chatDoctorPanelId"),
        userId: window.sessionStorage.getItem("userId"),
        hospitalId: window.sessionStorage.getItem("appHosId"),
      }),
    };
    // console.log("json.body", json.body);
    apiCalling(json).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
        $("#messagesHolder").empty();
        // alert("...." + data.result[0].oldMessages);
        $("#startRecord").show();
        $(".messageFileUpload").show();
        //alert(data.result[0].isExpired);
        // alert(data.result[0].messagingCost);
        this.setState({ oldMessage: data.result[0].oldMessages });
        oldData.push(data.result[0].oldMessages);
        this.setState({ isExpired: data.result[0].isExpired });
        window.sessionStorage.setItem("appDocId", data.result[0].doctorId);
        window.sessionStorage.setItem("appHosId", data.result[0].hospitalId);
        window.sessionStorage.setItem(
          "chatDoctorPanelId",
          data.result[0].chatGroupId
        );
        window.sessionStorage.setItem(
          "doctorPanelId",
          data.result[0].chatDoctorPanelId
        );
        window.sessionStorage.setItem(
          "messaging",
          data.result[0].messagingCost
        );
        window.sessionStorage.setItem("memberId_text", data.result[0].memberId);
        this.setState({ payAmount: data.result[0].messagingCost });
        if (window.sessionStorage.getItem("role") === "ROLE_USER") {
          if (data.result[0].isExpired === true) {
            $(".ots-messages-holder").addClass("ots-messages-holder-isExpired");
            $("#messageBox").hide();
            $("#startRecord").hide();
            $("#sendMessage").hide();
            $("#controls").hide();
            $("#boottomDiv").hide();
            $("#paymentReqBlock").show();
            $(".messageFileUpload").hide();
            //alert(data.result[0].messagingCost);
            if (data.result[0].messagingCost === 0) {
              $("#withoutPayment").show();
              this.setState({
                headerMessage: "To continue chat, Please click continue",
              });
            } else {
              $("#withPayment").show();
              this.setState({
                headerMessage: "To continue chat, Please make payment",
              });
            }
          }
        }

        let flag = 0;
        data.result[0].oldMessages.map((vid, vin) => {
          let showBlock = "";
          flag = flag + 1;
          if (
            vid.role === "ROLE_HOSPITALADMIN" &&
            window.sessionStorage.getItem("role") === "ROLE_DOCTOR"
          ) {
          } else {
            if (vid.message.includes("https://")) {
              let checkmemp3 = this.checkURLMP3(vid.message);
              let checkWav = this.checkURLWAV(vid.message);

              let newmessages = vid.message
                .toString()
                .replace(".ap-south-1", "")
                .replace(".ap-south?", "");
              //.ap-south/?
              if (checkmemp3 === true || checkWav === true) {
                str = "<audio src=" + newmessages + " controls='controls' />";
              } else {
                let checkme = this.checkURL(vid.message);
                if (checkme === true) {
                  str =
                    "<a href=" +
                    newmessages +
                    " ><img src=" +
                    newmessages +
                    "></a>";
                } else {
                  str =
                    "<a href=" +
                    vid.message
                      .toString()
                      .replace(".ap-south-1", "")
                      .replace(".ap-south/?", "") +
                    " ><img src=" +
                    pdfImg +
                    "></a>";
                }
              }
            } else {
              str = vid.message;
              if (str.includes("?-0")) {
                messages = str
                  .replace("?-0", "?")
                  .replace("?-1", "?")
                  .replace("-1", "?");
                str = messages;
                showBlock = $("#questionDiv0").html();
              }
              if (str.includes("?-1")) {
                messages = str
                  .replace("?-0", "?")
                  .replace("?-1", "?")
                  .replace("-1", "?");
                str = messages;
                showBlock = $("#questionDiv1").html();
              }
            }
            str = str
              .replace("?-0", "?")
              .replace("?-1", "?")
              .replace("-1", "?");
            if (
              parseInt(vid.senderId) ===
              parseInt(window.sessionStorage.getItem("userId"))
            ) {
              $("#messagesHolder").append(
                '<div class="ots-message-item ots-message-sent my-chat">' +
                  '<div class="ots-user-name-initial"> ' +
                  "<img src=" +
                  avatarImg +
                  " width='20'/>" +
                  // "<img src="+this.state.docImgPath+"/"+ vid.imageName+" width='20'/>"+
                  "</div>" +
                  '<div class="ots-item-timestamp">' +
                  vid.sender +
                  ', <span <="" span="">' +
                  moment(vid.createdOnMillSec).format("DD MMM YYYY hh:mm A") +
                  "</span></div>" +
                  '<div class="ots-item-text">' +
                  "<span class='text-left'> " +
                  str +
                  "</span>" +
                  "<div id=abcd" +
                  flag +
                  "></div>" +
                  // showBlock +
                  "</div>" +
                  "</div>" +
                  "</div>"
              );
              //$("#abcd" + flag).append($("#questionDiv1"));
            } else {
              $("#messagesHolder").append(
                '<div class="ots-message-item my-chat">' +
                  '<div class="ots-user-name-initial"> ' +
                  "<img src=" +
                  avatarImg +
                  " width='20'/>" +
                  // "<img src="+this.state.docImgPath+"/"+ vid.imageName+ " width='20'/>"+
                  "</div>" +
                  '<div class="ots-item-timestamp"> <span class="text-left">' +
                  vid.sender +
                  '</span>, <span <="" span="">' +
                  moment(vid.createdOnMillSec).format("DD MMM YYYY hh:mm A") +
                  "</span></div>" +
                  '<div class="ots-item-text">' +
                  "<span class='text-left'> " +
                  str +
                  "</span>" +
                  showBlock +
                  "<div id=abcd" +
                  flag +
                  "></div>" +
                  "</div>" +
                  "</div>"
              );
              // if (showBlock !== "") {
              //   $("#abcd" + flag).append($("#questionDiv0"));
              // }
            }

            //     if (event.message.includes("https://")) {
            //   let checkme = this.checkURL(event.message);
            //   if (checkme === true) {
            //     $(".ots-message-sent:last-child .ots-item-text").append(
            //       "<a href=" + event.message + " ><img src=" + event.message + "></a>"
            //     );
            //   } else {
            //     $(".ots-message-sent:last-child .ots-item-text").append(
            //       "<a href=" +
            //         event.message +
            //         " ><img src='https://purplehealthfiles.s3.amazonaws.com/test/3c8b7d84-d3a1-4773-9ba4-8a2e3174fb3bgoogle-docs.png'/></a>"
            //     );
            //   }
            // }
            // }, 5000);
            $("#messagesHolder").scrollTop(
              $("#messagesHolder")[0].scrollHeight
            );
          }
        });

        $(".loader").hide();
      }
    });

    //this.showDivBlock(oldData);
  }

  endCall() {}

  uploadattach() {
    $(".App-chat-attach .messageFileUpload").addClass("messageloader");
  }
  upload() {
    $("#chat-upload").show();
  }
  havePromoCode() {
    $("#promoApplySection").show();
    $("#havePromoCode").hide();
  }
  sendAsMail() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "chat/sendChatAsMail",
        siteId: window.sessionStorage.getItem("siteId"),
        chatGroupId: window.sessionStorage.getItem("chatDoctorPanelId"),
        userId: window.sessionStorage.getItem("userId"),
        bundleId: "",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
      }
      alert("Your chat messages have been send to your mail successfully.");
    });
  }
  endChatButton() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "endChat",
        siteId: window.sessionStorage.getItem("siteId"),
        chatGroupId: window.sessionStorage.getItem("chatDoctorPanelId"),
        userId: window.sessionStorage.getItem("userId"), //"19906",
        bundleId: "",
      }),
    };
    // console.log(apiJson.body);
    // debugger;
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        if (window.sessionStorage.getItem("role") !== "ROLE_USER") {
          $("#messageBox").val(endchatmsg);
          $("#sendMessage").click();
        }

        $(".loader").hide();
        window.location.href = "../chat/chatmessagegroup";
      }
    });
  }
  oldchat() {
    // $("#oldchat").show();
    //alert();
  }
  awsCredentials() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
        siteId: window.sessionStorage.getItem("siteId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          s3bucketAccessKey: data.configPathDetails[0].s3bucketAccessKey,
        });
        this.setState({
          AWSAccessKeyId: data.configPathDetails[0].s3bucketSecretKey,
        });

        this.setState({
          bucket: data.configPathDetails[0].s3BucketReliance,
        });
        this.setState({
          bucketFolder: data.configPathDetails[0].S3BucketMainFolder,
        });
      }
    });
  }
  onChangeFileUpload() {
    let fullPAth = this.state.fullFilePath;
    //$("#messageBox").val("sss");
    //$("#sendMessage").click();
    $("#messageBox").val(fullPAth);
    $("#sendMessage").click();
    $(".uploadattachBtn").hide();
    $(".uploadattach").show();
  }
  checkURL(url) {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }
  checkURLMP3(url) {
    return url.match(/\.(mp3)$/) != null;
  }
  checkURLWAV(url) {
    return url.match(/\.(wav)$/) != null;
  }
  onChangeDefaultQuestion() {
    let defaultQustVal = $("#defaultQust").val();
    let defaultQustText =
      $("#defaultQust option:selected").text() + "-" + defaultQustVal;

    $("#messageBox").val(defaultQustText);

    $("#sendMessage").click();
    $("select#defaultQust").val("12");
  }
  checkMyStatus(status) {
    $("#messageBox").val(status);
    $("#sendMessage").click();
  }
  textChatPayNow() {
    window.sessionStorage.setItem(
      "chatDoctorPanelId",
      window.sessionStorage.getItem("doctorPanelId")
    );
    window.sessionStorage.setItem("messaging_cost", this.state.payAmount);
    window.location.href = "/patient/textchatpayment";
  }
  chatmessageClickValidate() {
    let doctorId = window.sessionStorage.getItem("appDocId");
    let hospitalId = window.sessionStorage.getItem("appHosId");
    let chatDoctorPanelId = window.sessionStorage.getItem("doctorPanelId");

    $(".loader").show();
    const jsonData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "messageChatFree",
        chatDoctorPanelId: chatDoctorPanelId,
        memberId: window.sessionStorage.getItem("userId"),
        doctorId: doctorId,
        hospitalId: hospitalId,
      }),
    };
    // console.log(jsonData.body);
    apiCalling(jsonData).then((data) => {
      // debugger;
      if (data.success === "1") {
        const json = {
          method: "POST",
          body: JSON.stringify({
            functionName: "startTextChat",
            chatGroupId: data.chatGroupId,
            userId: window.sessionStorage.getItem("userId"),
            hospitalId: hospitalId,
          }),
        };
        let chatGroupId = data.chatGroupId;
        // console.log("json.body", json.body);
        apiCalling(json).then((data) => {
          $(".loader").hide();
          if (data.success === "1") {
            //console.log("Data: ", data);
            window.sessionStorage.setItem("chatStore", data.result[0]);
            window.sessionStorage.setItem("API_KEY", data.result[0].API_KEY);
            window.sessionStorage.setItem(
              "sessionId",
              data.result[0].sessionId
            );
            window.sessionStorage.setItem("token", data.result[0].token);
            window.sessionStorage.setItem(
              "memberId_text",
              data.result[0].memberId
            );
            window.sessionStorage.setItem("appDocId", doctorId);
            window.sessionStorage.setItem("appHosId", hospitalId);
            //window.sessionStorage.setItem("chatDoctorPanelId", chatDoctorPanelId);
            window.sessionStorage.setItem("chatDoctorPanelId", chatGroupId);
            window.location.href = "/chat/chatmessage";
          }
        });
      }
    });
  }
  onRefresh() {
    this.startCall();
  }

  readMessage() {
    const jsontest = {
      method: "POST",
      body: JSON.stringify({
        functionName: "chat/markChatIsRead",
        chatGroupId: window.sessionStorage.getItem("chatDoctorPanelId"),
        userId: window.sessionStorage.getItem("userId"),
        bundleId: "",
        siteId: window.sessionStorage.getItem("siteId"),
      }),
    };
    apiCalling(jsontest).then((data) => {});
  }
  callcompltd() {
    alert("lllll");
    $("document").ready(function () {
      $("#prescription").focus();
    });
    $("#pre_memberId").val(window.sessionStorage.getItem("memberId_text"));
    $("#pre_hospitalId").val(window.sessionStorage.getItem("appHosId"));
    $("#pre_doctorId").val(window.sessionStorage.getItem("userId"));
    $("#prescription").val("");
    $("#callcompltd_text").show();
  }
  cancelPrescription() {
    $("#prescription").val("");
    $("#callcompltd_text").hide();
  }
  voiceRecord() {
    this.start();
  }
  srcTypeImage(ev) {
    ev.target.src = bolgtemp;
  }
  render() {
    // const { connected, active } = this.state;
    //$(".loader").show();
    const { controlClass } = containerClasses(this.state);
    return (
      <div class="purpleWrap">
        <Suspense
          fallback={
            <div>
              <h1>Loading profile...</h1>
            </div>
          }
        >
          <section>
            <Header />

            <div class="container">
              <div style={{ display: "none" }}>
                <div id="questionDiv0" class="unni123">
                  <p class="text-center">
                    <input
                      type="button"
                      class="chatQstnBtn questionDiv0Yes"
                      value="Yes"
                      onClick={() => this.checkMyStatus("Yes")}
                    />
                    <input
                      type="button"
                      class="chatQstnBtn"
                      value="No"
                      onClick={() => this.checkMyStatus("No")}
                    />
                  </p>
                </div>
                <div id="questionDiv1">
                  <p class="text-center">
                    <input
                      type="button"
                      class="chatQstnBtn questionDiv1"
                      value="Yes"
                      onClick={() => this.checkMyStatus("Yes")}
                    />
                    <input
                      type="button"
                      class="chatQstnBtn"
                      value="No"
                      onClick={() => this.checkMyStatus("No")}
                    />

                    <input
                      type="button"
                      class="chatQstnBtn"
                      value="May Be"
                      onClick={() => this.checkMyStatus("May Be")}
                    />
                  </p>
                </div>
              </div>
              <div class="main-wrapper-smwidth1">
                <div class="chat-outer">
                  <div
                    ref={(el) => {
                      this.messagesEnd = el;
                    }}
                  ></div>
                  <div class="chat-header">
                    <span class="form-head">Chats</span>
                    <span class="chat-span">
                      <button
                        type="button"
                        class="btn-default"
                        onClick={() => this.onRefresh()}
                      >
                        Refresh
                      </button>
                    </span>

                    <span class="chat-span">
                      <button
                        type="button"
                        class="btn-default"
                        headerMessage
                        onClick={() => this.endChatButton()}
                      >
                        End Chat
                      </button>
                    </span>
                    <span class="chat-span">
                      <Link to="/chat/archivedMessageChats">
                        <button type="button" class="btn-default">
                          Old Chat(s)
                        </button>
                      </Link>
                    </span>
                    <span class="chat-span">
                      <a href="/chat/chatmessagegroup">
                        <button type="button" class="btn-default">
                          Inbox
                        </button>
                      </a>
                    </span>

                    <span class="chat-span">
                      <button
                        type="button"
                        onClick={() => this.sendAsMail()}
                        class="btn-default"
                      >
                        Send As Mail
                      </button>
                    </span>
                    {window.sessionStorage.getItem("role") === "ROLE_USER" ? (
                      <span class="chat-span">
                        <Link to="/home/doctor-list-main/2">
                          <button type="button" class="btn-default">
                            New Chat
                          </button>
                        </Link>
                      </span>
                    ) : (
                      <span class="chat-span">
                        <Link to="/doctor/dashboard">
                          <button type="button" class="btn-default">
                            Dashboard
                          </button>
                        </Link>
                      </span>
                    )}
                  </div>
                  <div class="chat-body">
                    <div class="messages" id="messageOld">
                      {/* <div
                    className="col-md-12 text-center cursor-pointer"
                    style={{ fontWeight: "600" }}
                    onClick={() => this.oldchat()}
                  >
                    <i
                      class="fa fa-chevron-down mr-2"
                      style={{ fontSize: "10px" }}
                    ></i>{" "}
                    Load earlier messages
                  </div> */}

                      <div
                        id="oldchat"
                        className="text-chat-container"
                        style={{ display: "none" }}
                      >
                        {this.state.oldMessage.map((vid, vin) => {
                          let fileImgLink = false;
                          let fileLink = false;
                          let text = "";
                          if (vid.message.includes("https://")) {
                            let checkme = this.checkURL(vid.message);
                            if (checkme === true) {
                              fileImgLink = true;
                            } else {
                              fileLink = true;
                            }
                          } else {
                            fileImgLink = false;
                            fileLink = false;
                            text = vid.message;
                          }
                          return (
                            <ul>
                              {parseInt(vid.senderId) !==
                              parseInt(
                                window.sessionStorage.getItem("userId")
                              ) ? (
                                <li class="message left">
                                  <div class="avatar">
                                    <img
                                      src={avatar}
                                      class="avatarimg"
                                      alt=""
                                    ></img>
                                  </div>
                                  <div class="text_wrapper">
                                    <div class="text-header">
                                      <h6 className="textchat-name">
                                        {vid.doctorName}
                                      </h6>
                                      <time class="message-time">
                                        {" "}
                                        {moment(vid.createdOnMillSec).format(
                                          "DD MMM YYYY hh:mm A"
                                        )}
                                      </time>
                                      {/* <time class="message-time">15 May 2020 06:05 PM</time> */}
                                    </div>
                                    <p class="text"></p>
                                    {fileImgLink === true && (
                                      <p class="text">
                                        <a href={vid.message}>
                                          <img src={vid.message} alt="" />
                                        </a>
                                      </p>
                                    )}
                                    {fileLink === true && (
                                      <p class="text">
                                        <a href={vid.message}>
                                          <img
                                            src={this.state.docImgLink}
                                            alt=""
                                          />
                                        </a>
                                      </p>
                                    )}
                                    {text !== "" && (
                                      <p class="text">{vid.message}</p>
                                    )}
                                  </div>
                                </li>
                              ) : (
                                <li class="message right">
                                  <div class="avatar">
                                    <img
                                      src={avatar}
                                      class="avatarimg"
                                      alt=""
                                    />
                                  </div>
                                  <div class="text_wrapper">
                                    <div class="text-header">
                                      <h6 className="textchat-name">
                                        {" "}
                                        {vid.memberName}
                                      </h6>
                                      <time class="message-time">
                                        {" "}
                                        {vid.createdDate}
                                      </time>
                                    </div>
                                    {fileImgLink === true && (
                                      <p class="text">
                                        <a href={vid.message}>
                                          <img src={vid.message} alt="" />
                                        </a>
                                      </p>
                                    )}
                                    {fileLink === true && (
                                      <p class="text">
                                        <a href={vid.message}>
                                          <img
                                            src={this.state.docImgLink}
                                            alt=""
                                          />
                                        </a>
                                      </p>
                                    )}
                                    {text !== "" && (
                                      <p class="text">{vid.message}</p>
                                    )}
                                  </div>
                                </li>
                              )}
                            </ul>
                          );
                        })}
                      </div>
                    </div>

                    <div className="App">
                      <div className="App-main text-chat-main">
                        <div id="controls" className={controlClass}></div>

                        <div
                          id="chat"
                          className="App-chat-container text-chat-container"
                        >
                          <div class="App-chat-attach">
                            {/* <div
                          class="messageFileUpload"
                          onClick={this.uploadattach}
                        ></div> */}
                            <div
                              class="messageFileUpload"
                              // onClick={this.uploadattach}
                              // style={{ display: "none" }}
                            >
                              <input
                                id="file1"
                                name="file1"
                                class="uploadattach"
                                type="file"
                                onChange={this.fileChange}
                              />
                              <div
                                class="uploadattachBtn"
                                onClick={(e) => {
                                  this.onChangeFileUpload();
                                }}
                              >
                                Send
                              </div>
                            </div>
                          </div>

                          <div
                            className="App-header"
                            style={{ display: "none" }}
                          >
                            <button
                              className="recording-delete"
                              disabled={this.state.isRecording}
                              style={{ display: "none" }}
                            >
                              <img
                                src={deleteImg}
                                onClick={() => this.deleteRecord()}
                                className="recdeleteImg-icon-img"
                                alt="Delete"
                                title="Delete"
                              />
                            </button>

                            <button
                              className="recording-icon"
                              disabled={this.state.isRecording}
                            >
                              <img
                                src={recordGif}
                                className="recording-icon-img"
                                alt="Record"
                                title="Record"
                              />
                            </button>
                            <audio
                              src={this.state.blobURL}
                              controls="controls"
                              id="recordedAudio"
                            />
                            <button
                              className="record-stop"
                              id="recStop"
                              onClick={this.stop}
                              disabled={!this.state.isRecording}
                            >
                              <img
                                src={pauseImg}
                                className="record-stop-img"
                                alt="Stop"
                                title="Stop"
                              />
                            </button>

                            <button             
                              onClick={() => this.SendvoiceRecord()}
                              className="voice-sndIcon"
                              id="voiceSend"
                              style={{ display: "none" }}
                            >
                              Send
                            </button>
                          </div>
                          <div className="voice-recordBtn">
                            <img
                              src={microphoneImg}
                              className="voice-recordImg"
                              id="startRecord"
                              onClick={this.start}
                              alt=""
                              title="Start Recording"
                            ></img>
                          </div>
                        </div>
                      </div>
                    </div>

                    {window.sessionStorage.getItem("role") ===
                      "ROLE_DOCTOR" && (
                      <div className="row col-md-12 m-0">
                        <div className="col-md-4 col-7 p-0 mb-2">
                          <select
                            name="dropDownSelect"
                            className="input-design chat-dr-question"
                            id="defaultQust"
                            onChange={(e) => {
                              this.onChangeDefaultQuestion();
                            }}
                          >
                            <option value="12">
                              -- Select default question - -
                            </option>
                          </select>
                        </div>

                        <div className="col-md-4 col-5">
                          {/* <button className="formButtonBg formButton-size chat-bgbtn pt-1 prescrptn-btn"
                          > */}
                          <Link
                            to={{
                              pathname: `/prescriptionAdd-${window.sessionStorage.getItem(
                                "appHosId"
                              )}-${window.sessionStorage.getItem(
                                "memberId_text"
                              )}-0-${window.sessionStorage.getItem(
                                "patientName"
                              )}`,
                              state: {
                                setPage: "textChat",
                              },
                            }}
                            className="formButtonBg formButton-size chat-bgbtn pt-1"
                          >
                            Prescription
                          </Link>
                          {/* Prescription */}
                          {/* </button> */}
                        </div>
                      </div>
                    )}
                    <div className="col-md-12 pr-4" id="boottomDiv">
                      {/* <p class="textarea-count">10000 characters left</p> */}
                      {/* <input
                        id="file1"
                        name="file1"
                        tabindex="33"
                        class="uploadattach"
                        type="file"
                        onChange={this.fileChange}
                      />
                      <input
                        type="button"
                        value="Upload"
                        onClick={(e) => {
                          this.onChangeFileUpload();
                        }}
                      /> */}
                    </div>

                    <div
                      class="doctor-pay-box"
                      id="paymentReqBlock"
                      style={{ display: "none" }}
                    >
                      <div>
                        <p className="text-center">
                          {this.state.headerMessage}
                        </p>
                      </div>
                      {/* <div class="row">
                      <label class="col-5 label-text label-text-cln">
                        Doctor Name
                      </label>
                      <label class="col-5" style={{ fontWeight: "600" }}>
                        {this.state.doctorName}
                      </label>
                    </div> */}
                      <div class="row">
                        <label
                          class="col-5 label-text label-text-cln"
                          style={{ textAlign: "right" }}
                        >
                          Amount
                        </label>
                        <label class="col-7" style={{ fontWeight: "600" }}>
                          <span style={{ fontFamily: "none" }}>₹</span>
                          {this.state.payAmount}
                        </label>
                      </div>
                      <div className="col-lg-12 text-center">
                        <input
                          type="button"
                          class="formButton"
                          value="Pay Now"
                          id="withPayment"
                          style={{ display: "none" }}
                          onClick={() => this.textChatPayNow()}
                        />
                        <input
                          type="button"
                          class="formButton"
                          id="withoutPayment"
                          value="Continue Chat"
                          style={{ display: "none" }}
                          onClick={() => this.chatmessageClickValidate()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*
-----------------------------------------------------------
        */}
            {/* <div className="App">
          <div className="App-main text-chat-main">
            <div id="controls" className={controlClass}></div>
            <div id="oldchat" className="text-chat-container"></div>
            <div id="chat" className="App-chat-container text-chat-container">
              <div class="App-chat-attach">
                <div class="messageFileUpload" onClick={this.uploadattach}>
                  <input
                    id="file1"
                    name="file1"
                    tabindex="33"
                    class="uploadattach"
                    type="file"
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}
            {/* <div
              class="popup-overlay"
              id="callcompltd_text"
              style={{ display: "none" }}>
            
              <div className="popup-content">
                <div className="modal-popup">
                  <a
                    className="close-popup popup-hide cancelPrescription"
                    onClick={() => this.cancelPrescription()}
                    id=""
                  >
                    &times;
                  </a>
                  <div className="header border-0"> Prescription Details</div>
                  <div className="content">
                    <div className="col-md-12">
                      <input type="hidden" id="pre_memberId" />
                      <input type="hidden" id="pre_hospitalId" />
                      <input type="hidden" id="pre_doctorId" />
                      <textarea
                        class="input-design"
                        rows="10"
                        id="prescription"
                      ></textarea>
                    </div>
                    <div class="text-center">
                      <input
                        type="button"
                        class="formButtonBg mx-1"
                        value="Send"
                        id="savePrescription"
                      />
                      <input
                        type="button"
                        class="formButtonBg cancelButtonBg mx-1 popup-hide cancelPrescription"
                        value="Cancel"
                        onClick={() => this.cancelPrescription()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
