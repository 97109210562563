import React, { Component, Suspense } from "react";
import $ from "jquery";

export default class Doctorseo extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.doctorData.length > 0) {
      $("#txtSlug").val(this.props.doctorData[0].slug);
      $("#txtTitle").val(this.props.doctorData[0].seoTitle);
      $("#txtKeyword").val(this.props.doctorData[0].keywords);
      $("#txtDescriptionNew").val(
        this.props.doctorData[0].description == "undefined"
          ? ""
          : this.props.doctorData[0].description
      );
    }
  }
  generateSEO() {
    if (
      window.sessionStorage.getItem("seoDisplaytName") === "" ||
      window.sessionStorage.getItem("seoDisplaytName") === null ||
      window.sessionStorage.getItem("seoDisplaytName") === undefined
    ) {
      alert("Full Name Required");
    } else if (
      window.sessionStorage.getItem("seoTitle") === "" ||
      window.sessionStorage.getItem("seoTitle") === null ||
      window.sessionStorage.getItem("seoTitle") === undefined
    ) {
      alert("Title Required");
    } else if (
      window.sessionStorage.getItem("seoState") === "" ||
      window.sessionStorage.getItem("seoState") === null ||
      window.sessionStorage.getItem("seoState") === undefined
    ) {
      alert("State Required");
    } else {
      var slug =
        window.sessionStorage.getItem("seoDoctorId") +
        "-" +
        window.sessionStorage.getItem("seoTitle") +
        window.sessionStorage.getItem("seoDisplaytName");
      var title =
        window.sessionStorage.getItem("seoTitle") +
        window.sessionStorage.getItem("seoDisplaytName") +
        "-Select-" +
        window.sessionStorage.getItem("seoState");
      var keywords =
        "The ayurvedic Doctor, homeopathic doctors, veterinarians, Dentists in india " +
        window.sessionStorage.getItem("seoTitle") +
        " " +
        window.sessionStorage.getItem("seoDisplaytName") +
        "-Select-" +
        window.sessionStorage.getItem("seoState");
      var description =
        "Search engine for doctors. Doctors of India, Doctors Directory, " +
        window.sessionStorage.getItem("seoTitle") +
        " " +
        window.sessionStorage.getItem("seoDisplaytName") +
        "-Select-" +
        window.sessionStorage.getItem("seoState");

      slug = slug.replaceAll(".", "");
      slug = slug.replaceAll(" ", "");

      $("#txtSlug").val(slug);
      $("#txtTitle").val(title);
      $("#txtKeyword").val(keywords);
      $("#txtDescriptionNew").val(description);

      // localStorage.getItem("specialisation")
      // console.log("specialisation",localStorage.getItem("specialisation"))
      // console.log("title",localStorage.getItem("title"))
      // console.log("displayNmae",localStorage.getItem("displaytName"))
      // console.log("state",localStorage.getItem("state"))
    }
  }
  render() {
    return (
      <div className="bg-clr-wthHead mb-4">
        <Suspense fallback={<div></div>}>
          <section>
            <div className="seoWrapperHspMangemnt doctr-wraper-border">
              <div className="container mt-3">
                <span className="seosectinTextHspMangemnt">
                  Click the button to create the default SEO details --{" "}
                </span>
                <div className="generatebtnDiv-seohsp">
                  <button
                    className="generatebtn-seohsp w-auto"
                    onClick={() => this.generateSEO()}
                  >
                    Generate SEO
                  </button>
                </div>
                <div className="mt-4 mb-5">
                  <div className="seofieldDivhsp mb-2">
                    <label>Slug</label>
                    <input
                      type="text"
                      name="txtSlug"
                      id="txtSlug"
                      placeholder="Slug"
                      className="hspInfofieldinput"
                    />
                  </div>
                  <div className="seofieldDivhsp">
                    <label>Title</label>
                    <textarea
                      name="txtTitle"
                      id="txtTitle"
                      cols="30"
                      rows="10"
                      placeholder="Title"
                      className="seofieldDivhsp-textarea"
                    ></textarea>
                  </div>

                  <div className="seofieldDivhsp">
                    <label>Keywords</label>
                    <textarea
                      name="txtKeyword"
                      id="txtKeyword"
                      cols="30"
                      rows="10"
                      placeholder="The ayurvedic Doctor, homeopathic doctors, veterinarians, Dentists in india.  Dr.   - Kollam - Kerala"
                      className="seofieldDivhsp-textarea"
                    ></textarea>
                  </div>

                  <div className="seofieldDivhsp">
                    <label>Description</label>
                    <textarea
                      name="txtDescriptionNew"
                      id="txtDescriptionNew"
                      cols="30"
                      rows="10"
                      placeholder="Search engine for doctors. Doctors of India, Doctors Directory,  Dr.   - Kollam - Kerala"
                      className="seofieldDivhsp-textarea"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}></Suspense>
      </div>
    );
  }
}
