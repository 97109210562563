import React, { Component, Suspense } from "react";
import Edit from "../../image/icons/edit.png";
import AddImage from "../../image/icons/addImage.png";
import Copy from "../../image/icons/copy.png";
import Procedure from "../../image/icons/procedure.svg";
import tick from "../../image/icons/tick.png";
import Deactivate from "../../image/icons/deactvate.png";
import { apiCalling } from "../../apiService";
import $ from "jquery";
import Pagination from "react-js-pagination";
import Header from "../../Reliance/header";
import Footer from "../../Reliance/footer";
import { Link } from "react-router-dom";
export default class HospitalMangementList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hospitalManagementData: [],
      totalCount: 0,
      activePage: window.sessionStorage.getItem('hospitalpageNumber'),
      showMessage: "Waiting for data... ",
      pageOffset:0,
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handlePageChange(pageNumber) {
    window.sessionStorage.setItem('hospitalpageNumber', pageNumber)
    this.setState({ activePage: pageNumber });
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
      });
    } else {
      offset = parseInt(pageNumber - 1) * 10;
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
        pageOffset:offset
      });
      window.sessionStorage.setItem('hospitalpageNumber', pageNumber)
      window.sessionStorage.setItem('hospitaloffset', offset)
    }
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHospitalManagementList",
        cmbSelect:
          $("#selectCategory").val() === "0" ? "" : $("#selectCategory").val(),
        txtInput: $("#hospitalTxt").val(),
        Offset: JSON.stringify(offset),
        max: max,
      }),
    };
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          hospitalManagementData: data.hospitalManagementData,
          totalCount: data.totalCount,
        });
        $(".loader").hide();
      }
    });
  }
  setactivepagenumber = (hospitaloffset) => {
		window.sessionStorage.setItem("hospitaloffset", hospitaloffset)
	}
  componentDidMount() {
    this.getHospitalDetails();
    window.addEventListener("beforeunload", (e)=>this.handleBefore(e));
    return () => {
      window.removeEventListener("beforeunload",(e)=>this.handleBefore(e));
    };
  } 
  handleBefore = (e) => {
    e.preventDefault();
    window.sessionStorage.setItem('hospitalpageNumber',1)
    window.sessionStorage.setItem('hospitaloffset',0)
  };

  getHospitalDetails() {
    
    $(".loader").show();
    this.setState({ activePage: Number(window.sessionStorage.getItem('hospitalpageNumber')) });
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHospitalManagementList",
        cmbSelect: "",
        txtInput: "",
        Offset:  window.sessionStorage.getItem('hospitaloffset') == null ? 0 : window.sessionStorage.getItem('hospitaloffset'),
        max: "10",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        this.setState({
          hospitalManagementData: data.hospitalManagementData,
          totalCount: data.totalCount,
        });
      }
      if (data.success === "0") {
        $(".loader").hide();
        this.setState({
          hospitalManagementData: [],
          showMessage: data.errorMessage,
        });
      }
    });
  }

  changeText = () => {
    if ($("#selectCategory").val() !== "0") {
      document.getElementById("hospitalTxt").disabled = false;
      $("input[name=hospitalTxt]").focus();
    } else {
      document.getElementById("hospitalTxt").disabled = true;
    }
    document.getElementById("hospitalTxt").value = "";
  };

  searchHandler() {
    if ($("#selectCategory").val() !== "0" && $("#hospitalTxt").val() === "") {
      var t = document.getElementById("selectCategory");
      var selectedText = t.options[t.selectedIndex].text;
      alert(selectedText + " Required");
      $("#hospitalTxt").focus();
      return false;
    } else {
      const searchData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "getHospitalManagementList",
          cmbSelect:
            $("#selectCategory").val() === "0"
              ? ""
              : $("#selectCategory").val(),
          txtInput: $("#hospitalTxt").val(),
          Offset: "0",
          max: "10",
        }),
      };
      $(".loader").show();
      apiCalling(searchData).then((data) => {
        if (data.success === "1") {
          $("#paginationType").show();
          $(".loader").hide();
          this.setState({
            hospitalManagementData: data.hospitalManagementData,
            totalCount: data.totalCount,
            activePage: 1,
          });
        }
        if (data.success === "0") {
          $("#paginationType").hide();
          $(".loader").hide();
          this.setState({
            hospitalManagementData: [],
            showMessage: data.errorMessage,
          });
        }
      });
    }
  }

  statusChangeHandler(id,adminId, Status) {
    var x = false;
    if (Status === true) {
      x = window.confirm("Do you want to Disable this?");
    } else {
      x = window.confirm("Do you want to Enable this?");
    }
    if (x === true) {
      const SatusData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "deactivateHospital",
          hospitalId: id,
          hospitalAdminId:adminId,
          isActive: Status === true ? 0 : 1,
        }),
      };
      $(".loader").show();
      apiCalling(SatusData).then((data) => {
        if (data.success === "1") {
          $(".loader").hide();
          alert("Status Changed Successfully");
          this.getHospitalDetails();
        }
        if (data.success === "0") {
          $(".loader").hide();
          alert(data.errorMessage);
        }
      });
    }
  }

  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header /> <div className="loader"></div>
          </section>
        </Suspense>
        <div className="loader"></div>
        <div className="container mb-5">
          <div>
            <ul className="breadcrum-adminMangmnt">
              <li>
                <a href="/admin/dashboard">Dashboard</a>
              </li>
              <li>
                <a
                  href="/adminManagement/hospitalManagementList/hospitalManagementList"
                  className="current"
                >
                  Hospital Management
                </a>
              </li>
            </ul>
          </div>
          <div className="head-hospMangmntList">Hospital Management</div>

          <div className="HosptlMangemntTable">
            <div className="AddNewHspbtnDiv">
              <Link
                to="/adminManagement/hospitalManagementList/addnewHosp"
                className="addnewhspbtn"
              >
                Add New Hospital
              </Link>
            </div>
            {this.state.totalCount > 5 ? (
              <div
                className="paginationSection orderm-paginationSection pagination-HspList"
                id="paginationType"
              >
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalCount}
                  pageRangeDisplayed={10}
                  onChange={this.handlePageChange}
                />
              </div>
            ) : (
              ""
            )}
            <div className="tableHeader-HspMangemnt">
              <div className="selectField-HspMangmnt">
                <select
                  className="slecthsp slecthspMob"
                  id="selectCategory"
                  name="selectCategory"
                  onChange={() => this.changeText()}
                >
                  <option value="0">Search In</option>
                  <option value="1">ID</option>
                  <option value="2">Hospital Name</option>
                  <option value="3">Phone</option>
                  <option value="4">City</option>
                  <option value="5">Category</option>
                </select>
              </div>
              <div className="selectField-HspMangmnt">
                <input
                  type="text"
                  className="hspmngInput hspmngInputmob"
                  name="hospitalTxt"
                  id="hospitalTxt"
                  disabled
                  tabIndex="0"
                  onKeyPress={(event) => {
                    if (event.key === "Enter" || event.key === "NumpadEnter") {
                      event.preventDefault();
                      this.searchHandler();
                    }
                  }}
                />
              </div>
              <div className="selectField-HspMangmnt">
                <button
                  className="searchbtn-hspMng"
                  onClick={() => this.searchHandler()}
                >
                  Search
                </button>
              </div>
            </div>
            {this.state.hospitalManagementData.length > 0 ? (
              <div>
              <div class="table-responsive">
                <table className="table table-bordered-hsp">
                  <thead>
                    <tr>
                      <th className="tablehead-hspmng" width="5%">
                        ID
                      </th>
                      <th className="tablehead-hspmng" width="30%">
                        Hospital Name
                      </th>
                      <th className="tablehead-hspmng" width="10%">
                        Category
                      </th>
                      <th className="tablehead-hspmng" width="20%">
                        Website
                      </th>
                      <th className="tablehead-hspmng" width="10%">
                        Phone
                      </th>
                      <th className="tablehead-hspmng" width="10%">
                        City
                      </th>
                      <th className="tablehead-hspmng" width="15%">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.hospitalManagementData.map((data) => (
                      <tr key={data.hospitalId}>
                        <td>{data.hospitalId}</td>
                        <td>{data.hospitalName}</td>
                        <td>{data.categoryName}</td>
                        <td>{data.webSiteUrl}</td>
                        <td>{data.phoneNumber}</td>
                        <td>{data.cityName}</td>
                        <td className="nowrap">
                            <Link
                                             onClick={() => this.setactivepagenumber(this.state.pageOffset)}
                              to={{
                                pathname:
                                  "/adminManagement/hospitalManagementList/addnewHosp",
                                state: { hospitalId: data.hospitalId },
                              }}
                              className="mr-2 "
                            >
                              <img
                                src={Edit}
                                alt=""
                                title="Edit"
                                width="18"
                                className="img-fluid"
                              />
                            </Link>
                            {data.isActive === true ? (
                              <img
                                src={Deactivate}
                                width="22"
                                title="Click to Deactivate "
                                className="mr-2 conf-cursorpointer"
                                alt=""
                                onClick={() => {
                                  this.statusChangeHandler(
                                    data.hospitalId,
                                    data.hospitalAdminId,
                                    true
                                  );
                                }}
                              ></img>
                            ) : (
                              <img
                                src={tick}
                                alt=""
                                title="Click to Activate"
                                className="mr-2 conf-cursorpointer"
                                width="20"
                                onClick={() => {
                                  this.statusChangeHandler(
                                    data.hospitalId,
                                    data.hospitalAdminId,
                                    false
                                  );
                                }}
                              ></img>
                            )}

                            <img
                              src={Copy}
                              alt=""
                              title="Copy"
                              width="18"
                              className="img-fluid"
                            />

                            <a
                              href="/adminManagement/hospitalManagementList/procedurelist"
                              className="listLink-hspmangmnt"
                              title="Procedure"
                            >
                              <img
                                src={Procedure}
                                alt=""
                                width="30"
                                className="img-fluid"
                              />
                            </a>
                            <Link
to={{
  pathname:
  `/adminManagement/hospitalManagementList/addimages-${data.hospitalId}`,
  state: { hospitalId: data.hospitalId,
  hospitalName:data.hospitalName },

}}

                              // to={`/adminManagement/hospitalManagementList/addimages-${data.hospitalId}${data.hospitalName}`}
                              className="listLink-hspmangmnt"
                              title="Add Image "
                            >
                              
                              <img
                                src={AddImage}
                                alt=""
                                width="18"
                                className="img-fluid"
                              />
                         
                            </Link>
                          <div>
                            <span>
                              <a
                                href={`/adminManagement/hospitalManagementList/hospitalDoctorSchedule-${data.hospitalId}`}
                                className="listLink-hspmangmnt"
                              >
                                Doctor Video/Audio Schedule
                              </a>
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                </div>
                {this.state.totalCount > 5 ? (
                  <div className="paginationSection orderm-paginationSection pagination-HspList pagination-HspListwidth">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.totalCount}
                      pageRangeDisplayed={10}
                      onChange={this.handlePageChange}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <p className="error-msg text-center mb-0">
                {" "}
                {this.state.showMessage}{" "}
              </p>
            )}
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
