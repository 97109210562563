import React, { Component, Suspense } from "react";
import Spinner from "react-spinner";
import classNames from "classnames";
import AccCore from "opentok-accelerator-core";
import "opentok-solutions-css";
import moment from "moment";
import "../doctor/App.css";
import $ from "jquery";
import screenRecording from "../image/icons/video-recorder.gif";
import screenRecord from "../image/icons/vi-recod.png";
import { apiCalling } from "../apiService";
import AWS from "aws-sdk";
import { Link } from "react-router-dom";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
let otCore;
let recorder, stream;
var s3;
let projectId = "";
let archaveId = "";
const otCoreOptions = {
  credentials: {
    apiKey: window.sessionStorage.getItem("apiKey"),
    sessionId: window.sessionStorage.getItem("sessionId"),
    token: window.sessionStorage.getItem("token"),
  },

  streamContainers(pubSub, type, data, stream) {
    return {
      publisher: {
        camera: "#cameraPublisherContainer",
        screen: "#screenPublisherContainer",
      },
      subscriber: {
        camera: "#cameraSubscriberContainer",
        screen: "#screenSubscriberContainer",
      },
    }[pubSub][type];
  },
  controlsContainer: "#videoControls",
  packages: ["textChat", "annotation"],
  //packages: ['textChat'],
  communication: {
    autoSubscribe: true,
    subscribeOnly: false,
    connectionLimit: null,
  },
  textChat: {
    name: [window.sessionStorage.getItem("userName")], // eslint-disable-line no-bitwise
    waitingMessage: "Messages will be delivered when other users arrive",
    container: "#chat",
    alwaysOpen: false,
  },
  annotation: {
    absoluteParent: {
      publisher: ".App-video-container",
      subscriber: ".App-video-container",
    },
  },
  // archiving: {
  //   startURL: 'https://purplehealthfiles.s3.amazonaws.com/startArchive',
  //   stopURL: 'https://purplehealthfiles.s3.amazonaws.com/stopArchive',
  //  // startURL: 'https://a4c2uh4pka.execute-api.ap-south-1.amazonaws.com/test/purplehealth/startArchieve',
  //  // stopURL: 'https://a4c2uh4pka.execute-api.ap-south-1.amazonaws.com/test/purplehealth/stopArchive',
  // }
};

/**
 * Build classes for container elements based on state
 * @param {Object} state
 */
const containerClasses = (state) => {
  const { active, meta, localAudioEnabled, localVideoEnabled } = state;
  const sharingScreen = meta ? !!meta.publisher.screen : false;
  const viewingSharedScreen = meta ? meta.subscriber.screen : false;
  const activeCameraSubscribers = meta ? meta.subscriber.camera : 0;
  const activeCameraSubscribersGt2 = activeCameraSubscribers > 2;
  const activeCameraSubscribersOdd = activeCameraSubscribers % 2;
  const screenshareActive = viewingSharedScreen || sharingScreen;

  return {
    controlClass: classNames("App-control-container", { hidden: !active }),
    localAudioClass: classNames("ots-video-control circle audio", {
      hidden: !active,
      muted: !localAudioEnabled,
    }),
    localVideoClass: classNames("ots-video-control circle video", {
      hidden: !active,
      muted: !localVideoEnabled,
    }),
    localCallClass: classNames("ots-video-control circle end-call", {
      hidden: !active,
    }),
    cameraPublisherClass: classNames("video-container", {
      hidden: !active,
      small: !!activeCameraSubscribers || screenshareActive,
      left: screenshareActive,
    }),
    screenPublisherClass: classNames("video-container", {
      hidden: !active || !sharingScreen,
    }),
    cameraSubscriberClass: classNames(
      "video-container",
      { hidden: !active || !activeCameraSubscribers },
      { "active-gt2": activeCameraSubscribersGt2 && !screenshareActive },
      { "active-odd": activeCameraSubscribersOdd && !screenshareActive },
      { small: screenshareActive }
    ),
    screenSubscriberClass: classNames("video-container", {
      hidden: !viewingSharedScreen || !active,
    }),
  };
};

const connectingMask = () => (
  <div className="App-mask">
    <Spinner />
    <div className="message with-spinner">Connecting...</div>
  </div>
);

const startCallMask = (start) => (
  <div className="App-mask" style={{ display: "none" }}>
    <button className="message button clickable" onClick={start}>
      Click to Start Call{" "}
    </button>
  </div>
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connected: false,
      active: false,
      publishers: null,
      stream: null,
      subscribers: null,
      meta: null,
      localAudioEnabled: true,
      localVideoEnabled: true,
      streamCreated: false,
      chatStartTime: "",
      chatEndTime: "",
      chatEndFlag: false,
      s3bucketAccessKey: "",
      AWSAccessKeyId: "",
      bucket: "",
      screenRecordFile: "",
      screenRecordFileName: "",
      screenRecordFileNameFlag: false,
      vcStartTime: "",
      vcEndTime: "",
      showSession: false,
      bucketFolder: "",
      noChecking: "NO",
      archiveId: "",
    };
    this.startCall = this.startCall.bind(this);
    this.endCall = this.endCall.bind(this);
    this.toggleLocalAudio = this.toggleLocalAudio.bind(this);
    this.toggleLocalVideo = this.toggleLocalVideo.bind(this);
    this.enableVc = this.enableVc.bind(this);
    this.getEventHandling = this.getEventHandling.bind(this);
    this.fileChange = this.fileChange.bind(this);
    this.endChatButton = this.endChatButton.bind(this);
    this.fullscreen = this.fullscreen.bind(this);
  }
  awsCredentials() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
        siteId: window.sessionStorage.getItem("siteId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          s3bucketAccessKey: data.configPathDetails[0].s3bucketAccessKey,
        });
        this.setState({
          AWSAccessKeyId: data.configPathDetails[0].s3bucketSecretKey,
        });

        this.setState({
          bucket: data.configPathDetails[0].s3BucketReliance,
        });
        this.setState({
          bucketFolder: data.configPathDetails[0].S3BucketMainFolder,
        });
      }
    });
  }
  fileChange(e) {
    $(".loader").show();
    this.setState({ file: e.target.files[0] });
    if (e.target.files[0]) {
      this.fileUpload(e.target.files[0]);

      //console.log("1111111", e.target.files[0]);
      $("#attachImg").text(e.target.files[0].name);
      $("#aattachImg").attr("href", URL.createObjectURL(e.target.files[0]));
      $(".uploadattach").hide();
      $(".uploadattachBtn").show();
    } else {
      $(".App-chat-attach .messageFileUpload").removeClass("messageloader");
    }
  }
  fileUpload(file) {
    AWS.config.update({
      accessKeyId: this.state.s3bucketAccessKey,
      secretAccessKey: this.state.AWSAccessKeyId,
      region: this.state.region,
    });
    var bucketParams = {
      Bucket: this.state.bucket,
    };
    s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    let S3BucketMainFolder = "test";
    let fileName = this.imagepath1() + file.name;
    let fullFilePath = S3BucketMainFolder + "/" + fileName;
    //alert(fullFilePath);
    this.setState({ fileName: fileName });

    s3.upload(
      {
        Key: fullFilePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        $(".loader").hide();

        $(".App-chat-attach .messageFileUpload").removeClass("messageloader");

        if (err) {
          alert("There was an error uploading your photo: ", err.message);
          return false;
        } else if (data) {
          // console.log(data);
          this.setState({ fullFilePath: data.Location });
          alert("Successfully uploaded");

          return true;
        }
      }
    );
  }
  checkURL(url) {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }
  onChangeFileUpload() {
    let fullPAth = this.state.fullFilePath;
    //$("#messageBox").val("sss");
    //$("#sendMessage").click();
    $("#messageBox").val(fullPAth);
    $("#sendMessage").click();
    $(".uploadattachBtn").hide();
    $(".uploadattach").show();
  }
  imagepath1 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  closeIt() {
    return (
      "Any string value here forces a dialog box to \n" +
      "appear before closing the window."
    );
  }

  getEventHandling() {
    var test = "";
    //alert(this.state.meta.subscriber.camera)
    if (this.state.meta) {
      if (this.state.meta.subscriber.camera === 0) {
        if (
          window.sessionStorage.getItem("role") === "ROLE_DOCTOR" &&
          this.state.noChecking === "NO"
        ) {
          // test = window.confirm(
          //   "Patient not available ! Do you want to close the chat ?"
          // );
          // if (test === true) {
          //   $(".loader").show();
          //   this.callDisconnectPush();
          //   this.endChatButton();
          // }
          $(".popupvideoClass").attr("hidden", false);
        }
        if (window.sessionStorage.getItem("role") === "ROLE_USER") {
          $(".popupvideoClassPatient").attr("hidden", false);
          // test = window.confirm(
          //   "Doctor not available ! Do you want to close the chat ?"
          // );
          // if (test === true) {
          //   $(".loader").show();
          //   this.endChatButton();
          // }
        }
      }
      // else{
      //this.setState({ showSession: true });
      // }
    }
  }
  videoOk() {
    $(".popupvideoClass").attr("hidden", true);
    $(".popupvideoClassPatient").attr("hidden", true);
    $(".loader").show();
    this.callDisconnectPush();
    this.endChatButton();
  }
  videoRefresh() {
    $(".popupvideoClass").attr("hidden", true);
    $(".popupvideoClassPatient").attr("hidden", true);
    window.location.reload(false);
  }
  videoclose() {
    $(".popupvideoClass").attr("hidden", true);
    $(".popupvideoClassPatient").attr("hidden", true);
  }
  componentWillMount() {
    // if (window.sessionStorage.getItem("role") === "ROLE_DOCTOR") {
    if (window.sessionStorage.getItem("drcallType") === "audio") {
      setInterval(async () => {
        //alert(this.state.meta.subscriber.camera)
        if (this.state.meta) {
          if (this.state.meta.subscriber.camera === 0) {
            $("#cameraSubscriberContainer").hide();
          } else {
            // $("#cameraSubscriberContainer").show();
          }
        }
      }, 10000);
    }
    // }
    ////if (window.sessionStorage.getItem("role") === "ROLE_USER") {
    setInterval(async () => {
      this.getEventHandling();
    }, 30000);
    //}
  }

  componentDidCatch() {
    //alert("catch");
  }
  componentDidUpdate() {}
  componentWillUpdate() {
    // $("#enableTextChat").click(function () {
    //  alert()
    // });
  }

  async startRecording() {
    //alert("start")
    const video = document.querySelector("video");
    stream = await navigator.mediaDevices.getDisplayMedia({
      video: { mediaSource: "screen" },
    });
    recorder = new MediaRecorder(stream);
    const chunks = [];
    recorder.ondataavailable = (e) => chunks.push(e.data);
    this.setState({ screenRecordFileNameFlag: true });
    recorder.onstop = (e) => {
      //alert("stop")
      const completeBlob = new Blob(chunks, { type: chunks[0].type });
      video.src = URL.createObjectURL(completeBlob);
      var d = new Date();
      var newDate = d.getTime();
      let fileName = newDate + ".mp4";
      // alert(fileName)
      const file = new File([completeBlob], fileName, {
        type: completeBlob.type,
        lastModified: Date.now(),
      });
      this.setState({ screenRecordFile: file });
      //alert(fileName)
      $(".loader2").show();
      this.setState({ screenRecordFileName: fileName });
      this.setState({ noChecking: "YES" });
      this.fileUploadScreenSave(file);
      //this.fileUploadScreenSave(file);
      //this.saveScreenRecord();
    };
    recorder.start();
  }

  fileUploadScreenSave(file) {
    AWS.config.update({
      accessKeyId: this.state.s3bucketAccessKey,
      secretAccessKey: this.state.AWSAccessKeyId,
      region: this.state.region,
    });
    var bucketParams = {
      Bucket: this.state.bucket,
    };
    s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    let S3BucketMainFolder = this.state.bucketFolder;
    let fileName = this.imagepath1() + file.name;
    this.setState({ screenRecordFileName: fileName });
    let fullFilePath = S3BucketMainFolder + "/" + fileName;
    //alert(fullFilePath);
    this.setState({ fileName: fileName });

    s3.upload(
      {
        Key: fullFilePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        $(".loader").hide();
        if (err) {
          alert("There was an error uploading your photo: ", err.message);
          this.endChatButton();
          return false;
        } else if (data) {
          // console.log(data);
          // alert("Successfully uploaded");
          this.saveScreenRecord();

          return true;
        }
      }
    );
  }

  saveScreenRecord() {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "saveRecordingDetails",
        appoinmentId: window.sessionStorage.getItem("reqAppointmentId"),
        doctorId: window.sessionStorage.getItem("userId"),
        fileName: this.state.screenRecordFileName,
      }),
    };
    // alert(apiJson.body);
    apiCalling(apiJson).then((data) => {
      $(".loader").hide();
      this.setState({ screenRecordFile: "" });
      this.setState({ screenRecordFileName: "" });
      if (data.success === "1") {
        //alert("Saved Successfully");
        $("#callcompltd").hide();
        $("#prescription").val("");
        this.endChatButton();
      }
    });
  }

  componentDidMount() {
    //$("#cameraSubscriberContainer").hide();
    if (window.sessionStorage.getItem("role") === "ROLE_DOCTOR"){
      $("#popupvideoClass").show();
    }
    else{
      $(".popupvideoClassPatient").attr("hidden", true);
    }
    
    if (
      window.sessionStorage.getItem(
        "isRecordingAvailable" + window.sessionStorage.getItem("vcHospitalId")
      ) === "false"
    ) {
      if (window.sessionStorage.getItem("role") === "ROLE_DOCTOR") {
        $(".recordContainer").hide();
        $("#stop").hide();

        const start = document.getElementById("start");
        const stop = document.getElementById("stop");
        const video = document.querySelector("video");
        const uploadRecord = document.getElementById("uploadRecord");
        start.addEventListener("click", () => {
          //start.setAttribute("disabled", true);
          // uploadRecord.setAttribute("disabled", true);
          // stop.removeAttribute("disabled");
          $("#start").hide();
          $("#stop").show();
          this.startRecording();
        });

        // stop.addEventListener("click", () => {
        //     stop.setAttribute("disabled", true);
        //     start.removeAttribute("disabled");
        //     uploadRecord.removeAttribute("disabled");
        //     recorder.stop();
        //     stream.getVideoTracks()[0].stop();
        // });
        uploadRecord.addEventListener("click", () => {
          $(".loader").show();
          $("#start").show();
          $("#stop").hide();
          uploadRecord.setAttribute("disabled", true);
          if (this.state.screenRecordFile) {
            let file = this.state.screenRecordFile;
            // alert("ppppppp")
            this.fileUpload(file);
            this.saveScreenRecord();
          }
        });
      }
    }
    $("#enableTextChat").click(function () {
      $(".video-screen-icon").click();
    });

    $("#ots-dup-chat").click(function () {
      // alert("22")
      $("#chat").toggleClass("ots-hiddenn");
      $(this).toggle();
    });

    if (window.performance) {
      //alert(performance.navigation.type);
      if (performance.navigation.type === 1) {
        // alert("This page is reloaded");
      }
      if (performance.navigation.type === 2) {
        this.endChatButton();
      }
      if (performance.navigation.TYPE_RELOAD) {
        //alert("unloaod");
      }
    }

    this.awsCredentials();
    otCore = new AccCore(otCoreOptions);
    otCore.connect().then(() => {
      if (window.sessionStorage.getItem("drcallType") === "audio") {
        $("#cameraSubscriberContainer").hide();
      }
      this.setState({ connected: true });

      this.startCall();
    });

    otCore.on("archiveStarted", (event) => {
      alert();
    });

    // otCore.on("streamCreated", function (event) {
    //   this.setState({ streamCreated: true });
    // });

    const events = [
      "subscribeToCamera",
      "unsubscribeFromCamera",
      "subscribeToScreen",
      "unsubscribeFromScreen",
      "startScreenShare",
      "endScreenShare",
      //  'startArchive',
      // 'stopArchive',
    ];

    events.forEach((event) =>
      otCore.on(event, ({ publishers, subscribers, meta, stream }) => {
        this.setState({ publishers, subscribers, meta, stream });
      })
    );
    // $("#enableTextChat").click(function () {
    //   $(".video-chat-main").toggleClass("video-chat-fullScreen");
    //   $(".video-chat-wrapper").toggleClass("video-chatwrapper-fullScreen");
    // });
  }
  checkURLMP3(url) {
    return url.match(/\.(mp3)$/) != null;
  }

  callDisconnectPush() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "callDisconnectPush",
        siteId: window.sessionStorage.getItem("siteId"),
        scheduleTimeSlotId: window.sessionStorage.getItem(
          "vcScheduledTimeSlotId"
        ),
      }),
    };
    apiCalling(apiJson).then((data) => {});
  }

  endChatButton() {
    $(".loader").show();
    $(".ots-dup-circle").hide();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "disconnectVideoCall",
        patientId: window.sessionStorage.getItem("vcPatientId"),
        siteId: window.sessionStorage.getItem("siteId"),
        billingEndTime: new Date(),
        hospitalId: window.sessionStorage.getItem("vcHospitalId"),
        doctorId: window.sessionStorage.getItem("vcDoctorId"),
        type: "completed",
        doctorVideoScheduleId: window.sessionStorage.getItem(
          "vcDoctorVideoScheduleId"
        ),
        scheduleTimeSlotId: window.sessionStorage.getItem(
          "vcScheduledTimeSlotId"
        ),
        isSessionVideoEnded: "false",
        sessionChatNo: window.sessionStorage.getItem("vcSessionChatNo"),
        chatComments: "",
        bundleId: "",
      }),
    };

    //console.log(apiJson.body);
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        // alert("Your chat session has been ended");
        window.sessionStorage.setItem(
          "videochatTransactionId",
          data.result.videochatTransactionId
        );
        if (window.sessionStorage.getItem("role") === "ROLE_DOCTOR") {
          window.location.href = "../doctor/videoSchedule";
        }
        if (window.sessionStorage.getItem("role") === "ROLE_USER") {
          window.location.href = "../patient/videochatsuccess";
        }
      } else {
        alert(data.errorMessage);
        $(".loader").hide();
      }
    });
  }
  startArchave() {
    alert("Start");
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "startArchieve",
        sessionId: window.sessionStorage.getItem("sessionId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      //console.log(data)
      if (data.success == "1") {
        archaveId = data.result.id;
        projectId = data.result.projectId;
      }
    });
  }
  stopArchive() {
    alert("Stop");
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "stopArchive",
        archiveId: archaveId,
      }),
    };
    apiCalling(apiJson).then((data) => {
      // console.log(data)
    });
  }

  ListArchive() {
    alert("List");
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getArchive",
        archiveId: archaveId,
      }),
    };
    apiCalling(apiJson).then((data) => {
      //console.log(data)
    });
  }
  endChat() {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "completeChatStatus",
        requestVideoAppointmentId: window.sessionStorage.getItem(
          "doctorVideoScheduleId"
        ),
        appointmentStatus: "Call Completed",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        alert("Call ended");
        if (window.sessionStorage.getItem("role") === "ROLE_DOCTOR") {
          window.location.href = "../doctor/videoSchedule";
        }
        if (window.sessionStorage.getItem("role") === "ROLE_USER") {
          window.location.href = "../patient/videochatsuccess";
        }
      }
      $(".loader").hide();
    });
  }

  startCall() {
    otCore
      .startCall()
      .then(({ publishers, subscribers, meta }) => {
        this.setState({ publishers, subscribers, meta, active: true });

        this.setState({
          vcStartTime: moment(new Date()).format("DD-MM-YYYY "),
        });
        //otCore.toggleLocalAudio(false);
        //this.setState({ localAudioEnabled: !this.state.localAudioEnabled });

        let drcallType = window.sessionStorage.getItem("drcallType");
        //alert(drcallType)
        if (drcallType === "audio") {
          otCore.toggleLocalVideo(!this.state.localVideoEnabled);
          this.setState({ localVideoEnabled: false });
          $("#vcEnable").hide();
        }

        $(".App-chat-attach,.video-chat-left").show();

        $(".messageFileUpload").show();

        //$("#enableTextChat").click();

        $(".text-chat").hide();
        $(".ots-dup-circle-active").show();
        // this.showOldMessages();
      })
      .catch((error) => console.log(error));
  }

  click() {
    $("#chat").addClass("ots-hidden");
    //alert("11")
    $("#chat").addClass("ots-hiddenn");
    $("#enableTextChat").click(function () {
      // alert("22")
      $("#chat").toggleClass("ots-hiddenn");
    });
  }

  endCall() {
    otCore.endCall();
    this.setState({ active: false });

    if (
      window.sessionStorage.getItem("role") === "ROLE_DOCTOR" &&
      this.state.screenRecordFileNameFlag === true
    ) {
      //alert("if loop")
      recorder.stop();
      stream.getVideoTracks()[0].stop();
      let file = this.state.screenRecordFile;
      // alert("File :"+file)
      this.endChat()
    } else {
      // alert()
      this.endChatButton();
    }
  }

  toggleLocalAudio() {
    otCore.toggleLocalAudio(!this.state.localAudioEnabled);
    this.setState({ localAudioEnabled: !this.state.localAudioEnabled });
  }

  enableVc() {
    //otCore.enableVc(!this.state.localVideoEnabled);
    //this.setState({ localVideoEnabled: !this.state.localVideoEnabled });
    this.toggleLocalVideo();
  }

  toggleLocalVideo() {
    //alert("kk")
    otCore.toggleLocalVideo(!this.state.localVideoEnabled);
    this.setState({ localVideoEnabled: !this.state.localVideoEnabled });
  }
  uploadattach() {
    // $(".App-chat-attach .messageFileUpload").addClass("messageloader");
  }
  fullscreen() {
    $(".video-chat-main").toggleClass("video-chat-fullScreen");
    $(".video-chat-wrapper").toggleClass("video-chatwrapper-fullScreen");
  }

  dashboardButton() {
    this.endChatButton();
    //window.location.href = "/doctor/videoSchedule";
  }
  appointmentList() {
    this.endChatButton();
    //window.location.href = "/patient/videocalllist";
  }

  render() {
    const { connected, active } = this.state;
    const {
      localAudioClass,
      localVideoClass,
      localCallClass,
      controlClass,
      cameraPublisherClass,
      screenPublisherClass,
      cameraSubscriberClass,
      screenSubscriberClass,
    } = containerClasses(this.state);

    return (
      <>
        <div class="videocall_modal popupvideoClass" hidden id="popupvideo">
          <div class="videocall_div">
            <div class="videocall_header">Message</div>
            <div class="videocall_body">Your chat has been ended</div>
            <div class="videocall_footer">
              <button
                type="button"
                class="bttn_Refresh"
                onClick={() => this.videoRefresh()}
              >
                Reconnect
              </button>
              <button
                type="button"
                class="bttn_ok"
                onClick={() => this.videoOk()}
              >
                End Chat
              </button>
              <button
                type="button"
                class="bttn_close"
                onClick={() => this.videoclose()}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div
          class="videocall_modal popupvideoClassPatient"
          hidden
          id="popupvideo"
        >
          <div class="videocall_div">
            <div class="videocall_header">Message</div>
            <div class="videocall_body">Doctor Not Available.</div>
            <div class="videocall_footer">
              <button
                type="button"
                class="bttn_Refresh"
                onClick={() => this.videoRefresh()}
              >
                Reconnect
              </button>
              <button
                type="button"
                class="bttn_ok"
                onClick={() => this.videoOk()}
              >
                End Chat
              </button>
              <button
                type="button"
                class="bttn_close"
                onClick={() => this.videoclose()}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div className="App video-chat-wrapper">
          <Suspense fallback={<div></div>}>
            <section>{/* <Header /> */}</section>
          </Suspense>
          <div className="loader2"></div>
          <div className="col-md-12 text-left video-call-btn">
            {window.sessionStorage.getItem("role") === "ROLE_DOCTOR" && (
              <span>
                {/* <button
                class="formButton formButton-sm mr-1"
                onClick={this.dashboardButton}
              >
                Dashboard
              </button> */}
                <button
                  class="formButton formButton-sm"
                  style={{ fontSize: "12px" }}
                  onClick={this.endCall}
                >
                  End chat
                </button>

                <button
                  class="formButton formButton-sm"
                  style={{ fontSize: "12px" }}
                  onClick={this.startArchave}
                >
                  Start
                </button>

                <button
                  class="formButton formButton-sm"
                  style={{ fontSize: "12px" }}
                  onClick={this.stopArchive}
                >
                  Stop
                </button>
                <button
                  class="formButton formButton-sm"
                  style={{ fontSize: "12px" }}
                  onClick={this.ListArchive}
                >
                  List
                </button>

                <div class="recordContainer" style={{ display: "none" }}>
                  <span id="start" title="Screen Recording">
                    {" "}
                    <img src={screenRecord} width="30"></img>{" "}
                  </span>
                  <span id="stop" className="screenRecordingMain" disabled>
                    <img src={screenRecording} width="30"></img>
                    <span className="screenRecordingText">Recording...</span>
                  </span>
                  <button
                    id="uploadRecord"
                    style={{ display: "none" }}
                    disabled
                  >
                    Upload
                  </button>
                  <video
                    autoplay
                    controls
                    style={{ width: "300px", display: "none" }}
                  />
                </div>
              </span>
            )}
            {window.sessionStorage.getItem("role") === "ROLE_USER" && (
              <span>
                {/* <button
                class="formButton formButton-sm mr-1"
                onClick={this.appointmentList}
              >
                Appointment(s)
              </button> */}

                <button
                  class="formButton formButton-sm"
                  style={{ fontSize: "12px" }}
                  onClick={this.endCall}
                >
                  End chat
                </button>
              </span>
            )}
          </div>
          <div className="App-main video-chat-main video-chat-fullScreen">
            <div className="App-video-container">
              {/* <div className="video-screen-icon" onClick={this.fullscreen}></div> */}
              {!connected && connectingMask()}
              {connected && !active && startCallMask(this.startCall)}

              <div
                id="cameraPublisherContainer"
                className={cameraPublisherClass}
              />
              <div
                id="screenPublisherContainer"
                className={screenPublisherClass}
              />

              <div
                id="cameraSubscriberContainer"
                className={cameraSubscriberClass}
                style={{
                  display:
                    window.sessionStorage.getItem("drcallType") === "audio"
                      ? "none"
                      : "block",
                }}
              />

              <div
                id="screenSubscriberContainer"
                className={screenSubscriberClass}
              />
            </div>
            <div id="videoControls" className={controlClass}>
              <div
                className={localAudioClass}
                onClick={this.toggleLocalAudio}
                title="Enable / disable Audio"
              />
              <div
                className={localVideoClass}
                onClick={this.toggleLocalVideo}
                id="vcEnable"
                title="Enable / disable video"
              />

              <div
                className={localCallClass}
                onClick={this.endCall}
                title="End call"
              />
            </div>
            <div className="App-control-container-dup">
              {window.sessionStorage.getItem("drcallType") !== "audio" && (
                <div className="ots-dup-circle" />
              )}
              <div className="ots-dup-circle" />
              <div className="ots-dup-circle" />
              {/* <div
              className="ots-dup-circle ots-dup-circle-active"
              id="ots-dup-chat"
              
            /> */}
            </div>

            <div
              id="chat"
              className="App-chat-container video-chat-container ots-hiddenn"
              title="Text chat"
              onClick={this.textChatClk}
            >
              <div
                class="video-chat-left text-center"
                style={{ display: "none" }}
              >
                <small class="color-maintext">
                  You are speaking to:{" "}
                  <span style={{ whiteSpace: "nowrap" }}>
                    {window.sessionStorage.getItem("role") === "ROLE_USER" ? (
                      <strong>
                        {window.sessionStorage.getItem("vc_showName1")}
                      </strong>
                    ) : (
                      <strong>
                        Dr. {window.sessionStorage.getItem("vc_showName1")}
                      </strong>
                    )}
                  </span>
                </small>
                <p className="vChatSpecial">
                  <small>
                    {window.sessionStorage.getItem("vc_showName2")},{" "}
                    {window.sessionStorage.getItem("vc_showName3")}
                  </small>
                </p>
              </div>
              <div class="App-chat-attach">
                <div
                  class="messageFileUpload"
                  style={{ display: "none" }}
                  onClick={this.uploadattach}
                >
                  <input
                    id="file1"
                    name="file1"
                    class="uploadattach"
                    type="file"
                    onChange={this.fileChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="loader"></div>
          <Suspense fallback={<div></div>}>
            <section>{/* <Footer /> */}</section>
          </Suspense>
        </div>
      </>
    );
  }
}

export default App;
