import React, { Component, Suspense } from "react";
import Closebtn from "../../image/icons/close-sm.png";
import AddImg from "../../image/icons/addbtn.png";
import $ from "jquery";

export default class Servicedetails extends Component {

  componentDidMount() {
    if (this.props.hospitalData1.length > 0) {
      $("#description").val(this.props.hospitalData1[0].description);
      $("#searchKeywords").val(this.props.hospitalData1[0].searchKeywords);
      $("#officeDetails").val(this.props.hospitalData1[0].officeDetails);
      $("#serviceOffered").val(this.props.hospitalData1[0].serviceOffered);
    }
  }

  addServicesTime() {
    let a = {
      hospitalServiceId: $("#cmbServices").val(),
      servicesTimeFrom: $("#servicesTimeFrom").val(),
      servicesTimeTo: $("#servicesTimeTo").val(),
    };
    var showalertforempty =a.hospitalServiceId ==="0" ||(a.servicesTimeFrom ==='From' || a.servicesTimeFrom=== null)||a.servicesTimeTo==="To" || a.servicesTimeTo=== null
    if(showalertforempty){

      alert("Service Details Required")
      return ;
    }
    let b = this.props.hospitalServiceDetailsEdit;
    b.push(a);

    this.props.setHospitalServiceDetailsEdit(b);

    $("#servicesTimeFrom").val("0");

    $("#servicesTimeTo").val("0");

    $("#cmbServices").val("0");
  }

  removeServicesTime(hospitalServiceId) {
    if (this.props.hospitalServiceDetailsEdit.length > 0) {
      var data = $.grep(this.props.hospitalServiceDetailsEdit, function (e) {
        return e.hospitalServiceId !== hospitalServiceId;
      });

      this.props.setHospitalServiceDetailsEdit(data);
    }
  }

  setAchievementData(value, name, i, mode) {
    let temp = this.props.hospitalServiceDetailsEdit;
    if (mode === "cmbServices") {
      temp[i].hospitalServiceId = $("#" + mode + i).val();
    } else if (mode === "servicesTimeFrom") {
      temp[i].servicesTimeFrom = $("#" + mode + i).val();
    } else if (mode === "servicesTimeTo") {
      temp[i].servicesTimeTo = $("#" + mode + i).val();
    }

    this.props.setHospitalServiceDetailsEdit(temp);
  }

  description() {
    var name1 = $("#description").val();
    $("#officeDetails").val(name1);
  }
  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead mb-4">
        <Suspense fallback={<div></div>}>
          <section>
            <div className="consultngTime-wrapper">
              <h2 className="hspInfoSubhead">Services Details</h2>

              <div className="hsptaltimeHead">
                <div className="educationdoctr-contentdiv">
                  <label className="educationdoctrlabelwidth servcelabel-hspwidth">
                    Services <span className="hspifoMantory"></span>
                  </label>

                  <select
                    id="cmbServices"
                    name="cmbServices"
                    className="hspInfofieldinput eductninputwidthdctr"
                  >
                    <option value="0">Select</option>

                    {this.props.servicesData.map((servicesDataObj, s) => {
                      return (
                        <option
                          key={servicesDataObj.serviceId}
                          value={servicesDataObj.serviceId}
                        >
                          {servicesDataObj.serviceName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                  <label className="tmelabel-hspadmin">Timing From</label>

                  <select name="servicesTimeFrom" id="servicesTimeFrom">
                    <option selected="From">From</option>

                    <option value="01:00 AM">01:00 AM</option>

                    <option value="01:30 AM">01:30 AM</option>

                    <option value="02:00 AM">02:00 AM</option>

                    <option value="02:30 AM">02:30 AM</option>

                    <option value="03:00 AM">03:00 AM</option>

                    <option value="03:30 AM">03:30 AM</option>

                    <option value="04:00 AM">04:00 AM</option>

                    <option value="04:30 AM">04:30 AM</option>

                    <option value="05:00 AM">05:00 AM</option>

                    <option value="05:30 AM">05:30 AM</option>

                    <option value="06:00 AM">06:00 AM</option>

                    <option value="06:30 AM">06:30 AM</option>

                    <option value="07:00 AM">07:00 AM</option>

                    <option value="07:30 AM">07:30 AM</option>

                    <option value="08:00 AM">08:00 AM</option>

                    <option value="08:30 AM">08:30 AM</option>

                    <option value="09:00 AM">09:00 AM</option>

                    <option value="09:30 AM">09:30 AM</option>

                    <option value="10:00 AM">10:00 AM</option>

                    <option value="10:30 AM">10:30 AM</option>

                    <option value="11:00 AM">11:00 AM</option>

                    <option value="11:30 AM">11:30 AM</option>

                    <option value="12:00 AM">12:00 AM</option>

                    <option value="12:30 AM">12:30 AM</option>

                    <option value="01:00 PM">01:00 PM</option>

                    <option value="01:30 PM">01:30 PM</option>

                    <option value="02:00 PM">02:00 PM</option>

                    <option value="02:30 PM">02:30 PM</option>

                    <option value="03:00 PM">03:00 PM</option>

                    <option value="03:30 PM">03:30 PM</option>

                    <option value="04:00 PM">04:00 PM</option>

                    <option value="04:30 PM">04:30 PM</option>

                    <option value="05:00 PM">05:00 PM</option>

                    <option value="05:30 PM">05:30 PM</option>

                    <option value="06:00 PM">06:00 PM</option>

                    <option value="06:30 PM">06:30 PM</option>

                    <option value="07:00 PM">07:00 PM</option>

                    <option value="07:30 PM">07:30 PM</option>

                    <option value="08:00 PM">08:00 PM</option>

                    <option value="08:30 PM">08:30 PM</option>

                    <option value="09:00 PM">09:00 PM</option>

                    <option value="09:30 PM">09:30 PM</option>

                    <option value="10:00 PM">10:00 PM</option>

                    <option value="10:30 PM">10:30 PM</option>

                    <option value="11:00 PM">11:00 PM</option>

                    <option value="11:30 PM">11:30 PM</option>

                    <option value="12:00 PM">12:00 PM</option>

                    <option value="12:30 PM">12:30 PM</option>

                    <option value="12:00 AM">12:00 AM </option>
                  </select>
                </div>

                <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                  <label className="tmelabel-hspadmin-to">Timing To</label>

                  <select name="servicesTimeTo" id="servicesTimeTo">
                    <option selected="From">To</option>

                    <option value="01:00 AM">01:00 AM</option>

                    <option value="01:30 AM">01:30 AM</option>

                    <option value="02:00 AM">02:00 AM</option>

                    <option value="02:30 AM">02:30 AM</option>

                    <option value="03:00 AM">03:00 AM</option>

                    <option value="03:30 AM">03:30 AM</option>

                    <option value="04:00 AM">04:00 AM</option>

                    <option value="04:30 AM">04:30 AM</option>

                    <option value="05:00 AM">05:00 AM</option>

                    <option value="05:30 AM">05:30 AM</option>

                    <option value="06:00 AM">06:00 AM</option>

                    <option value="06:30 AM">06:30 AM</option>

                    <option value="07:00 AM">07:00 AM</option>

                    <option value="07:30 AM">07:30 AM</option>

                    <option value="08:00 AM">08:00 AM</option>

                    <option value="08:30 AM">08:30 AM</option>

                    <option value="09:00 AM">09:00 AM</option>

                    <option value="09:30 AM">09:30 AM</option>

                    <option value="10:00 AM">10:00 AM</option>

                    <option value="10:30 AM">10:30 AM</option>

                    <option value="11:00 AM">11:00 AM</option>

                    <option value="11:30 AM">11:30 AM</option>

                    <option value="12:00 AM">12:00 AM</option>

                    <option value="12:30 AM">12:30 AM</option>

                    <option value="01:00 PM">01:00 PM</option>

                    <option value="01:30 PM">01:30 PM</option>

                    <option value="02:00 PM">02:00 PM</option>

                    <option value="02:30 PM">02:30 PM</option>

                    <option value="03:00 PM">03:00 PM</option>

                    <option value="03:30 PM">03:30 PM</option>

                    <option value="04:00 PM">04:00 PM</option>

                    <option value="04:30 PM">04:30 PM</option>

                    <option value="05:00 PM">05:00 PM</option>

                    <option value="05:30 PM">05:30 PM</option>

                    <option value="06:00 PM">06:00 PM</option>

                    <option value="06:30 PM">06:30 PM</option>

                    <option value="07:00 PM">07:00 PM</option>

                    <option value="07:30 PM">07:30 PM</option>

                    <option value="08:00 PM">08:00 PM</option>

                    <option value="08:30 PM">08:30 PM</option>

                    <option value="09:00 PM">09:00 PM</option>

                    <option value="09:30 PM">09:30 PM</option>

                    <option value="10:00 PM">10:00 PM</option>

                    <option value="10:30 PM">10:30 PM</option>

                    <option value="11:00 PM">11:00 PM</option>

                    <option value="11:30 PM">11:30 PM</option>

                    <option value="12:00 PM">12:00 PM</option>

                    <option value="12:30 PM">12:30 PM</option>

                    <option value="12:00 AM">12:00 AM </option>
                  </select>
                </div>

                <div className="addimgeDivHsp">
                  <img
                    src={AddImg}
                    alt="Add"
                    onClick={() => this.addServicesTime()}
                  />
                </div>
              </div>

              {this.props.hospitalServiceDetailsEdit.map((Obj, i) => {
                return (
                  <div key={i} className="hsptaltimeHead">
                    <div className="educationdoctr-contentdiv">
                      <label className="educationdoctrlabelwidth servcelabel-hspwidth">
                        Services <span className="hspifoMantory"></span>
                      </label>

                      <select
                        id={"cmbServices" + i}
                        name={"cmbServices" + i}
                        value={Obj.hospitalServiceId}
                        className="hspInfofieldinput eductninputwidthdctr"
                        onChange={(e) =>
                          this.setAchievementData(
                            e,
                            "cmbServices" + i,
                            i,
                            "cmbServices"
                          )
                        }
                      >
                        <option value="0">Select</option>

                        {this.props.servicesData.map((servicesDataObj, s) => {
                          return (
                            <option
                              key={servicesDataObj.serviceId}
                              value={servicesDataObj.serviceId}
                            >
                              {servicesDataObj.serviceName}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                      <label className="educationdoctrlabelwidth tmelabel-hspadmin tmelabel-hspadminmob">
                        Timing From <span className="hspifoMantory"></span>
                      </label>

                      <select
                        name={"servicesTimeFrom" + i}
                        id={"servicesTimeFrom" + i}
                        value={Obj.servicesTimeFrom}
                        onChange={(e) =>
                          this.setAchievementData(
                            e,
                            "servicesTimeFrom" + i,
                            i,
                            "servicesTimeFrom"
                          )
                        }
                      >
                        <option selected="From">From</option>

                        <option value="01:00 AM">01:00 AM</option>

                        <option value="01:30 AM">01:30 AM</option>

                        <option value="02:00 AM">02:00 AM</option>

                        <option value="02:30 AM">02:30 AM</option>

                        <option value="03:00 AM">03:00 AM</option>

                        <option value="03:30 AM">03:30 AM</option>

                        <option value="04:00 AM">04:00 AM</option>

                        <option value="04:30 AM">04:30 AM</option>

                        <option value="05:00 AM">05:00 AM</option>

                        <option value="05:30 AM">05:30 AM</option>

                        <option value="06:00 AM">06:00 AM</option>

                        <option value="06:30 AM">06:30 AM</option>

                        <option value="07:00 AM">07:00 AM</option>

                        <option value="07:30 AM">07:30 AM</option>

                        <option value="08:00 AM">08:00 AM</option>

                        <option value="08:30 AM">08:30 AM</option>

                        <option value="09:00 AM">09:00 AM</option>

                        <option value="09:30 AM">09:30 AM</option>

                        <option value="10:00 AM">10:00 AM</option>

                        <option value="10:30 AM">10:30 AM</option>

                        <option value="11:00 AM">11:00 AM</option>

                        <option value="11:30 AM">11:30 AM</option>

                        <option value="12:00 AM">12:00 AM</option>

                        <option value="12:30 AM">12:30 AM</option>

                        <option value="01:00 PM">01:00 PM</option>

                        <option value="01:30 PM">01:30 PM</option>

                        <option value="02:00 PM">02:00 PM</option>

                        <option value="02:30 PM">02:30 PM</option>

                        <option value="03:00 PM">03:00 PM</option>

                        <option value="03:30 PM">03:30 PM</option>

                        <option value="04:00 PM">04:00 PM</option>

                        <option value="04:30 PM">04:30 PM</option>

                        <option value="05:00 PM">05:00 PM</option>

                        <option value="05:30 PM">05:30 PM</option>

                        <option value="06:00 PM">06:00 PM</option>

                        <option value="06:30 PM">06:30 PM</option>

                        <option value="07:00 PM">07:00 PM</option>

                        <option value="07:30 PM">07:30 PM</option>

                        <option value="08:00 PM">08:00 PM</option>

                        <option value="08:30 PM">08:30 PM</option>

                        <option value="09:00 PM">09:00 PM</option>

                        <option value="09:30 PM">09:30 PM</option>

                        <option value="10:00 PM">10:00 PM</option>

                        <option value="10:30 PM">10:30 PM</option>

                        <option value="11:00 PM">11:00 PM</option>

                        <option value="11:30 PM">11:30 PM</option>

                        <option value="12:00 PM">12:00 PM</option>

                        <option value="12:30 PM">12:30 PM</option>

                        <option value="12:00 AM">12:00 AM </option>
                      </select>
                    </div>

                    <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                      <label className="educationlabelwidthyear tmelabel-hspadmin-to">
                        Timing To
                      </label>

                      <select
                        name={"servicesTimeTo" + i}
                        id={"servicesTimeTo" + i}
                        value={Obj.servicesTimeTo}
                        onChange={(e) =>
                          this.setAchievementData(
                            e,
                            "servicesTimeTo" + i,
                            i,
                            "servicesTimeTo"
                          )
                        }
                      >
                        <option selected="From">To</option>

                        <option value="01:00 AM">01:00 AM</option>

                        <option value="01:30 AM">01:30 AM</option>

                        <option value="02:00 AM">02:00 AM</option>

                        <option value="02:30 AM">02:30 AM</option>

                        <option value="03:00 AM">03:00 AM</option>

                        <option value="03:30 AM">03:30 AM</option>

                        <option value="04:00 AM">04:00 AM</option>

                        <option value="04:30 AM">04:30 AM</option>

                        <option value="05:00 AM">05:00 AM</option>

                        <option value="05:30 AM">05:30 AM</option>

                        <option value="06:00 AM">06:00 AM</option>

                        <option value="06:30 AM">06:30 AM</option>

                        <option value="07:00 AM">07:00 AM</option>

                        <option value="07:30 AM">07:30 AM</option>

                        <option value="08:00 AM">08:00 AM</option>

                        <option value="08:30 AM">08:30 AM</option>

                        <option value="09:00 AM">09:00 AM</option>

                        <option value="09:30 AM">09:30 AM</option>

                        <option value="10:00 AM">10:00 AM</option>

                        <option value="10:30 AM">10:30 AM</option>

                        <option value="11:00 AM">11:00 AM</option>

                        <option value="11:30 AM">11:30 AM</option>

                        <option value="12:00 AM">12:00 AM</option>

                        <option value="12:30 AM">12:30 AM</option>

                        <option value="01:00 PM">01:00 PM</option>

                        <option value="01:30 PM">01:30 PM</option>

                        <option value="02:00 PM">02:00 PM</option>

                        <option value="02:30 PM">02:30 PM</option>

                        <option value="03:00 PM">03:00 PM</option>

                        <option value="03:30 PM">03:30 PM</option>

                        <option value="04:00 PM">04:00 PM</option>

                        <option value="04:30 PM">04:30 PM</option>

                        <option value="05:00 PM">05:00 PM</option>

                        <option value="05:30 PM">05:30 PM</option>

                        <option value="06:00 PM">06:00 PM</option>

                        <option value="06:30 PM">06:30 PM</option>

                        <option value="07:00 PM">07:00 PM</option>

                        <option value="07:30 PM">07:30 PM</option>

                        <option value="08:00 PM">08:00 PM</option>

                        <option value="08:30 PM">08:30 PM</option>

                        <option value="09:00 PM">09:00 PM</option>

                        <option value="09:30 PM">09:30 PM</option>

                        <option value="10:00 PM">10:00 PM</option>

                        <option value="10:30 PM">10:30 PM</option>

                        <option value="11:00 PM">11:00 PM</option>

                        <option value="11:30 PM">11:30 PM</option>

                        <option value="12:00 PM">12:00 PM</option>

                        <option value="12:30 PM">12:30 PM</option>

                        <option value="12:00 AM">12:00 AM </option>
                      </select>
                    </div>

                    <div className="addimgeDivHsp">
                      <img
                        src={Closebtn}
                        alt="Close"
                        onClick={() =>
                          this.removeServicesTime(Obj.hospitalServiceId)
                        }
                        width="20"
                      />
                    </div>
                  </div>
                );
              })}

              <div className="hsplistTextareaWrapper">
                <div className="mt-4">
                  <label className="label-hspservice">
                    Description<span className="hspifoMantory">*</span>
                  </label>

                  <div>
                    <textarea
                      name="description"
                      id="description"
                      cols="30"
                      rows="10"
                      placeholder="Description"
                      className="textarea-hspservice"
                      onKeyUp={() => this.description()}
                    ></textarea>
                  </div>
                </div>

                <div className="mt-4">
                  <label className="label-hspservice">Search Keyword</label>

                  <div>
                    <textarea
                      name="searchKeywords"
                      id="searchKeywords"
                      cols="30"
                      rows="10"
                      placeholder="Search Keyword"
                      className="textarea-hspservice"
                    ></textarea>
                  </div>
                </div>

                <div className="mt-4">
                  <label className="label-hspservice">Office Details</label>

                  <div>
                    <textarea
                      name="officeDetails"
                      id="officeDetails"
                      cols="30"
                      rows="10"
                      placeholder="Office Details"
                      className="textarea-hspservice"
                    ></textarea>
                  </div>
                </div>

                <div className="mt-4">
                  <label className="label-hspservice">Service Offered</label>

                  <div>
                    <textarea
                      name="serviceOffered"
                      id="serviceOffered"
                      cols="30"
                      rows="10"
                      placeholder=""
                      className="textarea-hspservice textarea-hspservicewidth"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Suspense>

        <Suspense fallback={<div></div>}></Suspense>
      </div>
    );
  }
}
