import React, { Component } from 'react';
import Header from "../Reliance/header"
import Footer from "../Reliance/footer"
import $ from "jquery";

export default class email extends Component {
  componentDidMount(){
    $("#divSomeoneElse").hide();
    $("#file1,#remfile1,#file2,#remfile2,#file3,#remfile3").hide();
    $("#inlineRadioSomeone").click(function()		
    {		 
		  $("#divSomeoneElse").show();
    });
    $("#attach").click(function(){
	
      if($("#file1").is(':hidden'))
      {
      $("#file1").show();
      $("#file1").click();
      $("#remfile1").show();
      }
      else if($("#file2").is(':hidden'))
      {
      $("#file2").show();
      $("#file2").click();
      $("#remfile2").show();
      }
      else if($("#file3").is(':hidden'))
      {
      $("#file3").show();
      $("#file3").click();
      $("#remfile3").show();
      }
      
    });
    $("#remfile1").click(function(){	 	
  		$("#file1").val("");
  		$("#file1").hide();
  		$("#remfile1").hide();
  	});
	$("#remfile2").click(function(){
  		$("#file2").val("");
  		$("#file2").hide();
  		$("#remfile2").hide();
  	});
	$("#remfile3").click(function(){
  		$("#file3").val("");
  		$("#file3").hide();
  		$("#remfile3").hide();
    });
    
    $(".radio-item").click(function () {
      $(".radio-item").removeClass("radio-item-active");
      $(this).addClass("radio-item-active");
    });
    
    $(".popup-hide").click(function () {
      $(".popup-overlay").hide();
    });
    $("html").click(function () {
      $(".popup-overlay").hide();
    });

    $(".popup-content").click(function (event) {
      event.stopPropagation();
    });

  }
  termcondtn() {
    $("#term-main").show();
  }
  render() {
    return (
      <div class="purpleWrap">
        <Header></Header>
        <div class="container">
          <div class="main-wrapper-smwidth">
            <div class="doctorDetail">
              <h3 class="doctor-name">Dr.  Video schedule doc MBBS</h3>
              <p class="doctoAddress">Ortho</p>
              <p class="doctoAddress">Trivandrum  - 695582 </p>
              <p class="doctoAddress">Kerala, India</p>
            </div>
            <div class="form-section mt-4">
              <div className="row">
                <div class="row-margin col-sm-12">
                  <label class="col-4 label-text">Name</label>
                  <label class="col-8 ">Name</label>
                </div>

                <div class="row-margin col-sm-12">
                  <label class="col-sm-4 label-text">Asking Question For?</label>
                  <div class="col-sm-8">
                    <div class="form-check form-check-inline radio-item">
                      <input class="form-check-input" type="radio" id="inlineRadiome" />
                      <label class="form-check-label" for="inlineRadiome">Me</label>
                    </div>
                    <div class="form-check form-check-inline radio-item">
                      <input class="form-check-input" type="radio" id="inlineRadioSomeone" value="option2" />
                      <label class="form-check-label" for="inlineRadioSomeone">Someone Else</label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 row pr-0" id="divSomeoneElse">
                  <div class="row-margin col-sm-6">
                    <label class="col-sm-12 label-text">
                      First Name <span className="star-red">*</span>
                    </label>
                    <div class="col-sm-12">
                      <input
                        type="text"
                        class="input-design"
                        id="sFirstName"
                        name="sFirstName"
                        placeholder="First Name"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>

                  <div class="row-margin col-sm-6">
                    <label class="col-sm-12 label-text">
                      Last Name <span className="star-red">*</span>
                    </label>
                    <div class="col-sm-12">
                      <input
                        type="text"
                        class="input-design"
                        placeholder="Last Name"
                        name="sLastName"
                        id="sLastName"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>

                  <div class="row-margin col-sm-6">
                    <label class="col-sm-12 label-text">
                      Date Of Birth <span className="star-red">*</span>
                    </label>
                    <div class="col-sm-12">
                      <input
                        type="date"
                        name="sDOB"
                        id="sDOB"
                        class="input-design"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>

                  <div class="row-margin col-sm-6">
                    <label class="col-sm-12 label-text">
                      Gender <span className="star-red">*</span>
                    </label>
                    <div class="col-sm-12">
                      <select
                        class="input-design input-box-w-150"
                        name="sGender"
                        id="sGender"
                        onChange={this.handleInputChange}
                      >
                        <option>Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class=" row-margin col-sm-12">
                  <label class="col-sm-4 label-text">Question  <span className="star-red">*</span> </label>
                  <div class="col-sm-8">
                    <textarea class="input-design" placeholder="Type Your Question" rows="3"></textarea>
                  </div>
                </div>

                <div class="col-sm-12 mt-2 px-5">
                  <div id="attach" title="Add" class="fileUpload mr-3 float-left">Add image</div>
                  <p class="note">Format: JPEG,JPG,PNG. Image Size: 500*245. File Size: 10MB maximum.(optional)</p>
                </div>
                        
                  <div class="fileAttachment col-sm-12">
                    <div class="fileAttachmentList">
                    <input type="file" id="file1" class="attchedFile" />
                    <input type="button" id="remfile1" class="attchedDelete"/>
                    </div>
                    <div class="fileAttachmentList">
                    <input type="file" id="file2" class="attchedFile" />
                    <input type="button" id="remfile2" class="attchedDelete"/>
                    </div>
                    <div class="fileAttachmentList">
                    <input type="file" id="file3" class="attchedFile" />
                    <input type="button" id="remfile3" class="attchedDelete"/>
                    </div>
                  </div>

                  <div class="col-sm-11 ml-5 term-condtn-main mb-3"  for="invalidCheck" >
                      <label className="checkbox-main">
                        <input type="checkbox" id="invalidCheck" />
                        <span
                          class="checkbox"
                        ></span>
                      </label>
                      I agree with{" "}
                      <a href="#" onClick={this.termcondtn} style={{ color: "#464646" }}>
                        Terms and Conditions
                      </a>
                  </div>

                <div className="col-sm-12 text-right">
                  <a href="../patient/emailconfirm">
                    <input type="submit" value="Submit" className="formButton formButtonBold"></input>
                  </a>
                  <input type="button" value="Cancel" className="formButtonBold cancelButton"></input>
                </div>

              </div>
            </div>
          </div>
        </div>

        {/* Popup */}
        <div class="popup-overlay" id="term-main">
          <div className="popup-content">
            <div className="modal-popup">
              <a className="close-popup  popup-hide">
                &times;
              </a>
              <div className="header border-0"> Embed Code </div>
              <div className="content">
                <div className="col-md-12">
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}
