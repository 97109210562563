import React, { Component, Suspense } from "react";

import Dashboardleftmenu from "./dashboardleftmenu";
import $ from "jquery";
import moment from "moment";
import { apiCalling } from "../../src/apiService";
import OfficeTime from "../doctor/doctorOfficeTime";
const folderName = window.sessionStorage.getItem("homeName");

const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class WorkTime extends Component {

  state = {

    hospitalDetailsId: '',
    hospitalName: ''
  }

  Cancel = () => {
    window.location.href = ("/doctor/doctorprofile")
  }


  saveHospital = () => {
    
    
     var flag = true

     if (flag === true) {
      // console.log('222222222222222')
      var dayArray = ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"]
      var dayFullArray = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
      for (var i = 0; i < dayArray.length; i++) {
        if ($("#sh1" + dayArray[i] + "From").val() !== "0" && $("#sh1" + dayArray[i] + "To").val() === "0") {
          alert(dayFullArray[i] + " To Time required in Schedule 1")
          $("#sh1" + dayArray[i] + "To").focus()
          flag = false;
          break;
        }
        else if ($("#sh1" + dayArray[i] + "From").val() === "0" && $("#sh1" + dayArray[i] + "To").val() !== "0") {
          alert(dayFullArray[i] + " From Time required in Schedule 1")
          $("#sh1" + dayArray[i] + "From").focus()
          flag = false;
          break;
        }
        else if ($("#sh2" + dayArray[i] + "From").val() !== "0" && $("#sh2" + dayArray[i] + "To").val() === "0") {
          alert(dayFullArray[i] + " To Time required in Schedule 2")
          $("#sh2" + dayArray[i] + "To").focus()
          flag = false;
          break;
        }
        else if ($("#sh2" + dayArray[i] + "From").val() === "0" && $("#sh2" + dayArray[i] + "To").val() !== "0") {
          alert(dayFullArray[i] + " From Time required in Schedule 2")
          $("#sh2" + dayArray[i] + "From").focus()
          flag = false;
          break;
        }
      }
      // console.log('333333333333...', flag)
      if (flag === true) {
        // console.log('44444444444444444...')
        for (var i = 0; i < dayArray.length; i++) {
          //console.log('5555...', $("#sh1" + dayArray[i] + "From").val())
          //console.log('6666...', $("#sh1" + dayArray[i] + "To").val())
          if ($("#sh1" + dayArray[i] + "From").val() !== "0" && $("#sh1" + dayArray[i] + "To").val() !== "0") {
            // console.log('iffffffffffffffffff...')
            var from_time = $("#sh1" + dayArray[i] + "From option:selected").text();
            var to_time = $("#sh1" + dayArray[i] + "To option:selected").text();
            var from = Date.parse('01/01/2011 ' + from_time);
            var to = Date.parse('01/01/2011 ' + to_time);

            
            if (from >= to) {
              alert("Incorrect time on " + dayFullArray[i] + ". To Time should be greater than From Time in Schedule 1.")
              $("#sh1" + dayArray[i] + "To").focus()
              flag = false;
              break;
            }
          }


        }
      }


      if (flag === true) {
        // console.log('44444444444444444...')
        for (var i = 0; i < dayArray.length; i++) {
          // console.log('5555...', $("#sh2" + dayArray[i] + "From").val())
          // console.log('6666...', $("#sh2" + dayArray[i] + "To").val())

          if ($("#sh2" + dayArray[i] + "From").val() !== "0" && $("#sh2" + dayArray[i] + "To").val() !== "0") {
            // console.log('if22222222222222222...')
            var from_time = $("#sh2" + dayArray[i] + "From option:selected").text();
            var to_time = $("#sh2" + dayArray[i] + "To option:selected").text();
            var from = Date.parse('01/01/2011 ' + from_time);
            var to = Date.parse('01/01/2011 ' + to_time);
            if (from >= to) {
              alert("Incorrect time on " + dayFullArray[i] + ". To Time should be greater than From Time in Schedule 2.")
              $("#sh2" + dayArray[i] + "To").focus()
              flag = false;
              break;
            }
          }

        }
      }




    }


    if (flag === true) {

      const apiJson = {
        method: "POST",

        body: JSON.stringify({
          functionName: "saveDoctorWorkingTime",
          doctorId: window.sessionStorage.getItem("userId"),
          isOwner: false,



          scheduleDetails: [
            {
              hospitalId: this.state.hospitalDetailsId,
              sunToSchedule1: $("#sh1SunTo").val(),

              sunFromSchedule1: $("#sh1SunFrom").val(),

              monToSchedule1: $("#sh1MonTo").val(),

              monFromSchedule1: $("#sh1MonFrom").val(),

              tueToSchedule1: $("#sh1TueTo").val(),

              tueFromSchedule1: $("#sh1TueFrom").val(),

              wedToSchedule1: $("#sh1WedTo").val(),

              wedFromSchedule1: $("#sh1WedFrom").val(),

              thuToSchedule1: $("#sh1ThurTo").val(),

              thuFromSchedule1: $("#sh1ThurFrom").val(),

              friToSchedule1: $("#sh1FriTo").val(),

              friFromSchedule1: $("#sh1FriFrom").val(),

              satToSchedule1: $("#sh1SatTo").val(),

              satFromSchedule1: $("#sh1SatFrom").val(),




              sunToSchedule2: $("#sh2SunTo").val(),

              sunFromSchedule2: $("#sh2SunFrom").val(),

              monToSchedule2: $("#sh2MonTo").val(),

              monFromSchedule2: $("#sh2MonFrom").val(),

              tueToSchedule2: $("#sh2TueTo").val(),

              tueFromSchedule2: $("#sh2TueFrom").val(),

              wedToSchedule2: $("#sh2WedTo").val(),

              wedFromSchedule2: $("#sh2WedFrom").val(),

              thuToSchedule2: $("#sh2ThurTo").val(),

              thuFromSchedule2: $("#sh2ThurFrom").val(),

              friToSchedule2: $("#sh2FriTo").val(),

              friFromSchedule2: $("#sh2FriFrom").val(),

              satToSchedule2: $("#sh2SatTo").val(),

              satFromSchedule2: $("#sh2SatFrom").val(),
            },
          ],


        }),

      };



      apiCalling(apiJson).then((data) => {
        if (data.success === "1") {
          alert("Working Time Updated Successfully");
          window.location.href = "/doctor/worktime"
        } else {
          alert("error");
        }
      });
    }






  }

  componentDidMount() {

    const getDetails = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDoctorDetails",
        siteId: window.sessionStorage.getItem("siteId"),
        doctorId: window.sessionStorage.getItem("userId"),
      }),
    }
    apiCalling(getDetails).then((data) => {
      //console.log("hospitalDetails")
      if (data.success === "1") {
        this.setState({
          hospitalDetailsId: data.doctorData[0].hospitalDetails[0].hospitalId,
          hospitalName: data.doctorData[0].hospitalDetails[0].name
        })

      }
    });

    $("#workingTime").addClass("dashBoardLeftMenu-active");
    const apiJson = {
      method: "POST",
      body: JSON.stringify({

        functionName: "workingTime",
        doctorId: window.sessionStorage.getItem("userId"),

      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {



        this.setState(
          () => {
            if (data.doctorData[0].hospitalData[0].timeSchedule.length > 0) {

              $("#sh1FriFrom").val(data.doctorData[0].hospitalData[0].timeSchedule[0].fri_from);
              $("#sh1FriTo").val(data.doctorData[0].hospitalData[0].timeSchedule[0].fri_to);
              $("#sh1MonFrom").val(data.doctorData[0].hospitalData[0].timeSchedule[0].mon_from);
              $("#sh1MonTo").val(data.doctorData[0].hospitalData[0].timeSchedule[0].mon_to);
              $("#sh1SatFrom").val(data.doctorData[0].hospitalData[0].timeSchedule[0].sat_from);
              $("#sh1SatTo").val(data.doctorData[0].hospitalData[0].timeSchedule[0].sat_to);
              $("#sh1SunFrom").val(data.doctorData[0].hospitalData[0].timeSchedule[0].sun_from);
              $("#sh1SunTo").val(data.doctorData[0].hospitalData[0].timeSchedule[0].sun_to);
              $("#sh1ThurFrom").val(data.doctorData[0].hospitalData[0].timeSchedule[0].thu_from);
              $("#sh1ThurTo").val(data.doctorData[0].hospitalData[0].timeSchedule[0].thu_to);
              $("#sh1TueFrom").val(data.doctorData[0].hospitalData[0].timeSchedule[0].tue_from);
              $("#sh1TueTo").val(data.doctorData[0].hospitalData[0].timeSchedule[0].tue_to);
              $("#sh1WedFrom").val(data.doctorData[0].hospitalData[0].timeSchedule[0].wed_from);
              $("#sh1WedTo").val(data.doctorData[0].hospitalData[0].timeSchedule[0].wed_to);



              $("#sh2FriFrom").val(data.doctorData[0].hospitalData[0].timeSchedule[1].fri_from);
              $("#sh2FriTo").val(data.doctorData[0].hospitalData[0].timeSchedule[1].fri_to);
              $("#sh2MonFrom").val(data.doctorData[0].hospitalData[0].timeSchedule[1].mon_from);
              $("#sh2MonTo").val(data.doctorData[0].hospitalData[0].timeSchedule[1].mon_to);
              $("#sh2SatFrom").val(data.doctorData[0].hospitalData[0].timeSchedule[1].sat_from);
              $("#sh2SatTo").val(data.doctorData[0].hospitalData[0].timeSchedule[1].sat_to);
              $("#sh2SunFrom").val(data.doctorData[0].hospitalData[0].timeSchedule[1].sun_from);
              $("#sh2SunTo").val(data.doctorData[0].hospitalData[0].timeSchedule[1].sun_to);
              $("#sh2ThurFrom").val(data.doctorData[0].hospitalData[0].timeSchedule[1].thu_from);
              $("#sh2ThurTo").val(data.doctorData[0].hospitalData[0].timeSchedule[1].thu_to);
              $("#sh2TueFrom").val(data.doctorData[0].hospitalData[0].timeSchedule[1].tue_from);
              $("#sh2TueTo").val(data.doctorData[0].hospitalData[0].timeSchedule[1].tue_to);
              $("#sh2WedFrom").val(data.doctorData[0].hospitalData[0].timeSchedule[1].wed_from);
              $("#sh2WedTo").val(data.doctorData[0].hospitalData[0].timeSchedule[1].wed_to);

            }

          }
        );
      }
      else {
        alert("error")
      }

    });
  }

  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container container-auto">
          <div class="main-wrapper1 mt-2 pt-0 pb-3">
            <div class="row">
              <div class="col-lg-3">
                <Dashboardleftmenu></Dashboardleftmenu>
              </div>
              <div class="col-lg-9">
                <div class="form-head mt-4 main_head_doc">Doctor Schedule Management</div>
                <div class="dashboard-right form-section px-2">

                  <div class="m-0 form-subhead text-muted text-capitalize text-left mb-2">
                    Hospital Name : {" "}
                    {this.state.hospitalName}{" "}
                  </div>
                  <div class="m-0 form-head text-center office_hourhead"> Office Hours</div>
                  <div class="row row-margin mx-0">
                    <label class="col-2 week-text px-0"></label>
                    <div class="col-5 px-2 label-text-brdr"> schedule 1 </div>
                    <div class="col-5 px-2 label-text-brdr"> schedule 2 </div>
                  </div>
                  <div class="row row-margin mx-0">
                    <label class="col-2 week-text px-0">Monday</label>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh1MonFrom"
                          nameValue="sh1"
                          timeType="From"
                        ></OfficeTime>

                      </div>
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh1MonTo"
                          nameValue="sh1"
                          timeType="To"
                        ></OfficeTime>
                      </div>
                    </div>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh2MonFrom"
                          nameValue="sh2"
                          timeType="From"
                        ></OfficeTime>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh2MonTo"
                          nameValue="sh2"
                          timeType="To"
                        ></OfficeTime>
                      </div>
                    </div>
                  </div>

                  <div class="row row-margin  mx-0">
                    <label class="col-2 week-text px-0">Tuesday</label>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh1TueFrom"
                          nameValue="sh1"
                          timeType="From"
                        ></OfficeTime>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh1TueTo"
                          nameValue="sh1"
                          timeType="To"
                        ></OfficeTime>
                      </div>
                    </div>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh2TueFrom"
                          nameValue="sh2"
                          timeType="From"
                        ></OfficeTime>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh2TueTo"
                          nameValue="sh2"
                          timeType="To"
                        ></OfficeTime>
                      </div>
                    </div>
                  </div>

                  <div class="row row-margin  mx-0">
                    <label class="col-2 week-text px-0">Wednesday</label>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh1WedFrom"
                          nameValue="sh1"
                          timeType="From"
                        ></OfficeTime>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh1WedTo"
                          nameValue="sh1"
                          timeType="To"
                        ></OfficeTime>
                      </div>
                    </div>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh2WedFrom"
                          nameValue="sh2"
                          timeType="From"
                        ></OfficeTime>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh2WedTo"
                          nameValue="sh2"
                          timeType="To"
                        ></OfficeTime>
                      </div>
                    </div>
                  </div>

                  <div class="row row-margin  mx-0">
                    <label class="col-2 week-text px-0">Thursday</label>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh1ThurFrom"
                          nameValue="sh1"
                          timeType="From"
                        ></OfficeTime>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh1ThurTo"
                          nameValue="sh1"
                          timeType="To"
                        ></OfficeTime>
                      </div>
                    </div>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh2ThurFrom"
                          nameValue="sh2"
                          timeType="From"
                        ></OfficeTime>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh2ThurTo"
                          nameValue="sh2"
                          timeType="To"
                        ></OfficeTime>
                      </div>
                    </div>
                  </div>

                  <div class="row row-margin  mx-0">
                    <label class="col-2 week-text px-0">Friday</label>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh1FriFrom"
                          nameValue="sh1"
                          timeType="From"
                        ></OfficeTime>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh1FriTo"
                          nameValue="sh1"
                          timeType="To"
                        ></OfficeTime>
                      </div>
                    </div>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh2FriFrom"
                          nameValue="sh2"
                          timeType="From"
                        ></OfficeTime>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh2FriTo"
                          nameValue="sh2"
                          timeType="To"
                        ></OfficeTime>
                      </div>
                    </div>
                  </div>

                  <div class="row row-margin  mx-0">
                    <label class="col-2 week-text px-0">Saturday</label>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh1SatFrom"
                          nameValue="sh1"
                          timeType="From"
                        ></OfficeTime>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh1SatTo"
                          nameValue="sh1"
                          timeType="To"
                        ></OfficeTime>
                      </div>
                    </div>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh2SatFrom"
                          nameValue="sh2"
                          timeType="From"
                        ></OfficeTime>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh2SatTo"
                          nameValue="sh2"
                          timeType="To"
                        ></OfficeTime>
                      </div>
                    </div>
                  </div>

                  <div class="row row-margin  mx-0">
                    <label class="col-2 week-text px-0">Sunday</label>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh1SunFrom"
                          nameValue="sh1"
                          timeType="From"
                        ></OfficeTime>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh1SunTo"
                          nameValue="sh1"
                          timeType="To"
                        ></OfficeTime>
                      </div>
                    </div>
                    <div class="col-5 px-2">
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh2SunFrom"
                          nameValue="sh2"
                          timeType="From"
                        ></OfficeTime>
                      </div>
                      <div class="col-md-6 d-inline-block mb-2 px-1">
                        <OfficeTime
                          idValue="sh2SunTo"
                          nameValue="sh2"
                          timeType="To"
                        ></OfficeTime>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 px-0 mt-3 text-center text-lg-left">
                    <a href="#">
                      <input
                        type="submit"
                        value="Update"
                        className="formButton formButtonBold"
                        onClick={() => this.saveHospital()}
                      ></input>
                    </a>
                    <input
                      type="button"
                      value="cancel"
                      className="formButtonBold cancelButton"
                      onClick={() => this.Cancel()}
                    ></input>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
