import React, { Component, Suspense } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Link } from "react-router-dom";
import Header from "../../Reliance/header"
import Footer from "../../Reliance/footer"


export default class AddProcedure extends Component{



render() {
    return (
        <div className="purpleWrap bg-clr-wthHead">
      <Suspense fallback={<div></div>}>
          <section>
            <Header />{" "}
            <div className='loader'></div>
          </section>
        </Suspense>
            
            <div className="container mb-5">

                {/* <div>
                      <ul className="breadcrum-adminMangmnt">
                          <li><a href="/adminManagement/DCadmindashboard">Dashboard</a></li>
                          <li><a href="/adminManagement/DCadmindashboard">Doctor Management</a></li>
                          <li><a href="" className="current">Doctor Details</a></li>
                      </ul>
                </div> */}

                <div className="smssetng-btn mb-4 mt-4">
                         <input type="submit" value="Procedure Listing" className="smssetng-svebtn" />
                          <input type="submit" value="Save" className="smssetng-svebtn" />
                          <input type="submit" value="Cancel" className="smssetng-cancelbtn" />
                </div>

              <div className="addHspPage-Wrapper">
              <Tabs>

              <TabList>
                  <Tab>Procedure Details</Tab>
             </TabList>
             <TabPanel>
                   <div className="container procedreouterPadng doctr-wraper-border">
                      <div className="smssetng-btn mb-4 mt-1 text-right">
                          <input type="submit" value="Save & Continue" className="smssetng-svebtn" />
                      </div>
                      <div className="procedhsp-contentdiv">
                            <label>Hospital Name</label>
                            <input type="text" className="hspInfofieldinput proinputMobwidt"/>
                        </div>

                        <div className="hsptaltimeHead">
                            <div className="profield-div">
                                <label>Procedures <span className="hspifoMantory">*</span></label>                           
                                    <select name="" id="" className="hspInfofieldinput eductninputwidthdctr">
                                        <option value="">Select</option>
                                        <option value="">Dr</option>
                                        <option value="">Mr</option>
                                        <option value="">Ms</option>
                                    </select>
                             </div>
                              <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                    <label htmlFor="">Min. Price <span className="priceinrtext">(INR)</span></label>
                                    <input type="text" placeholder="Price" className="hspInfofieldinput priceinputproduredetl prInpwidthMob"/>
                              </div>
                              <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                    <label htmlFor="">Max. Price <span className="priceinrtext">(INR)</span></label>
                                    <input type="text" placeholder="Price" className="hspInfofieldinput priceinputproduredetl prInpwidthMob"/>
                              </div>
                              <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                    <label htmlFor="">Buy Price <span className="priceinrtext">(INR)</span></label>
                                    <input type="text" placeholder="Price" className="hspInfofieldinput priceinputproduredetl prInpwidthMob"/>
                              </div>
                          </div>


                          <div className="hsptaltimeHead">
                    
                              <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                    <label htmlFor="">MT Min. Price <span className="priceinrtext">(USD)</span></label>
                                    <input type="text" placeholder="Price" className="hspInfofieldinput priceinputproduredetl prInpwidthMob"/>
                              </div>
                              <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                    <label htmlFor="">MT Max. Price <span className="priceinrtext">(USD)</span></label>
                                    <input type="text" placeholder="Price" className="hspInfofieldinput priceinputproduredetl prInpwidthMob"/>
                              </div>
                              <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                    <label htmlFor="">MT Buy Price <span className="priceinrtext">(USD)</span></label>
                                    <input type="text" placeholder="Price" className="hspInfofieldinput priceinputproduredetl prInpwidthMob"/>
                              </div>

                              <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                    <label htmlFor="">Status</label>
                                    <select name="" id="">
                                      <option value="">Enabled </option>
                                      <option value="">Disabled </option>
                                    </select>
                              </div>

                          </div>

                          <div className="hsptaltimeHead">
                            <div className="procedhsp-contentdiv procedhsp-contentdivWidth hsptimefieldDivMob-time">
                                  <label>Image<span className="hspifoMantory">*</span></label> 
                                  <div className="hspinfoimgaeuploadDiv procedufleupload-width"> 
                                      <div className="hspinfo-btnupload">
                                          <span>Upload</span>
                                          <input type="file" className="hspinfoInputfieldupload"/>
                                      </div>
                                  </div>                          
                              </div>
                          </div>

                          <div className="mt-4 prcdre-padngmob">
                              <label className="label-hspservice">Procedure  Details</label>
                              <div>
                                    <textarea name="" id="" cols="30" rows="20" className="textarea-hspservice"></textarea>
                              </div>
                            </div>
                   </div>

              
              </TabPanel>


              </Tabs>

                    
               

              

                  

                  
                 
              </div>
               
            </div>
            
           



            <Suspense fallback={<div></div>}>
    <section>
      <Footer />
    </section>
  </Suspense>
      </div>
       );
  }
}
