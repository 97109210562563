import React, { Component, Suspense } from "react";

import Dashboardleftmenu from "./dashboardleftmenu";
import $ from "jquery";
import avatar from "../image/theme/user.png";
import doctors from "../image/media/doctors.jpg";
import like from "../image/media/icon4.png";
// import InnerHTML from "dangerously-set-html-content";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class Dashboard extends Component {
  constructor(props){
    super(props);
    this.dashboard_html=window.sessionStorage.getItem("dashboard_html")
  }
  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container">
          <div class="main-wrapper1 mt-2 pt-0">
            <div class="row">
              <div class="col-lg-3 px-0">
                <Dashboardleftmenu></Dashboardleftmenu>
              </div>
              <div class="col-lg-9">
                {/* <InnerHTML
                  html={window.sessionStorage.getItem("dashboard_html")}
                /> */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.dashboard_html,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
