import React, { Component, Suspense } from "react";
import $ from "jquery";
import ReactTooltip from "react-tooltip";
import { apiCalling } from "../apiService";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      privacyPolicy: "",
      backupPrivacyPolicy: `<div className="terms-subhead">General</div>
      <p className="terms-p-bold">
      To use any of our services users must be 18+ years of age and
      Citizens of India.
    </p>
    <p>
      We take your Privacy Seriously. In general we only ask for,
      view and store data that is necessary to run our apps and
      websites and to facilitate engagement with Health Care
      Providers and Users and for the smooth running of the
      services..
    </p>
    <p>
      We do our very best to follow all laws regarding your Data
      Privacy and we implement industry standards and best practices
      for your Data Security.
    </p>
    <p className="terms-p-bold">Definitions</p>
    <p>
      “We”, “Our”, “Us” - refers to the owner(s) of this website or
      mobile app and its associated clinic .
    </p>
    <p>
      “You”, “Your”, “User”, “Patient”, “Client” - refer to the
      individual User of the mobile app or website and its
      associated services.
    </p>
    <p>
      “Health Care Provider” or “HCP” “Provider” - refers to
      Doctors, Clinicians, Therapists or any other health and
      medical professional, expert, consultant or practitioner that
      may be using the service.
    </p>
    <p>
      “Health Care Partner”, “Affiliate”, “Partner”, “Service
      Provider” - refer to associated partners that we may engage
      with or whose services that we use such as health care
      providers, clinics, hospitals, labs, pharmacies, insurance
      companies, medical device & equipment providers, third party
      technology providers or any third party service providers that
      we may engage with in order to provide services for the User
      of the apps & website.
    </p>
    <p>
      “Personal Information”, “Personally Identifiable Information”,
      “PII” - refer to information that you provide us that
      identifies you like:
    </p>
    <ul>
      <li>
        <p>Name, Phone Number, Home Address, Email address. </p>
      </li>
      <li>
        <p>Financial Information like Bank Accounts</p>
      </li>
    </ul>
    <p>
      “Personal Health Information”, “PHI” - refer to information
      that is provided to us in regards to users’s health like:
    </p>
    <ul>
      <li>
        <p>
          Medical information: age, gender, information on your
          medical conditions, information on your health related
          issues
        </p>
      </li>
    </ul>
    <p>
      “Digital Health Platform”, “Platform” - refer to the mobile
      app or website and the underlying technology that Users,
      Health Care Partners and Health Care Providers use to engage
      each other in terms of either accessing or providing Digital
      Health Services.
    </p>
    <p>
      “Digital Health Services”, “Health Services”, “Services” -
      Refers to the key functions offered by the Digital Health
      Platform eg. Booking an appointment with a doctor, Telehealth
      consultations, Sending of Prescriptions, Creation of an
      Electronic Health Records and any other medical or health
      related service enabled by the Platform
    </p>
    <p>
      “Telehealth”, “Telemedicine” - refers to remote consultation
      between a patient and a Health Care Provider (eg. a doctor)
      via phone-call, video-call, email, or text/message chat.
    </p>
    <p className="terms-p-bold">
      Use of Personal Data and Personal Information That Users and
      HCP’s Provide To Us
    </p>
    <p>
      In order to use our services, we require that Users and Health
      Care Providers give us personal information in order to do
      basic things like the following:
    </p>
    <ul>
      <li>
        <p>Create and Login to an account.</p>
      </li>
      <li>
        <p>
          Send SMS, Phone Calls or emails for appointment reminders,
          calls or chats between an user and a Health Care Provider
          or and any such engagement between a patient and
          healthcare provider that requires knowledge of contact
          information for either of the parties.
        </p>
      </li>
      <li>
        <p>
          Fulfill Telemedicine regulations whereby the doctor or
          health care provider is required to know the name, age,
          gender of the patient
        </p>
      </li>
      <li>
        <p>
          Creation of Electronic Medical & Health Records for
          Doctors and Health Care Providers: User’s name, gender,
          illnesses and medical history, lab tests and similar
          health related information is needed for medical and
          health professionals to have access to in order to provide
          health care and for the continuity of care.
        </p>
      </li>
      <li>
        <p>
          Purchase of Health Services by using an User’s financial
          information, insurance information or government ID
          through online methods (like online payments using
          credit-cards/debit-cards, bank transfers or other online
          payment services)
        </p>
      </li>
      <li>
        <p>
          Payment to HCP’s bank for purchased Health Care Services
          such as booking a telehealth appointment by User
        </p>
      </li>
      <li>
        <p>
          Customer Service and Support requests whereby we have to
          contact the person requesting help.
        </p>
      </li>
      <li>
        <p>
          Transmission of Personal Information to associated HCP’s
          whom Users have requested to engage with and who use the
          platform’s technologies in order fulfill services such as
          appointments, telehealth consultations, prescriptions or
          any other services.
        </p>
      </li>
      <li>
        <p>
          We may at times personalize information and content so
          that it is relevant to End-Users and share those
          recommendations. This may include relevant articles,
          content, information on healthcare providers and
          personalize potential advertisements of services or
          products that we think that End-Users may find beneficial.
        </p>
      </li>
      <li>
        <p>
          <span className="terms-p-bold">
            Note that we do not share or sell anyone’s personal
            information to advertisers.
          </span>
        </p>
      </li>
    </ul>
    <p className="terms-p-bold">
      Data Usage, Storage & Transmission
    </p>
    <p>
      Some example of what we use application and device data access
      for:
    </p>
    <ul>
      <li>
        <p>Media Storing & Transmission</p>
        <ul>
          <li>
            Users & HCP’s saving images and files of health care
            records. eg. a pdf of an x-ray, photo of a prescription
            etc…
          </li>
          <li>
            Sending and receiving digitized media through
            video-chats and messages, eg. a patient sends a doctor a
            pdf of an xray or a HCP sends a patient a photo of a
            prescription
          </li>
        </ul>
      </li>
    </ul>
    <ul>
      <li>
        <p>Analytics</p>
        <ul>
          <li>
            Transmission of Performance analytics to see if services
            are working properly
          </li>
          <li>
            Transmission of Crash & error logs to note application
            crashes and errors
          </li>
          <li>
            Transmission of Data showing usage levels of services
          </li>
        </ul>
      </li>
    </ul>
    <ul>
      <li>
        <p>Contacts</p>
        <ul>
          <li>
            Facilitate audio, video, email contact between Users &
            HCP’s. For example a doctor may need to call a patient
            or a lab may need to email a report and will save and
            access contact information. HCP’s may transmit contact
            information such as a Doctor to a Lab or a Doctor to
            another referred Doctor
          </li>
        </ul>
      </li>
    </ul>
    <ul>
      <li>
        <p>Other Data</p>
        <ul>
          <li>
            The use of technical non-personal data such as device or
            browser information which is used in order to improve
            the experience and functionality of our services as well
            as to identify and solve any technology related problems
            or issues.
          </li>
          <li>Storage of data to simplify login process.</li>
        </ul>
      </li>
    </ul>
    <p className="terms-p-bold">
      NOTE: WE DO NOT SELL PERSONAL IDENTIFIABLE INFORMATION TO DATA
      BROKERS OR ADVERTISERS, PHARMA OR INSURANCE COMPANIES
    </p>
    <p className="terms-p-bold">Data Deletion Policy</p>
    <p>
      We understand that you may want to delete your data from our
      systems. We respect your right to do so, and we have made it
      easy for you to request deletion of your data.
    </p>
    <p>
      Should you wish to delete your Personal Information from your
      account please contact support@purplehealth.com with your
      request. We do not guarantee the removal of Personal
      Information that has been shared between User and an HCP. In
      such an instance, you will have to contact the HCP directly.
      We only deal with information on this platform.
    </p>
    <p>
      To delete your data, please contact us at
      <span className="terms-p-bold">
        support@purplehealth.com
      </span>{" "}
      and provide us with your name, email address and phone number
      that is associated with the account. We will contact you to
      confirm your identity and may need additional proof of
      identity as required.
    </p>
    <p>
      Once the data deletion request has been verified, we will
      normally delete your data within 30 days of receiving your
      request.
    </p>
    <p>
      Please note that we may not be able to delete all of your
      data, such as data that is necessary for us to comply with our
      legal obligations or to protect our legitimate interests.
    </p>
    <p>
      As well, please note that once data is deleted, we will not be
      able to retrieve your data
    </p>
    <p>Thank you for your understanding.</p>
    <p>
      If you have any questions about our data deletion policy,
      please contact us at{" "}
      <span className="terms-p-bold">support@purplehealth.com</span>
    </p>
    <p className="terms-p-bold">Consent</p>
    <p>
      You agree to the above terms and conditions of our privacy and
      data usage policy.
    </p>`,
    };
  }
  componentDidMount() {
    this.getSiteDetails();
  }
  getSiteDetails = () => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getSiteDetails",
        siteId: "1",
        siteUrl: "",
      }),
    };
    apiCalling(apiJson).then((data) => {
      console.log("data", data);
      for (let obj of data.siteDetailsData[0].footerData) {
        if (obj.name.toLowerCase() == "privacy policy") {
          // console.log("objobj", obj);
          this.setState({
            privacyPolicy: obj.content,
          });
        }
      }
    });
  };
  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div class="loader"></div>}>
          <section>
            {/* {folderName != null && <Header />} */}
            <div class="mentalHeader">
              <div class=" container mentalLogo d-flex align-items-center">
                <a href="/">
                  <img
                    src="https://purplehealthfiles.s3.amazonaws.com/production/theme/rHealthAssistant/relianceLogo.png"
                    alt=""
                  />
                </a>
                <h4 class="mb-0 ml-auto support_div">
                  support@rhealthassist.com
                </h4>
              </div>
            </div>
            <div class="container">
              <div class="main-wrapper1 terms-wrapper">
                <div className="terms-head">PRIVACY & DATA USAGE POLICY</div>
                {this.state.privacyPolicy && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.privacyPolicy,
                    }}
                  ></div>
                )}
              </div>
            </div>{" "}
            {folderName != null && <Footer />}
          </section>
        </Suspense>
      </div>
    );
  }
}
