import React, { Component } from "react";
import $ from "jquery";
import { apiCalling, changeDateFormat } from "../apiService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import TimePicker from "rc-time-picker";
const format = "h:mm a";
const now = moment().hour(0).minute(0);

export default class AppointmentEditPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate:new Date(parseInt(this.props.appointmentObj.startTime)),
    };
  }
  componentDidMount() {
  //  alert(this.props.appointmentObj.appointmentId)
    $(".popup-hide").click(function () {
      $(".popup-overlay").hide();
      $("body").removeClass("overflow-hidden");
    });
  }


  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
    $("#selectDate").val(moment(date).format("DD/MM/YYYY"));
  };

 
  confirmAppointmentPopUp(id) {

    let appDate = $("#editAppDate" + id).val();
    let appTime = $("#editAppTime" + id).val();

    let appointmentTime = moment(appTime, "hh:mm a").format("LT")
    // console.log("appointmentTime",appointmentTime)
    $("body").addClass("overflow-hidden");
    let appointmentDate=appDate+" "+appTime  
    let newAppDatetest=moment(appointmentDate,"DD/MM/YYYY h:mm A").format("YYYY-MM-DD hh:mm A")
    var todateNow = new Date(); 
    var todateNow = moment(todateNow).format('YYYY-MM-DD hh:mm A');
     if(todateNow > newAppDatetest){
    alert("Select the Future Date and Time")
    }
  
    // if($("#editAppDate" + id).val()===""){
    //   alert("Please Select Appointment Date")
    // }
    // else if( $("#editAppTime" + id).val()===""){
    // alert("Please Select Appointment Time")
    // }
     else {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        "functionName": "editAppointment",
        "userId": this.props.appointmentObj.memberId,//window.sessionStorage.getItem("memberId"),
       "appointmentId": this.props.appointmentObj.appointmentId,//window.sessionStorage.getItem("appointmentId"),
       "appointmentDate":appDate,
       "appointmentTime":appointmentTime,
        "appointmentType":this.props.appointmentObj.appointmentType,//window.sessionStorage.getItem("appointmentType")
      }),
    };
    //  console.log("editappoint",apiJson.body);
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      $(".loader").show();
      //  console.log("dataesit",data)
      if (data.success == "1") {
        alert(data.successMessage);
        $("#appointment-edit-main" + this.props.appointmentObj.appointmentId).hide();
        window.location.href = "/patient/openAppointmentList"; 
      } else {
        alert(data.errorMessage);
        $(".loader").hide();
      }
    });
    
   }
  }

  render() {
    return (
      <div class="popup-overlay time-date-popup" style={{display:"none"}} 
      id={"appointment-edit-main"+this.props.appointmentObj.appointmentId}>
        <div className="popup-content">
          <div className="modal-popup">
            <a className="close-popup popup-hide">&times;</a>
            <div className="header border-0">
              {" "}
              Change Appointment Date and Time{" "}
            </div>
            <div className="content">
              <div class="row">
              <div class="col-6">
                  <label class="label-text">Appointment Date</label>
                  <DatePicker
                    placeholderText="Edit Date"
                    id={"editAppDate" + this.props.appointmentObj.appointmentId}
                    name={"editAppDate" + this.props.appointmentObj.appointmentId}
                    minDate={new Date()}
                    selected={this.state.startDate}
                    onChange={this.handleChange}
                    dateFormat="dd/MM/yyyy"
                    calendarClassName="react-date-picker"
                  />
                </div>
                <div class="col-6">
                  <label class="label-text">Appointment Time</label>
                  <TimePicker
                    showSecond={false}
                    defaultValue={moment(this.props.appointmentObj.startTime)}
                    className="app-popup-time"
                    format={format}
                    use12Hours
                    inputReadOnly
                    id={"editAppTime" + this.props.appointmentObj.appointmentId}
                    name={"editAppTime" + this.props.appointmentObj.appointmentId}
                  />
                </div>
              </div>
              <div className="text-center mt-2">
                <a href="#">
                  <input
                    type="submit"
                    value="Change"
                    className="formButtonBg mx-1"
                    onClick={() =>
                      this.confirmAppointmentPopUp(this.props.appointmentObj.appointmentId)
                    }
                  ></input>
                </a>
                <input
                  type="button"
                  value="Cancel"
                  className="formButtonBg cancelButtonBg mx-1 popup-hide"
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
