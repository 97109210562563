import React, { Component, Suspense, useState } from "react";
import $ from "jquery";
import { apiCalling } from "../../apiService";
import Header from "../../Reliance/header"
import Footer from "../../Reliance/footer"
import Logoimg from "../../image/icons/image-preview.png";
import AWS from "aws-sdk";
var s3;
var imageFile;

export default class RepAdd extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pharmaResult: [],
      mobileNumber: '',
      emailIdTxt: '',
      nameTxt: '',
      descriptionTxt: '',
      companyId: '',
      secUserId: '',
      s3bucketAccessKey: "",
      AWSAccessKeyId: "",
      region: "us-east-1",
      bucket: "",
      bucketFolder: "",
      pharmaImagePath: "",
      fileNameOnly: "",
      fileNameWithPath: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.numberHandleChange = this.numberHandleChange.bind(this);
    this.textType = this.textType.bind(this);
  }

  componentDidMount() {

    this.imageUploadHandler();
    this.companyDetails();
    if (this.props.match.params.repId) {
      this.editApprovalResult();
    }
  }

  handleChange = (event) => {

    const { value, name } = event.target;
    this.setState({
      [name]: value,
    })
  }

  textType(e){
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code > 47 && code < 58) ) { // lower alpha (a-z)
  alert("Only Numbers Accepted")
    e.preventDefault();
  }
  }

  numberHandleChange(event) {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({ mobileNumber: event.target.value })
    }
  }

  companyDetails() {
    const companyList = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getCompanyData",
      }),
    };
    apiCalling(companyList).then((data) => {
      if (data.success === "1") {
        this.setState({
          pharmaResult: data.pharmaCompanyData,
        });
      }
    });
  }

  editApprovalResult() {

    const approvalData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "editRepApproval",
        repId: this.props.match.params.repId,
      }),
    };
    $(".loader").show();
    apiCalling(approvalData).then((data) => {
      if (data.success === "1") {
        this.setState({
          mobileNumber: data.repApprovalData[0].phoneNumber,
          emailIdTxt: data.repApprovalData[0].emailId,
          nameTxt: data.repApprovalData[0].repName,
          companyId: data.repApprovalData[0].company,
          secUserId: data.repApprovalData[0].secUserId,
          descriptionTxt: data.repApprovalData[0].profileDescription,
          fileNameOnly: data.repApprovalData[0].pharmaLogoOnly,
          fileNameWithPath: data.repApprovalData[0].pharmaLogoWithPath,
        });
        if (data.repApprovalData[0].hasWritePermission === true) {
          $("#PermissionStatus").prop('checked', true);
        }
        else {
          $("#PermissionStatus").prop('checked', false);
        }
      }
      $(".loader").hide();
    });
  }

  validateForm() {

    let companyId = $("#companyType").val();

    if (this.state.mobileNumber === "") {
      alert("Phone Number Required");
      $("#mobileNumber").focus();
    }
    else if(!this.state.mobileNumber.match( /^[0-9.\b]{10}$/)){
      alert("Please enter valid mobile number");
      $("#mobileNumber").focus();
    }
     else if (this.state.emailIdTxt === "") {
      alert("Email Id Required");
      $("#emailIdTxt").focus();
      return false;
    }
    else if(!this.state.emailIdTxt.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
      alert("Please Enter Valid Email Id")
      $("#emailIdTxt").focus();
    }
     else if (this.state.nameTxt === "") {
      alert("Rep Name Required");
      $("#nameTxt").focus();
      return false;
    } else if (companyId === "") {
      alert("Company Name Required");
      $("#companyType").focus();
      return false;
    } else if (this.state.fileNameWithPath === "") {
      alert("Image required.");
      return false;
    }else {
      return true;
    }
  }

  saveHandle = () => {

    if (this.validateForm()) {
  
    let companyId = $("#companyType").val();
    let hasWritePermission = ($("#PermissionStatus").is(":checked") === true ? 'true' : 'false')
    const saveField = {
      method: "POST",
      body: JSON.stringify({
        functionName: "saveRep",
        repId: this.props.match.params.repId === "null" ? "" : this.props.match.params.repId,
        secUserId: this.state.secUserId,
        phoneNumber: this.state.mobileNumber,
        emailId: this.state.emailIdTxt,
        repName: this.state.nameTxt,
        companyId: companyId,
        hasWritePermission: hasWritePermission,
        profileDescription: this.state.descriptionTxt,
        pharmaLogo: this.state.fileNameOnly
      }),
    };
    $(".loader").show();
    apiCalling(saveField).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
        let message =
          this.props.match.params.repId > 0
            ? "Updated successfully "
            : "Save successfully";
        alert(message);
        this.cancelHandle();
      } else if (data.success === "0") {
        alert(data.errorMessage);
      }
    });
  }
  };

  imageUploadHandler() {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
        siteId: "",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        this.setState({
          s3bucketAccessKey: data.configPathDetails[0].s3bucketAccessKey,
        });
        this.setState({
          AWSAccessKeyId: data.configPathDetails[0].s3bucketSecretKey,
        });
        this.setState({
          bucket: data.configPathDetails[0].PurpleHealthS3bucket,
        });
        this.setState({
          bucketFolder: data.configPathDetails[0].S3BucketMainFolder,
        });
        this.setState({
          pharmaImagePath: data.configPathDetails[0].pharmaImagePath,
        });
      }
    });
  }

  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  fileChange = (e) => {
    imageFile = e.target.files[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      alert("Add file format with only JPG,JPEG,PNG format");
      return false;
    }
    if (imageFile.size > 3145728) {
      alert("File size must under 3 MB!");
      return false;
    } else {
      var fileObj = e.target.files[0];
      this.setState({ fileNameWithPath: window.URL.createObjectURL(fileObj) });
      this.fileUpload(imageFile);
    }
  };

  fileUpload(file) {
    AWS.config.update({
      accessKeyId: this.state.s3bucketAccessKey,
      secretAccessKey: this.state.AWSAccessKeyId,
      region: this.state.region,
    });
    var bucketParams = {
      Bucket:
        this.state.bucket +
        "/" +
        this.state.bucketFolder +
        "/" +
        this.state.pharmaImagePath,
    };
    s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    let S3BucketMainFolder = "";
    let fileName = this.uuidv4() + file.name;
    let fullFilePath = S3BucketMainFolder + fileName;
    imageFile = fileName;
    s3.upload(
      {
        Key: fullFilePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        if (err) {
          alert("There was an error uploading your photo: ", err.message);
        } else if (data) {
          this.setState({
            fileNameWithPath: data.Location,
            fileNameOnly: fileName,
          });
          return null;
        }
      }
    );
  }

  cancelHandle() {
    window.location.href = "/adminManagement/pharmadashboardList/repApprovalList";
  }

  srcTypeImage(ev) {
    ev.target.src = Logoimg;
  }

  render() {

    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />{" "}
            <div className='loader'></div>
          </section>
        </Suspense>
        <div className="container">
          <div>
            <ul className="breadcrum-adminMangmnt">
              <li><a href="/admin/dashboardAdmin">Dashboard</a></li>
              <li><a href="/adminDashboard/pharmaDashboard">Pharma Ad Approval</a></li>
              <li><a href="" className="current">Rep Approval List</a></li>
            </ul>
          </div>
          <div className="addrepwrapperborder">
            <div className="addrepOuter">
              <div className="addrep-contentdiv row mb-2">
                <label className="col-md-2 col-4">Phone No.<span className="hspifoMantory">*</span></label>
                <input type="text" className="addrepfieldinput col-md-3 col-8" id="mobileNumber" maxLength="10" name="mobileNumber" value={this.state.mobileNumber} placeholder="Phone No." onChange={this.numberHandleChange}  onKeyPress={this.textType}/>
              </div>
              <div className="addrep-contentdiv row mb-2">
                <label className="col-md-2 col-4">Email Id<span className="hspifoMantory">*</span></label>
                <input type="text" className="addrepfieldinput col-md-3 col-8" placeholder="Email Id" id="emailIdTxt" name="emailIdTxt" value={this.state.emailIdTxt} onChange={this.handleChange} />
              </div>
              <div className="addrep-contentdiv row mb-2">
                <label className="col-md-2 col-4">Rep Name<span className="hspifoMantory">*</span></label>
                <input type="text" className="addrepfieldinput col-md-3 col-8" placeholder="Rep Name" id="nameTxt" name="nameTxt" value={this.state.nameTxt} onChange={this.handleChange} />
              </div>
              <div className="addrep-contentdiv row mb-2">
                <label className="col-md-2 col-4">Company<span className="hspifoMantory">*</span></label>
                <select id="companyType"
                  name="companyType"
                  onChange={this.handleChange}
                  className="addrepfieldinput col-md-3 col-8">
                  <option value="">Select Company</option>
                  {this.state.pharmaResult.map((obj) =>
                    obj.companyId === this.state.companyId ? (
                      <option key={obj.companyId} value={obj.companyId} selected>
                        {obj.companyName}
                      </option>
                    ) : (
                      <option value={obj.companyId}>{obj.companyName}</option>
                    )
                  )}
                </select>
              </div>
              <div className="addrep-contentdiv addrep-contentdivmob mb-2">
                <label className="mb-0">Has Write Permission</label>
                <input type="checkbox" className="ml-2 align-middle" id="PermissionStatus" name="PermissionStatus" />
              </div>

              <div className="addrep-contentdiv row mb-2">
                <label className="col-md-2 col-4">Profile Description</label>
                <input type="text" className="addrepfieldinput  col-md-3 col-8" placeholder="Profile Description" id="descriptionTxt" name="descriptionTxt" value={this.state.descriptionTxt} onChange={this.handleChange} />
              </div>
              <div className="addrep-contentdiv row mb-2">
                <label  className="col-md-2 col-4">Image<span className="hspifoMantory">*</span></label>
                <div className="col-md-3 col-8 px-0">
                  <div className="hspinfo-btnupload">
                    <span>Upload</span>
                    <input type="file" className="hspinfoInputfieldupload" name="fileText" id="fileText" onChange={this.fileChange} />
                  </div>
                  {this.state.fileNameWithPath.length > 0 ?
                    <div className="fileUpload-img fileUpload-imgreptop">
                      <img
                        id="fileNameWithPath"
                        onError={this.srcTypeImage}
                        src={this.state.fileNameWithPath}
                        id="blogImage"
                        width="50"
                      ></img>
                    </div>
                    : ""}
                </div>
              </div>
            </div>
            <div className="smssetng-btn addrep-btn">
              <input type="submit" value={this.props.match.params.repId > 0 ? "Update" : "Save"} className="smssetng-svebtn" id="saveType" onClick={this.saveHandle} />
              <input type="submit" value="Cancel" className="smssetng-cancelbtn" onClick={() => this.cancelHandle()} />
            </div>
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
