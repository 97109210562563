import React, { Component } from 'react';
import { S3ImagePath } from "../config";
import { apiCalling } from "../apiService";
import { Link } from "react-router-dom";
const imgPath = S3ImagePath();
export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Support: "",
            TermsOfUse: "",
            Disclaimer: "",
            PrivacyPolicy: "",
        };
    }
    getData() {
        const userAPI = {

            method: "POST",
            body: JSON.stringify({
                "functionName": "getSiteDetails",
                "siteId": window.sessionStorage.getItem("siteId"),
                "siteUrl": null
            }),
        };
        apiCalling(userAPI).then((data) => {
            if (data.success === "1") {
                // console.log(data.siteDetailsData[0], "datadatahhhhhhhhhhhhhhhhhhhh");
                // alert("lokokokokokokokok")
                this.setState({ Support: data.siteDetailsData[0].footerData[3].name });
                this.setState({ TermsOfUse: data.siteDetailsData[0].footerData[0].name })
                this.setState({ Disclaimer: data.siteDetailsData[0].footerData[1].name })
                this.setState({ PrivacyPolicy: data.siteDetailsData[0].footerData[2].name })
            }
        });
    }
    componentDidMount() {
        this.getData()
    }
    TERMS() {
        window.location.href = "/patient/terms-Of-Use";
    }
    DISCLAIMER() {
        window.location.href = "/patient/disclaimer";
    }
    PRIVACY() {
        window.location.href = "/patient/privacy";
    }
    Powered() {
        window.open("https://www.purplehealth.com/");
    }
    render() {
        const urlPath = window.location.origin.toString();
        return (
            <div className="footerWrapper">
                <div class="row mx-0">
                    <div class="col-12 col-md-2 col-lg-2 mb-md-2 mb-2 mb-lg-0">
                        <div className="powerdBy text-center text-md-left" title="Purplehealth"  >
                            Powered by
                            <a onClick={() => this.Powered()} href="#"><span className="powererdbyouter"><img src={imgPath + "/rHealthAssistant/poweredby.png"} alt="poweredby" className="img-fluid imgResponsive" /></span></a>
                        </div>
                    </div>
                    <div class="col-12 col-md-12 col-lg-5 order-3 order-md-3 order-lg-0 pl-lg-5 pl-md-0">
                        <div class="footerLinks footerLinks-a text-center">
                            <ul className="pl-0">
                                <li><a href="/termsConditions" title='Terms Of Use'>{this.state.TermsOfUse}</a></li>
                                <li><a href="/Desclaimer" title='Disclaimer'> {this.state.Disclaimer}</a></li>
                                <li><a href="/privacyPolicy" title='Privacy Policy'>{this.state.PrivacyPolicy}</a></li>
                                {/* <li ><a href="/Support" title='Support'>  {this.state.Support}</a></li> */}
                                {/* <li ><a href="/orderMedicine" title='Order Medicine' >  Ortho</a></li> */}
                            </ul>
                        </div>
                    </div>
                    {((this.state.Support) && (this.state.Support.split("|")[0])) &&
                        <div class="col-12 col-md-10 col-lg-5 order-md-1 order-lg-0 mt-md-3 mt-lg-0">
                            <div class="footerLinks text-md-right">
                                <ul className=' pl-0'>
                                    <li ><a href="/Support" title='Support'>
                                        {this.state.Support.split("|")[0]}
                                        {/* Email: support@rhealthassist.com  */}
                                    </a></li>
                                </ul>
                            </div>
                        </div>
                    }
                    {/* </div>
                <div class="row mx-0"> */}
                    <div class="col-12 col-md-2 col-lg-2 d-md-none d-none d-lg-inline-block"></div>
                    {((this.state.Support) && (this.state.Support.split("|")[2])) &&
                        <div class="col-12 col-md-5 col-lg-5 order-2 pl-lg-5 pl-md-3">
                            <div class="footerLinks text-md-left text-lg-center">
                                <ul className="pl-0">
                                    <li><span className='footer-a'>
                                        {this.state.Support.split("|")[2]}
                                        {/* Support Hours: Mon - Fri 10am to 6 pm */}
                                    </span></li>
                                </ul>
                            </div>
                        </div>
                    }
                    {((this.state.Support) && (this.state.Support.split("|")[1])) &&
                        <div class="col-12 col-md-7 col-lg-5 pl-md-0 order-2">
                            <div class="footerLinks text-md-right">
                                <ul className=' pl-0'>
                                    <li ><span className='footer-a'>
                                        {this.state.Support.split("|")[1] && this.state.Support.split("|")[1].split(",").join(" | ")}
                                        {/* Phone: +91 9544986088 | +91 9544986088 | +91 9544986088  */}
                                    </span></li>
                                </ul>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}
