import React, { Component, Suspense } from "react";
import bolgtemp from "../image/icons/blogging.png";
import { Link } from "react-router-dom";
import $ from "jquery";
import { apiCalling } from "../apiService";
// import InnerHTML from "dangerously-set-html-content";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class JournalListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      journalData: [],
      showMessage: "Waiting For Data......",
    };
  }

  componentDidMount() {
    this.journalResultList();
  }

  journalResultList() {
    const dataList = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getJournalsListing",
        siteId: window.sessionStorage.getItem("siteId"),
        journalId: "",
      }),
    };
    $(".loader").show();
    apiCalling(dataList).then((data) => {
      $(".loader").hide();
      if (data.success === "1" && data.result.length > 0) {
        this.setState({ journalData: data.result });
      } else if (data.success === "0") {
        this.setState({ showMessage: "No Data Found " });
      }
    });
  }

  addJournalHandle() {
    window.location.href = "/doctor/addJournal";
  }

  srcTypeImage(ev) {
    ev.target.src = bolgtemp;
  }

  editJournalHandle() {
    window.sessionStorage.setItem("AddJournal", "New Journal");
  }

  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header /> <div className="loader"></div>
          </section>
        </Suspense>
        <div className="container blog-wrapper" id="journalList">
          <h1 className="telehealthfeaturehead text-center"> Journal</h1>
          <div className="row ">
            <div className="col-md-12">
              <button
                className="addNew-btn"
                onClick={() => this.addJournalHandle()}
              >
                Add Journal
              </button>
            </div>
            {this.state.journalData.length > 0 ? (
              <div className="col-lg-12 col-md-12" id="blog-detailsId">
                <div className="row mt-1 mt-md-0">
                  {this.state.journalData.map((data) => (
                    <div className="blog-listbox">
                      <div className="blog-listImgDiv">
                        <img
                          onError={this.srcTypeImage}
                          src={data.fileNameWithPath}
                          alt="Blog Image Name"
                          className="img-fluid blog-listImg"
                        ></img>
                      </div>
                      <div className="blog-list-detils">
                        <div className="blogtag-name">{data.journalTitle}</div>
                        <div className="bloguserList-text">
                          {/* <InnerHTML html={data.description} /> */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data.description,
                            }}
                          ></div>
                          
                        </div>

                        <Link
                          className="readmoreBlog"
                          to={"/doctor/addJournal/" + data.journalId}
                          onClick={this.editJournalHandle()}
                        >
                          {" "}
                          Edit Journal
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div
                className="col-lg-12 col-md-12 py-5"
                id="blog-detailsId"
                style={{ textAlign: "center" }}
              >
                <p className="error-msg text-center mb-0">
                  {" "}
                  {this.state.showMessage}{" "}
                </p>
              </div>
            )}
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
