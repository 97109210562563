import React, { Component, Suspense } from "react";

import { divide } from "lodash";
import { apiCalling } from "../apiService";
import $ from "jquery";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class VideochatSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionId: "",
      doctorName: "",
      hospitalName: "",
      ChatStartTime: "",
      ChatEndTime: "",
      drrating: "",
      VCrating: "",
      suggestions: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitSummery = this.submitSummery.bind(this);
  }
  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };
  submitSummery = (event) => {
    let drrating = this.state.drrating;
    let VCrating = this.state.VCrating;
    let suggestions = this.state.suggestions;
    let startChatData = JSON.parse(window.sessionStorage.getItem("startChatData"));
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "video/saveFeedback",
        userId: startChatData.result[0].memberId,
        hospitalId: startChatData.result[0].hospitalId,
        bundleId: "",
        doctorQuality: drrating,
        videoQuality: VCrating,
        message: suggestions,
        videoTransactionId: window.sessionStorage.getItem("videochatTransactionId"),
        siteId: window.sessionStorage.getItem("siteId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success == "1") {
        alert(data.successMessage);
        window.location.href = "/patient/videocalllist";
      } else {
        //  alert(data.errorMessage);
        window.location.href = "/patient/videocalllist";
      }
    });
  };
  componentDidMount() {
    let self = this;
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "summaryPage",
        videoTransactionId: window.sessionStorage.getItem("videochatTransactionId"),
        siteId: window.sessionStorage.getItem("siteId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success == "1") {
        this.setState({
          transactionId: data.summaryResultData[0].transactionId,
          doctorName: data.summaryResultData[0].doctorName,
          hospitalName: data.summaryResultData[0].hospitalName,
          ChatStartTime: data.summaryResultData[0].ChatStartTime,
          ChatEndTime: data.summaryResultData[0].ChatEndTime,
        });
      }
    });
    $(".radio-item").click(function () {
      $(".radio-item").removeClass("radio-item-active");
      $(this).addClass("radio-item-active");
    });
    $(".radio-item").addClass("mt-0");
  }
  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container">
          <div class="main-wrapper">
            <div class="col-sm-12">
              <div class="col-sm-12 text-center form-head">
                Your Video Chat with {this.state.doctorName} of{" "}
                {this.state.hospitalName} Ended Successfully!
              </div>
              <div class="form-section-bgcolor form-section-smwidth px-2 px-md-0">
                <div class="my-4 text-center form-subhead-brdr">
                  Video Chat Summary
                </div>
                <div class="row">
                  <label class="col-sm-6 col-7 label-text-right label-text-cln">
                    Transaction Id
                  </label>
                  <label class="col-sm-6 col-5">
                    {this.state.transactionId}
                  </label>
                </div>
                {/* <div class="row">
                  <label class="col-sm-6 col-7 label-text-right  label-text-cln">
                    Video Chat Start Time
                  </label>
                  <label class="col-sm-6 col-5">
                    {this.state.ChatStartTime}
                  </label>
                </div>
                <div class="row">
                  <label class="col-sm-6 col-7 label-text-right  label-text-cln">
                    Video Chat End Time
                  </label>
                  <label class="col-sm-6 col-5 text-left">
                    {this.state.ChatEndTime}
                  </label>
                </div> */}
              </div>
            </div>
            <div class="form-section pb-3">
              <div class="my-4 text-center form-subhead-brdr">
                Your Feedback
              </div>
              <div class="row row-margin">
                <label class="col-sm-4 label-text-right">Doctor Rating</label>
                <div class="col-sm-8 ">
                  <div class="form-check form-check-inline radio-item">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="drrating"
                      value="Low"
                      id="low"
                      onChange={this.handleInputChange}
                    />
                    <label class="form-check-label" for="low">
                      Low
                    </label>
                  </div>
                  <div class="form-check form-check-inline radio-item">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="drrating"
                      value="Average"
                      id="average"
                      onChange={this.handleInputChange}
                    />
                    <label class="form-check-label" for="average">
                      Average
                    </label>
                  </div>
                  <div class="form-check form-check-inline radio-item">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="drrating"
                      value="Good"
                      id="good"
                      onChange={this.handleInputChange}
                    />
                    <label class="form-check-label" for="good">
                      Good
                    </label>
                  </div>
                  <div class="form-check form-check-inline radio-item">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="drrating"
                      value="Very Good"
                      id="verygood"
                      onChange={this.handleInputChange}
                    />
                    <label class="form-check-label" for="verygood">
                      Very Good
                    </label>
                  </div>
                  <div class="form-check form-check-inline radio-item">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="drrating"
                      value="Excellent"
                      id="excllnt"
                      onChange={this.handleInputChange}
                    />
                    <label class="form-check-label" for="excllnt">
                      Excellent
                    </label>
                  </div>
                </div>
              </div>

              <div class="row row-margin">
                <label class="col-sm-4 label-text-right">Video Quality</label>
                <div class="col-sm-8 ">
                  <div class="form-check form-check-inline radio-item">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="VCrating"
                      value="Low"
                      id="vlow"
                      onChange={this.handleInputChange}
                    />
                    <label class="form-check-label" for="vlow">
                      Low
                    </label>
                  </div>
                  <div class="form-check form-check-inline radio-item">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="VCrating"
                      value="Average"
                      id="vaverage"
                      onChange={this.handleInputChange}
                    />
                    <label class="form-check-label" for="vaverage">
                      Average
                    </label>
                  </div>
                  <div class="form-check form-check-inline radio-item">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="VCrating"
                      value="Good"
                      id="vgood"
                      onChange={this.handleInputChange}
                    />
                    <label class="form-check-label" for="vgood">
                      Good
                    </label>
                  </div>
                  <div class="form-check form-check-inline radio-item">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="VCrating"
                      value="Very Good"
                      id="vverygood"
                      onChange={this.handleInputChange}
                    />
                    <label class="form-check-label" for="vverygood">
                      Very Good
                    </label>
                  </div>
                  <div class="form-check form-check-inline radio-item">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="VCrating"
                      value="Excellent"
                      id="vexcllnt"
                      onChange={this.handleInputChange}
                    />
                    <label class="form-check-label" for="vexcllnt">
                      Excellent
                    </label>
                  </div>
                </div>
              </div>

              <div class="row row-margin">
                <label class="col-sm-4 label-text-right">
                  How Can We Improve? <br></br>Please give your suggestions
                </label>
                <div class="col-sm-6 ">
                  <textarea
                    class="input-design"
                    rows="4"
                    name="suggestions"
                    id="suggestions"
                    onChange={this.handleInputChange}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="col-sm-12 text-center">
              <input
                type="submit"
                value="Submit"
                className="formButton formButtonBold"
                onClick={this.submitSummery}
              ></input>
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
