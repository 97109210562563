import React, { Component, Suspense } from "react";
import DatePicker from "react-datepicker";
import { apiCalling } from "../../apiService";
import $ from "jquery";
import AWS from "aws-sdk";

import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

var s3;
var fileNameUploadDrImage;
const folderName = window.sessionStorage.getItem("homeName");
// const Header = React.lazy(() => import("../" + folderName + "/header"));
// const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class OfficialInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stateData: [],
      associationData: [],
      activeDate: new Date(),
      deactiveDate: new Date().setDate(new Date().getDate() + 366),
      fileDrImage: null,
      registrationDate: new Date(),
      createdOnDate: new Date(),
      s3bucketAccessKey: "",
      s3bucketSecretKey: "",
      s3BucketMainFolder: "",
      s3BucketAddressPath: "",
      s3BucketDoctorImages: "",
      s3Bucket: "",
      associationSelectedOption: null,
    };
    this.handleChangeDateActive = this.handleChangeDateActive.bind(this);
    this.handleChangeDateDeactive = this.handleChangeDateDeactive.bind(this);
    this.handleChangeRegistration = this.handleChangeRegistration.bind(this);
  }

  componentDidMount() {
    $("#txtSortOrder").val("999");
    $("#txtAdvertisementId").val("999");
    $("#txtFreeAQAvailableCount").val("1000");
    $("#cmbAQNumFollowUp").val("0");
    $("#cmbAQPrice").val("0");
    $("#txtRating").val("0");
    $("#chkAcceptOnlineAppointment").prop("checked", true);
    $("#chkPulsePaymentDone").prop("checked", true);
    $("#chkAcceptMobileAppOnly").prop("checked", true);
    this.listing(this.props.associationEdit);
    if (this.props.doctorData.length === 0) {
      this.dataLoad();
    }
  }
  async fetchAllData() {
    await this.listing();
    await this.dataLoad();
  }

  dataLoad() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDropdownLists",
      }),
    };

    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState(
          {
            stateData: data.result[0].stateData,
            s3bucketAccessKey: data.result[0].s3bucketAccessKey,
            s3bucketSecretKey: data.result[0].s3bucketSecretKey,
            s3BucketMainFolder: data.result[0].S3BucketMainFolder,
            s3BucketAddressPath: data.result[0].s3BucketAddressPath,
            s3BucketDoctorImages: data.result[0].S3BucketDoctorImages,
            s3Bucket: data.result[0].s3Bucket,
          },
          () => {
            if (this.props.doctorData.length > 0) {
              // alert("444")
              $("#activeDate").val(this.props.doctorData[0].activeDate);
              $("#deactiveDate").val(this.props.doctorData[0].deactiveDate);
              // $("#cmbRegistrationState option:selected").text(this.props.doctorData[0].registrationstate === "" ? "Select":this.props.doctorData[0].registrationstate)
              this.registratiomState(
                this.props.doctorData[0].registrationstate
              );
              $("#cmbPracticeStartYear").val(
                this.props.doctorData[0].practice_start_year !== ""
                  ? this.props.doctorData[0].practice_start_year
                  : "0"
              );
              $("#txtMedRegistrationNo").val(
                this.props.doctorData[0].medicalRegNumber
              );
              $("#cmbNewStatus").val(
                this.props.doctorData[0].status === true ? "true" : "false"
              );
              $("#cmbMedTourism").val(
                this.props.doctorData[0].isMedicalTourism === true
                  ? "true"
                  : "false"
              );

              $("#cmbIsVideoorAudio").val(
                this.props.doctorData[0].isVideoEnabled === true
                  ? "true"
                  : "false"
              );
              $("#cmbIsVerified").val(
                this.props.doctorData[0].isVerified === true ? "true" : "false"
              );
              $("#cmbIsEHREnabled").val(
                this.props.doctorData[0].isEhrEnabled === true
                  ? "true"
                  : "false"
              );
              $("#cmbIsVaasEnabled").val(
                this.props.doctorData[0].isVaasEnabled === true
                  ? "true"
                  : "false"
              );
              // $("#chkAcceptOnlineAppointment").val(this.props.doctorData[0].isAcceptOnline === true ? "true" : "false")
              //$("#chkPulsePaymentDone").val(this.props.doctorData[0].pmPaymentDone === true ? "true" : "false")
              $("#registrationDate").val(
                this.props.doctorData[0].registrationDate
              );
              $("#txtDoctorId").val(this.props.doctorData[0].doctorId);
              $("#txtCreatedBy").val(this.props.doctorData[0].createdBy);
              $("#txtCreatedOn").val(this.props.doctorData[0].createdOn);
              $("#txtModifiedBy").val(this.props.doctorData[0].modifiedBy);
              $("#txtModifiedOn").val(this.props.doctorData[0].modifiedOn);
              $("#txtSortOrder").val(this.props.doctorData[0].sortOrder);
              $("#txtAdvertisementId").val(
                this.props.doctorData[0].advertisementId
              );
              $("#txtRating").val(this.props.doctorData[0].rating);
              $("#cmbAskQuestion").val(
                this.props.doctorData[0].askQuestion === true ? "true" : "false"
              );
              $("#cmbIsAssisted").val(
                this.props.doctorData[0].isAssistedEnabled === true
                  ? "true"
                  : "false"
              );
              $("#cmbAQNumFollowUp").val(
                this.props.doctorData[0].aqNumFollowUp
              );
              $("#cmbPhoneConsulting").val(
                this.props.doctorData[0].isPhoneConsultAvail === true
                  ? "true"
                  : "false"
              );
              $("#cmbAQPrice").val(this.props.doctorData[0].aqPrice);
              $("#txtPhoneConsultingPrice").val(
                this.props.doctorData[0].phoneConsultPrice
              );
              $("#cmbIsFreeAQ").val(
                this.props.doctorData[0].isFreeAq === true ? "true" : "false"
              );
              $("#txtInPersonConsultationPrice").val(
                this.props.doctorData[0].consultationPrice
              );
              $("#txtFreeAQAvailableCount").val(
                this.props.doctorData[0].freeAqAvailCount
              );
              $("#cmbIsAccountLocked").val(
                this.props.doctorData[0].isAccountLocked === true
                  ? "true"
                  : "false"
              );
              $("#cmbInPersonAppoinment").val(
                this.props.doctorData[0].hasInPersonAppointment === true
                  ? "true"
                  : "false"
              );

              if (this.props.doctorData[0].isAcceptOnline === true) {
                $("#chkAcceptOnlineAppointment").prop("checked", true);
              } else {
                $("#chkAcceptOnlineAppointment").prop("checked", false);
              }

              if (this.props.doctorData[0].pmPaymentDone === true) {
                $("#chkPulsePaymentDone").prop("checked", true);
              } else {
                $("#chkPulsePaymentDone").prop("checked", false);
              }
              if (this.props.doctorData[0].mobileAppOnly === true) {
                $("#chkAcceptMobileAppOnly").prop("checked", true);
              } else {
                $("#chkAcceptMobileAppOnly").prop("checked", false);
              }
              if (this.props.doctorData[0].imageNameOnly !== "") {
                $("#imgDiv1").show();
                $("#image1").attr(
                  "src",
                  this.props.doctorData[0].imageNameWithPath
                );
                //$("#hidDoctorImage").val(this.props.doctorData[0].imageNameOnly)
              }
            }
          }
        );
        AWS.config.update({
          accessKeyId: data.result[0].s3bucketAccessKey,
          secretAccessKey: data.result[0].s3bucketSecretKey,
          region: "us-east-1",
        });
        var bucketParams = {
          Bucket: data.result[0].s3Bucket,
        };
        s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
      }
    });
  }

  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  registratiomState(registrationstate) {
    if (registrationstate !== "" || registrationstate !== 0) {
      let regStateObj = this.state.stateData.filter(
        (o) => o.stateName === registrationstate
      );
      if (regStateObj.length > 0) {
        $("#cmbRegistrationState").val(regStateObj[0].stateId);
      }
    } else {
      $("#cmbRegistrationState").val(0);
    }
  }

  listing(associationEdit) {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHospitalDropdown",
      }),
    };

    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        let assoc = [];
        data.hospitalData[0].associationData.map((d) =>
          assoc.push({
            value: d.associationId,
            label: d.associationName + " - " + d.associationId,
          })
        );

        let associationSelectedOption = [];
        associationEdit.map((d) =>
          associationSelectedOption.push({
            value: d,
            label: assoc.find((element) => element.value === d).label,
          })
        );

        this.setState(
          {
            associationData: assoc,
            associationSelectedOption: associationSelectedOption,
          },
          () => {
            if (this.props.doctorData.length > 0) {
              this.dataLoad();
            }
          }
        );
      }
    });

    this.yearDropdown();
  }

  yearDropdown() {
    var ddlYears = document.getElementById("cmbPracticeStartYear");
    var currentYear = new Date().getFullYear();
    var option1 = document.createElement("OPTION");
    option1.innerHTML = "Select";
    option1.value = "0";
    if (ddlYears !== null && ddlYears !== "null") {
      ddlYears.appendChild(option1);
      for (var i = currentYear; i >= 1950; i--) {
        var option = document.createElement("OPTION");
        option.innerHTML = i;
        option.value = i;
        ddlYears.appendChild(option);
      }
    }
  }

  handleChange(event) {
    // alert(event.target.keyCode)
    const re = /^\d{0,10}(\.\d{0,6})?$/;
    if (event.target.value === "" || !re.test(event.target.value)) {
      var a = event.target.value.toString().slice(0, -1);
      $("#" + event.target.id).val(a);
      if (!re.test($("#" + event.target.id).val())) {
        $("#" + event.target.id).val("");
      }
      alert("Only Numbers Accepted");
    } else {
      return true;
    }
  }
  handleChangeDateActive(date) {
    this.setState({
      activeDate: date,
    });
  }
  disableDateField() {
    $("#activeDate").attr("readonly", "readonly");
    $("#deactiveDate").attr("readonly", "readonly");
    this.setState({
      activeDate: null,
      deactiveDate: null,
      registrationDate: null,
    });
  }

  handleChangeDateDeactive(date) {
    this.setState({
      deactiveDate: date,
    });
  }
  handleChangeRegistration(date) {
    this.setState({
      registrationDate: date,
    });
  }
  handleChangeCreatedDate(date) {
    this.setState({
      createdOnDate: date,
    });
  }
  handleImageUpload(e, mode) {
    if (typeof e.target.files[0] !== "undefined") {
      let file = e.target.files[0];
      let fileType = file["type"];
      //console.log('fileType........', fileType)
      let validImageTypes = ["image/jpg", "image/jpeg", "image/png"];
      if (!validImageTypes.includes(fileType)) {
        alert("Invalid file type");
        $("#" + mode + "File").val(null);
        if (mode === "doctorImg") {
          $("#image1").attr("src", "");
          $("#imgDiv1").hide();
        }
        // else {
        //   $("#pofileImage").attr('src', '');
        //   $("#imgProfileDiv").hide()
        // }
      } else {
        var fileObj = e.target.files[0];
        let reader = new FileReader();
        var img = new Image();
        img.src = window.URL.createObjectURL(fileObj);
        img.onload = function () {
          reader.onload = function (e) {
            if (mode === "doctorImg") {
              $("#image1").attr("src", e.target.result);
              $("#imgDiv1").show();
            }
            // else {
            //   $("#pofileImage").attr('src', e.target.result);
            //   $("#imgProfileDiv").show()
            // }
          };

          reader.readAsDataURL(fileObj);
        };
        if (mode === "doctorImg") {
          this.setState({ fileDrImage: fileObj });
        }
        // else {
        //   this.setState({ fileAppProfile: fileObj })
        // }
      }
    }
  }
  fileUpload(file, mode) {
    //alert("22")
    // alert(file.name);
    //console.log("imagggg....", file)
    let reader = new FileReader();
    //let reader = new FileReader();
    let s3BucketMainFolder1 =
      this.state.s3BucketMainFolder + "/" + this.state.s3BucketDoctorImages;
    let fileName = this.uuidv4() + file.name;
    // console.log('files3BucketMainFolder1Name.........' + s3BucketMainFolder1)
    let fullFilePath = s3BucketMainFolder1 + "/" + fileName;
    fileNameUploadDrImage = fileName;
    //console.log('file.........' + file)
    //console.log('fileName.........' + fileName)
    //console.log('fullFilePath.........' + fullFilePath)
    if (mode === "doctorImg") {
      fileNameUploadDrImage = fileName;
    }

    s3.upload(
      {
        Key: fullFilePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        if (err) {
          // console.log('img error,...', err)
          // alert(
          //     "There was an error uploading your photo: ",
          //     err.message
          // );
          return null;
        } else {
          // alert("Image uploaded successfuly")
          // //alert("fullFilePath"+fullFilePath)
          // return fullFilePath;
        }
      }
    );
  }
  enableAskQuestion() {
    if ($("#cmbAskQuestion").val() === "true") {
      $("#cmbAQNumFollowUp").attr("disabled", false);
      $("#cmbAQPrice").attr("disabled", false);
    } else {
      $("#cmbAQNumFollowUp").attr("disabled", true);
      $("#cmbAQPrice").attr("disabled", true);
    }
  }
  phoneConsultingEnable() {
    if ($("#cmbPhoneConsulting").val() === "true") {
      $("#txtPhoneConsultingPrice").attr("disabled", false);
    } else {
      $("#txtPhoneConsultingPrice").attr("disabled", true);
    }
  }
  officialInformationData() {
    var image = "";
    if (this.state.fileDrImage !== null) {
      this.fileUpload(this.state.fileDrImage, "doctorImg");
      image = fileNameUploadDrImage;
    } else if (
      this.props.doctorData.length > 0 &&
      this.props.doctorData[0].imageNameOnly !== ""
    ) {
      image = this.props.doctorData[0].imageNameOnly;
    }

    return image;
  }
  render() {
    return (
      <div className="bg-clr-wthHead offcialinfowrapperhsp-bg mb-3 reactdate_pick">
        <Suspense fallback={<div></div>}>
          <section>
            <div className="hspAdmindetails-wrapper pb-4 pt-3">
              <h2 className="hspInfoSubhead pb-3">Official Information</h2>

              <div className="hspinfo-contentdiv ">
                <label>Active Date</label>
                {/* <input type="text" className="hspInfofieldinput Infoinputwidthdoctr"/> */}
                {/* <input type="date"  name="activeDate" id="activeDate"  className="hspInfofieldinput Infoinputwidthdoctr " /> */}

                <DatePicker
                  placeholderText="Active Date"
                  id="activeDate"
                  name="activeDate"
                  // maxDate={new Date()}
                  showMonthDropdown
                  className="doctrHsp-dateinput"
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  dropdownMode="scroll"
                  peekNextMonth
                  disabledKeyboardNavigation
                  selected={this.state.activeDate}
                  onSelect={this.handleChangeDateActive}
                  dateFormat="dd/MM/yyyy"
                  calendarClassName="react-date-picker"
                  autoComplete="off"
                  onKeyDown={() => this.disableDateField()}
                />
              </div>

              <div className="hspinfo-contentdiv ">
                <label>
                  Deactive Date <span className="hspifoMantory">*</span>
                </label>
                {/* <input type="text" className="hspInfofieldinput Infoinputwidthdoctr"/> */}
                {/* <input type="date"  id="deactiveDate" name="deactiveDate"    className="hspInfofieldinput Infoinputwidthdoctr " /> */}

                <DatePicker
                  placeholderText="Deactive Date "
                  id="deactiveDate"
                  name="deactiveDate"
                  // maxDate={new Date()}
                  showMonthDropdown
                  className="doctrHsp-dateinput"
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  dropdownMode="scroll"
                  peekNextMonth
                  disabledKeyboardNavigation
                  selected={this.state.deactiveDate}
                  onSelect={this.handleChangeDateDeactive}
                  dateFormat="dd/MM/yyyy"
                  calendarClassName="react-date-picker"
                  autoComplete="off"
                  onKeyDown={() => this.disableDateField()}
                />
              </div>

              <div className="hspinfo-contentdiv">
                <label>Registration State</label>
                <select
                  name="cmbRegistrationState"
                  id="cmbRegistrationState"
                  className="hspInfofieldinput Infoinputwidthdoctr"
                >
                  <option value="0">Select</option>
                  {this.state.stateData.map((stateObj, i) => {
                    return (
                      <option key={stateObj.stateId} value={stateObj.stateId}>
                        {stateObj.stateName}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="hspinfo-contentdiv">
                <label>Practice Start Year</label>
                <select
                  name="cmbPracticeStartYear"
                  id="cmbPracticeStartYear"
                  className="hspInfofieldinput Infoinputwidthdoctr"
                ></select>
              </div>

              <div className="hspinfo-contentdiv">
                <label>Medical Register No.</label>
                <input
                  type="text"
                  id="txtMedRegistrationNo"
                  name="txtMedRegistrationNo"
                  className="hspInfofieldinput Infoinputwidthdoctr"
                  placeholder="Medical Register No."
                />
              </div>

              <div className="superadminDivBoxcover">
                <div className="superadminDivBox">
                  <label>Image(jpeg,png)</label>
                  <div className="hspinfoimgaeuploadDiv">
                    <div className="hspinfo-btnupload w-100">
                      <span>Upload</span>

                      <input
                        type="file"
                        id="doctorImg"
                        accept="image/*"
                        name="doctorImg"
                        className="hspinfoInputfieldupload"
                        onChange={(e) => this.handleImageUpload(e, "doctorImg")}
                      />
                    </div>
                    <div id="imgDiv1" style={{ display: "none" }}>
                      {" "}
                      <img height="30" width="35" id="image1"></img>
                    </div>
                    <hidden id="hidDoctorImage" name="hidDoctorImage"></hidden>
                  </div>
                </div>
              </div>

              <div className="hspinfo-contentdiv">
                <label>Status</label>
                <select
                  name="cmbNewStatus"
                  id="cmbNewStatus"
                  className="hspInfofieldinput Infoinputwidthdoctr"
                >
                  <option value="true">Enabled</option>
                  <option value="false">Disabled</option>
                </select>
              </div>

              <div className="hspinfo-contentdiv">
                <label>Medical Tourism</label>
                <select
                  name="cmbMedTourism"
                  id="cmbMedTourism"
                  className="hspInfofieldinput Infoinputwidthdoctr"
                >
                  <option value="false">Disabled</option>
                  <option value="true">Enabled</option>
                </select>
              </div>

              <div
                className="superadminDivBoxcover"
                style={{ overflow: "visible" }}
              >
                <div className="hspinfo-contentdiv association-input-doctr d-flex align-items-center">
                  <label className="pt-0">Association Name</label>
                  <Select
                    value={this.state.associationSelectedOption}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    // defaultValue={[colourOptions[4], colourOptions[5]]}
                    isMulti
                    options={this.state.associationData}
                    onChange={(selectedOption) => {
                      this.props.setAssociations(
                        selectedOption.map((a) => a.value)
                      );

                      this.setState({
                        associationSelectedOption: selectedOption,
                      });
                    }}
                  />
                  {/* 

                   <select
                    multiple
                    style={{ height: "95px" }}
                    name="cmbAssociationName"
                    id="cmbAssociationName"
                    className="hspInfofieldinput Infoinputwidthdoctr"
                  >
                    <option value="">Select</option>
                    {this.state.associationData.map((associationObj, i) => {
                      return (
                        <option
                          key={associationObj.associationId}
                          value={associationObj.associationId}
                        >
                          {associationObj.associationName}
                        </option>
                      );
                    })}
                  </select> */}
                </div>
              </div>

              <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
                <label>Is Video/Audio Enabled</label>
                <select
                  name="cmbIsVideoorAudio"
                  id="cmbIsVideoorAudio"
                  className="hspInfofieldinput hspInfofieldinputWidth"
                >
                  <option value="false">No</option>
                  <option value="true">Yes</option>
                </select>
              </div>
              <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
                <label>Is Verified</label>
                <select
                  name="cmbIsVerified"
                  id="cmbIsVerified"
                  className="hspInfofieldinput hspInfofieldinputWidth"
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>

              <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
                <label>Is EHR Enabled</label>
                <select
                  name="cmbIsEHREnabled"
                  id="cmbIsEHREnabled"
                  className="hspInfofieldinput hspInfofieldinputWidth"
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
              <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
                <label>Is vaas Enabled</label>
                <select
                  name="cmbIsVaasEnabled"
                  id="cmbIsVaasEnabled"
                  className="hspInfofieldinput hspInfofieldinputWidth"
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>

              <div className="superadminDivBoxcover">
                <div className="superadminDivBox">
                  <div className="doctrcheckboxDivhsp">
                    <input
                      type="checkbox"
                      id="chkAcceptOnlineAppointment"
                      name="chkAcceptOnlineAppointment"
                    />
                    <label className="pt-0">Accept online Appointment</label>
                  </div>

                  <div className="doctrcheckboxDivhsp">
                    <input
                      type="checkbox"
                      id="chkPulsePaymentDone"
                      name="chkPulsePaymentDone"
                    />
                    <label className="pt-0">Pulse Payment Done</label>
                  </div>
                  <div className="doctrcheckboxDivhsp">
                    <input
                      type="checkbox"
                      id="chkAcceptMobileAppOnly"
                      name="chkAcceptMobileAppOnly"
                    />
                    <label className="pt-0">Mobile App Only</label>
                  </div>
                </div>
              </div>

              <div className="hspinfo-contentdiv">
                <label>Registration Date</label>
                {/* <input type="text" className="hspInfofieldinput" /> */}

                <DatePicker
                  placeholderText="Active Date"
                  id="registrationDate"
                  name="registrationDate"
                  // maxDate={new Date()}
                  showMonthDropdown
                  className="doctrHsp-dateinput"
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  dropdownMode="scroll"
                  peekNextMonth
                  disabledKeyboardNavigation
                  selected={this.state.registrationDate}
                  onSelect={this.handleChangeRegistration}
                  dateFormat="dd/MM/yyyy"
                  calendarClassName="react-date-picker"
                  autoComplete="off"
                  onKeyDown={() => this.disableDateField()}
                />
              </div>

              <div className="hspinfo-contentdiv">
                <label>Doctor Id</label>
                <input
                  type="text"
                  id="txtDoctorId"
                  name="txtDoctorId"
                  className="hspInfofieldinput"
                  placeholder="Doctor Id"
                  disabled
                />
              </div>

              <div className="hspinfo-contentdiv">
                <label>Created By</label>
                <input
                  type="text"
                  id="txtCreatedBy"
                  name="txtCreatedBy"
                  className="hspInfofieldinput"
                  placeholder="Created By"
                  disabled
                />
              </div>

              <div className="hspinfo-contentdiv">
                <label>Created On</label>
                {/* <input type="text" id="txtCreatedOn" name="txtCreatedOn" className="hspInfofieldinput" placeholder="Created On" disabled /> */}
                <DatePicker
                  placeholderText="Active Date"
                  disabled
                  id="txtCreatedOn"
                  name="txtCreatedOn"
                  // maxDate={new Date()}
                  showMonthDropdown
                  className="doctrHsp-dateinput"
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  dropdownMode="scroll"
                  peekNextMonth
                  disabledKeyboardNavigation
                  selected={this.state.createdOnDate}
                  onSelect={this.handleChangeCreatedDate}
                  dateFormat="dd/MM/yyyy"
                  calendarClassName="react-date-picker"
                  autoComplete="off"
                  onKeyDown={() => this.disableDateField()}
                />
              </div>

              <div className="hspinfo-contentdiv">
                <label>Modified By</label>
                <input
                  type="text"
                  id="txtModifiedBy"
                  name="txtModifiedBy"
                  className="hspInfofieldinput"
                  placeholder="Modified By"
                  disabled
                />
              </div>

              <div className="hspinfo-contentdiv">
                <label>Modified On</label>
                <input
                  type="text"
                  id="txtModifiedOn"
                  name="txtModifiedOn"
                  className="hspInfofieldinput"
                  placeholder="Modified On"
                  disabled
                />
              </div>

              <div className="hspinfo-contentdiv">
                <label>Sort Order</label>
                <input
                  type="text"
                  id="txtSortOrder"
                  name="txtSortOrder"
                  className="hspInfofieldinput"
                  placeholder="Sort Order"
                  onKeyUp={this.handleChange}
                  onChange={this.handleChange}
                />
              </div>

              <div className="hspinfo-contentdiv">
                <label>Advertisement ID</label>
                <input
                  type="text"
                  id="txtAdvertisementId"
                  name="txtAdvertisementId"
                  className="hspInfofieldinput"
                  placeholder="Advertisement ID"
                  onKeyUp={this.handleChange}
                  onChange={this.handleChange}
                />
              </div>

              <div className="superadminDivBoxcover">
                <div className="hspinfo-contentdiv">
                  <label className="pt-0">Rating</label>
                  <input
                    type="text"
                    id="txtRating"
                    name="txtRating"
                    className="hspInfofieldinput"
                    placeholder="Rating"
                    onKeyUp={this.handleChange}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
                <label>Ask Question</label>
                <select
                  name="cmbAskQuestion"
                  id="cmbAskQuestion"
                  className="hspInfofieldinput hspInfofieldinputWidth"
                  onChange={() => this.enableAskQuestion()}
                >
                  <option value="false">No</option>
                  <option value="true">Yes</option>
                </select>
              </div>

              <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
                <label>Is Assisted</label>
                <select
                  name="cmbIsAssisted"
                  id="cmbIsAssisted"
                  className="hspInfofieldinput hspInfofieldinputWidth"
                >
                  <option value="false">No</option>
                  <option value="true">Yes</option>
                </select>
              </div>

              <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
                <label>AQ Num Follow up</label>
                <input
                  type="text"
                  id="cmbAQNumFollowUp"
                  name="cmbAQNumFollowUp"
                  className="hspInfofieldinput"
                  placeholder="AQ Num Follow up"
                  onKeyUp={this.handleChange}
                  onChange={this.handleChange}
                  disabled
                />
              </div>

              <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
                <label>Phone Consulting</label>
                <select
                  name="cmbPhoneConsulting"
                  id="cmbPhoneConsulting"
                  className="hspInfofieldinput hspInfofieldinputWidth"
                  onChange={() => this.phoneConsultingEnable()}
                >
                  <option value="false">No</option>
                  <option value="true">Yes</option>
                </select>
              </div>

              <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
                <label>AQ Price</label>
                <input
                  type="text"
                  id="cmbAQPrice"
                  name="cmbAQPrice"
                  className="hspInfofieldinput"
                  placeholder="AQ Price"
                  onKeyUp={this.handleChange}
                  onChange={this.handleChange}
                  disabled
                />
              </div>

              <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
                <label>Phone Consulting Price</label>
                <input
                  type="text"
                  id="txtPhoneConsultingPrice"
                  name="txtPhoneConsultingPrice"
                  className="hspInfofieldinput"
                  placeholder="Phone Consulting Price"
                  onKeyUp={this.handleChange}
                  onChange={this.handleChange}
                  disabled
                />
              </div>

              <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
                <label>Is Free AQ</label>
                <select
                  name="cmbIsFreeAQ"
                  id="cmbIsFreeAQ"
                  className="hspInfofieldinput hspInfofieldinputWidth"
                >
                  <option value="false">No</option>
                  <option value="true">Yes</option>
                </select>
              </div>

              <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
                <label>In Person Consultation Price</label>
                <input
                  type="text"
                  id="txtInPersonConsultationPrice"
                  name="txtInPersonConsultationPrice"
                  className="hspInfofieldinput"
                  placeholder="In Person Consultation Price"
                  onKeyUp={this.handleChange}
                  onChange={this.handleChange}
                />
              </div>

              <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
                <label>Free AQ Available Count</label>
                <input
                  type="text"
                  id="txtFreeAQAvailableCount"
                  name="txtFreeAQAvailableCount"
                  className="hspInfofieldinput"
                  placeholder="Free AQ Available Count"
                  onKeyUp={this.handleChange}
                  onChange={this.handleChange}
                />
              </div>

              <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
                <label>Is Account Locked</label>
                <select
                  name="cmbIsAccountLocked"
                  id="cmbIsAccountLocked"
                  className="hspInfofieldinput hspInfofieldinputWidth"
                >
                  <option value="false">No</option>
                  <option value="true">Yes</option>
                </select>
              </div>
              <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
                <label>InPerson Appoinment</label>
                <select
                  name="cmbInPersonAppoinment"
                  id="cmbInPersonAppoinment"
                  className="hspInfofieldinput hspInfofieldinputWidth"
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}></Suspense>
      </div>
    );
  }
}
