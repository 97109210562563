import React, { Component, Suspense } from "react";
import user from "../image/theme/user.png";
import ReactTooltip from 'react-tooltip';
import {
  apiCalling,
  patientAPI,
  validateEmail,
  phonenumberValidation,
} from "../apiService";
import moment from "moment";
import $ from "jquery";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class JoinAsMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: window.sessionStorage.getItem("Firstname"),
      lastName: window.sessionStorage.getItem("Lastname"),
      mobileNo:window.sessionStorage.getItem("mobileNumber"),
      emailId: window.sessionStorage.getItem("emailId"),
       PolicyNumber:  window.sessionStorage.getItem("PolicyNo"),
        checkboxflag:false
    };
    this.updateProfile = this.updateProfile.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  getData() {
    const userAPI = {
      method: "POST",
      body: JSON.stringify({
        functionName: "userDetails",
        siteId: "",
        userId: window.sessionStorage.getItem("userId"),
      }),
    };
    $(".loader").show();
    apiCalling(userAPI).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        this.setState({ firstName: data.userData[0].firstName});
        this.setState({ lastName: data.userData[0].lastName });
        this.setState({ mobileNo: data.userData[0].phoneNumber });
        this.setState({ emailId: data.userData[0].emailId });
        this.setState({ PolicyNumber: data.userData[0].policyNo });
      }
    });
  }
  componentDidMount() {
    this.getData()
  }
  handleInputChange = (event) => {
    const { value, name } = event.target;
    if (name=="mobileNo"){ $("#mobilespan").attr("hidden",true);}
    else  if(name=="emailId"){$("#emailspan").attr("hidden",true);}
    this.setState({
      [name]: value,
    });
  };
  checkbox = (e) => {
    if(e.target.checked){
      this.setState({checkboxflag : true})
    }
    else{
      this.setState({checkboxflag : false})
     // this.state.flag === false&& this.state.flag1 === false
    }
  }
  updateProfile = (e) => {
    let emailId = (this.state.emailId).replace(/\s/g, "");;
    let mobileNo = (this.state.mobileNo).replace(/\s/g, "");;
     if (!phonenumberValidation(mobileNo)) {
      $("#mobilespan").attr("hidden",false);
      return false
    }
   else if (!validateEmail(emailId)) {
      $("#emailspan").attr("hidden",false);
      return false
    }
    else if(this.state.checkboxflag===false){
      alert("Please Agree Terms and Conditions.")
      return false
    }
     else {
      const saveData = {
        method: "POST",
        body: JSON.stringify( {
          "functionName": "memberRegistration",
          "zip": "",
          "lastName": this.state.lastName,
          "address": "",
          "gender": "",
          "city": "",
          "siteId": "1",
          "corporateCode": "",
          "policyEndDate": "",
          "employeeId": "",
          "policyNo": this.state.PolicyNumber,
          "dateOfBirth": "",
          "emailID": this.state.emailId,
          "otp": "",
          "firstName":this.state.firstName,
          "phoneNumber":this.state.mobileNo,
          "zone": "",
          "grade": "",
          "relationShipId":"",
          "policyType": "",
          "uHID": "",
          "state": ""
        }),
      };
      // debugger;
      // console.log("saveDatasaveDatasaveData",saveData)
      
      apiCalling(saveData).then((data) => {
        //$(".loader").hide();
        if (data.success == "1") {
          $("#messageMemberEdit").css("color", "green");
          alert("Registered Successfully");
          window.sessionStorage.setItem("userId", data.userData.memberId);
          window.sessionStorage.setItem("emailId", data.userData.emailID);
          window.sessionStorage.setItem("userName", data.userData.firstName);
          window.sessionStorage.setItem(
            "logedSiteId",
            window.sessionStorage.getItem("siteId")
          );
          window.sessionStorage.setItem("isLogin", "true");
          $("#showMyName").text(data.userData.firstName);
          window.sessionStorage.setItem(
            "mobileNumber",
            data.userData.phoneNumber
          );
          window.sessionStorage.setItem("role", 'ROLE_USER');
               $(".patientMenuTop").show();
          window.location.href = "/";
        }
      });
    }
      };
  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div class="loader"></div>}>
          <section>
             <Header /> 
          </section>
        </Suspense>
        <div className="contentCoverWrapper">
            <div class="container">
                <div className="col-lg-12 col-md-12 joinasmember-bg">
                        <div className="joinasmember-outer">
                            <h1 className="joinasmember-head" >Join As Member/Patient</h1>
                            <div className="col-lg-6 col-md-8 joinasmember-form">
                            {/* {this.state.flag1 == false && ( */}
                            <span hidden className="form-errormsg" id="mobilespan">Please Enter Valid Mobile Number</span>
                            {/* )} */}
                            {/* {this.state.flag == false && ( */}
                            <span hidden className="form-errormsg"id="emailspan">Please Enter Valid Email Id</span>
                            {/* )} */}
                                <ul>
                                    <li>
                                        <input type="text" name="PolicyNumber" placeholder="Policy Number" readOnly title="Policy Number "   value= {this.state.PolicyNumber === "null" ? "" : this.state.PolicyNumber} className="joinasmember-input joinasmember-input-policy"/>
                                    </li>
                                    <li>
                                        <input type="text" name="firstName" title="First Name" readOnly placeholder="First Name"   value={this.state.firstName} className="joinasmember-input user-first joinasmember-input-name"/>
                                        <input type="text"  name="lastName" title="Last Name" onChange={this.handleInputChange} value= { this.state.lastName} className="joinasmember-input user-last "/>
                                    </li>
                                    <li>
                                        <input type="text" name="mobileNo" title="Mobile Number" id="mobileNo"autoFocus placeholder="Mobile Number*" onChange={this.handleInputChange} value={this.state.mobileNo} className="joinasmember-input joinasmember-input-mob"/>
                                    </li>
                                    <li>
                                        <input type="text" name="emailId" title="Email Id" id="emailId" placeholder="Email Address*" onChange={this.handleInputChange} value={this.state.emailId} className="joinasmember-input joinasmember-input-email"/>
                                    </li>
                                    <p className="terms-div">
                                        <input type="checkbox" onChange={(e)=>this.checkbox(e)} />
                                        <span className="terms-text">I agree with <span><a href="">Terms and Conditions</a></span></span></p>
                                    <li><input type="button" onClick={this.updateProfile}  value="Register" className="register-btn"/></li>
                                </ul>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        <Suspense fallback={<div></div>}>
           <section>
             <Footer />  
          </section>
        </Suspense>
      </div>
    );
  }
}
