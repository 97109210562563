import React, { Component, Suspense } from "react";
import $ from "jquery";
import { apiCalling } from "../apiService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import rightarrow from "../image/icons/ht-arrow-right.png";
import htbaby from "../image/icons/ht-baby.png";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class HealthTrackbabymilestns extends Component {
  constructor(props) {
    super(props);

    this.state = {

      babyTxtName: '',
      dateOfBirth: ''

    }
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.disableDateField = this.disableDateField.bind(this);
  };

  handleChange(event) {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    })
  }

  handleChangeDate = (date) => {
    this.setState({
      dateOfBirth: date,
    });
  };

  disableDateField() {
    $("#txtDateOfBirth").attr('readonly', 'readonly')
    this.setState({
      dateOfBirth: null
    })
  }



  checkHandleDetails() {
    let babyName = $("#babyTxtName").val();;
    let babyDob = $("#txtDateOfBirth").val()

    if ($("#babyTxtName").val() === "") {
      alert("Please Enter Name of the Baby");
      $("#babyTxtName").focus();
      return false;
    }
    if ($("#txtDateOfBirth").val() === "") {
      alert("Please Select Date of Birth");
      $("#txtDateOfBirth").focus();
      return false;
    }
    const detailsCheck = {
      method: 'POST',
      body: JSON.stringify({
        functionName: "getHealthSchedule",
        healthTrackerTypeId: "2",
        userId: "", //window.sessionStorage.getItem("userId"),
        dateOfBirth:babyDob
      })
    }
    $(".loader").show();
    apiCalling(detailsCheck).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        window.location.href = "/health-trackers-babymilestone-download-pdf";
        window.sessionStorage.setItem('babyName', babyName);
        window.sessionStorage.setItem('dob', babyDob);
      }
      if (data.success === "0") {
        alert("No Data Found")
      }
    })
  }
  render() {
    return (
      <div class="purpleWrap ph-selectDisable ph-mainWrap">
        <Suspense fallback={<div ></div>}>
          <section>
            <div className='loader'></div>
            <Header />
            <div class="container container-auto">
              <div class="main-wrapper1 ">

              <h1 class="  text-center telehealthfeaturehead"><span>Baby Milestones</span> </h1>
                {/* <h4 class="mainHead text-center ph_htinnerhead"><span>Baby Milestones</span> </h4> */}
                {/* <div class="col-sm-12 form-head text-center  ph_htinnerhead">Baby Milestones</div> */}
                <div className="row">
                  <div className="col-lg-6">
                    <div className="health-track-card">
                      <div className="health-trck-img">
                        <img src={htbaby} />
                      </div>
                      <p>Baby Milestones</p>
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    <div className="health-track-card-right">

                      <div class="form-section mt-2">
                        <div class="row">
                          <div class="col-lg-12 row pl-3 d-center">
                            <label class="col-5 label-text label-text-cln text-right">
                              Name of the Baby
                            </label>
                            <label class="col-5 text-uppercase">
                              <input type="text" class="input-design mb-0" id="babyTxtName" name="babyTxtName" value={this.state.babyTxtName} onChange={this.handleChange} />
                            </label>
                          </div>
                        </div>

                      </div>
                      <div class="form-section mt-2">
                        <div class="row">
                          <div class="col-lg-12 row pl-3 d-end-center">
                            <label class="col-5 label-text label-text-cln text-right">
                              Date of Birth
                            </label>

                            <div class="col-sm-5 col-5 ph-calendar">

                              <DatePicker
                                placeholderText="Date of Birth"
                                id="txtDateOfBirth" name="txtDateOfBirth"
                                maxDate={new Date()}
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={100}
                                dropdownMode="scroll"
                                peekNextMonth
                                disabledKeyboardNavigation
                                selected={this.state.dateOfBirth}
                                onSelect={this.handleChangeDate}
                                dateFormat="dd/MM/yyyy"
                                calendarClassName="react-date-picker"
                                autoComplete="off"
                                tabIndex="8"
                                onKeyDown={() => this.disableDateField()}
                              />
                            </div>
                            <label className="col-2">
                              <button className="ht-button" onClick={() => this.checkHandleDetails()}>
                                <img src={rightarrow} />

                              </button>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
