import React, { Component, Suspense } from "react";
import Closebtn from "../../image/icons/close-sm.png";
import AddImg from "../../image/icons/addbtn.png";
import $ from "jquery";
import { EditorState, convertToRaw, ContentState, convertFromHTML, } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
const folderName = window.sessionStorage.getItem("homeName");
// const Header = React.lazy(() => import("../" + folderName + "/header"));
// const Footer = React.lazy(() => import("../" + folderName + "/footer"));


export default class SearchOffceService extends Component {
  constructor(props) {
    super(props);

    this.state = {

      editorState1: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(this.props.doctorData.length > 0 ? this.props.doctorData[0].officeDetails:"")
        )
      ),
      editorState2: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(this.props.doctorData.length > 0 ? this.props.doctorData[0].serviceOffered:"")
        )
      ),
      content1: this.props.doctorData.length > 0 ? this.props.doctorData[0].officeDetails:"",
      content2: this.props.doctorData.length > 0 ? this.props.doctorData[0].serviceOffered:""
    }
  }
  componentWillReceiveProps(nextProps) {   

    this.setState({ 

      editorState1: EditorState.createWithContent(

        ContentState.createFromBlockArray(

          convertFromHTML(nextProps.statement)

        )
      ),     
      content1: nextProps.statement

    });

  }
  componentDidMount() {
    if (this.props.doctorData.length > 0) {
      $("#txtAreaSearchKeyword").val(this.props.doctorData[0].searchKeywords)

    }
  }

  onContentChange1 = (editorState1) => {
    let content1 = draftToHtml(
      convertToRaw(this.state.editorState1.getCurrentContent())
    );
    this.setState({ content1, editorState1 });
  };


  onContentChange2 = (editorState2) => {
    let content2 = draftToHtml(
      convertToRaw(this.state.editorState2.getCurrentContent())
    );
    this.setState({ content2, editorState2 });
  };

  searchOfficeServices(){
    var a={
      content1:this.state.content1,
      content2:this.state.content2
    }
    return a;
  }
  render() {
    const { editorState1, editorState2 } = this.state;
    return (
      <div className="bg-clr-wthHead mb-4 search-dctrdiv-clear">
        <Suspense fallback={<div></div>}>
          <section>
            <div className="consultngTime-wrapper">
             
              <div className="hsplistTextareaWrapper pb-4">
                <div className="mt-4">
                  <label className="label-hspservice">Search Keyword</label>
                  <div>
                    <textarea name="txtAreaSearchKeyword" id="txtAreaSearchKeyword" cols="30" rows="10" placeholder="Search Keyword" className="textarea-hspservice"></textarea>
                  </div>
                </div>

                <div className="mt-4">
                  <label className="label-hspservice">Office Details</label>
                  <Editor
                    editorState={this.state.editorState1}
                    initialEditorState={editorState1}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="redactor-wrapper"
                    editorClassName="editorClassName"
                    onEditorStateChange={this.onContentChange1}
                    handlePastedText={() => false}
                  />
                </div>

                <div className="mt-4">
                  <label className="label-hspservice">Service Offered</label>
                  <Editor
                    editorState={this.state.editorState2}
                    initialEditorState={editorState2}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="redactor-wrapper"
                    editorClassName="editorClassName"
                    onEditorStateChange={this.onContentChange2}
                    handlePastedText={() => false}
                  />
                </div>

              </div>


            </div>

          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
        </Suspense>
      </div>
    );
  }
}
