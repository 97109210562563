import React, { Component, Suspense } from "react";
import Header from "../../Reliance/header";
import Footer from "../../Reliance/footer";

export default class Procedurelist extends Component {
  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
            <div className="procedureListWrapperHsp">
              <div className="container mb-5">
                <div>
                  <ul className="breadcrum-adminMangmnt">
                    <li>
                      <a href="/admin/dashboardAdmin">Dashboard</a>
                    </li>
                    <li>
                      <a href="/adminManagement/hospitalManagementList/hospitalManagementList">
                        Hospital Management
                      </a>
                    </li>
                    <li>
                      <a href="" className="current">
                        Procedure Listing
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="head-hospMangmntList pt-4">
                  Procedure Listing
                </div>

                <div className="HosptlMangemntTable">
                  <div className="AddNewHspbtnDiv pt-2 pb-2">
                    <a
                      href="/adminManagement/hospitalManagementList/addProcedure"
                      className="addnewhspbtn"
                    >
                      {" "}
                      Add New Procedure
                    </a>
                  </div>
                  <div className="tableHeader-HspMangemnt">
                    <div className="selectField-HspMangmnt">
                      <select
                        className="slecthsp slecthspMob"
                        id="selectCategory"
                        name="selectCategory"
                      >
                        <option value="0">Search In</option>
                        <option value="1">ID</option>
                        <option value="2">Hospital Name</option>
                        <option value="3">Phone</option>
                        <option value="4">City</option>
                        <option value="5">Category</option>
                      </select>
                    </div>

                    <div className="selectField-HspMangmnt">
                      <input
                        type="text"
                        className="hspmngInput hspmngInputmob"
                        name="hospitalTxt"
                        id="hospitalTxt"
                      />
                    </div>

                    <div className="selectField-HspMangmnt">
                      <select
                        className="slecthsp slecthspMob"
                        id="selectCategory"
                        name="selectCategory"
                      >
                        <option value="0">Status</option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                      </select>
                    </div>
                    <div className="selectField-HspMangmnt">
                      <button className="searchbtn-hspMng">Search</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
