export function awsUrl() {
  const apiUrl =
    // "https://a4c2uh4pka.execute-api.ap-south-1.amazonaws.com/reliancetest/purplehealth";
  "https://a4c2uh4pka.execute-api.ap-south-1.amazonaws.com/relianceProd_Token/purplehealth";
  return apiUrl;
}

export function awsUrlcommon() {
  const apiUrl =
    // "https://a4c2uh4pka.execute-api.ap-south-1.amazonaws.com/reliancetest/purplehealthcommon";
  "https://a4c2uh4pka.execute-api.ap-south-1.amazonaws.com/relianceProd_Token/purplehealthcommon";
  return apiUrl;
}

export function apiUrlReliance() {
  const apiUrlReliance =
    // "https://a4c2uh4pka.execute-api.ap-south-1.amazonaws.com/reliancetest/purplehealth";
  "https://a4c2uh4pka.execute-api.ap-south-1.amazonaws.com/relianceProd_Token/purplehealth";
  return apiUrlReliance;
}

export function apiUrlRelianceElasticSearch() {
  const apiUrlRelianceElasticSearch =
    // "https://ki2slqvnhb.execute-api.ap-south-1.amazonaws.com/test/elasticsearch";
  "https://ki2slqvnhb.execute-api.ap-south-1.amazonaws.com/prod/elasticsearch";
  return apiUrlRelianceElasticSearch;
}

export function S3ImagePath() {
  // const S3Link ="https://purplehealthfiles.s3.amazonaws.com/test";
  const S3Link = "https://purplehealthfiles.s3.amazonaws.com/production/theme";
  return S3Link;
}
export function elasticLink() {
  // const elsticLink = "ph-test-rel-search";
   const elsticLink = "ph-prod-rel-search";

  return elsticLink;
}

export function jwtSecret() {
  const jwtSecret = "Xilli1123581321$";
  return jwtSecret;
}
