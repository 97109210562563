import React, { Component, Suspense } from "react";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { apiCalling } from "../apiService";
import $ from "jquery";
import Dashboardleftmenu from "./dashboardleftmenu";
import edit from "../image/icons/edit.png";
import close from "../image/icons/close-circle.png";
import tick from "../image/icons/tick-circle.png";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
let groupHospitalAdminId = ''
export default class AdminHeaderLink extends Component {
  constructor(props) {
      super(props);
      this.state = {
          cmbActive:'',
          headerdata:[],
          headerId:''
      };
      this.headerSave = this.headerSave.bind(this)
  }
  componentDidMount() {
      const groupHospitalId = window.sessionStorage.getItem("userId");
      groupHospitalAdminId = groupHospitalId
      this.getHeaderLink()
  }
  toggleClass = () => {
      $('.group_listLink').toggleClass('group_listLink_Active');
  }
  searchClick = () => {
    this.getHeaderLink()
  }
  getHeaderLink () {
    $(".loader").show();
    const apiJson = {
        method: "POST",
        body: JSON.stringify({
          "functionName": "headerFooterLinks",
          "subFunction":"headerListing",
          "cmbCategory":$("#headerType").val(),
          "cmbActive":$('#status').val() === "1" ? "true" : $('#status').val() === "0" ? "false" : "",
          "max":"100",
          "offset":"0", 
          "groupHospitalAdminId":groupHospitalAdminId,
          "txtInput": $('#headerSearch').val()
        }),
    };
    apiCalling(apiJson).then((data) => {
      // console.log(data,"datadatadatadata");
      // console.log(apiJson.body,"apiJsonapiJsonapiJson");
        if (data.success === "1") {
            $(".loader").hide();
            this.setState({
              headerdata:data.headerLinkData
            })
        } else {
          this.setState({
            headerdata:[]
          })
            $(".loader").hide();
            alert(data.errorMessage)
        }
    });
  }
  headerSave = (name) => {
      if($('#name').val() == "") {
        alert("Link Name Required")
      } else {
          $(".loader").show();
          const apiJson = {
              method: "POST",
              body: JSON.stringify({
                "functionName": "headerFooterLinks",
                "subFunction":"headerSave",
                "headerId":name === "Update" ? this.state.headerId : '',
                "headerName":$('#name').val(),
                "txtDescrip":$('#content').val(),
                "txtUrl":$('#url').val(),
                "groupHospitalAdminId":groupHospitalAdminId,
                "siteId": "1"
              }),
          };
          apiCalling(apiJson).then((data) => {
              if (data.success === "1") {
                  $(".loader").hide();
                  $('#name').val('');
                  $('#content').val('');
                  $('#url').val('')
                  $('.group_listLink').toggleClass('group_listLink_Active');
                  this.setState({ headerId:''})
                  this.getHeaderLink()
              } else {
                  $(".loader").hide();
                  alert(data.errorMessage)
              }
          });
      }
  }
  headerClose = () => {
    $('.group_listLink').toggleClass('group_listLink_Active');
    this.setState({ headerId:''})
    $('#name').val('');
    $('#content').val('');
    $('#url').val('')
  }
  linkEdit = (item) => {
    $('.group_listLink').toggleClass('group_listLink_Active');
    this.setState({ headerId:item.headerId})
    const apiJson = {
        method: "POST",
        body: JSON.stringify({
          "functionName": "headerFooterLinks",
          "subFunction":"headerLinkGet",
          "headerLinkId":item.headerId
        }),
    };
    apiCalling(apiJson).then((data) => {
        $(".loader").hide();
        if (data.success === "1") {
            $('#name').val(data.editData.linkName);
            $('#content').val(data.editData.contents);
            $('#url').val(data.editData.url)
        } else {
            alert(data.errorMessage)
        }
    });
  }
  linkEnableDisable = (item, name) => {
    var answer = name === "Enable" ? window.confirm("Do you want to activate the header link?") : window.confirm("Do you want to deactivate the header link?");
    if (answer) {
      const apiJson = {
          method: "POST",
          body: JSON.stringify({
            "functionName": "headerFooterLinks",
            "subFunction":"headerActiveDeactive",
            "isActive":name === "Enable" ? true: false,
            "headerLinkId":item.headerId
          }),
      };
      apiCalling(apiJson).then((data) => {
          $(".loader").hide();
          if (data.success === "1") {
              this.getHeaderLink()
          } else {
              alert(data.errorMessage)
          }
      });
    }
  }
  headerType = () => {
    if ( $('#headerType').val()==""){
      document.getElementById("headerSearch").disabled = true
      document.getElementById("headerSearch").value = "";
    }
    else{ 
      document.getElementById("headerSearch").disabled = false; 
      document.getElementById("headerSearch").focus();
    }
  }
  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container">
          <div class="main-wrapper1 hspmain-wrapper-promo" id="row-col-width-leftmenu">
            <div class="row">
              <div class="col-lg-1   d-sm-none d-lg-block d-md-none d-xl-block d-none" >
                <Dashboardleftmenu></Dashboardleftmenu>
              </div>
              <div class="col-lg-11">
              <div className="dashboard-right dashbrd-rght-bottom-padng-hsp">
                  <div class="tabmain_list">
                  <h6 className="mb-0">HEADER LINKS</h6>
                  </div>
                  <div className="group_listLink">

                      <div className="toggle_header d-flex align-items-center" onClick={() => this.toggleClass()}>
                        <span> Add Header Link</span>
                        <span className=" ml-auto pr-2 faPlus"><i class="fa fa-plus" title="Add"></i></span>
                        <span className=" ml-auto pr-2 faMinus"><i class="fa fa-minus"></i></span>
                      </div>
                      <div class="toggle_row-sec">
                        <div class="row toggle_row">
                            <div className="col-md-12">
                                <div className="ml-3 mt-2">
                                <p>Add Header Link</p>
                                </div>
                            </div>
                                  <div className="col-md-12 mb-2">
                                  <div className="col-md-6">
                                  <label class="col-sm-12 label-text  px-0">Name<span className="star-red">*</span></label>
                                    <input type="text" className="custome_input mb-0" id="name"/>
                                  </div>
                                  </div>
                                  <div className="col-md-12 mb-2">
                                  <div className="col-md-6">
                                  <label class="col-sm-12 label-text  px-0">URL</label>
                                    <input type="text" className="custome_input mb-0" id="url"/>
                                  </div>
                                  </div>
                                  <div className="col-md-6  mb-2">
                                  <div className="col-md-12 text-center">
                                  <label class="col-sm-12 label-text  px-0py-0 py-sm-3 m-0">OR</label>
                                  </div>
                                  </div>
                                  <div className="col-md-12  mb-2">
                                  <div className="col-md-6">
                                  <label class="col-sm-12 label-text  px-0">Content</label>
                                    <textarea className="custome_input mb-0" id="content" rows="4" resize="none"/>
                                  </div>
                                  </div>
                                  <div className="col-md-12 my-4 mx-3">
                                    {this.state.headerId == '' ?
                                      <input type="submit" className="border_btn" value="Save" onClick={() => this.headerSave("New")}/>
                                    :
                                      <input type="submit" className="border_btn" value="Update" onClick={() => this.headerSave("Update")}/>
                                    }
                                  <input type="button" className="border_btn btn_gray" value="Cancel" onClick={() => this.headerClose()}/>
                                  </div>
                        </div>
                      </div>
                    <div className="col-sm-12">
              <div className="row my-4">
              {/* <div className="col-md-3">
                  <input type="text" className="custome_input mb-0" value="Name" disabled={true}/>
             </div> */}
              <div className="col-md-3">
                    <select className="custome_input mb-0" onChange={() => this.headerType()} id="headerType">
                    <option value="">Search</option>
                    <option value="1">Name</option>
                </select>
             </div>
                <div className="col-md-3">
                  <input type="text" className="custome_input mb-0"  disabled id="headerSearch"/>
                </div>
                <div className="col-md-3">
                    <select className="custome_input mb-0" id="status">
                    <option value="">Status</option>
                    <option value="1">Active</option>
                    <option value="0">InActive</option>
                </select>
             </div>
             <div className="col-md-3">
             <input type="submit" className="formBtnBg w-75 mb-0" value="Search" onClick={() => this.searchClick()}/>
             </div>
              </div>
          </div>
          <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>URL</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        {this.state.headerdata.length > 0 ?
                        <tbody>
                          {this.state.headerdata.map((item) => (
                            <tr>
                              <td>{item.linkName}</td>
                              <td>{item.urls}</td>
                              <td>{item.IsActive == true ? "Active" : "In active"}</td>
                              <td>
                                <span style={{marginRight:"10px", cursor:'pointer'}} onClick={() => this.linkEdit(item)}><img src={edit} title={"Edit"} style={{width:"20px"}}/></span>
                                {item.IsActive == false ?
                                  <span style={{cursor:'pointer'}} onClick={() => this.linkEnableDisable(item, "Enable")}><img src={close} title={"Inactive"} style={{width:"25px"}}/></span>
                                :
                                  <span style={{cursor:'pointer'}} onClick={() => this.linkEnableDisable(item, "Disable")}><img src={tick} title={"Active"} style={{width:"25px"}}/></span>
                                }
                              </td>
                            </tr>
                          ))}
                          </tbody>
                          :
                          <tbody>
                          <tr>
                            <td colSpan={4}>No data Found</td>
                          </tr>
                          </tbody>
                          }
                        </table>
                    </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
