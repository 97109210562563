import React, { Component, Suspense } from "react";

class doctor extends Component {
  state = {
    doctorId: this.props.doctorData.doctorId,
    doctorName: this.props.doctorData.doctorName,
    hopsitalName: this.props.doctorData.hopsitalName,
    specialityName: this.props.doctorData.specialityName,
    messagingCost: this.props.doctorData.messagingCost,
    dayOfExpiry: this.props.doctorData.dayOfExpiry,
    hospitalId: this.props.doctorData.hospitalId,
    specialityId: this.props.doctorData.specialityId,
    doctorImage: this.props.doctorData.doctorImage,
    chatStatus: this.props.doctorData.chatStatus,
    isAddedPanel: this.props.doctorData.isAddedPanel,
  };

  changeHandler = (e) => {
    const amount = e.target.value;
    if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
      this.setState({ [e.target.id]: e.target.value });
    }
  };

  handleFocus = (event) => event.target.select();

  

  render() {
    return (
      <div
        className="col-md-4"
        style={{
          marginTop: "15px",
          marginBottom: "15px",
        }}
      >
        <div
          style={{
            background: "#f2f2f2",
            padding: 15,
            border: "1px solid gainsboro",
          }}
        >
          <Suspense fallback={<div></div>}>
            <section className="container">
              <div>
                <div 
                  style={{
                    textAlign: "center",
                    opacity: 1,
                    width: "80px",
                    maxWidth: "100%",
                    float: "left",
                    height: "100px",
                    margin:"5px"
                  }}
                >
                  <img
                    src={this.state.doctorImage}
                    style={{ maxHeight: "100%", maxWidth: "100%" }}
                  />
                </div>
                <div
                  style={{
                    width: "75%",
                    height: "auto",
                    minHeight: "80px",
                    width: "68%",
                    float: "left",
                    fontFamily: "montserratregular,sans-serif",
                    textAlign: "justify",
                    background: "#f2f2f2",
                    padding: "5px",
                    transition: "top .8s,opacity .8s ease-in-out",
                    webkitTransition: "top .8s,opacity .8s ease-in-out",
                  }}
                >
                  <div
                    style={{
                      color: "#3498db",
                      fontFamily: "montserratregular,sans-serif",
                      fontSize: "1em",
                      opacity: 1,
                      cursor: "pointer",
                      width: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    title={this.state.doctorName}
                  >
                    <a className="dctr-msg-name">{this.state.doctorName}</a>
                  </div>
                  <div
                    style={{
                      color: "#393939",
                      fontFamily: "source_sans_proregular,sans-serif",
                      fontSize: "1em",
                      marginBottom: 0,
                      marginTop: "2px",
                      cursor: "pointer",
                      width: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    title={this.state.specialityName}
                  >
                    {this.state.specialityName}
                  </div>
                  <div
                    style={{
                      color: "#393939",
                      fontFamily: "source_sans_proregular,sans-serif",
                      fontSize: "1em",
                      marginBottom: 0,
                      marginTop: "2px",
                      cursor: "pointer",
                      width: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    title={this.state.hopsitalName}
                  >
                    {this.state.hopsitalName}
                  </div>
                </div>

                <div class="clearfix"></div>
                <div class="row">
                  <div className="dctr-msg-field">
                    <span>Messaging Cost</span>
                  </div>
                  <div className="dctr-msg-inputfield">
                    <i
                      class=" fa fa-inr"
                      style={{ position: "absolute", top: "5px", left: "20px" }}
                    ></i>
                    <input
                      type="text"
                      id="messagingCost"
                      placeholder=""
                      name="messagingCost"
                      value={this.state.messagingCost}
                      disabled={this.props.doctorData.isAddedPanel ? "disabled" : null}
                      min="0.00"
                      step="0.001"
                      max="1.00"
                      presicion={2}
                      style={{
                        width: "100px",
                        textAlign: "right",
                        background: "white",
                        border: "none",
                        paddingLeft: "15px"
                      }}
                      onInput={this.changeHandler}
                      onFocus={this.handleFocus}
                    />
                  </div>
                </div>
                <div class="clearfix mrgTop"></div>

                <div
                  class="row"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <div className="dctr-msg-field">
                    <span>Expires In Days</span>
                  </div>
                  <div className="dctr-msg-inputfield">
                    <input
                      type="text"
                      id="dayOfExpiry"
                      placeholder=""
                      name="daysOfExpiry"
                      value={this.state.dayOfExpiry}
                      disabled={this.props.doctorData.isAddedPanel ? "disabled" : null}
                      style={{
                        width: "100px",
                        textAlign: "right",
                        background: "white",
                        border: "none",
                      }}
                      onInput={this.changeHandler}
                      onFocus={this.handleFocus}
                    />
                  </div>
                </div>
                <div class="row d-flex justify-content-center">
                  <a
                    className="btn btn-messaging-dctr smssetng-svebtn"
                    style={{
                      // fontSize: "1em",
                      minWidth: "100%",
                      // padding: "1px",
                    }}
                    onClick={()=>this.props.addRemoveMessaging(this.state,this.props.doctorData.isAddedPanel)}
                  >
                    {this.props.doctorData.isAddedPanel
                      ? "Remove From Messaging"
                      : "Add to Messaging"}
                  </a>
                </div>
              </div>
            </section>
          </Suspense>
        </div>
      </div>
    );
  }
}

export default doctor;
