import React, { Component, Suspense } from "react";
// import InnerHTML from "dangerously-set-html-content";
import * as ReactDOM from "react-dom";
import { apiCalling } from "../apiService";
import $ from "jquery";
const folderName = window.sessionStorage.getItem("homeName");
const Home = React.lazy(() => import("../" + folderName + "/home"));
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class Common extends Component {
  constructor(props) {
    super(props);
    this.state = {
      html: "",
      urlName: "",
    };
  }
  componentDidUpdate() {}

  componentDidMount() {
    let name = this.props.match.params.pageName;
    const headerDetails = {
      method: "POST",
      body: JSON.stringify({
        functionName: "headerLinksDetails",
        siteId: window.sessionStorage.getItem("siteId"),
      }),
    };

    apiCalling(headerDetails).then((data) => {
      if (data.success == "1") {
        let headerLinkContent = data.headerLinkContent[0];
        data.headerLinkContent.map((d) => {
          if (d.url === name) {
            this.setState({ html: d.headerlinkContent });
            this.setState({ urlName: d.url });
          }
        });
      }
    });
  }

  render() {
    return (
      // <div className="purpleWrap home bg-clr-wthHead">
      <div>
        <Suspense fallback={<div></div>}>
          <section>
            <Header menuActive={this.props.match.params.pageName} />
          </section>
        </Suspense>
        <div id="commonLoader">
          <div dangerouslySetInnerHTML={{ __html: this.state.html }} />
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
