import React, { Component } from "react";
import { apiCalling } from "../apiService";
import "bootstrap/dist/css/bootstrap.css";
import "../css/slick.css";
import "../css/style.css";
import loaderImg from "../image/theme/loader.gif";
import { ClearBrowserCache } from 'react-clear-browser-cache';
import { Helmet } from "react-helmet";
export default class Root extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteId: '1',//this.props.match.params.siteId,
      siteUrl:'',//window.location.host,
      isLoader: false,
      urlRedirect:window.location.pathname,
      urlFlag:false,
    };
   
  }
  signOutPatientClick() {
    const logoutReq = {
      method: "POST",
      body: JSON.stringify({
        functionName: "logout",
        siteId: '1',
        userId: window.sessionStorage.getItem("userId"),
      }),
    };

    apiCalling(logoutReq).then((data) => {
      if (data.success === "1") {
        window.sessionStorage.setItem("userId", "");
        window.sessionStorage.setItem("emailId", "");
        window.sessionStorage.setItem("userName", "");
        window.sessionStorage.setItem("isLogin", "");
        window.sessionStorage.setItem("role", "");
        window.sessionStorage.setItem("logedSiteId", "");
        // const urlPath = window.location.origin.toString();
        // window.location.href = urlPath + "/";

        //alert(data.successMessage);
      } else {
        //alert("Logout Failed");
      }
    });
  } 
  componentDidMount() {
 
    localStorage.clear();
    let siteurl=""//this.state.siteUrl//""
    window.sessionStorage.setItem("pathname",window.location.pathname)
    if (!this.state.siteId) {
    // alert("if")
     siteurl=this.state.siteUrl
    } else {
      
      //alert("else")
      //alert(window.location.host);
      //alert(window.location.pathname)
    //this.setState({urlFlag:true})
    //alert("else")
   // window.sessionStorage.setItem("pathname",'null')
    }
     
   //  window.sessionStorage.setItem("mysession","xxx")
    window.sessionStorage.setItem("siteId", '1');
    if (
      this.state.siteId !== window.sessionStorage.getItem("logedSiteId") &&
      window.sessionStorage.getItem("logedSiteId") !== ""
    ) {
      this.signOutPatientClick();
    }
    const loginRequest = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getSiteDetails",
        siteId: this.state.siteId,
        siteUrl: siteurl,
      }),
    };
   // alert(loginRequest.body)
   //alert("1")
    apiCalling(loginRequest).then((data) => {
    //  alert("2")
      if (data.success === "1") {
//alert("3")
        var location = data.siteDetailsData[0].siteData[0].siteName;
        let is_theme_based = data.siteDetailsData[0].siteData[0].is_theme_based;
        let project_folder = data.siteDetailsData[0].siteData[0].project_folder;
        var folder = "Reliance";
        // if (is_theme_based === false && project_folder !== "") {
        //   folder = project_folder;
        // } else {
        //   folder = data.siteDetailsData[0].siteData[0].themeName;
        // }
        
        //folder = data.siteDetailsData[0].siteData[0].title;
        window.sessionStorage.setItem("siteId", data.siteDetailsData[0].siteData[0].id);
        window.sessionStorage.setItem(
          "themeId",
          data.siteDetailsData[0].siteData[0].themeId
        );
       
        window.sessionStorage.setItem("themeVersion", "0");
        window.sessionStorage.setItem("homeName", folder);
        window.sessionStorage.setItem("reload", "1");
        window.sessionStorage.setItem("theme_custom_color", data.siteDetailsData[0].siteData[0].theme_custom_color);
        window.sessionStorage.setItem("dashboard_html", data.siteDetailsData[0].siteData[0].dashboard_html);
        window.sessionStorage.setItem("contact_emailId", data.siteDetailsData[0].siteData[0].emailId);
        window.sessionStorage.setItem("contact_no", data.siteDetailsData[0].siteData[0].contact_no);
        window.sessionStorage.setItem("fav_icon",data.siteDetailsData[0].siteData[0].fav_icon);
        window.sessionStorage.setItem("seo_description",data.siteDetailsData[0].siteData[0].seo_description);
        window.sessionStorage.setItem("seo_keywords",data.siteDetailsData[0].siteData[0].seo_keywords);
        window.sessionStorage.setItem("seo_title",data.siteDetailsData[0].siteData[0].seo_title);
        window.sessionStorage.setItem("slug",data.siteDetailsData[0].siteData[0].slug);
        window.sessionStorage.setItem("googleId",data.siteDetailsData[0].siteData[0].google_analytics_script_id);
        window.sessionStorage.setItem("siteName", data.siteDetailsData[0].siteData[0].siteName);
        window.sessionStorage.setItem("book_appointment_terms", data.siteDetailsData[0].siteData[0].book_appointment_terms);
        window.sessionStorage.setItem("video_chat_terms", data.siteDetailsData[0].siteData[0].video_chat_terms);
        window.sessionStorage.setItem("hospital_working_time", data.siteDetailsData[0].siteData[0].hospital_working_time);
        window.sessionStorage.setItem("site_url", data.siteDetailsData[0].siteData[0].site_url);
        window.sessionStorage.setItem("title", data.siteDetailsData[0].siteData[0].title);
        window.sessionStorage.setItem("fbPixel",data.siteDetailsData[0].siteData[0].fbPixel);
        window.sessionStorage.setItem("hasHealthTracker",data.siteDetailsData[0].siteData[0].hasHealthTracker);
        window.sessionStorage.setItem(
          "siteLogo",
          data.siteDetailsData[0].siteData[0].logo_path
        );
        window.sessionStorage.setItem(
          "groupHospitalAdminId",
          data.siteDetailsData[0].groupHospitalAdminData[0].groupHospitalAdminId
        );
        window.sessionStorage.setItem(
          "headerData",
          JSON.stringify(data.siteDetailsData[0].headerData)
        );
       
          window.sessionStorage.setItem(
            "footerData",JSON.stringify(data.siteDetailsData[0].footerData));
      

        const hosDataVar = JSON.stringify(data.siteDetailsData[0].hospitalData);
        window.sessionStorage.setItem(
          "siteServices",
          JSON.stringify(data.siteDetailsData[0].services)
        );
        if (hosDataVar) {
          window.sessionStorage.setItem(
            "footerhospitalData",
            JSON.stringify(data.siteDetailsData[0].hospitalData)
          );

          window.sessionStorage.setItem(
            "footerHospitalName",
            data.siteDetailsData[0].hospitalData.hospitalName
          );
          window.sessionStorage.setItem(
            "footerHospitalcity",
            data.siteDetailsData[0].hospitalData.city
          );
          window.sessionStorage.setItem(
            "footerHospitaladdress",
            data.siteDetailsData[0].hospitalData.address
          );
          window.sessionStorage.setItem(
            "footerHospitalstateName",
            data.siteDetailsData[0].hospitalData.stateName
          );
          window.sessionStorage.setItem(
            "footerHospitalzipCode",
            data.siteDetailsData[0].hospitalData.zipCode
          );
         
          
        }
        else{
          window.sessionStorage.setItem(
            "footerhospitalData",
            JSON.stringify(data.siteDetailsData[0].hospitalData)
          );
        }
        if (data.siteDetailsData[0].themeSectionList) {
          for (
            let i = 0;
            i < data.siteDetailsData[0].themeSectionList.length;
            i++
          ) {
            window.sessionStorage.setItem(
              data.siteDetailsData[0].themeSectionList[i].themeSectionName,
              JSON.stringify(
                data.siteDetailsData[0].themeSectionList[i].themeSectionContent
              )
            );
          } 
        }       
        window.sessionStorage.setItem(
          "doctorDetails",
          JSON.stringify(data.siteDetailsData[0].doctorDetails)
        );

       // alert("root")
        window.location.href = "/";
       //window.location.href="/maintenance"
        
      }
    });
  }
  render() {
    return (
      <div>
         <Helmet>
          <title></title>
          <meta http-equiv='cache-control' content='no-cache'/>
          <meta http-equiv='expires' content='0'/>
          <meta http-equiv='pragma' content='no-cache'/>
        </Helmet>
        <ClearBrowserCache>{(contextValue) => null}</ClearBrowserCache>;
        {this.state.isLoader === false ? (
          <div
            style={{ padding: "30vh", textAlign: "center", display: "none" }}
          >
            <img src={loaderImg} alt="" />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
