import React, { Component, Suspense } from "react";
import $ from "jquery";
import { apiCalling } from "../apiService";
import ReactTooltip from 'react-tooltip';
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class PrescriptionList extends Component {
  render() {
    return (
      
      <div class="purpleWrap">
          <div className='loader'></div>
        <Suspense fallback={<div class="loader"></div>}>
          <section>
            <Header />
            <div class="contentCoverWrapper">
              <div class="container">
                  <div className="col-md-12 col-lg-8 col-xs-12 col-md-offset-2 col-lg-offset-2 media-wrap-margin">
                  <div className="media-wrap">
                  <h3 class="mediaMain-title-supprt">
					Email: support@rhealthassist.com Phone: +91 9544986088 Support Hours: Mon - Fri 10am to 6 pm
				  </h3>
                  </div>
                  

                  </div>
         
              </div>
            </div>

            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}


