import React, { Component, Suspense } from "react";
import $ from "jquery";
import { apiCalling } from "../apiService";
import ReactTooltip from 'react-tooltip';
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class PrescriptionList extends Component {
  render() {
    return (
      
      <div class="purpleWrap">
          <div className='loader'></div>
        <Suspense fallback={<div class="loader"></div>}>
          <section>
            <Header />
            <div class="contentCoverWrapper">
              <div class="container">
                  <div className="col-md-12 col-lg-10 col-xs-12 col-md-offset-2 col-lg-offset-2 media-wrap-margin">
                      <div className="media-wrap">
                        <h3 className="mediaMain-title">Terms of Use</h3>
                        <div className="media-outer-wrap">
                            <h4 className="mediaMain-title-text">** THIS PLATFORM DOES NOT HANDLE MEDICAL EMERGENCIES **</h4>
                            <p><b>Welcome to RHealthAssist.com</b></p>
                            <p>This website (‘site’) is maintained as a service to our customers. By using this site, you are agreeing to comply with and be bound by the following terms of use. Please review the following terms carefully. If you do not agree to these terms, you may not use this site.</p>
                            <p>As an internet-based service, RHealthAssist.com is accessible by customers (‘users’) worldwide; but it remains the RESPONSIBILITY OF USERS to verify the extent to which RHealthAssist.com information and services are permissible in their own jurisdiction (as local, state and national regulations vary widely). By using RHealthAssist.com in any form, you, the user are agreeing to comply with all applicable laws and to explicitly limit the liability of, indemnify and hold harmless RHealthAssist.com in any and all instances.</p>
                            <p><b>These "Terms of Use" govern your use of the website, both as a casual visitor and as a registered user.</b></p>
                            <p><b>1. Acceptance of Agreement</b></p>
                            <p>1.1 For the purposes of this agreement, the terms 'we', 'us', 'our', ‘RHealthAssist’, DoctorsCabin Health Technologies Private Limited, its successors, partners, affiliates, subsidiaries and assigns. The terms 'you', 'consumer', 'end-user' and ‘user’ refer to you as a user of this site. The terms ‘content provider/s’ and ‘service provider/s’ refer to any company, organization, corporation, association, individual, partnership or limited liability entity that supplies content or services to, or via, RHealthAssist.com and may collectively be referred to a ‘CSP’s. You agree to the terms and conditions outlined in this Terms of Use Agreement (the ‘Agreement’) with respect to our internet website (the ‘site’). This Agreement constitutes the entire and only agreement between us and you, and supersedes all prior or contemporaneous agreements, representations, warranties and understandings with respect to the site, the content, products or services provided by or through the site, and the subject matter of this Agreement. This Agreement may be amended at any time from time to time by us without specific notice to you. The latest Agreement will be posted on the site, and you should review this Agreement prior to using the site. By accessing the site, you agree to be bound by the terms of the latest Agreement posted. Please read this Agreement carefully and do not click or continue to use the system unless you agree with these terms. You and we are collectively referred to as the “parties.”</p>
                            <p><b>2. RHealthAssist Overview</b></p>
                            <p>2.1 RHealthAssist offers or plans to offer a variety of online products and services including, though not limited to, an internet/email/live-video-enabled “communication platform” designed to enable consumers to find and connect with content or service providers (CSP). CSPs reserve the right to deny services, at their sole discretion. While information on each CSP is contained in the RHealthAssist database, it remains the responsibility of users to satisfy themselves as to the ultimate credentials, qualifications and fitness of each CSP. Please note that:</p>

                            <ul>
                                <li>RHealthAssist does not provide medical service – RHealthAssist is a communication platform.</li>
                                <li>RHealthAssist does not replace a user’s primary care provider.</li>
                                <li>RHealthAssist is not a pre-paid health plan or insurance plan and does not eliminate need for such a plan.</li>
                                <li>RHealthAssist is not a drug fulfillment warehouse.</li>
                                <li>RHealthAssist does not at this time allow or facilitate the issuing of medical prescriptions.</li>
                                <li>RHealthAssist is not a substitute for in-person medical care and attention.</li>
                            </ul>
                            <p>2.2 RHealthAssist “VIDEO-CONNECT”s are for personal educational purposes only, they explicitly exclude professional treatment, medical diagnosis, or formal consultations. RHealthAssist is not a substitute for in-person medical care and attention. RHealthAssist is a service that helps users gain greater individual knowledge and participation in health & wellness and, to the extent feasible, encourages users to maximize opportunities for preventive care.</p>
                            <p>2.3 ALL INFORMATION YOU OBTAIN VIA THIS WEBSITE OR IN CONNECTION WITH ANY COMMUNICATIONS SUPPORTED BY RHealthAssist.COM, ITS EMPLOYEES, CONTRACTORS, PARTNERS, SPONSORS, ADVERTISERS, LICENSORS OR OTHERWISE, IS FOR INFORMATIONAL AND SCHEDULING PURPOSES ONLY; THIS INCLUDES, WITHOUT LIMITATION, REAL-TIME VIDEO OR EMAIL COMMUNICATIONS BETWEEN PROFESSIONALS UTILIZING THE WEBSITE AND CONSUMERS/USERS; THESE ARE EXPLICITLY AND EXCLUSIVELY FOR GENERAL INFORMATIONAL AND EDUCATIONAL PURPOSES ONLY, AND ARE IN NO WAY INTENDED TO CREATE A PHYSICIAN–PATIENT RELATIONSHIP AS DEFINED APPLICABLE LAWS.</p>
                            <p>2.4.1 Independence of CSPs: content and service providers, including medical specialists and related professionals utilizing or featured on the site, are subscribers and licensees to the site. They are neither employees, nor independent contractors of RHealthAssist. Any opinions, advice, or information expressed by CSP are those of the CSP alone. They do not reflect the opinions of RHealthAssist. RHealthAssist does not recommend or endorse any specific tests, physicians, products, procedures, opinions, or other information that may be mentioned on RHealthAssist or by a licensee of RHealthAssist.</p>
                            <p>2.5 RHealthAssist has no control over, and cannot guarantee the availability of any Provider at any particular time. We will not be liable for cancelled or otherwise unfulfilled appointments or any injury resulting therefrom, or for any other injury resulting from the use of the site or services whatsoever. You are strongly advised to perform your own investigation prior to selecting a health care professional by contacting the appropriate licensing authorities to verify listed credentials and education, and to further verify information about a particular doctor, dentist or other content or service by confirming with the CSP’s office, your current provider, the medical association relative to the CSP’s specialty and your state or national medical board (as applicable).</p>
                            <p>2.6 RHealthAssist IS NOT A SUBSTITUTE FOR PROFESSIONAL MEDICAL DIAGNOSIS OR TREATMENT. RELIANCE ON ANY INFORMATION PROVIDED VIA RHealthAssist OR ANY CSP UTILIZING RHealthAssist IS SOLELY AT THE USER’S OWN RISK. RHealthAssist ASSUMES NO LIABILITY AND, AS A USER, YOU ACKNOWLEDGE THIS POINT.</p>
                            <p>2.7 If you elect to enter information into any type of ‘form’ on the site (for examples a "Medical History Form") on behalf of yourself or a third-party from whom you have authorization to provide such information; you thereby authorize us to provide such information to the specified CSPs. You also acknowledge that RHealthAssist may use the data or information you provide without limitations.</p>
                            <p>2.8 RHealthAssist reserves the right to deny services, particularly if it suspects potential misuse.</p>
                            <p><b>3.Copyright, Trademarks and Service Marks</b></p>
                            <p>3.1 The domain name for this website, affiliated domain names, all page headers, custom graphics, and button icons are service marks, trademarks, logos, and/or trade dress of RHealthAssist.</p>
                            <p>3.2 The content, organization, graphics, logos, design, compilation, magnetic translation, digital conversion and other matters related to the site are protected under applicable domestic and international copyright, trademark and other proprietary (including but not limited to intellectual property) rights.</p>
                            <p>3.3 "RHealthAssist.com," and others marks are either trademarks or registered trademarks or service marks of RHealthAssist.</p>
                            <p>3.4 Other product, company names, and content displayed on the site may be the trademarks or copyrights of their respective owners. Individual documents on this server may have different copyright conditions, and that will be noted in those documents.</p>
                            <p>3.5 The copying, redistribution, use or publication by you of any such matters or any part of the site, except as allowed by this Agreement, is strictly prohibited. You do not acquire ownership rights to any content, document or other materials viewed through the site.</p>
                            <p>3.6 The posting of information or materials on the site by RHealthAssist does not constitute a waiver of any rights in such information and materials.</p>
                            <p><b>4. Limited Right to Use</b></p>
                            <p><b>4.1 Limited (personal use only) License and Site Access – All Rights Reserved:</b></p>
                            <p>RHealthAssist hereby grants you a limited license to access and make personal use of this website, but not to download (other than page caching) or modify it, or any portion of it, except with express written consent of RHealthAssist (e.g., downloading of PDF forms, applications, etc.). This license does not include any resale or commercial use of this website or the Content; any derivative use of this website or the Content; or any use of data mining, robots, or similar data gathering and extraction tools. This website or any portion of this site may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose. You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of RHealthAssist.com without RHealthAssist's express written consent. You may not use any meta-tags or any other "hidden text" utilizing any of RHealthAssist's name(s) or service marks without the express written consent of their owners. RHealthAssist (or the respective third-party owners of Content) retains all right, title, and interest in this website and any Content and features offered on this site, including any and all intellectual property rights. RHealthAssist (or the respective third-party owners of Content) reserve all rights not expressly granted herein. Any unauthorized use immediately terminates the permission or license granted by RHealthAssist.com. All rights, title and interest in and to the materials on this site are owned or controlled by and shall remain at all time vested in RHealthAssist or its CSPs.</p>
                            <p><b>4.2 Passwords &amp; Security:</b></p>
                            <p>By registering on the website, users can receive access to the password-protected portions of the site (the "User Portal"). When accessing the User Portal, you are entirely responsible for maintaining the confidentiality of your password and account and for any and all activities that occur under your account. You agree to (a)immediately notify RHealthAssist of any unauthorized use of your account or any other breach of security of which you become aware, and (b) exit completely from your account at the end of each online session. RHealthAssist will not be liable for any loss that you may incur as a result of someone else using your password or account, either with or without your knowledge. However, you could be held liable for losses incurred by RHealthAssist or another party due to someone else using your account or password. You may not use anyone else's account at any time.</p>
                            <p><b>4.3 Code of Conduct:</b></p>
                            <p>Users may be asked to comply with a user “Code of Conduct”, which will consist of policies and procedures governing user conduct on the site. Such Code of Conduct, once added to the site, may be updated from time to time; the most recent Code of Conduct will be posted on the website and any new material added to the Code of Conduct will be effective upon the date of publication on the site.</p>
                            <p><b>4.4 Connection Requirements:</b></p>
                            <p>You are solely responsible for providing and maintaining, at your own risk, option and expense, any hardware, software and communication lines required to access and use this website, and RHealthAssist reserves the right to change the access configuration of this site at any time without prior notice.</p>
                            <p><b>4.5 Prohibited Use:</b></p>
                            <p>Any use or attempted use of this website (i) for any unlawful, unauthorized, fraudulent or malicious purpose, or (ii) that could damage, disable, overburden, or impair any server, or the network(s) connected to any server, or (iii) interfere with any other party's use and enjoyment of the website, or (iv) to gain unauthorized access to any other accounts, computer systems or networks connected to any server or systems through hacking, password mining or any other means, or (v) to access systems, data or information not intended by RHealthAssist to be made accessible to a user, or (vi) attempt to obtain any materials or information through any means not intentionally made available by RHealthAssist, or (vii) any use other than the business purpose for which it was intended, is prohibited.</p>
                            <p>4.5.1 In addition, in connection with your use of the website, you agree you will not:</p>
                            <p>4.5.1(a) Upload or transmit any message, information, data, text, software or images, or other content ("Material") that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, or otherwise objectionable, or that may invade another's right of privacy or publicity</p>
                            <p>4.5.1(b) Create a false identity for the purpose of misleading others or impersonate any person or entity, including, without limitation, any RHealthAssist.com representative, or falsely state or otherwise misrepresent your affiliation with a person or entity</p>
                            <p>4.5.1(c) Upload or transmit any material that you do not have a right to reproduce, display or transmit under any law or under contractual or fiduciary relationships (such as nondisclosure agreements);</p>
                            <p>4.5.1(d) Upload files that contain viruses, trojan horses, worms, time bombs, cancel-bots, corrupted files, or any other similar software or programs that may damage the operation of another's computer or property of another</p>
                            <p>4.5.1(e) Delete any author attributions, legal notices or proprietary designations or labels that you upload to any communication feature;</p>
                            <p>4.5.1(f) Use the website's communication features in a manner that adversely affects the availability of its resources to other users (e.g., excessive shouting, use of all caps, or flooding continuous posting of repetitive text)</p>
                            <p>4.5.1(g) Upload or transmit any unsolicited advertising, promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes" or any other form of solicitation, commercial or otherwise;</p>
                            <p>4.5.1(h) Violate any applicable local, state, national or international law;</p>
                            <p>4.5.1(i) Upload or transmit any material that infringes any patent, trademark, service mark, trade secret, copyright or other proprietary rights of any party;</p>
                            <p>4.5.1(j) Delete or revise any material posted by any other person or entity;</p>
                            <p>4.5.1(k) Manipulate or otherwise display the website by using framing, mirroring or similar navigational technology or directly link to any portion of the website other than the main homepage, www.rhealthassist.com, in accordance with the Limited License and Site Access outlined above</p>
                            <p>4.5.1(l) Probe, scan, test the vulnerability of or breach the authentication measures of, this website or any related networks or systems;</p>
                            <p>4.5.1(m) Register, subscribe, attempt to register, attempt to subscribe, unsubscribe, or attempt to unsubscribe, any party for any services or any contests, promotions or sweepstakes if you are not expressly authorized by such party to do so;</p>
                            <p>4.5.1(n) Harvest or otherwise collect information about others, including e-mail addresses; or</p>
                            <p>4.5.1(o) Use any robot, spider, scraper, or other automated or manual means to access this website, or copy any content or information on this site. RHealthAssist.com reserves the right to take whatever lawful actions it may deem appropriate in response to actual or suspected violations of the foregoing, including, without limitation, the suspension or termination of the user's access and/or account. RHealthAssist.com may cooperate with legal authorities and/or third-parties in the investigation of any suspected or alleged crime or civil wrong. Except as may be expressly limited by the Privacy Policy, RHealthAssist.com reserves the right at all times to disclose any information as RHealthAssist.com deems necessary to satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to remove any information or materials, in whole or in part, in RHealthAssist.com's sole discretion.</p>
                            <p>4.6 Right to Monitor</p>
                            <p>RHealthAssist.com neither actively monitors general use of this website under normal circumstances nor exercises editorial control over the content of any third-party's website, e-mail transmission, news group, or other material created or accessible over or through this website. However, RHealthAssist does reserve the right to monitor such use at any time as it deems appropriate and to remove any materials that, in RHealthAssist’s sole discretion, may be illegal, may subject RHealthAssist to liability, may violate these Terms of Use, or are, in the sole discretion of RHealthAssist, inconsistent with RHealthAssist.com's purpose for this website.</p>
                            <p>4.7. Editing, Deleting and Modification:</p>
                            <p>RHealthAssist reserves the right in our sole discretion to edit or delete any documents, information or other content appearing on the site at any time without notice.</p>
                            <p>4.8. Submissions</p>
                            <p>4.8.1 If you send submissions (e.g., postings to chats, message boards, or contests) or creative suggestions, ideas, notes, drawings, concepts, or other information (collectively, the "Submissions"), the Submissions shall be deemed, and shall remain, the property of RHealthAssist. None of the Submissions shall be subject to any obligation of confidence on the part of RHealthAssist (unless explicitly stated otherwise), and RHealthAssist shall not be liable for any use or disclosure of any Submissions. You hereby represent and warrant that you have all necessary rights in and to the Submissions you provide and that all information they contain shall not infringe any proprietary or other rights of third-parties, or contain any libelous, tortuous, or otherwise unlawful information. In addition, you warrant that all so-called moral rights in any Submissions you provide have been waived. Without limitation of the foregoing, RHealthAssist shall exclusively own all known or hereafter existing rights to the Submissions of every kind and nature in perpetuity and shall be entitled to unrestricted use of the Submissions for any purpose whatsoever, commercial or otherwise, without compensation to the provider of the Submissions.</p>
                            <p>4.8.2 RHealthAssist does not endorse and has no control over the content of Submissions submitted by others to the site. Submissions submitted to the site are not necessarily reviewed by RHealthAssist prior to posting and do not necessarily reflect the opinions or policies of RHealthAssist. RHealthAssist makes no warranties, express or implied, as to the content of the Submissions on the site or the accuracy and reliability of any Submissions and other materials on the site. Nonetheless, RHealthAssist reserves the right to prevent you from contributing Submissions to the site and to edit, change and/or remove such Submissions for any reason whatsoever without prior notice.</p>
                            <p>4.8.3 Furthermore, content posted by you on RHealthAssist remains the property of RHealthAssist and will not be removed if or when you elect to close your account. However, RHealthAssist may endeavor to anonymise any content posted by you, upon account closure.</p>
                            <p>4.9. Nontransferable:</p>
                            <p>Your right to use the site is not transferable. Any password or right given to you to obtain information, documents and so on, remains non-transferable</p>
                            <p><b>5. Minors</b></p>
                            <p>5.1 If you have agreed to allow your Minor Child, or a child for whom you are Legal Guardian (a "Minor"), to use this site, you agree that you shall be Solely Responsible for: (a) the online conduct of such Minor; (b) monitoring such Minor’s access to and use of the site; and (c) the consequences of any use of the site by such Minor.</p>
                            <p>5.2 Child Online Protection Notification: RHealthAssist hereby notifies you that parental control protections (such as computer hardware, software, or filtering services) are commercially available that may assist you in limiting access to material that is harmful to Minors. You are strongly encouraged to take appropriate action on this point.</p>
                            <p><b>6. Third-Party Services</b></p>
                            <p>6.1 We may, from time to time at our sole discretion, allow access to or advertise third-party merchant sites and service providers ("Merchants" or “Service Providers”, collectively referred to as ‘MSP’) from which you may purchase certain goods or services. You understand that we do not operate or control the products or services offered by MSP. MSP are responsible for all aspects of order processing, fulfillment, billing and customer service.</p>
                            <p>6.1 We may, from time to time at our sole discretion, allow access to or advertise third-party merchant sites and service providers ("Merchants" or “Service Providers”, collectively referred to as ‘MSP’) from which you may purchase certain goods or services. You understand that we do not operate or control the products or services offered by MSP. MSP are responsible for all aspects of order processing, fulfillment, billing and customer service.</p>
                            <p>6.1 We may, from time to time at our sole discretion, allow access to or advertise third-party merchant sites and service providers ("Merchants" or “Service Providers”, collectively referred to as ‘MSP’) from which you may purchase certain goods or services. You understand that we do not operate or control the products or services offered by MSP. MSP are responsible for all aspects of order processing, fulfillment, billing and customer service.</p>
                            <p>6.1 We may, from time to time at our sole discretion, allow access to or advertise third-party merchant sites and service providers ("Merchants" or “Service Providers”, collectively referred to as ‘MSP’) from which you may purchase certain goods or services. You understand that we do not operate or control the products or services offered by MSP. MSP are responsible for all aspects of order processing, fulfillment, billing and customer service.</p>
                            <p><b>7. Payments</b></p>
                            <p>7.1 You represent and warrant that if you are purchasing something from us or from MSP that: (i) any credit information you supply is true and complete; (ii) charges incurred by you will be honored by your credit card company or applicable payment intermediary; and (iii) you will pay the charges incurred by you at the posted prices, including any applicable taxes.</p>
                            <p><b>8. Securities Laws</b></p>
                            <p>8.1 This site may include statements concerning our operations, prospects, strategies, financial condition, future economic performance and demand for our products or services, as well as our intentions, plans and objectives, that are forward-looking statements. These statements are based upon a number of assumptions and estimates that are subject to significant uncertainties, many of which are beyond our control. When used on our site, words like "anticipates," "expects," "believes," "estimates," "seeks," "plans," "intends" and similar expressions are intended to identify forward-looking statements designed to fall within securities law safe harbors for forward-looking statements. The site and the information contained herein do not constitute an offer or a solicitation of an offer for the sale of any securities. None of the information contained herein is intended to be, nor shall be deemed incorporated into any of our securities-related filings or documents.</p>
                            <p><b>9. Links to Other Websites</b></p>
                            <p>9.1 The site contains links to other websites. We are not responsible for the content, accuracy or opinions expressed in such websites, and such websites are not investigated, monitored or checked for accuracy or completeness by us. Inclusion of any linked website on our site does not imply approval or endorsement of the linked website by us. If you decide to leave our site and access these third-party sites, you do so at your own risk.</p>
                            <p><b>10. Infringement Policy</b></p>
                            <p>10.1 RHealthAssist reserves the right, but not the obligation, to terminate your license to use the site if it determines in its sole and absolute discretion that you are involved in infringing activity, including alleged acts of first-time or repeat infringement, regardless of whether the material or activity is ultimately determined to be infringing. RHealthAssist accommodates and does not interfere with standard technical measures used by copyright owners to protect their materials.</p>
                            <p>10.2 Any written notice regarding any defamatory or infringing activity, whether of a copyright, patent, trademark or other proprietary right, should be immediately brought to our attention by contacting us as the address set forth in this Agreement.</p>
                            <p><b>11. Information and Press Releases</b></p>
                            <p>11.1 The site may contain information and press releases about us. While this information was believed to be accurate as of the date prepared, we disclaim any duty or obligation to update this information or any press releases. Information about companies other than ours contained in press releases or otherwise, should not be relied upon as being provided or endorsed by us.</p>
                            <p><b>12. Compliance with Local Laws</b></p>
                            <p>12.1 RHealthAssist makes no representation that materials on this site are appropriate or available for use in your legal jurisdiction. Those who choose to access this site do so on their own initiative and are responsible for compliance with local laws, if and to the extent that local laws are applicable. Software from this site may be subject to international export controls. By using the site, you represent and warrant that you are not located in, under the control of, or a national or resident of any such restricted jurisdiction. Information RHealthAssist publishes on the World Wide Web may contain references or cross references to RHealthAssist products, programs or services that are not announced or available in your country.</p>
                            <p><b>13. Indemnification</b></p>
                            <p>13.1 You agree to defend, indemnify and hold harmless RHealthAssist, its affiliates and their respective directors, officers, employees and agents (collectively, "Affiliated Parties") from and against any and all claims, actions, suits or proceedings, as well as any and all losses, liabilities, damages, costs and expenses (including reasonable attorneys fees and costs) arising out of or accruing from: (a) any material posted or otherwise provided by you that infringes any copyright, trademark, trade secret, trade dress, patent or other intellectual property right of any person or defames any person or violates their rights of publicity or privacy; (b) any misrepresentation made by you in connection with your use of the site; (c) any non-compliance by you with the terms and conditions of this Agreement; and (d) claims regarding any liability, loss, claim and/or expense arising from or related to your access and use of the site, including information obtained through the site. Notwithstanding the foregoing, RHealthAssist reserves the right to assume at its expense the exclusive defense and control of any matter subject to indemnification by you, in which event you will fully cooperate with RHealthAssist in asserting any available defenses.</p>
                            <p><b>14. Disclaimer and Limits</b></p>
                            <p>14.1 Information on this site may contain technical inaccuracies or typographical errors. Information may be changed or updated without notice. RHealthAssist may also make improvements or changes in the products or services described in this information at any time without notice.</p>
                            <p>14.1.1 Medical Disclaimers: this site is designed to offer you general health information for general educational purposes only. The health information furnished on this site and the interactive responses are not intended to be professional medical treatment or diagnosis and are not intended to replace personal consultation with a qualified physician, pharmacist, or other healthcare professional. You must always seek the advice of a professional for questions related to a disease, disease symptoms, and appropriate therapeutic treatments. If you have or suspect that you have a medical problem or condition, please contact a qualified healthcare provider immediately. You should never disregard medical attention or delay in seeking it because of information conveyed via site.</p>
                            <p>14.1.2 While information on this site has been obtained from sources believed to be reliable, neither we nor the CSPs warrant the accuracy of codes, prices, therapeutic content, or other data of any nature contained on this website. We do not give medical treatment, nor do we provide medical or diagnostic services. Neither we nor the CSPs guarantee that the content on this website covers all possible uses, directions, precautions, interactions, or adverse effects that may occur.</p>
                            <p>14.1.3 You may not rely on the application of any information obtained via this website as being applicable to your specific circumstances. It is intended for educational purposes only. Your reliance upon information and content obtained by you at or through this site is solely at your own risk. Neither we nor the CSPs assume any liability or responsibility for damage or injury (including death) to you, other persons, or property arising from any use of any information, idea, or instruction contained in the content or service provided to you.</p>
                            <p>14.1.4 Liability Disclaimers: RHealthAssist and the CSPs have utilized reasonable care in collecting and transmitting the information on and via this website and have obtained such information from sources believed to be reliable. However, RHealthAssist and the CSPs do not warrant the accuracy of the information on the website. Information reflecting prices is not a quotation or offer to sell or purchase. The clinical information contained in the information is intended as a supplement to, and not a substitute for, the knowledge, expertise, skill, and judgment of physicians, pharmacists, nurses, or other healthcare professionals involved in patient care.</p>
                            <p>14.2 THE INFORMATION FROM OR THROUGH THE SITE IS PROVIDED "AS IS," "AS AVAILABLE," AND RHealthAssist AND THE CSPs MAKE NO WARRANTY OR REPRESENTATION, EXPRESS OR IMPLIED, AS TO THE ACCURACY OF THE DATA FROM WHICH THE INFORMATION IS COMPILED OR THE ACCURACY OF THE INFORMATION ITSELF AND SPECIFICALLY DISCLAIMS THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. THE INFORMATION AND SERVICES MAY CONTAIN BUGS, ERRORS, PROBLEMS OR OTHER LIMITATIONS. RHealthAssist AND ITS AFFILIATED PARTIES HAVE NO LIABILITY WHATSOEVER FOR YOUR USE OF ANY INFORMATION OR SERVICE. WE ARE NOT LIABLE FOR ANY FAILURE TO PERFORM OR PERFORMANCE DUE TO A FORCE MAJEURE EVENT. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US THROUGH THE SITE SHALL CREATE ANY WARRANTY, REPRESENTATION OR GUARANTEE NOT EXPRESSLY STATED IN THIS AGREEMENT.</p>
                            <p>14.3 THIS WEBSITE AND THE CSPs OFFER NO FORMAL MEDICAL ADVICE IN CONNECTION WITH THIS WEBSITE. USERS ARE RESPONSIBLE FOR THE USE OF THE CONTENT ON THIS WEBSITE. YOU MUST CONSULT A LICENSED MEDICAL PROFESSIONAL IN-PERSON TO OBTAIN TREAMENT OR DIAGNOSIS; THIS SITE MAY ONLY BE USED FOR EDUCATIONAL PURPOSES. BY USE OF THIS WEBSITE YOU ACKNOWLEDGE AND AGREE THAT THE INFORMATION OBTAINED VIA THIS WEBSITE MAY CONTAIN INACCURACIES AND OTHER ERRORS. YOU USE THIS WEBSITE AND THE MATERIAL AND INFORMATION PROVIDED AT YOUR OWN RISK. RHealthAssist AND THE CSPs ARE NOT LIABLE FOR ANY DAMAGES ALLEGEDLY SUSTAINED ARISING OUT OF USE OF THE WEBSITE, AND INCLUDING ANY CONSEQUENTIAL, SPECIAL, OR SIMILAR DAMAGES, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
                            <p>14.4 ALL RESPONSIBILITY OR LIABILITY FOR ANY DAMAGES CAUSED BY VIRUSES, WORMS OR OTHER MALICIOUS SOFTWARE CONTAINED WITHIN THE SITE IS DISCLAIMED. WE WILL NOT BE LIABLE TO YOU FOR ANY INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES OF ANY KIND (INCLUDING LOST PROFITS) THAT MAY RESULT FROM USE OF, OR INABILITY TO USE OUR SITE. RHealthAssist DOES NOT WARRANT OR MAKE REPRESENTATION REGARDING THE USE OR THE RESULTS OF THE USE OF THE MATERIALS IN THIS SITE IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY OR OTHERWISE. AS A LIMITATION THEREOF, WE AND OUR AFFILIATED PARTIES ARE NOT LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES (INCLUDING DAMAGES FOR LOSS OF BUSINESS, LOSS OF PROFITS, LITIGATION, OR THE LIKE), WHETHER BASED ON BREACH OF CONTRACT, BREACH OF WARRANTY, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE NEGATION OF DAMAGES SET FORTH ABOVE IS A FUNDAMENTAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN US AND YOU. THIS SITE AND THE INFORMATION WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS. IF, NOTWITHSTANDING THE OTHER TERMS OF THIS AGREEMENT (OR ANY OTHER AGREEMENT BETWEEN YOU AND US), WE (OR THE CSP OR SPONSOR/ADVERTISERS) SHOULD HAVE ANY LIABILITY FOR ANY LOSS, HARM OR DAMAGE, YOU AND WE (ON BEHALF OF OURSELVES AND THE CSPs AND SPONSOR/ADVERTISERS) AGREE THAT SUCH LIABILITY SHALL UNDER NO CIRCUMSTANCES EXCEED US$1. YOU AND WE (ON BEHALF OF OURSELVES AND THE CSPs AND SPONSOR/ADVERTISERS) AGREE THAT THE FOREGOING LIMITATION OF LIABILITY IS AN AGREED ALLOCATION OF RISK BETWEEN YOU AND US (AND THE CSPs AND SPONSOR/ADVERTISERS) AND REFLECTS THE FEES, IF ANY, WE CHARGE YOU TO USE THIS SITE, THE SERVICES AND THE CONTENT. YOU ACKNOWLEDGE THAT ABSENT YOUR AGREEMENT TO THIS LIMITATION OF LIABILITY WE WOULD NOT PROVIDE THE WEBSITE, THE SERVICES, OR THE CONTENT TO YOU.</p>
                            <p>14.5 WAIVER OF JURY TRIAL: THE PARTIES HERETO HEREBY WAIVE TRIAL BY JURY IN ANY LITIGATION, SUIT OR PROCEEDING, IN ANY COURT WITH RESPECT TO, IN CONJUNCTION WITH, OR ARISING OUT OF THESE TERMS OF USE OR THE PRIVACY NOTICE OR THE VALIDITY, PROTECTION, INTERPRETATION, COLLECTION OR ENFORCEMENT THEREOF, AND/OR PERFORMANCE OF ANY OF THE OBLIGATIONS OR SERVICES HEREUNDER OR THEREUNDER; PROVIDED, HOWEVER, THAT WITH RESPECT TO ANY COMPULSORY COUNTERCLAIM (I.E., A CLAIM BY A PARTY HERETO AGAINST ANOTHER PARTY WHICH, IF NOT BROUGHT IN SUCH ACTION, WOULD RESULT IN THE FIRST PARTY BEING FOREVER BARRED FROM BRINGING SUCH CLAIM), A PARTY HERETO SHALL HAVE THE RIGHT TO RAISE SUCH COMPULSORY COUNTERCLAIM IN ANY SUCH LITIGATION, SUIT OR PROCEEDING, WHETHER OR NOT IT IS BEING TRIED BY A JURY.</p>
                            <p><b>General Provisions</b></p>
                            <p>15.1 Notices: any notices to you from RHealthAssist regarding the website or these Terms of Use will be posted on this website or made by e-mail.</p>
                            <p>15.2 Electronic Communications: when you visit this website or send e-mails to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by e-mail or by posting notices on this website. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing. You further agree that any notices provided by us electronically are deemed to be given and received on the date we transmit any such electronic communication.</p>
                            <p>15.3 Entire Agreement: these Terms of Use, any associated or subsequent Privacy Policy, and other policies RHealthAssist may post on this website constitute the “entire agreement” between RHealthAssist and you in connection with your use of this site including, without limitation, the User Portal, and supersedes any prior agreements between RHealthAssist and you regarding use of this site, including prior versions of these Terms of Use.</p>
                            <p>15.4 Dispute: all disputes between the Parties arising in connection with this Agreement shall be finally settled by binding arbitration, using Expedited Procedures, and judgment on the award rendered by the arbitrator(s) may be entered in any court of competent jurisdiction. The arbitrator shall be an individual generally skilled in the legal and business aspects of the subject matter of this Agreement. The arbitrator shall have no authority to impose penalties or award punitive damages. The arbitration shall take place at the appropriate venue located in Trivandrum, India, and the arbitrator shall apply the law of the State of Kerala and applicable rules of evidence. If all parties and the arbitrator agree, arbitration may take place by telephone or by written communication. Unless the arbitrator otherwise directs, the parties, their representatives, other participants, and the arbitrator shall hold the existence, content, and result of the arbitration in confidence. No action, regardless of form, related to the obligations of the parties under this Agreement may be brought by either party against the other more than one (1) year after the cause of action has accrued.</p>
                            <p>15.4.1 In any proceeding to enforce this Agreement, you explicitly waive your right to recover any litigation costs or attorneys’ fees. Notwithstanding the foregoing, RHealthAssist may seek immediate injunctive relief in the event of your infringement of intellectual property rights hereunder or a breach of your confidentiality obligations.</p>
                            <p>15.4.2 Notwithstanding the provision of this section, RHealthAssist may, at its sole discretion, choose to terminate this Agreement and, thus, preclude the ‘Dispute’ provision of this Agreement</p>
                            <p>15.5 Governing Law &amp; Severability of Provisions: these Terms of Use are governed by the laws of the Indian State of Kerala and controlling Indian Law without regard to any conflicts of law provisions. All parts this Agreement apply to the maximum extent permitted by law. Both Parties agree that if a part of this contract is found to be unenforceable as written, then that part will be replaced with terms that most closely match the intent of such part, to the extent permitted by law. The invalidity of part of this Agreement will not affect the validity and enforceability of the remaining provisions. The section headings are for convenience and do not have any force or effect.</p>
                            <p>15.5.1 To the extent that anything in or associated with the site is in conflict or inconsistent with this Agreement, this Agreement shall take precedence. Our failure to enforce any provision of this Agreement shall not be deemed either a waiver of such provision or a waiver of the right to enforce such provision.</p>
                            <p>15.6 Remedies: you agree that any violation, or threatened violation, by you of this Agreement constitutes an unlawful and unfair business practice that will cause us irreparable and unquantifiable harm. You also agree that monetary damages would be inadequate for such harm and consent to our obtaining any injunctive or equitable relief that we deem necessary or appropriate. These remedies are in addition to any other remedies we may have at law or in equity.</p>
                            <p>15.7 No Agency Relationship: neither these Terms of Use, nor any Content, materials or features of this website create any partnership, joint venture, employment, or other agency relationship between you and us. You may not enter into any contract on our behalf or bind us in any way.</p>
                            <h4 className="media-paratexthead">Contacting Us</h4>
                            <p>If you have any questions or concerns about this Agreement, please do not hesitate to contact us at rgicl.services@relianceada.com. We will attempt to respond to your questions or concerns promptly.</p>
                            <h4 className="media-paratexthead">Refund Policy</h4>
                            <p>RHealthAssist will make every effort to make a refund to the customer. RHealthAssist reserves the rights to issue refund. If applicable, we will make all efforts to refund the money in 45 days via Bank Transfer.</p>
                            <h4 className="media-paratexthead">Cancellation Policy</h4>
                            <p>Once a customer purchase credit and later decide not to use, before the interaction with the doctor, we will make all efforts to cancel and refund the amount in about 45 days via Bank Transfer. RHealthAssist reserves the rights on cancellation of any purchase.
                        </p>
                            <p>Updated: 07 February 2018
                        © 2017 rhealthassist.com All rights reserved.</p>
       
                        </div>
                      </div>

                  </div>
         
              </div>
            </div>

            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}


