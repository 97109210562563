import React, { Component, Suspense } from "react";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { apiCalling,apiUrlRelianceFunction } from "../apiService";
import moment from "moment";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import csv from "../image/icons/csv.png";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class textChatApptReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentData: [],
            toDate: "",
            fromDate: "",
            totalCount: 0,
            activePage: 1,
            fuctionName: 'TextChatPayment Report',
            csvDetails: [],
            showMessage: "Waiting for data... "
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeTo = this.handleChangeTo.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.textChatReport();
    }


    handleChange = (date) => {
        this.setState({
            fromDate: date,
        });
    };
    handleChangeTo = (date) => {
        this.setState({
            toDate: date,
        });
    };

    changeHandler = (event) => {

        const { value, name } = event.target;
        this.setState({
            [name]: value,
        })
    }

    handlePageChange(pageNumber) {
        window.scrollTo(0, 0);
        this.setState({ activePage: pageNumber });
        var offset;
        var max;
        if (pageNumber === "1") {
            offset = "0";
            max = "10";
            this.setState({
                activePage: pageNumber,
                isLoading: '0'
            })
        }
        else {
            offset = parseInt(pageNumber - 1) * 10;
            max = "10";
            this.setState({
                activePage: pageNumber,
                isLoading: '0'
            })
        }
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "paymentReport",
                subFunctionName: "textChatPayment",
                userId:this.props.match.params.groupHospitalId, //window.sessionStorage.getItem("groupHospitalAdminId"), //"19238",
                max: max,
                offset: JSON.stringify(offset),
                cmbCategory1: $("#textChatSelect").val() === '0' ? '' : $("#textChatSelect").val(),
                searchKeyWord: $("#txtTextChat").val(),
                fromDate: $('#fromDate').val(),
                toDate: $('#toDate').val()
            }),
        };

        $(".loader").show();
        let apiFunction=folderName!== null ? apiCalling : apiUrlRelianceFunction
        apiFunction(apiJson).then((data) => {

            if (data.success === "1") {
                this.setState({
                    paymentData: data.result.paymentData,
                    totalCount: data.result.count,
                });
                $(".loader").hide();
            }
        });
    }

    textChatReport() {
        $(".loader").show();
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "paymentReport",
                subFunctionName: "textChatPayment",
                userId:this.props.match.params.groupHospitalId, //window.sessionStorage.getItem("groupHospitalAdminId"), //"19238",
                max: "10",
                offset: "0"
            }),
        };
        let apiFunction=folderName!== null ? apiCalling : apiUrlRelianceFunction
        apiFunction(apiJson).then((data) => {
            $(".loader").hide();
            if (data.success === "1") {
                this.setState({
                    paymentData: data.result.paymentData,
                    csvDetails:data.result.paymentExportData,
                    totalCount: data.result.count,
                });
            }
            if (data.success === "0") {
                $(".loader").hide();
                this.setState({
                    paymentData: [],
                    csvDetails:[],
                    showMessage: data.errorMessage
                });
            }
        });
    }

    changeText = () => {
        if ($("#textChatSelect").val() !== '0') {
            document.getElementById("txtTextChat").disabled = false
            $('input[name=txtTextChat]').focus();
        }
        else {
            document.getElementById("txtTextChat").disabled = true
        }
        document.getElementById("txtTextChat").value = ""
    }

    searchHandler() {

        var startDate = new Date($('#fromDate').val());
        var endDate = new Date($('#toDate').val());
        if ($("#textChatSelect").val() !== "0" && $("#txtTextChat").val() === "") {
            var t = document.getElementById("textChatSelect");
            var selectedText = t.options[t.selectedIndex].text;
            alert(selectedText + " Required");
            $("#txtTextChat").focus();
            return false;
        }
        if (startDate > endDate) {
            alert("To date should be greater than or equal to from date")
            return false
        }
        else {
            const searchData = {
                method: "POST",
                body: JSON.stringify({
                    functionName: "paymentReport",
                    subFunctionName: "textChatPayment",
                    userId:this.props.match.params.groupHospitalId, //window.sessionStorage.getItem("groupHospitalAdminId"),  //"19238",
                    max: "10",
                    offset: "0",
                    cmbCategory1: $("#textChatSelect").val() === '0' ? '' : $("#textChatSelect").val(),
                    searchKeyWord: $("#txtTextChat").val(),
                    fromDate: $('#fromDate').val(),
                    toDate: $('#toDate').val()
                }),
            };
            $(".loader").show();
            let apiFunction=folderName!== null ? apiCalling : apiUrlRelianceFunction
            apiFunction(searchData).then((data) => {
                if (data.success == "1") {
                    $(".loader").hide();
                    this.setState({
                        paymentData: data.result.paymentData,
                        csvDetails: data.result.paymentExportData,
                        totalCount: data.result.count,
                        activePage: 1,
                    });
                }
                if (data.success === "0") {
                    $(".loader").hide();
                    this.setState({
                        paymentData: [],
                        csvDetails:[],
                        showMessage: data.errorMessage
                    });
                }
            })
        }
    }

    csvFileDownload() {

        const csvRow = [];
        var columnDetails = [['Patient%20Name', 'Hospital%20Name', 'Doctor%20Name', 'Chat%20Requested Date', 'Transaction%20Id',  'Appointment%20Status  ',  'Coupon%20Amount', 'Doctor%20Charge', 'Amount%20Received']]
        var results = this.state.csvDetails;

        for (var item = 0; item < results.length; item++) {
            let testStatus = '';
            if (results[item].doctorReply === true && results[item].isComplete === 1) {
                  testStatus = "Completed%20-%20Doctor%20Replied";
            }
            else if (results[item].doctorReply === false && results[item].isComplete === 0) {
                testStatus = "Active%20-%20Doctor%20Didn't%20Reply";
            }
            else if (results[item].doctorReply === false && results[item].isComplete === 1) {
                testStatus = "Expired%20-%20Doctor%20Didn't%20Reply";
            }
            else if (results[item].doctorReply === true && results[item].isComplete === 0) {
                testStatus = "Active%20-%20Doctor%20Replied";
            }

            columnDetails.push([results[item].memName.replaceAll(' ', '%20'), results[item].hospitalName.replaceAll(' ', '%20'), results[item].docName.replaceAll(' ', '%20'), results[item].requestedDate.replaceAll(' ', '%20'), results[item].transactionId.replaceAll(' ', '%20'), testStatus.replaceAll(' ', '%20'), results[item].promoAmt.replaceAll(' ', '%20'), results[item].actualAmount.replaceAll(' ', '%20'), results[item].amount.replaceAll(' ', '%20')])
        }

        for (var i = 0; i < columnDetails.length; ++i) {
            csvRow.push(columnDetails[i].join(","))
        }

        var csvString = csvRow.join("%0A");

        var dataType = document.createElement("a");

        dataType.href = 'data:attachment/csv,' + csvString;
        dataType.target = "_Blank";
        dataType.download = this.state.fuctionName + '.csv';
        document.body.appendChild(dataType);
        dataType.click();
    }

    render() {
        return (
            <div class="purpleWrap">
                <Suspense fallback={<div >Waiting...</div>}>
                    <section>
                    {folderName!==null &&
                        <Header />
                    }
                        <div className='loader'></div>
                        <div class="container">
                            <div class="main-wrapper1 date_width  dashbrd-rght-bottom-padng-hsp">
                                <div class="col-md-12 p-0">
                                    <ul class="breadcrumb">
                                        <li>
                                            <Link to="/Groupadmin/dashboard">Dashboard</Link>
                                            <i class="fa fa-chevron-right"></i>
                                        </li>
                                        <li> Text Chat Payment Report</li>
                                    </ul>
                                </div>
                                <div class="form-head"> Text Chat Payment Report </div>
                                <div class="row bg_ddd pt-3 mx-0">
                                    <div class="col-md-2 mb-3">
                                        <select class="custome_input mb-0"
                                            id="textChatSelect"
                                            name="textChatSelect"
                                            onChange={() => this.changeText()}
                                        >
                                            <option value="0">- - Select - -</option>
                                            <option value="Patient Name">Patient Name</option>
                                            <option value="Doctor Name"> Doctor Name</option>
                                            <option value="Hospital Name">Hospital Name</option>
                                            <option value="Transaction Id"> Transaction ID</option>
                                            {/* <option value="Status">Appointment Status</option> */}
                                        </select>
                                    </div>
                                    <div class="col-md-2 mb-3">
                                        <input
                                            type="text"
                                            class="custome_input mb-0"
                                            id="txtTextChat"
                                            name="txtTextChat"
                                            disabled
                                        />
                                    </div>
                                    <div class="col-md-2 mb-3">
                                        <DatePicker
                                            placeholderText="From Date"
                                            id="fromDate"
                                            name="fromDate"
                                            selected={this.state.fromDate}
                                            onChange={this.handleChange}
                                            dateFormat="yyyy/MM/dd"
                                            calendarClassName="react-date-picker"
                                            className="custome_input mb-0  datepickerIcon-hsp"
                                        />
                                    </div>
                                    <div class="col-md-2 mb-3">
                                        <DatePicker
                                            placeholderText="To Date"
                                            id="toDate"
                                            name="toDate"
                                            selected={this.state.toDate}
                                            onChange={this.handleChangeTo}
                                            dateFormat="yyyy/MM/dd"
                                            calendarClassName="react-date-picker"
                                            className="custome_input mb-0  datepickerIcon-hsp"
                                        />
                                    </div>
                                    <div class="col-md-2 mb-3">
                                        <input
                                            type="submit"
                                            value="Search"
                                            class="formBtnBg w-100 mb-0"
                                            onClick={() => this.searchHandler()}
                                        />
                                    </div>
                                    <div className="col-md-1  mb-3 d-flex align-items-center">
                                        <a onClick={() => this.csvFileDownload()} className="cursor-pointer"><img src={csv} alt="csv" width="25" title="Export CSV" className="mt-1" /></a>
                                    </div>
                                </div>
                                {this.state.paymentData.length > 0 ?
                                <div>
                                    <div class="table-responsive">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Patient Name</th>
                                                    <th>Hospital Name</th>
                                                    <th>Doctor Name</th>
                                                    <th>Text Chat Requested Date</th>
                                                    <th>Transaction Id </th>
                                                    <th>Appointment Status</th>
                                                    <th>Coupon Amount</th>
                                                    <th>Doctor Charge</th>
                                                    <th>Amount Received</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ fontSize: "12px" }}>
                                                {this.state.paymentData.map((data) => {
                                                     let testStatus = '';
                                                    if (data.doctorReply === true && data.isComplete === 1) {
                                                          testStatus = "Completed - Doctor Replied";
                                                    }
                                                    else if (data.doctorReply === false && data.isComplete === 0) {
                                                        testStatus = "Active - Doctor Didn't Reply";
                                                    }
                                                    else if (data.doctorReply === false && data.isComplete === 1) {
                                                        testStatus = "Expired - Doctor Didn't Reply";
                                                    }
                                                    else if (data.doctorReply === true && data.isComplete === 0) {
                                                        testStatus = "Active - Doctor Replied";
                                                    }
                                                    //   let requestedDate = moment(data.requestedDate).format("DD-MMM-YYYY");
                                                    return (
                                                        <tr>
                                                            <td>{data.memName}</td>
                                                            <td >{data.hospitalName}</td>
                                                            <td>Dr.{data.docName}</td>
                                                            <td>{data.requestedDate}</td>
                                                            <td>{data.transactionId}</td>
                                                            <td>{testStatus}</td>
                                                            <td>₹ {data.promoAmt}</td>
                                                            <td>₹ {data.actualAmount}</td>
                                                            <td>₹ {data.amount}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        </div>
                                        {this.state.totalCount > 5 ?
                                            <div className="paginationSection orderm-paginationSection text-right">
                                                <Pagination
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={10}
                                                    totalItemsCount={this.state.totalCount}
                                                    pageRangeDisplayed={10}
                                                    prevPageText={"Previous"}
                                                    nextPageText={"Next"}
                                                    onChange={this.handlePageChange}
                                                />
                                            </div>
                                            : ""}
                                    </div>
                                    :
                                    <p className="error-msg text-center mb-0"> {this.state.showMessage} </p>
                                }
                            </div>
                        </div>
                        {folderName!==null &&
                        <Footer />
    }
                    </section>
                </Suspense>
            </div>
        );
    }
}
