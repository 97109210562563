import React, { Component, Suspense } from "react";
import $ from "jquery";
import { apiCalling } from "../apiService";
import ReactTooltip from 'react-tooltip';
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class PrescriptionList extends Component {
  render() {
    return (
      
      <div class="purpleWrap">
          <div className='loader'></div>
        <Suspense fallback={<div class="loader"></div>}>
          <section>
            <Header />
            <div class="contentCoverWrapper">
              <div class="container">
                  <div className="col-md-12 col-lg-10 col-xs-12 col-md-offset-2 col-lg-offset-2 media-wrap-margin">
                      <div className="media-wrap">
                        <h3 className="mediaMain-title">DISCLAIMER</h3>
                        <div className="media-outer-wrap">
                        <p> The Web Site is maintained by Reliance General Insurance Co. Ltd. and use of any part of the Web Site is subject to the Terms and Conditions available herein and all applicable Indian laws.</p>
                        <p>The materials and content available on the Web Site are provided without warranties of any kind, either express or implied, and Reliance General Insurance specifically disclaims all warranties, including implied warranties of merchantability or fitness for any purpose. Reliance General Insurance does not make any representation or warranty regarding the use, or the results of the use, of the materials and content available on the Web Site in terms of accuracy, reliability or otherwise. The product information, including the scopes of cover, terms, conditions, exclusions and limitations, available on the Web Site is not intended to be exhaustive and is indicative in nature. </p>
                        <p>The Web Site may contain links to other websites, web-pages and services also operated by Reliance General insurance and / or its Affiliates (the "Group Sites"), and your use of each such Group Site is also subject to the Terms and Conditions and other terms and guidelines, if any, contained within each Group Site. Reliance General Insurance disclaims all liability for the content and/or materials available on such other sites.</p>
                        <p>You should assume that everything you see or read on the Web Site (including but not limited to directories, guides, news articles, opinions, reviews, text, photographs, images, illustrations, profiles, audio clips, video clips, trademarks, service marks and the like) is copyrighted/ protected by intellectual property laws unless otherwise noted and may not be used except as provided in these Terms and Conditions, or in the text on the Web Site, without the prior written permission of Reliance General Insurance. Reliance General Insurance. Neither warrants nor represents that your use of materials displayed on the Web Site will not infringe patent, copyright or any intellectual property rights or any other rights of third parties not owned by or affiliated with Reliance General Insurance.</p>
                                        
       
                        </div>
                      </div>

                  </div>
         
              </div>
            </div>

            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}


