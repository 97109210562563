import React, { Component, Suspense } from "react";
import bolgtemp from "../image/icons/4.jpg"
import { Link } from "react-router-dom";
import $ from "jquery";
import { apiCalling } from "../apiService";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class Blog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      resultData: [],
      selectCategotyList: [],
      blogData:[],
      showMessage: "Waiting For Data......"
    }
  }

  componentDidMount() {

    this.categorySelectList();
    this.resultListDataCommon();

  }

  categorySelectList() {
    $(".loader").show();
    const saveField = {
      method: 'POST',
      body: JSON.stringify({
        "functionName": "healthInfoMasterData"
      })
    }
    apiCalling(saveField).then((data) => {
      $(".loader").hide();
      if (data.success == "1") {
        this.setState({
          selectCategotyList: data.healthInfoCategory,
        })

      }
    })
  }

  resultListDataCommon() {
    const dataList = {
      method: 'POST',
      body: JSON.stringify({
        "functionName": "getDoctorHealthArticles",
        "siteId": window.sessionStorage.getItem("siteId"),
        "categoryId": ""
      })
    }
    $(".loader").show();
    apiCalling(dataList).then((data) => {
      $(".loader").hide();
      if (data.success == "1" && data.result.length > 0) {
        this.setState({ resultData: data.result });
      } else if (data.success == "0") {
        this.setState({ showMessage: "No Data Found " });
      }
    });
  }

  resultListData(obj) {
    const dataList = {
      method: 'POST',
      body: JSON.stringify({
        "functionName": "getDoctorHealthArticles",
        "siteId": window.sessionStorage.getItem("siteId"),
        "categoryId": obj.categoryId
      })
    }
    //alert(dataList)
    $(".loader").show();
    apiCalling(dataList).then((data) => {
      /// console.log("datttatypeee",data)
      // alert("dataList",data)
      $(".loader").hide();
      if (data.success == "1" && data.result.length > 0) {
        this.setState({ resultData: data.result });
      } else if (data.success == "0") {
        this.setState({ showMessage: data.errorMessage });
      }
    });
    var idd= $(this).attr("id");
    $("#"+idd).addClass("category-sub-active");
    $("html,body").animate({"scrollTop": $("#blog-detailsId").offset().top})
  }

  addBlogHandle() {
    window.location.href = "/doctor/healthblog";
    window.sessionStorage.setItem('AddJournal','');
  }

  
  srcTypeImage(ev){
    ev.target.src =bolgtemp
  }

  deleteBlogData(data){

  var r =window.confirm("Are you sure you want to delete?");
  if (r == true) {
    $(".loader").show();
    const deleteBlog = {
      method: 'POST',
      body: JSON.stringify({
        "functionName": "deleteHealthInfo",
         "healthInfoId":data.healthInfoId
      })
    }
    apiCalling(deleteBlog).then((data) => {
      $(".loader").hide();
       if (data.success == "1" ) {
        alert("Deleted successfully");
        this.resultListDataCommon();
       }
    });
  } else {
    return false;
  }
  }

  editBlogHandle(){

    window.sessionStorage.setItem('AddJournal','');  
  }

  render() {
    return (
      <div class="purpleWrap blg-wrapr-height">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />{" "}
            <div className='loader'></div>
          </section>
        </Suspense>
        <div className="container blog-wrapper" id="blogList">
          <h1 className="telehealthfeaturehead text-center"> Blog </h1>
          <div className="row ">
          <div className="col-md-12">
            <button className="addNew-btn" onClick={() => this.addBlogHandle()}>Add Article</button>
          </div>

            {/* <div className="col-lg-3 col-md-4">
              <div className="category-main">
                {this.state.selectCategotyList.map((obj) => (
                  <div className="category-list">
                    <a href="#"><div className="category-sub" onClick={() => this.resultListData(obj)}>{obj.categoryName}</div></a>
                   </div>
                ))}
              </div>
            </div> */}
            {this.state.resultData.length > 0 ?
              <div className="col-lg-12 col-md-12" id="blog-detailsId">
                <div className="row mt-1 mt-md-0">
                  {this.state.resultData.map((data) => (
                   <div className="blog-listbox">
                      <div className="blog-listImgDiv">
                        <img onError={this.srcTypeImage} src={data.imageName} alt="Blog Image Name" className="img-fluid blog-listImg"></img>
                      </div>
                      <div className="blog-list-detils">
                      <div className="blogtag-name-head">{data.healthInfoTitle}</div>
                      <div className="bloguserList-text bloguserList-textPadd">{data.shortDescription} </div>
                      {/* <div className="blogtag-main">
                                <div className="blogtag-sub"> {data.tagName}</div>
                                <div className="blogtag-sub"> Webinar</div>
                              </div> */}
                           {data.secUserId == window.sessionStorage.getItem("userId") ? (
                       <Link   className="readmoreBlog" to={"/doctor/healthblog/" + data.healthInfoId} onClick={this.editBlogHandle()} > Edit Blog</Link>
                       ) : ''}
                        {data.secUserId == window.sessionStorage.getItem("userId") ? (
                        <button  className="readmoreBlog" onClick={() => this.deleteBlogData(data)} > Delete Blog</button>
                        ) : ''}
                      {/* {renderHTML(data.description)} */}
                      </div>
                    </div>
                  ))}

                  {/* <div className="col-lg-4 col-md-6 mb-5">
                              <img src={bolg2} alt="apple-laptop-notebook" className="img-fluid"></img>
                              <div className="blogtag-name">Author Post</div>
                              <div className="bloguser-text">Lorem ipsum dolor sit amet, elit, do eiusmod tempor incididunt </div>
                              <div className="blogtag-main">
                                <div className="blogtag-sub"> Remote work</div>
                                <div className="blogtag-sub"> Webinar</div>
                              </div>
                              <a href="#" className="readmoreBlog">
                                Read more
                              </a>
                          </div>

                          <div className="col-lg-4 col-md-6 mb-5">
                              <img src={bolg2} alt="apple-laptop-notebook" className="img-fluid"></img>
                              <div className="blogtag-name">Author Post</div>
                              <div className="bloguser-text">Lorem ipsum dolor sit amet, elit, do eiusmod tempor incididunt </div>
                              <div className="blogtag-main">
                                <div className="blogtag-sub"> Remote work</div>
                                <div className="blogtag-sub"> Webinar</div>
                              </div>
                              <a href="#" className="readmoreBlog">
                                Read more
                              </a>
                          </div>

                          <div className="col-lg-4 col-md-6 mb-5">
                              <img src={bolg2} alt="apple-laptop-notebook" className="img-fluid"></img>
                              <div className="blogtag-name">Author Post</div>
                              <div className="bloguser-text">Lorem ipsum dolor sit amet, elit, do eiusmod tempor incididunt </div>
                              <div className="blogtag-main">
                                <div className="blogtag-sub"> Remote work</div>
                                <div className="blogtag-sub"> Webinar</div>
                              </div>
                              <a href="#" className="readmoreBlog">
                                Read more
                              </a>
                          </div>

                          <div className="col-lg-4 col-md-6 mb-5">
                              <img src={bolg2} alt="apple-laptop-notebook" className="img-fluid"></img>
                              <div className="blogtag-name">Author Post</div>
                              <div className="bloguser-text">Lorem ipsum dolor sit amet, elit, do eiusmod tempor incididunt </div>
                              <div className="blogtag-main">
                                <div className="blogtag-sub"> Remote work</div>
                                <div className="blogtag-sub"> Webinar</div>
                              </div>
                              <a href="#" className="readmoreBlog">
                                Read more
                              </a>
                          </div>

                          <div className="col-lg-4 col-md-6 mb-5">
                              <img src={bolg2} alt="apple-laptop-notebook" className="img-fluid"></img>
                              <div className="blogtag-name">Author Post</div>
                              <div className="bloguser-text">Lorem ipsum dolor sit amet, elit, do eiusmod tempor incididunt </div>
                              <div className="blogtag-main">
                                <div className="blogtag-sub"> Remote work</div>
                                <div className="blogtag-sub"> Webinar</div>
                              </div>
                              <a href="#" className="readmoreBlog">
                                Read more
                              </a>
                          </div>

                          <div className="col-lg-4 col-md-6 mb-5">
                              <img src={bolg2} alt="apple-laptop-notebook" className="img-fluid"></img>
                              <div className="blogtag-name">Author Post</div>
                              <div className="bloguser-text">Lorem ipsum dolor sit amet, elit, do eiusmod tempor incididunt </div>
                              <div className="blogtag-main">
                                <div className="blogtag-sub"> Remote work</div>
                                <div className="blogtag-sub"> Webinar</div>
                              </div>
                              <a href="#" className="readmoreBlog">
                                Read more
                              </a>
                          </div>

                          <div className="col-lg-4 col-md-6 mb-5">
                              <img src={bolg2} alt="apple-laptop-notebook" className="img-fluid"></img>
                              <div className="blogtag-name">Author Post</div>
                              <div className="bloguser-text">Lorem ipsum dolor sit amet, elit, do eiusmod tempor incididunt </div>
                              <div className="blogtag-main">
                                <div className="blogtag-sub"> Remote work</div>
                                <div className="blogtag-sub"> Webinar</div>
                              </div>
                              <a href="#" className="readmoreBlog">
                                Read more
                              </a>
                          </div> */}

                  {/* <div className="col-md-12 text-center mt-0 mt-md-5">
                            <a class="commonButton">Read More</a>
                          </div> */}

                </div>
              </div>
              :
              <div className="col-lg-12 col-md-12 py-5" id="blog-detailsId" style={{textAlign:"center"}}><p className="error-msg text-center mb-0" > {this.state.showMessage} </p></div>
            }
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
