import React, { Component, Suspense } from "react";
import { Link } from "react-router-dom";
import Dashboardleftmenu from "./dashboardleftmenu";
import { apiCalling } from "../apiService";
import $ from 'jquery';

const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class HospDashboard extends Component {

    
    render() {
        return (
            <div class="purpleWrap">
                <Suspense fallback={<div></div>}>
                    <section>
                        <Header />
                    </section>
                </Suspense>
                <div class="container">
                    <div class="main-wrapper1 hspmain-wrapper-promo" id="row-col-width-leftmenu">
                        <div class="row">
                            <div class="col-lg-1 d-lg-block d-xl-block">
                                <Dashboardleftmenu></Dashboardleftmenu>
                            </div>
                            <div class="col-lg-11">
                                <div className="dashboard-right dashbrd-rght-bottom-padng-hsp dash_left_border pl-0">
                                    <div className="dash_bg">
                                        <div className="row">
                                            <div className="col-md-7">
                                                <div className="dash_link d-flex align-items-center">
                                                    <Link to="/videoSchedule/editDoctorSchedule" className="dash_linkHead">Setup Doctors Video/Audio Schedule</Link>
                                                    <Link to="/videoSchedule/editDoctorSchedule" className="ml-auto dash_linkbutton"><button className="">Go</button></Link>
                                                </div>
                                                <div className="dash_link d-flex align-items-center">
                                                    <Link to="/GroupAdmin/VideoAudioAppointment" className="dash_linkHead">View Doctors Video/Audio Appointments</Link>
                                                    <Link to="/GroupAdmin/VideoAudioAppointment" className="ml-auto dash_linkbutton"><button className="">Go</button></Link>
                                                </div>
                                                {/* <div className="dash_link d-flex align-items-center">
                                                    <Link to="" className="dash_linkHead">Doctors Video Chat History</Link>
                                                    <Link to="" className="ml-auto dash_linkbutton"><button className="">Go</button></Link>
                                                </div> */}
                                            </div>
                                            <div className="col-md-5">
                                                <div className="dash_rightdiv">
                                              
                                                          
                                  						    	<ul className="">
                                                                  <p>Minimum requirements for video chat</p>
                                                                    <li>Latest version of Chrome 30+ <a href="https://www.google.com/intl/en_uk/chrome/" target="_blank" class="blueLink">Download Chrome</a></li>
                                                                    <li>Wired Internet connection with at least 500kbps speed</li>
                                                                    <li>A webcam</li>
                                                                    <li>A Microphone</li>
                                                                </ul>
                                						
                                                </div>
                                            </div>
                                        </div>
</div>

                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Suspense fallback={<div></div>}>
                    <section>
                        <Footer />
                    </section>
                </Suspense>
            </div>
        );
    }
}
