import React, { Component } from 'react';
import $ from "jquery";
import Pagination from "react-js-pagination";
import ZeroUtilization from './zero_Utilization';
import ErrorList from './error_list'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { apiCalling } from "../apiService";
import * as XLSX from "xlsx";
import exportFromJSON from 'export-from-json'
let groupHospitalAdminId = ''
let status = 1;
let offset = 0;
export default class GroupReport extends Component {
  constructor(props) {
      super(props);    
      this.state = {
          activePage: 1,
          activeIndex: 0,
          status: 1,
          policyCouponData:[],
          policyCouponDataCount:'',          
          startDate: '',
          EndDate:''
        };
        this.handleEndChange = this.handleEndChange.bind(this);
        this.handleStartChange = this.handleStartChange.bind(this);
        this.fileDownload = this.fileDownload.bind(this)
    }
    handleEndChange = (date) => {
        this.setState({
          EndDate: date
        })
    }
    handleStartChange = (date) =>  {
      this.setState({
          startDate: date
      })
    }
    radioHandler = (value) => {
      status = value
      $('#CouponName').val('')
      this.setState({
          startDate: '',
          EndDate:'',
          activePage:1
      })
      offset = 0
      this.getCouponReport()
    };
    setActive = index => {
      this.setState({ activeIndex: index });
    };
  
    handlePageChange(pageNumber) {
      this.setState({activePage: pageNumber});
      if(pageNumber === 1) {
        offset = 0
      } else {
        offset =  10*pageNumber
      }
      this.getCouponReport()
    }
    componentDidMount() {
      $("#changePassword").addClass("dashBoardLeftMenu-active");
      groupHospitalAdminId = window.sessionStorage.getItem("userId");
      this.getCouponReport()
    }
    getCouponReport () {
      $(".loader").show();
      const apiJson = {
          method: "POST",
          body: JSON.stringify({
            "functionName": "rhCouponCode",
            "subFunction":"couponCodeReport",
            "txtInput":status === 1 ? $('#CouponName').val() : $('#PolicyNo').val(),
            "type2":status === 1 ? "coupons" : "policies",
            "fromDate":"",
            "toDate":"",
            "max":"15",
            "offset":offset === 0 ? 0 : offset
          }),
      };
      apiCalling(apiJson).then((data) => {
          if (data.success === "1") {
              $(".loader").hide();
              this.setState({
                policyCouponData: status === 1 ? data.couponData : data.policyData,
                policyCouponDataCount: status === 1 ? data.couponDataCount : data.policyDataCount
              })
          } else {
              $(".loader").hide();
              this.setState({
                policyCouponData:[],
                policyCouponDataCount:''
              })
              alert(data.errorMessage)
          }
      });
    }
    refresh = () => {
      $('#CouponName').val('')
      this.setState({
          startDate: '',
          EndDate:''
      })
      this.getCouponReport()
    }
    searchData = () => {
     
      let startDate = this.state.startDate;
      let EndDate = this.state.EndDate;
      if ($('#CouponName').val() == "") {
          alert('Enter Coupon Name')
      } else if((startDate != "" && EndDate == "") || (startDate == "" && EndDate != "") || (startDate != "" && EndDate != "")) {
        if (startDate == "") {
          alert('Coupon Start Date Required')
        } else if (EndDate == "") {
          alert('Coupon End Date Required')
        } else if (startDate.getTime() > EndDate.getTime()) {
          alert('Coupon End date should be greater than or equal to Coupon Start date')
        } else {
          this.setState({activePage: 1});
          offset = 0
          this.getCouponReport()
        }
      } else {
        this.setState({activePage: 1});
        offset = 0
        this.getCouponReport()
      }
    }
    downloadXlsx = (data,fileName,exportType) => {
      exportFromJSON({ data, fileName, exportType });
    }
    fileDownload = () => {
          var fileName = "Coupons_success_rows";
          var exportType = "xls";
          let policyCouponData = []
          const apiJson = {
            method: "POST",
            body: JSON.stringify({
              "functionName": "rhCouponCode",
              "subFunction":"couponCodeReport",
              "txtInput":status === 1 ? $('#CouponName').val() : $('#PolicyNo').val(),
              "type2":status === 1 ? "coupons" : "policies",
              "fromDate":"",
              "toDate":"",
              "max":"1000",
              "offset":0
            }),
        };
        apiCalling(apiJson).then((data) => {
            if (data.success === "1") {
                $(".loader").hide();
                policyCouponData = status === 1 ? data.couponData : data.policyData
                this.downloadXlsx(policyCouponData, fileName, exportType)
            } else {
                $(".loader").hide();
            }
        });
    }
 render() {
    return (
      <>
      
        <div class="tabmain_list">
                    <a className={"list_tab " +(this.state.activeIndex === 0 ? "active" : "")}
          onClick={() => this.setActive(0)}>Coupons and Policies </a>
                    <a className={"list_tab " +(this.state.activeIndex === 1 ? "active" : "")}
          onClick={() => this.setActive(1)}>
                        <span class="videoSpan">Zero Utilization</span> 
                      </a>
                    <a className={"list_tab " +(this.state.activeIndex === 2 ? "active" : "")}
          onClick={() => this.setActive(2)}>Error Report </a>
        </div>
       
        <div className={"main_coupons " +(this.state.activeIndex === 0 ? "active" : "")}
          onClick={() => this.setActive(0)}>
               <div className='row'>
            <div className='col-md-2'>
            <label class=" label-text px-0 px-sm-3 mb-0 d-flex align-items-center">
              <input type="radio"  name="group_report" id="Coupons" checked={status === 1} onClick={(e) => this.radioHandler(1)}/><span className="ml-2 label-text">Coupons</span>
            </label>
            </div>
            <div className='col-md-2'>
            <label class=" label-text px-0 px-sm-3 mb-0 d-flex align-items-center">
              <input type="radio"  name="group_report" id="Policies" checked={status === 2} onClick={(e) => this.radioHandler(2)}/><span className="ml-2 label-text">Policies</span>
            </label>
            </div>
        </div>
        {status === 1 && 
        <div className='group_coupons'>
        <div className='row align-items-center my-4'>
                     {/* <div className='col-md-3'>
                     <label class="col-md-12 label-text px-0 px-sm-3 invisible">invisible</label>
                      <select className="custome_input mb-0">
                          <option>All</option>
                          <option>2</option>
                          <option>3</option>
                      </select>
                      </div> */}
                      <div className='col-md-4'>
                      <label class="col-md-12 label-text px-0 px-sm-3">Enter Coupon Name</label>
                      <input type="text" className="custome_input mb-0 " id="CouponName"/>
                      </div>
                      <div className='col-md-4'>
                      <label class="col-md-12 label-text px-0 px-sm-3">Coupon Start Date</label>
                            <div class="col-md-12  px-0  px-sm-3">
                            <DatePicker
                                selected={this.state.startDate}
                                onChange={this.handleStartChange}
                                name="startDate"
                                dateFormat="dd/MM/yyyy"
                                placeholder="Coupon Start Date"
                                className="mb-0 datepickerIcon-hsp custome_input"
                                yearDropdownItemNumber={100}
                                    scrollableYearDropdown={true}
                                    showYearDropdown
                                    showMonthDropdown
                            />
                            </div>
                      </div>
                      <div className='col-md-4'>
                      <label class="col-md-12 label-text px-0 px-sm-3">Coupon End Date</label>
                        <div class="col-md-12  px-0  px-sm-3">
                          <DatePicker
                                selected={this.state.EndDate}
                                onChange={this.handleEndChange}
                                name="startDate"
                                dateFormat="dd/MM/yyyy"
                                placeholder="Coupon End Date"
                                className="mb-0 datepickerIcon-hsp custome_input"
                                yearDropdownItemNumber={100}
                                    scrollableYearDropdown={true}
                                    showYearDropdown
                                    showMonthDropdown
                            />
                        </div>
                      </div>
                      <div className="col-md-12 coupon_btn d-flex justify-content-end mt-2 flex-wrap">
                <input type="submit" className="formBtnBg mr-1" value="Display" onClick={() => this.searchData("Coupon")}/>
                <input type="submit" className="formBtnBg mr-1" value="Refresh" onClick={() => this.refresh()}/>
                <input type="submit" className="formBtnBg" value="Download" onClick={() => this.fileDownload()}/>
                </div>
                    </div>
                    <div class="table-responsive mb-3 custom_scroll">
                                        <table class="table custom_table">
                                            <thead>
                                            <tr>
                                                <th>Coupon Name</th>
                                                <th>Coupon Version</th>
                                                <th>Coupon Type</th>
                                                <th>Policies</th>
                                                <th>Coupons Allotted</th>
                                                <th>Used Coupon Count</th>
                                                <th>Balance Coupons Count</th>
                                                <th>Payment Date</th>
                                                <th>RGI Share Value</th>
                                                <th>Corp Share Value</th>
                                                <th>Paid Amount</th>
                                            </tr>
                                            </thead>
                                            {this.state.policyCouponData.length > 0 ?
                                              <tbody>
                                                {this.state.policyCouponData.map((item) => (
                                                  <tr>
                                                    <td>{item.couponName}</td>
                                                    <td>{item.couponVersion}</td>
                                                    <td>{item.couponType}</td>
                                                    <td>{item.policies}</td>
                                                    <td>{item.couponsAllotted}</td>
                                                    <td>{item.usedCouponCount} </td>
                                                    <td>{item.couponsAllotted - item.usedCouponCount}</td>
                                                    <td  className="text-nowrap">{item.createdOn}</td>
                                                    <td>₹ {parseFloat(item.rgiShareValue).toFixed(2)}</td>
                                                    <td>{item.corpShareValue == "" ? "NA" : "₹" + parseFloat(item.corpShareValue).toFixed(2)}</td>
                                                    <td>₹ {parseFloat(item.paidAmount).toFixed(2)}</td>
                                                  </tr>
                                                ))}
                                              </tbody>
                                            :
                                              <tbody>
                                              <tr>
                                                <td colspan={11}>No data Found</td>
                                              </tr>
                                              </tbody>
                                            }
                                            </table>
                    </div>
                    <div className="text-right">
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.policyCouponDataCount}
                        pageRangeDisplayed={10}
                        onChange={this.handlePageChange.bind(this)}
                      />
                    </div>
                    </div>
 }
 {status === 2 &&
                    <div className='group_policies'>
        <div className='row align-items-center my-4'>
                     {/* <div className='col-md-3'>
                     <label class="col-md-12 label-text px-0 px-sm-3 invisible">invisible</label>
                      <select className="custome_input mb-0">
                          <option>All</option>
                          <option>2</option>
                          <option>3</option>
                      </select>
                      </div> */}
                      <div className='col-md-4'>
                      <label class="col-md-12 label-text px-0 px-sm-3">Enter Policy Number</label>
                      <input type="text" className="custome_input mb-0 " id="PolicyNo"/>
                      </div>
                      <div className='col-md-4'>
                      <label class="col-md-12 label-text px-0 px-sm-3">Coupon Start Date</label>
                            <div class="col-md-12  px-0  px-sm-3">
                            <DatePicker
                                selected={this.state.startDate}
                                onChange={this.handleStartChange}
                                name="startDate"
                                dateFormat="dd/MM/yyyy"
                                placeholder="Coupon Start Date"
                                className="custome_input mb-0  datepickerIcon-hsp"
                                yearDropdownItemNumber={100}
                                    scrollableYearDropdown={true}
                                    showYearDropdown
                                    showMonthDropdown
                            />
                            </div>
                      </div>
                      <div className='col-md-4'>
                      <label class="col-md-12 label-text px-0 px-sm-3">Coupon End Date</label>
                        <div class="col-md-12  px-0  px-sm-3">
                        <DatePicker
                                selected={this.state.EndDate}
                                onChange={this.handleEndChange}
                                name="startDate"
                                dateFormat="dd/MM/yyyy"
                                placeholder="Coupon End Date"
                                className="custome_input mb-0  datepickerIcon-hsp"
                                yearDropdownItemNumber={100}
                                    scrollableYearDropdown={true}
                                    showYearDropdown
                                    showMonthDropdown
                            />
                        </div>
                      </div>
                      <div className="col-md-12 coupon_btn d-flex justify-content-end mt-2">
                <input type="submit" className="formBtnBg mr-1" value="Display" onClick={() => this.searchData("Policy")}/>
                <input type="submit" className="formBtnBg mr-1" value="Refresh" onClick={() => this.refresh()}/>
                <input type="submit" className="formBtnBg" value="Download" onClick={() => this.fileDownload()}/>
                </div>
                    </div>
                    <div class="table-responsive mb-3">
                                        <table class="table custom_table">
                                            <thead>
                                            <tr>
                                                <th>Policy Number</th>
                                                <th>Policy Version</th>
                                                <th>Employee ID</th>
                                                <th>Employee Name</th>
                                                <th>Coupon Name</th>
                                                <th>Coupons Allotted</th>
                                                <th>Coupons Used</th>
                                                <th>Coupons Balance</th>
                                                <th>Payment Date</th>
                                                <th>Date of Consultation</th>
                                                <th>Type of Consultation</th>
                                                <th>Consultation Fee</th>
                                                <th>RGI Share Value</th>
                                                <th>Corp Share Value</th>
                                                <th>Paid Amount</th>
                                            </tr>
                                            </thead>

                                              {this.state.policyCouponData.length > 0 ?
                                              <tbody>
                                                {this.state.policyCouponData.map((item) => (
                                                  <tr>
                                                    <td>{item.policyNumber}</td>
                                                    <td>{item.couponWithVersion}</td>
                                                    <td>{item.employeeId == "" ? "-" : item.employeeId}</td>
                                                    <td>{item.employeeName}</td>
                                                    <td>{item.couponName}</td>
                                                    <td>{item.couponsAllotted}</td>
                                                    <td>{item.couponUsed}</td>
                                                    <td>{item.couponsAllotted - item.couponUsed}</td>
                                                    <td  className="text-nowrap">{item.createdOn}</td>
                                                    <td  className="text-nowrap">{item.dateOfConsultation}</td>
                                                    <td>{item.typeOfConsultation}</td>
                                                    <td>₹ {parseFloat(item.consultaionFee).toFixed(2)}</td>
                                                    <td>₹ {parseFloat(item.rgiShareValue).toFixed(2)}</td>
                                                    <td>{item.corpShareValue == "" ? "NA" : "₹" + parseFloat(item.corpShareValue).toFixed(2)}</td>
                                                    <td>₹ {parseFloat(item.paidAmount).toFixed(2)}</td>
                                                  </tr>
                                                ))}
                                              </tbody>
                                              :
                                                <tbody>
                                                <tr>
                                                  <td colspan={15}>No data Found</td>
                                                </tr>
                                                </tbody>
                                              }
                                            </table>
                    </div>
                    <div className="text-right">
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.policyCouponDataCount}
                        pageRangeDisplayed={10}
                        onChange={this.handlePageChange.bind(this)}
                      />
                    </div>
                    </div>
                   
 }
  </div>
  <div className={"utilization_list " +(this.state.activeIndex === 1 ? "active" : "")}
          onClick={() => this.setActive(1)}>
              
              <ZeroUtilization></ZeroUtilization>
          </div>
          <div className={"error_list " +(this.state.activeIndex === 2 ? "active" : "")}
          onClick={() => this.setActive(2)}>
           <ErrorList></ErrorList>
          </div>
      </>
    )
 }
}