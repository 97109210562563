import React, { Component, Suspense } from "react";
import Edit from "../../image/icons/edit.png";
import tick from "../../image/icons/tick.png";
import Deactivate from "../../image/icons/deactvate.png";
import { apiCalling } from "../../apiService";
import $ from "jquery";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Header from "../../Reliance/header";
import Footer from "../../Reliance/footer";
const folderName = window.sessionStorage.getItem("homeName");
//const Header = React.lazy(() => import("../../" + folderName + "/header"));
//const Footer = React.lazy(() => import("../../" + folderName + "/footer"));

export default class HospitalMangementList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctorManagementData: [],
      totalCount: 0,
      activePage: 1,
      showMessage: "Waiting for data... ",
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
      });
    } else {
      offset = parseInt(pageNumber - 1) * 10;
      max = "10";
      this.setState({
        activePage: pageNumber,
        isLoading: "0",
      });
    }
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorLists",
        cmbSelect:
          $("#selectCategory").val() === "0" ? "" : $("#selectCategory").val(),
        txtInput: $("#hospitalTxt").val(),
        Offset: JSON.stringify(offset),
        max: max,
      }),
    };
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          doctorManagementData: data.doctorData,
          totalCount: data.totalCount,
        });
        $(".loader").hide();
      }
    });
  }

  componentDidMount() {
    this.getHospitalDetails();
  }

  getHospitalDetails() {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorLists",
        cmbSelect: "",
        txtInput: "",
        max: "10",
        Offset: "0",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        this.setState({
          doctorManagementData: data.doctorData,
          totalCount: data.totalCount,
        });
      }
      if (data.success === "0") {
        $(".loader").hide();
        this.setState({
          doctorManagementData: [],
          showMessage: data.errorMessage,
        });
      }
    });
  }

  changeText = () => {
    if ($("#selectCategory").val() !== "0") {
      document.getElementById("hospitalTxt").disabled = false;
      $("input[name=hospitalTxt]").focus();
    } else {
      document.getElementById("hospitalTxt").disabled = true;
    }
    document.getElementById("hospitalTxt").value = "";
  };
  searchHandler() {
    if ($("#selectCategory").val() !== "0" && $("#hospitalTxt").val() === "") {
      var t = document.getElementById("selectCategory");
      var selectedText = t.options[t.selectedIndex].text;
      alert(selectedText + " Required");
      $("#hospitalTxt").focus();
      return false;
    } else {
      const searchData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "doctorLists",
          cmbSelect:
            $("#selectCategory").val() === "0"
              ? ""
              : $("#selectCategory").val(),
          txtInput: $("#hospitalTxt").val(),
          Offset: "0",
          max: "10",
        }),
      };
      $(".loader").show();
      apiCalling(searchData).then((data) => {
        if (data.success === "1") {
          $("#paginationType").show();
          $(".loader").hide();
          this.setState({
            doctorManagementData: data.doctorData,
            totalCount: data.totalCount,
            activePage: 1,
          });
        }
        if (data.success === "0") {
          $("#paginationType").hide();
          $(".loader").hide();
          this.setState({
            doctorManagementData: [],
            showMessage: data.errorMessage,
          });
        }
      });
    }
  }

  statusChangeHandler(id, Status) {
    var x = false;
    if (Status === true) {
      x = window.confirm("Do you want to Disable this?");
    } else {
      x = window.confirm("Do you want to Enable this?");
    }
    if (x === true) {
      const SatusData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "doctorStatusChange",
          isActive: Status === true ? 0 : 1,
          doctorId: id,
        }),
      };
      $(".loader").show();
      apiCalling(SatusData).then((data) => {
        if (data.success === "1") {
          $(".loader").hide();
          alert("Status Changed Successfully");
          this.getHospitalDetails();
        }
        if (data.success === "0") {
          $(".loader").hide();
          alert(data.errorMessage);
        }
      });
    }
  }
  copyData(doctorId) {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "copyDoctor",
        doctorId: doctorId,
      }),
    };

    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        alert("Doctor data copied successfully");
        this.getHospitalDetails();
        // $(".loader").hide();
      } else {
        alert(data.errorMessage);
      }
    });
  }
  loginToPurpleHealth() {
    // alert("ssss")
    window.location.href =
      "https://www.imahospitals.com/videoSchedule/chatroomconsole";
    //  window.open("https://www.imahospitals.com/videoSchedule/chatroomconsole","_self")
  }
  loginToPulse() {
    //  alert("rrrr")
    // window.open("https://pulse.purplehealth.com/practiceManagement/loginFromPurple?","_self")
    window.location.href =
      "https://pulse.purplehealth.com/practiceManagement/loginFromPurple?";
  }
  render() {
    return (
      <div className="bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
            <div className="loader"></div>
            <div className="container mb-5">
              <div>
                <ul className="breadcrum-adminMangmnt">
                  <li>
                    <a href="/admin/dashboard">Dashboard</a>
                  </li>
                  <li>
                    <a href="/adminManagement/doctorManagementList/doctorManagement" className="current">
                      Doctor Management
                    </a>
                  </li>
                </ul>
              </div>
              <div className="head-hospMangmntList">Doctor Management</div>

              <div className="HosptlMangemntTable">
                <div className="AddNewHspbtnDiv">
                  <Link
                    to={{
                      pathname:
                        "/adminManagement/doctorManagementList/addDoctor",
                      state: { doctorId: "" },
                    }}
                    className="addnewhspbtn"
                  >
                    Add New Doctor
                  </Link>
                </div>
                {this.state.totalCount > 5 ? (
                  <div
                    className="paginationSection orderm-paginationSection pagination-HspList"
                    id="paginationType"
                  >
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.totalCount}
                      pageRangeDisplayed={10}
                      onChange={this.handlePageChange}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="tableHeader-HspMangemnt">
                  <div className="selectField-HspMangmnt">
                    <select
                      className="slecthsp slecthspMob"
                      id="selectCategory"
                      name="selectCategory"
                      onChange={() => this.changeText()}
                    >
                      <option value="0">Search In</option>
                      <option value="1">ID</option>
                      <option value="2">Doctor Name</option>
                      <option value="3">City</option>
                    </select>
                  </div>
                  <div className="selectField-HspMangmnt">
                    <input
                      type="text"
                      className="hspmngInput hspmngInputmob"
                      name="hospitalTxt"
                      id="hospitalTxt"
                      disabled
                      tabIndex="0"
                      onKeyPress={(event) => {
                        if (
                          event.key === "Enter" ||
                          event.key === "NumpadEnter"
                        ) {
                          event.preventDefault();
                          this.searchHandler();
                        }
                      }}
                    />
                  </div>
                  <div className="selectField-HspMangmnt">
                    <button
                      className="searchbtn-hspMng"
                      onClick={() => this.searchHandler()}
                    >
                      Search
                    </button>
                  </div>
                </div>
                {this.state.doctorManagementData.length > 0 ? (
                  <div>
                  <div className="table-responsive">
                    <table className="table table-bordered-hsp">
                      <thead>
                        <tr>
                          <th className="tablehead-hspmng" colSpan="2">
                            ID
                          </th>
                          <th className="tablehead-hspmng" colSpan="4">
                            Doctor Name{" "}
                          </th>
                          <th className="tablehead-hspmng" colSpan="3">
                            City
                          </th>
                          <th className="tablehead-hspmng" colSpan="4">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.doctorManagementData.map((data) => (
                          <tr key={data.doctorId}>
                            <td colSpan="2">{data.doctorId}</td>
                            <td colSpan="4">{data.doctorName}</td>
                            <td colSpan="3">{data.city}</td>
                            <td colSpan="4" className="nowrap">
                              <div className="ph_actn_img">
                                <Link
                                  to={{
                                    pathname:
                                      "/adminManagement/doctorManagementList/addDoctor",
                                    state: { doctorId: data.doctorId },
                                  }}
                                  className="mr-2 "
                                >
                                  <img
                                    src={Edit}
                                    alt=""
                                    width="18"
                                    className="img-fluid"
                                  />
                                </Link>
                                {/* <span><a href="/adminManagement/doctorManagementList/addDoctor" className="mr-2"><img src={Edit} alt="" width="18" className="img-fluid" /></a></span> */}
                                {data.enabled === true ? (
                                  <img
                                    src={tick}
                                    width="22"
                                    title="Click to Deactivate "
                                    className="mr-2 conf-cursorpointer"
                                    alt=""
                                    onClick={() => {
                                      this.statusChangeHandler(
                                        data.doctorId,
                                        true
                                      );
                                    }}
                                  ></img>
                                ) : (
                                  <img
                                    src={Deactivate}
                                    alt=""
                                    title="Click to Activate"
                                    className="mr-2 conf-cursorpointer"
                                    width="20"
                                    onClick={() => {
                                      this.statusChangeHandler(
                                        data.doctorId,
                                        false
                                      );
                                    }}
                                  ></img>
                                )}
                              </div>
                              <div>
                                <span className="ph_action_horizontal">
                                  <a
                                    href="#"
                                    className="listLink-hspmangmnt"
                                    onClick={() => this.copyData(data.doctorId)}
                                  >
                                    Copy Data
                                  </a>
                                  <a
                                    href="#"
                                    className="listLink-hspmangmnt"
                                    onClick={() => this.loginToPurpleHealth()}
                                  >
                                    Login To PurpleHealth
                                  </a>
                                </span>
                              </div>
                              {/* <div><span><a href="#" className="listLink-hspmangmnt" onClick={()=>this.loginToPurpleHealth()}>Login To PurpleHealth</a></span></div> */}
                              <div>
                                <span className="ph_action_horizontal">
                                  <a
                                    href="#"
                                    className="listLink-hspmangmnt"
                                    onClick={() => this.loginToPulse()}
                                  >
                                    Login To Pulse
                                  </a>
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>                    
                      </div>
                      {this.state.totalCount > 5 ? (
                      <div className="paginationSection orderm-paginationSection pagination-HspList pagination-HspListwidth">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalCount}
                          pageRangeDisplayed={10}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <p className="error-msg text-center mb-0">
                    {" "}
                    {this.state.showMessage}{" "}
                  </p>
                )}
              </div>
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
