import React, { Component, Suspense } from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import GroupPolicy from "./groupPolicy";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import exportFromJSON from "export-from-json";
import edit from "../image/theme/edit.png";
import close from "../image/theme/close_red.png";


import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Dashboardleftmenu from "./dashboardleftmenu";
import $ from "jquery";
import GroupReport from "./groupReport";
import { apiUrlRelianceFunction } from "../apiService";

const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class CouponListAdmin extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitHandle = this.submitHandle.bind(this);
    this.cancelButton = this.cancelButton.bind(this);
  }
  state = {
    txtInput: "",
    activeIndex: 0,
    activePage: 1,
    offset: 0,
    maxRow: "10",
    couponLists: [],
    dataCount: 0,
    couponCodeFullCount: 0,
    txtCouponCodeName: "",
    txtActiveFrom: new Date(),
    txtActiveTo: "",
    txtCreatedDate: new Date(),
    radType: "",
    cmbRGI: "",
    txtShareVal: "",
    couponCodeId: "",
    hidCouponCurrentVersion: "",
    CouponCodeDownloadLists: [],
  };

  expiryDate = (date) => {
    this.setState({
      txtActiveTo: date,
    });
  };
  validFrom = (date) => {
    this.setState({
      txtActiveFrom: date,
    });
  };
  createDate = (date) => {
    this.setState({
      txtCreatedDate: date,
    });
  };

  componentDidMount() {
    this.couponListing();

  }
  couponListingcoupon = () => {
    window.location.reload(false);
    $(".loader").show();
    const couponListing = {
      method: "POST",
      body: JSON.stringify({
        functionName: "rhCouponCode",
        subFunction: "getActiveCouponList",
        groupHospitalAdminId: window.sessionStorage.getItem("userId")
      }),
    };

    // console.log(couponListing.body);
    apiUrlRelianceFunction(couponListing).then((data) => {

      if (data.success === "1") {

        this.setState({
          couponList: data.couponData,
          dataCouponListCount: data.totalCount
        });
      } else if (data.success === "0") {
        this.setState({
          couponList: [],
          dataCouponListCount: 0
        });
      }
      $(".loader").hide();
    });
  };
  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  setActive = (index) => {
    this.setState({ activeIndex: index });
  };

  async handlePageChange(pageNumber) {
    await this.setState(
      {
        activePage: pageNumber,
        offset: (parseInt(pageNumber) - 1) * 10,
      },
      () => {
        this.couponListing();
      }
    );
  }

  changeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  refresh = (e) => {

    $(".accordion .accordion__button").removeAttr("aria-expanded", "true");
    $(".accordion .accordion__button").attr("aria-expanded", "false");
    $(".accordion .accordion__panel").attr("hidden", "hidden");

    $(".radioIsSingle").prop("checked", false);
    $(".radioRgi").prop("checked", false);

    this.setState({ txtInput: "", offset: 0, activeIndex: 0, activePage: 1 });
    this.handleInputState();
    this.couponListing();
    this.couponListingcoupon()


  };

  couponListingSearch = () => {
    let searchInput = this.state.txtInput;
    this.setState({ txtInput: "", offset: 0, activeIndex: 0, activePage: 1 });
    if (!searchInput || searchInput.length < 3) {
      alert("Please enter minimum 3 characters");
      return false;
    } else {
      this.couponListing();
    }
  };

  couponListing = () => {
    $(".loader").show();
    const couponListing = {
      method: "POST",
      body: JSON.stringify({
        functionName: "rhCouponCode",
        subFunction: "couponListing",
        txtInput: this.state.txtInput,
        userId: window.sessionStorage.getItem("userId"),
        max: this.state.maxRow,
        offset: this.state.offset,
      }),
    };

    // debugger;
    //console.log(couponListing.body);
    apiUrlRelianceFunction(couponListing).then((data) => {
      // debugger;

      if (data.success === "1") {
        
        this.setState({
          couponLists: data.couponData,
          dataCount: data.dataCount,
          couponCodeFullCount: data.couponCodeFullCount,
        });
        this.downloadData(data.couponCodeFullCount);
      } else if (data.success === "0") {
        this.setState({
          couponLists: [],
          dataCount: 0,
          couponCodeFullCount: 0,
        });
      }
      $(".loader").hide();
    });
  };

  downloadData = (dataCount) => {
    const couponListing = {
      method: "POST",
      body: JSON.stringify({
        functionName: "rhCouponCode",
        subFunction: "couponListDownloadHistory",
        txtInput: this.state.txtInput,
        userId: window.sessionStorage.getItem("userId"),
        max: 100000,
        offset: 0,
      }),
    };

    apiUrlRelianceFunction(couponListing).then((data) => {
      if (data.success === "1") {
        this.setState({
          CouponCodeDownloadLists: data.couponData,
        });
      } else if (data.success === "0") {
        this.setState({
          CouponCodeDownloadLists: [],
        });
      }
    });
  };
  RowCloapse(couponLists, parentCouponId, version) {
    // console.log("sizee--->"+couponLists.length)
    // debugger;
    const elementsIndex = couponLists.findIndex(
      (element) =>
        element.parentCouponId === parentCouponId 
        && 
        element.version.includes(version)
    );

    if (elementsIndex > -1) {
      couponLists[elementsIndex].isExpand =
        couponLists[elementsIndex].isExpand == 1 ? 0 : 1;
      return couponLists;
    } else {
      var indexArr=[]
      var Index = couponLists.findIndex(
        (element) =>
          //version.includes(element.version) 
          //&&
          element.parentCouponId === parentCouponId
      );
      if (Index > -1) {
        indexArr.push(Index)
        while(Index!==-1){
          Index = couponLists.findIndex(
            (element,index) =>
              //version.includes(element.version) 
              //&&
              element.parentCouponId === parentCouponId && index>Index
          );
          if (Index > -1) {
            indexArr.push(Index)
          }
        }
      }
      // console.log("indexArr--->"+indexArr)
      indexArr.forEach((val)=>{
        couponLists[val].subData = this.RowCloapse(
          couponLists[val].subData,
          parentCouponId,
          version
        )
      })
      // if (Index > -1) {
       
      // }

      return couponLists;
    }
  }

  getCouponDetails(couponId) {
    // console.log("Inside coupon details:" + couponId);
    const postData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "rhCouponCode",
        subFunction: "getCouponDetailsbyId",
        couponCodeId: couponId,
      }),
    };
    apiUrlRelianceFunction(postData).then((data) => {
      if (data.success === "1") {
        let isSingle = "";

        if (data.couponData[0].isSingle === true) {
          $("#radioSingle").prop("checked", true);
          isSingle = "Single";
        } else {
          $("#radioMultiple").prop("checked", true);
          isSingle = "Multiple";
        }
        if (data.couponData[0].shareType === "Amount") {
          $("#radioAmount").prop("checked", true);
        } else {
          $("#radioPercentage").prop("checked", true);
        }
        this.setState({
          txtCouponCodeName: data.couponData[0].couponCodeName,
          couponCodeId: data.couponData[0].couponCode,
          hidCouponCurrentVersion: data.couponData[0].couponVersion,
          txtActiveFrom: new Date(data.couponData[0].activeFrom),
          txtActiveTo: new Date(data.couponData[0].activeTo),
          txtCreatedDate: new Date(data.couponData[0].createdOn),
          radType: isSingle,
          cmbRGI: data.couponData[0].shareType,
          txtShareVal: data.couponData[0].shareValue,
        });

        $(".accordion .accordion__button").attr("aria-expanded", "true");
        $(".accordion .accordion__panel").removeAttr("hidden");
        $("#submitBtn").val("Update");
      }
    });
  }
  cancelButton() {
    this.refresh();
  }

  submitHandle() {
    let couponCodeId = this.state.couponCodeId;
    let hidCouponCurrentVersion = this.state.hidCouponCurrentVersion;
    let txtCouponCodeName = this.state.txtCouponCodeName;
    let validFrom = this.state.txtActiveFrom;
    let validTo = this.state.txtActiveTo;
    let createdOn = this.state.txtCreatedDate;
    let radType = this.state.radType;
    let cmbRGI = this.state.cmbRGI;
    let txtShareVal = this.state.txtShareVal;
    let userId = window.sessionStorage.getItem("userId");
    let remarks = "";

    // console.log(validFrom + "--" + validTo + "---" + createdOn + "--1");

    let subFunction = "saveCouponCode";

    if (!couponCodeId) {
    } else {
      subFunction = "updateCouponCode";
    }

    if (!txtCouponCodeName) {
      alert("Coupon Name Required.");
      $("#txtCouponCodeName").focus();
      return false;
    } else if (!txtShareVal) {
      alert("Share Value Required.");
      $("#txtShareVal").focus();
      return false;
    } else if (!String(txtShareVal).match(/^[0-9]+$/)) {
      alert("For RGI Share, allow numeric characters only");
      $("#txtShareVal").focus();
      return false;
    } else if (!cmbRGI) {
      alert("RGI Share Required.");
      return false;
    } else if (!validTo) {
      alert("Expiry Date Required.");
      $("#validTo").focus();
      return false;
    } else if (!radType) {
      alert("Remarks Required.");
      return false;
    } else if (
      !txtCouponCodeName ||
      !validFrom ||
      !validTo ||
      !radType ||
      !cmbRGI ||
      !txtShareVal ||
      !createdOn
    ) {
      alert("Please enter all mandatory fields");
      return false;
    } else {
      let txtActiveFrom = dateFormat(validFrom, "dd/mm/yyyy");
      let txtActiveTo = dateFormat(validTo, "dd/mm/yyyy");
      let txtCreatedDate = dateFormat(createdOn, "dd/mm/yyyy");

      // console.log(
      //   radType +
      //     "--" +
      //     txtActiveFrom +
      //     "--" +
      //     txtActiveTo +
      //     "--" +
      //     txtCreatedDate
      // );

      let curDate = dateFormat(new Date(), "yyyy-mm-dd");
      let cDt = dateFormat(createdOn, "yyyy-mm-dd");
      let fDt = dateFormat(validFrom, "yyyy-mm-dd");
      let tDt = dateFormat(validTo, "yyyy-mm-dd");
      // console.log(cDt + "--" + fDt + "---" + tDt + "---2");

      const created = new Date(cDt);
      const from = new Date(fDt);
      const to = new Date(tDt);

      // console.log(created + "--" + from + "---" + to + "---3");

      const today = new Date(curDate);
      // console.log(today + "--4");

      if (created < today) {
        alert("Created Date should be greater than or equal to Current Date.");
        $("#txtCreatedDate").focus();
        return false;
      } else if (from < created) {
        alert(
          "Valid From date should be greater than or equal to Created Date."
        );
        $("#txtActiveFrom").focus();
        return false;
      } else if (to < from) {
        alert(
          "Expiry Date should be greater than or equal to Valid From date."
        );
        $("#txtActiveTo").focus();
        return false;
      } else {
        const saveField = {
          method: "POST",
          body: JSON.stringify({
            functionName: "rhCouponCode",
            subFunction: subFunction,
            txtCouponCodeName: txtCouponCodeName,
            couponCodeId: couponCodeId,
            hidCouponCurrentVersion: hidCouponCurrentVersion,
            txtActiveFrom: txtActiveFrom,
            txtActiveTo: txtActiveTo,
            radType: radType,
            cmbRGI: cmbRGI,
            txtShareVal: txtShareVal,
            txtCreatedDate: txtCreatedDate,
            userId: userId,
            remarks: remarks,
          }),
        };
        // console.log("saveField--->"+saveField)
        apiUrlRelianceFunction(saveField).then((data) => {
          if (data.success === "1") {
            alert(data.successMessage);
            this.refresh();
          } else {
            alert(data.errorMessage);
          }
        });
      }
    }
  }

  handleRowCloapse = (parentCouponId, version) => {



    let couponLists = this.RowCloapse(
      this.state.couponLists,
      parentCouponId,
      version
    );

    this.setState({ couponLists });
  };

  handleCouponEdit = (couponId) => {
    this.getCouponDetails(couponId);
  };

  handleCouponActivateDeActivate = (couponId, couponStatus) => {
    this.activateDeActivateCoupon(couponId, couponStatus);
  };

  activateDeActivateCoupon(couponId, couponStatus) {
    // // console.log(
    //   "Inside coupon Activate DeActivate:" + couponId + "--" + couponStatus
    // );
    var status="activate";
    if(couponStatus=="Active")
    {
      status="deactivate";
    }
    if(window.confirm("Do you want to "+status)== true)
    {
    const postData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "rhCouponCode",
        subFunction: "activateDeActivateCoupon",
        couponCodeId: couponId,
        couponStatus: couponStatus,
      }),
    };
    apiUrlRelianceFunction(postData).then((data) => {
      if (data.success === "1") {
        this.couponListing();
      } else {
        alert(data.errorMessage);
      }
    });
  }
  }
  generateXLXData(CouponCodeDownloadLists) {
    var generateXlxData = [];
    /// alert(CouponCodeDownloadLists.length);
    for (var i = 0; i < CouponCodeDownloadLists.length; i++) {
      generateXlxData[i] = {
        CouponName: CouponCodeDownloadLists[i].couponName,
        Version: CouponCodeDownloadLists[i].version,
        RGIShare: CouponCodeDownloadLists[i].rgiShare,
        ShareValue: CouponCodeDownloadLists[i].shareValueNew,
        CreatedDate: CouponCodeDownloadLists[i].createdDate,
        ValidFrom: CouponCodeDownloadLists[i].validFromDate,
        ExpiryDate: CouponCodeDownloadLists[i].expiryDate,
        IsSingle:
          CouponCodeDownloadLists[i].isSingle == true ? "Single" : "Multiple",
        IsOldVersion:
          CouponCodeDownloadLists[i].isOldVersion == true ? "Yes" : "No",
        Status: CouponCodeDownloadLists[i].statusChange,
      };
    }
    const fileName = "exportCode";
    const exportType = "csv";
    this.exportXlsx(generateXlxData, fileName, exportType);
  }
  exportXlsx = (data, fileName, exportType) => {
    exportFromJSON({ data, fileName, exportType });
  };
  handleInputState = () => {
    $(".radioIsSingle").prop("checked", false);
    $(".radioRgi").prop("checked", false);
    this.setState({
      txtCouponCodeName: "",
      txtActiveFrom: new Date(),
      txtActiveTo: "",
      txtCreatedDate: new Date(),
      radType: "",
      cmbRGI: "",
      txtShareVal: "",
      couponCodeId: "",
      hidCouponCurrentVersion: "",
    });
    $("#submitBtn").val("Save");
  };

  renderCouponList(couponLists) {
    
    
    return couponLists.map((c,i) => [
      <div className="table_divbody">
        {c.srNo!==""&&<div className="table_full">
                      Coupon Name : <span>{c.couponName}</span>
                      </div>}
                      {/* {console.log("ffffffffffffffffff",c.version.toString().split("."))} */}
                      <div className={c.srNo===""&&c.version.toString().split(".").length>=2?"table_container subrowdiv":"table_container"}>
                      <div className="div_modify table_common">
                      <img src={edit}  onClick={() => this.handleCouponEdit(c.couponId)}/>
                            </div>
                            <div className="div_srno table_common">
                            {c.srNo}
                            </div>
                            <div className="div_versions table_common d-flex align-items-center" >
       
                            {c.subData.length > 0 && (
            <a
            
              onClick={() => this.handleRowCloapse(c.parentCouponId, c.version)}
            >
              {c.isExpand == 0 && <i class="span_circle">+</i>}
              {c.isExpand == 1 && <i class="span_circle">-</i>}
              
            </a>
          )}{" "}
          {c.version}
                            </div>
                            <div className="div_share table_common" >
                            {c.rgiShare}
                            </div>
                            <div className="div_share_value table_common">
                            {c.shareValue}
                            </div>
                            <div className="div_create table_common">
                            {c.createdDate}
                            </div>
                            <div className="div_validfrom table_common">
                            {c.validFromDate}
                            </div>
                            <div className="div_expirydate table_common">
                            {c.expiryDate}
                            </div>
                            <div className="div_remarks table_common">
                            {c.remarks}
                            </div>
                            <div className="div_stateschange table_common d-flex">
                            {c.statusChange}{" "}
          {c.showActiveButton == 1 && (
            <a
              className="ml-3"
              onClick={() =>
                this.handleCouponActivateDeActivate(c.couponId, c.statusChange)
              }
            >
              {c.statusChange == "Inactive" && (
                <i class="fa fa-check-circle" title="Click To Activate" />
              )}
              {c.statusChange == "Active" && (
                <img src={close} className="ml-3" title="Click To Deactivate"/>
                
              )}
            </a>
          )}{" "}

                            </div>
                      </div>
                    </div>,
      c.isExpand == 1 && this.renderCouponList(c.subData),
    ]);
  }

  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container">
          <div
            class="main-wrapper1 hspmain-wrapper-promo"
            id="row-col-width-leftmenu"
          >
            <div class="row">
              <div class="col-lg-1  d-sm-none d-lg-block d-md-none d-xl-block d-none">
                <Dashboardleftmenu></Dashboardleftmenu>
              </div>
              <div class="col-lg-11">
                <div className="dashboard-right dashbrd-rght-bottom-padng-hsp">
                  <div class="tabmain_list">
                    <a
                      className={
                        "list_tab " +
                        (this.state.activeIndex === 0 ? "active" : "")
                      }
                      onClick={() => this.setActive(0)}
                    >
                      Coupons{" "}
                    </a>
                    <a
                      className={
                        "list_tab " +
                        (this.state.activeIndex === 1 ? "active" : "")
                      }
                      onClick={() => this.setActive(1)}
                    >
                      <span class="videoSpan">Policies</span>
                    </a>
                    <a
                      className={
                        "list_tab " +
                        (this.state.activeIndex === 2 ? "active" : "")
                      }
                      onClick={() => this.setActive(2)}
                    >
                      Reports{" "}
                    </a>
                  </div>

                  <div
                    className={
                      "group_list " +
                      (this.state.activeIndex === 0 ? "active" : "")
                    }
                    onClick={() => this.setActive(0)}
                  >
                    <Accordion allowZeroExpanded="true">
                      <AccordionItem>
                        <AccordionItemHeading
                          onClick={() => this.handleInputState()}
                        >
                          <AccordionItemButton>Add Coupon</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div class="row px-2">
                            <div className="col-md-12">
                              <Link
                                to="/GroupAdmin/couponList"
                                href="#"
                                onClick={() => this.refresh()}
                              >
                                Go Back
                              </Link>
                            </div>
                            <div className="col-md-12">
                              <div class="col-md-6">
                                <div class="row my-2">
                                  <label class="col-md-12 label-text px-0 px-sm-3">
                                    Coupon Name <span>*</span>
                                  </label>
                                  <div class="col-md-12  px-0 px-sm-3">
                                    <input
                                      type="hidden"
                                      className="custome_input"
                                      name="couponCodeId"
                                      value={this.state.couponCodeId}
                                      onChange={this.handleInputChange}
                                    />
                                    <input
                                      type="hidden"
                                      className="custome_input"
                                      name="hidCouponCurrentVersion"
                                      value={this.state.hidCouponCurrentVersion}
                                      onChange={this.handleInputChange}
                                    />
                                    <input
                                      type="text"
                                      className="custome_input"
                                      name="txtCouponCodeName"
                                      value={this.state.txtCouponCodeName}
                                      onChange={this.handleInputChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div class="col-md-6">
                                <div class="row my-2">
                                  <label class="col-md-12 label-text px-0 px-sm-3">
                                    RGI Share <span>*</span>
                                  </label>
                                  <div class="col-md-12  px-0 px-sm-3 d-flex align-items-center">
                                    <input
                                      type="text"
                                      className="custome_input mb-0 border_change"
                                      name="txtShareVal"
                                      id="txtShareVal"
                                      value={this.state.txtShareVal}
                                      onChange={this.handleInputChange}
                                    />
                                    <div className="custome_rgi d-flex">
                                      <label className="">
                                        <input
                                          type="radio"
                                          name="cmbRGI"
                                          value="0"
                                          id="radioAmount"
                                          class="radioRgi"
                                          onChange={this.handleInputChange}
                                        />
                                        <span>₹</span>
                                      </label>
                                      <label className="">
                                        <input
                                          type="radio"
                                          name="cmbRGI"
                                          value="1"
                                          id="radioPercentage"
                                          class="radioRgi"
                                          onChange={this.handleInputChange}
                                        />
                                        <span>%</span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div class="col-md-6">
                                <div class="row my-2">
                                  <label class="col-md-12 label-text px-0 px-sm-3">
                                    Created Date <span>*</span>
                                  </label>
                                  <div class="col-md-12  px-0  px-sm-3">
                                    <DatePicker
                                      selected={this.state.txtCreatedDate}
                                      onChange={this.createDate}
                                      dateFormat="dd/MM/yyyy"
                                      placeholderText="Created Date"
                                      className="mb-0 datepickerIcon-hsp custome_input"
                                      name="txtCreatedDate"
                                      id="txtCreatedDate"
                                      value={this.state.txtCreatedDate}
                                      yearDropdownItemNumber={100}
                                      scrollableYearDropdown={true}
                                      showYearDropdown
                                      showMonthDropdown
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div class="col-md-6">
                                <div class="row my-2">
                                  <label class="col-md-12 label-text px-0 px-sm-3">
                                    Valid From <span>*</span>
                                  </label>
                                  <div class="col-md-12  px-0  px-sm-3">
                                    <DatePicker
                                      selected={this.state.txtActiveFrom}
                                      onChange={this.validFrom}
                                      dateFormat="dd/MM/yyyy"
                                      placeholderText="Valid From "
                                      className="mb-0 datepickerIcon-hsp custome_input"
                                      name="txtActiveFrom"
                                      id="txtActiveFrom"
                                      value={this.state.txtActiveFrom}
                                      yearDropdownItemNumber={100}
                                      scrollableYearDropdown={true}
                                      showYearDropdown
                                      showMonthDropdown
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div class="col-md-6">
                                <div class="row my-2">
                                  <label class="col-md-12 label-text px-0 px-sm-3">
                                    Expiry Date <span>*</span>
                                  </label>
                                  <div class="col-md-12  px-0  px-sm-3">
                                    <DatePicker
                                      selected={this.state.txtActiveTo}
                                      onChange={this.expiryDate}
                                      name="txtActiveTo"
                                      dateFormat="dd/MM/yyyy"
                                      placeholderText="Expiry Date"
                                      className="mb-0 datepickerIcon-hsp custome_input"
                                      id="txtActiveTo"
                                      value={this.state.txtActiveTo}
                                      yearDropdownItemNumber={100}
                                      scrollableYearDropdown={true}
                                      showYearDropdown
                                      showMonthDropdown
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div class="col-md-6">
                                <div class=" my-2 d-flex align-items-center">
                                  <label class=" label-text px-0 px-sm-3 mb-0 d-flex align-items-center">
                                    <input
                                      type="radio"
                                      name="radType"
                                      id="radioSingle"
                                      class="radioIsSingle"
                                      value="Single"
                                      onChange={this.handleInputChange}
                                    />
                                    <span className="ml-2 label-text">
                                      Single
                                    </span>
                                  </label>
                                  <label class=" label-text px-0 px-sm-3 mb-0 d-flex align-items-center">
                                    <input
                                      type="radio"
                                      name="radType"
                                      id="radioMultiple"
                                      class="radioIsSingle"
                                      value="Multiple"
                                      onChange={this.handleInputChange}
                                    />
                                    <span className="ml-2 label-text">
                                      Multiple
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 mt-2">
                              <input
                                type="submit"
                                className="border_btn"
                                value="Save"
                                id="submitBtn"
                                onClick={this.submitHandle}
                              />
                              <input
                                type="button"
                                className="border_btn btn_basics"
                                value="Cancel"
                                id="cancelBtn"
                                onClick={this.cancelButton}
                              />
                            </div>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                    <div className="col-md-12">
                      <div className="row align-items-center my-4">
                        <div className="col-md-4 d-flex align-items-center">
                          <label className="custom_label">Coupon Name</label>
                          <input
                            type="text"
                            className="custome_input border_change mb-0"
                            id="txtInput"
                            value={this.state.txtInput}
                            onInput={this.changeHandler}
                            name=""
                          />
                        </div>
                        <div className="col-md-8 coupon_btn d-flex align-items-center flex-wrap">
                          <input
                            type="submit"
                            className="formBtnBg mr-3"
                            value="Search"
                            onClick={() => this.couponListingSearch()}
                          />
                          <input
                            type="submit"
                            className="formBtnBg mr-3"
                            value="Refresh"
                            onClick={() => this.refresh()}
                          />
                          <input
                            type="submit"
                            className="formBtnBg"
                            value="Download History"
                            onClick={() =>
                              this.generateXLXData(
                                this.state.CouponCodeDownloadLists
                              )
                            }
                          />
                          <p className="mb-0 ml-3">
                            <b>
                              Total Coupon Count :{" "}
                              {this.state.couponCodeFullCount}
                            </b>
                          </p>
                        </div>
                        {/* <div className="col-md-3">
                          <p className="mb-0">
                            <b>Total Coupon Count : {this.state.dataCount}</b>
                          </p>
                        </div> */}
                      </div>
                    </div>
                    {/* <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th>Modify</th>
                            <th>Sr.No.</th>
                            <th>Coupon Name</th>
                            <th>Version</th>
                            <th>RGI Share</th>
                            <th>Share Value </th>
                            <th>Created Date</th>
                            <th>Valid From Date </th>
                            <th>Expiry Date</th>
                            <th>Remarks</th>
                            <th>Status Change</th>
                          </tr>
                        </thead>
                        <tbody style={{ fontSize: "12px" }}>
                          {this.renderCouponList(this.state.couponLists)}
                        </tbody>
                      </table>
                    </div> */}
                    {(this.state.couponCodeFullCount!==0)?
                    (
<>
<div className="table_div">
                      <div className="table_divhead">
                        
                            <div className="div_modify table_common">
                            Modify
                            </div>
                            <div className="div_srno table_common">
                          Sr.No.
                            </div>
                            <div className="div_versions table_common">
                              Version
                            </div>
                            <div className="div_share table_common">
                            RGI Share
                            </div>
                            <div className="div_share_value table_common">
                            Share Value
                            </div>
                            <div className="div_create table_common">
                            Created Date
                            </div>
                            <div className="div_validfrom table_common">
                            Valid From Date
                            </div>
                            <div className="div_expirydate table_common">
                            Expiry Date
                            </div>
                            <div className="div_remarks table_common">
                            Remarks
                            </div>
                            <div className="div_stateschange table_common">
                            Status Change
                            </div>
                      </div>
                  <div className="table_divcontainer">
                  {this.renderCouponList(this.state.couponLists)}
                  
                    
                    </div>
                    </div>
                    <div className="text-right mt-3">
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.dataCount}
                        pageRangeDisplayed={10}
                        prevPageText={"Previous"}
                        nextPageText={"Next"}
                        onChange={(pageNumber) =>
                          this.handlePageChange(pageNumber)
                        }
                      />
                    </div>
</>
                    )
                  :
                  ""}
                    
                    
                  </div>
                  <div
                    className={
                      "group_plicy " +
                      (this.state.activeIndex === 1 ? "active" : "")
                    }
                    onClick={() => this.setActive(1)}
                  >
                    <GroupPolicy></GroupPolicy>
                  </div>
                  <div
                    className={
                      "report_list " +
                      (this.state.activeIndex === 2 ? "active" : "")
                    }
                    onClick={() => this.setActive(2)}
                  >
                    <GroupReport></GroupReport>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
