import React, { Component } from 'react';
import "../css/purpleresponsive.css"
import "../css/style.css"
import about from "../image/theme/about.png"
import bckgrndimgabtus from "../image/theme/Union2.png"
import reviewer1 from "../image/theme/reviewer1.png"
import reviewer2 from "../image/theme/reviewer-1.png"
import reviewer3 from "../image/theme/reviewer-2.png"
import nagarjuna from "../image/theme/nagarjuna.png"
import bala from "../image/theme/bala.png"
import Header from "../Reliance/header"
import Footer from "../Reliance/footer"
import ContactForm from "../home/contactus-form"

export default class AboutUs extends Component {
    render() {
        return (
            <div className="purpleWrap bg-clr-wthHead">
                <Header></Header>
            <div className="fullabtus">
      <div className="aboutus">
          <div className="container ">
              <div className="row">
                <div className="col-lg-6">
                    <span className="aboutusvedioimage"><img src={about} class="about-img side-image"></img></span>
                </div>
                <div className="col-lg-6 right-text-about">
                    <div className="telehealthfeaturehead ">About us</div>
                    <p className="aboutuspara font-20">Purple Health's mission to bring clarity, efficiency, accessiblity and affordability to the healthcare space with the use of modern digital health technologies.</p>
                    <br></br>
                    <p className="aboutuspara font-20">Our focus in ultimately helping patients and health providers improve healthcare outcomes with the technologies that we offer.</p>
                </div>
              </div>
          </div>
      </div>
      <div className="container mt-5">
      <div className="telehealthfeaturehead">Our team</div>
       <div className="row bckgrndreviewer">
          <div className="col-md-2 reviewimg">
              <span className="reviewpicture"><img src={nagarjuna}></img></span>
              <div className="reviewname">Dr. Nagarjun<br></br>Mishra</div>
          </div>
          <div className="col-md-10">
              <p className="reviewerrightpara">Dr. Nagarjun Mishra is the CEO and co-founder of Purple Health. An alumnus of Hyderabad Public School, Dr. Mishra is a trained Emergency Physician from Apollo Hospitals. He comes with a wealth of experience in the Healthcare Investments, Technology, Insurance and Wellness sectors. Prior to joining Purple Health, Dr. Mishra was managing healthcare investments at Tata Industries Limited, the new ventures arm of the Tata Group. Dr. Mishra also has formed and led the Wellness teams of Reliance General Insurance and ICICI Lombard besides being the founding member of one of India's first healthcare portals, Total Health Needs. </p>
          </div>
       </div>
       <div className="row bckgrndreviewer">
          <div className="col-md-2 reviewimg">
              <span className="reviewpicture"><img src={reviewer1}></img></span>
              <div className="reviewname">Vikram<br></br> Nair</div>
          </div>
          <div className="col-md-10">
              <p className="reviewerrightpara">Vikram is a co-founder as well as Managing Director of Purple Health. He has has 20 years of experience working with IT companies in leadership, strategy and product design roles in North America and in India. As an entrepreneur he has founded multiple start-ups and believes in the ability of technology to change the world.</p>
          </div>
       </div>
       <div className="row bckgrndreviewer">
          <div className="col-md-2 reviewimg">
              <span className="reviewpicture"><img src={reviewer2}></img></span>
              <div className="reviewname">Mini <br></br>Balaraman</div>
          </div>
          <div className="col-md-10">
              <p className="reviewerrightpara">Mini is a co-founder as well as the President & COO of PurpleHealth.com. She is responsible for day-to-day running of operations and works with hospitals and clinic administrators to ensure that PurpleHealth.com customers get great service. Mini returned to India after 14 years in the US, having worked at Blue Shield of California and Sun Microsystems(now Oracle) in various technical roles, from software development to Business Intelligence. She combines a unique blend of client-facing experience and strong technical knowledge. Mini holds a Master's degree in Computer Applications.</p>
          </div>
       </div>
       <div className="row bckgrndreviewer">
          <div className="col-md-2 reviewimg">
              <span className="reviewpicture"><img src={reviewer3}></img></span>
              <div className="reviewname">Prakash<br></br>Sathyapalan</div>
          </div>
          <div className="col-md-10">
              <p className="reviewerrightpara">As CTO and co-founder, Prakash is responsible for all technology and development for PurpleHealth.com and is focused on making PurpleHealth.com a world class product. Before returning to India he spent over 14 years in California where he used to work for Stubhub(an eBay company) as a technology manager. Prior to Stubhub he worked for IBM (Cognos Corporation) and SAS Institute. He has over 16 years experience in software development, design and Business Intelligence. Prakash holds a Master's degree in Computer Science.</p>
          </div>
       </div>
       <div className="row bckgrndreviewer">
          <div className="col-md-2 reviewimg">
              <span className="reviewpicture"><img src={bala}></img></span>
              <div className="reviewname">Balaji <br></br>Jagannathan</div>
          </div>
          <div className="col-md-10">
              <p className="reviewerrightpara">Bala is a Board Observer and Advisor for Purple Health. He has over two decades of proven leadership experience in starting-up businesses, building teams and growing revenues. He has had a big impact in the growth stories of Mindcraft, Sybase, CA, SGI, and SAS. He has been the driving force behind three recent startups, Iaito Infotech, Covacsis & jusTap! Bala is also Founder & CEO at Katabole. </p>
          </div>
       </div>
      </div>
      </div>
            {/* <ContactForm></ContactForm> */}
            <Footer></Footer>
        </div>

      
  
    )
  }
}
