import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { Multiselect } from 'react-autocomplete-npm';
import exportFromJSON from 'export-from-json'
import * as XLSX from "xlsx";
import date from 'date-and-time';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { apiUrlRelianceFunction } from "../apiService";
const userId = window.sessionStorage.getItem("userId");
const groupHospitalAdminId = window.sessionStorage.getItem("userId");
export default class GroupPolicy extends Component {
  constructor(props) {
    super(props);
    this.displayCouponData = [];
    this.selPolicyNo = [];
    this.selPolicyType = [];
    this.selPolicyName = [];
    this.selPolicyStartDate = [];
    this.selPolicyEndDate = [];
    this.selDateOfBirth = [];
    this.handleClick = this.handleClick.bind(this);
    this.resetfile = this.resetfile.bind(this);
    this.state = {
      txtInput: "",
      cmbStatus: "0",
      activeIndex: 1,
      activePage: 1,
      offset: "0",
      maxRow: "10",
      policyCouponLists: [],
      policyCouponDownloadLists: [],
      dataCount: 0,
      policyCouponCodeFullCount: 0,
      dateBirth: null,
      couponList: [],
      dataCouponListCount: 0,
      selCouponId: [],
      couponDetail: {},
      showCouponData: this.displayCouponData,
      couponId: 0,
      policyNoList: [],
      selectedPolicyNo: null,
      policyType: "",
      policyName: "",
      policyStartDate: "",
      policyEndDate: "",
      dateOfBirth: "",
      showDateofBirth: false,
      showPolicydata: false,
      isVerifyPolicyData: false,
      finalPolicyNo: this.selPolicyNo,
      finalPolicyType: this.selPolicyType,
      finalPolicyName: this.selPolicyName,
      finalPolicyStartDate: this.selPolicyStartDate,
      finalPolicyEndDate: this.selPolicyEndDate,
      finalDateOfBirth: this.selDateOfBirth,
      txtPolicyName: new Date().getTime(),
      existCouponId: 0,
      existPolicyNumCoupon: false,
      searchValue: false,
      checkedFirstTimeCoupon: [],
      filename: "",
      filetype: ""
    };
    this.dateChange = this.dateChange.bind(this);
    this.cancelButton = this.cancelButton.bind(this);
    this.couponHandler = this.couponHandler.bind(this);
    this.getCoupon = this.getCoupon.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.addPolicyData = this.addPolicyData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.removeCouponCodeRow = this.removeCouponCodeRow.bind(this);
  }

  cancelButton() {
    this.displayCouponData = [];
    this.selPolicyNo = [];
    this.selPolicyType = [];
    this.selPolicyName = [];
    this.selPolicyStartDate = [];
    this.selPolicyEndDate = [];
    this.selDateOfBirth = [];
    this.setState({
      txtInput: "",
      cmbStatus: "0",
      activeIndex: 1,
      activePage: 1,
      offset: "0",
      maxRow: "10",
      dataCount: 0,
      policyCouponCodeFullCount: 0,
      dateBirth: null,
      dataCouponListCount: 0,
      selCouponId: [],
      couponDetail: {},
      showCouponData: this.displayCouponData,
      couponId: 0,
      selectedPolicyNo: null,
      policyType: "",
      policyName: "",
      policyStartDate: "",
      policyEndDate: "",
      dateOfBirth: "",
      showDateofBirth: false,
      showPolicydata: false,
      isVerifyPolicyData: false,
      finalPolicyNo: this.selPolicyNo,
      finalPolicyType: this.selPolicyType,
      finalPolicyName: this.selPolicyName,
      finalPolicyStartDate: this.selPolicyStartDate,
      finalPolicyEndDate: this.selPolicyEndDate,
      finalDateOfBirth: this.selDateOfBirth,
      txtPolicyName: new Date().getTime(),
      existCouponId: 0,
      existPolicyNumCoupon: false,
      searchValue: false,
      checkedFirstTimeCoupon: [],
      filename: "",
      filetype: ""

    })
    $(".accordion .accordion__button").attr("aria-expanded", "false");
    $(".accordion .accordion__panel").attr("hidden", "");
    //window.location.reload()
  }

  async onApplyPolicy() {

    if (this.state.selCouponId.length === 0) {
      alert("Select atleast one coupon code.");
    }
    else if (this.state.finalPolicyNo.length === 0 && this.state.filename === "") {
      alert("Policy number required.");
    }
    else if (this.state.finalPolicyNo.length !== 0) {
      var radType = [];
      var chkFirstTime = [];
      var txtTimes = [];
      var txtCorporateValue = [];
      var cmbCorporateShare = [];
      for (var i = 0; i < document.getElementsByClassName('radCouponUsageType').length; i++) {
        if (document.getElementsByClassName('radCouponUsageType')[i].checked) {
          radType.push(document.getElementsByClassName('radCouponUsageType')[i].value)
        }
      }
      for (var i = 0; i < document.getElementsByClassName('chkFirstTime').length; i++) {
        if (document.getElementsByClassName('chkFirstTime')[i].checked) {
          chkFirstTime.push(document.getElementsByClassName('chkFirstTime')[i].value)
        } else {
          chkFirstTime.push("");
        }
      }
      for (var i = 0; i < document.getElementsByClassName('txtNoOfTimes').length; i++) {

        txtTimes.push(document.getElementsByClassName('txtNoOfTimes')[i].value)

      }
      for (var i = 0; i < document.getElementsByClassName('txtCorporateShare').length; i++) {

        txtCorporateValue.push(document.getElementsByClassName('txtCorporateShare')[i].value)

      }
      for (var i = 0; i < document.getElementsByClassName('radShareType').length; i++) {
        if (document.getElementsByClassName('radShareType')[i].checked) {
          cmbCorporateShare.push(document.getElementsByClassName('radShareType')[i].value)
        }
      }
      const savePolicyCoupon = {
        method: "POST",
        body: JSON.stringify({
          "functionName": "rhCouponCode",
          "subFunction": "saveCouponPolicyDetails",
          "excelUploadData": "",
          "hidFileCsv": "",
          "userId": userId,
          "couponCount": this.state.selCouponId.length,
          "policyCount": this.state.finalPolicyNo.length,
          "hidPolicyNumber": this.state.finalPolicyNo,
          "hidCouponId": this.state.selCouponId,
          "txtPolicyName": this.state.txtPolicyName,
          "file1": "",
          "chkFirstTime": chkFirstTime,
          "radType": radType,
          "txtTimes": txtTimes,
          "hidPolicyStartDate": this.state.finalPolicyStartDate,
          "hidPolicyEndDate": this.state.finalPolicyEndDate,
          "txtCorporateValue": txtCorporateValue,
          "cmbCorporateShare": cmbCorporateShare,
          "hidDob": this.state.finalDateOfBirth,
          "hidPolicyName": this.state.finalPolicyName,
          "hidPolicyType": this.state.finalPolicyType,
          "groupId": groupHospitalAdminId
        }),
      };
      // console.log("save---->" + savePolicyCoupon.body)
      await apiUrlRelianceFunction(savePolicyCoupon).then((data) => {

        if (data.success === "1") {
          alert(data.successMessage);
          this.setState({activeIndex:1})
          this.refresh();
          $(".close-icon").click();
          $(".loader").show();
        } else {
          alert(data.errorMessage);

        }
      });
      this.displayCouponData = [];
      this.selPolicyNo = [];
      this.selPolicyType = [];
      this.selPolicyName = [];
      this.selPolicyStartDate = [];
      this.selPolicyEndDate = [];
      this.selDateOfBirth = [];
      this.setState({
        txtInput: "",
        cmbStatus: "0",
        activeIndex: 1,
        activePage: 1,
        offset: "0",
        maxRow: "10",
        dataCount: 0,
        policyCouponCodeFullCount: 0,
        dateBirth: null,
        dataCouponListCount: 0,
        selCouponId: [],
        couponDetail: {},
        showCouponData: this.displayCouponData,
        couponId: 0,
        selectedPolicyNo: null,
        policyType: "",
        policyName: "",
        policyStartDate: "",
        policyEndDate: "",
        dateOfBirth: "",
        showDateofBirth: false,
        showPolicydata: false,
        isVerifyPolicyData: false,
        finalPolicyNo: this.selPolicyNo,
        finalPolicyType: this.selPolicyType,
        finalPolicyName: this.selPolicyName,
        finalPolicyStartDate: this.selPolicyStartDate,
        finalPolicyEndDate: this.selPolicyEndDate,
        finalDateOfBirth: this.selDateOfBirth,
        txtPolicyName: new Date().getTime(),
        existCouponId: 0,
        existPolicyNumCoupon: false,
        searchValue: false,
        checkedFirstTimeCoupon: [],
        filename: "",
        filetype: ""

      })
    }
    else if (this.state.filename !== "") {

      var radType = [];
      var chkFirstTime = [];
      var txtTimes = [];
      var txtCorporateValue = [];
      var cmbCorporateShare = [];
      for (var i = 0; i < document.getElementsByClassName('radCouponUsageType').length; i++) {
        if (document.getElementsByClassName('radCouponUsageType')[i].checked) {
          radType.push(document.getElementsByClassName('radCouponUsageType')[i].value)
        }
      }
      for (var i = 0; i < document.getElementsByClassName('chkFirstTime').length; i++) {
        if (document.getElementsByClassName('chkFirstTime')[i].checked) {
          chkFirstTime.push(document.getElementsByClassName('chkFirstTime')[i].value)
        } else {
          chkFirstTime.push("");
        }
      }
      for (var i = 0; i < document.getElementsByClassName('txtNoOfTimes').length; i++) {

        txtTimes.push(document.getElementsByClassName('txtNoOfTimes')[i].value)

      }
      for (var i = 0; i < document.getElementsByClassName('txtCorporateShare').length; i++) {

        txtCorporateValue.push(document.getElementsByClassName('txtCorporateShare')[i].value)

      }
      for (var i = 0; i < document.getElementsByClassName('radShareType').length; i++) {
        if (document.getElementsByClassName('radShareType')[i].checked) {
          cmbCorporateShare.push(document.getElementsByClassName('radShareType')[i].value)
        }
      }

      var successCount = 0
      var errorCount = 0
      var totalCount = 0
      var noOfRows = 0

      var f = this.state.file;
      var policyNumbers = []
      var dobs = []
      var policyStartDates = []
      var policyEndDates = []
      var employeeIds = []
      var policyTypes = []
      var policyType = "";
      $(".loader").show();
      const reader = new FileReader();
      reader.onload = (evt) => {
        // evt = on_file_select event
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        //const xlsdata = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        //console.log("xlsdata===="+xlsdata)
        let arr = XLSX.utils.sheet_to_json(ws, {
          raw: false,
          dateNF: "DD-MM-YYYY",
          header: 1,
          defval: ""
        });
        noOfRows = arr.length
        totalCount = arr.length - 1
        arr.forEach((exceldata, index) => {
          if (index === 0) {
            if (exceldata[4]) {
              policyType = "Corporate"
            } else {
              policyType = 'Retail'
            }
          }
          if (index !== 0) {

            // console.log("data ", exceldata)
            // Policy Number Checking
            policyTypes.push(policyType)
            policyNumbers.push(exceldata[0])
            dobs.push(exceldata[1])
            policyStartDates.push(exceldata[2])
            policyEndDates.push(exceldata[3])
            if (policyType === 'Corporate') {
              employeeIds.push(exceldata[4])
            } else {
              employeeIds.push("")
            }

          }
          if (index === totalCount) {
            var fileUpload = {
              method: "POST",
              body: JSON.stringify({
                "functionName": "RHUpload",
                "subFunction": "fileUpload",
                "couponIds": this.state.selCouponId,
                "radType": radType,
                "chkFirstTime": chkFirstTime,
                "txtTimes": txtTimes,
                "txtCorporateValue": txtCorporateValue,
                "cmbCorporateShare": cmbCorporateShare,
                "userId": userId,
                "policyNumbers": policyNumbers,
                "dobs": dobs,
                "policyStartDates": policyStartDates,
                "policyEndDates": policyEndDates,
                "employeeIds": employeeIds,
                "policyTypes": policyTypes,
                "totalCount": totalCount,
                "txtPolicyName": this.state.txtPolicyName,
                "csvFileName": this.state.filename
              }),
            };
            // console.log(fileUpload.body)
            apiUrlRelianceFunction(fileUpload).then((data) => {
              // console.log(data)
              this.displayCouponData = [];
              this.selPolicyNo = [];
              this.selPolicyType = [];
              this.selPolicyName = [];
              this.selPolicyStartDate = [];
              this.selPolicyEndDate = [];
              this.selDateOfBirth = [];
              this.setState({
                txtInput: "",
                cmbStatus: "0",
                activeIndex: 1,
                activePage: 1,
                offset: "0",
                maxRow: "10",
                dataCount: 0,
                policyCouponCodeFullCount: 0,
                dateBirth: null,
                dataCouponListCount: 0,
                selCouponId: [],
                couponDetail: {},
                showCouponData: this.displayCouponData,
                couponId: 0,
                selectedPolicyNo: null,
                policyType: "",
                policyName: "",
                policyStartDate: "",
                policyEndDate: "",
                dateOfBirth: "",
                showDateofBirth: false,
                showPolicydata: false,
                isVerifyPolicyData: false,
                finalPolicyNo: this.selPolicyNo,
                finalPolicyType: this.selPolicyType,
                finalPolicyName: this.selPolicyName,
                finalPolicyStartDate: this.selPolicyStartDate,
                finalPolicyEndDate: this.selPolicyEndDate,
                finalDateOfBirth: this.selDateOfBirth,
                txtPolicyName: new Date().getTime(),
                existCouponId: 0,
                existPolicyNumCoupon: false,
                searchValue: false,
                checkedFirstTimeCoupon: [],
                filename: "",
                filetype: ""
        
              })
            });
          }
        });

      };

      reader.readAsBinaryString(f);
      
    }
  }
  removeCouponCodeRow(policyNum) {
    var arr = this.selPolicyNo;
    var arr2 = this.selPolicyType;
    var arr3 = this.selPolicyName;
    var arr4 = this.selPolicyStartDate;
    var arr5 = this.selPolicyEndDate;
    var arr6 = this.selDateOfBirth;
    for (var i = 0; i < arr.length; i++) {

      if (parseInt(arr[i]) === parseInt(policyNum)) {
        // console.log("inside if")
        arr.splice(i, 1);
        arr2.splice(i, 1);
        arr3.splice(i, 1);
        arr4.splice(i, 1);
        arr5.splice(i, 1);
        arr6.splice(i, 1);

      }

    }


    // console.log("length--->" + arr.length)
    // console.log("disp length--->" + arr2.length)
    this.setState({
      finalPolicyNo: this.arr,
      finalPolicyType: this.arr2,
      finalPolicyName: this.arr3,
      finalPolicyStartDate: this.arr4,
      finalPolicyEndDate: this.arr5,
      finalDateOfBirth: this.arr6,

    });
  }

  existPolicyNumCoupon = (couponCodeId, selectedPolicyNo) => {
    var existPolicyNumCoupon = {
      method: "POST",
      body: JSON.stringify({
        "functionName": "rhCouponCode",
        "subFunction": "checkCouponPolicyExist",
        "empId": "",
        "couponCodeId": couponCodeId,
        "policyNumber": selectedPolicyNo
      }),
    };
    // console.log(existPolicyNumCoupon.body);
    apiUrlRelianceFunction(existPolicyNumCoupon).then((data) => {
      if (data.success === "1") {
        // console.log("dataExist---->" + data.dataExist)
        if (data.dataExist === 1) {
          // console.log("inside if exist fn")

          this.setState({
            existCouponId: couponCodeId,
            existPolicyNumCoupon: true
          })

        }
      }
    });
  }

  addPolicyData() {


    if (this.state.existPolicyNumCoupon === true) {
      alert("Policy Number " + this.state.selectedPolicyNo.value + " with coupon code " + document.getElementById('spnCouponName' + this.state.existCouponId).innerHTML + " already exists")
    }

    else if (this.state.isVerifyPolicyData === true) {

      this.selPolicyNo.push(this.state.selectedPolicyNo.value);
      this.selPolicyType.push(this.state.policyType);
      this.selPolicyName.push(this.state.policyName);
      this.selPolicyStartDate.push(this.state.policyStartDate);
      this.selPolicyEndDate.push(this.state.policyEndDate);
      this.selDateOfBirth.push(this.state.dateOfBirth);
      this.setState({
        finalPolicyNo: this.selPolicyNo,
        finalPolicyType: this.selPolicyType,
        finalPolicyName: this.selPolicyName,
        finalPolicyStartDate: this.selPolicyStartDate,
        finalPolicyEndDate: this.selPolicyEndDate,
        finalDateOfBirth: this.selDateOfBirth,
        selectedPolicyNo: null,
        policyType: "",
        policyName: "",
        policyStartDate: "",
        policyEndDate: "",
        dateOfBirth: "",
        showDateofBirth: false,
        showPolicydata: false,
        isVerifyPolicyData: false

      });
      //console.log("finalPolicyNo size--->"+this.state.finalPolicyNo.length)
      //console.log("selPolicyNo size--->"+this.selPolicyNo.length)
    } else {
      if (this.state.policyType === "") {
        alert("Please Select Policy No.")
      }
      else if (this.state.policyType === "Retail") {
        alert("Please verify Date of Birth")
      }

    }

  }
  onDateSelect(date) {
    this.setState({
      dateBirth: date
    })
  }
  dateChange() {
    var date = this.state.dateBirth
    var dt = ('0' + date.getDate()).slice(-2) + "/" + ('0' + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
    // console.log(dt + "===" + this.state.dateOfBirth);
    if (dt === this.state.dateOfBirth) {
      this.setState({
        isVerifyPolicyData: true,
        showPolicydata: true
      })
    } else {
      alert("Invalid Date of Birth");
    }

  }
  handleClick(e) {
    e.stopPropagation();
    e.preventDefault();
    var file = e.target.files[0];
    // console.log(file);
    const excelmimetype = "application/vnd.ms-excel";

    if (file.type !== excelmimetype) {
      alert("Please attach xls file.")
      this.setState({ file: "", filename: "", filetype: "" });
      return false;
    } else {
      this.setState({ file, filename: file.name, filetype: file.type });
    }

  }
  resetfile = () => {
    this.setState({ file: "", filename: "", filetype: "" });
  }

  changeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    this.setState({ searchValue: e.target.value })
  };

  removeRow(couponCode) {
    var arr = this.state.selCouponId;
    var arr2 = this.state.showCouponData;
    for (var i = 0; i < arr.length; i++) {

      if (parseInt(arr[i]) === parseInt(couponCode)) {
        // console.log("inside if")
        arr.splice(i, 1);
        arr2.splice(i, 1);
      }

    }


    // console.log("length--->" + arr.length)
    // console.log("disp length--->" + arr2.length)
    this.setState({
      selCouponId: arr,
      showCouponData: arr2

    });

  }

  getPolicyNo = () => {

    $(".loader").show();
    const couponListing = {
      method: "POST",
      body: JSON.stringify({
        functionName: "rhCouponCode",
        subFunction: "getPolicyNoForAutoComplete"
      }),
    };

    // console.log(couponListing.body);
    apiUrlRelianceFunction(couponListing).then((data) => {
      var policyData = [];
      if (data.success === "1") {
        for (var i = 0; i < data.policyDetails.length; i++) {
          policyData[i] = {
            value: data.policyDetails[i].policyNo,
            label: data.policyDetails[i].policyNo

          }

        }


        this.setState({
          policyNoList: policyData
        });
      } else if (data.success === "0") {
        this.setState({
          policyNoList: []
        });
      }
      $(".loader").hide();
    });
  }

  couponHandler = (e) => {
    if (this.state.finalPolicyNo.length > 0) {
      this.state.finalPolicyNo.forEach(policyNo => {
        this.existPolicyNumCoupon(e.target.value, policyNo)
        if (this.state.existPolicyNumCoupon === true) {
          alert("Coupon Code with Policy number already exist")
        }
      });
    }

    if (this.state.selCouponId.indexOf(e.target.value) !== -1) {
      alert("Coupon Code already exist")
    }
    else {
      if (this.state.existPolicyNumCoupon === true) {
        alert("Coupon Code with Policy number already exist")
      } else {
        this.getCoupon(e.target.value)

      }

    }

    //this.renderCouponDetails(this.state.couponDetail);
  };
  couponUsageTypeHandler = (usageType, parentId) => {
    // console.log("usageType====" + usageType + "           parentId" + parentId)
    if (usageType === "Single") {
      $("#divNoOfTimes" + parentId).addClass("d-none");

    } else if (usageType === "Multiple") {
      $("#divNoOfTimes" + parentId).removeClass("d-none");
    }

  }
  getCoupon = (couponId) => {
    $(".loader").show();
    var isFirstTimeExist = false;
    const checkFirstTimeExist = {
      method: "POST",
      body: JSON.stringify({
        "functionName": "rhCouponCode",
        "subFunction": "checkFirstTimeExist",
        "empId": "",
        "couponCodeId": couponId
      }),
    };
    apiUrlRelianceFunction(checkFirstTimeExist).then((data) => {

      if (data.success === "1") {
        if (data.dataExist === 1) {
          isFirstTimeExist = true;
        }
      }
    });

    const couponDetails = {
      method: "POST",
      body: JSON.stringify({
        functionName: "rhCouponCode",
        subFunction: "getCouponDetailsbyId",
        couponCodeId: couponId
      }),
    };

    // console.log(couponDetails.body);
    apiUrlRelianceFunction(couponDetails).then((data) => {

      if (data.success === "1") {

        if (this.state.selCouponId.indexOf(data.couponData[0].parentCouponCode) !== -1) {
          alert("Coupon Code already exist");
          this.setState({
            couponDetail: {},
            couponId: 0
          });
        }
        else if (this.state.existPolicyNumCoupon === true) {
          alert("Coupon Code with Policy number already exist")
        }
        else {

          this.displayCouponData.push(
            <div className='d-flex align-items-center div_widthW'>
              <div className='row_si row_common mx-2'></div>
              <div className='row_close  row_common mx-2'><span onClick={() => this.removeRow(data.couponData[0].couponCode)}><i class="fa fa-times-circle text-danger mr-2"></i></span><span className='div_version' id={'spnCouponName' + data.couponData[0].couponCode}>{data.couponData[0].couponCodeName} (ver {data.couponData[0].couponVersion}{data.couponData[0].couponVersion === "1" ? '.0' : ''})</span></div>
              <div className='row_radio  row_common mr-3'>
                <label className='mb-0 row_common'>
                  <input className='radCouponUsageType' name={'radCouponUsageType' + data.couponData[0].couponCode} id={'radCouponUsageTypeSingle' + data.couponData[0].couponCode} onClick={() => this.couponUsageTypeHandler('Single', data.couponData[0].couponCode)} type="radio" value='Single' defaultChecked={data.couponData[0].isSingle === true} /><span>Single</span>
                </label>
                <label className='mb-0 row_common'>
                  <input className='radCouponUsageType' name={'radCouponUsageType' + data.couponData[0].couponCode} id={'radCouponUsageTypeMultiple' + data.couponData[0].couponCode} onClick={() => this.couponUsageTypeHandler('Multiple', data.couponData[0].couponCode)} type="radio" value='Multiple' defaultChecked={data.couponData[0].isSingle === false} /><span>Multiple</span>
                </label>
                <label className={isFirstTimeExist === true ? 'mb-0 d-none' : 'mb-0'}>
                  <input className='chkFirstTime' onClick={() => this.disableFirstTime(data.couponData[0].couponCode, 1)} name={'chkFirstTime' + data.couponData[0].couponCode} id={'chkFirstTime' + data.couponData[0].couponCode} value='on' type="checkbox" /><span>First Time</span>
                </label>
              </div>

              <div className={data.couponData[0].isSingle === false ? "no_time row_common" : "no_time row_common d-none"} id={'divNoOfTimes' + data.couponData[0].couponCode}>
                <span className='mr-2'> Number of Times</span>
                <input className='txtNoOfTimes custome_input' type="text" name={'txtNoOfTimes' + data.couponData[0].couponCode} id={'txtNoOfTimes' + data.couponData[0].couponCode} />
              </div>
              <div className='row_right  d-flex align-items-center ml-auto'>
                <div className='no_time row_common'>
                  <span className='mb-0 mr-2'>Corporate Share</span>
                  <input type="text" placeholder='value' className='border_radious_right txtCorporateShare' name={'txtCorporateShare' + data.couponData[0].couponCode} id={'txtCorporateShare' + data.couponData[0].couponCode} />
                </div>
                <div className='corporate_Share row_common custome_rgi ml-2'>
                  <label className='mb-0 row_common mx-0'>
                    <input className='radShareType' name={'radShareType' + data.couponData[0].couponCode} id={'radShareTypeAmount' + data.couponData[0].couponCode} value='Amount' defaultChecked={data.couponData[0].shareType === "Amount"} type="radio" /><span className='mx-0'>₹</span>
                  </label>
                  <label className='mb-0 row_common mx-0'>
                    <input className='radShareType' name={'radShareType' + data.couponData[0].couponCode} id={'radShareTypePercent' + data.couponData[0].couponCode} value='Percentage' defaultChecked={data.couponData[0].shareType === "Percentage"} type="radio" /><span className='mx-0'>%</span>
                  </label>
                </div>
              </div>
            </div>
          )
          // var row_width = $(".row_flex").innerWidth();
          // $('.div_widthW').css('width', row_width +'px')
          this.setState({
            selCouponId: [...this.state.selCouponId, couponId],
            couponDetail: data.couponData[0],
            showCouponData: this.displayCouponData
          });
          // this.state.checkedFirstTimeCoupon.forEach((coupId)=>{
          //   this.disableFirstTime(coupId,0)
          // })
          if (isFirstTimeExist === false) {
            this.disableFirstTime(data.couponData[0].couponCode, 0)
          }
        }



      } else if (data.success === "0") {
        this.setState({
          couponDetail: {},
          couponId: 0
        });
      }
      $(".loader").hide();
    });
  };

  disableFirstTime = (couponId, from) => {


    var ckName = document.getElementsByClassName('chkFirstTime');
    var checked = document.getElementById('chkFirstTime' + couponId);
    var count = 0;
    if (from === 1) {
      if (checked.checked) {
        for (var i = 0; i < ckName.length; i++) {

          if (!ckName[i].checked) {
            ckName[i].disabled = true;
          } else {
            ckName[i].disabled = false;
          }
        }

        this.setState({
          checkedFirstTimeCoupon: [...this.state.checkedFirstTimeCoupon, couponId]
        })
      }
      else {
        for (var i = 0; i < ckName.length; i++) {
          ckName[i].disabled = false;
        }
        var chekedFirstTime = this.state.checkedFirstTimeCoupon.filter(function (ele) {
          return ele !== couponId;
        });
        this.setState({
          checkedFirstTimeCoupon: chekedFirstTime
        })
      }
    }
    else {
      // console.log("length========>" + ckName.length)
      for (var i = 0; i < ckName.length; i++) {
        if (ckName[i].checked) {
          count = 1;
        }
      }
      // console.log("count========>" + count)
      if (count > 0) {
        $('#chkFirstTime' + couponId).prop('disabled', true);

      }
      else {
        $('#chkFirstTime' + couponId).prop('disabled', false);
      }
    }

  };
  refresh = () => {
    this.setState({
      txtInput: "",
      offset: "0",
      cmbStatus: "0",
      activeIndex: 1,
      activePage: 1
    });
    this.policyCouponListing();
  };
  search = () => {
    this.setState({
      offset: 0,
      activeIndex: 1,
      activePage: 1
    });
    let txInput = this.state.txtInput;
    $(".loader").show();
    const policyCouponListing = {
      method: "POST",
      body: JSON.stringify({
        functionName: "rhCouponCode",
        subFunction: "getPolicyCoupon",
        fromDate: "",
        toDate: "",
        txtInput: txInput.trim(),
        cmbStatus: this.state.cmbStatus,
        groupHospitalAdminId: groupHospitalAdminId,
        max: this.state.maxRow,
        offset: this.state.offset
      }),
    };

    // console.log(policyCouponListing.body);
    apiUrlRelianceFunction(policyCouponListing).then((data) => {

      if (data.success === "1") {
        this.setState({
          policyCouponLists: data.promoCodeData,
          dataCount: data.totalCount
        });
        this.downloadData(data.totalCount);
      } else if (data.success === "0") {
        this.setState({
          policyCouponLists: [],
          dataCount: 0
        });
      }
      $(".loader").hide();
    });
  };

  handleChange = selectedItem => {
    this.setState({
      existPolicyNumCoupon: false
    })
    if (this.state.selCouponId.length === 0) {
      alert("Select atleast one coupon code.");
      this.setState({ selectedPolicyNo: null });
    }
    else if (this.selPolicyNo.indexOf(selectedItem.value) !== -1) {
      alert("Policy Number already exist")
    }
    else {
      this.setState({ selectedPolicyNo: selectedItem });
      this.getPolicyDetails(selectedItem.value);
      for (var i = 0; i < this.state.selCouponId.length; i++) {
        this.existPolicyNumCoupon(this.state.selCouponId[i], selectedItem.value);
      }
    }


    //  console.log(`Option selected:`, selectedItem);
  };
  getPolicyDetails = (policyNum) => {

    $(".loader").show();
    const couponListing = {
      method: "POST",
      body: JSON.stringify({
        functionName: "rhCouponCode",
        subFunction: "getPolicyDetailsByPolicyNo",
        policyNo: policyNum
      }),
    };

    // console.log(couponListing.body);
    apiUrlRelianceFunction(couponListing).then((data) => {
      var policyData = [];
      if (data.success === "1") {
        if (data.policyDetails.policyType === "Corporate") {
          this.setState({
            policyType: data.policyDetails.policyType,
            policyName: data.policyDetails.policyName,
            policyStartDate: data.policyDetails.policyStartDate,
            policyEndDate: data.policyDetails.policyEndDate,
            dateOfBirth: data.policyDetails.dateOfBirth,
            showDateofBirth: false,
            showPolicydata: true,
            isVerifyPolicyData: true
          });
        } else {
          this.setState({
            policyType: data.policyDetails.policyType,
            policyName: data.policyDetails.policyName,
            policyStartDate: data.policyDetails.policyStartDate,
            policyEndDate: data.policyDetails.policyEndDate,
            dateOfBirth: data.policyDetails.dateOfBirth,
            showDateofBirth: true,
            showPolicydata: false,
            isVerifyPolicyData: false
          });

        }

      } else if (data.success === "0") {
        this.setState({
          policyType: '',
          policyName: '',
          policyStartDate: '',
          policyEndDate: '',
          dateOfBirth: '',
          showDateofBirth: false,
          showPolicydata: false,
          isVerifyPolicyData: false
        });
      }
      $(".loader").hide();
    });
  }


  async handlePageChange(pageNumber) {
    await this.setState(
      {
        activePage: pageNumber,
        offset: (parseInt(pageNumber) - 1) * 10,
      },
      () => {
        this.policyCouponListing();
      }
    );
  }
  componentDidMount() {
    this.couponListing();
    this.policyCouponListing();
    this.getPolicyNo();
    $("#changePassword").addClass("dashBoardLeftMenu-active");
  }
  couponListing = () => {
    $(".loader").show();
    const couponListing = {
      method: "POST",
      body: JSON.stringify({
        functionName: "rhCouponCode",
        subFunction: "getActiveCouponList",
        groupHospitalAdminId: groupHospitalAdminId
      }),
    };

    // console.log(couponListing.body);
    apiUrlRelianceFunction(couponListing).then((data) => {

      if (data.success === "1") {

        this.setState({
          couponList: data.couponData,
          dataCouponListCount: data.totalCount
        });
      } else if (data.success === "0") {
        this.setState({
          couponList: [],
          dataCouponListCount: 0
        });
      }
      $(".loader").hide();
    });
  };
  renderCouponList(couponLists) {

    return couponLists.map((d, key) =>
      <option value={d.couponId}> {d.couponCodeName}(ver {d.couponVersion}{d.couponVersion === "1" ? '.0' : ''}) </option>
    );
  }
  policyCouponListing = () => {
    let txInput = this.state.txtInput;
    $(".loader").show();
    const policyCouponListing = {
      method: "POST",
      body: JSON.stringify({
        functionName: "rhCouponCode",
        subFunction: "getPolicyCoupon",
        fromDate: "",
        toDate: "",
        txtInput: txInput.trim(),
        cmbStatus: this.state.cmbStatus,
        groupHospitalAdminId: groupHospitalAdminId,
        max: this.state.maxRow,
        offset: this.state.offset
      }),
    };

    // console.log(policyCouponListing.body);
    apiUrlRelianceFunction(policyCouponListing).then((data) => {

      if (data.success === "1") {
        this.setState({
          policyCouponLists: data.promoCodeData,
          dataCount: data.totalCount
        });
        this.downloadData(data.totalCount);
      } else if (data.success === "0") {
        this.setState({
          policyCouponLists: [],
          dataCount: 0
        });
      }
      $(".loader").hide();
    });
  };
  deactivatePolicyCoupon = (policyCouponId) => {
    const deactivatePolicyCoupon = {
      method: "POST",
      body: JSON.stringify({
        functionName: "rhCouponCode",
        subFunction: "deactivateCouponPolicyDetails",
        id: policyCouponId,
        userId: userId
      }),
    };
    apiUrlRelianceFunction(deactivatePolicyCoupon).then((data) => {

      if (data.success === "1") {
        alert(data.successMessage);
        this.policyCouponListing();
        this.renderPolicyCouponList(this.state.policyCouponLists);

      } else if (data.success === "0") {
        alert(data.errorMessage)
      }
    });

  }
  activatePolicyCoupon = (policyCouponId) => {
    const activatePolicyCoupon = {
      method: "POST",
      body: JSON.stringify({
        functionName: "rhCouponCode",
        subFunction: "activateCouponPolicyDetails",
        id: policyCouponId,
        userId: userId
      }),
    };
    apiUrlRelianceFunction(activatePolicyCoupon).then((data) => {

      if (data.success === "1") {
        alert(data.successMessage);
        this.policyCouponListing();
        this.renderPolicyCouponList(this.state.policyCouponLists);

      } else if (data.success === "0") {
        alert(data.errorMessage)
      }
    });

  }
  downloadData = (dataCount) => {
    const policyCouponListing = {
      method: "POST",
      body: JSON.stringify({
        functionName: "rhCouponCode",
        subFunction: "getPolicyCoupon",
        fromDate: "",
        toDate: "",
        txtInput: this.state.txtInput,
        cmbStatus: this.state.cmbStatus,
        groupHospitalAdminId: groupHospitalAdminId,
        max: 100000,
        offset: 0
      }),
    };

    // console.log(policyCouponListing.body);
    apiUrlRelianceFunction(policyCouponListing).then((data) => {

      if (data.success === "1") {
        // console.log("********************" + data.totalCount);
        this.setState({
          policyCouponDownloadLists: data.promoCodeData
        });
      } else if (data.success === "0") {
        this.setState({
          policyCouponDownloadLists: []
        });
      }
    });

  };
  generateXLXData(policyCouponDownloadLists) {
    var generateXlxData = [];
    for (var i = 0; i < policyCouponDownloadLists.length; i++) {
      generateXlxData[i] = {
        PolicyNumber: policyCouponDownloadLists[i].policyNumber,
        empId: policyCouponDownloadLists[i].empId,
        policyApiName: policyCouponDownloadLists[i].policyApiName,
        policyStartDate: policyCouponDownloadLists[i].policyStartDate,
        policyEndDate: policyCouponDownloadLists[i].policyEndDate,
        couponName: policyCouponDownloadLists[i].couponName + "(" + policyCouponDownloadLists[i].version + ")",
        policyversion: policyCouponDownloadLists[i].policyversion,
        couponType: policyCouponDownloadLists[i].couponType,
        allotted: policyCouponDownloadLists[i].allotted,
        used: policyCouponDownloadLists[i].used,
        Pending: policyCouponDownloadLists[i].allotted !== "-" && policyCouponDownloadLists[i].used !== "-" ? parseInt(policyCouponDownloadLists[i].allotted) - parseInt(policyCouponDownloadLists[i].used) : '',
        shareType: policyCouponDownloadLists[i].shareType,
        shareValue: policyCouponDownloadLists[i].shareValue,
        remark: policyCouponDownloadLists[i].isSingle == true ? 'Single' : '' + policyCouponDownloadLists[i].isMultiple == true ? 'Multiple' : '',
        status: policyCouponDownloadLists[i].isActive == true ? 'Active' : 'Inactive'
      }

    }
    const fileName = "policyExportData";
    const exportType = "csv";
    this.exportXlsx(generateXlxData, fileName, exportType)

  }
  exportXlsx = (data, fileName, exportType) => {
    exportFromJSON({ data, fileName, exportType });
  }
  renderPolicyCouponList(policyCouponLists) {
    return policyCouponLists.map((c) => [
      <tr>
        <td>{c.policyNumber}</td>
        <td>{c.empId}</td>
        <td>{c.policyApiName}</td>
        <td>{c.policyStartDate}</td>
        <td>{c.policyEndDate}</td>
        <td>{c.couponName}({c.version})</td>
        <td>{c.policyversion}</td>
        <td>{c.couponType}</td>
        <td>{c.allotted}</td>
        <td>{c.used}</td>
        <td>{c.allotted !== "-" && c.used !== "-" ? parseInt(c.allotted) - parseInt(c.used) : "-"}</td>
        <td>{c.shareType}</td>
        <td>{c.shareType === "Amount" ? "₹ " : ""}{c.shareValue}{c.shareType === "Percentage" ? " %" : ""}</td>
        <td>{c.isSingle == true ? "Single" : ""}{c.isMultiple == true ? "Multiple" : ""}</td>
        <td> <div className='d-flex align-items-center'>{c.isActive == true ? "Active" : "Inactive"}
          {c.isActive == true ? <span onClick={() => this.deactivatePolicyCoupon(c.couponPolicyId)} className='btn'><i className='fa fa-times-circle text-danger'></i></span> : <span onClick={() => this.activatePolicyCoupon(c.couponPolicyId)} className='btn'><i className='fa fa-check-circle-o text-success'></i></span>}
        </div></td>

      </tr>


    ]);
  }
  render() {

    var selectedPolicyDiv = (
      <div>
        <ol>
          {this.selPolicyNo.map((policy, index) => (
            <li key={index}>
              {policy}<span onClick={() => this.removeCouponCodeRow(policy)}><i class="fa fa-times-circle text-danger ml-2"></i></span>
            </li>
          ))}
        </ol>
      </div>

    );

    return (
      <>
        <Accordion allowZeroExpanded='true'>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Add Coupons To Policies
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <form id='frmCouponPolicy' name='frmCouponPolicy' autocomplete="off">
                <div class="row mb-5">
                  <div class="col-sm-5">
                    <div class="row d-flex align-items-center">
                      <label class="col-md-6 label-text">Coupon <span>*</span></label>
                      <div class="col-md-6  px-0 px-sm-3">

                        <select value={this.state.couponId} className="custome_input" id="" onChange={this.couponHandler}>
                          <option value="0">Select</option>
                          {this.renderCouponList(this.state.couponList)}

                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className='row_flex mt-3'>

                      {this.state.showCouponData.map(data => (data))}



                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div class="col-sm-5">
                    <div className='row d-flex align-items-end'>
                      <div className='col-md-6'>
                        <label class="label-text">Policy Number <span>*</span></label>
                      </div>
                      {/* <input type="text" className="custome_input mb-0"/>    */}
                      <div className='col-md-6'>
                        <label className='invisible'>invisible</label>
                        <Multiselect className='custome_input'
                          placeholder={'Search Policy Number'}
                          data={this.state.policyNoList}
                          value={this.state.selectedPolicyNo}
                          handleOnChange={this.handleChange}
                          defaultValue=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className={this.state.showDateofBirth === false ? 'col-sm-3 d-none' : 'col-sm-3'}>
                    <label className="label-text">Date of Birth<span className="star-red">*</span></label>
                    <DatePicker
                      selected={this.state.dateBirth}
                      onCalendarClose={date => this.dateChange()}
                      onChange={date => this.onDateSelect(date)}
                      name="startDate"
                      dateFormat="dd/MM/yyyy"
                      placeholder="Date of Birth"
                      className="mb-0 datepickerIcon-hsp"

                    
                      scrollableYearDropdown={true}
                      showYearDropdown
                      showMonthDropdown
                      adjustDateOnChange
                    />
                    <span className='text-danger fs_12 ml-2'>Enter Date of Birth</span>
                  </div>
                  <div className={this.state.showPolicydata === true && this.state.isVerifyPolicyData === true ? "col-sm-3" : "col-sm-3 d-none"}>
                    <label className="label-text">Policy Name<span className="star-red">*</span></label>
                    <input type="text" readOnly className="custome_input mb-0" placeholder='Policy Name' value={this.state.policyName} />
                  </div>
                  <div className={this.state.showPolicydata === true && this.state.isVerifyPolicyData === true ? "col-sm-3" : "col-sm-3 d-none"}>
                    <label className="label-text">Policy Start Date<span className="star-red">*</span></label>
                    <input type="text" readOnly className="custome_input mb-0" placeholder='Policy Start Date' value={this.state.policyStartDate} />
                  </div>
                  <div className={this.state.showPolicydata === true && this.state.isVerifyPolicyData === true ? "col-sm-3" : "col-sm-3 d-none"}>
                    <label className="label-text">Policy End Date<span className="star-red">*</span></label>
                    <input type="text" readOnly className="custome_input mb-0" placeholder='Policy End Date' value={this.state.policyEndDate} />
                  </div>
                  <div className={this.state.isVerifyPolicyData === true ? 'col-md-3 text-left ' : 'col-md-3 text-left d-none'}>
                    <label className='invisible d-block'>invisible</label>
                    <input type="button" class="formBtnBg" onClick={() => this.addPolicyData()} value="Add" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row my-12">
                    <div class="col-md-12 ml-auto">
                      {selectedPolicyDiv}
                    </div>
                  </div>
                  <div class="col-md-12 overflow-hidden ml-auto text-right ">
                    <label className="file_upload mb-0">
                      <input type="file" onChange={this.handleClick.bind(this)} />
                      File Upload
                    </label>
                    <span className='ml-2 pt-2'>{this.state.filename}{this.state.filename !== "" ? <span onClick={() => { this.resetfile() }}><i class="fa fa-times-circle text-danger"></i></span> : <span></span>}</span>
                  </div>
                </div>

                <div class="col-md-12 text-right mt-4">
                  <input type="button" onClick={() => this.onApplyPolicy()} className="border_btn btn_basics mr-3" value="Apply" />
                  <input type="button" className="border_btn btn_basics" value="Cancel" onClick={() => this.cancelButton()} />
                </div>
              </form>

            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
        <div className='row align-items-center my-4'>
          <div className='col-md-3'>
            <input
              type="text"
              className="custome_input mb-0"
              placeholder="Policy Name/Policy Number"
              id="txtInput"
              value={this.state.txtInput}
              onInput={this.changeHandler}
            />
          </div>
          <div className='col-md-3'>
            <select className="custome_input mb-0" id="cmbStatus" onChange={this.changeHandler}>

              <option value="0" selected="">All</option>
              <option value="true">Active</option>
              <option value="false">Inactive</option>

            </select>
          </div>
          <div className="col-md-6 coupon_btn d-flex flex-wrap">
            <input type="submit" className="formBtnBg mr-3" onClick={() => this.search()} value="Search" />
            <input type="submit" className="formBtnBg mr-3" value="Refresh" onClick={() => this.refresh()} />
            <input type="submit" className="formBtnBg" value="Download History" onClick={() => this.generateXLXData(this.state.policyCouponDownloadLists)} />
          </div>
        </div>
        <div class="table-responsive mb-3 custom_scroll" >
          <table class="table custom_table">
            <thead>
              <tr>
                <th>Policy Number</th>
                <th>Employee<br />ID</th>
                <th>Policy<br />Name</th>
                <th> Policy Start<br />Date</th>
                <th>Policy End<br />Date</th>
                <th>Coupon Name</th>
                <th>Policy<br />Version</th>
                <th>Coupon<br />Type	</th>
                <th >Allotted</th>
                <th> Used</th>
                <th>Pending</th>
                <th>Corporate<br />Share<br />Type</th>
                <th>Corporate<br />Share<br />Value</th>
                <th>Remarks</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: "12px" }}>
              {this.renderPolicyCouponList(this.state.policyCouponLists)}
            </tbody>
          </table>

        </div>
        <div className="text-right">
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.dataCount}
            pageRangeDisplayed={10}
            prevPageText={'Previous'}
            nextPageText={'Next'}
            onChange={(pageNumber) =>
              this.handlePageChange(pageNumber)
            }
          />
        </div>
      </>
    )
  }
}