import React, { Component, Suspense } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { apiCalling,apiUrlRelianceFunction } from "../apiService";
import moment from "moment";
import Pagination from "react-js-pagination";
import csv from "../image/icons/csv.png";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

var UserRole = window.sessionStorage.getItem("role")

export default class patientTransaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentData: [{
                memTrId: 4078,
                hospitalName: '',
                employeeId: null,
                policyNumber: null,
                hasAudio: 0,
                createdby: 181410,
                createdon: '',
                docId: 180974,
                doctorName: '',
                doctorMob: '',
                rpAmt: 0,
                hosId: 10773,
                memId: 181410,
                patient: '',
                productNames: '',
                prodId: 4,
                transactionsId: '',
                IsActive: true,
                memMobile: '',
                UserId:this.props.match.params.groupHospitalId
            }],
            toDate: "",
            fromDate: "",
            totalCount: 0,
            activePage: 1,
            fuctionName: 'Inperson Reports',
            csvDetails: [],
            showMessage: "Waiting for data... "
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeTo = this.handleChangeTo.bind(this);
        // this.changeHandler = this.changeHandler.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.patientTransactionReport();
        this.inPersonReportsCSV();
    }
    handleChange = (date) => {
        this.setState({
            fromDate: date,
        });
    };
    handleChangeTo = (date) => {
        this.setState({
            toDate: date,
        });
    };
    // changeHandler = (event) => {

    //     const { value, name } = event.target;
    //     this.setState({
    //         [name]: value,
    //     })
    // }
    handlePageChange(pageNumber) {
        window.scrollTo(0, 0);
        var startDate = $("#fromDate").val();
        var toDate = $("#toDate").val();
        var newDate = moment(startDate, "DD-MM-YYYY").format("MM-DD-YYYY")
        var endDate = moment(toDate, "DD-MM-YYYY").format("MM-DD-YYYY")
        this.setState({ activePage: pageNumber });
        var offset;
        var max;
        if (pageNumber === "1") {
            offset = "0";
            max = "10";
            this.setState({
                activePage: pageNumber,
                isLoading: '0'
            })
        }
        else {
            offset = parseInt(pageNumber - 1) * 10;
            max = "10";
            this.setState({
                activePage: pageNumber,
                isLoading: '0'
            })
        }
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "paymentReport",
                subFunctionName: "patientTransactionReports",
                userId:this.props.match.params.groupHospitalId, //"19238",
                role:window.sessionStorage.getItem("role"),
                fromDate: newDate,
                endDate:endDate,
                txtInput: $("#txtInperson").val(),
                cmbSelect:$("#inpersonSelect").val() === '0' ? '' : $("#inpersonSelect").val(),
                cmbSerSelect:$("#productInput").val()  === '0' ? '' : $("#productInput").val(),
                max: max,
                offset: JSON.stringify(offset),
            }),
        };
        $(".loader").show();
        let apiFunction = folderName!== null ? apiCalling : apiUrlRelianceFunction
        apiFunction(apiJson).then((data) => {
            if (data.success == "1") {
                this.setState({
                    paymentData: data.result.data,
                    totalCount: data.result.count,
                });
                $(".loader").hide();
            }
        });
    }
    patientTransactionReport() {
        
        $(".loader").show();
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "paymentReport",
                subFunctionName: "patientTransactionReports",
                userId:window.sessionStorage.getItem("userId"), //"19238", window.sessionStorage.getItem("groupHospitalAdminId")
                role:window.sessionStorage.getItem("role"),
                fromDate:"",
                endDate:"",
                txtInput:"",
                cmbSelect:"",
                cmbSerSelect:"",
                max: "10",
                offset: "0"    
            }),
        };
        // console.log(apiJson.body,"jjjjjjjjjjjjjjjj");
        let apiFunction=folderName!== null ? apiCalling : apiUrlRelianceFunction
        apiFunction(apiJson).then((data) => {
            $(".loader").hide();
            // console.log(data)
            if (data.success === "1") {
                this.setState({
                    paymentData: data.result.data,
                    totalCount: data.result.totalCount,
                });
            }
            if (data.success === "0") {
                $(".loader").hide();
                this.setState({
                     paymentData: [],
                      csvDetails:[],
                    showMessage: data.errorMessage
                });
            }
        });
    }
    changeText = () => {
        if ($("#inpersonSelect").val() !== '0') {
            document.getElementById("txtInperson").disabled = false
            $('input[name=txtInperson]').focus();
        }
        else {
            document.getElementById("txtInperson").disabled = true
        }
        document.getElementById("txtInperson").value = ""

        if ($("#productSelect").val() !== '0') {
            document.getElementById("productInput").disabled = false
            $('input[name=productInput]').focus();
        }
        else {
            document.getElementById("productInput").disabled = true
        }
        document.getElementById("productInput").value = ""
    }
    searchHandler() {
        var startDate = $("#fromDate").val();
        var toDate = $("#toDate").val();
        var newDate = moment(startDate, "DD-MM-YYYY").format("MM-DD-YYYY")
        var endDate = moment(toDate, "DD-MM-YYYY").format("MM-DD-YYYY")
        if ($("#inpersonSelect").val() !== "0" && $("#txtInperson").val() === "") {
            var t = document.getElementById("inpersonSelect");
            var selectedText = t.options[t.selectedIndex].text;
            alert(selectedText + " Required");
            $("#txtInperson").focus();
            return false;
        }
        if ($("#productSelect").val() !== "0" && $("#productInput").val() === "") {
            var t = document.getElementById("productSelect");
            var selectedText = t.options[t.selectedIndex].text;
            alert(selectedText + " Required");
            $("#productInput").focus();
            return false;
        }
        if (newDate > endDate) {
            alert("To date should be greater than or equal to from date")
            return false
        }
        else {
            const searchData = {
                method: "POST",
                body: JSON.stringify({
                    functionName: "paymentReport",
                    subFunctionName: "patientTransactionReports",
                    role:window.sessionStorage.getItem("role"),
                    userId:this.props.match.params.groupHospitalId, //"19238",this.props.match.params.groupHospitalId
                    fromDate:newDate,
                    endDate:endDate,
                    txtInput:$("#txtInperson").val(),
                    cmbSelect:$("#inpersonSelect").val() === '0' ? '' : $("#inpersonSelect").val(),
                    cmbSerSelect:$("#productInput").val()  === '0' ? '' : $("#productInput").val(),
                    max: "10",
                    offset: "0"
                }),
            };
            $(".loader").show();
            let apiFunction=folderName!== null ? apiCalling : apiUrlRelianceFunction
            apiFunction(searchData).then((data) => {
                // this.setState({ toDate: "", fromDate: "" })
                if (data.success == "1") {
                    $(".loader").hide();
                    this.setState({
                        paymentData: data.result.data,
                        csvDetails:data.result.paymentExportData,
                        totalCount: data.result.count,
                        activePage: 1,
                    });
                }
                if (data.success === "0") {
                    $(".loader").hide();
                    this.setState({
                        paymentData: [],
                        showMessage: data.errorMessage,
                        csvDetails:[]
                    });
                }
            })
        }
    }
    inPersonReportsCSV() {
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "paymentReport",
                subFunctionName: "patientTransactionReports",
                userId: this.props.match.groupHospitalId, // "19238",
                role:window.sessionStorage.getItem("role")
            }),
        };
        apiCalling(apiJson).then((data) => {
            if (data.success === "1") {
                this.setState({
                    csvDetails: data.result.data,
                });
            }
        });
    }
    csvFileDownload() {

        const csvRow = [];
        var columnDetails = [['Patient%20Name', 'Hospital%20Name', 'Doctor%20Name', 'Transaction%20Id', 'Appointment%20Status', 'Appointment%20Requested%20Date', 'Appointment%20Time']]
        var results = this.state.csvDetails;

        for (var item = 0; item < results.length; item++) {

            columnDetails.push([results[item].memName.replaceAll(' ', '%20'), results[item].hospitalName.replaceAll(' ', '%20'), results[item].docName.replaceAll(' ', '%20'),results[item].transactionId.replaceAll(' ', '%20'),results[item].statusName.replaceAll(' ', '%20'), moment(results[item].requestedDate).format("DD-MMM-YYYY").replaceAll(' ', '%20'),moment(results[item].requestedDate).format("hh:mm A").replaceAll(' ', '%20')])
        }

        for (var i = 0; i < columnDetails.length; ++i) {
            csvRow.push(columnDetails[i].join(","))
        }

        var csvString = csvRow.join("%0A");

        var dataType = document.createElement("a");

        dataType.href = 'data:attachment/csv,' + csvString;
        dataType.target = "_Blank";
        dataType.download = this.state.fuctionName + '.csv';
        document.body.appendChild(dataType);
        dataType.click();
    }
    render() {
        return (
            <div class="purpleWrap">
                <Suspense fallback={<div >Waiting...</div>}>
                    <section>
                    {folderName!==null &&
                        <Header />
                    }
                        <div className='loader'></div>
                        <div class="container">
                            <div class="main-wrapper1 date_width  dashbrd-rght-bottom-padng-hsp">
                                <div class="col-md-12 p-0">
                                    <ul class="breadcrumb">
                                        <li>
                                            <Link to="/Groupadmin/dashboard/">Dashboard</Link>
                                            <i class="fa fa-chevron-right"></i>
                                        </li>
                                        <li> Patient Transaction Report</li>
                                    </ul>
                                </div>
                                <div class="form-head">Patient Transaction Report </div>
                                <div class="row bg_ddd pt-3 mx-0">
                                <div class="col-md-2 mb-3">
                                        <select class="custome_input mb-0"
                                            id="productSelect"
                                            name="productSelect"
                                            onChange={() => this.changeText()}
                                        >
                                            <option value="0">- - Select - -</option>
                                            <option value="1">Products</option>
                                        </select>
                                    </div>
                                    <div class="col-md-2 mb-3">
                                        <input
                                            type="text"
                                            class="custome_input mb-0"
                                            id="productInput"
                                            name="productInput"
                                            disabled
                                        />
                                    </div>
                                    <div class="col-md-2 mb-3">
                                        <select class="custome_input mb-0"
                                            id="inpersonSelect"
                                            name="inpersonSelect"
                                            onChange={() => this.changeText()}
                                        >
                                            <option value="0">- - Select - -</option>
                                            <option value="1">Transaction Id</option>
                                            <option value="2">Patient Name</option>
                                            <option value="3">Doctor Name</option>
                                            <option value="4">Hospital Name</option>
                                            <option value="5">Policy Number</option>
                                        </select>
                                    </div>
                                    <div class="col-md-2 mb-3">
                                        <input
                                            type="text"
                                            class="custome_input mb-0"
                                            id="txtInperson"
                                            name="txtInperson"
                                            disabled
                                        />
                                    </div>
                                    <div class="col-md-2 mb-3">
                                        <DatePicker
                                            placeholderText="From Date"
                                            id="fromDate"
                                            name="fromDate"
                                            selected={this.state.fromDate}
                                            onChange={this.handleChange}
                                            dateFormat="dd/MM/yyyy"
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={100}
                                            dropdownMode="scroll"
                                            disabledKeyboardNavigation
                                            autoComplete="off"
                                            tabIndex="8"
                                            calendarClassName="react-date-picker"
                                            className="custome_input mb-0  datepickerIcon-hsp"
                                        />
                                    </div>
                                    <div class="col-md-2 mb-3">
                                        <DatePicker
                                            placeholderText="To Date"
                                            id="toDate"
                                            name="toDate"
                                            selected={this.state.toDate}
                                            onChange={this.handleChangeTo}
                                            dateFormat="dd/MM/yyyy"
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={100}
                                            dropdownMode="scroll"
                                            disabledKeyboardNavigation
                                            autoComplete="off"
                                            tabIndex="8"
                                            calendarClassName="react-date-picker"
                                            className="custome_input mb-0  datepickerIcon-hsp"
                                        />
                                    </div>
                                    <div class="col-md-2 mb-3 ml-auto">
                                        <input
                                            type="submit"
                                            value="Search"
                                            class="formBtnBg w-100 mb-0"
                                            onClick={() => this.searchHandler()}
                                        />
                                    </div>
                                    <div className="col-md-1  mb-3 d-flex align-items-center">
                                        <a onClick={() => this.csvFileDownload()} className="cursor-pointer"><img src={csv} alt="csv" width="25" title="Export CSV" className="mt-1" /></a>
                                    </div>
                                </div>
                                {this.state.paymentData.length > 0 ?
                                <div>
                                    <div class="table-responsive">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Policy Number</th>
                                                    <th>Employee ID</th>
                                                    <th>Transaction ID</th>
                                                    <th>Patient Details</th>
                                                    <th>Doctor Details</th>
                                                    <th>Hospital Name</th>
                                                    <th>Service</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ fontSize: "12px" }}>
                                                {this.state.paymentData.map((data) => {
                                                    let requestedDate = moment(data.requestedDate).format("DD-MMM-YYYY");
                                                    let appointmentTime =  moment(data.requestedDate).format("hh:mm A");
                                                    return (
                                                        <tr>
                                                            <td>{data.createdon}</td>
                                                            <td>{data.policyNumber}</td>
                                                            <td>{data.employeeId}</td>
                                                            <td>{data.memTrId}</td>
                                                            <td>{data.patient}&nbsp;{data.memMobile}</td>
                                                            <td>{data.doctorName}&nbsp;{data.doctorMob}</td>
                                                            <td>{data.hospitalName}</td>
                                                            <td>{data.productNames}</td>
                                                            <td>{data.rpAmt}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        </div>
                                        {this.state.totalCount > 5 ?
                                            <div className="paginationSection orderm-paginationSection text-right">
                                                <Pagination
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={10}
                                                    totalItemsCount={this.state.totalCount}
                                                    pageRangeDisplayed={10}
                                                    prevPageText={"Previous"}
                                                    nextPageText={"Next"}
                                                    onChange={this.handlePageChange}
                                                />
                                            </div>
                                            : ""}
                                    </div>

                                    :
                                    <p className="error-msg text-center mb-0"> {this.state.showMessage} </p>
                                }
                            </div>
                        </div>
                        {folderName!==null &&
                        <Footer />
    }
                    </section>
                </Suspense>
            </div>
        );
    }
}
