import { apiCalling } from "../apiService";

export function getSiteData() {
    return new Promise(res => {
        const jsonReq = {
            method: "POST",
            body: JSON.stringify({
                functionName: "getSiteDetails",
                siteId: "",
                siteUrl: window.location.host,
            }),
        };

        apiCalling(jsonReq).then((data) => {

            if (data.success === "1") {

                // console.log(data.siteDetailsData[0].siteData[0]);
                var location = data.siteDetailsData[0].siteData[0].siteName;
                let is_theme_based =
                    data.siteDetailsData[0].siteData[0].is_theme_based;
                let project_folder =
                    data.siteDetailsData[0].siteData[0].project_folder;
                //alert(project_folder);
                var folder = "purpleHealth";
                if (is_theme_based === false && project_folder !== "") {
                    folder = project_folder;
                } else {
                    folder = data.siteDetailsData[0].siteData[0].themeName;
                }
                //folder = data.siteDetailsData[0].siteData[0].title;
                //alert(folder);
                var folderName = folder;
                window.sessionStorage.setItem(
                    "groupHospitalAdminId",
                    data.siteDetailsData[0].groupHospitalAdminData[0]
                    .groupHospitalAdminId
                );
                window.sessionStorage.setItem(
                    "themeId",
                    data.siteDetailsData[0].siteData[0].themeId
                );

                window.sessionStorage.setItem("themeVersion", "0");
                window.sessionStorage.setItem("homeName", folder);
                window.sessionStorage.setItem("reload", "1");
                window.sessionStorage.setItem("theme_custom_color", data.siteDetailsData[0].siteData[0].theme_custom_color);
                window.sessionStorage.setItem("contact_emailId", data.siteDetailsData[0].siteData[0].emailId);
                window.sessionStorage.setItem("contact_no", data.siteDetailsData[0].siteData[0].contact_no);
                window.sessionStorage.setItem("fav_icon", data.siteDetailsData[0].siteData[0].fav_icon);
                window.sessionStorage.setItem("seo_description", data.siteDetailsData[0].siteData[0].seo_description);
                window.sessionStorage.setItem("seo_keywords", data.siteDetailsData[0].siteData[0].seo_keywords);
                window.sessionStorage.setItem("seo_title", data.siteDetailsData[0].siteData[0].seo_title);
                window.sessionStorage.setItem("slug", data.siteDetailsData[0].siteData[0].slug);
                window.sessionStorage.setItem("googleId", data.siteDetailsData[0].siteData[0].google_analytics_script_id);
                window.sessionStorage.setItem("siteName", data.siteDetailsData[0].siteData[0].siteName);
                window.sessionStorage.setItem("book_appointment_terms", data.siteDetailsData[0].siteData[0].book_appointment_terms);
                window.sessionStorage.setItem("video_chat_terms", data.siteDetailsData[0].siteData[0].video_chat_terms);
                window.sessionStorage.setItem("hospital_working_time", data.siteDetailsData[0].siteData[0].hospital_working_time);
                window.sessionStorage.setItem("site_url", data.siteDetailsData[0].siteData[0].site_url);
                window.sessionStorage.setItem("fbPixel", data.siteDetailsData[0].siteData[0].fbPixel);
                window.sessionStorage.setItem("hasHealthTracker", data.siteDetailsData[0].siteData[0].hasHealthTracker);
                window.sessionStorage.setItem(
                    "footerData", JSON.stringify(data.siteDetailsData[0].footerData));

                window.sessionStorage.setItem(
                    "siteLogo",
                    data.siteDetailsData[0].siteData[0].logo_path
                );
                // this.setState({ isLoader: true });
                window.sessionStorage.setItem(
                    "siteId",
                    data.siteDetailsData[0].siteData[0].id
                );
                const hosDataVar = JSON.stringify(
                    data.siteDetailsData[0].hospitalData
                );
                window.sessionStorage.setItem(
                    "siteServices",
                    JSON.stringify(data.siteDetailsData[0].services)
                );

                if (hosDataVar) {
                    window.sessionStorage.setItem(
                        "footerhospitalData",
                        JSON.stringify(data.siteDetailsData[0].hospitalData)
                    );
                    window.sessionStorage.setItem(
                        "footerHospitalName",
                        data.siteDetailsData[0].hospitalData.hospitalName
                    );
                    window.sessionStorage.setItem(
                        "footerHospitalcity",
                        data.siteDetailsData[0].hospitalData.city
                    );
                    window.sessionStorage.setItem(
                        "footerHospitaladdress",
                        data.siteDetailsData[0].hospitalData.address
                    );
                    window.sessionStorage.setItem(
                        "footerHospitalstateName",
                        data.siteDetailsData[0].hospitalData.stateName
                    );
                    window.sessionStorage.setItem(
                        "footerHospitalzipCode",
                        data.siteDetailsData[0].hospitalData.zipCode
                    );
                }
                //alert(data.siteDetailsData[0].themeSectionList.length);
                for (
                    let i = 0; i < data.siteDetailsData[0].themeSectionList.length; i++
                ) {
                    window.sessionStorage.setItem(
                        data.siteDetailsData[0].themeSectionList[i].themeSectionName,
                        JSON.stringify(
                            data.siteDetailsData[0].themeSectionList[i].themeSectionContent
                        )
                    );
                }
                window.sessionStorage.setItem(
                    "doctorDetails",
                    JSON.stringify(data.siteDetailsData[0].doctorDetails)
                );
                //alert(window.location.pathname)

                // if(String(window.location.pathname)!="null"){
                // window.location.href = window.sessionStorage.getItem("pathname")
                //window.location.href="/maintenance"
                // }
                // else{
                // window.location.href ="/"
                // window.location.href="/maintenance"
                // }

                res(true);

            } else {
                res(false);
            }
        });
    });

}