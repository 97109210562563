import React, { Component, Suspense } from "react";
import closered from "../image/theme/closered.png";
import $ from "jquery";
import { apiCalling } from "../apiService";
import AWS from "aws-sdk";
import docImg from "../image/icons/google-docs.png" 
var s3;
let fullFilePath
var imageFile
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class HealthRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameFile: '',
      file: null,
      imageData: null ,
      picture: '',
      fileName:'',
      s3bucketAccessKey: "",
      AWSAccessKeyId: "",
      region:"us-east-1",
      bucket:" ",
      healthDetails: [],
      showMessage:" ", 
      bucketMainFolder:'',
      s3bucketAttachmentPath:'',
    };
    this.deleteData = this.deleteData.bind(this);
  }

  componentDidMount() {
  
    $("body").removeClass("body-ovrflw-hdn-menu");
       this.getData();
    $("#fieldShow").hide();
    $("#fileShow").hide();
    $("#deleteButton").hide();
    $("#fileData").hide();
    
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName:"imagePath",
        siteId: window.sessionStorage.getItem("siteId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          s3bucketAccessKey: data.configPathDetails[0].s3bucketAccessKey,
        });
        this.setState({
          AWSAccessKeyId: data.configPathDetails[0].s3bucketSecretKey,
        });

        this.setState({
          bucket: data.configPathDetails[0].PurpleHealthS3bucket,
        });
        this.setState({
          bucketMainFolder: data.configPathDetails[0].S3BucketMainFolder,
        });
        this.setState({
          s3bucketAttachmentPath: data.configPathDetails[0].s3bucketAttachmentPath,
        });
      }
    });
  }

  getData() {
    $(".loader").show();
    const dataList = {
      method: "POST",
      body: JSON.stringify({
        functionName: "healthRecords/patient/showDetails",
        userId: window.sessionStorage.getItem("userId"),
        max:"0",
        offset:"10"
      }),
    }
    apiCalling(dataList).then((data) => {
      $(".loader").hide();
      if (data.success == "1") {
        this.setState({ healthDetails: data.healthRecords,
          showMessage: data.healthRecords.length > 0 ? "" : " No Record(s) Found"
        });
      }
      $("#fileShow").show();
    });
  }

  uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  fileChange = (e) => {

    imageFile = e.target.files[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png|pdf)$/)) {
      alert("Add file(s) with only PDF,JPG,JPEG,PNG format")
      return false;
    }
    if (imageFile.size > 3145728)
  {
      alert("File size must under 3 MB!");
      return false;
    }
     else {
     this.setState({imageData:e.target.files[0]})
     imageFile = null;
    $("#fileData").show();
    //console.log("iiiiiii", e.target.files[0]);
    $("#fileData").attr("src",URL.createObjectURL(e.target.files[0]));
    $("#deleteButton").show();
    // this.setState({ image: URL.createObjectURL(e.target.files[0]) });
     }
  }

  deleteData(){
    $("#fileData").attr("src", "");
    $("#fileText").val('');
    $("#fileData").hide();
    $("#deleteButton").hide();
    this.setState({ nameFile:"" });
    this.setState({ imageData: null });
  }

  fileUpload(file) {
    AWS.config.update({
      accessKeyId: this.state.s3bucketAccessKey,
      secretAccessKey: this.state.AWSAccessKeyId,
      region: this.state.region,
    });
    var bucketParams = {
      Bucket: this.state.bucket+ "/"+ this.state.bucketMainFolder + "/"+ this.state.s3bucketAttachmentPath,
    };
    s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    let S3BucketMainFolder = "";
    let fileName = this.uuidv4() + file.name;
    fullFilePath = S3BucketMainFolder + fileName;
    this.setState({imageData:fileName})
    s3.upload(
      {
        Key:fullFilePath,
        Body: file,
        ACL: "public-read",
       
      },
      (err, data) => {
        if (err) {
          alert("There was an error uploading your File:",err.message);
        } else if (data) {
          // alert("File Uploaded Successfully",data.message);
          return null;
        }
      }
    );
   
  }

  uploadButton() {
    if (!this.state.nameFile) {
      alert("Please Enter File Name");
      $("#nameFile").focus();
    }else if(!this.state.imageData) {
      alert("Please Upload File")
      } 
         else {
    this.fileUpload(this.state.imageData)
    const saveFile = {
      method: 'POST',
      body: JSON.stringify({
        functionName: "healthRecords/patient/saveDetails",
        userId: window.sessionStorage.getItem("userId"),
        healthRecordTime: new Date(),
        txtFileName:this.state.nameFile,
        fileName: fullFilePath
      })
    }
    $(".loader").show();
    apiCalling(saveFile).then((data) => {
       $(".loader").hide();
      if (data.success == "1") {
        // console.log("data",data)
        alert("Health Record Saved Successfully")
        $("#fileData").attr("src", "");
        $("#fileData").hide();
        $("#deleteButton").hide();
         this.setState({ nameFile:"" });
         this.setState({ imageData: " " });
         setTimeout(() => {
          window.location.reload();
        }, 3000);
        }else{
          alert(data.errorMessage)
        }
    })
  }
  }

  addHandle = () => {
    $("#fieldShow").toggle();
  }

  handleChange = (event) => {

    const { value, name } = event.target;
    this.setState({
      [name]: value,
    })
  }

  deleteFile = (id) => {
   
    const apiDelete = {
      method: "POST",
      body: JSON.stringify({
        functionName: "healthRecords/patient/deleteDetails",
        recordId:id
      })
    }
   $(".loader").show();
    apiCalling(apiDelete).then((data) => {
      $(".loader").hide();
      if (data.success == "1") {
        alert("Deleted successfully");
      } else {
        alert("update failed...")
      }
     window.location.reload(false);
    });
  }

  srcTypeImage(ev){
    ev.target.src = docImg;
  }



  render() {
    return (
      <div class="purpleWrap  ">
          <Suspense fallback={<div ></div>}>
          <section>
          <div className='loader'></div>
            <Header />
            <div class="container container-auto">
              <div class="main-wrapper1">
                <div class="col-sm-12 form-head">Health Record(s)
              <div class="addNew-btn" onClick={() => this.addHandle()}>
                <span className="add-new-plus"  >+</span>ADD
              </div>
                </div>

                <div class="form-section-brdr px-0 sh-file-thumbnail" id="fieldShow">
                  <div className="health-record-head px-3 py-2"> You can add a health/medical record (Optional) </div>
                  <div class="col-sm-12 health-records-files">
                    <div className="row form-group">
                      <div class="col-lg-1  col-sm-2 health-records-steps ">
                       <h3>Step 1</h3>
                      </div>
                      <div class="col-lg-4  col-sm-5 health-records-opt">
                        <div class="fileUpload-medical">
                          <span>Select File</span>
                          <input type="button" id="attach" name="attach" class="upload" />
                          <input type="file" name="fileText" id="fileText" class="upload" onChange={this.fileChange} />
                        </div>
                        <div className="fileRecordThumb">
                          <img onError={this.srcTypeImage} src="" id={"fileData"} name="srcFileType" className="fileRecordThumbImg"></img>
                          <span class="thumbClose" style={{ right: "-20px" }}>
                            <img title="Delete" id="deleteButton" onClick={this.deleteData} src={closered} />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row form-group">
                      <div class="col-lg-1  col-sm-2 health-records-steps">
                        <h3>Step 2</h3>
                      </div>
                      <div class="col-lg-3  col-sm-8 health-records-opt">
                        <input
                          type="text"
                          placeholder="Name the document"
                          class="input-design"
                          id="nameFile"
                          name="nameFile"
                          value={this.state.nameFile}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div class="col-lg-1  col-sm-2  health-records-steps">
                        <h3>Step 3</h3>
                      </div>
                      <div class="col-lg-4  col-sm-4 health-records-opt">
                        <input
                          type="submit"
                          value="Upload"
                          className="formButton my-0"
                          style={{ verticalAlign: "top" }}
                          onClick={() => this.uploadButton()}
                        ></input>
                      </div>
                    </div>
                    {/* <div class="row">
                      <div class="col-sm-3">
                        <input
                          type="text"
                          placeholder="Enter File Name"
                          class="input-design"
                          id="nameFile"
                          name="nameFile"
                          value={this.state.nameFile}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div class="col-sm-9 mt-3 mt-md-0">
                        <div class="fileUpload-medical">
                          <span>Choose File</span>
                          <input type="button" id="attach" name="attach" class="upload" />
                          <input type="file" name="fileText" id="fileText" class="upload" onChange={this.fileChange} />
                        </div>

                      //   {/* <div class="hrecordFileUpload">
                      //   <input
                      //     class="uploadattach"
                      //     type="file"
                      //     name="fileText"
                      //     id="fileText"
                      //     onChange={this.fileChange}
                      //   />
                      // </div> */}
                        {/* <div className="fileRecordThumb">
                          <img onError={this.srcTypeImage} src="" id={"fileData"} name="srcFileType" className="fileRecordThumbImg"></img>
                          <span class="thumbClose" style={{ right: "-20px" }}>
                            <img title="Delete" id="deleteButton" onClick={this.deleteData} src={closered} />
                          </span>
                        </div>
                        <input
                          type="submit"
                          value="Upload"
                          className="formButton my-0"
                          style={{ verticalAlign: "top" }}
                          onClick={() => this.uploadButton()}
                        ></input>
                      </div>

                      <div className="col-md-12">
                        <p class="note">
                          Support File Type:(JPEG,JPG,PNG,PDF)
                        </p>
                      </div>
                    </div> */}  
                  </div>
                </div>
                <div class="form-section-brdr px-0" id="fileShow">
                {this.state.healthDetails.length > 0 ? 
                <div>
                  <div className="health-record-head px-3 py-2"> Files </div>
                  {this.state.healthDetails.map((data) => (
                    <div className="fileRecordThumb fileRecordThumb-list" >
                      <div className="elipsis">
                        <small className="text-muted"><strong>{data.description}</strong></small><br></br>
                        {/* <small className="text-muted">screenshot.png</small> */}
                      </div>
                      <a href={data.imageUrl}  target="_blank" >
                      <img  onError={this.srcTypeImage} src={data.imageUrl} className="fileRecordThumbImg"></img>
                      <small className="text-muted">{data.createdDate} </small>
                      </a>
                      <span class="thumbClose">
                        <img title="Delete" onClick={() => this.deleteFile(data.recordId)} src={closered} />
                      </span>
                    </div>
                  ))}
                  </div>
                  :
                  <p className="error-msg text-center mb-0"> {this.state.showMessage} </p>
                }
                </div>
              </div>
            </div>

            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
