import React, { Component,Suspense } from "react";
import Dashboardleftmenu from "./dashboardleftmenu";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class PatientLoginHistory extends Component {
 render() {
    return (
        <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container-fluid">
          <div class="main-wrapper1 mt-0 pt-0 hspmain-wrapper-promo" id="row-col-width-leftmenu">
            <div class="row">
              <div class="col-lg-3 p-0">
                <Dashboardleftmenu></Dashboardleftmenu>
              </div>
              <div class="col-lg-9">
              <div className="dashboard-right dashbrd-rght-bottom-padng-hsp">
                        sdfsf
                  </div>
              </div>
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
                )
            }
           }