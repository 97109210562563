import React, { Component, Suspense } from "react";
import { Link } from "react-router-dom";
import Dashboardleftmenu from "./dashboardleftmenu";
import add from "../image/icons/addplus.png"
import close from "../image/icons/close-sm.png"
import $ from 'jquery'
import { apiCalling,apiUrlRelianceFunction } from "../apiService";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
let groupHospitalAdminId = ''
export default class ExpertDoctors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            doctorList:[],
            doctorAutoCompleteList:[],
            doctorId:''
        }
    }
    componentDidMount(){
        const groupHospitalId = window.sessionStorage.getItem("userId");
        groupHospitalAdminId = groupHospitalId
        this.expertDoctorList()
        $(document).on("click", function(e) {
            if ($(e.target).is(".expertdctr-selectndiv") === false) {
              $(".expertdctr-selectndiv").fadeOut()
            }
        })
        $("#doctorAutoComplete").on("click", function(e) {
            $(".expertdctr-selectndiv").fadeIn()
            e.stopPropagation()
          });
    }
    expertDoctorList () {
        $(".loader").show();
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                "functionName": "RhExpertDoctors",
                "subFunction":"expertDoctorList",
                "groupHospitalAdminId":groupHospitalAdminId
            }),
        };
        apiCalling(apiJson).then((data) => {
            if (data.success === "1") {
                $(".loader").hide();
                this.setState({
                    doctorList:data.expertDoctorData
                })
            } else {
                $(".loader").hide();
                alert(data.errorMessage)
            }
        });
    }
    doctorSearch = () => {
        // console.log("keyword---->"+$('#doctorAutoComplete').val())
        let keyword = $('#doctorAutoComplete').val() == "" ? '' : $('#doctorAutoComplete').val() ;
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                "functionName": "RhExpertDoctors",
                "subFunction":"getDoctorNameAutocompleteGpAdmn",
                "userId":groupHospitalAdminId,
                "keyWord":keyword
            }),
        };
        // console.log("body---->"+apiJson.body)
        apiUrlRelianceFunction(apiJson).then((data) => {
            // console.log("status---->"+data.success)
            // console.log("message---->"+data.errorMessage)
            if (data.success === "1") {
                this.setState({
                    doctorAutoCompleteList:data.expertDoctorData
                })
            } else {
                this.setState({
                    doctorAutoCompleteList:[]
                })
                // alert(data.errorMessage)
            }
        });
    }
    doctorSelect = (item) => {
        $('#doctorAutoComplete').val(item.name)
        this.setState({
            doctorId:item.id,
            doctorAutoCompleteList:[]
        })
    }
    doctorAdd = () => {
        if ($('#doctorAutoComplete').val() == "") {
            alert('Please select a doctor')
        } else {
            $(".loader").show();
            const apiJson = {
                method: "POST",
                body: JSON.stringify({
                    "functionName": "RhExpertDoctors",
                    "subFunction":"saveExpertDocs",
                    "doctorId":this.state.doctorId,
                    "groupHospitalAdminId":groupHospitalAdminId
                }),
            };
            apiCalling(apiJson).then((data) => {
                if (data.success === "1") {
                    $('#doctorAutoComplete').val('')
                    this.setState({
                        doctorAutoCompleteList:[],
                        doctorId:''
                    })
                    this.expertDoctorList()
                } else {
                    $(".loader").show();
                    alert(data.errorMessage)
                }
            });
        }
    }
    doctorDelete = (id) => {
        $(".loader").show();
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                "functionName": "RhExpertDoctors",
                "subFunction":"deleteDoctorFromGroupAdmn",
                "userId": id,
                "groupHospitalAdminId":groupHospitalAdminId
            }),
        };
        apiCalling(apiJson).then((data) => {
            if (data.success === "1") {
                this.expertDoctorList()
            } else {
                $(".loader").hide();
                alert(data.errorMessage)
            }
        });
    }
    render() {
        return (
            <div className="purpleWrap">
                <Suspense fallback={<div></div>}>
                    <section>
                        <Header />
                    </section>
                </Suspense>
                <div className="container">
                    <div className="main-wrapper1  hspmain-wrapper-promo" id="row-col-width-leftmenu">
                        <div className="row">
                            <div className="col-lg-1  d-sm-none d-lg-block d-md-none d-xl-block d-none">
                                <Dashboardleftmenu></Dashboardleftmenu>
                            </div>
                            <div className="col-lg-11">
                                <div className="dashbrd-rght-bottom-padng-hsp">
                                    <div className="tabmain_list">
                                        <h6 className="mb-0">EXPERT DOCTORS</h6>
                                    </div>
                                    <div className="col-sm-12 pt-3 pb-3 prflefrm-border">
                                        <div className="headertext-bg">
                                            <h3 className="form-head py-2 mb-0">Add Expert Doctors</h3>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-3 label-textpos">
                                                <label className="label-text mt-1 label-text-fontpro">Select Expert Doctors</label>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-11">
                                                <input type="text" className="custome_input mb-2 w-100" id="doctorAutoComplete" onChange={() => this.doctorSearch()}/>
                                                {this.state.doctorAutoCompleteList.length > 0 &&
                                                    <div className="expertdctr-selectndiv">
                                                        <ul>
                                                            {this.state.doctorAutoCompleteList.map((item) => (
                                                                <li onClick={() => this.doctorSelect(item)}>{item.name}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-lg-2 pl-0 mt-1 col-md-3 col-1">
                                                <span className="cursor-pontr" onClick={() => this.doctorAdd()}>
                                                    <img src={add} alt="add" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="expertdctr-bgcolor">
                                            <div>
                                                <h3 className="form-head mt-2">Expert Doctors</h3>
                                            </div>
                                            {this.state.doctorList.length > 0 ?
                                                <div className="row">
                                                    {this.state.doctorList.map((item) => (
                                                        <div className="row col-lg-4 col-md-6">
                                                            <div className="col-lg-10 col-md-11  col-11">
                                                                <input type="text" className="custome_input mb-2 w-100" value={item.NAME} disabled/>
                                                            </div>
                                                            <div className="col-lg-1 mt-1 p-0 col-md-1 col-1">
                                                                <span className="cursor-pontr" onClick={() => this.doctorDelete(item.id)}>
                                                                    <img src={close} alt="close" width={20}/>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            :
                                                <div>No data found</div>
                                            }
                                        </div>
                                        <div className="row py-3">
                                              <div className="col-lg-3 col-md-4 col-6 my-1">
                                                <button className="grpadmin-btnupdate">Save</button>
                                              </div>
                                              <div className="col-lg-3 col-md-4 col-6 my-1">
                                                <button className="grpadmin-btnucancel">Cancel</button>
                                              </div>
                                           </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Suspense fallback={<div></div>}>
                    <section>
                        <Footer />
                    </section>
                </Suspense>
            </div>
        );
    }
}
