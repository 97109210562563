import React, { Component, Suspense } from "react";
import Header from "../../Reliance/header"
import Footer from "../../Reliance/footer"

class siteSettingsSiteModule extends Component {
  state = {};
  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />

            <div className="container">
              <div className="sectionItemDiv sectionItemDivBorder sectionBorderadminMangmnt">
                <div className="adminMangemnt-dashboard">
                SiteModule
                </div>
              </div>
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}

export default siteSettingsSiteModule;
