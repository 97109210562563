import React, { Component } from "react";
import $ from "jquery";
import { apiCalling, changeDateFormat } from "../apiService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "rc-time-picker/assets/index.css";
import close from "../image/icons/close-sm.png";
import moment from "moment";
import TimePicker from "rc-time-picker";
const format = "h:mm a";
const now = moment().hour(0).minute(0);

export default class DateTimePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(parseInt(this.props.appTime)),
    };
    this.confirmAppointmentPopUp = this.confirmAppointmentPopUp.bind(this);
  }
  componentDidMount() {
    // alert(this.props.appTime)
    $(".time-date-popup").hide();
    $(".popup-hide").click(function () {
      $(".popup-overlay").hide();
      window.sessionStorage.setItem("confirmId", "");
      $("body").removeClass("overflow-hidden");
    });
  }
  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
    $("#selectDate").val(moment(date).format("DD/MM/YYYY"));
  };
  getDisabledHours() {
    var hours = [];
    for (var i = 0; i < moment().hour(); i++) {
      hours.push(i);
    }
    return hours;
  }
  getDisabledMinutes(selectedHour) {
    var minutes = [];
    if (selectedHour === moment().hour()) {
      for (var i = 0; i < moment().minute(); i++) {
        minutes.push(i);
      }
    }
    return minutes;
  }
  confirmAppointmentPopUp(id) {
    $(".loader2").show();
    let appDate = $("#editAppDate" + id).val();
    let appTime = $("#editAppTime" + id).val();
    let newDates = changeDateFormat(appDate);
    let appointmentTime = moment(appTime, "hh:mm a").format("LT");
    //alert(appTime);
    //console.log("appointmentTime", appointmentTime)

    let appointmentDate = appDate + " " + appTime;
    let newAppDatetest = moment(appointmentDate, "DD/MM/YYYY h:mm A").format(
      "YYYY-MM-DD hh:mm A"
    );
    var todateNow = new Date();
    var todateNow = moment(todateNow).format("YYYY-MM-DD hh:mm A");
    if (todateNow > newAppDatetest) {
      alert("Select the Future Date and Time");
      $(".loader2").hide();
    } else {
      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "confirmVideoAppointment",
          appointmentDate: appDate,
          appointmentTime: appointmentTime,
          appointmentDateTime: new Date(
            moment(appDate, "DD/MM/YYYY").format("YYYY-MM-DD") +
              " " +
              moment(appTime, ["h:mm A"]).format("kk:mm:ss")
          ).getTime(),
          requestVideoAppointmentId: id,
          ratePerSession: "",
          ratePerSessionAudio: "",
          siteId: window.sessionStorage.getItem("siteId"),
          isConfirmAndDeduct: "",
          isReferralWithOtp: "",
        }),
      };
      // console.log("confirmVideoAppointment",apiJson.body);

      apiCalling(apiJson).then((data) => {
        if (data.success == "1") {
          alert(data.successMessage);
          $("#dateTime-popup" + this.props.appId).hide();
          $("#rowOpenApp" + id).hide();
          this.confirmVideoAppointmentMail(data);

          // window.location.reload();
          $("body").removeClass("overflow-hidden");
          $(".popup-overlay").hide();
          $(".loader2").hide();
        } else {
          alert(data.errorMessage);
          $("body").removeClass("overflow-hidden");
          $(".popup-overlay").hide();
          $(".loader2").hide();
        }
      });
    }
  }
  async confirmVideoAppointmentMail(data) {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "confirmVideoAppointmentMail",
        siteId: "",
        jsonMailOptions: data.jsonMailOptions,
        jsonAdminDocMailOptions: data.jsonAdminDocMailOptions,
        smsObj: data.smsObj,
        smsDoctorObj: data.smsDoctorObj,
        smsAdminObj: data.smsAdminObj,
      }),
    };
    //console.log(apiJson.body);
    apiCalling(apiJson).then((data) => {});
  }
  render() {
    return (
      <div
        class="popup-overlay time-date-popup"
        id={"dateTime-popup" + this.props.appId}
      >
        <div className="popup-content">
          <div className="modal-popup">
            <a className="popup-hide close-popup-top">
              <img src={close} alt="close" />
            </a>
            <div className="header border-0">
              {" "}
              Change Appointment Date and Time
              {""}
            </div>
            <div className="content">
              <div class="row my-3">
                <div class="col-6">
                  <label class="label-text">Appointment Date</label>
                  <DatePicker
                    placeholder="Appointment Date"
                    id={"editAppDate" + this.props.appId}
                    name={"editAppDate" + this.props.appId}
                    minDate={new Date()}
                    selected={this.state.startDate}
                    onChange={this.handleChange}
                    dateFormat="dd/MM/yyyy"
                    calendarClassName="react-date-picker"
                  />
                </div>
                <div class="col-6">
                  <label class="label-text">Appointment Time</label>
                  <TimePicker
                    showSecond={false}
                    defaultValue={moment(this.props.appTime)}
                    className="app-popup-time"
                    format={format}
                    use12Hours
                    inputReadOnly
                    id={"editAppTime" + this.props.appId}
                    name={"editAppTime" + this.props.appId}
                    disabledHours={() => this.getDisabledHours()}
                    disabledMinutes={(selectedHour) =>
                      this.getDisabledMinutes(selectedHour)
                    }
                    // onChange={()=>this.handleTime()}
                  />
                  ,
                </div>
              </div>
              <div className="text-center">
                <a href="#">
                  <input
                    type="button"
                    value="Confirm"
                    className="formButtonBg mx-1"
                    onClick={() =>
                      this.confirmAppointmentPopUp(this.props.appId)
                    }
                  ></input>
                </a>
                <input
                  type="button"
                  value="Cancel"
                  className="formButtonBg cancelButtonBg mx-1 popup-hide"
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
