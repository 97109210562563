import React, { Component, Suspense } from "react";
import * as ReactDOM from "react-dom";
import $ from "jquery";
import ReactTooltip from "react-tooltip";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { apiCalling } from "../apiService";
import Edit from "../image/icons/edit.png";
import Delete from "../image/icons/delete-black.png";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
let medicinCountNo = 0;
export default class PrescriptionAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      prescDate: "",
      FromTexChatLinkConditionCheck: this.props.match.params.scheduleId,
      memberId: this.props.match.params.memberId,
      hospitalId: this.props.match.params.hospitalId,
      scheduleId: this.props.match.params.scheduleId,
      patientName: this.props.match.params.patientName,
      doctorId:
        window.sessionStorage.getItem("userId") === ""
          ? this.props.match.params.doctorId
          : window.sessionStorage.getItem("userId"),
      medicineTemplateData: [],
      medicineData: [],
      mostPrescribedMedicineData: [],
      frequency: [],
      intake: [],
      duration: [],
      medicineType: [],
      medicineUnit: [],
      medicinSearch: "",
      errorMessage: "Fetching data...",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.prescAdd = this.prescAdd.bind(this);
    this.prescDetailAdd = this.prescDetailAdd.bind(this);
  }
  handleInputChange = (event) => {
    const { value, name } = event.target;
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "medicineList",
        hospitalId: this.state.hospitalId,
        doctorId: this.state.doctorId,
        key: event.target.value,
        max: "1000000",
        offset: "0",
      }),
    };
    // console.log(apiJson.body)
    apiCalling(apiJson).then((data) => {
      // console.log("fsdfsdf", data);
      if (data.success === "1") {
        this.setState({
          //medicineTemplateData: data.result[0].medicineTemplateData,
          medicineData: data.result[0].medicineData,
          //mostPrescribedMedicineData: data.result[0].mostPrescribedMedicineData,
          frequency: data.result[0].frequency,
          //intake: data.result[0].intake,
          // duration: data.result[0].duration,
          // medicineType: data.result[0].medicineType,
          //medicineUnit: data.result[0].medicineUnit,
        });
        let dataset = data.result[0].medicineData;
        if (dataset.length === 0) {
          this.setState({ errorMessage: "No data available " });
        }
      } else {
        this.setState({ errorMessage: "" });
      }
    });
  };

  handleChange = (date) => {
    this.setState({
      prescDate: date,
    });
  };

  editTemplateData(tempId, templateCode) {
    $("#presc-details-add").show();
    const LazyPage = React.lazy(() => import("../doctor/MedicineTemplate"));
    ReactDOM.render(
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <section>
            <LazyPage
              medicineType={this.state.medicineType}
              medicineUnit={this.state.medicineUnit}
              hospitalId={this.state.hospitalId}
              doctorId={this.state.doctorId}
              frequency={this.state.frequency}
              intake={this.state.intake}
              duration={this.state.duration}
              tempObj={tempId}
              tempName={templateCode}
            />
          </section>
        </Suspense>
      </div>,
      document.getElementById("presc-details-add")
    );
  }

  componentDidMount() {
    // console.log("props", this.props);
    $(".loader").show();
    this.medicineList();

    // this.showmedicin();

    $(".popup-hide").click(function () {
      $(".popup-overlay").hide();
    });

    // setInterval(async () => {
    //  // this.medicineList();
    // }, 100000);
  }
  // reloadTemp() {
  //   this.medicineList();
  // }

  medicineList() {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "medicineList",
        hospitalId: this.state.hospitalId,
        doctorId: this.state.doctorId,
        key: "",
        max: "100000",
        offset: "0",
      }),
    };
    // console.log("medicine list apijson", apiJson.body);
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        // console.log("medicine list dataaaaaaaaaaaa", data);
        $(".loader").hide();
        this.setState({
          medicineTemplateData: data.result[0].medicineTemplateData,
          medicineData: data.result[0].medicineData,
          mostPrescribedMedicineData: data.result[0].mostPrescribedMedicineData,
          frequency: data.result[0].frequency,
          intake: data.result[0].intake,
          duration: data.result[0].duration,
          medicineType: data.result[0].medicineType,
          medicineUnit: data.result[0].medicineUnit,
          errorMessage: "",
        });
      } else {
        this.setState({ errorMessage: "No data available " });
      }
    });
  }
  saveMedicin() {
    let count = medicinCountNo; //parseInt($("#medicineCnt").val());
    let dataSet = [];
    let frequencyAndDosage = "";
    let frequencydata = "";
    let startDate = "";
    let flagss = false;
    for (var i = 0; i < count; i++) {
      if ($("#medicinId" + i).val()) {
        if ($("#frequencyAndDosage" + i).val() === "") {
          alert("Please select frequency & dosage");
          $("#frequencyAndDosage" + i).focus();
          break;
        } else if ($("#intake" + i).val() === "") {
          alert("Please select intake");
          $("#intake" + i).focus();
          break;
        } else if ($("#Instructions" + i).val() === "") {
          alert("Please Enter Instructions");
          $("#Instructions" + i).focus();
          break;
        } else if ($("#DurationF" + i).val() === "") {
          alert("Please Enter Duration");
          $("#DurationF" + i).focus();
          break;
        } else if ($("#DurationS" + i).val() === "") {
          alert("Please Enter Duration");
          $("#DurationS" + i).focus();
          break;
        } else {
          if (i + 1 === count) {
            flagss = true;
          }
          startDate = $("#startDate" + i).val();
          startDate = startDate.split("-");
          startDate = startDate[2] + "/" + startDate[1] + "/" + startDate[0];
          frequencyAndDosage = $("#frequencyAndDosage" + i).val();
          frequencydata = frequencyAndDosage.split("|");
          let fin = "";
          let flag = parseInt(frequencydata[1]);
          if (flag === 1) {
            fin = $("#frequency" + i + "Times1").val();
          }
          if (flag === 2) {
            fin =
              $("#frequency" + i + "Times1").val() +
              "-" +
              $("#frequency" + i + "Times2").val();
          }
          if (flag === 3) {
            fin =
              $("#frequency" + i + "Times1").val() +
              "-" +
              $("#frequency" + i + "Times2").val() +
              "-" +
              $("#frequency" + i + "Times3").val();
          }
          if (flag === 4) {
            fin =
              $("#frequency" + i + "Times1").val() +
              "-" +
              $("#frequency" + i + "Times2").val() +
              "-" +
              $("#frequency" + i + "Times3").val() +
              "-" +
              $("#frequency" + i + "Times4").val();
          }

          var data = {
            doctorVisitMedicineId: "",
            medicineId: $("#medicinId" + i).val(),
            frequency: $("#frequencyAndDosage" + i + " option:selected").text(),
            frequencyId: frequencydata[0],
            dosage: fin,
            intake: $("#intake" + i + " option:selected").text(),
            intakeId: $("#intake" + i).val(),
            instruction: $("#Instructions" + i).val(),
            quantity: $("#DurationF" + i).val(),
            duration: $("#DurationF" + i).val(), //$("#DurationS" + i + " option:selected").text(),
            durationId: $("#DurationS" + i).val(),
            startDate: startDate,
            refill: "yes",
          };
          dataSet.push(data);
        }
      }
    }
    // console.log(dataSet)
    if (flagss === true) {
      $("#saveAndSenDButton").hide();

      if (this.state.FromTexChatLinkConditionCheck === "0") {
        const apiJson = {
          method: "POST",
          body: JSON.stringify({
            functionName: "saveAppointmentPrescription",
            userId: this.state.memberId,
            doctorId: this.state.doctorId,
            hospitalId: this.state.hospitalId,
            type: "",
            medicineImageData: "",
            medicineData: dataSet,
          }),
        };
        $(".loader").show();
        //console.log(apiJson.body)
        apiCalling(apiJson).then((data) => {
          if (data.success === "1") {
            $(".loader").hide();
            alert("Saved and Sent  successfully");
            window.location.reload()
            // window.location =
            //   "/doctor/prescriptionList-" +
            //   this.state.hospitalId +
            //   "-" +
            //   this.state.memberId +
            //   "-" +
            //   this.state.patientName +
            //   "-" +
            //   this.state.scheduleId;
          }
        });
      } else {
        const apiJson = {
          method: "POST",
          body: JSON.stringify({
            functionName: "savePrescription",
            scheduledTimeSlotId: this.state.scheduleId,
            memberId: this.state.memberId,
            doctorId: this.state.doctorId,
            hospitalId: this.state.hospitalId,
            medicineData: dataSet,
          }),
        };
        $(".loader").show();
        // console.log("savePrescription",apiJson.body)
        // debugger;
        apiCalling(apiJson).then((data) => {
          if (data.success === "1") {
            $(".loader").hide();
            alert("Saved and Sent  successfully");
            window.location.reload()
            // window.location =
            //   "/doctor/prescriptionList-" +
            //   this.state.hospitalId +
            //   "-" +
            //   this.state.memberId +
            //   "-" +
            //   this.state.patientName +
            //   "-" +
            //   this.state.scheduleId;
          }
        });
      }
    }
  }
  showMedicineTemplateData(tempId) {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "medicineTemplateDetails",
        templateId: tempId,
      }),
    };
    // console.log("apiJsonapiJsonapiJsonapiJsonapiJson", apiJson.body);
    apiCalling(apiJson).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
        data.medicineTemplateData.map((obj, index) => {
          this.showmedicin("Temp", obj);
        });
      }
    });
  }
  deleteTemplate(templateId) {
    const deleteTemplateJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "deleteMedicineTemplate",
        templateId: templateId,
      }),
    };
    apiCalling(deleteTemplateJson).then((data) => {
      if (data.success === "1") {
        alert("Template Deleted Successfully");
        $(".loader").show();
        window.location.reload();
      } else {
        alert("Template Deletion Unsuccessful");
      }
    });
  }
  showmedicin(type, obj) {
    let medicineCnt = medicinCountNo; //parseInt($("#medicineCnt").val());
    let falgs = "0";
    for (var i = 0; i < medicineCnt; i++) {
      if ($("#medicinId" + i).val() === obj.medicineId.toString()) {
        falgs = "1";
        //alert("Its already added.");
      }
    }
    if (falgs === "0") {
      // debugger;
      $("#saveDiv").show();
      $("#showMedicin").append("<div id='medicine" + medicineCnt + "' />");
      const LazyPage = React.lazy(() => import("../doctor/medicine"));
      ReactDOM.render(
        <div>
          <Suspense fallback={<div>Loading...</div>}>
            <section>
              <LazyPage
                medicineType={this.state.medicineType}
                medicineUnit={this.state.medicineUnit}
                hospitalId={this.state.hospitalId}
                doctorId={this.state.doctorId}
                frequency={this.state.frequency}
                intake={this.state.intake}
                duration={this.state.duration}
                medicineCnt={medicineCnt}
                obj={obj}
                flagType={type}
              />
            </section>
          </Suspense>
        </div>,
        document.getElementById("medicine" + medicineCnt)
      );
      $(".medicineCntClass").val(medicinCountNo + 1);
      medicinCountNo = medicinCountNo + 1;
    }
  }

  prescAdd() {
    $("#presc-add").show();
    const LazyPage = React.lazy(() => import("../doctor/saveMedicin"));
    ReactDOM.render(
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <section>
            <LazyPage
              medicineType={this.state.medicineType}
              medicineUnit={this.state.medicineUnit}
              hospitalId={this.state.hospitalId}
              doctorId={this.state.doctorId}
            />
          </section>
        </Suspense>
      </div>,
      document.getElementById("presc-add")
    );
  }
  prescDetailAdd() {
    $("#presc-details-add").show();
    const LazyPage = React.lazy(() => import("../doctor/MedicineTemplate"));
    ReactDOM.render(
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <section>
            <LazyPage
              medicineType={this.state.medicineType}
              medicineUnit={this.state.medicineUnit}
              hospitalId={this.state.hospitalId}
              doctorId={this.state.doctorId}
              frequency={this.state.frequency}
              intake={this.state.intake}
              duration={this.state.duration}
            />
          </section>
        </Suspense>
      </div>,
      document.getElementById("presc-details-add")
    );
  }
  cancelButton() {
    window.location.reload();
  }
  render() {
    return (
      <div className="purpleWrap presc-add-main">
        <div className="loader"></div>
        <Suspense fallback={<div className="loader"></div>}>
          <section>
            <Header />
            <div className="container-fluid">
              <div class="col-md-12 p-0 mt-3">
                <ul class="breadcrumb">
                  <li>
                    <a href="/doctor/dashboard">Dashboard</a>
                    <i class="fa fa-chevron-right"></i>
                  </li>
                  <li>
                    <a href="/doctor/videoSchedule">Video and Audio Chat</a>
                    <i class="fa fa-chevron-right"></i>
                  </li>
                  <li>Add Prescription</li>
                </ul>
              </div>
              <div className="main-wrapper main-wrapper-padding">
                <div className="form-head mb-0 mb-md-4 prescription-mainhead">
                  PRESCRIPTIONS
                  <span>

                  <Link
                    className="formButton m-0 text-decoration-none"
                    to={{
                      pathname: `/doctor/prescriptionList-${this.props.match.params.hospitalId}-${this.props.match.params.memberId}-${this.props.match.params.patientName}-${this.props.match.params.scheduleId}`,
                      state: { setPage: this.props.location.state ? this.props.location.state.setPage : "" }
                    }}
                  >
                    Prescriptions List
                  </Link>
                  </span>
                </div>
                <input
                  type="hidden"
                  id="medicineCnt"
                  className="medicineCntClass"
                  defaultValue="0"
                />
                <div className="form-section row px-3">
                  <div className="prescrptn-left-main">
                    <div>
                      <div id="showMedicin"></div>
                    </div>
                    <div
                      class="col-md-12 text-right"
                      id="saveDiv"
                      style={{ display: "none" }}
                    >
                      <input
                        type="button"
                        class="cancelButton-black formButtonBg"
                        value="Cancel"
                        onClick={() => this.cancelButton()}
                      />
                      <input
                        type="button"
                        class="formButton"
                        value="Save & Send"
                        id="saveAndSenDButton"
                        onClick={() => this.saveMedicin()}
                      />
                    </div>
                  </div>
                  <div class="prescrptn-right-main">
                    <div class="prescrptn-add-section-right">
                      <div className="row">
                        <div className="col-md-6 col-4 pr-0">
                          <input
                            type="text"
                            className="input-design"
                            placeholder="Search"
                            id="medicinSearch"
                            name="medicinSearch"
                            onChange={this.handleInputChange}
                          ></input>
                        </div>
                        <div className="col-md-2 col-3">
                          <input
                            type="button"
                            className="formButton formButton-sm m-0"
                            value="+ Add"
                            onClick={this.prescAdd}
                          ></input>
                        </div>
                        <div className="col-md-3 col-5">
                          <input
                            type="button"
                            className="formButton formButton-sm m-0"
                            value="+ Add Template"
                            onClick={this.prescDetailAdd}
                          ></input>
                        </div>
                        <div class="col-md-12">
                          <hr></hr>
                        </div>
                      </div>
                      <Tabs className="prescrptn-right-tab">
                        <TabList>
                          <Tab>
                            <div className="prescrptn-right-gh">Drugs</div>
                          </Tab>
                          <Tab>
                            <div className="">Templates</div>
                            {/* <div className="" onClick={() => this.reloadTemp()}>
                              Templates
                            </div> */}
                          </Tab>
                          <Tab>
                            <div className="">Most Prescribed</div>
                          </Tab>
                        </TabList>
                        <TabPanel>
                          <div className="presc-drug-main">
                            {this.state.medicineData.map((obj, index) => {
                              return (
                                <div
                                  className="presc-drug-sub"
                                  onClick={() => this.showmedicin("MD", obj)}
                                >
                                  {obj.medicineName +
                                    " " +
                                    obj.strength +
                                    " " +
                                    obj.medicineUnit}
                                </div>
                              );
                            })}
                            {this.state.errorMessage}
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <div className="presc-drug-main">
                            {this.state.medicineTemplateData.map(
                              (obj, index) => {
                                return (
                                  <div className="presc-drug-sub d-flex align-items-center">
                                    <span
                                      onClick={() =>
                                        this.showMedicineTemplateData(
                                          obj.groupCodeId
                                        )
                                      }
                                    >
                                      {obj.templateCode}
                                    </span>
                                    <button
                                      type="button"
                                      class="btn  p-0 border-0 ml-auto"
                                      onClick={() =>
                                        this.editTemplateData(
                                          obj.groupCodeId,
                                          obj.templateCode
                                        )
                                      }
                                    >
                                      {" "}
                                      <img
                                        src={Edit}
                                        alt="Edit"
                                        className="img-fluid"
                                        width={15}
                                        height={15}
                                      />{" "}
                                    </button>
                                    <button
                                      className="btn-edit-bg pl-2"
                                      onClick={() =>
                                        this.deleteTemplate(obj.groupCodeId)
                                      }
                                    >
                                      <img
                                        src={Delete}
                                        alt="Delete"
                                        className="img-fluid"
                                        width={15}
                                        height={15}
                                      />
                                    </button>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <div className="presc-drug-main">
                            {this.state.mostPrescribedMedicineData.map(
                              (obj, index) => {
                                return (
                                  <div
                                    className="presc-drug-sub"
                                    onClick={() => this.showmedicin("MD", obj)}
                                  >
                                    {obj.medicineName}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </TabPanel>
                      </Tabs>
                    </div>
                  </div>

                  {/* Popup Prescription details*/}
                  <div id="presc-details-add"></div>

                  {/* Popup Prescription details*/}
                  <div id="presc-add"></div>
                </div>
              </div>
            </div>

            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
