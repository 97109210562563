import React, { Component, Suspense } from "react";
import $ from "jquery";
import tick from "../image/theme/tick.png";
export default class DoctorRegSuccess extends Component {

//   windowClose(){
//     window.open("about:blank", "_self");
//     window.close();
//   }
    
     
    render() {
        return (
            <div class="purpleWrap">
                <div class="container">
                    <div class="main-wrapper pt-5 mt-5" style={{backgroundColor:"#fbfbfb"}}>
                        <div class="col-sm-12 text-center pt-5">
                            <img src={tick} width="50"></img>
                            <div class="col-sm-12 form-head">
                                Congratulations
                            </div>
                            <div class="col-sm-12 form-subhead" style={{fontWeight:"400"}}>
                                You have been successfully registered, we will get in touch with you shortly.
                            </div>
                            {/* <button  className="formButton formButtonBold mt-4" onClick={()=>this.windowClose()} >Ok </button> */}
                            {/* <a href="#" onclick="close_window();return false;">close</a> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}