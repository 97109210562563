import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { apiCalling } from "../../apiService";
import $ from "jquery";

var HospitalAutoComplete = function (props) {
  const [nameDisplay, setNameDisplay] = useState(false);
  const [idDisplay, setIdDisplay] = useState(false);
  const [options, setOptions] = useState([]);

  const wrapperRef = useRef(null);

  const setDex = (id, name) => {
    let hospital = {
      hospitalId: id,
      hospitalName: name,
    };
    props.setSearch(hospital);
    setNameDisplay(false);
    setIdDisplay(false);
  };

  useEffect(() => {
    $(".loader").show();
    const getHospitalAutoComplete = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHospitalAutoComplete",
        hospitalName: "",
      }),
    };

    apiCalling(getHospitalAutoComplete).then((data) => {
      if (data.success === "1") {
        setOptions(data.hospitalData);
      }
      $(".loader").hide();
    });
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleModeClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleModeClickOutside);
    };
  }, []);

  const handleModeClickOutside = (e) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(e.target)) {
      setNameDisplay(false);
      setIdDisplay(false);
    }
  };

  return (
    <div ref={wrapperRef} className="flex-container flex-column pos-rel">
      <input
        id="autoHospital"
        onClick={() => {setIdDisplay(!idDisplay);setNameDisplay(false)}}
        onFocus={(e) => e.target.select()}
        placeholder="Hospital ID"
        value={props.tempHospitalData.hospitalId}
        onChange={(e) =>
          props.setSearch({
            hospitalId: e.target.value,
            hospitalName: "",
          })
        }
        className="input-design"
        autocomplete="off"
        style={{ width: "80px" }}
      ></input>
      {idDisplay && (
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            background: "white",
            borderColor: "lightgray",
            borderWidth: 1,
            borderStyle: "solid",
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: -15,
            marginLeft: 15,
            fontSize: 14,
            fontFamily: "Poppins Light",
            width: "calc(100% - 30px)",
          }}
        >
          {options
            .filter(({ hospitalId }) =>
              hospitalId
                .toString()
                .includes(
                  props.tempHospitalData.hospitalId === undefined
                    ? ""
                    : props.tempHospitalData.hospitalId.toString()
                )
            )
            .map((v, i) => {
              return (
                <div
                  onClick={() => {
                    setDex(v.hospitalId, v.hospitalName);
                    props.onItemChange(v);
                  }}
                  className="option"
                  key={i}
                  tabIndex="0"
                >
                  <span>{v.hospitalId}</span>
                </div>
              );
            })}
        </div>
      )}

       <input
        id="autoHospital"
        onClick={() => {setIdDisplay(false);setNameDisplay(!nameDisplay)}}
        onFocus={(e) => e.target.select()}
        placeholder="Hospital Name"
        value={props.tempHospitalData.hospitalName}
        onChange={(e) =>
          props.setSearch({
            hospitalId: '',
            hospitalName:e.target.value ,
          })
        }
        className="input-design"
        autocomplete="off"
        style={{ width: "180px" }}
      ></input>
      {nameDisplay && (
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            background: "white",
            borderColor: "lightgray",
            borderWidth: 1,
            borderStyle: "solid",
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: -15,
            marginLeft: 15,
            fontSize: 14,
            fontFamily: "Poppins Light",
            width: "calc(100% - 30px)",
          }}
        >
          {options
            .filter(({ hospitalName }) =>
              hospitalName
                .toLocaleLowerCase()
                .includes(
                  props.tempHospitalData.hospitalName.toLocaleLowerCase()
                )
            )
            .map((v, i) => {
              return (
                <div
                  onClick={() => {
                    setDex(v.hospitalId, v.hospitalName);
                    props.onItemChange(v);
                  }}
                  className="option"
                  key={i}
                  tabIndex="0"
                >
                  <span>{v.hospitalName}</span>
                </div>
              );
            })}
        </div>
      )}  
    </div>
  );
};

export default HospitalAutoComplete;
