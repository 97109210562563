import React, { Component } from 'react';
import $ from "jquery";
import Pagination from "react-js-pagination";
import { apiCalling } from "../apiService";
import * as XLSX from "xlsx";
import exportFromJSON from 'export-from-json'
let groupHospitalAdminId = ''
export default class ErrorList extends Component {
  constructor(props) {
    super(props);    
    this.state = {
        memberPlanData:[],
        memberPlanCountData:'',  
        disabled:true
      };
      this.errorChange = this.errorChange.bind(this);
      this.fileDownload = this.fileDownload.bind(this);
  }
  errorChange = () => {
    this.setState({ disabled: $('#errorSelect').val() == 0 ? true : false });
    if($('#errorSelect').val() == 0) {
      $('#errorSearch').val('')
    }
  }
  componentDidMount() {
    $("#changePassword").addClass("dashBoardLeftMenu-active");
    groupHospitalAdminId =window.sessionStorage.getItem("userId");
    this.getErrorReport()
  }
  getErrorReport () {
    $(".loader").show();
    const apiJson = {
        method: "POST",
        body: JSON.stringify({
          "functionName": "rhCouponCode",
          "subFunction":"errorReports",
          "txtInput":$('#errorSearch').val(),
          "cmbSelect":$('#errorSelect').val(),
          "groupHospitalAdminId":groupHospitalAdminId
        }),
    };
    apiCalling(apiJson).then((data) => {
        if (data.success === "1") {
            $(".loader").hide();
            this.setState({
              memberPlanData: data.memberPlanData,
              memberPlanCountData: data.memberPlanCountData
            })
        } else {
            $(".loader").hide();
            this.setState({
              memberPlanData:[],
              memberPlanCountData:''
            })
            alert(data.errorMessage)
        }
    });
  }
  refresh = () => {
    $('#errorSelect').val(0)
     $('#errorSearch').val('')
    this.getErrorReport()
  }
  searchData = () => {
    if ($('#errorSelect').val() == 1 && $('#errorSearch').val() == "") {
        alert('Enter Policy Number')
    } else if ($('#errorSelect').val() == 2 && $('#errorSearch').val() == "") {
        alert('Enter File Name')
    } else {
      this.getErrorReport()
    }
  }
  fileDownload = () => {
        var fileName = "error_list";
        var exportType = "xls";
        var data = this.state.memberPlanData;
        exportFromJSON({ data, fileName, exportType });
  }
 render() {
    return (

<div className='group_coupons'>
<div className='row align-items-center my-4'>
             <div className='col-md-3'>
              <select className="custome_input mb-0" id="errorSelect" onChange={() => this.errorChange()}>
                  <option value="0">All</option>
                  <option value="1">Policy Number</option>
                  <option value="2">File Name</option>
              </select>
              </div>
              <div className='col-md-3'>
              <input type="text" className="custome_input mb-0 " id="errorSearch" disabled={this.state.disabled}/>
              </div>
              <div className="col-md-6 coupon_btn d-flex justify-content-start mt-2 flex-wrap">
                <input type="submit" className="formBtnBg mr-5" value="Search" onClick={() => this.searchData("Policy")}/>
                <input type="submit" className="formBtnBg mr-5" value="Refresh" onClick={() => this.refresh()}/>
                <input type="submit" className="formBtnBg" value="Download" onClick={() => this.fileDownload()}/>
        </div>
            </div>
            <div class="table-responsive mb-3 custom_scroll">
                                <table class="table custom_table">
                                    <thead>
                                    <tr>
                                        <th>Excel Row Number</th>	
                                        <th>Policy Number</th>																		
                                        <th>Date Of Birth</th>
                                        <th>Policy Start Date</th>
                                        <th>Policy End Date</th>
                                        <th>Employee ID</th>
                                        <th>Error Message</th>
                                        <th>File Name</th>
                                        <th>Uploaded Date</th>																																				
                                    </tr>
                                    </thead>
                                    {this.state.memberPlanData.length > 0 ?
                                      <tbody>
                                        {this.state.memberPlanData.map((item) => (
                                          <tr>
                                          <td>{item.rowId}</td>
                                          <td>{item.policyNumber}</td>
                                          <td>{item.dob}</td>
                                          <td  className="text-nowrap">{item.startDate}</td>
                                          <td  className="text-nowrap">{item.endDate}</td>
                                          <td>{item.empId}</td>
                                          <td>{item.errorMsgData}</td>
                                          <td>{item.csvFileName}</td>
                                          <td>{item.createdOn}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                      :
                                        <tbody>
                                          <tr>
                                            <td colspan={9}>No data Found</td>
                                          </tr>
                                        </tbody>
                                      }
                                    </table>
                                   
            </div>
            <div className="text-right">
                                      {/* <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={10}
                                        totalItemsCount={40}
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePageChange.bind(this)}
                                      /> */}
            </div>
            </div>
                )
            }
           }