import React, { Component, Suspense } from "react";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import Pagination from "react-js-pagination";
import { apiCalling,apiUrlRelianceFunction } from "../apiService";
import csv from "../image/icons/csv.png";
import { Link } from "react-router-dom";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class videoAudioFeedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentData: [],
            toDate: "",
            fromDate: "",
            totalCount: 0,
            activePage: 1,
            fuctionName: 'VideoAudioPayment Report',
            csvDetails: [],
            showMessage: "Waiting for data... "
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeTo = this.handleChangeTo.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.videoAudioReport();
    }


    handleChange = (date) => {
        this.setState({
            fromDate: date,
        });
    };
    handleChangeTo = (date) => {
        this.setState({
            toDate: date,
        });
    };

    changeHandler = (event) => {

        const { value, name } = event.target;
        this.setState({
            [name]: value,
        })
    }

    handlePageChange(pageNumber) {
        window.scrollTo(0, 0);
        var startDate = $("#fromDate").val();
        var toDate = $("#toDate").val();
        var newDate = moment(startDate, "DD-MM-YYYY").format("MM-DD-YYYY")
        var SearchDate = new Date(newDate)
        var milliStartDate = SearchDate.getTime();

        if (newDate === 'Invalid date') {
            SearchDate = "";
            milliStartDate = "";
        }
        var endDate = moment(toDate, "DD-MM-YYYY").format("MM-DD-YYYY")
        var SearchEndDate = new Date(endDate)
        var milliEndDate = SearchEndDate.getTime() + 86400000;
        if (endDate === 'Invalid date') {
            SearchEndDate = "";
            milliEndDate = "";
        }
        this.setState({ activePage: pageNumber });
        var offset;
        var max;
        if (pageNumber === "1") {
            offset = "0";
            max = "10";
            this.setState({
                activePage: pageNumber,
                isLoading: '0'
            })
        }
        else {
            offset = parseInt(pageNumber - 1) * 10;
            max = "10";
            this.setState({
                activePage: pageNumber,
                isLoading: '0'
            })
        }
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "paymentReport",
                subFunctionName: "videoAudioPayment",
                userId:this.props.match.params.groupHospitalId, //"19238",
                max: max,
                offset: JSON.stringify(offset),
                cmbCategory1: $("#videoAudioSelect").val() === '0' ? '' : $("#videoAudioSelect").val(),
                searchKeyWord: $("#txtVideoAudio").val(),
                appointmentStatus:$("#appointmentStatusType").val() === '0' ? '' : $("#appointmentStatusType").val(),
                appointmentType:$("#appointmentType1").val() === '0' ? '' : $("#appointmentType1").val(),
                fromDate: milliStartDate,
                toDate: milliEndDate
            }),
        };
        $(".loader").show();
        let apiFunction=folderName!== null ? apiCalling : apiUrlRelianceFunction
        apiFunction(apiJson).then((data) => {
            if (data.success === "1") {
                this.setState({
                    paymentData: data.result.paymentData,
                    totalCount: data.result.count,
                });
                $(".loader").hide();
            }
        });
    }

    videoAudioReport() {
        $(".loader").show();
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "paymentReport",
                subFunctionName: "videoAudioPayment",
                userId:this.props.match.params.groupHospitalId, // "19238",
                max: "10",
                offset: "0"
            }),
        };
       // console.log(apiJson.body)
        let abcc=folderName!= null ? "a1" : "apiUrlRelianceFunction"
       // alert(abcc)
        let apiFunction=folderName!== null ? apiCalling : apiUrlRelianceFunction
        //console.log(apiFunction)
        apiFunction(apiJson).then((data) => {
            $(".loader").hide();
            //alert(data.success)
            if (data.success === "1") {
                var count = 0;
                for(let i=0;i<data.result.count;i++){
                    this.state.paymentData.push(data.result.paymentData[i])
                    this.state.csvDetails.push(data.result.paymentExportData[i])
                    count = i+1;
                }
                this.setState({totalCount:count})
                // this.setState({
                //     paymentData: data.result.paymentData,
                //     csvDetails:data.result.paymentExportData,
                //     totalCount: data.result.count,
                // });
            }
            if (data.success === "0") {
                $(".loader").hide();
                this.setState({
                    paymentData: [],
                    csvDetails:[],
                    showMessage: data.errorMessage
                });
            }
        });
    }

    changeText = () => {
        if ($("#videoAudioSelect").val() !== '0') {
            document.getElementById("txtVideoAudio").disabled = false
            $('input[name=txtVideoAudio]').focus();
        }
        else {
            document.getElementById("txtVideoAudio").disabled = true
        }
        document.getElementById("txtVideoAudio").value = ""
    }

    searchHandler() {

        var startDate = $("#fromDate").val();
        var toDate = $("#toDate").val();
        var newDate = moment(startDate, "DD-MM-YYYY").format("MM-DD-YYYY")
        var SearchDate = new Date(newDate)
        var milliStartDate = SearchDate.getTime();

        if (newDate === 'Invalid date') {
            SearchDate = "";
            milliStartDate = "";
        }
        var endDate = moment(toDate, "DD-MM-YYYY").format("MM-DD-YYYY")
        var SearchEndDate = new Date(endDate)
        var milliEndDate = SearchEndDate.getTime() + 86400000;
        if (endDate === 'Invalid date') {
            SearchEndDate = "";
            milliEndDate = "";
        }

        if ($("#videoAudioSelect").val() !== "0" && $("#txtVideoAudio").val() === "") {
            var t = document.getElementById("videoAudioSelect");
            var selectedText = t.options[t.selectedIndex].text;
            alert(selectedText + " Required");
            $("#txtVideoAudio").focus();
            return false;
        }
        if (milliStartDate > milliEndDate) {
            alert("To date should be greater than or equal to from date")
            return false
        }
        else {
            const searchData = {
                method: "POST",
                body: JSON.stringify({
                    functionName: "paymentReport",
                    subFunctionName: "videoAudioPayment",
                    userId:this.props.match.params.groupHospitalId, //"19238",
                    max: "10",
                    offset: "0",
                    cmbCategory1: $("#videoAudioSelect").val() === '0' ? '' : $("#videoAudioSelect").val(),
                    searchKeyWord: $("#txtVideoAudio").val(),
                    appointmentStatus:$("#appointmentStatusType").val() === '0' ? '' : $("#appointmentStatusType").val(),
                     appointmentType:$("#appointmentType1").val() === '0' ? '' : $("#appointmentType1").val(),
                    fromDate: milliStartDate,
                    toDate: milliEndDate
                }),
            };
            $(".loader").show();
            let apiFunction=folderName!== null ? apiCalling : apiUrlRelianceFunction
            apiFunction(searchData).then((data) => {
                if (data.success === "1") {
                    $(".loader").hide();
                    this.setState({
                        paymentData: data.result.paymentData,
                        csvDetails:data.result.paymentExportData,
                        totalCount: data.result.count,
                        activePage: 1,
                    });
                } if (data.success === "0") {
                    $(".loader").hide();
                    this.setState({
                        paymentData: [],
                        showMessage: data.errorMessage,
                        csvDetails:[]
                    });
                }
            })
        }
    }

    csvFileDownload() {

        const csvRow = [];
        var columnDetails = [['Patient%20Name', 'Hospital%20Name', 'Doctor%20Name', 'Chat%20Requested%20Date', 'Transaction%20Id', 'Appointment%20Type', 'Appointment%20Time', 'Appointment%20Status', 'Coupon%20Amount', 'Doctor%20Charge', 'Amount%20Received']]
        var results = this.state.csvDetails;

        for (var item = 0; item < results.length; item++) {

            columnDetails.push([results[item].memName.replaceAll(' ', '%20'), results[item].hospitalName.replaceAll(' ', '%20'), results[item].docName.replaceAll(' ', '%20'), moment(results[item].requestedDate).format("DD-MMM-YYYY").replaceAll(' ', '%20'), results[item].transactionId.replaceAll(' ', '%20'), results[item].type.replaceAll(' ', '%20'), moment(results[item].requestedDate).format("hh:mm A").replaceAll(' ', '%20'), results[item].statusName.replaceAll(' ', '%20'),results[item].promoAmt.replaceAll(' ', '%20'), results[item].actualAmount.replaceAll(' ', '%20'), results[item].amount.replaceAll(' ', '%20')])
        }

        for (var i = 0; i < columnDetails.length; ++i) {
            csvRow.push(columnDetails[i].join(","))
        }

        var csvString = csvRow.join("%0A");

        var dataType = document.createElement("a");

        dataType.href = 'data:attachment/csv,' + csvString;
        dataType.target = "_Blank";
        dataType.download = this.state.fuctionName + '.csv';
        document.body.appendChild(dataType);
        dataType.click();
    }


    render() {
        return (
            <div class="purpleWrap">
                <Suspense fallback={<div></div>}>
                    <section>
                        <Header />
                    </section>
                </Suspense>
                <div class="container">
                    <div class="main-wrapper1 date_width  dashbrd-rght-bottom-padng-hsp">
                        <div class="col-md-12 p-0">
                            <ul class="breadcrumb">
                                <li>
                                    <Link to="/admin/dashboard/">Dashboard</Link>
                                    <i class="fa fa-chevron-right"></i>
                                </li>
                                <li>Video /Audio Chat Payment Report</li>
                            </ul>
                        </div>
                        <div class="form-head"> Video /Audio Chat Payment Report </div>
                        <div class="row bg_ddd pt-3 mx-0">
                            <div class="col-md-2 mb-3">
                                <select class="custome_input mb-0"
                                    id="videoAudioSelect"
                                    name="videoAudioSelect"
                                    onChange={() => this.changeText()}
                                >
                                    <option value="0">- - Select - -</option>
                                    <option value="Patient Name">Patient Name</option>
                                    <option value="Doctor Name"> Doctor Name</option>
                                    <option value="Hospital Name">Hospital Name</option>
                                    <option value="Transaction Id"> Transaction ID</option>
                                </select>
                            </div>
                            <div class="col-md-2 mb-3">
                                <input
                                    type="text"
                                    class="custome_input mb-0"
                                    id="txtVideoAudio"
                                    name="txtVideoAudio"
                                    disabled
                                />
                            </div>
                            <div class="col-md-2 mb-3">
                                <select class="custome_input mb-0"
                                    id="appointmentType1"
                                    name="appointmentType1"
                                >
                                    <option value="0">Appointment Type </option>
                                    <option value="Video Chat">Video Chat</option>
                                    <option value="Audio Chat">Audio Chat</option>
                                </select>
                            </div>
                            <div class="col-md-2 mb-3">
                                <select class="custome_input mb-0"
                                    id="appointmentStatusType"
                                    name="appointmentStatusType"
                                >
                                    <option value="0">Appointment Status</option>
                                    <option value="Open">Open</option>
                                    <option value="Confirmed">Confirmed</option>
                                    <option value="Rejected">Rejected</option>
                                    <option value="Cancel">Cancel</option>
                                    <option value="Completed">Completed</option>
                                    <option value="Call Completed">Call Completed</option>
                                </select>
                            </div>
                            <div class="col-md-2 mb-3">
                                <DatePicker
                                    placeholderText="From Date"
                                    id="fromDate"
                                    name="fromDate"
                                    selected={this.state.fromDate}
                                    onChange={this.handleChange}
                                    dateFormat="dd/MM/yyyy"
                                    calendarClassName="react-date-picker"
                                    className="custome_input mb-0  datepickerIcon-hsp"
                                />
                            </div>
                            <div class="col-md-2 mb-3">
                                <DatePicker
                                    placeholderText="To Date"
                                    id="toDate"
                                    name="toDate"
                                    selected={this.state.toDate}
                                    onChange={this.handleChangeTo}
                                    dateFormat="dd/MM/yyyy"
                                    calendarClassName="react-date-picker"
                                    className="custome_input mb-0  datepickerIcon-hsp"
                                />
                            </div>
                            <div class="col-md-2 mb-2 d-flex align-items-center ml-auto">
                                <input
                                    type="submit"
                                    value="Search"
                                    class="formBtnBg w-100 mb-0"
                                    onClick={() => this.searchHandler()}
                                />
                                <a onClick={() => this.csvFileDownload()} className="cursor-pointer"><img src={csv} alt="csv" width="25" title="Export CSV" className="mt-1" /></a>
                            </div>
                        </div>
                        {this.state.paymentData.length > 0 ?
                        <div>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Patient Name</th>
                                            <th>Hospital Name</th>
                                            <th>Doctor Name</th>
                                            <th>Video  Chat Requested Date</th>
                                            <th>Transaction Id</th>
                                            <th>Appointment Type</th>
                                            <th> Appointment Time</th>
                                            <th>Appointment Status </th>
                                            <th>Coupon Amount</th>
                                            <th>Doctor Charge</th>
                                            <th>Amount Received</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ fontSize: "12px" }}>
                                        {this.state.paymentData.map((data) => {
                                            let requestedDate = moment(data.requestedDate).format("DD-MMM-YYYY");
                                            let appointmentTime =  moment(data.requestedDate).format("hh:mm A");
                                            return (
                                                <tr>
                                                    <td>{data.memName}</td>
                                                    <td >{data.hospitalName}</td>
                                                    <td>Dr.{data.docName}</td>
                                                    <td>{requestedDate === '01-Jan-1970' ? '' : requestedDate}</td>
                                                    <td>{data.transactionId}</td>
                                                    <td>{data.type}</td>
                                                    <td>{appointmentTime}</td>
                                                    <td>{data.statusName}</td>
                                                    <td>₹ {data.promoAmt}</td>
                                                    <td>₹ {data.actualAmount}</td>
                                                    <td>₹ {data.amount}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                </div>
                                {this.state.totalCount > 5 ?
                                    <div className="paginationSection orderm-paginationSection text-right">
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={10}
                                            totalItemsCount={this.state.totalCount}
                                            pageRangeDisplayed={10}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                    : ""}
                            </div>
                            :
                            <p className="error-msg text-center mb-0"> {this.state.showMessage} </p>
                        }
                    </div>
                </div>
                <Suspense fallback={<div></div>}>
                    <section>
                        <Footer />
                    </section>
                </Suspense>
            </div>
        );
    }
}
