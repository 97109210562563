import React, { Component, Suspense } from "react";
import SiteSettingsSiteList from "./siteSettings-AddEdit-List";
import SiteSettingsSiteUpdate from "./siteSettings-AddEdit-Update";
import AddHeaderLink from "./addHeaderLink";
import AddPromoCode from "./addPromoCode";
import AddFooterLink from "./addFooterLink";
import Header from "../../Reliance/header";
import Footer from "../../Reliance/footer";
import { apiCalling } from "../../apiService";

class siteSettingsAddEdit extends Component {
  state = {
    mode: "list",
    siteId: 0,
    currentPage: "Site Settings List",
    groupHospitalAdminId: null,
    navigation: [
      { pageName: "Dashboard", url: "/admin/dashboard" },
      // { pageName: "Admin Management", url: "/admin/dashboardAdmin" },
      { pageName: "Site Settings", url: "/adminManagement/SiteSettings" },
      {
        pageName: "Site Settings List",
        url: "/adminManagement/SiteSettings-AddEdit",
        current: true,
      },
    ],
    imagePathData: {},
  };

  componentDidMount() {
    this.getImagePath()
  }

  handleView() {
    if (this.state.mode == "list") {
      return <SiteSettingsSiteList changeMode={this.handleModeChange} />;
    } else if (this.state.mode == "addNew") {
      return (
        <SiteSettingsSiteUpdate
          changeMode={this.handleModeChange}
          siteId={this.state.siteId}
          imagePathData={this.state.imagePathData}
          groupHospitalAdminId=""
        />
      );
    } else if (this.state.mode == "edit") {
      return (
        <SiteSettingsSiteUpdate
          changeMode={this.handleModeChange}
          siteId={this.state.siteId}
          imagePathData={this.state.imagePathData}
          groupHospitalAdminId={this.state.groupHospitalAdminId}
        />
      );
    } else if (this.state.mode == "addNewHeaderLink") {
      return (
        <AddHeaderLink
          changeMode={this.handleModeChange}
          siteId={this.state.siteId}
          groupHospitalAdminId={this.state.groupHospitalAdminId}
        />
      );
    } else if (this.state.mode == "addNewFooterLink") {
      return (
        <AddFooterLink
          changeMode={this.handleModeChange}
          siteId={this.state.siteId}
          groupHospitalAdminId={this.state.groupHospitalAdminId}
        />
      );
    } else if (this.state.mode == "addNewPromoCode") {
      return (
        <AddPromoCode
          changeMode={this.handleModeChange}
          siteId={this.state.siteId}
          groupHospitalAdminId={this.state.groupHospitalAdminId}
        />
      );
    } else {
      return <div>Error occured..</div>;
    }
  }

  handleModeChange = (
    mode,
    navigation,
    siteId = 0,
    currentPage = "",
    groupHospitalAdminId = null
  ) => {
    this.setState({
      mode,
      navigation,
      siteId,
      currentPage,
      groupHospitalAdminId,
    });
  };

  getImagePath() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
        siteId: window.sessionStorage.getItem("siteId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          imagePathData: data.configPathDetails[0],
        });
      }
    });
  }

  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead d-flex flex-column">
        <Suspense fallback={<div>Waiting...</div>}>
          <section>
            <Header />
            <div className="loader"></div>
            <div className="container">
              <div>
                <ul className="breadcrum-adminMangmnt">
                  {this.state.navigation.map((nav) => (
                    <li key={nav.pageName}>
                      <a
                        href={nav.url}
                        className={nav.current ? "current" : null}
                      >
                        {nav.pageName}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="head-hospMangmntList">
                {this.state.currentPage}
              </div>
              {this.handleView()}
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}

export default siteSettingsAddEdit;
