import React, { Component, Suspense } from "react";
import Closebtn from "../../image/icons/close-sm.png";
import AddImg from "../../image/icons/addbtn.png";
import { apiCalling } from "../../apiService";
import $ from "jquery";
const folderName = window.sessionStorage.getItem("homeName");
// const Header = React.lazy(() => import("../" + folderName + "/header"));
// const Footer = React.lazy(() => import("../" + folderName + "/footer"));


export default class Achievementsdetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      achievementTypeData: [],
      achievementDetailsArray: []
    }

  }
  componentDidMount() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        "functionName": "getDropdownLists"

      })
    };
    // console.log("listinggg.222222..", apiJson)
    apiCalling(apiJson).then((data) => {
     

      if (data.success === "1") {
        this.setState({
          achievementTypeData: data.result[0].achievementTypeData,
        }
        ,()=>{
          if(this.props.achievementsData.length > 0){
            this.setState({
              achievementDetailsArray:this.props.achievementsData
            })
          }else{
            this.setState({
              achievementDetailsArray:[]
            })
          }
          // console.log("this.props.achievementsData..", this.props.achievementsData)
        }
        )
      }

    });
  }
  addAchievementsDetails() {
    var flag = false

    for (var i = 0; i <= this.state.achievementDetailsArray.length; i++) {
      if ($("#cmbAchievements" + i).val() === "0") {
        alert("Achievements Required")
        $("#cmbAchievements" + i).focus();
        flag = true;
        break;
      }
    }

    if (flag === false) {
      var a = {
        "Id": $("#cmbAchievements").val(),
        "description": $("#txtDescription").val()
      }
      this.state.achievementDetailsArray.push(a)
      // console.log("achievementDetailsArray....", this.state.achievementDetailsArray)
      this.setState({
        achievementDetailsArray: this.state.achievementDetailsArray
      })
    }
    $("#cmbAchievements").val('0')
    $("#txtDescription").val('')
  }
  setAchievementData(value,name,i, mode) {
    if (mode === "cmbAchievements") {
      this.state.achievementDetailsArray[i].Id = $("#" + mode + i).val()
    } else if (mode === "txtDescription") {
      this.state.achievementDetailsArray[i].description = $("#" + mode + i).val()
    } 
    // console.log("achievementDetailsArray...set......", this.state.achievementDetailsArray)
    this.setState({
      [name]: value,
      achievementDetailsArray: this.state.achievementDetailsArray
    })
   
  }
  removeAchievementDetails(Id) {

    var data = $.grep(this.state.achievementDetailsArray, function (e) {
      return e.Id !== Id;
    });
    this.state.achievementDetailsArray = data
    this.setState({
      achievementDetailsArray: this.state.achievementDetailsArray
    })
  }

  achievements(){
    var c = {
      achievementArray:this.state.achievementDetailsArray
    }
    return c;
  }
  enableAchievement(){
    if($("#cmbAchievements").val() !== "0"){
      $("#txtDescription").prop("disabled", false);
    }else{
      $("#txtDescription").prop("disabled", true);
    }
  }
  render() {
    return (
      <div className="bg-clr-wthHead mb-4">
        <Suspense fallback={<div></div>}>
          <section>
            <div className="consultngTime-wrapper">
              <h2 className="hspInfoSubhead">Achievements Details</h2>

              <div className="hsptaltimeHead">
                <div className="educationdoctr-contentdiv">
                  <label className="educationdoctrlabelwidth">Achievements </label>
                  <select name="cmbAchievements" id="cmbAchievements" className="hspInfofieldinput eductninputwidthdctr" onChange={()=>this.enableAchievement()}>
                    <option value="0">Select</option>
                    {this.state.achievementTypeData.map((eduObj, s) => {
                      return (
                        <option key={eduObj.achievementTypeId} value={eduObj.achievementTypeId}>{eduObj.achievementTypeName}</option>
                      )
                    })}
                  </select>
                </div>

                <div className="hsptimefieldDiv pl-2 hsptimefieldDivMob-time">
                  <label className="educationlabelwidthdctr">Description</label>
                  <input type="text" id="txtDescription" name="txtDescription" className="hspInfofieldinput eductninputwidthdctr" placeholder="Description" disabled />
                </div>
                <div className="addimgeDivHsp">
                  <img src={AddImg} alt="Add" onClick={() => this.addAchievementsDetails()} />
                </div>
              </div>

              {this.state.achievementDetailsArray.map((Obj, i) => {
                return (
                  <div key={Obj.Id} className="hsptaltimeHead">
                    <div className="educationdoctr-contentdiv">
                      <label className="eductninputwidthdctr">Achievements</label>
                      <select name={"cmbAchievements"+i} id={"cmbAchievements"+i} value={Obj.Id} className="hspInfofieldinput eductninputwidthdctr"  onChange={(e) => this.setAchievementData(e,"cmbAchievements"+i,i, "cmbAchievements")}>
                        <option value="0">Select</option>
                        {this.state.achievementTypeData.map((eduObj, s) => {
                          return (
                            <option key={eduObj.achievementTypeId} value={eduObj.achievementTypeId}>{eduObj.achievementTypeName}</option>
                          )
                        })}
                      </select>
                    </div>

                    <div className="hsptimefieldDiv pl-2 hsptimefieldDivMob-time">
                      <label className="educationlabelwidthdctr">Description</label>
                      <input type="text" id={"txtDescription"+i} name={"txtDescription"+i} value={Obj.description} className="hspInfofieldinput eductninputwidthdctr" placeholder="Description"  onChange={(e) => this.setAchievementData(e,"txtDescription"+i,i, "txtDescription")}/>
                    </div>
                    <div className="addimgeDivHsp">
                      <img src={Closebtn} alt="Close" onClick={() => this.removeAchievementDetails(Obj.Id)} width="20"/>
                    </div>
                  </div>
                  )
              })}
                    </div>

          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
        </Suspense>
      </div>
    );
  }
}
