import React, { Component, Suspense, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { apiCalling, formatDatePickerToddMMyyyy } from "../../apiService";
import $ from "jquery";
import Header from "../../Reliance/header";
import Footer from "../../Reliance/footer";
import { addDays } from "date-fns";

const today = moment(new Date(), "DD-MM-YYYY").format("YYYY-MM-DD");
const twoYearAfterToday = moment(addDays(new Date(), 730), "DD-MM-YYYY").format("YYYY-MM-DD");

export default class SmsSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hospitalData: [],
      availTxt: 0,
      addTxt: 0,
      availPromotional: 0,
      addPromotional: 0,
      smsTxtSender: "",
      publicKeyTxt: "",
      secretKeyTxt: "",
      transactionalFromDate: today,
      transactionalToDate: twoYearAfterToday,
      promotionDateFrom: today,
      promotionDateTo: twoYearAfterToday,
    };
    this.textType = this.textType.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addCountChange = this.addCountChange.bind(this);
    this.addPromotionalChange = this.addPromotionalChange.bind(this);
    this.selectHospital = this.selectHospital.bind(this);
  }

  selectHospital() {
    this.setState({
      hospitalId: "",
    });
    let obj = this.state.hospitalData.filter(
      (o) => o.hospitalId.toString() === $("#txtHospitalIdNew").val()
    );

    if (obj.length > 0) {
      this.setState(
        {
          hospitalId: obj[0].hospitalId,
        },
        () => {
          $("#hospList").val(this.state.hospitalId);
          this.hospitalDetails();
        }
      );
    } else {
      this.setState(
        {
          hospitalId: "",
        },
        () => {
          $("#hospList").val("0");
        }
      );
    }
  }
  componentDidMount() {
    this.hospitalList();
  }

  hospitalList() {
    const getDetails = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHospitalList",
      }),
    };
    apiCalling(getDetails).then((data) => {
      if (data.success === "1") {
        this.setState({ hospitalData: data.HospitalData });
      }
    });
  }

  hospitalDetails() {
    const hospitalResult = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHospitalSMSDetails",
        hospitalId: $("#hospList").val(),
      }),
    };
    apiCalling(hospitalResult).then((data) => {
      if (data.success === "1") {
       /* let TransactionalSmsValidFrom = moment(
          data.hospitalSmsData[0].TransactionalSmsValidFrom,
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD");
        let TransactionalSmsValidTo = moment(
          data.hospitalSmsData[0].TransactionalSmsValidTo,
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD");
        let promotionalSmsValidFrom = moment(
          data.hospitalSmsData[0].promotionalSmsValidFrom,
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD");
        let promotionalSmsValidTo = moment(
          data.hospitalSmsData[0].promotionalSmsValidTo,
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD");*/

        this.setState({
          availTxt: Number(data.hospitalSmsData[0].availTransactionalSmsCount),
          availPromotional:  Number(data.hospitalSmsData[0].availPromotionalSmsCount),
          smsTxtSender: data.hospitalSmsData[0].smsSender,
          transactionalFromDate: today,
          transactionalToDate: twoYearAfterToday,
          promotionDateFrom: today,
          promotionDateTo: twoYearAfterToday,
        });
        // $("#transactionalFromDate").val(TransactionalSmsValidFrom)
        // $("#transactionalToDate").val(TransactionalSmsValidTo)
        // $("#promotionDateFrom").val(promotionalSmsValidFrom)
        // $("#promotionDateTo").val(promotionalSmsValidTo)
        if (data.hospitalSmsData[0].isExcludeFromPurpleHealth === true) {
          $("#chkTxtExclude").prop("checked", true);
        } else {
          $("#chkTxtExclude").prop("checked", false);
        }
      }
    });
  }

  saveHandle() {
    let transactionalSmsValidFrom = moment(
      $("#transactionalFromDate").val(),
      "YYYY-MM-DD"
    ).format("DD/MM/YYYY");
    let transactionalSmsValidTo = moment(
      $("#transactionalToDate").val(),
      "YYYY-MM-DD"
    ).format("DD/MM/YYYY");
    let promotionalSmsValidFrom = moment(
      $("#promotionDateFrom").val(),
      "YYYY-MM-DD"
    ).format("DD/MM/YYYY");
    let promotionalSmsValidTo = moment(
      $("#promotionDateTo").val(),
      "YYYY-MM-DD"
    ).format("DD/MM/YYYY");

    if ($("#hospList").val() === "") {
      alert("Hospital Name Required");
      $("#hospList").focus();
      return false;
    } else {
      // console.log(typeof this.state.availTxt)
      let finalSmsCount = this.state.availTxt+this.state.addTxt
      let finalPromotionalSmsCount = this.state.availPromotional+this.state.addPromotional
      const smsDetails = {
        method: "POST",
        body: JSON.stringify({
          functionName: "saveHospitalSmsDetails",
          availTransactionalSmsCount: this.state.availTxt,
          addTransactionalSmsCount: finalSmsCount,
          availPromotionalSmsCount: this.state.availPromotional,
          addpromotionalSmsCount: finalPromotionalSmsCount,
          transactionalsmsvalidfrom: transactionalSmsValidFrom,
          transactionalsmsvalidto: transactionalSmsValidTo,
          promotionalsmsvalidfrom: promotionalSmsValidFrom,
          promotionalsmsvalidto: promotionalSmsValidTo,
          smsSender: this.state.smsTxtSender,
          excludePH: $("#chkTxtExclude").is(":checked") === true ? "1" : "0",
          hospitalId: $("#hospList").val(),
        }),
      };
      $(".loader").show();
      apiCalling(smsDetails).then((data) => {
        $(".loader").hide();
        if (data.success === "1") {
          alert("Saved successfully");
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        }
        if (data.success === "0") {
          alert(data.errorMessage);
        }
      });
    }
  }

  razorPaySaveHandle() {
    if ($("#hospList").val() === "") {
      alert("Hospital Name Required");
      $("#hospList").focus();
    }
    const razorPay = {
      method: "POST",
      body: JSON.stringify({
        functionName: "smsSettingsRazorPay",
        isActive: "1",
        publicKey: this.state.publicKeyTxt,
        secretKey: this.state.secretKeyTxt,
        hospitalId: $("#hospList").val(),
      }),
    };
    $(".loader").show();
    apiCalling(razorPay).then((data) => {
      if (data.success === "1") {
        alert("Saved successfully");
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
        $(".loader").hide();
      }
      if (data.success === "0") {
        alert(data.errorMessage);
      }
    });
  }

  handleChange(event) {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  }

  addCountChange(event) {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      this.setState({ addTxt: Number(event.target.value) });
    }
  }

  addPromotionalChange(event) {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      this.setState({ addPromotional: Number(event.target.value) });
    }
  }

  textType(e) {
    var code = "charCode" in e ? e.charCode : e.keyCode;
    if (!(code > 47 && code < 58)) {
      // lower alpha (a-z)
      alert("Only Numbers Accepted");
      e.preventDefault();
    }
  }

  cancelHandle() {
    window.location.reload(false);
  }

  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header /> <div className="loader"></div>
          </section>
        </Suspense>
        <div className="container">
          <div>
            <ul className="breadcrum-adminMangmnt">
              <li>
                <a href="/admin/dashboard">Dashboard</a>
              </li>
              <li>
                <a href="/adminManagement/smsSettings/sms-settings" className="current">
                  SMS Settings
                </a>
              </li>
            </ul>
          </div>
          <div className="sectionItemDiv sectionItemDivBorder sectionBorderadminMangmnt">
            <div className="smssetngsWrap">
              <div className="smssetngsTop smssetngsTop-select w-100">
                <label>Hospital Name</label>
                <select
                  name="hospList"
                  id="hospList"
                  onChange={() => this.hospitalDetails()}
                >
                  <option value="">Hospital Name</option>
                  {this.state.hospitalData.map((hosObj, i) => (
                    <option value={hosObj.hospitalId}>
                      {hosObj.hospitalName}
                    </option>
                  ))}
                </select>
              
              <input
                type="text"
                id="txtHospitalIdNew"
                name="txtHospitalIdNew"
                className="hspInfofieldinput dctrofce-inputhspid"
                placeholder="Hospital Id"
                onChange={() => this.selectHospital()}
              />
              </div>

              <div className="smsSetngHead">SMS</div>
              <div className="smsSetngsContent">
                <div className="smssetngscontentdiv">
                  <div className="smsItem">
                    <label className="">
                      Avail Transactional Sms Count
                    </label>
                    <label className="label_smssetngbold">
                      {this.state.availTxt}
                    </label>
                    {/* <input type="text" className="smssetngInput" readOnly id="availTxt" name="availTxt" value={this.state.availTxt} /> */}
                  </div>
                  <div className="smsItem">
                    <label>Add Transactional Sms Count</label>
                    <input
                      type="text"
                      className="smssetngInput"
                      id="addTxt"
                      name="addTxt"
                      value={this.state.addTxt}
                      onChange={this.addCountChange}
                      onKeyPress={this.textType}
                    />
                  </div>
                  <div className="smsItem">
                    <label>Transactional Sms Valid From</label>
                    <input
                      type="date"
                      id="transactionalFromDate"
                      name="transactionalFromDate"
                      value={this.state.transactionalFromDate}
                      onChange={this.handleChange}
                      className="smssetngInput text-uppercase"
                    />
                    {/* <DatePicker
                          selected={this.state.transactionalStartDate}
                          onChange={this.fromDateHandler} className="smssetngInput mb-0" dateFormat="dd/MM/yyyy" id="transactionalFromDate" name="transactionalFromDate"
                        /> */}
                  </div>
                  <div className="smsItem">
                    <label>Transactional Sms Valid To</label>
                    <input
                      type="date"
                      id="transactionalToDate"
                      name="transactionalToDate"
                      value={this.state.transactionalToDate}
                      onChange={this.handleChange}
                      className="smssetngInput text-uppercase"
                    />
                    {/* <DatePicker
                          selected={this.state.transactionalEndDate}
                          onChange={this.toDateHandler} dateFormat="dd/MM/yyyy" className="smssetngInput mb-0" id="transactionalToDate" name="transactionalToDate"
                        /> */}
                  </div>
                </div>
                <div className="smssetngscontentdiv">
                  <div className="smsItem">
                    <label className="">
                      Avail Promotional Sms Count
                    </label>
                    <label className="label_smssetngbold">
                      {this.state.availPromotional}
                    </label>
                    {/* <input type="text" className="smssetngInput" readOnly id="availPromotional" name="availPromotional" value={this.state.availPromotional} /> */}
                  </div>
                  {/* <div className="smsItem">
                    <label>Add Transactional Sms Count</label>
                    <input
                      type="text"
                      className="smssetngInput"
                      id="addTxt"
                      name="addTxt"
                      value={this.state.addTxt}
                      onChange={this.addCountChange}
                      onKeyPress={this.textType}
                    />
                  </div> */}
                  <div className="smsItem">
                    <label>Add Promotional Sms Count</label>
                    <input
                      type="text"
                      className="smssetngInput"
                      id="addPromotional"
                      name="addPromotional"
                      value={this.state.addPromotional}
                      onChange={this.addPromotionalChange}
                      onKeyPress={this.textType}
                    />
                  </div>
                  <div className="smsItem">
                    <label>Promotional Sms Valid From</label>
                    <input
                      type="date"
                      id="promotionDateFrom"
                      name="promotionDateFrom"
                      value={this.state.promotionDateFrom}
                      onChange={this.handleChange}
                      className="smssetngInput text-uppercase"
                    />
                    {/* <DatePicker
                          selected={this.state.promotionFrom}
                          onChange={this.promotionHandleFrom} className="smssetngInput mb-0" dateFormat="dd/MM/yyyy" id="promotionDateFrom" name="promotionDateFrom"
                        /> */}
                  </div>
                  <div className="smsItem">
                    <label>Promotional Sms Valid To</label>
                    <input
                      type="date"
                      id="promotionDateTo"
                      name="promotionDateTo"
                      value={this.state.promotionDateTo}
                      onChange={this.handleChange}
                      className="smssetngInput text-uppercase"
                    />
                    {/* <DatePicker
                          selected={this.state.promotionTo}
                          onChange={this.promotionHandleTo} dateFormat="dd/MM/yyyy" className="smssetngInput mb-0" id="promotionDateTo" name="promotionDateTo"
                        /> */}
                  </div>
                  <div className="smsItem">
                    <label>Sms Sender</label>
                    <input
                      type="text"
                      className="smssetngInput"
                      id="smsTxtSender"
                      name="smsTxtSender"
                      value={this.state.smsTxtSender}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <br />
                <div className="smsSetngHead">General Settings</div>
                <div className="smssetngscontentdiv smssetngCheckbox">
                  <div className="smsItem">
                    <label>Exclude From PurpleHealth</label>
                    <input
                      type="checkbox"
                      className="smssetngInput mt-1"
                      name="chkTxtExclude"
                      id="chkTxtExclude"
                    />
                  </div>
                </div>
                <div className="smssetng-btn">
                  <input
                    type="submit"
                    value="Save"
                    className="smssetng-svebtn"
                    onClick={() => this.saveHandle()}
                  />
                  <input
                    type="submit"
                    value="Cancel"
                    className="smssetng-cancelbtn"
                    onClick={() => this.cancelHandle()}
                  />
                </div>
                <div className="smsSetngHead">Razor Pay</div>
                <div className="smsSetngsContent">
                  <div className="smssetngscontentdiv">
                    <div className="smsItem">
                      <label>PublicKey</label>
                      <input
                        type="text"
                        className="smssetngInput"
                        id="publicKeyTxt"
                        name="publicKeyTxt"
                        value={this.state.publicKeyTxt}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="smsItem">
                      <label>Secret Key</label>
                      <input
                        type="text"
                        className="smssetngInput"
                        id="secretKeyTxt"
                        name="secretKeyTxt"
                        value={this.state.secretKeyTxt}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="smssetng-btn">
                  <input
                    type="submit"
                    value="Save"
                    className="smssetng-svebtn"
                    onClick={() => this.razorPaySaveHandle()}
                  />
                  <input
                    type="submit"
                    value="Cancel"
                    className="smssetng-cancelbtn"
                    onClick={() => this.cancelHandle()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
    // };
  }
}
