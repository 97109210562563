import React, { Component } from 'react';
import { apiCalling } from "../apiService";

export default class HospitalDetails extends Component {

  constructor(props){
    super(props);

this.state={

  country:'',
  State:'',
  hospital:'',
  hospitalDetails:[],

}
}

componentDidMount(){

  const getDetails = {
    method: "POST",
    body: JSON.stringify({
      functionName: "getDoctorDetails",
      siteId: window.sessionStorage.getItem("siteId"),
      doctorId: window.sessionStorage.getItem("userId"),
    }),
  }
  apiCalling(getDetails).then((data) => {
    //console.log("hospitalDetails")
    if (data.success === "1") {        
      this.setState({  
        hospitalDetails:data.doctorData[0].hospitalDetails,
        // hospital: data.doctorData[0].hospitalDetails[0].name,
        // State: data.doctorData[0].hospitalDetails[0].state,
        // country: data.doctorData[0].hospitalDetails[0].country,
      });
    }
  });
//  let hospitalDetails=this.props.hospitalDetails 
//  hospitalDetails.map((obj)=>{
//   this.setState({country:obj[0].country});
//   this.setState({state:obj[0].state});
//   this.setState({hospital:obj[0].name});
// }) ;
}

  render() {
    return ( 
      <div class="row px-2">
        <div class="col-sm-12  px-0  px-sm-3">
        <div class="table-responsive">
          <table class="table" id="tblLocation">
            <thead>
                <tr>           
                  <th class="w-25">Country</th>
                  <th class="w-25"> State</th>
                  <th class="w-50"> Hospital</th>
                </tr>
            </thead>
            <tbody>
              {this.state.hospitalDetails.map((data) => (
                <tr>
                  <td class="w-25">
                    <input disabled="disabled" placeholder="Countryyy" type="text" class="input-design" value={data.country} />
                  </td>
                  <td class="w-25">
                      <input disabled placeholder="State" type="text" class="input-design"  value={data.state}/>
                  </td>
                  <td class="w-50">
                      <input disabled type="text"  placeholder="Hospital" class="input-design" value={data.name} />
                  </td>
                </tr>
                 ))}
            </tbody>
          </table>
      </div>
        </div>
      </div>
    )
  }
}
