import React, { Component, Suspense } from "react";
import Closebtn from "../../image/icons/close-sm.png";
import AddImg from "../../image/icons/addbtn.png";
import { apiCalling } from "../../apiService";
import $ from "jquery";
const folderName = window.sessionStorage.getItem("homeName");
// const Header = React.lazy(() => import("../" + folderName + "/header"));
// const Footer = React.lazy(() => import("../" + folderName + "/footer"));


export default class Proceduredetails extends Component {

      constructor(props) {
            super(props);

            this.state = {
                  procedures: [],
                  procedureDetailsArray: []
            }
      }
      componentDidMount() {
            const apiJson = {
                  method: "POST",
                  body: JSON.stringify({
                        "functionName": "getHospitalDropdown"

                  })
            };
            // console.log("listinggg.222222..", apiJson)
            apiCalling(apiJson).then((data) => {
                  // console.log("listinggg.1111..", data)

                  if (data.success === "1") {
                        this.setState({
                              procedures: data.hospitalData[0].procedures,

                        }
                        ,()=>{
                              if(this.props.proceduresData.length>0){
                                    this.setState({
                                          procedureDetailsArray:this.props.proceduresData
                                    })
                              }
                              else{
                                    this.setState({
                                          procedureDetailsArray:[]
                                    })   
                              }
                        }
                        )
                  }

            });

      }

      addProcedureDetails() {
            var flag = false

            for (var i = 0; i <= this.state.procedureDetailsArray.length; i++) {
                  if ($("#cmbProcedures" + i).val() === "0") {
                        alert("Procedures Required")
                        $("#cmbProcedures" + i).focus();
                        flag = true;
                        break;
                  }
            }


            if (flag === false) {
                  var a = {
                        "Id": $("#cmbProcedures").val(),
                        "minPrice": $("#txtMinPrice").val(),
                        "maxPrice": $("#txtMaxPrice").val(),
                        "buyPrice": $("#txtBuyPrice").val(),
                        "mtMinPrice": $("#txtMtMinPrice").val(),
                        "mtMaxPrice": $("#txtMtMaxPrice").val(),
                        "mtBuyPrice": $("#txtMtBuyPrice").val(),
                        "isActive": $("#cmbStatus").val()

                      
                  }
                  this.state.procedureDetailsArray.push(a)
                  // console.log("procedureDetailsArray....", this.state.procedureDetailsArray)
                  this.setState({
                        procedureDetailsArray: this.state.procedureDetailsArray
                  })
            }
            $("#cmbProcedures").val('0')
            $("#txtMinPrice").val('')
            $("#txtMaxPrice").val('')
            $("#txtBuyPrice").val('')
            $("#txtMtMinPrice").val('')
            $("#txtMtMaxPrice").val('')
            $("#txtMtBuyPrice").val('')
            $("#cmbStatus").val('false')

      }
      removeProcedureDetails(Id) {

            var data = $.grep(this.state.procedureDetailsArray, function (e) {
                  return e.Id !== Id;
            });
            this.state.procedureDetailsArray = data
            this.setState({
                  procedureDetailsArray: this.state.procedureDetailsArray
            })
      }
      setProcedureData(value,name,i, mode) {
            if (mode === "cmbProcedures") {
              this.state.procedureDetailsArray[i].Id = $("#" + mode + i).val()
            } else if (mode === "txtMinPrice") {
              this.state.procedureDetailsArray[i].minPrice = $("#" + mode + i).val()
            } else if (mode === "txtMaxPrice") {
                  this.state.procedureDetailsArray[i].maxPrice = $("#" + mode + i).val()
            } else if (mode === "txtBuyPrice") {
                  this.state.procedureDetailsArray[i].buyPrice = $("#" + mode + i).val()
            } else if (mode === "txtMtMinPrice") {
                  this.state.procedureDetailsArray[i].mtMinPrice = $("#" + mode + i).val()
            } else if (mode === "txtMtMaxPrice") {
                  this.state.procedureDetailsArray[i].mtMaxPrice = $("#" + mode + i).val()
            } else if (mode === "txtMtBuyPrice") {
                  this.state.procedureDetailsArray[i].mtBuyPrice = $("#" + mode + i).val()
            } else if (mode === "cmbStatus") {
                  this.state.procedureDetailsArray[i].isActive = $("#" + mode + i).val()
            }   
            // console.log("procedureDetailsArray...set......", this.state.procedureDetailsArray)
            this.setState({
              [name]: value,
              procedureDetailsArray: this.state.procedureDetailsArray
            })
           
          }

      handleChange(event) {
            // alert(event.target.keyCode)
            const re = /^\d{0,10}(\.\d{0,6})?$/;
            if (event.target.value === '' || !re.test(event.target.value)) {
                  var a = event.target.value.toString().slice(0, -1)
                  $("#" + event.target.id).val(a)
                  if (!re.test($("#" + event.target.id).val())) {
                        $("#" + event.target.id).val('')
                       

                  }else{
                        alert("Only Numbers Accepted")
                  }
                
            }
            else {
                  return true;
            }
           

      }
      enablingPrices(){
            if( $("#cmbProcedures").val() !== "0"){
                  $("#txtMinPrice").prop("disabled", false);
                  $("#txtMaxPrice").prop("disabled", false);
                  $("#txtBuyPrice").prop("disabled", false);
                  $("#txtMtMinPrice").prop("disabled", false);
                  $("#txtMtMaxPrice").prop("disabled", false);
                  $("#txtMtBuyPrice").prop("disabled", false);
            }else{
                  $("#txtMinPrice").prop("disabled", true);
                  $("#txtMaxPrice").prop("disabled", true);
                  $("#txtBuyPrice").prop("disabled", true);
                  $("#txtMtMinPrice").prop("disabled", true);
                  $("#txtMtMaxPrice").prop("disabled", true);
                  $("#txtMtBuyPrice").prop("disabled", true);
            }
      }
     
      procedures(){
         var s = {
            procedureDetailsArray :this.state.procedureDetailsArray 
         }
         return s;
      }
      render() {
            return (
                  <div className="bg-clr-wthHead mb-4">
                        <Suspense fallback={<div></div>}>
                              <section>
                                    <div className="consultngTime-wrapper">
                                          <h2 className="hspInfoSubhead">Procedure Details</h2>

                                          <div className="hsptaltimeHead">
                                                <div className="educationdoctr-contentdiv">
                                                      <label className="educationdoctrlabelwidth">Procedures </label>
                                                      <select name="cmbProcedures" id="cmbProcedures" className="hspInfofieldinput eductninputwidthdctr" onChange={()=>this.enablingPrices()}>
                                                            <option value="0">Select</option>
                                                            {this.state.procedures.map((procedureObj, s) => {
                                                                  return (
                                                                        <option key={procedureObj.procedureId} value={procedureObj.procedureId}>{procedureObj.procedureName}</option>
                                                                  )
                                                            })}
                                                      </select>
                                                </div>
                                                <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                                      <label htmlFor="">Min. Price <span className="priceinrtext">(INR)</span></label>
                                                      <input type="text" id="txtMinPrice" name="txtMinPrice" placeholder="Price" className="hspInfofieldinput priceinputproduredetl" onKeyUp={this.handleChange} onChange={this.handleChange} disabled />
                                                </div>
                                                <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                                      <label htmlFor="">Max. Price <span className="priceinrtext">(INR)</span></label>
                                                      <input type="text" id="txtMaxPrice" name="txtMaxPrice" placeholder="Price" className="hspInfofieldinput priceinputproduredetl" onKeyUp={this.handleChange} onChange={this.handleChange} disabled/>
                                                </div>
                                                <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                                      <label htmlFor="">Buy Price <span className="priceinrtext">(INR)</span></label>
                                                      <input type="text" id="txtBuyPrice" name="txtBuyPrice" placeholder="Price" className="hspInfofieldinput priceinputproduredetl" onKeyUp={this.handleChange} onChange={this.handleChange}  disabled/>
                                                </div>

                                                <div className="addimgeDivHsp">
                                                      <img src={AddImg} alt="Add" onClick={() => this.addProcedureDetails()} />
                                                </div>
                                          </div>


                                          <div className="hsptaltimeHead">

                                                <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                                      <label htmlFor="">MT Min. Price <span className="priceinrtext">(USD)</span></label>
                                                      <input type="text" id="txtMtMinPrice" name="txtMtMinPrice" placeholder="Price" className="hspInfofieldinput priceinputproduredetl" onKeyUp={this.handleChange} onChange={this.handleChange}  disabled/>
                                                </div>
                                                <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                                      <label htmlFor="">MT Max. Price <span className="priceinrtext">(USD)</span></label>
                                                      <input type="text" id="txtMtMaxPrice" name="txtMtMaxPrice" placeholder="Price"  className="hspInfofieldinput priceinputproduredetl" onKeyUp={this.handleChange} onChange={this.handleChange}  disabled/>
                                                </div>
                                                <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                                      <label htmlFor="">MT Buy Price <span className="priceinrtext">(USD)</span></label>
                                                      <input type="text" id="txtMtBuyPrice" name="txtMtBuyPrice" placeholder="Price" className="hspInfofieldinput priceinputproduredetl" onKeyUp={this.handleChange} onChange={this.handleChange} disabled />
                                                </div>

                                                <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                                      <label htmlFor="">Status</label>
                                                      <select name="cmbStatus" id="cmbStatus">
                                                            <option value="false">Disabled </option>
                                                            <option value="true">Enabled </option>
                                                      </select>
                                                </div>

                                          </div>

                                          {this.state.procedureDetailsArray.map((Obj, i) => {
                                                return (
                                                      <div key={Obj.Id}>
                                                            <div className="hsptaltimeHead">
                                                                  <div className="educationdoctr-contentdiv">
                                                                        <label>Procedures </label>
                                                                        <select name={"cmbProcedures" + i} id={"cmbProcedures" + i} value={Obj.Id} className="hspInfofieldinput eductninputwidthdctr"  onChange={(e) => this.setProcedureData(e,"cmbProcedures"+i,i, "cmbProcedures")}>
                                                                              <option value="0">Select</option>
                                                                              {this.state.procedures.map((procedureObj, s) => {
                                                                                    return (
                                                                                          <option key={procedureObj.procedureId} value={procedureObj.procedureId}>{procedureObj.procedureName}</option>
                                                                                    )
                                                                              })}
                                                                        </select>
                                                                  </div>
                                                                  <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                                                        <label htmlFor="">Min. Price <span className="priceinrtext">(INR)</span></label>
                                                                        <input type="text" id={"txtMinPrice" + i} name={"txtMinPrice" + i} value={Obj.minPrice}  placeholder="Price" className="hspInfofieldinput priceinputproduredetl" onKeyUp={this.handleChange} onChange={this.handleChange} onChange={(e) => this.setProcedureData(e,"txtMinPrice"+i,i, "txtMinPrice")}  />
                                                                  </div>
                                                                  <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                                                        <label htmlFor="">Max. Price <span className="priceinrtext">(INR)</span></label>
                                                                        <input type="text" id={"txtMaxPrice" + i} name={"txtMaxPrice" + i} value={Obj.maxPrice} placeholder="Price" className="hspInfofieldinput priceinputproduredetl" onKeyUp={this.handleChange} onChange={this.handleChange} onChange={(e) => this.setProcedureData(e,"txtMaxPrice"+i,i, "txtMaxPrice")}  />
                                                                  </div>
                                                                  <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                                                        <label htmlFor="">Buy Price <span className="priceinrtext">(INR)</span></label>
                                                                        <input type="text" id={"txtBuyPrice" + i} name={"txtBuyPrice" + i} value={Obj.buyPrice} placeholder="Price" className="hspInfofieldinput priceinputproduredetl" onKeyUp={this.handleChange} onChange={this.handleChange} onChange={(e) => this.setProcedureData(e,"txtBuyPrice"+i,i, "txtBuyPrice")}  />
                                                                  </div>


                                                            </div>


                                                            <div className="hsptaltimeHead">

                                                                  <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                                                        <label htmlFor="">MT Min. Price <span className="priceinrtext">(USD)</span></label>
                                                                        <input type="text" id={"txtMtMinPrice" + i} name={"txtMtMinPrice" + i} value={Obj.mtMinPrice} placeholder="Price" className="hspInfofieldinput priceinputproduredetl" onKeyUp={this.handleChange} onChange={this.handleChange} onChange={(e) => this.setProcedureData(e,"txtMtMinPrice"+i,i, "txtMtMinPrice")}  />
                                                                  </div>
                                                                  <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                                                        <label htmlFor="">MT Max. Price <span className="priceinrtext">(USD)</span></label>
                                                                        <input type="text" id={"txtMtMaxPrice" + i} name={"txtMtMaxPrice" + i} value={Obj.mtMaxPrice} placeholder="Price" className="hspInfofieldinput priceinputproduredetl" onKeyUp={this.handleChange} onChange={this.handleChange} onChange={(e) => this.setProcedureData(e,"txtMtMaxPrice"+i,i, "txtMtMaxPrice")}  />
                                                                  </div>
                                                                  <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                                                                        <label htmlFor="">MT Buy Price <span className="priceinrtext">(USD)</span></label>
                                                                        <input type="text" id={"txtMtBuyPrice" + i} name={"txtMtBuyPrice" + i} value={Obj.mtBuyPrice} placeholder="Price" className="hspInfofieldinput priceinputproduredetl" onKeyUp={this.handleChange} onChange={this.handleChange} onChange={(e) => this.setProcedureData(e,"txtMtBuyPrice"+i,i, "txtMtBuyPrice")}  />
                                                                  </div>

                                                                  <div className="hsptimefieldDiv hsptimefieldDivMob-time" onChange={(e) => this.setProcedureData(e,"cmbStatus"+i,i, "cmbStatus")}>
                                                                        <label htmlFor="">Status</label>
                                                                        <select name={"cmbStatus" + i} id={"cmbStatus" + i} value={Obj.isActive}>
                                                                              <option value="false">Enabled </option>
                                                                              <option value="true">Disabled </option>
                                                                        </select>
                                                                  </div>
                                                                  <div className="addimgeDivHsp">
                                                                        <img src={Closebtn} alt="Close" onClick={() => this.removeProcedureDetails(Obj.Id)} width="20"/>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                )
                                          })}
                                    </div>

                              </section>
                        </Suspense>
                        <Suspense fallback={<div></div>}>
                        </Suspense>
                  </div>
            );
      }
}
