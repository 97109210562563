import React, { Component, Suspense } from "react";
import * as ReactDOM from "react-dom";
import doctor from "../image/media/doctor.jpg";
import profile from "../image/theme/profile1.png";
import purple from "../image/theme/practice.png";
import video from "../image/theme/video1.png";
import chat from "../image/theme/text.png";
import video_audio from "../image/theme/video_audio.png";
import doctor_video from "../image/theme/doctor_video.png";
import inbox from "../image/theme/mail1.png";
import article from "../image/theme/health.png";
import shnewsfeedicon from "../image/theme/shnewsfeedicon.png";
import password from "../image/theme/pw.png";
import time from "../image/theme/time1.png";
import inperson from "../image/theme/inperson.png";
import practice from "../image/theme/practice.png";
import service from "../image/theme/service1.png";
import prescriptionImg from "../image/theme/prescription.png";
import info from "../image/theme/info.png";
import { apiCalling } from "../apiService";
import { Link } from "react-router-dom";
import $ from "jquery"
export default class DashboardLeftmenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drFName: "",
      drLName: "",
      drSpec: "",
      drImg: "",
      textChatCount: "",
      videoOrAudioChatCount: "",
      pulseSiteUrl: "",
      newsFeedCount: "",
      pulsePaymentDone: false,
      windowName: "",
      groupHospitalId: window.sessionStorage.getItem("userId")
    };
  }
  doctorProfile() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorProfile",
        siteId: window.sessionStorage.getItem("siteId"),
        doctorId: window.sessionStorage.getItem("userId"),
      }),
    };

    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          drName: data.doctorData[0].firstName,
          drLName: data.doctorData[0].lastName,
          drSpec: data.doctorData[0].primarySpeciality,
          drImg: data.doctorData[0].image,
        });
      }
    });
  }
  doctorDashboard() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorDashboard",
        doctorId: window.sessionStorage.getItem("userId"),
        siteId: window.sessionStorage.getItem("siteId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          textChatCount: data.textChatCount,
          videoOrAudioChatCount: data.videoOrAudioChatCount,
          pulseSiteUrl: data.pulseSiteUrl,
          pulsePaymentDone: data.pulsePaymentDone,
          newsFeedCount: data.newsFeedCount,
        });
      }
    });
  }
  componentDidMount() {
    const windowPath = window.location.pathname.toString()
    this.setState({windowName:window.sessionStorage.getItem("userName")})
    if(windowPath ==='/GroupAdmin/dashboard'){
      document.getElementById('dashboard').style.background = "#f2f2f2";
      document.getElementById('videoSpan').style.color = "#0c4da2";
    }
    else if(windowPath ==='/GroupAdmin/Profile'){
      document.getElementById('profileMenu').style.background = "#f2f2f2"
      document.getElementById('videoSpan1').style.color = "#0c4da2";
    }
    else if(windowPath ==='/GroupAdmin/AdminHeaderLink'){
      document.getElementById('headerMenu').style.background = "#f2f2f2"
      document.getElementById('videoSpan2').style.color = "#0c4da2";
    }
    else if(windowPath ==='/GroupAdmin/AdminFooterLink'){
      document.getElementById('footerMenu').style.background = "#f2f2f2"
      document.getElementById('videoSpan3').style.color = "#0c4da2";
    }
    else if(windowPath ==='/GroupAdmin/CouponList'){
      document.getElementById('couponMenu').style.background = "#f2f2f2"
      document.getElementById('videoSpan4').style.color = "#0c4da2";
    }
    else if(windowPath ==='/doctor/changepassword'){
      document.getElementById('passwordMenu').style.background = "#f2f2f2"
      document.getElementById('passwordFont').style.color = "#0c4da2";
    }
    else if(windowPath ==='/GroupAdmin/ExpertDoctors'){
      document.getElementById('expertMenu').style.background = "#f2f2f2"
      document.getElementById('videoSpan6').style.color = "#0c4da2";
    }
    // else if(windowPath ==='/admin/dashboard'){
    //   document.getElementById('dashboard').style.background = "#0000FF"
    //   document.getElementById('dashboardFont').style.color = "#f2f2f2"
    // }
    
    this.doctorProfile();
    this.doctorDashboard();

    $(".report-dropdwnGrpAdmin").hide();
    $(document).ready(function(){
      $("#reportMenu").click(function(){
        $(".report-dropdwnGrpAdmin").slideToggle();  
       
      });
    });
  }

  render() {
    return (
      <div class="dashbrd-menu-leftt-hospital">
        <div class="">
          <div class="QckLinksNew">
            <div class="LinksList">
              <ul class="dashBoardMenu quicklinks">
                <li class="book hvr-float-shadow greyHeaderClr">
                  <h5 class="mb-0">{this.state.windowName}</h5>
                </li>
                <li id="dashboard" class="book hvr-float-shadow greyHeaderClr">
                  <Link to="/GroupAdmin/dashboard" class="practiceImg" id="dashboardFont">
                    {/* <img src={profile} alt="" /> */}
                    <span class="videoSpan" id="videoSpan"><i className="fa fa-tachometer"></i>&nbsp;&nbsp;<span className="show_hide">Dashboard</span></span>
                    
                  </Link>
                </li>

                <li id="profileMenu" class="video hvr-float-shadow greyHeaderClr">
                  <Link to="/GroupAdmin/Profile" id="profileFont">
                    {/* <img src={chat} alt="" /> */}
                    <span  class="videoSpan"  id="videoSpan1"><i className="fa fa-user"></i>&nbsp;&nbsp;<span className="show_hide">Profile</span></span>
                    
                  </Link>
                </li>

                <li id="headerMenu" class="video hvr-float-shadow greyHeaderClr">
                  <Link to="/GroupAdmin/AdminHeaderLink" id="headerFont">
                    {/* <img src={chat} alt="" /> */}
                    <span class="videoSpan"  id="videoSpan2"><i className="fa fa-list-alt"></i>&nbsp;&nbsp;<span className="show_hide">Add Header Links</span></span>

                  </Link>
                </li>

                <li id="footerMenu" class="video hvr-float-shadow greyHeaderClr">
                  <Link to="/GroupAdmin/AdminFooterLink" id="footerFont">
                    {/* <img src={chat} alt="" /> */}
                    <span  class="videoSpan"  id="videoSpan3"><i className="fa fa-bars"></i>&nbsp;&nbsp;<span className="show_hide">Add Footer Links</span></span>

                  </Link>
                </li>

                <li id="couponMenu" class="video hvr-float-shadow greyHeaderClr">
                  <Link to="/GroupAdmin/CouponList" id="couponFont">
                    {/* <img src={chat} alt="" /> */}
                    <span  class="videoSpan"  id="videoSpan4"><i className="fa fa-picture-o"></i>&nbsp;&nbsp;<span className="show_hide">Coupon Code</span></span>

                  </Link>
                </li>
                <li id="passwordMenu" class="video hvr-float-shadow greyHeaderClr">
                  <Link to="/doctor/changepassword" id="passwordFont">
                    {/* <img src={chat} alt="" /> */}
                    <span id="passwordFont " class="videoSpan"><i className="fa fa-key"></i>&nbsp;&nbsp;<span className="show_hide">Change Password</span></span>

                  </Link>
                </li>
                <li  class="video hvr-float-shadow greyHeaderClr" id="reportMenu" >
                  <Link to="#" >
                    {/* <img src={chat} alt="" /> */}
                    <span class="videoSpan"><i className="fa fa-file"></i>&nbsp;&nbsp;<span className="show_hide">My Reports</span></span>
                  </Link>
                  <ul className="report-dropdwnGrpAdmin pl-2 pt-3">
                    <li class="video hvr-float-shadow greyHeaderClr">
                      <Link to="/GroupAdmin/textChatReports2" id="reportMenu" >
                        <span class="videoSpan"><i className="fa fa-files-o"></i>&nbsp;&nbsp;<span className="show_hide">Text Chat Reports</span></span>
                      </Link>
                    </li>
                    <li class="video hvr-float-shadow greyHeaderClr">
                      <Link to="/GroupAdmin/inPersonReports2" id="reportMenu" >
                        <span class="videoSpan"><i className="fa fa-files-o"></i>&nbsp;&nbsp;<span className="show_hide">In-Person Reports</span></span>
                      </Link>
                    </li>
                    <li class="video hvr-float-shadow greyHeaderClr">
                      <Link to="/GroupAdmin/VideoAudioChatReports" id="reportMenu" >
                        <span class="videoSpan"><i className="fa fa-files-o"></i>&nbsp;&nbsp;<span className="show_hide">Video/Audio Chat Reports</span></span>
                      </Link>
                    </li>
                    <li class="video hvr-float-shadow greyHeaderClr">
                      <Link to="/GroupAdmin/patientTransaction">
                        {/* <img src={chat} alt="" /> */}
                        <span class="videoSpan"><i className="fa fa-files-o"></i>&nbsp;&nbsp;<span className="show_hide">Patient Transaction </span></span>
                      </Link>
                    </li>
                   </ul>
                </li>
               
                     

                <li id="textChat" class="video hvr-float-shadow greyHeaderClr">
                  <Link to="/GroupAdmin/VideoAudioAppointment">
                  <span class="videoSpan"><img src={video_audio} alt="" />&nbsp;&nbsp;<span className="show_hide">Video/Audio Appointments</span></span>

                  </Link>
                </li>

                <li id="textChat" class="video hvr-float-shadow greyHeaderClr">
                  <Link to="/videoSchedule/editDoctorSchedule">
                  <span class="videoSpan"><img src={doctor_video} alt="" />&nbsp;&nbsp;<span className="show_hide">Doctor Video/Audio Schedule</span></span>
                  </Link>
                </li>

                <li id="expertMenu" class="video hvr-float-shadow greyHeaderClr">
                  <Link to="/GroupAdmin/ExpertDoctors" id="expertFont">
                    {/* <img src={chat} alt="" /> */}
                    <span class="videoSpan" id="videoSpan6"><i className="fa fa-user-plus"></i>&nbsp;&nbsp;<span className="show_hide">Add Expert Doctors</span></span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
