import React, { Component, Suspense } from "react";
import $ from "jquery";
import moment from "moment";
import * as ReactDOM from "react-dom";
import doctor from "../image/media/doctor.jpg";
import profile from "../image/theme/profile2.png";
import purple from "../image/theme/practice2.png";
import video from "../image/theme/video2.png";
import chat from "../image/theme/text2.png";
import inbox from "../image/theme/mail1.png";
import article from "../image/theme/health2.png";
import shnewsfeedicon from "../image/theme/shnewsfeedicon.png";
import password from "../image/theme/pw2.png";
import time from "../image/theme/time1.png";
import inperson from "../image/theme/inperson.png";
import practice from "../image/theme/practice.png";
import service from "../image/theme/service2.png";
import prescriptionImg from "../image/theme/prescription2.png";
import info from "../image/theme/info2.png";
import { apiCalling } from "../apiService";
import { Link } from "react-router-dom";
export default class DashboardLeftmenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drFName: "",
      drLName: "",
      drSpec: "",
      drImg: "",
      textChatCount: "0",
      videoOrAudioChatCount: "0",
      pulseSiteUrl: "",
      newsFeedCount: "",
      pulsePaymentDone: false,
    };
  }
  doctorProfile() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorProfile",
        siteId: window.sessionStorage.getItem("siteId"),
        doctorId: window.sessionStorage.getItem("userId"),
      }),
    };

    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          drName: data.doctorData[0].firstName,
          drLName: data.doctorData[0].lastName,
          drSpec: data.doctorData[0].primarySpeciality,
          drImg: data.doctorData[0].image,
        });
      }
    });
  }
  doctorDashboard() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorDashboard",
        doctorId: window.sessionStorage.getItem("userId"),
        siteId: window.sessionStorage.getItem("siteId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          // textChatCount: data.textChatCount,
          videoOrAudioChatCount: data.videoOrAudioChatCount,
          pulseSiteUrl: data.pulseSiteUrl,
          pulsePaymentDone: data.pulsePaymentDone,
          newsFeedCount: data.newsFeedCount,
        });
      }
    });
    const textChatJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "chat/getChatQuestions",
        siteId: window.sessionStorage.getItem("siteId"),
        hospitalId: "", //window.sessionStorage.getItem("appHosId"),
        userId: window.sessionStorage.getItem("userId"), //"19906",
        bundleId: "",
      }),
    };
    apiCalling(textChatJson).then((data) => {
      let count = 0;
      if (data.success === "1") {
        if (data.resultArray.activeChats.length > 0) {
          for (let i = 0; i < data.resultArray.activeChats.length; i++) {
            count =
              count + parseInt(data.resultArray.activeChats[i].unreadCount);
          }
          this.setState({ textChatCount: count.toString() });
        } else {
          this.setState({ textChatCount: "0" });
        }
        // this.setState({
        //   textChatCount: data.result[0].textChatCount
        // });
      }
    });
  }
  // readMessage() {
  //   const jsontest = {
  //     method: "POST",
  //     body: JSON.stringify({
  //       functionName: "chat/markChatIsRead",
  //       chatGroupId: window.sessionStorage.getItem("chatDoctorPanelId"),
  //       userId: "",
  //       bundleId: "",
  //       siteId: window.sessionStorage.getItem("siteId"),
  //     }),
  //   };
  //   apiCalling(jsontest).then((data) => {});
  // }
  componentDidMount() {
    $("#videoOrAudioChatCount").text("0");
    
    this.doctorProfile();
    setInterval(async () => {
      this.doctorDashboard();
    }, 3000);
    let todate = moment(new Date()).format("DD/MM/YYYY");
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "videoAudioDoctorAppointment",
        siteId: window.sessionStorage.getItem("siteId"),
        doctorId: window.sessionStorage.getItem("userId"),
        fromDate: todate,
        toDate: todate,
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $("#videoOrAudioChatCount").text("0");
        $("#videoOrAudioChatCount").text(data.openAppointments.length);
        this.setState({videoOrAudioChatCount:data.openAppointments.length})
      } else {
        $("#videoOrAudioChatCount").text("0");
      }
    });
  }

  render() {
    return (
      <div class="dashbrd-menu-leftt">
        <div class="col-md-12 px-0">
          <div className="drpagedetail mb-4">
            {/* <img src={this.state.drImg} className="dr-profileImg"></img> */}
            <img src={this.state.drImg} className="dr-profileImg"></img>
            <div class="drpagedetailName">
              <div class="healthpageDocName">
                {this.state.drName}&nbsp;
                {this.state.drLName}
              </div>
              <div class="healthpageDocSpl">{this.state.drSpec}</div>
            </div>
          </div>
          <p class="quick_content">
            <span class="videoSpan">Quick Links</span>
          </p>
        </div>
        <div class="col-md-12 pl-0 mt-3 px-0">
          <div class="QckLinksNew">
            <div class="LinksList">
              <ul class="dashBoardMenu quicklinks">
                <li id="profile" class="book hvr-float-shadow greyHeaderClr">
                  <Link to="/doctor/doctorprofile" class="practiceImg">
                    <img src={profile} alt="" />
                    <span class="videoSpan">Profile</span>
                  </Link>
                </li>
                {this.state.pulsePaymentDone === true && (
                  <li class="book hvr-float-shadow greyHeaderClr">
                    <a
                      href={
                        "https://" +
                        this.state.pulseSiteUrl +
                        "?did=" +
                        window.sessionStorage.getItem("userId") +
                        "&siteId=" +
                        window.sessionStorage.getItem("siteId")
                      }
                      class="practiceImg"
                      target="_blank"
                    >
                      <img src={purple} />
                      <span class="videoSpan">Practice Management</span>
                    </a>
                  </li>
                )}
                <li id="textChat" class="video hvr-float-shadow greyHeaderClr">
                  <Link to="/chat/chatmessagegroup">
                    <img src={chat} alt="" />
                    <span class="videoSpan">Text Chat</span>
                    {/* {this.state.textChatCount !== "" &&
                      this.state.textChatCount !== "0" && (
                        <span className="dashMenuNotfcn d-flex align-items-center justify-content-center mr-2">
                          {this.state.textChatCount}
                        </span>
                      )} */}
                      <span className="dashMenuNotfcn d-flex align-items-center justify-content-center mr-2">
                          {this.state.textChatCount}
                        </span>
                  </Link>
                </li>

                <li id="videoChat" class="video hvr-float-shadow greyHeaderClr">
                  <Link to="/doctor/videoSchedule">
                    <img src={video} alt="" />
                    <span class="videoSpan">Video/Audio Chat</span>
                    {this.state.videoOrAudioChatCount !== "" &&
                      this.state.videoOrAudioChatCount !== "0" && (
                        <span
                          id="videoOrAudioChatCount"
                          className="dashMenuNotfcn d-flex align-items-center justify-content-center mr-2"
                        >{this.state.videoOrAudioChatCount}</span>
                      )}
                  </Link>
                </li>

                {/* <li
                  id="InpersonAppointment"
                  class="video hvr-float-shadow greyHeaderClr"
                >
                  <Link to="inpersonAppointment" class="practiceImg">
                    <img src={inperson} alt="" />
                    <span className="videoSpan">Inperson Appointment(s)</span>
                  </Link>
                </li> */}

                {/* <li id="doctorInbox" class="video hvr-float-shadow greyHeaderClr">
                  <Link to="/doctor/doctorinbox" class="practiceImg">
                    <img src={prescriptionImg} alt="" />
                    <span className="videoSpan">Inbox</span>
                  </Link>
                </li> */}
                {/* <li id="InpersonAppointment" class="video hvr-float-shadow greyHeaderClr">
                  <Link to="/doctor/chat-history" class="practiceImg">
                    <img src={prescriptionImg} alt="" />
                    <span className="videoSpan">Video/Audio chat History</span>
                  </Link>
                </li> */}

                {/* <li id="DoctorPrescription" class="video hvr-float-shadow greyHeaderClr">
                  <Link to="prescriptionList" class="practiceImg">
                    <img src={prescriptionImg} alt="" />
                    <span class="videoSpan">Prescription List</span>
                  </Link>
                </li> */}

                {/* <li
                  id="healthArticle"
                  class="ask hvr-float-shadow greyHeaderClr"
                >                 
                  <Link to="/newFeedsList">
                    <img src={shnewsfeedicon} alt=""/>
                    <span class="videoSpan">News Feed</span>
                    {this.state.newsFeedCount !== "" &&
                      this.state.newsFeedCount !== "0" && (
                        <span className="dashMenuNotfcn">
                          {this.state.newsFeedCount}
                        </span>
                           )}
                  </Link>
                </li> */}

                <li
                  id="healthArticle"
                  class="ask hvr-float-shadow greyHeaderClr"
                >
                  <Link to="/BlogList">
                    <img src={article} alt="" />
                    <span class="videoSpan">Write health Article</span>
                  </Link>
                </li>
                {/* <li
                  id="journalListing"
                  class="ask hvr-float-shadow greyHeaderClr"
                >                 
                  <Link to="/journalListing">
                    <img src={article} alt=""/>
                    <span class="videoSpan">Journal</span>
                  </Link>
                </li> */}

                <li id="changePassword">
                  <Link to="/doctor/changepassword" class="practiceImg">
                    <img src={password} alt="" />
                    <span className="videoSpan">Change Password</span>
                  </Link>
                </li>

                {/* <li id="workingTime">
                  <Link to="/doctor/worktime" class="practiceImg">
                    <img src={time} alt="" />
                    <span class="videoSpan">Working Time</span>
                  </Link>
                </li> */}

                <li id="services">
                  <Link to="/doctor/docservices" class="practiceImg">
                    <img src={service} alt="" />
                    <span class="videoSpan">Services</span>
                  </Link>
                </li>
                <li id="worktime">
                  <Link to="/doctor/worktime" class="practiceImg">
                    <img src={info} alt="" />
                    <span class="videoSpan">Working Time</span>
                  </Link>
                </li>
                {/* <li id="services">
                  <Link to="/doctor/reportDoctor" class="practiceImg">
                    <img src={info} alt="" />
                    <span class="videoSpan">Referral Report</span>
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
