import React, { Component, Suspense } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import tick from "../image/media/tick.png";
import { apiCalling } from "../apiService";
import avatar from "../image/media/avatar.png";
import attachment from "../image/media/attachgrey.png";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class ChatmessageGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeChats: [],
      errorMessage: "Fetching data...",
    };
    this.onRefresh = this.onRefresh.bind(this);
  }

  componentDidMount() {
    // const jsontest = {

    //   method: "POST",
    //   body: JSON.stringify({
    //     functionName: "chat/markChatIsRead",
    //     chatGroupId: window.sessionStorage.getItem("chatDoctorPanelId"),
    //     userId: "",
    //     bundleId: "",
    //     siteId: window.sessionStorage.getItem("siteId"),
    //   }),

    // };

    // apiCalling(jsontest).then((data) => {

    // });
    $("body").removeClass("body-ovrflw-hdn-menu");
    // alert(window.sessionStorage.getItem("userId"));
    $("#promoApplySection").hide();
    this.getActiveChatQuestions();
  }
  havePromoCode() {
    $("#promoApplySection").show();
    $("#havePromoCode").hide();
  }
  getActiveChatQuestions() {
    // alert(window.sessionStorage.getItem("hospitalId"));

    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "chat/getChatQuestions",
        siteId: window.sessionStorage.getItem("siteId"),
        hospitalId: "", //window.sessionStorage.getItem("appHosId"),
        userId: window.sessionStorage.getItem("userId"), //"19906",
        bundleId: "",
      }),
    };
    $(".loader").show();
    // console.log("chat/getChatQuestions>>" + apiJson.body);
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();

        this.setState({
          activeChats: data.resultArray.activeChats,
        });
        // console.log("ffffffffff",data.resultArray.activeChats.length);
        if (data.resultArray.activeChats.length === 0) {
          this.setState({ errorMessage: "You have no messages" });
        }
        // console.log("chatt.....", data.resultArray.activeChats);
      } else {
        this.setState({ errorMessage: "You have no messages" });
      }
    });
  }
  onRefresh() {
    this.getActiveChatQuestions();
  }
  getStartChat(chatGroupId, memberId, doctorId, hospitalId) {
    // debugger;
    $(".loader").show();
    const json = {
      method: "POST",
      body: JSON.stringify({
        functionName: "startTextChat",
        chatGroupId: chatGroupId,
        userId: window.sessionStorage.getItem("userId"),
        hospitalId: hospitalId,
      }),
    };
    // console.log("json.body", json.body);
    apiCalling(json).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
        // console.log("Data: ", data);
        window.sessionStorage.setItem("chatStore", data.result[0]);
        window.sessionStorage.setItem("API_KEY", data.result[0].API_KEY);
        window.sessionStorage.setItem("sessionId", data.result[0].sessionId);
        window.sessionStorage.setItem("token", data.result[0].token);
        window.sessionStorage.setItem("appDocId", doctorId);
        window.sessionStorage.setItem("appHosId", hospitalId);
        window.sessionStorage.setItem("chatDoctorPanelId", chatGroupId);
        window.sessionStorage.setItem(
          "defaultMessages",
          data.result[0].defaultMessages
        );
        window.location.href = "/chat/chatmessage";
      }
    });
  }
  checkURL(url) {
    return url.match(/\.(jpeg|jpg|gif|png|mp3)$/) != null;
  }
  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container">
          <div class="main-wrapper-smwidth1">
            <div class="col-md-12 p-0">
              <ul class="breadcrumb">
                <li>
                  {window.sessionStorage.getItem("role") === "ROLE_USER" ? (
                    <Link to="/home/doctor-list-main/2">New Chat</Link>
                  ) : (
                    <Link to="/doctor/dashboard">Dashboard</Link>
                  )}
                  <i class="fa fa-chevron-right"></i>
                </li>
                <li>Inbox</li>
              </ul>
            </div>
            <div class="chat-outer">
              <div class="chat-header">
                <span class="form-head"> Inbox</span>
                <span class="chat-span">
                  <button
                    type="button"
                    class="btn-default"
                    onClick={() => this.onRefresh()}
                  >
                    Refresh
                  </button>
                </span>
                <span class="chat-span">
                  <Link to="/chat/archivedMessageChats">
                    <button type="button" class="btn-default">
                      Old Chat(s)
                    </button>
                  </Link>
                </span>
                <span class="chat-span">
                  {window.sessionStorage.getItem("role") === "ROLE_USER" ? (
                    <Link to="/home/doctor-list-main/2">
                      <button type="button" class="btn-default">
                        Start New Chat
                      </button>
                    </Link>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <div class="chat-bodygroup px-2">
                {this.state.activeChats.length > 0 ? (
                  <ul class="messages">
                    {this.state.activeChats.map((active) => {
                      let dates = new Date(active.modifiedOnMillSec);

                      let messages = active.allMessages[0];
                      if (typeof messages === "undefined") {
                        messages = "";
                      }
                      if (messages) {
                        messages = messages
                          .replace("?-0", "?")
                          .replace("?-1", "?")
                          .replace("-1", "?");
                      }

                      return (
                        <li class="message message-brdr">
                          <a
                            href="#"
                            onClick={() =>
                              this.getStartChat(
                                active.chatGroupId,
                                active.memberId,
                                active.doctorId,
                                active.hospitalId
                              )
                            }
                            class="text-decoration-none"
                          >
                            <div class="avatar">
                              <img src={avatar} class="avatarimg" alt=""></img>
                              {active.unreadCount > 0 && (
                                <span className="chatBubleOnline">
                                  {active.unreadCount}
                                </span>
                              )}
                              {/* <span class="chatBubleOnline">
                              {active.isLogged === "true" ? (
                                <img src={tick} alt="" />
                              ) : (
                                ""
                              )}
                            </span> */}
                            </div>
                            <div class="textwrappergroup">
                              <div class="text-header">
                                {window.sessionStorage.getItem("role") ===
                                "ROLE_USER" ? (
                                  <div className="textchat-namehead">
                                    {active.doctorName}
                                  </div>
                                ) : (
                                  <div className="textchat-namehead">
                                    {active.memberName}
                                  </div>
                                )}
                                {dates.toDateString() !== "Invalid Date" && (
                                  <time class="message-time">
                                    <i class="fa fa-clock-o"></i>
                                    {dates.toDateString()}{" "}
                                    {dates.toLocaleTimeString("en-IN", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })}
                                  </time>
                                )}
                              </div>
                              <p class="text">
                                {active.unreadCount > 0 ? (
                                  <b>
                                    {messages.includes("https://") ? (
                                      <span>
                                        <img src={attachment} /> Attachment{" "}
                                      </span>
                                    ) : (
                                      messages
                                    )}
                                  </b>
                                ) : (
                                  <p>
                                    {" "}
                                    {messages.includes("https://") ? (
                                      <span>
                                        <img src={attachment} /> Attachment{" "}
                                      </span>
                                    ) : (
                                      messages
                                    )}
                                  </p>
                                )}
                              </p>
                            </div>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <div className="p-2 text-center">
                    {this.state.errorMessage}{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
