import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "./css/style.css";
import "./css/purpleresponsive.css";
import "./css/admin.css";
import "./css/adminresponsive.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "../src/home/home";
import Root from "../src/layout/root";
import AboutUs from "./home/aboutus";
import Favorate from "./home/favorate";
import Contact from "./home/contactus";
import Telehealth from "./home/telehealth";
import BrandedAppsWebsite from "./home/branded-apps-website";
import ClinicManagement from "./home/clinic-management";
import Clients from "../src/home/clients";
import DoctorDetail from "./home/doctor-detail";
import DoctorListMain from "./home/doctor-list-main";
import OnlineDoctors from "./home/online-doctors";
import OnlineDoctorsSub from "./home/online-doctors-sub";
import email from "./patient/email";
import EmailConfirm from "./patient/emailconfirm";
import EmailSuccess from "./patient/emailsuccess";
import Textchat from "./patient/textchat";
import TextchatSuccess from "./patient/textchatsuccess";
import Bookappointment from "./patient/bookappointment";
import BookappointmentSuccess from "./patient/bookappointmentsuccess";
import Chatmessage from "./patient/chatmessage";
import VideocallList from "./patient/videocalllist";
import ChatmessageGroup from "./patient/chatmessagegroup";
import Videocall from "./patient/videocall";
import VideochatSuccess from "./patient/videochatsuccess";
import Appointmentlist from "./patient/appointmentlist";
import Inbox from "./patient/inbox";
import Send from "./patient/send";
import Messgedetail from "./patient/messgedetail";
import Profile from "./patient/profile";
import ProfileEdit from "./patient/profile-edit";
import Dashboard from "./doctor/dashboard";
import ChangePassword from "./doctor/changepassword";
import WorkTime from "./doctor/worktime";
import Doctorprofile from "./doctor/doctorprofile";
import Docservices from "./doctor/docservices";
import VideoSchedule from "./doctor/videoSchedule";
import Healtharticle from "./doctor/healtharticle";
import Healthblog from "./doctor/healthblog";
import HealthRecord from "./patient/health-record";
import HealthTrackers from "./healthTrackerCalender/health-trackers";
import HealthTrackbabymilestns from "./healthTrackerCalender/health-trackers-babymilestone";
import HealthTrackbabymilestnsdwd from "./healthTrackerCalender/health-trackers-babymilestone-download";
import HealthTrackbabymilestnsdwdpdf from "./healthTrackerCalender/health-trackers-babymilestone-download-pdf";
import HealthTrackimmunisation from "./healthTrackerCalender/health-trackers-immunisation";
import HealthTrackimmunisationdwd from "./healthTrackerCalender/health-trackers-immunisation-download";
import HealthTrackimmunisationdwdpdf from "./healthTrackerCalender/health-trackers-immunisation-download-pdf";
import HealthTrackimmunisationreminders from "./healthTrackerCalender/health-trackers-immunisation-reminders";

import HealthRecordLogin from "./patient/healthrecordlogin";
import History from "./patient/history";
import HistoryShowDetails from "./patient/history_listing";
import DoctorVideocall from "./doctor/videocall";
import DoctorInbox from "./doctor/doctorinbox";
import DoctorSend from "./doctor/doctorsend";
import DoctorMessgedetail from "./doctor/messgedetail";
import DoctorChatmessage from "./doctor/chatmessage";
import DoctorChatmessageGroup from "./doctor/chatmessagegroup";
import DoctorChatHistory from "./doctor/chat-history";
import OldChatMessage from "./patient/archivedMessageChats";

import PrescriptionList from "./patient/prescription-list";
import PrescriptionAdd from "./doctor/prescription-add";
import Common from "./home/common";
import HeaderLinks from "./dashboard/headerLinks";
import CommonMenuLinks from "./home/commonMenuLinks";
import PaymentSuccess from "./patient/paymentSuccess";
import AdminDashboard from "./adminDashboard/dashboard";
import ThemeEdit from "./home/themeEdit";
import TermsConditions from "./home/terms-conditions";
import PrivacyPolicy from "./home/privacy-policy";
import DrTermsConditions from "./doctor/terms-conditions";
import InpersonAppointment from "./doctor/inpersonAppointment";
import DoctorPrescription from "./doctor/prescriptionList";
import MapLink from "./Ganga/map";
import TestHouse from "./patient/testHouse";
import BlogList from "./doctor/blog";
import BlogDetails from "./Sabarmathi/blogDetails";
import SiteBlog from "./Sabarmathi/healthBlog";

/*** pharmacy app screens***/
import Splash from "./pharmacyApp/splash";
import DoctorNetwork from "./pharmacyApp/doctor-network";
import DoctorInvitation from "./pharmacyApp/doctor-invitation";

/*** Doctor Registration***/
import DoctorRegistration from "./doctor/registration";
import doctorRegSuccess from "./doctor/doctorRegSuccess";
import timeComponent from "./doctor/timeComponent";
import registrationErrorPage from "./doctor/registrationErrorPage";
import videoRecordList from "./doctor/videoRecordList";
import reportDoctor from "./doctor/reportDoctor";
import hospitalReports from "./dashboard/hospitalReports";
import Maintenance from "./home/maintenance";
import reportDashboard from "./dashboard/reportDashboard";
import videoaudioReports from "./dashboard/videoaudioReports";
import textChatReports from "./dashboard/textChatReports";
import inPersonReports from "./dashboard/inPersonReports";
import ThemeColor from "./adminDashboard/themeColor";
// import SpeechRecognition from "./home/speechRecognition";
import NewFeeds from "./doctor/newFeedsList";
import NewFeedsListDetails from "./doctor/newFeedsListDetails";
import OpenAppointmentlist from "./patient/openAppointmentList";
import JournalListing from "./doctor/journalListing";
import DoctorRegistrationReport from "./dashboard/doctorRegistrationReport";
import IDABannerSave from "./dashboard/BannerIDASave";
import BannerIDAListing from "./dashboard/BannerIDAListing";
import PharmaNetworkReport from "./dashboard/pharmaNetworkReport";
import DoctorNetworkReport from "./dashboard/doctorNetworkReport";
import AddJournal from "./doctor/addJournal";
import dataMigration from "./adminManagement/dataMigration/dataMigration";
import PromocodeDetail from "./hospital/promocodeDetails";
import CouponList from "./hospital/couponList";
import CouponListAdmin from "./GroupAdmin/couponList";
import GroupPolicy from "./GroupAdmin/groupPolicy";
import VideoAudioAppointment from "./GroupAdmin/video-audioAppointment";
import DoctorVideoAudioSchedule from "./GroupAdmin/DoctorVideoAudioSchedule";
import HospDashboard from "./GroupAdmin/dashboard";
import GroupProfile from "./GroupAdmin/groupProfile";
import AdminFooterLink from "./GroupAdmin/adminFooterLink";
import AdminHeaderLink from "./GroupAdmin/adminHeaderLink";
import GroupReport from "./GroupAdmin/groupReport";
import ZeroUtilization from "./GroupAdmin/zero_Utilization";
import ErrorList from "./GroupAdmin/error_list";
import AdminProfile from "./GroupAdmin/profile";
import ExpertDoctors from "./GroupAdmin/expertDoctor";
import PatientLoginHistory from "./GroupAdmin/patientLoginHistory";
import videoAudioReports from "./GroupAdmin/VideoAudioChatReports";
import videoAudioChatFeedback from "./GroupAdmin/videoAudioChatFeedback";

import inPersonApptReports from "./GroupAdmin/inPersonReports";
import textChatApptReports from "./GroupAdmin/textChatReports";
import patientTransaction from "./GroupAdmin/patientTransactionReports";
import PatientProfile from "./patient/patientProfile";
import HealthRecords from "./patient/health_records";

import HospitalDoctorSchedule from "./adminManagement/hospitalManagementList/hospitalDoctorSchedule";
import HospitalMangementList from "./adminManagement/hospitalManagementList/hospitalManagementList";
import SmsSettings from "./adminManagement/smsSettings/sms-settings";
import AddnewHsp from "./adminManagement/hospitalManagementList/addnewHosp";
import HospitalInfomation from "./adminManagement/hospitalManagementList/hospitalInformation";
import HospitalworkDetails from "./adminManagement/hospitalManagementList/hospitalworkDetails";
import HospitalSpecialisation from "./adminManagement/hospitalManagementList/hospitalSpecialisation";
import SuperAdmin from "./adminManagement/hospitalManagementList/superAdmin";
import BankAccountdetails from "./adminManagement/hospitalManagementList/bankAccountdetails";
import ConsultingTime from "./adminManagement/hospitalManagementList/consultingTime";
import VisitingTime from "./adminManagement/hospitalManagementList/visitingTime";
import Servicedetails from "./adminManagement/hospitalManagementList/servicedetails";
import Addimages from "./adminManagement/hospitalManagementList/addimages";
import HospitalAdmindetails from "./adminManagement/hospitalManagementList/hospitalAdmindetails";
import Seo from "./adminManagement/hospitalManagementList/seo";
import LogoUpload from "./adminManagement/hospitalManagementList/logoUpload";
import Photo from "./adminManagement/hospitalManagementList/photo";
import LinkHsp from "./adminManagement/hospitalManagementList/link";
import Procedurelist from "./adminManagement/hospitalManagementList/procedurelist";
import Addnewprocedurelist from "./adminManagement/hospitalManagementList/addnewprocedurelist";
import doctorManagement from "./adminManagement/doctorManagementList/doctorManagement";
import addDoctor from "./adminManagement/doctorManagementList/addDoctor";
import Doctorinformation from "./adminManagement/doctorManagementList/doctorinformation";
import DoctorOffcicehours from "./adminManagement/doctorManagementList/doctorOffcicehours";
import DoctorSpecialisation from "./adminManagement/doctorManagementList/doctorSpecialisation";
import EducationDetails from "./adminManagement/doctorManagementList/educationDetails";
import Achievementsdetails from "./adminManagement/doctorManagementList/achievementsdetails";
import Proceduredetails from "./adminManagement/doctorManagementList/proceduredetails";
import OfficialInformation from "./adminManagement/doctorManagementList/officialInformation";
import SearchOffceService from "./adminManagement/doctorManagementList/searchoffceservice";
import Doctorseo from "./adminManagement/doctorManagementList/doctorseo";
import DoctorPhoto from "./adminManagement/doctorManagementList/doctorPhoto";
import AddProcedure from "./adminManagement/hospitalManagementList/addProcedure";
import TermsOfUse from "../src/patient/terms-Of-Use";
import Desclaimer from "../src/patient/disclaimer";
import Privacy from "../src/patient/privacy";
import Support from "../src/patient/support";
import JoinAsMember from "../src/patient/join-as-member";

import siteSettings from "./adminManagement/siteSettings/siteSettings";
import siteSettingsAddEdit from "./adminManagement/siteSettings/siteSettings-AddEdit";
import siteSettingsSiteModule from "./adminManagement/siteSettings/siteSettingsSiteModule";
import siteSettingsSiteModuleByDoctor from "./adminManagement/siteSettings/siteSettingsSiteModuleByDoctor";
import siteSettingsSiteModuleByHospital from "./adminManagement/siteSettings/siteSettingsSiteModuleByHospital";
import medicineBranch from "./adminManagement/medicineBranch/medicineBranch";
import Specialisation from "./adminManagement/medicineBranch/Specialisation/Specialisation";

import DoctorPanel from "./adminManagement/messaging/doctorPanenl/doctorPanenl";
import Messaging from "./adminManagement/messaging/messaging";
import Journalisting from "./adminManagement/journalDashboard/journalListing";
import DoctorWorkdashboard from "./adminDashboard/doctorWorkdashboard";
import Qualification from "./adminManagement/doctorWorkdashboardList/qualification";
import Language from "./adminManagement/doctorWorkdashboardList/language";
import healthInformation from "./adminManagement/healthInformation/healthInformation";
import helthInfo from "./adminManagement/healthInformation/healthInfo";
import Pharmadashboard from "./adminDashboard/pharmaDashboard";
import RepApprovalList from "./adminManagement/pharmadashboardList/repApprovalList";
import RepAdd from "./adminManagement/pharmadashboardList/addRep";
import MobileappDashboard from "./adminDashboard/mobileappdashboard";
import HospitalBannerdetails from "./adminManagement/mobileAppdashboard/hospitalbannerdetails";
import Addbanner from "./adminManagement/mobileAppdashboard/addBanner";
import productDashboard from "./adminManagement/product/productDashboard";
import hospitalProducts from "./adminManagement/product/hospitalProducts/hospitalProducts";
import manageLocations from "./adminManagement/manageLocation/manageLocation";
import countries from "./adminManagement/manageLocation/manageLocations/countries/countries";
import cities from "./adminManagement/manageLocation/manageLocations/cities/cities";
import states from "./adminManagement/manageLocation/manageLocations/states/states";
import subcities from "./adminManagement/manageLocation/manageLocations/subcities/subcities";
import OrderMedicine from "./Reliance/orderMedicine";
import Feedback from "./adminManagement/dataMigration/feedback";
import DoctorPrivacyPolicy from "./doctor/privacyPolicy";

ReactDOM.render(
  <Router>
    <Route exact path="/SITEID-:siteId" component={Root} />
    <Route path="/" component={Home} exact />
    <Route path="/home/aboutus" component={AboutUs} />
    <Route path="/home/favorate" component={Favorate} />
    <Route path="/home/contactus" component={Contact} />
    <Route path="/home/telehealth" component={Telehealth} />
    <Route path="/home/branded-apps-website" component={BrandedAppsWebsite} />
    <Route path="/home/clinic-management" component={ClinicManagement} />
    <Route path="/home/clients" component={Clients} />
    <Route path="/home/doctor-detail" component={DoctorDetail} />
    <Route path="/Doctor-:slug" component={DoctorDetail} />
    <Route
      path="/home/doctor-list-main/:serviceType"
      component={DoctorListMain}
    />
    <Route path="/home/online-doctors" component={OnlineDoctors} />
    <Route path="/home/online-doctors-sub" component={OnlineDoctorsSub} />
    <Route path="/patient/email" component={email} />
    <Route path="/patient/emailconfirm" component={EmailConfirm} />
    <Route path="/patient/emailsuccess" component={EmailSuccess} />
    <Route path="/patient/textchatpayment" component={Textchat} />
    <Route path="/patient/textchatsuccess" component={TextchatSuccess} />
    <Route path="/patient/bookappointment" component={Bookappointment} />
    <Route
      path="/patient/bookappointmentsuccess"
      component={BookappointmentSuccess}
    />
    <Route path="/patient/thankyou" component={PaymentSuccess} />
    <Route path="/chat/chatmessage" component={Chatmessage} />
    <Route path="/chat/archivedMessageChats" component={OldChatMessage} />
    <Route path="/patient/videocalllist" component={VideocallList} />
    <Route path="/chat/chatmessagegroup" component={ChatmessageGroup} />
    <Route path="/patient/videocall" component={Videocall} strict />
    <Route path="/patient/videochatsuccess" component={VideochatSuccess} />
    <Route path="/patient/appointmentlist" component={Appointmentlist} />
    <Route path="/patient/inbox" component={Inbox} />
    <Route path="/patient/send" component={Send} />
    <Route path="/patient/messgedetail" component={Messgedetail} />
    <Route path="/patient/profile" component={Profile} />
    <Route path="/patient/profile-edit" component={ProfileEdit} />
    <Route path="/doctor/dashboard" component={Dashboard} />
    <Route path="/doctor/changepassword" component={ChangePassword} />
    <Route path="/doctor/worktime" component={WorkTime} />
    <Route path="/doctor/doctorprofile" component={Doctorprofile} />
    <Route path="/doctor/docservices" component={Docservices} />
    <Route path="/doctor/videoSchedule" component={VideoSchedule} />
    <Route path="/doctor/healtharticle" component={Healtharticle} />
    <Route path="/doctor/healthblog/:healthInfoId?" component={Healthblog} />
    <Route path="/doctor/privacyPolicy" component={DoctorPrivacyPolicy} />
    <Route path="/patient/health-record" component={HealthRecord} />
    <Route path="/patient/healthrecordlogin" component={HealthRecordLogin} />
    <Route path="/health-trackers" component={HealthTrackers} />
    <Route
      path="/health-trackers-babymilestone"
      component={HealthTrackbabymilestns}
    />
    <Route
      path="/health-trackers-babymilestone-download"
      component={HealthTrackbabymilestnsdwd}
    />
    <Route
      path="/health-trackers-babymilestone-download-pdf"
      component={HealthTrackbabymilestnsdwdpdf}
    />
    <Route
      path="/health-trackers-immunisation"
      component={HealthTrackimmunisation}
    />
    <Route
      path="/health-trackers-immunisation-download"
      component={HealthTrackimmunisationdwd}
    />
    <Route
      path="/health-trackers-immunisation-download-pdf"
      component={HealthTrackimmunisationdwdpdf}
    />
    <Route
      path="/health-trackers-immunisation-reminders"
      component={HealthTrackimmunisationreminders}
    />

    <Route path="/groupProfile" component={GroupProfile} />
    <Route path="/patient/history" component={History} />
    <Route
      path="/patient/historyShowDetails/:memberId?"
      component={HistoryShowDetails}
    />
    <Route path="/doctor/videocall" component={DoctorVideocall} />
    <Route path="/doctor/doctorinbox" component={DoctorInbox} />
    <Route path="/doctor/doctorsend" component={DoctorSend} />
    <Route path="/doctor/messgedetail" component={DoctorMessgedetail} />
    <Route path="/doctor/chatmessage" component={DoctorChatmessage} />
    <Route path="/doctor/chatmessagegroup" component={DoctorChatmessageGroup} />
    <Route path="/doctor/chat-history" component={DoctorChatHistory} />

    <Route path="/patient/prescriptionList" component={PrescriptionList} />
    <Route
      path="/prescriptionAdd-:hospitalId?-:memberId?-:scheduleId?-:patientName"
      component={PrescriptionAdd}
    />
    <Route path="/1-:pageName" component={Common} exact />
    <Route path="/home/themeEdit/:pageName" component={ThemeEdit} />
    <Route path="/dashboard/HeaderLinks" component={HeaderLinks} />
    <Route path="/2-:pageName" component={CommonMenuLinks} />
    <Route path="/admin/dashboard" component={AdminDashboard} />
    <Route path="/termsConditions" component={TermsConditions} />
    <Route path="/privacyPolicy" component={PrivacyPolicy} exact />
    <Route path="/termsAndConditions" component={DrTermsConditions} />
    <Route path="/doctor/inpersonAppointment" component={InpersonAppointment} />
    <Route
      path="/doctor/prescriptionList-:hospitalId?-:memberId?-:patientName?-:scheduleId"
      component={DoctorPrescription}
    />
    <Route path="/footer/map" component={MapLink} />
    <Route path="/testHouse" component={TestHouse} />
    <Route path="/splash/:splashId?/:networkId" component={Splash} />
    <Route path="/doctorNetwork" component={DoctorNetwork} />
    <Route path="/doctorInvitation" component={DoctorInvitation} />
    <Route
      path="/doctorRegistration/:pmSite?/:idaId?"
      component={DoctorRegistration}
    />
    <Route path="/doctorRegSuccess" component={doctorRegSuccess} />
    <Route path="/timeComponent" component={timeComponent} />
    <Route path="/registrationErrorPage" component={registrationErrorPage} />
    <Route path="/BlogList" component={BlogList} />
    <Route
      path="/HealthBlog/:shortDescri?/:blogId"
      component={BlogDetails}
      exact
    />
    {/* <Route path="/HealthInfo/:categoryId" component={SiteBlog} /> */}
    <Route path="/HealthInfo" component={SiteBlog} />
    <Route
      path="/doctor/videoRecordList/:appointmentId?"
      component={videoRecordList}
    />
    <Route path="/doctor/reportDoctor" component={reportDoctor} />
    <Route
      path="/dashboard/hospitalReports/:groupHospitalId"
      component={hospitalReports}
    />
    <Route path="/maintenance" component={Maintenance} exact />
    <Route
      path="/dashboard/reportDashboard/:groupHospitalId"
      component={reportDashboard}
    />
    {/* VIDEO */}

    <Route
      path="/dashboard/videoaudioReports/:groupHospitalId"
      exact
      component={videoaudioReports}
    />
    <Route
      path="/dashboard/videoAudioChatFeedback/:groupHospitalId"
      exact
      component={videoAudioChatFeedback}
    />
    <Route
      path="/dashboard/textChatReports/:groupHospitalId"
      component={textChatReports}
    />
    <Route
      path="/dashboard/inPersonReports/:groupHospitalId"
      component={inPersonReports}
    />
    <Route path="/themeColor" component={ThemeColor} />
    {/* <Route path="/speechRecognition"component={SpeechRecognition} />  */}
    <Route path="/newFeedsList" component={NewFeeds} />
    <Route path="/newFeedsListDetails" component={NewFeedsListDetails} />
    <Route
      path="/patient/openAppointmentList"
      component={OpenAppointmentlist}
    />
    <Route path="/journalListing" component={JournalListing} />
    <Route
      path="/dashboard/doctorRegistrationReport/:groupHospitalId"
      component={DoctorRegistrationReport}
    />
    <Route
      path="/dashboard/BannerIDASave:bannerId?"
      component={IDABannerSave}
    />
    <Route path="/dashboard/BannerIDAListing" component={BannerIDAListing} />
    <Route
      path="/dashboard/pharmaNetworkReport/:groupHospitalId"
      component={PharmaNetworkReport}
    />
    <Route
      path="/dashboard/doctorNetworkReport/:groupHospitalId"
      component={DoctorNetworkReport}
    />
    <Route path="/doctor/addJournal/:journalId?" component={AddJournal} />
    <Route path="/admin/dataMigration" component={dataMigration} />

    <Route path="/hospital/promocodeDetails" component={PromocodeDetail} />
    <Route path="/hospital/couponList" component={CouponList} />
    <Route path="/GroupAdmin/couponList" component={CouponListAdmin} />
    <Route
      path="/GroupAdmin/VideoAudioAppointment"
      component={VideoAudioAppointment}
    />
    <Route path="/GroupAdmin/dashboard" component={HospDashboard} />
    <Route
      path="/videoSchedule/editDoctorSchedule"
      component={DoctorVideoAudioSchedule}
    />
    <Route path="/GroupAdmin/groupPolicy" component={GroupPolicy} />
    <Route path="/GroupAdmin/adminFooterLink" component={AdminFooterLink} />
    <Route path="/GroupAdmin/adminHeaderLink" component={AdminHeaderLink} />
    <Route path="/GroupAdmin/groupReport" component={GroupReport} />
    <Route path="/GroupAdmin/zero_Utilization " component={ZeroUtilization} />
    <Route path="/GroupAdmin/error_list " component={ErrorList} />
    <Route path="/GroupAdmin/Profile" component={AdminProfile} />
    <Route path="/GroupAdmin/ExpertDoctors" component={ExpertDoctors} />
    <Route
      path="/GroupAdmin/VideoAudioChatReports"
      component={videoAudioReports}
    />
    <Route
      path="/GroupAdmin/videoAudioChatFeedback"
      component={videoAudioChatFeedback}
    />
    <Route
      path="/GroupAdmin/inPersonReports2"
      component={inPersonApptReports}
    />
    <Route
      path="/GroupAdmin/textChatReports2"
      component={textChatApptReports}
    />
    <Route
      path="/GroupAdmin/patientTransaction"
      component={patientTransaction}
    />
    <Route
      path="/GroupAdmin/patientLoginHistory"
      component={PatientLoginHistory}
    />
    <Route path="/patient/PatientProfile" component={PatientProfile} />
    <Route path="/patient/health_records" component={HealthRecords} />

    <Route path="/adminManagement/SiteSettings" component={siteSettings} />
    <Route
      path="/adminManagement/SiteSettings-AddEdit"
      component={siteSettingsAddEdit}
    />
    <Route
      path="/adminManagement/SiteSettings-SiteModule"
      component={siteSettingsSiteModule}
    />
    <Route
      path="/adminManagement/SiteSettings-SiteModuleByHospital"
      component={siteSettingsSiteModuleByHospital}
    />
    <Route
      path="/adminManagement/SiteSettings-SiteModuleByDoctor"
      component={siteSettingsSiteModuleByDoctor}
    />
    <Route path="/adminManagement/medicineBranch" component={medicineBranch} />
    <Route path="/adminManagement/specialisation" component={Specialisation} />
    <Route
      path="/adminManagement/hospitalManagementList/hospitalDoctorSchedule-:hospitalId"
      component={HospitalDoctorSchedule}
    />
    <Route
      path="/adminManagement/hospitalManagementList/hospitalManagementList"
      component={HospitalMangementList}
    />
    <Route
      path="/adminManagement/smsSettings/sms-settings"
      component={SmsSettings}
    />
    <Route
      path="/adminManagement/hospitalManagementList/addnewHosp"
      component={AddnewHsp}
    />
    <Route
      path="/adminManagement/hospitalManagementList/hospitalInformation"
      component={HospitalInfomation}
    />
    <Route
      path="/adminManagement/hospitalManagementList/hospitalworkDetails"
      component={HospitalworkDetails}
    />
    <Route
      path="/adminManagement/hospitalManagementList/hospitalSpecialisation"
      component={HospitalSpecialisation}
    />
    <Route
      path="/adminManagement/hospitalManagementList/superAdmin"
      component={SuperAdmin}
    />
    <Route
      path="/adminManagement/hospitalManagementList/bankAccountdetails"
      component={BankAccountdetails}
    />
    <Route
      path="/adminManagement/hospitalManagementList/consultingTime"
      component={ConsultingTime}
    />
    <Route
      path="/adminManagement/hospitalManagementList/visitingTime"
      component={VisitingTime}
    />
    <Route
      path="/adminManagement/hospitalManagementList/servicedetails"
      component={Servicedetails}
    />
    <Route
      path="/adminManagement/hospitalManagementList/addimages-:hospitalId"
      component={Addimages}
    />
    <Route
      path="/adminManagement/hospitalManagementList/hospitalAdmindetails"
      component={HospitalAdmindetails}
    />
    <Route path="/adminManagement/hospitalManagementList/seo" component={Seo} />
    <Route
      path="/adminManagement/hospitalManagementList/logoUpload"
      component={LogoUpload}
    />
    <Route
      path="/adminManagement/hospitalManagementList/photo"
      component={Photo}
    />
    <Route
      path="/adminManagement/hospitalManagementList/link"
      component={LinkHsp}
    />
    <Route
      path="/adminManagement/hospitalManagementList/procedurelist"
      component={Procedurelist}
    />
    <Route
      path="/adminManagement/hospitalManagementList/addnewprocedurelist"
      component={Addnewprocedurelist}
    />
    <Route
      path="/adminManagement/doctorManagementList/doctorManagement"
      component={doctorManagement}
    />
    <Route
      path="/adminManagement/doctorManagementList/addDoctor"
      component={addDoctor}
    />
    <Route
      path="/adminManagement/doctorManagementList/doctorinformation"
      component={Doctorinformation}
    />
    <Route
      path="/adminManagement/doctorManagementList/doctorOffcicehours"
      component={DoctorOffcicehours}
    />
    <Route
      path="/adminManagement/doctorManagementList/doctorSpecialisation"
      component={DoctorSpecialisation}
    />
    <Route
      path="/adminManagement/doctorManagementList/educationDetails"
      component={EducationDetails}
    />
    <Route
      path="/adminManagement/doctorManagementList/achievementsdetails"
      component={Achievementsdetails}
    />
    <Route
      path="/adminManagement/doctorManagementList/proceduredetails"
      component={Proceduredetails}
    />
    <Route
      path="/adminManagement/doctorManagementList/officialInformation"
      component={OfficialInformation}
    />
    <Route
      path="/adminManagement/doctorManagementList/searchoffceservice"
      component={SearchOffceService}
    />
    <Route
      path="/adminManagement/doctorManagementList/doctorseo"
      component={Doctorseo}
    />
    <Route
      path="/adminManagement/doctorManagementList/doctorPhoto"
      component={DoctorPhoto}
    />
    <Route
      path="/adminManagement/hospitalManagementList/addProcedure"
      component={AddProcedure}
    />
    <Route path="/adminManagement/doctorpanenl" component={DoctorPanel} />
    <Route path="/adminManagement/messaging" component={Messaging} />
    <Route
      path="/adminManagement/journalDashboard/journalListing"
      component={Journalisting}
    />

    <Route
      path="/adminDashboard/doctorWorkdashboard"
      component={DoctorWorkdashboard}
    />
    <Route
      path="/adminManagement/doctorWorkdashboardList/qualification"
      component={Qualification}
    />
    <Route
      path="/adminManagement/doctorWorkdashboardList/language"
      component={Language}
    />
    <Route
      path="/adminManagement/healthInformation"
      component={healthInformation}
    />
    <Route path="/adminManagement/healthInfo" component={helthInfo} />
    <Route path="/adminDashboard/pharmaDashboard" component={Pharmadashboard} />
    <Route
      path="/adminManagement/pharmadashboardList/repApprovalList"
      component={RepApprovalList}
    />
    <Route
      path="/adminManagement/pharmadashboardList/addRep/:repId?"
      component={RepAdd}
    />
    <Route
      path="/adminDashboard/mobileappdashboard"
      component={MobileappDashboard}
    />
    <Route
      path="/adminManagement/mobileAppdashboard/hospitalbannerdetails"
      component={HospitalBannerdetails}
    />
    <Route
      path="/adminManagement/mobileAppdashboard/addBanner/:hospitalbannerId?"
      component={Addbanner}
    />
    <Route
      path="/adminManagement/productDashboard"
      component={productDashboard}
    />
    <Route
      path="/adminManagement/hospitalProducts"
      component={hospitalProducts}
    />
    <Route
      path="/adminManagement/manageLocations"
      component={manageLocations}
    />
    <Route path="/adminManagement/countries" component={countries} />
    <Route path="/adminManagement/cities" component={cities} />
    <Route path="/adminManagement/states" component={states} />
    <Route path="/adminManagement/subcities" component={subcities} />
    <Route path="/Termsofuse" component={TermsOfUse} />
    <Route path="/Desclaimer" component={Desclaimer} />
    <Route path="/Privacy" component={Privacy} />
    <Route path="/Support" component={Support} />
    <Route path="/JoinAsMember" component={JoinAsMember} />
    <Route path="/orderMedicine" component={OrderMedicine} />
    <Route path="/5a6uk96q0w-:token" component={Home} exact />
    <Route path="/T-:token" component={Feedback} />

  </Router>,
  document.getElementById("root")
);
