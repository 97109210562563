import React, { Component } from "react";

export default class PromocodeAdd extends Component {
  componentDidMount() {
    // debugger;
    let f = this.props;
  }
  render() {
    return (
      <div class="row ">
        <div className="container mb-3">
          <div class="col-lg-12">
            <div class="form-head mt-4">Add Promo Code</div>
            <div class="dashboard-right form-section">
              <div class="row row-margin px-3">
                <label class="col-sm-4 label-text">Promo Hospital</label>
                <div class="col-sm-6">
                  <select
                    className="hspInput-promo"
                    id="hospitalId"
                    value={this.props.state.hospitalId}
                    onInput={this.props.changeHandler}
                  >
                    <option value="">All Hospital</option>
                    {this.props.state.hospitalData.map((h) => (
                      <option key={h.hospitalId} value={h.hospitalId}>
                        {h.hospitalName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div class="row row-margin px-3">
                <label class="col-sm-4 label-text">Promo Code *</label>
                <div class="col-sm-6">
                  <input
                    type="text"
                    class="input-design"
                    defaultValue=""
                    id="promoCodeName"
                    value={this.props.state.promoCodeName}
                    onInput={this.props.changeHandler}
                  />
                </div>
              </div>

              <div class="row row-margin px-3">
                <label class="col-sm-4 label-text">Discount Percentage*</label>
                <div class="col-sm-6">
                  <input
                    type="text"
                    class="input-design"
                    id="discountPercentage"
                    value={this.props.state.discountPercentage}
                    onInput={this.props.changeHandler}
                  />
                </div>
              </div>
              <div class="row row-margin px-3">
                <label class="col-sm-4 label-text">Active From Date*</label>
                <div class="col-sm-6 posrel-promo">
                  <input
                    type="date"
                    className="input-design"
                    id="activeFrom"
                    value={this.props.state.activeFrom}
                    onInput={this.props.changeHandler}
                  />
                </div>
              </div>

              <div class="row row-margin px-3">
                <label class="col-sm-4 label-text">Active To Date*</label>
                <div class="col-sm-6 posrel-promo">
                  <input
                    type="date"
                    className="input-design"
                    id="activeTo"
                    value={this.props.state.activeTo}
                    onInput={this.props.changeHandler}
                  />
                </div>
              </div>

              <div className="row px-4">
                <a href="#">
                  <input
                    type="submit"
                    value="Save"
                    className="formButton formButtonBold"
                    onClick={this.props.savePromo}
                  ></input>
                </a>
                <input
                  type="button"
                  value="cancel"
                  className="formButtonBold cancelButton"
                  onClick={this.props.cancelPromo}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
