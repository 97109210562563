import React, { Component, Suspense } from "react";
import $ from "jquery";
import { apiCalling } from "../apiService";
import ReactTooltip from 'react-tooltip';
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class PrescriptionList extends Component {
    render() {
        return (
            <div class="purpleWrap">
                <div className='loader'></div>
                <Suspense fallback={<div class="loader"></div>}>
                    <section>
                        <Header />
                        <div class="contentCoverWrapper">
                            <div class="container">
                                <div className="col-md-12 col-lg-10 col-xs-12 col-md-offset-2 col-lg-offset-2 media-wrap-margin">
                                    <div className="media-wrap">
                                        <h3 className="mediaMain-title">PRIVACY POLICY</h3>
                                        <div className="media-outer-wrap">
                                            <p><b>RHealthAssist does not provide medical service – RHealthAssist is a "communication platform".</b></p>
                                            <ul>
                                                <li>RHealthAssist takes your privacy very seriously.</li>
                                                <li>RHealthAssist is committed to protecting the information you provide via our website</li>
                                                <li>RHealthAssist aims to achieve international best practice in user confidentiality and information management.</li>
                                            </ul>
                                            <p>By registering to use the RHealthAssist sites or apps ie. "services”, you may be required to provide certain data to enable the functioning of the service. This may include contact information and also information specific to your health ("Personal Information"). This will at all times be handled with care and discretion.</p>
                                            <ul>
                                                <li>To enable appropriate advice, your Personal Information may be seen and used by service providers (“Providers”) whom you choose to interact with, by for example conducting VIDEO-CONNECTs and other RHealthAssist Platform interfaces.</li>
                                                <li>RHealthAssist strictly protects the security of your personal information and honors your choices for its intended use. In each case, we will defer to your use-preferences (wherever provided).</li>
                                            </ul>
                                            <p>RHealthAssist collects internet traffic data including cookies. This includes IP address of all visitors to the site, which enables RHealthAssist to administer its system and gather aggregate information, and minimize scope for misuse of the site.</p>
                                            <p>RHealthAssist does not intend to collect any personally identifiable information of children below the age of consent, without the guidance of the relevant parent or guardian.</p>
                                            <p>The RHealthAssist service may contains links to other sites and apps. Please note that RHealthAssist is not responsible for the privacy policies of such other services. RHealthAssist encourages all users to take care when leaving our service and to read the privacy statements of each and every website that collects personally identifiable information.</p>
                                            <p><i>RHealthAssist will, from time to time, amend this Privacy Policy by posting the most current version on the RHealthAssist website. It is up to you, the user, to periodically update yourself with the current version of the Policy.</i></p>
                                            <p>© 2019 rhealthassist.com All rights reserved</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </section>
                </Suspense>
            </div>
        );
    }
}


