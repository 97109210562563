import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.css"
import "../css/style.css"
import "../css/purpleresponsive.css"
import "../css/font-awesome.min.css"
import {Link} from "react-router-dom";
import Header from "../Reliance/header"
import Footer from "../Reliance/footer"
import logoarshad from "../image/theme/logo-arshad.png"
import logoace from "../image/theme/logo-ace.jpg"
import logoartadvanced from "../image/theme/logo-advanced.jpg"
import logoartmind from "../image/theme/logo-artmind.jpg"
import logocure from "../image/theme/logo-cure.jpg"
import logodeclogues from "../image/theme/logo-declogues.jpg"
import logogins from "../image/theme/logo-gins.jpg"
import logohello from "../image/theme/logo-hello.jpg"
import logohysterolap from "../image/theme/logo-hysterolap.jpg"
import logoictus from "../image/theme/logo-ictus.jpg"
import logomishras from "../image/theme/logo-mishras.jpg"
import logosamad from "../image/theme/logo-samad.jpg"
import logosambada from "../image/theme/logo-sambada.jpg"
import logosanjivani from "../image/theme/logo-sanjivani.jpg"
import logoskinhealth from "../image/theme/logo-skinhealth.jpg"
import logoDiabetesCentre from "../image/theme/logo-DiabetesCentre.jpg"
import logomhealth from "../image/theme/logo-mhealth.jpg"
import logoorthopod from "../image/theme/logo-orthopod.jpg"
import logopran from "../image/theme/logo-pran.jpg"
import logowholistic from "../image/theme/logo-wholistic.jpg"

export default class Clients extends Component {
  render() {
    return (
        
        <div className="purpleWrap">
            <Header></Header>
            
            <div className="container mt-5 mb-5">
              <div className="client-wrapper">
                <h4 class="bold-700 text-center">Purple Health Clients & Partners</h4>
                <p class="aboutuspara text-center my-4">Purple Health is proud to have many high quality doctors, clinics and hospitals as our clients.  Our customers use our branded websites and mobile apps products that integrate into our telemedicine solutions which also seamlessly work with our practice management software. Here are some of our clients & partners. Judge us by the company we keep!</p>
                <div className="parteners-main">
                  <div className="parteners-list">
                    <div className="parteners-img">
                      <img src={logoarshad} className="img-fluid"></img>
                    </div>
                    <div className="parteners-detail">
                      <div className="parteners-detailhead">
                      Dr. Arshad Chest <br></br>& Allergy Clinic
                      </div>
                      <div className="parteners-detailtext">
                      Dr. Arshad Chest & Allergy Clinic specializes in cardiovascular and allergy issues.
                      </div>
                    </div>
                  </div>
                  <div className="parteners-list">
                    <div className="parteners-img">
                      <img src={logopran} className="img-fluid"></img>
                    </div>
                    <div className="parteners-detail">
                      <div className="parteners-detailhead">
                      Pran Fertility <br></br>& Well-Women Clinic

                      </div>
                      <div className="parteners-detailtext">
                      Pran Fertility & Well-Women Clinic has an expert team of fertility & gynaecology doctors to help women with their fertility and medical needs.
                      </div>
                    </div>
                  </div>
                  <div className="parteners-list">
                    <div className="parteners-img">
                      <img src={logocure} className="img-fluid"></img>
                    </div>
                    <div className="parteners-detail">
                      <div className="parteners-detailhead">
                      CURE Clinic
                      </div>
                      <div className="parteners-detailtext">
                      CURE Clinic is run by Dr. Gopika Sekhar and Dr. Vinod KV. CURE Clinic specializes in paediatrics as well as urology medicine.  
                      </div>
                    </div>
                  </div>



                  <div className="parteners-list">
                    <div className="parteners-img">
                      <img src={logomhealth} className="img-fluid"></img>
                    </div>
                    <div className="parteners-detail">
                      <div className="parteners-detailhead">
                        MHealth Clinic
                      </div>
                      <div className="parteners-detailtext">
                      MHealth Clinic is an online clinic and with experienced Psychiatrists, Psychologists and Counsellors to help people with their Mental Health.
                      </div>
                    </div>
                  </div>
                  <div className="parteners-list">
                    <div className="parteners-img">
                      <img src={logoorthopod} className="img-fluid"></img>
                    </div>
                    <div className="parteners-detail">
                      <div className="parteners-detailhead">
                      Orthopod Online
                      </div>
                      <div className="parteners-detailtext">
                      Orthopod Online, Led by Dr. Vijay Shetty,  is a virtual group practice with leading Orthopaedic surgeons that can advise people with their bone, joint and orthopaedic needs.
                      </div>
                    </div>
                  </div>
                  <div className="parteners-list">
                    <div className="parteners-img">
                      <img src={logoDiabetesCentre} className="img-fluid"></img>
                    </div>
                    <div className="parteners-detail">
                      <div className="parteners-detailhead">
                      Diabetes Care Centre
                      </div>
                      <div className="parteners-detailtext">
                      Diabetes Care Centre, led by Dr. Sreejith N. Kumar, helps patients understand, track and manage their diabetes care.
                      </div>
                    </div>
                  </div>
                  <div className="parteners-list">
                    <div className="parteners-img">
                      <img src={logosamad} className="img-fluid"></img>
                    </div>
                    <div className="parteners-detail">
                      <div className="parteners-detailhead">
                      Samad IVF
                      </div>
                      <div className="parteners-detailtext">
                      Samad IVF hospital has an experienced team of IVF Doctors and specialists to help women with fertility issues.
                      </div>
                    </div>
                  </div>
                  <div className="parteners-list">
                    <div className="parteners-img">
                      <img src={logohello} className="img-fluid"></img>
                    </div>
                    <div className="parteners-detail">
                      <div className="parteners-detailhead">
                      MasterDoctor Clinic
                      </div>
                      <div className="parteners-detailtext">
                      MasterDoctor Clinic helps patients across a variety of healthcare needs: from diabetes & hypertension, to cardio & gastric, to liver & kidney issues, their doctors provide assistance across a wide field of issues.
                      </div>
                    </div>
                  </div>
                  <div className="parteners-list">
                    <div className="parteners-img">
                      <img src={logowholistic} className="img-fluid"></img>
                    </div>
                    <div className="parteners-detail">
                      <div className="parteners-detailhead">
                      Wholistic Care Center
                      </div>
                      <div className="parteners-detailtext">
                      Wholistic Care Center treats health and orthopaedic issues using a wholistic approach that includes, diet, exercise, yoga and medical treatments.
                      </div>
                    </div>
                  </div>
                  <div className="parteners-list">
                    <div className="parteners-img">
                      <img src={logoartmind} className="img-fluid"></img>
                    </div>
                    <div className="parteners-detail">
                      <div className="parteners-detailhead">
                      Art of the Mind
                      </div>
                      <div className="parteners-detailtext">
                      Art of the Mind App connects patients to Tanushri Baikar Talekar who is a Clinical Psychologist & Psychotherapist </div>
                    </div>
                  </div>
                  <div className="parteners-list">
                    <div className="parteners-img">
                      <img src={logosambada} className="img-fluid"></img>
                    </div>
                    <div className="parteners-detail">
                      <div className="parteners-detailhead">
                      Sampada Hospital
                      </div>
                      <div className="parteners-detailtext">
                      Sampada Hospital is headed by Dr Umesh Sudam Alegaonkar and focuses on Diabetic Care. 
                      </div>
                    </div>
                  </div>
                  <div className="parteners-list">
                    <div className="parteners-img">
                      <img src={logoskinhealth} className="img-fluid"></img>
                    </div>
                    <div className="parteners-detail">
                      <div className="parteners-detailhead">
                      Skin health Clinic
                      </div>
                      <div className="parteners-detailtext">
                      Skin health Clinic is run by Dr Reena, helps patients in the dermatology field as well as cosmetology.</div>
                    </div>
                  </div>
                  <div className="parteners-list">
                    <div className="parteners-img">
                      <img src={logoartadvanced} className="img-fluid"></img>
                    </div>
                    <div className="parteners-detail">
                      <div className="parteners-detailhead">
                      Advanced Bone <br></br>and Joint Clinic
                      </div>
                      <div className="parteners-detailtext">
                      Advanced Bone and Joint Clinic  is led by Dr Prajyot Jagtap specialises in Orthopedic Surgeries, Joint Replacement Surgeries and Regenerative Therapy. </div>
                    </div>
                  </div>
                  <div className="parteners-list">
                    <div className="parteners-img">
                      <img src={logosanjivani} className="img-fluid"></img>
                    </div>
                    <div className="parteners-detail">
                      <div className="parteners-detailhead">
                      Sanjivani <br></br>Ayurvedic Clinic
                      </div>
                      <div className="parteners-detailtext">
                      Sanjivani Ayurvedic Clinic is headed by Dr. Mandeep Sharma. He is committed to the Divyangjan cause and regularly devotes his time as a special educator... dealing with differently-abled children and adults. He is relentlessly involved in activates for the enablement of these individuals and tries to make a positive impact on their present and future lives. e.g. assisting them in getting facilities from the government and private sector, relevant medical certificates as needed, pension assistance, scholarships, assistive devises, education and vocational study opportunities etc.  
                      </div>
                    </div>
                  </div>
                  <div className="parteners-list">
                    <div className="parteners-img">
                      <img src={logohysterolap} className="img-fluid"></img>
                    </div>
                    <div className="parteners-detail">
                      <div className="parteners-detailhead">
                      Hysterolap
                      </div>
                      <div className="parteners-detailtext">
                      HysteroLap App connects patients to Dr  Bimal John.This app lets you book appointments,Email chat,Audio chat,video chat and text chat with the doctor. HysteroLap is a patient information, education and discussion app to help women understand and learn more about endoscopic or keyhole surgeries and related treatment. Special focus on infertility, endometriosis, fibroids, PCOS, abnormal uterine bleeding, polyps, and hysteroscopy and laparoscopy surgeries for women.</div>
                    </div>
                  </div>
                  <div className="parteners-list">
                    <div className="parteners-img">
                      <img src={logoace} className="img-fluid"></img>
                    </div>
                    <div className="parteners-detail">
                      <div className="parteners-detailhead">
                      Ace Super <br></br>speciality Clinic
                      </div>
                      <div className="parteners-detailtext">
                      Led by Dr Jayesh Rane  Ace Super speciality Clinic focuses on ENT Specialty.                      </div>
                    </div>
                  </div>
                  <div className="parteners-list">
                    <div className="parteners-img">
                      <img src={logodeclogues} className="img-fluid"></img>
                    </div>
                    <div className="parteners-detail">
                      <div className="parteners-detailhead">
                      Doclogues <br></br>(Premier Speciality Centre)
                      </div>
                      <div className="parteners-detailtext">
                      Dr  Arun L Naik specializes in all types of neurosurgical procedures. Dr Naik is known for his skills in Neurovascular surgery, endoscopic brain and spine surgery, stereotactic neurosurgery, Neurooncology, skull base surgery and pediatric neurosurgery.  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer></Footer>
        </div>
        
       
    )
  }
}
