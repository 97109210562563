import React, { Component, Suspense } from "react";
import AddImg from "../../image/icons/addbtn.png";
import Closebtn from "../../image/icons/close-sm.png";
import { apiCalling } from "../../apiService";
import $ from "jquery";
import OfficeTime from "../doctorManagementList/doctorOfficeTime";
const folderName = window.sessionStorage.getItem("homeName");
// const Header = React.lazy(() => import("../" + folderName + "/header"));
// const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class DoctorOffcicehours extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hospitalData: [],
      stateData: [],
      subCityArray: [],
      cityArray: [],
      countryArray: [],
      officeHoursDetails: this.props.doctorOfficeHours,
      hospitalId: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.selectHospital = this.selectHospital.bind(this);
    this.setOfficeHoursData = this.setOfficeHoursData.bind(this);
  }
  componentDidMount() {
    // console.log('officeHoursDetails..1..1.1.1.1.1.1....',this.state.officeHoursDetails)
    // this.fetchAllData()

    this.listing();
    if (this.props.doctorData.length === 0) {
      this.dataLoad();
    }
  }

  async fetchAllData() {
    await this.listing();
    await this.dataLoad();
  }

  dataLoad() {
    // alert("3")
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHospitalDropdown",
      }),
    };
    
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState(
          {
            hospitalData: data.hospitalData[0].hospitalData,
            //medicineBranchData: data.hospitalData[0].medicineBranchData,
          },
          () => {
            if (this.props.doctorData.length > 0) {
              // console.log("dataaaaaaa....", this.props.doctorData);
              $("#cmbState").val(this.props.doctorData[0].state);
              $("#txtEmail").val(this.props.doctorData[0].email);
              $("#txtAddress").val(this.props.doctorData[0].address);
              this.cityAgainstState(this.props.doctorData[0].city);
              //$("#cmbCity").val(this.props.doctorData[0].city)
              this.subCityAgainstCity(this.props.doctorData[0].subCity);
              // $("#cmbSubCity").val((this.props.doctorData[0].subCity !== "0" && this.props.doctorData[0].subCity !== "" )? this.props.doctorData[0].subCity : "Select")
              $("#cmbCountry").val(this.props.doctorData[0].country);
              $("#txtPinCode").val(this.props.doctorData[0].zip);
              $("#txtPhone").val(this.props.doctorData[0].phone);
              $("#txtMobile").val(this.props.doctorData[0].mobileNumber);
            }
          }
        );
      }
    });
  }

  listing() {
    //  alert("1")
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDropdownLists",
      }),
    };

    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        // alert("2")
        this.setState(
          {
            countryData: data.result[0].countryData,
            stateData: data.result[0].stateData,
            cityData: data.result[0].cityData,
            subCityData: data.result[0].subCityData,
          },
          () => {
            if (this.props.doctorData.length > 0) {
              this.dataLoad();
            }
          }
        );
      }
    });
  }
  cityAgainstState(cityEdit) {
    window.sessionStorage.setItem("seoState", $("#cmbState").val());
    // console.log("cityEdit.....", cityEdit);
    // console.log("stateEdit.....", $("#cmbState").val());
    // console.log("this.state.stateData.....", this.state.stateData);
    this.setState({
      countryArray: [],
    });
    this.setState({
      cityArray: [],
    });
    if ($("#cmbState").val() !== "0" && $("#cmbState").val() !== null) {
      let stateObj = this.state.stateData.filter(
        (o) => o.stateName === $("#cmbState").val()
      );

      // console.log("this.state.cityData.....", this.state.cityData);

      let obj1 = this.state.cityData.filter(
        (o) => o.stateName === $("#cmbState").val()
      );

      // console.log("stateObj.....", stateObj);
      // console.log("obj1.....", obj1);
      this.setState(
        {
          cityArray: obj1,
        },
        () => {
          if (cityEdit !== "") {
            $("#cmbCity").val(cityEdit);
          }
        }
      );

      let obj2 = this.state.countryData.filter(
        (o) => o.countryId === stateObj[0].countryId
      );

      // console.log("obj2....", obj2[0].countryId.toString())
      this.setState(
        {
          countryArray: obj2,
        },
        () => {
          if (this.state.countryArray.length === 1) {
            $("#cmbCountry").val(obj2[0].countryName);
          }
        }
      );
    }
  }
  subCityAgainstCity(subCityEdit) {
    this.setState({
      subCityArray: [],
    });

    let obj = this.state.subCityData.filter(
      (o) => o.cityName === $("#cmbCity").val()
    );

    this.setState(
      {
        subCityArray: obj,
      },
      () => {
        $("#cmbSubCity").val(
          subCityEdit !== "0" && subCityEdit !== "" ? subCityEdit : "0"
        );
      }
    );
  }

  addOfficeHoursDetails() {
    var flag = false;

    if ($("#cmbHospitalName").val() === "0") {
      alert("Hospital Name Required");
      $("#cmbHospitalName").focus();
      flag = true;
    }

    // for (var i = 0; i <= this.state.officeHoursDetails.length; i++) {
    //   if ($("#cmbHospitalName" + i).val() === "0") {
    //     alert("Hospital Name Required")
    //     $("#cmbHospitalName" + i).focus();
    //     flag = true;
    //     break;
    //   }
    // }

    if (flag === false) {
      var shedule1 = {
        sundayTo: $("#sh1SunTo").val(),
        sundayFrom: $("#sh1SunFrom").val(),
        mondayTo: $("#sh1MonTo").val(),
        mondayFrom: $("#sh1MonFrom").val(),
        tuesdayTo: $("#sh1TueTo").val(),
        tuesdayFrom: $("#sh1TueFrom").val(),
        wednesdayTo: $("#sh1WedTo").val(),
        wednesdayFrom: $("#sh1WedFrom").val(),
        thursdayTo: $("#sh1ThurTo").val(),
        thursdayFrom: $("#sh1ThurFrom").val(),
        fridayTo: $("#sh1FriTo").val(),
        fridayFrom: $("#sh1FriFrom").val(),
        saturdayTo: $("#sh1SatTo").val(),
        saturdayFrom: $("#sh1SatFrom").val(),
      };
      var shedule2 = {
        sundayTo: $("#sh2SunTo").val(),
        sundayFrom: $("#sh2SunFrom").val(),
        mondayTo: $("#sh2MonTo").val(),
        mondayFrom: $("#sh2MonFrom").val(),
        tuesdayTo: $("#sh2TueTo").val(),
        tuesdayFrom: $("#sh2TueFrom").val(),
        wednesdayTo: $("#sh2WedTo").val(),
        wednesdayFrom: $("#sh2WedFrom").val(),
        thursdayTo: $("#sh2ThurTo").val(),
        thursdayFrom: $("#sh2ThurFrom").val(),
        fridayTo: $("#sh2FriTo").val(),
        fridayFrom: $("#sh2FriFrom").val(),
        saturdayTo: $("#sh2SatTo").val(),
        saturdayFrom: $("#sh2SatFrom").val(),
      };
      var schedule = [];
      schedule.push(shedule1);
      schedule.push(shedule2);
      var a = {
        hospitalId: $("#cmbHospitalName").val(),
        hasOwner: "false",
        schedule: schedule,
      };
      
      this.state.officeHoursDetails.push(a);
      this.setState(
        {
          officeHoursDetails: this.state.officeHoursDetails,
        },
        () => {
          // console.log("officeHoursDetails....", this.state.officeHoursDetails)
        }
      );
    }
    $("#cmbHospitalName").val("0");
    $("#sh1MonFrom").val("0");
    $("#sh1MonTo").val("0");
    $("#sh2MonFrom").val("0");
    $("#sh2MonTo").val("0");
    $("#sh1TueFrom").val("0");
    $("#sh1TueTo").val("0");
    $("#sh2TueFrom").val("0");
    $("#sh2TueTo").val("0");
    $("#sh1WedFrom").val("0");
    $("#sh1WedTo").val("0");
    $("#sh2WedFrom").val("0");
    $("#sh2WedTo").val("0");
    $("#sh1ThurFrom").val("0");
    $("#sh1ThurTo").val("0");
    $("#sh2ThurFrom").val("0");
    $("#sh2ThurTo").val("0");
    $("#sh1FriFrom").val("0");
    $("#sh1FriTo").val("0");
    $("#sh2FriFrom").val("0");
    $("#sh2FriTo").val("0");
    $("#sh1SatFrom").val("0");
    $("#sh1SatTo").val("0");
    $("#sh2SatFrom").val("0");
    $("#sh2SatTo").val("0");
    $("#sh1SunFrom").val("0");
    $("#sh1SunTo").val("0");
    $("#sh2SunFrom").val("0");
    $("#sh2SunTo").val("0");
  }

  removeOfficeHoureDetails(hospitalId) {
    var data = $.grep(this.state.officeHoursDetails, function (e) {
      return e.hospitalId !== hospitalId;
    });
    this.state.officeHoursDetails = data;
    this.setState({
      officeHoursDetails: this.state.officeHoursDetails,
    });
  }

  handleChange(event) {
    // alert(event.target.keyCode)
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || !re.test(event.target.value)) {
      var a = event.target.value.toString().slice(0, -1);
      $("#" + event.target.id).val(a);
      if (!re.test($("#" + event.target.id).val())) {
        $("#" + event.target.id).val("");
      }
    } else {
      return true;
    }
  }
  setOfficeHoursData(name, i, mode, objectVal, schedule) {
   if (mode === "cmbHospitalName") {
      this.state.officeHoursDetails[i].hospitalId = $("#" + name).val();
    } else if (mode === "isOwner") {
      this.state.officeHoursDetails[i].hasOwner = $("#" + name).val();
    } else {
      this.state.officeHoursDetails[i].schedule[schedule][objectVal] = $(
        "#" + name
      ).val();
    }
    this.setState({
      officeHoursDetails: this.state.officeHoursDetails,
    });
  }
  selectHospital() {
    this.setState({
      hospitalId: "",
    });
    let obj = this.state.hospitalData.filter(
      (o) => o.hospitalId.toString() === $("#txtHospitalIdNew").val()
    );
    if (obj.length > 0) {
      this.setState(
        {
          hospitalId: obj[0].hospitalId,
        },
        () => {
          $("#cmbHospitalName").val(this.state.hospitalId);
          this.hospitalDetails();
        }
      );
    } else {
      this.setState(
        {
          hospitalId: "",
        },
        () => {
          $("#cmbHospitalName").val("0");
        }
      );
    }
  }
  hospitalDetails() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHospitalViewDetails",
        hospitalId: $("#cmbHospitalName").val(),
      }),
    };

    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $("#txtAddress").val(data.resultData[0].address);
        $("#cmbState").val(data.resultData[0].stateName);
        // $("#cmbCity").val(data.resultData[0].city)
        //$("#cmbSubCity").val(data.resultData[0].subCity)
        $("#cmbCountry").val(data.resultData[0].countryName);
        $("#txtPinCode").val(data.resultData[0].zip);
        $("#txtPhone").val(data.resultData[0].phone);
        this.cityAgainstState(data.resultData[0].city);
        this.subCityAgainstCity(data.resultData[0].subCity);
      }
    });
  }
  officeHourse() {
    var a = {
      officeHoursDetails: this.state.officeHoursDetails,
    };

    return a;
  }

  render() {
    return (
      <div className="bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <div className="hspworkdetails-wrapper pb-5 pt-5 mb-5">
              <div className="doctorhspwrkdetal-ofcehour mb-1 mt-1">
               
                <div className="hspinfodoctor-contentdiv doctorofcrhr-contentdiv pb-3 pt-0 mt-0 d-flex align-items-center">
                
                  <label className="hspofce-labelmob">
                    Hospital Name <span className="hspifoMantory">*</span>
                  </label>
                  <select
                    name="cmbHospitalName"
                    id="cmbHospitalName"
                    className="hspInfofieldinput hspinput-mobdoctr"
                    onChange={() => this.hospitalDetails()}
                  >
                    <option value="0">Select Hospital</option>
                    {this.state.hospitalData.map((hospitalObj, i) => {
                      return (
                        <option
                          key={hospitalObj.hospitalId}
                          value={hospitalObj.hospitalId}
                        >
                          {hospitalObj.hospitalName}({hospitalObj.hospitalId})
                        </option>
                      );
                    })}
                  </select>

                  <input
                    type="text"
                    id="txtHospitalIdNew"
                    name="txtHospitalIdNew"
                    className="hspInfofieldinput dctrofce-inputhspid ml_1"
                    placeholder="Hospital Id"
                    onChange={() => this.selectHospital()}
                  />
                  <div className=""> 
                  <img
                    src={AddImg}
                    alt="Add"
                    onClick={() => this.addOfficeHoursDetails()}
                  />
                </div>
                </div>
                {/* <div className="hspinfo-contentdiv">
                <label></label>
                <input type="text" id="txtHospitalIdNew" name="txtHospitalIdNew" className="hspInfofieldinput Infoinputwidthdoctr" placeholder="Hospital Id"  onChange={()=>this.selectHospital()}/>
              </div> */}
                <h4 class="officehrsHead">
                  Office Hours<span className="hspifoMantory">*</span>
                </h4>
                <div className="office_hours">
                  <div className="pb-3">
                    <label className="sheduleLabeldoctoffce"></label>
                    <div className="sheduledivdoctr">schedule 1</div>
                    <div className="sheduledivdoctr">schedule 2</div>
                  </div>
                  <div className="offcehrDaydiv doctroffcehrDaydiv">
                    <label>Monday</label>
                    <OfficeTime
                      idValue="sh1MonFrom"
                      nameValue="sh1"
                      timeType="From"
                    ></OfficeTime>
                    {/* <OfficeTime
                      setOfficeHoursData={this.setOfficeHoursData}
                      index={i}
                      inputVal={Obj.schedule[0].mondayFrom}
                      objectVal={"mondayFrom"}
                      schedule={0}
                      idValue="sh1MonFrom"
                      nameValue="sh1"
                      timeType="From"
                    ></OfficeTime> */}
                    <OfficeTime
                      idValue="sh1MonTo"
                      nameValue="sh1"
                      timeType="To"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh2MonFrom"
                      nameValue="sh2"
                      timeType="From"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh2MonTo"
                      nameValue="sh2"
                      timeType="To"
                    ></OfficeTime>
                  </div>
                  <div className="offcehrDaydiv doctroffcehrDaydiv">
                    <label>Tuesday</label>
                    <OfficeTime
                      idValue="sh1TueFrom"
                      nameValue="sh1"
                      timeType="From"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh1TueTo"
                      nameValue="sh1"
                      timeType="To"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh2TueFrom"
                      nameValue="sh2"
                      timeType="From"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh2TueTo"
                      nameValue="sh2"
                      timeType="To"
                    ></OfficeTime>
                  </div>
                  <div className="offcehrDaydiv doctroffcehrDaydiv">
                    <label>Wednesday</label>
                    <OfficeTime
                      idValue="sh1WedFrom"
                      nameValue="sh1"
                      timeType="From"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh1WedTo"
                      nameValue="sh1"
                      timeType="To"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh2WedFrom"
                      nameValue="sh2"
                      timeType="From"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh2WedTo"
                      nameValue="sh2"
                      timeType="To"
                    ></OfficeTime>
                  </div>
                  <div className="offcehrDaydiv doctroffcehrDaydiv">
                    <label>Thursday</label>
                    <OfficeTime
                      idValue="sh1ThurFrom"
                      nameValue="sh1"
                      timeType="From"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh1ThurTo"
                      nameValue="sh1"
                      timeType="To"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh2ThurFrom"
                      nameValue="sh2"
                      timeType="From"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh2ThurTo"
                      nameValue="sh2"
                      timeType="To"
                    ></OfficeTime>
                  </div>
                  <div className="offcehrDaydiv doctroffcehrDaydiv">
                    <label>Friday</label>
                    <OfficeTime
                      idValue="sh1FriFrom"
                      nameValue="sh1"
                      timeType="From"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh1FriTo"
                      nameValue="sh1"
                      timeType="To"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh2FriFrom"
                      nameValue="sh2"
                      timeType="From"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh2FriTo"
                      nameValue="sh2"
                      timeType="To"
                    ></OfficeTime>
                  </div>
                  <div className="offcehrDaydiv doctroffcehrDaydiv">
                    <label>Saturday</label>
                    <OfficeTime
                      idValue="sh1SatFrom"
                      nameValue="sh1"
                      timeType="From"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh1SatTo"
                      nameValue="sh1"
                      timeType="To"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh2SatFrom"
                      nameValue="sh2"
                      timeType="From"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh2SatTo"
                      nameValue="sh2"
                      timeType="To"
                    ></OfficeTime>
                  </div>
                  <div className="offcehrDaydiv doctroffcehrDaydiv">
                    <label>Sunday</label>
                    <OfficeTime
                      idValue="sh1SunFrom"
                      nameValue="sh1"
                      timeType="From"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh1SunTo"
                      nameValue="sh1"
                      timeType="To"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh2SunFrom"
                      nameValue="sh2"
                      timeType="From"
                    ></OfficeTime>
                    <OfficeTime
                      idValue="sh2SunTo"
                      nameValue="sh2"
                      timeType="To"
                    ></OfficeTime>
                  </div>
                </div>
              </div>
              {this.state.officeHoursDetails.map((Obj, i) => {
                return (
                  <div
                    key={Obj.hospitalId}
                    className="doctorhspwrkdetal-ofcehour mb-1 mt-1"
                  >
                   
                    <div className="hspinfodoctor-contentdiv doctorofcrhr-contentdiv pb-3 pt-1">
                    <div className="addimgeDivHsp">
                      <img
                        src={Closebtn}
                        alt="Close"
                        onClick={() =>
                          this.removeOfficeHoureDetails(Obj.hospitalId)
                        }
                        width="20"
                      />
                    </div>
                      <label>
                        Hospital Name <span className="hspifoMantory">*</span>
                      </label>
                      <select
                        name={"cmbHospitalName" + i}
                        id={"cmbHospitalName" + i}
                        className="hspInfofieldinput"
                        value={Obj.hospitalId}
                        onChange={(e) =>
                          this.setOfficeHoursData(
                            "cmbHospitalName" + i,
                            i,
                            "cmbHospitalName",
                            "",
                            ""
                          )
                        }
                      >
                        <option value="0">Select Hospital</option>
                        {this.state.hospitalData.map((hospitalObj, i) => {
                          return (
                            <option
                              key={hospitalObj.hospitalId}
                              value={hospitalObj.hospitalId}
                            >
                              {hospitalObj.hospitalName}-{hospitalObj.hospitalId}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <h4 class="officehrsHead">
                      Office Hours<span className="hspifoMantory">*</span>
                    </h4>

                    <div className="office_hours">
                      <div className="doctroffcehrDay hspinfodoctor-contentdiv mb-4">
                        <label>Hospital Owner</label>
                        <select
                          name={"isOwner" + i}
                          id={"isOwner" + i}
                          value={Obj.hasOwner}
                          className="hspInfofieldinput "
                          onChange={(e) =>
                            this.setOfficeHoursData(
                              "isOwner" + i,
                              i,
                              "isOwner",
                              "",
                              ""
                            )
                          }
                        >
                          <option value="false">No</option>
                          <option value="true">Yes</option>
                        </select>
                      </div>
                      <div className="pb-3">
                        <label className="sheduleLabeldoctoffce"></label>
                        <div className="sheduledivdoctr">schedule 1</div>
                        <div className="sheduledivdoctr">schedule 2</div>
                      </div>
                      <div className="offcehrDaydiv doctroffcehrDaydiv">
                        <label>Monday</label>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[0].mondayFrom}
                          objectVal={"mondayFrom"}
                          schedule={0}
                          idValue={"sh1MonFrom" + i}
                          nameValue={"sh1MonFrom" + i}
                          timeType="From"
                        ></OfficeTime>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[0].mondayTo}
                          objectVal={"mondayTo"}
                          schedule={0}
                          idValue={"sh1MonTo" + i}
                          nameValue={"sh1MonTo" + i}
                          timeType="To"
                        ></OfficeTime>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[1].mondayFrom}
                          objectVal={"mondayFrom"}
                          schedule={1}
                          idValue={"sh2MonFrom" + i}
                          nameValue={"sh2MonFrom" + i}
                          timeType="From"
                        ></OfficeTime>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[1].mondayTo}
                          objectVal={"mondayTo"}
                          schedule={1}
                          idValue={"sh2MonTo" + i}
                          nameValue={"sh2MonTo" + i}
                          timeType="To"
                        ></OfficeTime>
                      </div>
                      <div className="offcehrDaydiv doctroffcehrDaydiv">
                        <label>Tuesday</label>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[0].tuesdayFrom}
                          objectVal={"tuesdayFrom"}
                          schedule={0}
                          idValue={"sh1TueFrom" + i}
                          nameValue={"sh1TueFrom" + i}
                          timeType="From"
                        ></OfficeTime>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[0].tuesdayTo}
                          objectVal={"tuesdayTo"}
                          schedule={0}
                          idValue={"sh1TueTo" + i}
                          nameValue={"sh1TueTo" + i}
                          timeType="To"
                        ></OfficeTime>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[1].tuesdayFrom}
                          objectVal={"tuesdayFrom"}
                          schedule={1}
                          idValue={"sh2TueFrom" + i}
                          nameValue={"sh2TueFrom" + i}
                          timeType="From"
                        ></OfficeTime>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[1].tuesdayTo}
                          objectVal={"tuesdayTo"}
                          schedule={1}
                          idValue={"sh2TueTo" + i}
                          nameValue={"sh2TueTo" + i}
                          timeType="To"
                        ></OfficeTime>
                      </div>
                      <div className="offcehrDaydiv doctroffcehrDaydiv">
                        <label>Wednesday</label>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[0].wednesdayFrom}
                          objectVal={"wednesdayFrom"}
                          schedule={0}
                          idValue={"sh1WedFrom" + i}
                          nameValue={"sh1WedFrom" + i}
                          timeType="From"
                        ></OfficeTime>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[0].wednesdayTo}
                          objectVal={"wednesdayTo"}
                          schedule={0}
                          idValue={"sh1WedTo" + i}
                          nameValue={"sh1WedTo" + i}
                          timeType="To"
                        ></OfficeTime>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[1].wednesdayFrom}
                          objectVal={"wednesdayFrom"}
                          schedule={1}
                          idValue={"sh2WedFrom" + i}
                          nameValue={"sh2WedFrom" + i}
                          timeType="From"
                        ></OfficeTime>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[1].wednesdayTo}
                          objectVal={"wednesdayTo"}
                          schedule={1}
                          idValue={"sh2WedTo" + i}
                          nameValue={"sh2WedTo" + i}
                          timeType="To"
                        ></OfficeTime>
                      </div>
                      <div className="offcehrDaydiv doctroffcehrDaydiv">
                        <label>Thursday</label>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[0].thursdayFrom}
                          objectVal={"thursdayFrom"}
                          schedule={0}
                          idValue={"sh1ThurFrom" + i}
                          nameValue={"sh1ThurFrom" + i}
                          timeType="From"
                        ></OfficeTime>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[0].thursdayTo}
                          objectVal={"thursdayTo"}
                          schedule={0}
                          idValue={"sh1ThurTo" + i}
                          nameValue={"sh1ThurTo" + i}
                          timeType="To"
                        ></OfficeTime>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[1].thursdayFrom}
                          objectVal={"thursdayFrom"}
                          schedule={1}
                          idValue={"sh2ThurFrom" + i}
                          nameValue={"sh2ThurFrom" + i}
                          timeType="From"
                        ></OfficeTime>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[1].thursdayTo}
                          objectVal={"thursdayTo"}
                          schedule={1}
                          idValue={"sh2ThurTo" + i}
                          nameValue={"sh2ThurTo" + i}
                          timeType="To"
                        ></OfficeTime>
                      </div>
                      <div className="offcehrDaydiv doctroffcehrDaydiv">
                        <label>Friday</label>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[0].fridayFrom}
                          objectVal={"fridayFrom"}
                          schedule={0}
                          idValue={"sh1FriFrom" + i}
                          nameValue={"sh1FriFrom" + i}
                          timeType="From"
                        ></OfficeTime>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[0].fridayTo}
                          objectVal={"fridayTo"}
                          schedule={0}
                          idValue={"sh1FriTo" + i}
                          nameValue={"sh1FriTo" + i}
                          timeType="To"
                        ></OfficeTime>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[1].fridayFrom}
                          objectVal={"fridayFrom"}
                          schedule={1}
                          idValue={"sh2FriFrom" + i}
                          nameValue={"sh2FriFrom" + i}
                          timeType="From"
                        ></OfficeTime>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[1].fridayTo}
                          objectVal={"fridayTo"}
                          schedule={1}
                          idValue={"sh2FriTo" + i}
                          nameValue={"sh2FriTo" + i}
                          timeType="To"
                        ></OfficeTime>
                      </div>
                      <div className="offcehrDaydiv doctroffcehrDaydiv">
                        <label>Saturday</label>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[0].saturdayFrom}
                          objectVal={"saturdayFrom"}
                          schedule={0}
                          idValue={"sh1SatFrom" + i}
                          nameValue={"sh1SatFrom" + i}
                          timeType="From"
                        ></OfficeTime>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[0].saturdayTo}
                          objectVal={"saturdayTo"}
                          schedule={0}
                          idValue={"sh1SatTo" + i}
                          nameValue={"sh1SatTo" + i}
                          timeType="To"
                        ></OfficeTime>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[1].saturdayFrom}
                          objectVal={"saturdayFrom"}
                          schedule={1}
                          idValue={"sh2SatFrom" + i}
                          nameValue={"sh2SatFrom" + i}
                          timeType="From"
                        ></OfficeTime>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[1].saturdayTo}
                          objectVal={"saturdayTo"}
                          schedule={1}
                          idValue={"sh2SatTo" + i}
                          nameValue={"sh2SatTo" + i}
                          timeType="To"
                        ></OfficeTime>
                      </div>
                      <div className="offcehrDaydiv doctroffcehrDaydiv">
                        <label>Sunday</label>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[0].sundayFrom}
                          objectVal={"sundayFrom"}
                          schedule={0}
                          idValue={"sh1SunFrom" + i}
                          nameValue={"sh1SunFrom" + i}
                          timeType="From"
                        ></OfficeTime>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[0].sundayTo}
                          objectVal={"sundayTo"}
                          schedule={0}
                          idValue={"sh1SunTo" + i}
                          nameValue={"sh1SunTo" + i}
                          timeType="To"
                        ></OfficeTime>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[1].sundayFrom}
                          objectVal={"sundayFrom"}
                          schedule={1}
                          idValue={"sh2SunFrom" + i}
                          nameValue={"sh2SunFrom" + i}
                          timeType="From"
                        ></OfficeTime>
                        <OfficeTime
                          setOfficeHoursData={this.setOfficeHoursData}
                          index={i}
                          inputVal={Obj.schedule[1].sundayTo}
                          objectVal={"sundayTo"}
                          schedule={1}
                          idValue={"sh2SunTo" + i}
                          nameValue={"sh2SunTo" + i}
                          timeType="To"
                        ></OfficeTime>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="container">
              <div className="hspinfo-contentdiv">
                <label>
                  Email <span className="hspifoMantory">*</span>
                </label>
                <input
                  type="text"
                  id="txtEmail"
                  className="hspInfofieldinput Infoinputwidthdoctr"
                  placeholder="Email"
                />
              </div>
              <div className="hspinfo-contentdiv">
                <label>
                  Address <span className="hspifoMantory">*</span>
                </label>
                <input
                  type="text"
                  id="txtAddress"
                  name="txtAddress"
                  className="hspInfofieldinput Infoinputwidthdoctr"
                  placeholder="Address"
                />
              </div>
              <div className="hspinfo-contentdiv">
                <label>
                  State <span className="hspifoMantory">*</span>
                </label>
                <select
                  name="cmbState"
                  id="cmbState"
                  className="hspInfofieldinput Infoinputwidthdoctr"
                  onChange={() => this.cityAgainstState()}
                >
                  <option value="0">Select </option>
                  {this.state.stateData.map((stateObj, i) => {
                    return (
                      <option
                        key={stateObj.stateName}
                        value={stateObj.stateName}
                      >
                        {stateObj.stateName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="hspinfo-contentdiv">
                <label>
                  City <span className="hspifoMantory">*</span>
                </label>
                <select
                  name="cmbCity"
                  id="cmbCity"
                  className="hspInfofieldinput Infoinputwidthdoctr"
                  onChange={() => this.subCityAgainstCity()}
                >
                  <option value="0">Select </option>
                  {this.state.cityArray.map((stateObj, i) => {
                    return (
                      <option key={stateObj.cityName} value={stateObj.cityName}>
                        {stateObj.cityName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="hspinfo-contentdiv">
                <label>Sub City </label>
                <select
                  name="cmbSubCity"
                  id="cmbSubCity"
                  className="hspInfofieldinput Infoinputwidthdoctr"
                >
                  <option value="0">Select</option>
                  {this.state.subCityArray.map((stateObj, i) => {
                    return (
                      <option
                        key={stateObj.subcityName}
                        value={stateObj.subcityName}
                      >
                        {stateObj.subcityName}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="hspinfo-contentdiv">
                <label>
                  Country <span className="hspifoMantory">*</span>
                </label>
                <select
                  name="cmbCountry"
                  id="cmbCountry"
                  className="hspInfofieldinput Infoinputwidthdoctr"
                >
                  <option value="0">Select</option>
                  {this.state.countryArray.map((LangObj, i) => {
                    return (
                      <option
                        key={LangObj.countryName}
                        value={LangObj.countryName}
                      >
                        {LangObj.countryName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="hspinfo-contentdiv">
                <label>
                  Pin Code <span className="hspifoMantory">*</span>
                </label>
                <input
                  type="text"
                  id="txtPinCode"
                  name="txtPinCode"
                  className="hspInfofieldinput Infoinputwidthdoctr"
                  placeholder="Pin Code"
                  onKeyUp={this.handleChange}
                  onChange={this.handleChange}
                />
              </div>
              <div className="hspinfo-contentdiv">
                <label>Phone </label>
                <input
                  type="text"
                  id="txtPhone"
                  name="txtPhone"
                  className="hspInfofieldinput Infoinputwidthdoctr"
                  placeholder="Phone"
                  onKeyUp={this.handleChange}
                  onChange={this.handleChange}
                />
              </div>
              <div className="hspinfo-contentdiv">
                <label>
                  Mobile <span className="hspifoMantory">*</span>
                </label>
                <input
                  type="text"
                  id="txtMobile"
                  name="txtMobile"
                  className="hspInfofieldinput Infoinputwidthdoctr"
                  placeholder="Mobile"
                  onKeyUp={this.handleChange}
                  onChange={this.handleChange}
                />
              </div>

              {/* <div className="superadminDivBoxcover">
                      <div className="hspinfo-contentdiv">
                            <label>Medicine branch<span className="hspifoMantory">*</span></label>                              
                             <select name="" id="" className="hspInfofieldinput Infoinputwidthdoctr">
                                <option value="">Select</option>
                                {this.state.medicineBranchData.map((branchObj, i) => {
                                  return (                             
                                   <option value={branchObj.medicineBranchId}>{branchObj.medicineBranchName}</option>
                                  )
                                })}
                             </select>                       
                         </div>  
                    </div> */}
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}></Suspense>
      </div>
    );
  }
}
