import React, { Component, Suspense } from "react";
import $ from "jquery";

export default class HospitalSpecialisation extends Component {
  componentDidMount() {
    if (this.props.specializationIdEdit.length > 0) {
      for (var i = 0; i < this.props.specializationIdEdit.length; i++) {
        $(
          "#chkSpecialisation" +
            this.props.specializationIdEdit[i].specializationId
        ).prop("checked", true);
      }
    }
  }

  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <div className="specializationWraper pb-5">
              <h2 className="hspInfoSubhead pb-3">
                Specialisation{" "}
                <span>(Choose atleast one Primary Specialisation)*</span>
              </h2>

              <div className="hspspecializationDiv">
                <div className="table-bordered-hspSpecl table-responsive">
                  <table className="table table-bordered-hspSpecl mainBx">
                    <tbody>
                      <tr>
                        {this.props.specializationData.map(
                          (specializationDataObj, s) => (
                            <td key={s} className="hosptable-tdinline table_overflowtd">
                              <div className="d-flex align-items-center">
                              <input
                                type="checkbox"
                                id={
                                  "chkSpecialisation" +
                                  specializationDataObj.specializationId
                                }
                              />

                              <div className="tdtxtLabel-hsp text-nowrap">
                                {specializationDataObj.specialityName}
                              </div>
                              </div>
                            </td>
                          )
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </Suspense>

        <Suspense fallback={<div></div>}></Suspense>
      </div>
    );
  }
}
