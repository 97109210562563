import React, { Component, Suspense } from "react";
import $, { data } from 'jquery'
import { apiCalling } from "../apiService";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
var mobileNumber;
export default class History extends Component {
  constructor(props){
    super(props);
    this.state = {
      mobileNumber:"",
      otpValid:""
    }
  }
  componentDidMount(){

    const userAPI = {
      method: "POST",
      body: JSON.stringify({
        functionName: "userDetails",
        siteId: "",
        userId: window.sessionStorage.getItem("userId"),
      }),
    };
    apiCalling(userAPI).then((data)=>{
      // console.log("console....",data);
      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          "functionName": "commonSms",
          "mobileNumber": data.userData[0].phoneNumber
        })
      }
      apiCalling(apiJson).then((data)=>{
        if(data.success==="1"){
          // console.log("data",data)
          alert(data.successMessage)
        }
      })
      this.setState({
        mobileNumber: data.userData[0].phoneNumber
      })
      let number = data.userData[0].phoneNumber
      let output = []
      let sNumber = number.toString();
      for (var i = 0, len = sNumber.length; i < len; i += 1) {
        if(i>=8){
          output.push(+sNumber.charAt(i));
        }
        else(
          output.push("*")
        )
      }
      let toNum=output.join('')
      $("#mobileNumber").val(toNum)
      
    })
    
  }
  verifyOTP(){
    if($("#mobileNumber").val()===""){
      alert("Mobile Number Required");
      setTimeout(()=>{
        $("#mobileNumber").focus();
      },20)
      return false
    }
    else if($("#otpValid").val()===""){
      alert("OTP Required");
      setTimeout(()=>{
        $("#otpValid").focus();
      },20)
      return false
    }
    else{
      const verifyOtp = {
        method: "POST",
        body: JSON.stringify({
          "functionName": "checkValidOTP",
          "mobileNumber": this.state.mobileNumber,
          "otp": this.state.otpValid
        })
      }
      apiCalling(verifyOtp).then((data)=>{
        // console.log("data",data)
        if(data.success==="1"){
          window.location.href = "/patient/historyShowDetails/"+window.sessionStorage.getItem("userId")
        }
        else{
          alert(data.errorMessage)
        }
      })
    }
  }
  textType(e){
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code > 47 && code < 58) ) { // lower alpha (a-z)
      alert("Only Numbers Accepted")
      e.preventDefault();
    }
  }
  changeHandler(e){
    this.setState({
      [e.target.id]:e.target.value
    })
  }
  refresh(){
    // console.log("refreshhhh",this.state.otpValid);
    $("#otpValid").val('')
    this.setState({
      otpValid:""
    })
    
  }
  render() {
    return (
      <div class="purpleWrap  ">
          <Suspense fallback={<div ></div>}>
          <section>
          <div className='loader'></div>
            <Header />
            <div class="container container-auto">
              <div class="main-wrapper1">
                <div className="col-md-12 mb-3">
                  <h5 className="mb-0">History</h5>
                </div>
                <div className="col-md-12  position-relative">
                  <div className="health_record">
                    <h5 className="text-primary">Help us to Make your data secure !</h5>
                    <p className="mb-5">Your One Time PIN (OTP) has been sent to your Mobile Number
                        Please enter the OTP below</p>
                        <div className="row">
                          <div className="col-md-6 d-flex align-items-center mb-3">
                            <label className="mb-0">Mobile Number</label><span className="ml-auto">:</span>
                          </div>
                          <div className="col-md-6 mb-3">
                            <input 
                              id="mobileNumber"  
                              className="input-design mb-0"
                              readOnly
                            />
                          </div>
                          <div className="col-md-6 d-flex align-items-center  mb-3">
                            <label className="mb-0">One time Pin</label><span className="ml-auto">:</span>
                          </div>
                          <div className="col-md-6  mb-3 d-flex align-items-center">
                            <input 
                              id="otpValid" 
                              type="text" 
                              className="input-design mb-0"
                              onChange={(e)=>this.changeHandler(e)} 
                              onKeyPress={this.textType}                             
                            />
                            <i 
                            className="fa fa-refresh ml-2 text-primary"
                            onClick={()=>this.refresh()}
                            />
                          </div>
                          <div className="col-md-12 text-right">
                            <input type="submit" className="formButtonBg mb-0" value="Verify"onClick={()=>this.verifyOTP()}/>
                          </div>
                        </div>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
