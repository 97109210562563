import React, { Component, Suspense } from "react";
import NewFeedCalender from "../doctor/newFeedCalender";
import user from "../image/theme/user.png";
import file from "../image/icons/file.png";
import DatePicker from "react-datepicker";
import newsfeedimg from "../image/icons/newsfeedimg.png";
import $ from "jquery";
import { apiCalling } from "../apiService";
import moment from "moment";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class NewFeeds extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pharmaRepCompanyName: window.sessionStorage.getItem('pharmaRepCompanyName'),
      createdOnDate: window.sessionStorage.getItem('createdOnDate'),
      pharmaRepName: window.sessionStorage.getItem('pharmaRepName'),
      adName: window.sessionStorage.getItem('adName'),
      adImages: window.sessionStorage.getItem('adImages'),
      description: window.sessionStorage.getItem('description'),
      brochureLink: window.sessionStorage.getItem('brochureLink'),
      isMedicalAd: window.sessionStorage.getItem('isMedicalAd'),
      pharmaRepAdsId: window.sessionStorage.getItem('pharmaRepAdsId'),
    }
  }

  typeRequest(type) {
    const dataList = {
      method: 'POST',
      body: JSON.stringify({
        "functionName": "pharmaAdRequest",
        "userId": window.sessionStorage.getItem("userId"),
        "adId": this.state.pharmaRepAdsId,
        "requestType": type
      })
    }
    $(".loader").show();
    apiCalling(dataList).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
        alert("Request Sent Successfully")
      } else if (data.success == "0") {
        alert(data.errorMessage)
      }
    });
  }

  OfficeRequest(id) {
    $("#appointment-edit-main"+id).show();
  }

  
  newsPageList() {
    window.location.href = "/newFeedsList";
  }
  srcTypeImage(ev) {
    ev.target.src = newsfeedimg
  }

  componentDidMount(){
   // alert(window.sessionStorage.getItem('isMedicalAd'))
  }
  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />{" "}
            <div className='loader'></div>
          </section>
        </Suspense>
        <div className="container blog-wrapper" id="blogList">
          {/* <h1 className="telehealthfeaturehead text-center"> News Feed </h1> */}
          <div className="row ">
            <div className="col-lg-12 col-md-12 sh-blg" id="blog-detailsId">
              <div className="sh-newsfeedsbtn">
                <button className="addNew-btn" onClick={() => this.newsPageList()}>News Feed</button>
           </div>
              <div className="row mt-1 mt-md-0">
              
                <div className="blog-listbox sh-blog-listbox-dtls">
                  <div className="blogtag-main sh-blog-infos">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="sh-blog-title">
                          <p>{this.state.pharmaRepCompanyName}</p>
                          <p>{this.state.createdOnDate}</p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6 sh-d-center">
                        <div className="sh-blog-title sh-username ">
                          <div className="sh-user-pic">
                            <img src={user} />
                          </div>
                          <p>{this.state.pharmaRepName}</p>
                        </div>
                      </div>
                    </div>
                    <div className="sh-blog-head">
                      <div className="bloguserList-text-dtls">{this.state.adName}</div>
                    </div>
                  </div>
                  <div className="blog-listImgDiv">
                    <img onError={this.srcTypeImage} src={this.state.adImages} alt="Blog Image Name" className="img-fluid  "></img>
                  </div>

                  <div className="col-lg-12 col-md-12 col-sm-12 col-12  ">
                    <div className=" sh-dwnld">
                      {this.state.isMedicalAd === 'true' &&
                        <button class="formButton m-2" onClick={() => this.typeRequest("Request Sample")}>Request Sample</button>
                      }
                      <button class="formButton m-2" onClick={() => this.typeRequest("Request Information")}>Request Information</button>
                      <button class="formButton m-2" onClick={() => this.typeRequest("Request Call")}>Request Call</button>
                       {this.state.isMedicalAd === 'true' && 
                      <button class="formButton m-2" onClick={() => this.OfficeRequest(this.state.pharmaRepAdsId)}>Request Office Visit</button>
                        }
                      <NewFeedCalender requestId={this.state.pharmaRepAdsId} requestedType={"Office Visit"}></NewFeedCalender>
                    </div>
                  </div>
                  <div className="blog-list-detils">
                    {/* <div className="blogtag-name">Coronavirus | World could have prevented COVID catastrophe: expert panel</div> */}
                    <div className="bloguserList-text sh-bloguserList-text">{this.state.description}</div>
                  </div>

                  {this.state.brochureLink && this.state.brochureLink !== "" &&
                    <div className="blogtag-main sh-blog-infos">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-6 col-12 sh-d-left">
                            <a href={this.state.brochureLink} target="_blank" download>
                              <div className="sh-blog-title sh-dwnld " style={{ padding: "15px 0" }}>
                                <div className="sh-user-pic">
                                  <img src={file} />
                                </div>
                                <p>Download Brochure</p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 py-5" id="blog-detailsId" style={{ textAlign: "center" }}><p className="error-msg text-center mb-0" > {this.state.showMessage} </p></div>
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
