import React, { Component, Suspense } from "react";
import { apiCalling } from "../../apiService";
import $ from "jquery";
const folderName = window.sessionStorage.getItem("homeName");
// const Header = React.lazy(() => import("../" + folderName + "/header"));
// const Footer = React.lazy(() => import("../" + folderName + "/footer"));


export default class DoctorSpecialisation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            specialization: [],
            medicineBranchData: [],
            medicineBranchId: null,
            
        }
        this.getSpecialisation = this.getSpecialisation.bind(this)
    }
    componentDidMount() {
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                "functionName": "getDropdownLists"

            })
        };
        // console.log("listinggg.222222..", apiJson)
        apiCalling(apiJson).then((data) => {
            // console.log("medicineBranchData..", data)
            //console.log("this.props.doctorData.length..", this.props.doctorData.length)
            if (data.success === "1") {
                this.setState({
                    medicineBranchData: data.result[0].medicineBranchData,
                }
                ,()=>{
                    $('#cmbMedicineBranch option[value="1"]').prop("selected",true);
                    this.getSpecialisation()
                    if(this.props.doctorData.length > 0){ 
                        $("#cmbMedicineBranch").val(this.props.doctorData[0].medicineBranchId)
                        $("#txtProfessionalStatement").val(this.props.doctorData[0].professionalStatement)
                        this.getSpecialisation()
                    }
                    else{
                        $("#cmbMedicineBranch").val('')
                        $("#txtProfessionalStatement").val('')
                    }
                }
                )
               
            }

        });
        

    }
    getSpecialisation() {
        this.setState({
            specialization: []
          }, () => {
            var branchId = $("#cmbMedicineBranch").val()
            // console.log('branchId.......',branchId)
        //    console.log('this.state.medicineBranchData.......',this.state.medicineBranchData)
            

           if(branchId !== ""){
            let specilisationArray =this.state.medicineBranchData.filter(o => o.medicineBranchId.toString() === branchId.toString());
            
           // var specilisationArray = this.state.medicineBranchData[branchIndex[0]].specializationData
            // console.log("specilisationArray.....", specilisationArray)
            this.setState({
              specialization: specilisationArray[0].specializationData,
              medicineBranchId: branchId
            },() => {
                for(var i=0; i<this.props.specialisationEdit.length; i++){
                    $("#chkSpecialisation" + this.props.specialisationEdit[i].Id).prop('checked', true)
                    if(this.props.specialisationEdit[i].isPrimarySpecialization === true){
                        $("#chkPrimarySpecialisation" + this.props.specialisationEdit[i].Id).prop('checked', true)
                    }
                }
            });
        }
            // console.log("specialization.....", this.state.specialization)
          });
    
    }
    primarySpecialisationValidation(specialisationId) {
        if (($("#chkPrimarySpecialisation" + specialisationId).is(':checked') === true) && ($("#chkSpecialisation" + specialisationId).is(':checked') === false)) {
          alert("Please add corresponding Specialisation")
          $("#chkPrimarySpecialisation" + specialisationId).prop('checked', false)
    
        }
        // else{
        //     localStorage.setItem("specialisation",$("#chkSpecializationName").val());
        //     console.log("specialisation11111111111111111111111111111",$("#chkSpecializationName").val())
        // }
    }
    setProfessionalStatement(){
        let statement = $("#txtProfessionalStatement").val()
        this.props.proffesionalStatementSetting(statement)
    }
      specialisations(){
          var a = {
            medicineBranchId: this.state.medicineBranchId,
            specialization:this.state.specialization
          }
         
          return a;
      }
    render() {
        return (
            <div className="bg-clr-wthHead">
                <Suspense fallback={<div></div>}>
                    <section>
                        <div className="container">
                        <div className="superadminDivBoxcover pt-0">
                            <div className="hspinfo-contentdiv">
                                <label>Medicine branch<span className="hspifoMantory">*</span></label>
                                <select name="cmbMedicineBranch" id="cmbMedicineBranch" className="hspInfofieldinput Infoinputwidthdoctr" onChange={() => this.getSpecialisation()}>
                                    <option value="">Select</option>
                                    {this.state.medicineBranchData.map((branchObj, i) => {
                                        return (
                                            <option key={branchObj.medicineBranchId} value={branchObj.medicineBranchId}>{branchObj.medicineBranchName}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                       
                            <div className="specializationWraper pb-5 pt-5">
                                <h2 className="hspInfoSubhead pb-3">Specialisation <span>(Choose atleast one Primary Specialisation)*</span></h2>
                                {this.state.specialization.length > 0 ?
                                <div className="hspspecializationDiv">
                                    <div className="table-bordered-hspSpecl">
                                        <table className="table table-bordered-hspSpecl mainBx table-specializtin-admin">

                                            <tbody>
                                                {this.state.specialization.map((speObj, s) => {
                                                    return (
                                                        <tr key={speObj.specialityId} className="admindctr-specialzation-tr">
                                                            <td className ="admindctr-specialzation">
                                                                <input type="checkbox" id={"chkSpecialisation" + speObj.specialityId} value={speObj.specialityId} />
                                                                <input type="hidden" id="chkSpecializationName" value={speObj.speciality} />
                                                                <div className="tdtxtLabel-hsp">{speObj.speciality}</div>
                                                            </td>
                                                            <td className ="admindctr-specialzation">
                                                                <input type="checkbox" id={"chkPrimarySpecialisation" + speObj.specialityId} onClick={() => this.primarySpecialisationValidation(speObj.specialityId)}/>
                                                                <div className="tdtxtLabel-hsp">Primary Specialization</div>
                                                            </td>
                                                            {/* <td className ="admindctr-specialzation">
                                                                <input type="checkbox" id={"chkSpecialisation" + speObj.specialityId} value={speObj.specialityId} />
                                                                <input type="hidden" value={speObj.specialityId} />
                                                                <div className="tdtxtLabel-hsp">{speObj.speciality}</div>
                                                            </td>
                                                            <td className ="admindctr-specialzation">
                                                                <input type="checkbox" id={"chkPrimarySpecialisation" + speObj.specialityId} onClick={() => this.primarySpecialisationValidation(speObj.specialityId)}/>
                                                                <div className="tdtxtLabel-hsp">Primary Specialization</div>
                                                            </td> */}
                                                        
                                                        </tr>
                                                    )
                                                })}
                                               
                                            </tbody>

                                        </table>

                                    </div>


                                </div>
                                : ""} 
                                <div className="hsplistTextareaWrapper">
                                    <div className="mt-4">
                                        <label className="label-hspservice">Professional Statement<span className="hspifoMantory">*</span></label>
                                        <div>
                                            <textarea name="txtProfessionalStatement" id="txtProfessionalStatement" cols="30" rows="10" placeholder="Professional Statement" className="textarea-hspservice" onChange={()=>this.setProfessionalStatement()}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
          </section>
                </Suspense>
                <Suspense fallback={<div></div>}>
                </Suspense>
            </div>
        );
    }
}
