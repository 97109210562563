import React, { Component } from 'react';
import $ from "jquery";
import Pagination from "react-js-pagination";
import { apiCalling } from "../apiService";
let groupHospitalAdminId = ''
let status = 1;
let offset = 0;
export default class ZeroUtilization extends Component {
    constructor(props) {
        super(props);    
        this.state = {
            activePage: 1,
            activeIndex: 0,
            status: 1,
            memberPlanData:[],
            memberPlanCountData:'',  
            disabled:true
          };
          this.zeroChange = this.zeroChange.bind(this)
      }
      setActive = index => {
        this.setState({ activeIndex: index });
      };
      zeroChange = () => {
        this.setState({ disabled: $('#seroSelect').val() == 0 ? true : false });
        if($('#errorSelect').val() == 0) {
          $('#errorSearch').val('')
        }
      }
      handlePageChange(pageNumber) {
        this.setState({activePage: pageNumber});
        if(pageNumber === 1) {
          offset = 0
        } else {
          offset =  15*pageNumber
        }
        this.getZeroReport()
      }
      componentDidMount() {
        $("#changePassword").addClass("dashBoardLeftMenu-active");
        groupHospitalAdminId = window.sessionStorage.getItem("userId");
        this.getZeroReport()
      }
      getZeroReport () {
        $(".loader").show();
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
              "functionName": "rhCouponCode",
              "subFunction":"zeroUtilization",
              "txtInput":$('#zeroSearch').val(),
              "cmbSelect":$('#seroSelect').val(),
              "max":"15",
              "offset":offset === 0 ? 0 : offset
            }),
        };
        apiCalling(apiJson).then((data) => {
            if (data.success === "1") {
                $(".loader").hide();
                this.setState({
                  memberPlanData: data.memberPlanData,
                  memberPlanCountData: data.memberPlanCountData
                })
            } else {
                $(".loader").hide();
                this.setState({
                  memberPlanData:[],
                  memberPlanCountData:''
                })
                alert(data.errorMessage)
            }
        });
      }
      refresh = () => {
        $('#seroSelect').val(0)
         $('#zeroSearch').val('')
        this.getZeroReport()
      }
      searchData = () => {
        if ($('#seroSelect').val() == 1 && $('#zeroSearch').val() == "") {
            alert('Enter Policy Number')
        } else if ($('#seroSelect').val() == 2 && $('#zeroSearch').val() == "") {
            alert('Enter Coupon Name')
        } else {
          this.setState({activePage: 1});
          offset = 0
          this.getZeroReport()
        }
      }
 render() {
    return (

<div className='group_coupons'>
<div className='row align-items-center my-4'>
             <div className='col-md-3'>
              <select className="custome_input mb-0" id="seroSelect" onChange={() => this.zeroChange()}>
                  <option value="0">All</option>
                  <option value="1">Policy Number</option>
                  <option value="2">Coupon Name</option>
              </select>
              </div>
              <div className='col-md-3'>
              <input type="text" className="custome_input mb-0 " id="zeroSearch" disabled={this.state.disabled}/>
              </div>
              <div className="col-md-6 coupon_btn d-flex justify-content-start mt-2">
                <input type="submit" className="formBtnBg mr-5" value="Search" onClick={() => this.searchData("Policy")}/>
                <input type="submit" className="formBtnBg mr-1" value="Refresh" onClick={() => this.refresh()}/>
              </div>
            </div>
            <div class="table-responsive mb-3">
                                <table class="table custom_table">
                                    <thead>
                                    <tr>
                                        <th>Policy Number</th>
                                        <th>Employee ID</th>
                                        <th>Coupon Name</th>
                                        <th>Policy Version</th>
                                        <th>Coupons Allotted</th>
                                        
                                    </tr>
                                    </thead>
                                    {this.state.memberPlanData.length > 0 ?
                                      <tbody>
                                        {this.state.memberPlanData.map((item) => (
                                          <tr>
                                            <td>{item.policyNumber}</td>
                                            <td>{item.employeeId}</td>
                                            <td>{item.couponName} ({parseFloat(item.couponVersion).toFixed(1)})</td>
                                            <td>{parseFloat(item.policyVersion).toFixed(1)}</td>
                                            <td>{item.couponsAllotted}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                      :
                                        <tbody>
                                          <tr>
                                            <td colspan={5}>No data Found</td>
                                          </tr>
                                        </tbody>
                                      }
                                    </table>
                                    
            </div>
            <div className="text-right">
                                      <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={15}
                                        totalItemsCount={this.state.memberPlanCountData}
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePageChange.bind(this)}
                                      />
                                   </div>
            </div>
                )
            }
           }