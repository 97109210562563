import React, { Component } from 'react';
import "../css/purpleresponsive.css"
import "../css/style.css"


import cross from "../image/theme/cross.png"
import Header from "../Reliance/header"
import Footer from "../Reliance/footer"
import TrustedBy from "../home/trustedBy"
import ContactForm from "../home/contactus-form"
const telehealth1 = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/theme/home2.png"
const keyfeatures = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/theme/telehealth.jpg"
export default class Telehealth extends Component {
    render() {
        return (
            <div className="purpleWrap bg-clr-wthHead blue-shade">
                <Header></Header>
            <div className="fullabtus">
                <div className="aboutus">
                    <div className="container">
                            <div className="telehealthfeaturehead telehealthfeaturehead-mb">Telehealth Technology</div>
                        <div className="row">
                            <div className="col-lg-6 mb-4">
                                <p className="aboutuspara font-20">Are your patients out of town? Are you working from home? Would you like to connect with your patients whenever convenient, anytime, anywhere? If so, Purple Health has fantastic solutions for you!</p>
                                <br></br>
                                <p className="aboutuspara font-20">Our Telemedicine Solution craetes a virtual clinic and allows you expand your Patient reach using just your smartphone.</p>
                            </div>
                            <div className="col-lg-1"></div>
                            <div className="col-lg-5 image-center">
                                <img src={telehealth1} class="img-fluid img-height350"></img>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-5">
                    <div className="telehealthfeaturehead  telehealthfeaturehead-mb">Key Features</div>
                    <div className="row">
                        <div className="col-lg-6 mb-4 image-center">
                            <img src={keyfeatures} class="img-fluid"></img>
                        </div>
                        <div className="col-lg-6 rightaboutuspara">
                            <ul className="ul-liike-p">
                                <li class="font-20">Connect Using Video-Consult</li>
                                <li class="font-20">Chat consult</li>
                                <li class="font-20">Phone Consult</li>
                                <li class="font-20">Email Consult</li>
                                <li class="font-20">Includes In-Person appointment booking</li>
                                <li class="font-20">Set your own telemedicine rates</li>
                                <li class="font-20">Your own branded mobile app or website for patients</li>
                                <li class="font-20">Android or iOS mobile apps</li>
                                <li class="font-20">Multiple clinic doctors can be listed</li>
                                <li class="font-20">Connects with Practice Management Software</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <div className="container mb-5">
                    <div className="telehealthfeaturehead  telehealthfeaturehead-mb">Pricing</div>
                    <div className="row price-section-list-Main price-section-list-tlhlth">
                        <div className="col-lg-6">
                            <div className="price-section-list">
                                <div className="price-section-list-head">Individual Practitioner</div>
                                <div className="price-section-list-oldrate cross-price">Rs. 20,000/yr</div>
                                <div className="price-section-list-newrate">Rs. 5,000/yr !</div>
                                <div class="price-section-list-MainDiv">
                                    <p class="text-capitalize">Per App Charge</p>
                                    <p class="text-capitalize">Taxes Extra</p>
                                    <p class="text-capitalize">Terms & Conditions Apply</p>
                                    <p class="text-capitalize">Limited Time Special Offer</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="price-section-list">
                                <div className="price-section-list-head">Hospitals & Enterprise</div>
                                <div className="price-section-list-oldrate price-section-list-contact">Contact <br></br> For Pricing</div>
                                
                            </div>
                        </div>
                    </div>
                </div> */}
                <div class="bgbannerImg">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12">
                            <div className="bgbannerImg-text">Don't wait!<br></br> Contact PurpleHealth Today!</div>
                            <div class="contactusbtn mt-5">
                                <a href="#" class="text-decoration-none">
                                <span class="commonButton-whitebg">Get Started</span>
                                </a>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                {/* <TrustedBy></TrustedBy> */}
                {/* <ContactForm></ContactForm> */}
            </div>
            <Footer></Footer>
        </div>

      
  
    )
  }
}
