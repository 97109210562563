import React, { Component, Suspense } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import videoImg from "../image/theme/video1.png";
import { apiCalling, apiUrlRelianceFunction } from "../apiService";
import moment from "moment";
import DateTimePopup from "../doctor/datetime-popup";
import "react-datepicker/dist/react-datepicker.css";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
const userId = window.sessionStorage.getItem("userId");
const groupHospitalAdminId = window.sessionStorage.getItem("userId");
let hospitalId = window.sessionStorage.getItem("hospitalId");
if (window.sessionStorage.getItem("role") === "ROLE_GROUPHOSPITALADMIN") {
  hospitalId = "";
}

export default class VideoAudioAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      toDate: new Date().setMonth(new Date().getMonth() + 1),
      paymentData: [],
      showMessage: "",
      csvDetails: [],
      totalCount: 0,
      activePage: 1,
      offset: "0",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleToDateChange = this.handleToDateChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  handleChange(date) {
    this.setState({
      startDate: date,
    });
  }

  handleToDateChange(date) {
    this.setState({
      toDate: date,
    });
  }

  changeText = () => {
    if ($("#videoAudioSelect").val() !== "0") {
      document.getElementById("txtVideoAudio").disabled = false;
      $("input[name=txtVideoAudio]").focus();
    } else {
      document.getElementById("txtVideoAudio").disabled = true;
    }
    document.getElementById("txtVideoAudio").value = "";
  };

  confirmAppointment(id, dateTime, appTime) {
    let curDateTime = new Date().getTime();
    let aa = moment(appTime);
    let bb = moment(curDateTime);
    if (bb <= aa) {
      $(".loader").show();
      let newDate = new Date(parseInt(dateTime));
      let appDate = moment(newDate).format("DD/MM/YYYY");
      let appTime = moment(newDate).format("h:mm A");
      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "confirmVideoAppointment",
          appointmentDate: appDate,
          appointmentTime: appTime,
          requestVideoAppointmentId: id,
          appointmentDateTime: new Date(
            moment(appDate, "DD/MM/YYYY").format("YYYY-MM-DD") +
              " " +
              moment(appTime, ["h:mm A"]).format("kk:mm:ss")
          ).getTime(),
          ratePerSession: "",
          ratePerSessionAudio: "",
          siteId: window.sessionStorage.getItem("siteId"),
          isConfirmAndDeduct: "",
          isReferralWithOtp: "",
        }),
      };
      // console.log("apiJson", apiJson.body);
      // debugger;
      apiCalling(apiJson).then((data) => {
        if (data.success === "1") {
          $(".loader").hide();
          alert(data.successMessage);
          $("#rowOpenApp" + id).hide();
          this.confirmVideoAppointmentMail(data);
          //window.location.reload()
        } else {
          $(".loader").hide();
          alert(data.errorMessage);
        }
      });
    } else {
      alert("Appointment date & time is invalid please re-schedule");
    }
  }

  async confirmVideoAppointmentMail(data) {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "confirmVideoAppointmentMail",
        siteId: "",
        jsonMailOptions: data.jsonMailOptions,
        jsonAdminDocMailOptions: data.jsonAdminDocMailOptions,
        smsObj: data.smsObj,
        smsDoctorObj: data.smsDoctorObj,
        smsAdminObj: data.smsAdminObj,
      }),
    };
    //console.log(apiJson.body);
    apiCalling(apiJson).then((data) => {});
  }

  rejectOpenAppointment(id) {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "rejectVideoChat",
        requestVideoAppointmentId: id,
      }),
    };
    // console.log(apiJson.body)
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $("#rowOpenApp" + id).hide();
        alert("Rejected Successfully");
      } else {
        alert("Can't reject please try again later");
      }
      $(".loader").hide();
      // window.location.reload();
    });
  }

  searchHandler() {
    //alert($("#videoAudioSelect").val())
    var startDate = $("#fromDate").val();
    var toDate = $("#toDate").val();
    // console.log("startDate"+startDate)
    // console.log("toDate"+toDate)
    var newDate = moment(startDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    // console.log("newDate=========",newDate)
    // var SearchDate = new Date(newDate)
    var milliStartDate = moment(startDate, "DD-MM-YYYY").unix();
    // console.log("newDate"+ moment(startDate, "DD-MM-YYYY").unix())
    // console.log("SearchDate"+SearchDate)
    if (newDate === "Invalid date") {
      // SearchDate = "";
      milliStartDate = "";
    }
    var endDate = moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    // var SearchEndDate = new Date(endDate)
    var milliEndDate = moment(toDate, "DD-MM-YYYY").unix() + 86400000;
    if (endDate === "Invalid date") {
      // SearchEndDate = "";
      milliEndDate = "";
    }

    if (
      $("#videoAudioSelect").val() !== "0" &&
      $("#txtVideoAudio").val() === ""
    ) {
      var t = document.getElementById("videoAudioSelect");
      var selectedText = t.options[t.selectedIndex].text;
      alert(selectedText + " Required");
      $("#txtVideoAudio").focus();
      return false;
    }
    if (milliStartDate > milliEndDate) {
      alert("To date should be greater than or equal to from date");
      return false;
    } else {
      const searchData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "paymentReport",
          subFunctionName: "videoAudioPayment",
          userId: this.props.match.params.groupHospitalId, //"19238",
          max: "10",
          offset: this.state.offset,
          cmbCategory1:
            $("#videoAudioSelect").val() === "0"
              ? ""
              : $("#videoAudioSelect").val(),
          searchKeyWord: $("#txtVideoAudio").val(),
          appointmentStatus:
            $("#appointmentStatusType").val() === "0"
              ? ""
              : $("#appointmentStatusType option:selected").text(),
          appointmentType:
            $("#appointmentType1").val() === "0"
              ? ""
              : $("#appointmentType1 option:selected").text(),
          hospitalId: hospitalId,
          fromDate: new Date(newDate).getTime(), //milliStartDate
          toDate: new Date(endDate).getTime(), //milliEndDate
        }),
      };
      // console.log(searchData.body)
      $(".loader").show();
      // let apiFunction=folderName!== null ? apiCalling : apiUrlRelianceFunction
      apiUrlRelianceFunction(searchData).then((data) => {
        // replace this with apiFunction
        // console.log(data)
        if (data.success === "1") {
          $(".loader").hide();
          this.setState({
            paymentData: data.result.paymentData,
            csvDetails: data.result.paymentExportData,
            totalCount: data.result.count,
            activePage: 1,
          });
        }
        if (data.success === "0") {
          $(".loader").hide();
          this.setState({
            paymentData: [],
            showMessage: data.errorMessage,
            totalCount: 0,
            activePage: 1,
            csvDetails: [],
          });
        }
      });
    }
  }

  videoAudioReport() {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "paymentReport",
        subFunctionName: "videoAudioPayment",
        userId: this.props.match.params.groupHospitalId, // "19238",
        max: "10",
        offset: this.state.offset,
        hospitalId: hospitalId,
      }),
    };
    // console.log(apiJson.body)
    let abcc = folderName != null ? "a1" : "apiUrlRelianceFunction";
    // alert(abcc)
    let apiFunction = folderName !== null ? apiCalling : apiUrlRelianceFunction;
    //console.log(apiFunction)
    apiFunction(apiJson).then((data) => {
      $(".loader").hide();
      //alert(data.success)
      if (data.success === "1") {
        this.setState({
          paymentData: data.result.paymentData,
          csvDetails: data.result.paymentExportData,
          totalCount: data.result.count,
        });
      }
      if (data.success === "0") {
        $(".loader").hide();
        this.setState({
          paymentData: [],
          csvDetails: [],
          showMessage: data.errorMessage,
        });
      }
    });
  }
  dateTimePopup(id, startTime) {
    window.sessionStorage.setItem("confirmId", id);
    window.sessionStorage.setItem("confirmStartTime", startTime);
    $("#dateTime-popup" + id).show();
    $("body").addClass("overflow-hidden");
  }
  onFormSubmit(e) {
    e.preventDefault();
    // console.log(this.state.startDate)
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.videoAudioReport();
  }

  async handlePageChange(pageNumber) {
    await this.setState(
      {
        activePage: pageNumber,
        offset: ((parseInt(pageNumber) - 1) * 10).toString(),
      },
      () => {
        this.videoAudioReport();
      }
    );
  }
  async refresh() {
    await this.setState(
      {
        offset: "0",
        activePage: 1,
      },
      () => {
        this.videoAudioReport();
      }
    );
  }
  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container">
          <div class="main-wrapper1 date_width  dashbrd-rght-bottom-padng-hsp">
            <div class="col-md-12 p-0">
              <ul class="breadcrumb">
                <li>
                  <Link to="/GroupAdmin/dashboard">Dashboard</Link>
                  <i class="fa fa-chevron-right"></i>
                </li>
                <li>Video/Audio Appointment</li>
              </ul>
            </div>

            <div class="row">
              <h3 className="form-head mt-2 col-md-12 mx-3">
                Video/Audio Appointment
              </h3>
              <div class="col-lg-12">
                <div className="dashboard-right dashbrd-rght-bottom-padng-hsp border-0 p-0">
                  <div className="col-sm-12 mt-2">
                    <div className="row align-items-center pt-2 bg_color">
                      <div className="col-md-3 mb-2">
                        <select
                          type="text"
                          id="videoAudioSelect"
                          onChange={() => this.changeText()}
                          className="custome_input mb-0"
                        >
                          <option value="0" selected="selected">
                            Select
                          </option>
                          <option value="1">Patient Name</option>
                          <option value="2">Doctor Name</option>
                          <option value="3">Hospital Name</option>
                        </select>
                      </div>
                      <div className="col-md-3 mb-2">
                        <input
                          type="text"
                          id="txtVideoAudio"
                          className="custome_input mb-0"
                          disabled
                        />
                      </div>
                      <div className="col-md-3 mb-2">
                        <DatePicker
                          selected={this.state.startDate}
                          onChange={this.handleChange}
                          name="startDate"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="From Date"
                          className="mb-0 datepickerIcon-hsp  custome_input "
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown
                          showMonthDropdown
                          id="fromDate"
                        />
                      </div>
                      <div className="col-md-3 mb-2">
                        <DatePicker
                          selected={this.state.toDate}
                          onChange={this.handleToDateChange}
                          name="endDate"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="To Date"
                          className="mb-0 datepickerIcon-hsp  custome_input"
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown
                          showMonthDropdown
                          id="toDate"
                        />
                      </div>
                      <div className="col-md-3 mb-2">
                        <select
                          type="text"
                          id="appointmentType1"
                          className="custome_input mb-0"
                        >
                          <option value="0" selected="selected">
                            Service
                          </option>
                          <option value="Audio">Audio</option>
                          <option value="Video Chat">Video Chat</option>
                        </select>
                      </div>
                      <div className="col-md-3 mb-2">
                        <select
                          type="text"
                          id="appointmentStatusType"
                          className="custome_input mb-0"
                        >
                          <option value="0">Status</option>
                          <option value="1">Open</option>
                          <option value="2">Confirmed</option>
                          <option value="3">Rejected</option>
                          <option value="4">Cancel</option>
                          <option value="5">Completed</option>
                          <option value="7">Pending</option>
                        </select>
                      </div>
                      <div className="col-md-3 mb-2 coupon_btn ml-auto">
                        <input
                          type="submit"
                          className="formBtnBg w-100"
                          value="Refresh"
                          onClick={() => this.refresh()}
                        />
                      </div>
                      <div className="col-md-3 mb-2 coupon_btn ml-auto">
                        <input
                          type="submit"
                          onClick={() => this.searchHandler()}
                          className="formBtnBg w-100"
                          value="Search"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Requested Date</th>
                          <th>Patient Name</th>
                          <th>Doctor Name</th>
                          <th>Hospital Name</th>
                          <th>Service</th>
                          <th>Charge/Session</th>
                          <th>Appointment Date</th>
                          <th>Appointment Time</th>
                          <th>Status</th>
                          <th>Action</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: "12px" }}>
                        {this.state.paymentData.map((data) => {
                          let requestedDate = moment(data.requestedDate).format(
                            "DD-MMM-YYYY"
                          );
                          let appointmentDate = moment(
                            data.requestedDate
                          ).format("DD/MM/YYYY");
                          let appointmentTime = moment(
                            data.requestedDate
                          ).format("hh:mm A");
                          return (
                            <tr>
                              <td>
                                {requestedDate === "01-Jan-1970"
                                  ? ""
                                  : requestedDate}
                              </td>
                              <td>{data.memName}</td>
                              <td>Dr.{data.docName}</td>
                              <td>{data.hospitalName}</td>
                              <td>{data.type}</td>
                              <td>₹ {data.actualAmount}</td>
                              <td>
                                <i
                                  class="fa fa-edit ml-2"
                                  onClick={() =>
                                    this.dateTimePopup(
                                      data.requestVideoId,
                                      data.requestedDate
                                    )
                                  }
                                ></i>
                                <DateTimePopup
                                  id="datetimepopuoer"
                                  appId={data.requestVideoId}
                                  appTime={data.requestedDate}
                                ></DateTimePopup>
                                {appointmentDate}
                              </td>
                              <td>
                                <i
                                  class="fa fa-edit ml-2"
                                  onClick={() =>
                                    this.dateTimePopup(
                                      data.requestVideoId,
                                      data.requestedDate
                                    )
                                  }
                                ></i>
                                {appointmentTime}
                                <DateTimePopup
                                  id="datetimepopuoer"
                                  appId={data.requestVideoId}
                                  appTime={data.requestedDate}
                                ></DateTimePopup>{" "}
                              </td>

                              <td>{data.statusName}</td>
                              <td>
                                {data.statusName === "Open" ? (
                                  <span className="d-flex align-items-center text_decoration_underline">
                                    <button
                                      onClick={() =>
                                        this.confirmAppointment(
                                          data.requestVideoId,
                                          data.requestedDate,
                                          data.requestedDate
                                        )
                                      }
                                      className="formButton formButton-sm m-0 border-0"
                                    >
                                      Confirm
                                    </button>
                                    <button
                                      onClick={() =>
                                        this.rejectOpenAppointment(
                                          data.requestVideoId
                                        )
                                      }
                                      className="formButton formButton-sm m-0 border-0 "
                                    >
                                      Reject
                                    </button>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="text-right mt-4">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.totalCount}
                      pageRangeDisplayed={10}
                      prevPageText={"Previous"}
                      nextPageText={"Next"}
                      onChange={(pageNumber) =>
                        this.handlePageChange(pageNumber)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
