import React, { Component } from 'react';
import doctor from "../image/media/doctor.jpg"
import { apiCalling } from "../apiService";
import $ from "jquery";
import AWS from "aws-sdk";
var s3;
let fullFilePath

export default class DoctorImage extends Component {

    constructor(props){
    super(props);

    this.state = {
    file:null,
    image:"",
    picture:"",
    s3bucketAccessKey: "",
    AWSAccessKeyId: "",
    region:"us-east-1",
    bucket:" ",
    bucketMainFolder:'',
    S3BucketDoctorImages:'',
    }
    this.uploadHandler = this.uploadHandler.bind(this);
    this.fileChange = this.fileChange.bind(this);
    }

    getImageDetails(){
      const getDetails = {
        method: "POST",
        body: JSON.stringify({
          functionName: "getDoctorDetails",
          siteId: window.sessionStorage.getItem("siteId"),
          doctorId: window.sessionStorage.getItem("userId"),
        }),
      }
      apiCalling(getDetails).then((data) => {
        if (data.success === "1") {        
          this.setState({ picture:data.doctorData[0].personalDetails[0].image}); 
        }
      });
    }

    componentDidMount(){
      this.getAwsPath();
      this.getImageDetails();
    }

  getAwsPath() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
        siteId: window.sessionStorage.getItem("siteId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          s3bucketAccessKey: data.configPathDetails[0].s3bucketAccessKey,
          AWSAccessKeyId: data.configPathDetails[0].s3bucketSecretKey,
          bucket: data.configPathDetails[0].PurpleHealthS3bucket,
          bucketMainFolder: data.configPathDetails[0].S3BucketMainFolder,
          S3BucketDoctorImages: data.configPathDetails[0].S3BucketDoctorImages,
          region: data.configPathDetails[0].s3UploadRegion
        });
      }
    });

  }

  uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
  var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
  return v.toString(16);
  });
  }

  fileChange = (e) => {
    let imageFile = e.target.files[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      alert("Add file only with JPG,JPEG,PNG format")
      return false;
    }
    if (imageFile.size > 3145728){
      alert("File size must under 3 MB!");
      return false;
    }
    else {
      this.setState({image:e.target.files[0]})
      //console.log("iiiiiii",e.target.files[0]);
      this.setState({picture:URL.createObjectURL(e.target.files[0])});
      this.fileUpload(imageFile);
    }
  }

  fileUpload(file){
    AWS.config.update({
      accessKeyId: this.state.s3bucketAccessKey,
      secretAccessKey: this.state.AWSAccessKeyId,
      region: "ap-south-1",
    });
    var bucketParams = {
      Bucket: this.state.bucket + "/"+ this.state.bucketMainFolder + "/"+ this.state.S3BucketDoctorImages,
    };
    s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    let S3BucketMainFolder = "";
    let fileName = this.uuidv4() + file.name;
    fullFilePath = S3BucketMainFolder + fileName;
    this.setState({ image: fileName });
    s3.upload(
      {
        Key: fullFilePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        if (err) {
          alert("There was an error uploading your photo: ", err.message);
        } else if (data) {
          //alert("successfully uploaded", data.message);
          return null;
        }
      }
    );
  }

  uploadHandler() {
    let errormessage="";
    if(!this.state.image) {
      errormessage="Please Upload Image"
      $("#attach").focus();
    }
    // console.log(this.state.picture)
    if(errormessage===""){
      this.fileUpload("lets seeeeee",this.state.image)
      const saveFile = {
        method:'POST',
        body: JSON.stringify({
        functionName:"updateDoctorDetails",
        siteId: window.sessionStorage.getItem("siteId"),
        doctorId:window.sessionStorage.getItem("userId"),
        emailId:window.sessionStorage.getItem("emailId"),
        subFunctionName:"updateImageDetails",
        doctorImageId:"",
        imageName:this.state.image
        })
      }
      $(".loader").show();
      apiCalling(saveFile).then((data) => {
        $(".loader").hide();
        if(data.success==="1"){
        alert("Details updated successfully")
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        }
      })
    }
    else{
      $("#messageImageEdit").text(errormessage);
      $("#messageImageEdit").css("color", "red");
    }
  }

  render() {
    return (
      <div class="row px-2">
      <div className='loader'></div>
      <div class="col-sm-12">
              <div class="col-sm-12 my-2">
                  <p id="messageImageEdit" style={{textAlign:'center'}}></p>
                  </div>
            </div>
        <div class="col-6 col-sm-4 p-0 mt-2"> 
          <div class="fileUpload-medical">
            <span>Add Doctor Image</span>
            <input type="button" id="attach" name="attach" class="upload"  />
            <input type="file" class="upload"  onChange={this.fileChange}/>
          </div>
          {/* <div className="thumbImage">
          <img src={this.state.picture} id={"showImg"} />
          </div> */}
        </div>
        <div class="col-4 col-sm-4">
          <img src={this.state.picture} id={"showImg"} width="50"></img>
        </div>
        <div class="col-sm-12  p-0">
          <p class="note">Select a recent photograph of yourself in jpg/png/gif format to upload.</p>
        </div>
        <div className="col-sm-12 p-0">
              <input type="submit" value="Upload"  id="upload" onClick={()=>this.uploadHandler()} className="formButton"></input>
          </div>
      </div>
    )
  }
}
