import React, { Component, Suspense } from "react";
import DashboardleftmenuAdmin from "../GroupAdmin/dashboardleftmenu";
import Dashboardleftmenu from "./dashboardleftmenu";
import { apiCalling } from "../apiService";
import $ from "jquery";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));


export default class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drCurrentPassword: "",
      drNewPassword: "",
      drConfirmPassword: "",
      drMessages: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitPassword = this.submitPassword.bind(this);
    this.cancelButton=this.cancelButton.bind(this);
  }
  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };
  cancelButton(){
    window.location="/doctor/dashboard"
  }
  submitPassword() {
    let drCurrentPassword = this.state.drCurrentPassword;
    let drNewPassword = this.state.drNewPassword;
    let drConfirmPassword = this.state.drConfirmPassword;
    let flags = false;
    let msg = "";
    if (!drCurrentPassword) {
      msg = "Please enter Current Password";
      $("#drCurrentPassword").focus();
    } else if (!drNewPassword) {
      msg = "Please enter your new password";
      $("#drNewPassword").focus();
    } else if (!drConfirmPassword) {
      msg = "Please confirm your password";
      $("#drConfirmPassword").focus();
    } else if (drNewPassword !== drConfirmPassword) {
      msg = "New password and confirm password are not matched ";
      $("#drConfirmPassword").focus();
    }
    else if (drCurrentPassword === drConfirmPassword) {
      msg = "Current Password & New Password are same  ";
      $("#drNewPassword").focus();
    }
    else {
      flags = true;
    }
    if (flags === false) {
      $("#drmessagebloc").css("color", "red");
      this.setState({ drMessages: msg });
    } else {
      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "changePassword",
          userId: window.sessionStorage.getItem("userId"),
          siteId: window.sessionStorage.getItem("siteId"),
          bundleId: "",
          currentPassword:drCurrentPassword,
          newPassword: drConfirmPassword,
        }),
      };
      apiCalling(apiJson).then((data) => {
        if (data.success === "1") {
          this.setState({ drMessages: "Password Changes Successfully" });
          window.location.reload();
        }
        
        else if(data.success === "0"){
        
          this.setState({ drMessages: "Current password is wrong" });
          $("#drCurrentPassword").focus();
          $("input[type='password']").val('');
        }
        
      });
    }
  }
  componentDidMount() {
    $("#drCurrentPassword").focus();
    $("#changePassword").addClass("dashBoardLeftMenu-active");
  }
  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container">
          <div class="main-wrapper1">
            <div class="row">
              <div class="col-lg-3">
              {window.sessionStorage.getItem("role") === "ROLE_DOCTOR" &&(
                <Dashboardleftmenu></Dashboardleftmenu>
              )}
              {(window.sessionStorage.getItem("role") === "ROLE_GROUPHOSPITALADMIN"||window.sessionStorage.getItem("role") === "ROLE_HOSPITALADMIN") &&(
                <DashboardleftmenuAdmin></DashboardleftmenuAdmin>
              )}
              </div>
              <div class="col-lg-9">
              <div class="tabmain_list"><h6 class="mb-0">Change Password</h6></div>
                <div class="dashboard-right dashbrd-rght-bottom-padng-hsp">
                  <p class="text-muted px-3 imp_header py-4">
                    * Provide your old password & your new password
                  </p>
                  <p class="error-msg px-3" id="drmessagebloc">
                    {this.state.drMessages}
                  </p>
                  <div class="row row-margin px-3">
                    <div class="col-md-6">
                    <label class="col-md-12 label-text  px-0">Current Password<span className="star-red">*</span></label>
                      <input
                        type="password"
                        name="drCurrentPassword"
                        id="drCurrentPassword"
                        class="custome_input mb-0"
                        placeholder="Type your Current Password"
                        onChange={this.handleInputChange}
                        defaultValue=""
                      />
                    </div>
                  </div>
                  <div class="row row-margin px-3">
                    <div class="col-md-6">
                    <label class="col-md-12 label-text  px-0">New Password<span className="star-red">*</span></label>
                      <input
                        type="password"
                        class="custome_input mb-0"
                        name="drNewPassword"
                        id="drNewPassword"
                        placeholder="New Password"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div class="row row-margin px-3">
                    <div class="col-md-6">
                    <label class="col-md-12 label-text  px-0">Confirm Password<span className="star-red">*</span></label>
                      <input
                        type="password"
                        class="custome_input mb-0"
                        placeholder="Confirm Password"
                        name="drConfirmPassword"
                        id="drConfirmPassword"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="row px-4">
                    <a href="#">
                      <input
                        type="submit"
                        value="Change Password"
                        className="border_btn mr-3 mb-2"
                        onClick={() => this.submitPassword()}
                      ></input>
                    </a>
                    <input
                      type="button"
                      value="Cancel"
                      className="border_btn btn_gray mb-2"
                      onClick={()=>this.cancelButton()}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
