import React, { Component } from "react";
import AddHeaderLinkPopup from "./addHeaderLinkPopup";
import $ from "jquery";
import { apiCalling } from "../../apiService";
import Pagination from "react-js-pagination";
import Edit from "../../image/icons/edit.png";
import Cancel from "../../image/icons/cancel.png";
import Check from "../../image/icons/check.png";

export default class addHeaderLink extends Component {
  state = {
    siteId: this.props.siteId,
    groupHospitalAdminId: this.props.groupHospitalAdminId,
    showAddHeaderLinkPopup: false,
    headerDeatils: null,
    headerLinksData: null,
  };

  headerEditclick = (headerDeatils) => {
    this.setState({ showAddHeaderLinkPopup: true, headerDeatils: headerDeatils });
  };

  componentDidMount() {
    this.getHeaderLinksList();
  }

  getHeaderLinksList() {
    $(".loader").show();
    const headerLinksList = {
      method: "POST",
      body: JSON.stringify({
        functionName: "headerLinksList",
        groupHospitalAdminId: this.state.groupHospitalAdminId,
        siteId: this.props.siteId,
      }),
    };

    apiCalling(headerLinksList).then((data) => {
      if (data.success === "1") {
        var headerLinksData = data.headerLinksData;
        this.setState({ headerLinksData });
      }
      $(".loader").hide();
    });
  }

  handleStatusChange(headerLinksId, isActive) {
    $(".loader").show();
    const activateHeaderLink = {
      method: "POST",
      body: JSON.stringify({
        functionName: "activateHeaderLink",
        headerLinksId: headerLinksId,
        isActive: !isActive,
      }),
    };

    apiCalling(activateHeaderLink).then((data) => {
      this.getHeaderLinksList();
      if (data.success === "0") {
        alert(data.errorMessage);
      }
    });
  }

  handleSaveHeaderLink =(d)=> {
    $(".loader").show();
    const addOrEditHeaderLinks = {
      method: "POST",
      body: JSON.stringify({
        functionName: "addOrEditHeaderLinks",
        groupHospitalAdminId: this.state.groupHospitalAdminId,
        siteId: this.state.siteId,
        headerLinksId: d.headerLinksId,
        url: d.url,
        content: d.content,
        name: d.name,
      }),
    };

    apiCalling(addOrEditHeaderLinks).then((data) => {
      this.setState({ showAddHeaderLinkPopup: false });
      
      if (data.success === "0") {
        alert(data.errorMessage);
      }
      this.getHeaderLinksList();
      //$(".loader").hide();
    });
  }

  renderHeaderLinkData() {
    if (this.state.headerLinksData != null) {
      return this.state.headerLinksData.map((data) => (
        <tr key={data.headerLinkId}>
          <td>{data.name}</td>
          <td>{data.url}</td>
          <td>{data.active ? "Active" : "In-active"}</td>

          <td style={{ width: "10vw" }} className="nowrap">
            <div>
              <span>
                <a className="mr-2" onClick={() => this.headerEditclick(data)}>
                  <img
                    src={Edit}
                    alt="Edit"
                    width="18"
                    className="img-fluid"
                    title="Edit"
                  />
                </a>
              </span>
              <span>
                <a
                  onClick={() =>
                    this.handleStatusChange(data.headerLinkId, data.active)
                  }
                  className="mr-2"
                >
                  <img
                    src={data.active ? Cancel : Check}
                    alt="Add Header Links"
                    title={
                      data.active ? "Click to deactivate" : "Click to activate"
                    }
                    width="18"
                    className="img-fluid"
                  />
                </a>
              </span>
            </div>
          </td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan="6">No Data Found</td>
        </tr>
      );
    }
  }

  render() {
    return (
      <div className="HosptlMangemntTable">
        <div className="AddNewHspbtnDiv">
          <a className="addnewhspbtn" onClick={() => this.headerEditclick("")}>
            Add New Header
          </a>
        </div>
      <div className="table-responsive">
        <table className="table table-bordered-hsp">
          <thead>
            <tr>
              <th className="tablehead-hspmng">Name</th>
              <th className="tablehead-hspmng">URL</th>
              <th className="tablehead-hspmng">Status</th>
              <th className="tablehead-hspmng">Actions</th>
            </tr>
          </thead>
          <tbody>{this.renderHeaderLinkData()}</tbody>
        </table>
        </div>
        {this.state.totalCount > 5 ? (
          <div className="paginationSection orderm-paginationSection">
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={10}
              totalItemsCount={this.state.totalCount}
              pageRangeDisplayed={10}
              onChange={this.handlePageChange}
            />
          </div>
        ) : (
          ""
        )}

        {this.state.showAddHeaderLinkPopup == true && (
          <AddHeaderLinkPopup
            showAddHeaderLinkPopup={(data) =>
              this.setState({ showAddHeaderLinkPopup: data })
            }
            headerDeatils={this.state.headerDeatils}
            saveHeaderLink={this.handleSaveHeaderLink}
          />
        )}
      </div>
    );
  }
}
