import React, { useState, useRef, useCallback, useEffect } from "react";
import DataInput from "./dataInput";
import DragDropFile from "./dragDropFile";
import $ from "jquery";
import * as XLSX from "xlsx";

const navigationFileUpload = [
  { pageName: "Dashboard", url: "/admin/dashboard" },
  {
    pageName: "Data Migration",
    url: "/admin/dataMigration",
  },
  {
    pageName: "File Upload",
    url: "#",
    current: true,
  },
];

export default class DataMigrationFileUpload extends React.Component {
  state = {
    subDataList: this.props.state.subDataList,
    fileName: this.props.state.fileName,
    submitted: false,
  };

  handleFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      /* Parse data */
      const ab = e.target.result;
      const wb = XLSX.read(ab, { type: "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { raw: false, header: 1 });
      let DataList = [];
      let keys = [];
      keys = data[0];
      let record = {};
      for (let i = 1; i < data.length; i++) {
        let dd = data[i];
        if (dd.length > 0) {
          for (let j = 0; j < dd.length; j++) {
            record[keys[j].trim()] =
              dd[j] == undefined ? "" : dd[j].toString().trim();
          }
          record["status"] = true;
          record["error"] = "";
          record["createdBy"] = 1;

          DataList.push(record);
          record = {};
        }
      }
      // // debugger;
      /* Update state */
      this.setState({ subDataList: DataList, fileName: file.name });
    };
    reader.readAsArrayBuffer(file);
  };

  handleInputChange = (i, e) => {
    let TargetValue = e.currentTarget.value;
    let TargetId = e.currentTarget.name;
    let tempSubDataList = this.state.subDataList;
    tempSubDataList[i][TargetId] = TargetValue;
    this.setState({ subDataList: tempSubDataList });
  };

  onSubmitted = (e) => {
    this.setState({ submitted: true });
    this.props.saveHandle(this.state.subDataList, this.state.fileName);
  };

  onProcessData = (e) => {
    if (this.props.state.hospitalId > 0) {
      this.setState({ submitted: true });
      this.props.processData(this.state.subDataList);
    } else {
      alert("Select Hospithal");
      $("#hospitalId").focus();
    }
  };

  OutTable = () => {
    return (
      <div className="row">
        {this.props.state.patientMigrationMainId > 0 &&
          this.props.state.status == 0 && (
            <div className="container mb-3">
              <div class="row col-lg-6">
                <label class="col-sm-3 label-text">Hospital</label>
                <div class="col-sm-9">
                  <select
                    className="slecthsp"
                    id="hospitalId"
                    value={this.props.state.hospitalId}
                    onInput={this.props.changeHandler}
                  >
                    <option value="">Select Hospital</option>
                    {this.props.state.hospitalData.map((h) => (
                      <option key={h.hospitalId} value={h.hospitalId}>
                        {h.hospitalName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}
        <div className="table-responsive">
          <div
            style={{
              maxHeight: "400px",
              overflow: "scroll",
            }}
          >
            <table
              className="table table-striped"
              style={{
                width: "fit-content",
              }}
            >
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Gender</th>
                  <th>Email Id</th>
                  <th>Date Of Birth</th>
                  <th>Blood Group</th>
                  <th>Address</th>
                  <th>City</th>
                  <th>Phone</th>
                  <th>State</th>
                  <th>Country</th>
                  <th>Zip</th>
                  <th>Aadhar No</th>
                  <th>Mobile Number</th>
                </tr>
              </thead>

              <tbody>
                {this.state.subDataList.map((r, i) => (
                  <tr key={i}>
                    <td>
                      <input
                        type="text"
                        id={i + "firstName"}
                        value={r.firstName}
                        name="firstName"
                        onChange={(e) => this.handleInputChange(i, e)}
                        disabled={
                          this.props.state.patientMigrationMainId > 0
                            ? "disabled"
                            : ""
                        }
                        placeholder="First Name"
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        id={i + "lastName"}
                        value={r.lastName}
                        name="lastName"
                        onChange={(e) => this.handleInputChange(i, e)}
                        disabled={
                          this.props.state.patientMigrationMainId > 0
                            ? "disabled"
                            : ""
                        }
                        placeholder="Last Name"
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        id={i + "gender"}
                        value={r.gender}
                        name="gender"
                        onChange={(e) => this.handleInputChange(i, e)}
                        disabled={
                          this.props.state.patientMigrationMainId > 0
                            ? "disabled"
                            : ""
                        }
                        placeholder="Gender"
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        id={i + "emailId"}
                        value={r.emailId}
                        name="emailId"
                        onChange={(e) => this.handleInputChange(i, e)}
                        disabled={
                          this.props.state.patientMigrationMainId > 0
                            ? "disabled"
                            : ""
                        }
                        placeholder="e-Mail Id"
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        id={i + "dateOfBirth"}
                        value={r.dateOfBirth}
                        name="dateOfBirth"
                        onChange={(e) => this.handleInputChange(i, e)}
                        disabled={
                          this.props.state.patientMigrationMainId > 0
                            ? "disabled"
                            : ""
                        }
                        placeholder="Date Of Birth"
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        id={i + "bloodGroup"}
                        value={r.bloodGroup}
                        name="bloodGroup"
                        onChange={(e) => this.handleInputChange(i, e)}
                        disabled={
                          this.props.state.patientMigrationMainId > 0
                            ? "disabled"
                            : ""
                        }
                        placeholder="Blood Group"
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        id={i + "address"}
                        value={r.address}
                        name="address"
                        onChange={(e) => this.handleInputChange(i, e)}
                        disabled={
                          this.props.state.patientMigrationMainId > 0
                            ? "disabled"
                            : ""
                        }
                        placeholder="Address"
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        id={i + "city"}
                        value={r.city}
                        name="city"
                        onChange={(e) => this.handleInputChange(i, e)}
                        disabled={
                          this.props.state.patientMigrationMainId > 0
                            ? "disabled"
                            : ""
                        }
                        placeholder="City"
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        id={i + "phone"}
                        value={r.phone}
                        name="phone"
                        onChange={(e) => this.handleInputChange(i, e)}
                        disabled={
                          this.props.state.patientMigrationMainId > 0
                            ? "disabled"
                            : ""
                        }
                        placeholder="Phone"
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        id={i + "state"}
                        value={r.state}
                        name="state"
                        onChange={(e) => this.handleInputChange(i, e)}
                        disabled={
                          this.props.state.patientMigrationMainId > 0
                            ? "disabled"
                            : ""
                        }
                        placeholder="State"
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        id={i + "country"}
                        value={r.country}
                        name="country"
                        onChange={(e) => this.handleInputChange(i, e)}
                        disabled={
                          this.props.state.patientMigrationMainId > 0
                            ? "disabled"
                            : ""
                        }
                        placeholder="Country"
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        id={i + "zip"}
                        value={r.zip}
                        name="zip"
                        onChange={(e) => this.handleInputChange(i, e)}
                        disabled={
                          this.props.state.patientMigrationMainId > 0
                            ? "disabled"
                            : ""
                        }
                        placeholder="Zip"
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        id={i + "aadharNo"}
                        value={r.aadharNo}
                        name="aadharNo"
                        onChange={(e) => this.handleInputChange(i, e)}
                        disabled={
                          this.props.state.patientMigrationMainId > 0
                            ? "disabled"
                            : ""
                        }
                        placeholder="Aadhar No"
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        id={i + "mobileNumber"}
                        value={r.mobileNumber}
                        name="mobileNumber"
                        onChange={(e) => this.handleInputChange(i, e)}
                        disabled={
                          this.props.state.patientMigrationMainId > 0
                            ? "disabled"
                            : ""
                        }
                        placeholder="Mobile Number"
                      ></input>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <DragDropFile handleFile={this.handleFile}>
        <div className="row sh-rw">
          {this.state.subDataList.length === 0 ? (
            <div className="tableHeader-HspMangemnt">
              <div className="col-md-12">
                <DataInput handleFile={this.handleFile} />
              </div>
            </div>
          ) : (
            <div className="col-md-12">{this.OutTable()}</div>
          )}
        </div>

        {this.state.subDataList.length > 0 ? (
          <div className=" sh-sve">
            {this.props.state.patientMigrationMainId == 0 && (
              <button
                className="divBtn"
                onClick={(e) => {
                  this.onSubmitted(e);
                }}
                disabled={this.state.submitted == true ? "disabled" : ""}
              >
                {this.state.submitted == true ? "Saving.." : "Save"}
              </button>
            )}
            {this.props.state.patientMigrationMainId > 0 &&
              this.props.state.status == 0 && (
                <button
                  className="divBtn"
                  onClick={(e) => {
                    this.onProcessData(e);
                  }}
                  disabled={this.state.submitted == true ? "disabled" : ""}
                >
                  {this.state.submitted == true
                    ? "Processing.."
                    : "Process Migration"}
                </button>
              )}
            <span
              className="divBtn ph-cancel"
              onClick={() => {
                this.setState({ subDataList: [] });
                this.props.setFileName("");
                this.props.setHospital(0);
                this.props.changeMode(
                  "listView",
                  navigationFileUpload,
                  0,
                  "Patient Data Migration"
                );
              }}
            >
              Cancel
            </span>
          </div>
        ) : (
          ""
        )}
      </DragDropFile>
    );
  }
}
