import React, { Component, Suspense } from "react";
import $ from "jquery";

export default class SuperAdmin extends Component {
  componentDidMount() {
    if (this.props.hospitalData1.length > 0) {
      $("#hasSuperAdmin").val(
        this.props.hospitalData1[0].hasSuperAdmin === true ? "true" : "false"
      );
      $("#superAdminDisplayName").val(
        this.props.hospitalData1[0].superAdminDisplayName
      );
      $("#superAdminUserName").val(
        this.props.hospitalData1[0].superAdminUserName
      );
      $("#superAdminPassword").val(
        this.props.hospitalData1[0].superAdminPassword
      );
    }
  }
  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead mb-4">
        <Suspense fallback={<div></div>}>
          <section>
            <div className="superadmin-wrapper">
              <div>
                <h2 className="hspInfoSubhead pb-3">Super Admin Details</h2>

                <div className="superadminDivBox hspinfo-adminselct-mob">
                  <label>has SuperAdmin</label>

                  <select name="hasSuperAdmin" id="hasSuperAdmin">
                    <option value="false">No</option>

                    <option value="true">Yes</option>
                  </select>
                </div>
              </div>

              <div className="superadminDivBoxcover">
                <div className="superadminDivBox">
                  <label>Display Name</label>

                  <input
                    type="text"
                    id="superAdminDisplayName"
                    name="superAdminDisplayName"
                    className="hspInfofieldinput"
                  />
                </div>
              </div>

              <div className="superadminDivBoxcover">
                <div className="hspinfo-contentdiv">
                  <label>User Name</label>

                  <input
                    type="text"
                    id="superAdminUserName"
                    name="superAdminUserName"
                    className="hspInfofieldinput"
                  />
                </div>

                <div className="hspinfo-contentdiv">
                  <label>Password</label>

                  <input
                    type="text"
                    id="superAdminPassword"
                    name="superAdminPassword"
                    className="hspInfofieldinput"
                  />
                </div>
              </div>
            </div>
          </section>
        </Suspense>

        <Suspense fallback={<div></div>}></Suspense>
      </div>
    );
  }
}
