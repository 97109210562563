import React, { Component, Suspense } from "react";
import Header from "../../Reliance/header";
import Footer from "../../Reliance/footer";
import $ from "jquery";
import { apiCalling } from "../../apiService";

export default class BankAccountdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      razorpayBuisinessTypeListData: [],
    };
  }
  componentDidMount() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHospitalDropdown",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState(
          {
            razorpayBuisinessTypeListData:
              data.hospitalData[0].razorpayBuisinessTypeList,
          },
          () => {
            if (this.props.hospitalData1.length > 0) {
              $("#bankAccountName").val(
                this.props.hospitalData1[0].bankAccountName
              );
              $("#bankAccountEmail").val(
                this.props.hospitalData1[0].bankAccountEmail
              );
              $("#businessName").val(
                this.props.hospitalData1[0].accountBusinessName
              );
              $("#linkedAccountId").val(
                this.props.hospitalData1[0].linkedAccountId
              );
              $("#branchIFSCCode").val(
                this.props.hospitalData1[0].branchIfscCode
              );
              $("#bankAccountNo").val(
                this.props.hospitalData1[0].bankAccountNumber
              );
              $("#beneficiaryName").val(
                this.props.hospitalData1[0].beneficiaryName
              );
              $("#businessTypeId").val(
                this.props.hospitalData1[0].beneficiaryTypeId
              );
            }
          }
        );
      }
    });
  }

  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead mb-4">
        <Suspense fallback={<div></div>}>
          <section>
            <div className="bankAccount-wrapper">
              <h2 className="hspInfoSubhead">Bank Account Details</h2>

              <div className="hspinfo-contentdiv">
                <label>Account Name</label>

                <input
                  type="text"
                  id="bankAccountName"
                  name="bankAccountName"
                  className="hspInfofieldinput"
                />
              </div>

              <div className="hspinfo-contentdiv">
                <label>Account Email</label>

                <input
                  type="text"
                  id="bankAccountEmail"
                  name="bankAccountEmail"
                  className="hspInfofieldinput"
                />
              </div>

              <div className="hspinfo-contentdiv">
                <label>Business Name</label>

                <input
                  type="text"
                  id="businessName"
                  name="businessName"
                  className="hspInfofieldinput"
                />
              </div>

              <div className="hspinfo-contentdiv">
                <label>Linked Account Id</label>

                <input
                  type="text"
                  id="linkedAccountId"
                  name="linkedAccountId"
                  className="hspInfofieldinput"
                />
              </div>

              <div className="hspinfo-contentdiv">
                <label>Branch IFSC Code</label>

                <input
                  type="text"
                  id="branchIFSCCode"
                  name="branchIFSCCode"
                  className="hspInfofieldinput"
                />
              </div>

              <div className="hspinfo-contentdiv">
                <label>Account No.</label>

                <input
                  type="text"
                  id="bankAccountNo"
                  name="bankAccountNo"
                  className="hspInfofieldinput"
                />
              </div>

              <div className="hspinfo-contentdiv">
                <label>Benefitiary Name</label>

                <input
                  type="text"
                  id="beneficiaryName"
                  name="beneficiaryName"
                  className="hspInfofieldinput"
                />
              </div>

              <div className="hspinfo-contentdiv">
                <label>Bussiness Type</label>

                <select
                  name="businessTypeId"
                  id="businessTypeId"
                  className="hspInfofieldinput"
                >
                  <option value="0">Select Bussiness Type</option>
                  {this.state.razorpayBuisinessTypeListData.map(
                    (razorpayBuisinessTypeListDataObj, i) => {
                      return (
                        <option
                          key={
                            razorpayBuisinessTypeListDataObj.razorpayBuisinessTypeId
                          }
                          value={
                            razorpayBuisinessTypeListDataObj.razorpayBuisinessTypeId
                          }
                        >
                          {
                            razorpayBuisinessTypeListDataObj.razorpayBuisinessTypeName
                          }
                        </option>
                      );
                    }
                  )}
                </select>
              </div>
            </div>
          </section>
        </Suspense>

        <Suspense fallback={<div></div>}></Suspense>
      </div>
    );
  }
}
