import React, { Component, Suspense } from "react";
import { Link } from "react-router-dom";
import Dashboardleftmenu from "./dashboardleftmenu";
import { apiCalling } from "../apiService";
import $ from 'jquery';

const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class AdminProfile extends Component {
    constructor(props){
        super(props);
        
        this.state = {    
            emailId:'',
            mobileNumber:'',
            nameTxt:''
          }
        this.handleChange = this.handleChange.bind(this)
        this.numberHandleChange = this.numberHandleChange.bind(this)
        this.submitHandle = this.submitHandle.bind(this)
        this.cancelButton = this.cancelButton.bind(this)
    }
    componentDidMount(){
        this.getProfileDetails();
    }
    getProfileDetails(){
        // console.log('inside profile details')
        const postData = {
        method: "POST",
        body: JSON.stringify({
            functionName: "rhprofile",
            subFunction:"getProfileDetails",
            id: window.sessionStorage.getItem("userId"),
            }),
        }
        apiCalling(postData).then((data) => {
            if (data.success === "1") { 
                if(data.data.length!==0){
                    this.setState({ 
                        emailId:  data.data[0].secUserEmailId,
                        mobileNumber: data.data[0].secUserMobileNumber,
                        nameTxt: data.data[0].secUserDisplayName,
                      }); 
                }       
              
            }
          });
    }
    handleChange = (event) =>{
        const{value, name} = event.target;
        this.setState({
          [name]:value
        })
    }
    numberHandleChange(event) {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({ mobileNumber:event.target.value })
        }
    }
    cancelButton(){
        window.location.reload()
    }
    submitHandle(){
        let emailId = this.state.emailId;
        let mobileNumber = this.state.mobileNumber;
        let nameTxt = this.state.nameTxt;
        let errormessage="";
        let errorMessageDataExists="";
        let errormessageEmail="";
        let errormessageMobile="";
        let errormessageName="";
        if(!emailId){
          errormessage="flag"
          errormessageEmail="\xa0\xa0\xa0\xa0\xa0\xa0 Please Enter Email ID"
          $("#emailId").focus();
        }
        if(!emailId.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
          errormessage="flag"
          errormessageEmail="\xa0\xa0\xa0\xa0\xa0\xa0 Please Valid Email ID"
          $("#emailId").focus();
        }
        if(!mobileNumber){
          errormessage="flag"
          errormessageMobile="\xa0\xa0\xa0\xa0\xa0\xa0 Please Enter the Mobile Number"
          $("#mobileNumber").focus();
        }
        if(!mobileNumber.match( /^[0-9.\b]{10}$/)){
          errormessage="flag"
          errormessageMobile="\xa0\xa0\xa0\xa0\xa0\xa0 Please Enter Valid Mobile Number"
          $("#mobileNumber").focus();
        }
        if (!nameTxt){
          errormessage="flag"
          errormessageName="\xa0\xa0\xa0\xa0\xa0\xa0 Please Enter Name"
          $("#nameTxt").focus();
        }
       if(errormessage==""){
        const saveField ={
            method:'POST',
            body: JSON.stringify({
                functionName:"rhprofile",
                subFunction:"profileDataExists",
                id: window.sessionStorage.getItem("userId"),
                emailId:emailId,
                mobileNumber:mobileNumber,
                displayName:nameTxt,
            })
        }
        apiCalling(saveField).then((data) => {
            if(data.success==="1"){
                errorMessageDataExists="Email/ Mobile Number Already Exists"
                $("#dataAlreadyExists").text(errorMessageDataExists);
                $("#dataAlreadyExists").css("color", "red");
            }
            else{
                const saveField ={
                    method:'POST',
                    body: JSON.stringify({
                        functionName:"rhprofile",
                        subFunction:"profileEditDetails",
                        id: window.sessionStorage.getItem("userId"),
                        emailId:emailId,
                        mobileNumber:mobileNumber,
                        displayName:nameTxt,
                    })
                }
                $(".loader").show();
                apiCalling(saveField).then((data) => {
                    $(".loader").hide();
                    if(data.success==="1"){
                        
                        errormessage="";
                        errorMessageDataExists="";
                        errormessageEmail="";
                        errormessageMobile="";
                        errormessageName="";
                        $("#dataAlreadyExists").text(errorMessageDataExists);
                        $("#messageMemberEditName").text(errormessageName);
                        $("#messageMemberEditEmailId").text(errormessageEmail);
                        $("#messageMemberEditMobileNumber").text(errormessageMobile);
                        alert("Data Updated Successfully");
                    }
                    else{
                        alert(data.errormessage)
                    }
                })  
            }
        })
    } 
        else{
            $("#messageMemberEditName").text(errormessageName);
            $("#messageMemberEditName").css("color", "red");
            $("#messageMemberEditEmailId").text(errormessageEmail);
            $("#messageMemberEditEmailId").css("color", "red");
            $("#messageMemberEditMobileNumber").text(errormessageMobile);
            $("#messageMemberEditMobileNumber").css("color", "red");
        }
    }
    
    render() {
        return (
            <div class="purpleWrap">
                <Suspense fallback={<div></div>}>
                    <section>
                        <Header />
                    </section>
                </Suspense>
                <div class="container">
                    <div class="main-wrapper1 hspmain-wrapper-promo" id="row-col-width-leftmenu">
                        <div class="row">
                            <div class="col-lg-1 d-sm-none d-lg-block d-md-none d-xl-block d-none">
                                <Dashboardleftmenu></Dashboardleftmenu>
                            </div>
                            <div class="col-lg-11">
                                <div className="dashboard-right dashbrd-rght-bottom-padng-hsp">
                                    <div class="tabmain_list">
                                        <h6 className="mb-0">PROFILE</h6>
                                    </div>

                                        <div className="col-sm-12 pt-3 pb-5 prflefrm-border">
                                            <div className="col-lg-8 pt-2">
                                            <div class="col-sm-12 my-2">
                                                <p id="dataAlreadyExists"></p>
                                            </div>
                                            <div>
                                                <label className="label-text-prfhsp">Name * </label>
                                                <label id="messageMemberEditName"></label>
                                                <input id="nameTxt" type="text" className="custome_input" name="nameTxt" value={this.state.nameTxt} onChange={this.handleChange}/>
                                            </div>
                                            <div>
                                                <label className="label-text-prfhsp">Email Id *</label>
                                                <label id="messageMemberEditEmailId"></label>
                                                <input id="emailId" type="text" className="custome_input" name="emailId" value={this.state.emailId} onChange={this.handleChange}/>
                                            </div>
                                            <div>
                                                <label className="label-text-prfhsp">Mobile Number *</label>
                                                <label id="messageMemberEditMobileNumber"></label>
                                                <input id="mobileNumber" type="text" className="custome_input" name="mobileNumber" value={this.state.mobileNumber} onChange={this.numberHandleChange}/>
                                            </div>
                                           <div className="row pt-3">
                                                <button className="border_btn" onClick={this.submitHandle}>Update</button>
                                                <button className="border_btn btn_gray" onClick={this.cancelButton}>Cancel</button>
                                           </div>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Suspense fallback={<div></div>}>
                    <section>
                        <Footer />
                    </section>
                </Suspense>
            </div>
        );
    }
}
