import React, { Component, Suspense } from "react";
import $ from "jquery";
import { apiCalling } from "../../apiService";
import Edit from "../../image/icons/edit.png";
import tick from "../../image/icons/tick.png";
import Deactivate from "../../image/icons/deactvate.png";
import { Link } from "react-router-dom";
// import InnerHTML from "dangerously-set-html-content";
import Header from "../../Reliance/header";
import Footer from "../../Reliance/footer";
export default class Journalisting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      journalData: [],
      showMessage: "Waiting for data... ",
    };
  }

  componentDidMount() {
    this.journalListing();
  }

  journalListing() {
    const dataList = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getJournalsListing",
        siteId: "",
        journalId: "",
      }),
    };
    $(".loader").show();
    // console.log("listi", dataList.body);
    apiCalling(dataList).then((data) => {
      // console.log(data.result);
      $(".loader").hide();
      if (data.success === "1" && data.result.length > 0) {
        this.setState({
          journalData: data.result,
        });
      } else if (data.success === "0") {
        this.setState({ showMessage: "No Data Found " });
      }
    });
  }

  addNewJournal() {
    window.location.href = "/adminManagement/journalDashboard/addJournal";
  }

  statusChangeHandler(id, Status) {
    var x = false;
    if (Status === true) {
      x = window.confirm("Do you want to Disable this?");
    } else {
      x = window.confirm("Do you want to Enable this?");
    }
    if (x === true) {
      const SatusData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "journalStatusChange",
          journalId: id,
          isActive: Status === true ? 0 : 1,
        }),
      };
      $(".loader").show();
      apiCalling(SatusData).then((data) => {
        if (data.success === "1") {
          $(".loader").hide();
          alert("Status Changed Successfully");
          this.journalListing();
        }
        if (data.success === "0") {
          $(".loader").hide();
          alert(data.errorMessage);
        }
      });
    }
  }

  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div>Waiting...</div>}>
          <section>
            <Header /> <div className="loader"></div>
          </section>
        </Suspense>
        <div className="container mb-5 mt-4" id="feedList">
          <h3 className="text-left journlHead"> IDA Journal Listing </h3>
          <div>
            <ul className="breadcrum-adminMangmnt">
              <li>
                <a href={"/admin/dashboard"}>IDA Journal</a>
              </li>
              <li>
                <a href="/#" className="current">
                  IDA Journal Listing
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-12 pl-0">
            <button
              className="addNew-btn-journel addNew-btn-journel-border"
              onClick={() => this.addNewJournal()}
            >
              Add New Journal
            </button>
          </div>
          {this.state.journalData.length > 0 ? (
            <div class="table-responsive table-bordered-hsp">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th className="col-sm-1">Journal ID</th>
                    <th className="col-sm-5">Journal Title</th>
                    <th className="col-sm-5">Journal Description</th>
                    <th className="col-sm-1">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.journalData.map((data) => (
                    <tr key={data.journalId}>
                      <td>{data.journalId}</td>
                      <td
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          width: "10vw",
                        }}
                      >
                        {data.journalTitle}
                      </td>
                      <td className="journl-des-txt">
                        {/* <InnerHTML 
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          width: "10vw",
                        }}                         
                          html={data.description}
                        /> */}
                        <div
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            width: "10vw",
                          }}
                          dangerouslySetInnerHTML={{ __html: data.description }}
                        ></div>
                      </td>
                      <td>
                        <div className="row">
                          <div className="col-sm-6">
                            <form autocomplete="off">
                              {data.isActive === true ? (
                                <img
                                  src={Deactivate}
                                  width="22"
                                  title="Click to Deactivate "
                                  className="mr-2 conf-cursorpointer"
                                  alt=""
                                  onClick={() => {
                                    this.statusChangeHandler(
                                      data.journalId,
                                      true
                                    );
                                  }}
                                ></img>
                              ) : (
                                <img
                                  src={tick}
                                  alt=""
                                  title="Click to Activate"
                                  className="mr-2 conf-cursorpointer"
                                  width="20"
                                  onClick={() => {
                                    this.statusChangeHandler(
                                      data.journalId,
                                      false
                                    );
                                  }}
                                ></img>
                              )}
                            </form>
                          </div>
                          <div className="col-sm-6">
                            <Link
                              className="whitespace-nowrap"
                              to={
                                "/adminManagement/journalDashboard/addJournal/" +
                                data.journalId
                              }
                            >
                              {" "}
                              <img
                                src={Edit}
                                className="img-fluid"
                                width="20px"
                              />
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="error-msg text-center mb-0">
              {" "}
              {this.state.showMessage}{" "}
            </p>
          )}
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
