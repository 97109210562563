import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.css"
import "../css/style.css"
import "../css/purpleresponsive.css"
import "../css/font-awesome.min.css"
import {Link} from "react-router-dom";
import Header from "../Reliance/header"
import Footer from "../Reliance/footer"
import ContactForm from "../home/contactus-form"

export default class contactus extends Component {
  render() {
    return (
        
        <div className="purpleWrap">
            <Header></Header>
            <ContactForm></ContactForm>
            <Footer></Footer>
        </div>
        
       
    )
  }
}
