import React, { Component, Suspense } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import user from "../image/theme/user.png";
import $ from "jquery";
import {
  apiCalling,
  patientAPI,
  validateEmail,
  phonenumberValidation,
} from "../apiService";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class ProfileEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      mobileNo: "",
      emailId: "",
      PolicyNumber: "",
      employId: "",
      // city: "",
      // state: "",
      dateofBirth: "",
      // country: "",
       gender: "",
      // phoneExtension: "",
      // zip: "",
      // countryData: [],
      // stateData: [],
      // cityData: [],
      // selectedTeam: "",
      // validationError: "",
      // dob: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleNumberChange = this.handleNumberChange.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.handleInputChangeCountry = this.handleInputChangeCountry.bind(this);
    const getUserData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "userDetails",
        siteId: "",
        userId: window.sessionStorage.getItem("userId"),
      }),
    };
    $(".loader").show();
    apiCalling(getUserData).then((data) => {
      if (data.success === "1") {
        let dob = moment(data.userData[0].dob, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        );
        this.setState({ dateofBirth: dob === "01/01/1990" ? "" : dob });
        this.setState({ firstName: data.userData[0].firstName });
        this.setState({ lastName: data.userData[0].lastName });
        this.setState({ mobileNo: data.userData[0].phoneNumber.trim() });
        this.setState({ emailId: data.userData[0].emailId.trim() });
        this.setState({ PolicyNumber: data.userData[0].policyNo });
        this.setState({ employId: data.userData[0].employeeId });
         this.setState({ gender: data.userData[0].gender });
        this.setState({ address: data.userData[0].address });
        this.setState({ city: data.userData[0].city });
        this.setState({ state: data.userData[0].state });
        this.setState({ country: data.userData[0].country });
        this.setState({ phoneExtension: data.userData[0].phoneExtension });
        this.setState({ zip: data.userData[0].zip });
      }
      $(".loader").hide();
    });

    const getCountryData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "locationDetails",
        siteId: window.sessionStorage.getItem("siteId"),
      }),
    };
    apiCalling(getCountryData).then((data) => {
      if (data.success === "1") {
        this.setState({ countryData: data.result.countryData });
        if (this.state.state) {
          this.setState({ stateData: data.result.stateData });
          this.setState({ cityData: data.result.cityData });
        }
      }
    });
  }

  handleNumberChange = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      const { value, name } = event.target;
      this.setState({
        [name]: value,
      });
      // console.log(value);
    }
  };

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
    //console.log("111111111", value);
  };
  handleInputChangeCountry = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };
  /* setTimeout(() => {
    $("#loginMenu").hide();
    $("#signInWrap").hide();
    $("#loginErrorMsg").text("");
  }, 2000);*/

  updateProfile = (e) => {
    e.preventDefault();
    // let lastName = this.state.lastName;
    let emailId = this.state.emailId;
    let mobileNo = this.state.mobileNo;
    // let gender = this.state.gender;
    // let address = this.state.address;

    // let newdob = moment($("#dateofBirth").val(), "YYYY-MM-DD").format(
    //   "DD/MM/YYYY"
    // );
    // let dateofBirth = newdob;
    // if($("#dateofBirth").val()===""){
    //   alert("Please enter date of birth properly")
    // }
    if (!validateEmail(emailId)) {
      alert("Please Enter Valid Email Id");
      $("#emailIdId").focus();
    } else if (!phonenumberValidation(mobileNo)) {
      alert("Please Enter Valid Mobile Number");
      $("#mobileNoId").focus();
    }
      //  else if (!gender) {
      //    alert("Please select gender");
      //    $("#gender").focus();
      //   } 
    //     else if (!lastName) {
    //       alert("Please Enter Last Name");
        
    //  }
      //    else if (!dateofBirth) {
      //    alert("Please select your date of birth properly");
      //    $("#dateofBirth").focus();
      //  }
      // else if (!address) {
    //     alert("Please enter address field");
    //     $("#address").focus();
    //   } 
    
    else {
      const saveData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "updateUserDetails",
          siteId: "1",
          userId: window.sessionStorage.getItem("userId"),
          emailId: this.state.emailId,
          mobileNumber: this.state.mobileNo,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          address: this.state.address,
          city: this.state.city,
          state: this.state.state,
          country: this.state.country,
          gender: this.state.gender, 
          phoneExtension: this.state.phoneExtension,
          zip: this.state.zip,
          userDOB: this.state.dateofBirth,
        }),
      };
      $(".loader").show();
      // console.log("11111", saveData.body);
      apiCalling(saveData).then((data) => {
        $(".loader").hide();
        if (data.success == "1") {
          $("#messageMemberEdit").text("Updated Successfully");
          $("#messageMemberEdit").css("color", "green");
          alert("Profile Details Updated Successfully");
          window.location.href = "/patient/profile";
        }
        else { alert(data.errorMessage) }
      });
    }

    setTimeout(() => {
      $("#messageMemberEdit").text("");
    }, 4000);
  };
  componentDidMount() {
    $("body").removeClass("body-ovrflw-hdn-menu");
    let self = this;
    $("#countrySelect").change(function () {
      getStateFunction();
    });

    function getStateFunction() {
      const getState = {
        method: "POST",
        body: JSON.stringify({
          functionName: "locationDetails",
          siteId: window.sessionStorage.getItem("siteId"),
          countryId: $("#countrySelect").val(),
        }),
      };
      apiCalling(getState).then((data) => {
        if (data.success === "1") {
          self.setState({
            stateData: data.result.stateData,
          });
          self.setState({
            cityData: [],
          });
        }
      });
    }

    $("#state").change(function () {
      const getCity = {
        method: "POST",
        body: JSON.stringify({
          functionName: "locationDetails",
          siteId: window.sessionStorage.getItem("siteId"),
          countryId: $("#countrySelect").val(),
          stateId: $("#state").val(),
        }),
      };

      apiCalling(getCity).then((data) => {
        if (data.success === "1") {
          self.setState({
            cityData: data.result.cityData,
          });
        }
      });
    });
  }

  cancelProfile() {

    window.location.href = "/patient/profile";

  }

  render() {
    // const { stateData } = this.state;
    // const { cityData } = this.state;
    // $("#state").val(this.state.state);
    // $("#city").val(this.state.city);
    // let stateList =
    //   stateData.length > 0 &&
    //   stateData.map((item) => {
    //     return (
    //       <option key={item.stateId} value={item.stateId}>
    //         {item.stateName}
    //       </option>
    //     );
    //   }, this);

    // let cityList =
    //   cityData.length > 0 &&
    //   cityData.map((item) => {
    //     return (
    //       <option key={item.cityId} value={item.cityId}>
    //         {item.cityName}
    //       </option>
    //     );
    //   }, this);

    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container container-auto">
          <div class="main-wrapper rounded-0 mt-2">
            <div className="profile_page pt-0">
              <div class="form-head memberHead rounded-0">
                {/* <img src={user} alt=""></img> */}
                Member Profile
              </div>
              <div class="form-section mt-2 form_section_start">
                <div className="col-md-10">
                  <div class="profileHeadTitle mb-0">Member Details</div>
                  <h2>
                    <p id="messageMemberEdit"></p>
                  </h2>

                  <div class="row">
                    <div class="col-sm-6 row-margin">
                      <div className="row">
                        <label class="col-md-5 label-text">
                          First Name
                        </label>
                        <div class="col-md-7">
                          <input
                            type="text"
                            class="input-design"
                            name="firstName"
                            id="firstName"
                            disabled
                            value={this.state.firstName}
                            onChange={this.handleInputChange}
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 row-margin">
                      <div className="row">
                        <label class="col-md-5 label-text">
                          Last Name <span className="star-red"></span>
                        </label>
                        <div class="col-md-7">
                          <input
                            type="text"
                            class="input-design"
                            name="lastName"
                            readOnly
                            id="lastName"
                            value={this.state.lastName.toLocaleUpperCase()}
                            onChange={this.handleInputChange}
                          ></input>
                        </div>
                      </div>
                    </div>
                    {this.state.employId !== "" &&
                      <div class="col-sm-6 row-margin">
                        <div className="row">
                          <label class="col-md-5 label-text">
                            Employee Id <span className="star-red"></span>
                          </label>
                          <div class="col-md-7">
                            <input
                              value={this.state.employId}
                              type="text"
                              class="input-design"
                              name="lastName"
                              readOnly
                            ></input>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  <div class="profileHeadTitle mt-0">Account Details</div>
                  <div className="row">
                    <div class="col-sm-6 row-margin">
                      <div className="row">
                        <label class="col-md-5 label-text">
                          Policy Number{" "}
                        </label>
                        <div class="col-md-7">
                          <input
                            type="Policy"
                            class="input-design"
                            value={this.state.PolicyNumber}
                            id="Policy"
                            name="Policy"
                            readOnly
                            onChange={this.handleInputChange}
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 row-margin">
                      <div className="row">
                        <label class="col-md-5 label-text">
                          Email ID<span className="star-red ">*</span>{" "}
                        </label>
                        <div class="col-md-7">
                          <input
                            type="email"
                            class="input-design"
                            value={this.state.emailId}
                            id="emailId"
                            //autoFocus="on"
                            name="emailId"
                            //readOnly
                            onChange={this.handleInputChange}
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div class="row-margin col-sm-6 px-0">
                  <label class="col-sm-12 label-text" >
                    Date Of Birth <span className="star-red"></span>
                  </label>
                  
                  <div class="col-sm-12" >
                    <input
                      type="date"
                      name="dateofBirth"
                      id="dateofBirth"
                      class="input-design"
                      readOnly
                      format="DD/MM/YYYY"
                      onChange={this.handleInputChange}
                      value={this.state.dateofBirth}
                    />
                  </div>
                </div>
                    <div class="col-sm-6 row-margin">
                      <div className="row">
                        <label class="col-md-5 label-text">
                          Mobile Number<span className="star-red ">*</span>
                        </label>
                        <div class="col-md-7">
                          <input
                            type="text"
                            onChange={this.handleNumberChange}
                            class="input-design"
                            value={this.state.mobileNo}
                            id="mobileNoId"
                            name="mobileNo"
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div class="col-sm-6 row-margin">
                  <label class="col-12 label-text">Phone Extension </label>
                  <div class="col-12">
                    <input
                      type="text"
                      class="input-design"
                      value={
                        this.state.phoneExtension === "null"
                          ? ""
                          : this.state.phoneExtension
                      }
                      id="phoneExtension"
                      name="phoneExtension"
                      onChange={this.handleNumberChange}
                    ></input>
                  </div>
                </div> */}
                <div className="row">
                  <div class="col-sm-6 row-margin px-0">
                  <label class="col-12 label-text" >
                    Gender <span className="star-red"></span>
                  </label>
                  <div class="col-md-7">
                          <input
                            type="text"
                            // onChange={this.handleNumberChange}
                            class="input-design"
                            value={this.state.gender}
                            disabled
                            id="gender"
                            name="gender"
                          ></input>
                        </div>
                  {/* <div class="col-12">
                    <select
                      type="text"
                      class="input-design"
                      value={this.state.gender}
                      id="gender"
                      disabled
                      name="gender"
                      // onChange={this.handleInputChange}
                    >
                      <option value={this.state.gender}>{this.state.gender}</option>
                      {/* <option value="Male">Male</option>
                      <option value="Female">Female</option> */}
                    {/* </select>
                  </div> */} 
                </div>
                </div>
                  {/* <div class="col-sm-6 row-margin">
                  <label class="col-12 label-text">
                    Address <span className="star-red"></span>
                  </label>
                  <div class="col-12">
                    <textarea
                      type="text"
                      class="input-design"
                      id="address"
                      name="address"
                      value={this.state.address}
                      onChange={this.handleInputChange}
                    >
                      {this.state.address}
                    </textarea>
                  </div>
                </div> */}
                  {/* <div class="col-sm-6 row-margin">
                  <label class="col-12 label-text">Country </label>
                  <div class="col-12"> */}
                  {/* <select
                      class="input-design"
                      onChange={this.handleInputChange}
                      id="countrySelect"
                      name="country"
                      value={this.state.country}
                    >
                      <option value="">- - Select Country - -</option>
                      {this.state.countryData.map((obj) => {
                        return (
                          <option value={obj.countryId}>
                            {obj.countryName}
                          </option>
                        );
                      })}
                    </select> */}
                  {/* <input
                      type="text"
                      class="input-design"
                      value={
                        this.state.country === "null" ? "" : this.state.country
                      }
                      onChange={this.handleInputChange}
                      id="countrySelect"
                      name="country"
                    ></input>
                  </div> */}
                  {/* </div> */}
                  {/* <div class="col-sm-6 row-margin">
                  <label class="col-12 label-text">State</label>
                  <div class="col-12"> */}
                  {/* <select
                      class="input-design"
                      onChange={this.handleInputChange}
                      id="state"
                      name="state"
                      value={this.state.state}
                    >
                      <option value="">- -Select state - -</option>
                      {stateList}
                    </select> */}
                  {/* <input
                      type="text"
                      class="input-design"
                      id="state"
                      name="state"
                      value={ 
                        this.state.state === "null" ? "" : this.state.state
                      }
                      onChange={this.handleInputChange}
                    ></input>
                  </div>
                </div> */}
                  {/* <div class="col-sm-6 row-margin">
                  <label class="col-12 label-text">City </label>
                  <div class="col-12"> */}
                  {/* <select
                      class="input-design"
                      onChange={this.handleInputChange}
                      id="city"
                      name="city"
                      value={this.state.city}
                    >
                      <option value="">- - Select city - -</option>
                      {cityList}
                    </select> */}
                  {/* <input
                      type="text"
                      class="input-design"
                      onChange={this.handleInputChange}
                      id="city"
                      name="city"
                      value={this.state.city === "null" ? "" : this.state.city}
                    ></input>
                  </div>
                </div> */}
                  {/* <div class="col-sm-6 row-margin">
                  <label class="col-12 label-text">Pin Code </label>
                  <div class="col-12">
                    <input
                      type="text"
                      class="input-design"
                      value={this.state.zip === "null" ? "" : this.state.zip}
                      id="zip"
                      name="zip"
                      onChange={this.handleNumberChange}
                    ></input>
                  </div>
                </div> */}
                  <div className="col-sm-12 px-0 text-left">
                    <input
                      type="submit"
                      value="Update Profile"
                      className="border_btn border_edit"
                      onClick={this.updateProfile}
                    ></input>
                    <input
                      type="button"
                      value="Cancel"
                      className="border_btn btn_gray"
                      onClick={() => this.cancelProfile()}
                    ></input>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
