import React, { Component, Suspense } from "react";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class OrderMedicine extends Component {
  render() {
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container">
              <div className="simple_step">
              <div class="simple_stepheader">Order Medicines in 3 simple steps </div>
              <div className="simple_stepcontainer">
              <div class="step_list">
                      <div class="step_count">1</div>
                      <div class="step_content">Click on the “Order Now” button below</div>
                  </div>
                  <div class="step_list">
                      <span class="step_count">2</span>
                      <div class="step_content">Select the Medicines you want to purchase</div>
                  </div>
                  <div class="step_list">
                      <span class="step_count">3</span>
                      <div class="step_content">Use code <strong>RGICL</strong> at the time of payment to avail special discounts</div>
                  </div>
                  <div class="step_button">
                     <a target="_blank" href="https://www.medlife.com/order" class="">ORDER NOW</a>
                  </div>
              </div>
              </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
