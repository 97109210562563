import React, { Component, Suspense } from "react";
import { apiCalling } from "../apiService";
import $ from "jquery";
import { Editor } from "react-draft-wysiwyg";
import bolgtemp from "../image/icons/4.jpg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw,ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import AWS from "aws-sdk";
const folderName = window.sessionStorage.getItem("homeName");
var s3;
let fullFilePath
var imageFile
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class Healthblog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      blogTitleTxt: '',
      searchTag: [],
      listTag: [],
      categoryList: [],
      shortDescription: '',
      image: "",
      healthInfoCategoryId: '',
      journalCategoryId:'',
      videoRadio: '',
      sponsoredType: '',
      featuredType: '',
      picture: '',
      s3bucketAccessKey: "",
      AWSAccessKeyId: "",
      region: "us-east-1",  
      bucket: " ",
      hospitalId: '',
      s3BucketAddressPath:'',
      S3BucketBlogImages:"",
      bucketFolder:'',
      journalStatus:'1',
      articleStatus:'0',
      ownSiteOnly:"1",

     // editorState: EditorState.createEmpty(),
      editorState: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML('<p></p>')
        )
      ),
    }
    this.handleChange = this.handleChange.bind(this);
    this.fileChange = this.fileChange.bind(this);
    //this.onKeyUp = this.onKeyUp.bind(this);
  }

  componentDidMount() {
    $("#updateDetails").hide();
    this.categoryDetails();
    this.getDoctorDeatils();
    this.imageUploadHandler();

    if(this.props.match.params.healthInfoId){
      this.getBlogDetails();
      // this.getJournalDetails();
      $("#updateDetails").show();
      $("#saveType").hide();
      }
      // if(window.sessionStorage.getItem('AddJournal')){
      //   this.categoryJournal()
      // }
  }

  getBlogDetails() {

    const blogData = {
      method: 'POST',
      body: JSON.stringify({
        "functionName": "getDoctorHealthArticles",
        "siteId": window.sessionStorage.getItem("siteId"),
        "healthInfoId": this.props.match.params.healthInfoId
      })
    }
    $(".loader").show();
    apiCalling(blogData).then((data) => {
      $(".loader").hide();
      if (data.success == "1") {
        this.setState({
         
          blogTitleTxt: data.result[0].healthInfoTitle,
          description: data.result[0].description,
          picture: data.result[0].imageName,
          shortDescription: data.result[0].shortDescription,
          healthInfoCategoryId: data.result[0].healthInfoCategory,
          editorState: EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(data.result[0].description)
            )
          )
        });
        // $("#articleType").val(this.state.articleStatus)
      }
    });
  }

  
  // getJournalDetails() {

  //   const blogData = {
  //     method: 'POST',
  //     body: JSON.stringify({
  //       functionName: "getJournals",
  //       siteId:window.sessionStorage.getItem("siteId"),
  //       categoryId:"",
  //       journalId:this.props.match.params.healthInfoId
  //     })
  //   }
  //   $(".loader").show();
  //   apiCalling(blogData).then((data) => {
  //     $(".loader").hide();
  //     if (data.success == "1") {
  //       this.setState({
         
  //         blogTitleTxt: data.result[0].journalTitle,
  //         description: data.result[0].description,
  //         picture: data.result[0].imageName,
  //         shortDescription: data.result[0].shortDescription,
  //         journalCategoryId: data.result[0].journalCategoryId,
  //         editorState: EditorState.createWithContent(
  //           ContentState.createFromBlockArray(
  //             convertFromHTML(data.result[0].description)
  //           )
  //         )
  //       });
  //       $("#articleType").val(this.state.journalStatus)
  //     }
  //   });
  // }

  categoryDetails() {
    const saveField = {
      method: 'POST',
      body: JSON.stringify({
        "functionName": "healthInfoMasterData"
      })
    }
    apiCalling(saveField).then((data) => {
      if (data.success == "1") {
        this.setState({
          categoryList: data.healthInfoCategory
        })
      }
    })
  }

  // categoryJournal() {
  //   const saveField = {
  //     method: 'POST',
  //     body: JSON.stringify({
  //        functionName: "getJournalsCategory",
  //         siteId:window.sessionStorage.getItem("siteId")
  //     })
  //   }
  //   apiCalling(saveField).then((data) => {
  //     if (data.success == "1") {
  //       this.setState({
  //         categoryList: data.result
  //       })
  //     }
  //   })
  // }


  getDoctorDeatils() {
    const getDetails = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDoctorDetails",
        siteId: window.sessionStorage.getItem("siteId"),
        doctorId: window.sessionStorage.getItem("userId"),
      }),
    }
    apiCalling(getDetails).then((data) => {
      if (data.success === "1") {
        this.setState({ hospitalId: data.doctorData[0].hospitalDetails[0].hospitalId });
      }
    });

  }

  handleChange(event) {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    })
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  imageUploadHandler() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
        siteId: window.sessionStorage.getItem("siteId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          s3bucketAccessKey: data.configPathDetails[0].s3bucketAccessKey,
        });
        this.setState({
          AWSAccessKeyId: data.configPathDetails[0].s3bucketSecretKey,
        });
        this.setState({
          bucket: data.configPathDetails[0].PurpleHealthS3bucket,
        });
        this.setState({
          bucketFolder: data.configPathDetails[0].S3BucketMainFolder,
      });
      this.setState({
        s3BucketAddressPath: data.configPathDetails[0].S3BucketMainFolder,
    });
    this.setState({
      S3BucketBlogImages: data.configPathDetails[0].S3BucketBlogImages,
  });

      }
    });
  }

  uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  fileChange = (e) => {
     imageFile = e.target.files[0];

     

if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      alert("Add file format with only JPG,JPEG,PNG format")
     
      return false;
      
    }
    if (imageFile.size > 	5242880)
    
    {
        alert("File size must under 5 MB!");
        return false;
    }
     else {
    
      this.setState({ picture: URL.createObjectURL(e.target.files[0])});
      this.fileUpload(imageFile)
     }
  }

  fileUpload(file) {
    $(".loader").show();
    AWS.config.update({
      accessKeyId: this.state.s3bucketAccessKey,
      secretAccessKey: this.state.AWSAccessKeyId,
      region: this.state.region,
    });
    var bucketParams = { 
      Bucket: this.state.bucket + "/"+ this.state.bucketFolder + "/"+this.state.S3BucketBlogImages,
    };

    s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    let S3BucketMainFolder = ""
    let fileName = this.uuidv4() + file.name; 
    fullFilePath = S3BucketMainFolder + fileName;
    imageFile = fileName 
    s3.upload(
      {
        Key:  fullFilePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        $(".loader").hide();
        if (err) {
          alert("There was an error uploading your photo: ", err.message);
        } else if (data) {
          return null;
        }
      }
    );
  }

  saveHandle() {

    let blogTitle = this.state.blogTitleTxt;
    let searchTag = this.state.searchTag;
    let shortDescription = this.state.shortDescription;
    let errormessage = "";
    if (!blogTitle) {
      errormessage = "Please Enter Add Title"
      alert(errormessage)
      $("#blogTitleTxt").focus();
    }
    else if ($("#categoryType").val() === '') {
      errormessage = "Please Select Category "
      alert(errormessage)
      $("#categoryType").focus();
    }
    // else if($("#articleType").val() === '') {
    //   errormessage = "Please Select Type "
    //   $("#articleType").focus();
    // }
    else if (!searchTag) {
      errormessage = "Please Add Search Tags"
      alert(errormessage)
      $("#searchTag").focus();
    } else if (!shortDescription) {
      errormessage = "Please Enter Summary of your article"
      alert(errormessage)
      $("#shortDescription").focus();
    } 
    else {
    }
    if (errormessage === "") {
      
      const saveField = {
        method: 'POST',
        body: JSON.stringify({
          "functionName": "saveHealthInfo",
          "healthInfoId": this.props.match.params.healthInfoId === 'null' ? '' : this.props.match.params.healthInfoId,
          "hospitalId": this.state.hospitalId,
          "userId": window.sessionStorage.getItem("userId"),
          "title": this.state.blogTitleTxt.replace(/'/g, "''"),
          "categoryId": $("#categoryType").val(),
          "siteId": window.sessionStorage.getItem("siteId"),
          "isVideo":'0' ,    //$('input[name="typeBox"]:checked').val() === 'Video' ? '1' : '0',
          "isFeatured":'0', //$('input[name="typeBox"]:checked').val() === 'Featured' ? '1' : '0',
          "isSponsored":'0',  //$('input[name="typeBox"]:checked').val() === 'Sponsored' ? '1' : '0',
          "tags": "",
          "shortDescription": this.state.shortDescription.replace(/'/g, "''"),
          "description": draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())).replace(/'/g, "''"),
          "imageName": fullFilePath,
          "isJournal": "0", //$("#articleType").val()!= '' ? $("#articleType").val() : "0",
          "isOwnSiteOnly":this.state.ownSiteOnly
        })
      }
      $(".loader").show();
      // console.log("saveField",saveField.body)
      // debugger;
      apiCalling(saveField).then((data) => {
       $(".loader").hide();
        if (data.success === "1" && this.props.match.params.healthInfoId) {
          alert("Updated successfully")
          setTimeout(() => {
            // if(window.sessionStorage.getItem("AddJournal")){
            //   window.location.href = "/journalListing";
            // }else{
              window.location.href = "/BlogList"
            // }
          }, 3000);
        }
        else if (data.success === "1")  {
          alert("Saved successfully")
          setTimeout(() => {
            // if(window.sessionStorage.getItem("AddJournal")){
            //   window.location.href = "/journalListing";
            // }else{
              window.location.href = "/BlogList"
            // }
          }, 3000);
        }if(data.success === "0"){
          alert(data.errorMessage)
        }
      })
    }
    else {
      $("#messageHelathBlogEdit").text(errormessage);
      $("#messageHelathBlogEdit").css("color", "red");
    }
  }

  cancelHandle() {
    window.location.href = "/BlogList";
    // if(window.sessionStorage.getItem("AddJournal")){
    //   window.location.href = "/journalListing";
    // }
    // window.sessionStorage.setItem('AddJournal','');
  }

  srcTypeImage(ev){
    ev.target.src =bolgtemp
  }

  render() {

    const { editorState } = this.state;
    return (
      <div class="purpleWrap">
        <Suspense fallback={<div class="loader"></div>}>
          <section>
            <Header />{" "}
              <div className='loader'></div>
          </section>
        </Suspense>
        <div class="container">
          <div class="main-wrapper-smwidth1">
            <div class="col-md-12 p-0">
              <ul class="breadcrumb">
                <li>
                  <a href="/doctor/dashboard">Dashboard</a>
                  <i class="fa fa-chevron-right"></i>
                </li>
                {/* {window.sessionStorage.getItem("AddJournal")==='' ? ( */}
                <li>
                  <a href="/BlogList">Blog</a>
                  <i class="fa fa-chevron-right"></i>
                </li>
                  {/* ) : ''} */}
                    {/* {window.sessionStorage.getItem("AddJournal") &&
                <li>
                  <a href="/journalListing">journal</a>
                  <i class="fa fa-chevron-right"></i>
                </li>
                   } */}
                <li>Health Article</li>
              </ul>
            </div>
            <div class="col-sm-12 form-head mb-0">Health Article</div>
            <div class="form-section-brdr">
              <h2>
                <p id="messageHelathBlogEdit"></p>
              </h2>
              <div class="col-sm-12 form-subhead">
                Write Your Article or Blog
              </div>
              <p class="col-sm-12 text-muted">
                Write using our text editor below. It's simple and easy to do.
                (<span class="star-red">*</span>) Denotes a mandatory field.
              </p>

              <div className="row">
                <div class="row-margin col-lg-9">
                  <label class="col-sm-12 label-text">
                    Add Title <span class="star-red">*</span>
                  </label>
                  <div class="col-sm-12">
                    <input
                      type="text"
                      class="input-design py-2"
                      placeholder="Blog Title"
                      id="blogTitleTxt"
                      name="blogTitleTxt"
                      value={this.state.blogTitleTxt}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                
                <div class="row-margin col-lg-3">
                  <label class="col-sm-12 label-text">
                  Own Site Only  <span class="star-red">*</span>
                  </label>
                  <div class="col-sm-12">
                    <select class="input-design py-2"
                      id=" ownSiteOnly"
                      name="ownSiteOnly"
                      onChange={this.handleChange}
                    >
                     <option value="1">True</option>
                      <option value="0">False</option>
                    </select>
                  </div>
                </div>
                <div class="row-margin col-lg-6">
                  <label class="col-sm-12 label-text">
                    Category <span class="star-red">*</span>
                  </label>
                  <div class="col-sm-12">
                 {/* {window.sessionStorage.getItem("AddJournal") ? (
                  <select class="input-design"
                      id="categoryType"
                      name="categoryType"
                      onChange={this.handleChange}
                    >
                      <option value="">Status</option>
                      {this.state.categoryList.map((obj) => (
                          obj.categoryId === this.state.journalCategoryId ?
                          <option value={obj.categoryId} selected>{obj.categoryName}</option>
                          : 
                        <option value={obj.categoryId}>{obj.categoryName}</option>
                      ))}
                    </select>
                    ) :  */}
                    <select class="input-design py-2"
                      id="categoryType"
                      name="categoryType"
                      onChange={this.handleChange}
                    >
                      <option value="">Status</option>
                      {this.state.categoryList.map((obj) => (
                          obj.categoryName == this.state.healthInfoCategoryId ?
                          <option value={obj.categoryId} selected>{this.state.healthInfoCategoryId}</option>
                          : 
                        <option value={obj.categoryId}>{obj.categoryName}</option>
                      ))}
                    </select>
                     {/* } */}
                  </div>
                </div>
                {/* <div class="row-margin col-lg-3">
                  <label class="col-sm-12 label-text">
                    Type <span class="star-red">*</span>
                  </label>
                  <div class="col-sm-12">
                    <select class="input-design"
                      id="articleType"
                      name="articleType"
                      onChange={this.handleChange}
                    >
                      <option value="">Select Type</option>
                      <option value="0">Health Article</option>
                      <option value="1">Journal</option>
                    </select>
                  </div>
                </div> */}
                <div class="row-margin col-sm-12">
                  <label class="col-sm-12 label-text">
                    Summary of your article <span class="star-red">*</span>{" "}
                    <small>(Max 500 characters)</small>
                  </label>
                  <div class="col-sm-12">
                    <textarea
                      class="input-design p-3"
                      rows="5"
                      placeholder="Add a Short Description"
                      id="shortDescription"
                      name="shortDescription"
                      value={this.state.shortDescription}
                      onChange={this.handleChange}
                      maxLength={500}
                    ></textarea>
                  </div>
                </div>

                <div class="row-margin col-sm-12">
                  <label class="col-sm-12 label-text">Detailed Description </label>
                  <div class="col-sm-12">
                    <Editor
                    editorState={this.state.editorState}
                      initialEditorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="redactor-wrapper"
                      editorClassName="editorClassName"
                      onEditorStateChange={this.onEditorStateChange}
                      handlePastedText={() => false}
                     // defaultEditorState={"fffsfsf"}
                     //defaultContentState={"dsdsdsd"}
                   
                    />
                  </div>
                </div>
                <div class="col-sm-12 mt-2 px-5 d-flex align-items-center flex-wrap">
                  <div class="fileUpload-medical mr-3">
                    <span>Add Blog Image</span>
                    <input type="button" id="attach" name="attach" class="upload" />
                    <input type="file" class="upload" name="fileText" id="fileText" onChange={this.fileChange} />
                  </div>
                  <div class="fileUpload-img">
                    <img onError={this.srcTypeImage}  src={this.state.picture} id={"showImg"} width="50"></img>
                  </div>
                  <p class="note ml-auto">
                  Format: JPEG,JPG,PNG. <span className="text-danger">Image Size: 500*245. File Size: 5MB maximum.</span>
                  </p>
                </div>

                <div className="col-sm-12 text-right">
                  <input
                    type="submit"
                    value="Save"
                    id="saveType"
                    className="formButton formButtonBold"
                    onClick={() => this.saveHandle()}
                  ></input>
                    <input
                    type="submit"
                    value="Update"
                    id="updateDetails"
                    className="formButton formButtonBold"
                    onClick={() => this.saveHandle()}
                  ></input>
                  <input
                    type="button"
                    value="Cancel"
                    className="formButtonBold cancelButton"
                    onClick={() => this.cancelHandle()}
                  ></input>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
