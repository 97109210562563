import React, { Component, Suspense } from "react";
import DatePicker from "react-datepicker";
import { apiCalling } from "../../apiService";
import $ from "jquery";
import AWS from "aws-sdk";
import Edit from "../../image/icons/edit.png";
import Deactivate from "../../image/icons/deactvate.png";
const folderName = window.sessionStorage.getItem("homeName");
var s3;
var fileNameUploadSignature;
//var fileNameUploadProfile;
// const Header = React.lazy(() => import("../" + folderName + "/header"));
// const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class Doctorinformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      languageData: [],
      timeFilterData: [],
      dateOfBirth: "",
      fileSignature: null,
      s3bucketAccessKey: "",
      s3bucketSecretKey: "",
      s3BucketMainFolder: "",
      s3BucketAddressPath: "",
      s3BucketDoctorImages: "",
      s3Bucket: "",
    };
    this.handleChangeDate = this.handleChangeDate.bind(this);
  }
  componentDidMount() {
    $('#cmbTitle option[value="Dr."]').attr("selected", true);
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHospitalDropdown",
      }),
    };
    this.BindProps();
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState(
          {
            languageData: data.hospitalData[0].languages,
            timeFilterData: data.hospitalData[0].timeFilterData,
          },
          () => {
            if (this.props.languageEdit.length > 0) {
              $("#cmbLanguage").val(this.props.languageEdit);
            } else {
              $('#cmbLanguage option[value="1"]').prop("selected", true);
            }

            var timeFilter = [];
            if (
              this.props.doctorData[0] != undefined &&
              this.props.doctorData[0].timeFilter !== ""
            ) {
              timeFilter = this.props.doctorData[0].timeFilter.split(",");
            }

            if (timeFilter.length > 0) {
              $("#cmbWorkTimeFilter").val(timeFilter);
            } else {
              $('#cmbWorkTimeFilter option[value="1"]').prop("selected", true);
            }

            $(".loader").hide();
          }
        );
      }
    });

    this.listing();
  }

  BindProps = () => {
    if (this.props.doctorData.length > 0) {
      $("#txtFirstName").val(this.props.doctorData[0].doctorFirstName);
      $("#txtLastName").val(this.props.doctorData[0].doctorLastName);
      $("#cmbTitle").val(
        this.props.doctorData[0].doctorTitle !== "undefined"
          ? this.props.doctorData[0].doctorTitle.trim()
          : ""
      );
     
      // $("#txtDisplayName").val(this.props.doctorData[0].doctorFirstName + " " + this.props.doctorData[0].doctorLastName)
      // $("#txtDateOfBirth").val(this.props.doctorData[0].dateofbirth)
      $("#txtPassword").val(this.props.doctorData[0].password);
      $("#txtConfirmPassword").val(this.props.doctorData[0].password);

      if (this.props.doctorData[0].gender === "Male") {
        $("#rdoMale").prop("checked", true);
      } else {
        $("#rdoFemale").prop("checked", true);
      }

      if (this.props.doctorData[0].signatureOnly !== "") {
        $("#imgDiv").show();
        $("#image").attr("src", this.props.doctorData[0].signatureWithPath);
        //$("#hidSIgnatureName").val(this.props.doctorData[0].signatureOnly)
      }
      if (
        this.props.doctorData[0].dateofbirth !== "" &&
        this.props.doctorData[0].dateofbirth !== "00/00/1900"
      ) {
        var dateOfBithh = this.props.doctorData[0].dateofbirth
          .toString()
          .split("/");
        var dateConcate =
          dateOfBithh[2].toString().trim() +
          "," +
          dateOfBithh[1].toString().trim() +
          "," +
          dateOfBithh[0].toString().trim();
        var date = new Date(dateConcate);

        this.setState({
          dateOfBirth: date,
        });
      } else {
        this.setState({
          dateOfBirth: "",
        });
      }
    }
  };

  listing() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDropdownLists",
      }),
    };

    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState(
          {
            s3bucketAccessKey: data.result[0].s3bucketAccessKey,
            s3bucketSecretKey: data.result[0].s3bucketSecretKey,
            s3BucketMainFolder: data.result[0].S3BucketMainFolder,
            s3BucketAddressPath: data.result[0].s3BucketAddressPath,
            s3BucketDoctorImages: data.result[0].S3BucketDoctorImages,
            s3Bucket: data.result[0].s3Bucket,
          },
          () => {
            AWS.config.update({
              accessKeyId: this.state.s3bucketAccessKey,
              secretAccessKey: this.state.s3bucketSecretKey,
              region: "us-east-1",
            });
            var bucketParams = {
              Bucket: this.state.s3Bucket,
            };
            s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
          }
        );
      }
    });
  }
  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  handleChangeDate(date) {
    this.setState({
      dateOfBirth: date,
    });
  }
  disableDateField() {
    $("#txtDateOfBirth").attr("readonly", "readonly");
    this.setState({
      dateOfBirth: null,
    });
  }
  // displayName() {
  //   var name = $("#txtFirstName").val() + " " + $("#txtLastName").val();
  //   $("#txtDisplayName").val(name)
  //   localStorage.setItem("displaytName", name);
  // }

  handleImageUpload(e, mode) {
    if (typeof e.target.files[0] !== "undefined") {
      let file = e.target.files[0];
      let fileType = file["type"];
      let validImageTypes = ["image/jpg", "image/jpeg", "image/png"];
      if (!validImageTypes.includes(fileType)) {
        alert("Invalid file type");
        $("#" + mode + "File").val(null);
        if (mode === "attachImg") {
          $("#image").attr("src", "");
          $("#imgDiv").hide();
        }
      } else {
        var fileObj = e.target.files[0];
        let reader = new FileReader();
        var img = new Image();
        img.src = window.URL.createObjectURL(fileObj);
        img.onload = function () {
          reader.onload = function (e) {
            if (mode === "attachImg") {
              $("#image").attr("src", e.target.result);
              $("#imgDiv").show();
            }
            // else {
            //   $("#pofileImage").attr('src', e.target.result);
            //   $("#imgProfileDiv").show()
            // }
          };

          reader.readAsDataURL(fileObj);
        };
        if (mode === "attachImg") {
          this.setState({ fileSignature: fileObj });
        }
        // else {
        //   this.setState({ fileAppProfile: fileObj })
        // }
      }
    }
  }

  fileUpload(file, mode) {
    let reader = new FileReader();
    //let reader = new FileReader();
    let s3BucketMainFolder1 =
      this.state.s3BucketMainFolder + "/" + this.state.s3BucketDoctorImages;
    let fileName = this.uuidv4() + file.name;
    let fullFilePath = s3BucketMainFolder1 + "/" + fileName;
    // fileNameUploadSignature = fileName
    if (mode === "attachImg") {
      fileNameUploadSignature = fileName;
    }

    s3.upload(
      {
        Key: fullFilePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        if (err) {
          // alert(
          //     "There was an error uploading your photo: ",
          //     err.message
          // );
          return null;
        } else {
          //  alert("Image uploaded successfuly")
          // alert("fullFilePath"+fullFilePath)
          //return fullFilePath;
        }
      }
    );
  }
  doctorinformation() {
    var image = "";
    if (this.state.fileSignature !== null) {
      this.fileUpload(this.state.fileSignature, "attachImg");
      image = fileNameUploadSignature;
    } else if (
      this.props.doctorData.length > 0 &&
      this.props.doctorData[0].signatureOnly !== ""
    ) {
      image = this.props.doctorData[0].signatureOnly;
    }

    return image;
  }

  SetLocalStorage() {
    window.sessionStorage.setItem("seoTitle", $("#cmbTitle").val());
    var name = $("#txtFirstName").val() + $("#txtLastName").val();
    window.sessionStorage.setItem("seoDisplaytName", name);
  }
  render() {
    return (
      <div className="bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <div className="container mb-5">
             
                <div className="hspInformation-wraper">
                  <h2 className="hspInfoSubhead">Doctor Information</h2>
                  <div className="">
                  <div className="hspinfo-contentdiv">
                    <label>
                      First Name <span className="hspifoMantory">*</span>
                    </label>
                    <input
                      type="text"
                      id="txtFirstName"
                      autoComplete="off"
                      name="txtFirstName"
                      className="hspInfofieldinput Infoinputwidthdoctr"
                      placeholder="First Name"
                      onKeyUp={() => this.SetLocalStorage()}
                    />
                  </div>
                  <div className="hspinfo-contentdiv">
                    <label>Last Name</label>
                    <input
                      type="text"
                      id="txtLastName"
                      autoComplete="off"
                      name="txtLastName"
                      className="hspInfofieldinput Infoinputwidthdoctr"
                      placeholder="Last Name"
                      onKeyUp={() => this.SetLocalStorage()}
                    />
                  </div>

                  <div className="hspinfo-contentdiv">
                    <label>
                      Title <span className="hspifoMantory">*</span>
                    </label>
                    <select
                      name="cmbTitle"
                      id="cmbTitle"
                      className="hspInfofieldinput Infoinputwidthdoctr"
                      onChange={() => this.SetLocalStorage()}
                    >
                      <option value="">Select Title</option>
                      <option value="Dr.">Dr</option>
                      <option value="Mr.">Mr</option>
                      <option value="Ms.">Ms</option>
                    </select>
                  </div>
                  {/* <div className="hspinfo-contentdiv">
                    <label>Display Name </label>
                    <input type="text" id="txtDisplayName" autoComplete="off" name="txtDisplayName" className="hspInfofieldinput Infoinputwidthdoctr" placeholder="Display Name" />
                  </div> */}

                  <div className="hspinfo-contentdiv">
                    <label className="pt-0">
                      Gender<span className="hspifoMantory">*</span>
                    </label>
                    <div className="superadminDivBox">
                      <input
                        type="radio"
                        name="rdoGenderType"
                        value="Male"
                        id="rdoMale"
                        className=""
                      />{" "}
                      <span className="gendrfldHspdctr pr-2">Male</span>
                      <input
                        type="radio"
                        name="rdoGenderType"
                        value="Female"
                        id="rdoFemale"
                        className=""
                      />{" "}
                      <span className="gendrfldHspdctr">Female</span>
                    </div>
                  </div>

                  <div className="hspinfo-contentdiv">
                    <label>Doctor ID</label>
                    <input
                      type="text"
                      value={this.props.doctorId}
                      autoComplete="off"
                      className="hspInfofieldinput Infoinputwidthdoctr"
                      disabled
                    />
                  </div>

                  <div className="superadminDivBoxcover">
                    <div className="superadminDivBox superadminDivBoxwidtAuto d-flex w-100">
                      <label>Signature(jpeg,png)</label>
                      <div className="hspinfoimgaeuploadDiv">
                        <div className="hspinfo-btnupload w-100">
                          <span for="attachImg">Upload</span>
                          <input
                            type="file"
                            id="attachImg"
                            accept="image/*"
                            name="attachImg"
                            className="hspinfoInputfieldupload w-100"
                            onChange={(e) =>
                              this.handleImageUpload(e, "attachImg")
                            }
                          />
                        </div>
                        <div id="imgDiv" style={{ display: "none" }}>
                          {" "}
                          <img height="30" width="35" id="image"></img>
                        </div>
                        <hidden
                          id="hidSIgnatureName"
                          name="hidSIgnatureName"
                        ></hidden>
                      </div>
                    </div>
                  </div>
                  <div className="superadminDivBoxcover">
                  <div className="hspinfo-contentdiv custom_input react_datepicker">
                    <div className="superadminDivBox  react-date-dctr-width d-flex w-100">
                      <label className="pt-0">Date Of Birth</label>
                      <DatePicker
                        placeholderText="Date of Birth"
                        id="txtDateOfBirth"
                        name="txtDateOfBirth"
                        maxDate={new Date()}
                        showMonthDropdown
                        className="doctrHsp-dateinput"
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={100}
                        dropdownMode="scroll"
                        peekNextMonth
                        disabledKeyboardNavigation
                        selected={this.state.dateOfBirth}
                        onSelect={this.handleChangeDate}
                        dateFormat="dd/MM/yyyy"
                        calendarClassName="react-date-picker"
                        autoComplete='off'
                        tabIndex="8"
                        onKeyDown={() => this.disableDateField()}
                      />
                    </div>
                  </div>
                  </div>
                  <div className="hspinfo-contentdiv">
                    <label>
                      Password<span className="hspifoMantory">*</span>
                    </label>
                    <input
                      type="password"
                      id="txtPassword"
                      name="txtPassword"
                      className="hspInfofieldinput Infoinputwidthdoctr"
                      placeholder="Password"
                    />
                  </div>
                  <div className="hspinfo-contentdiv">
                    <label>
                      Confirm Password <span className="hspifoMantory">*</span>
                    </label>
                    <input
                      type="password"
                      id="txtConfirmPassword"
                      name="txtConfirmPassword"
                      className="hspInfofieldinput Infoinputwidthdoctr"
                      placeholder="Confirm Password"
                    />
                  </div>

                  <div className="hspinfo-contentdiv">
                    <label>
                      Language <span className="hspifoMantory">*</span>
                    </label>
                    <select
                      multiple
                      name="cmbLanguage"
                      id="cmbLanguage"
                      style={{ height: "95px" }}
                      className="hspInfofieldinput Infoinputwidthdoctr"
                    >
                      {this.state.languageData.map((LangObj, i) => {
                        return (
                          <option
                            key={LangObj.languageId}
                            value={LangObj.languageId}
                          >
                            {LangObj.languageName}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="hspinfo-contentdiv">
                    <label>
                      Work Time Filter <span className="hspifoMantory">*</span>
                    </label>
                    <select
                      multiple
                      name="cmbWorkTimeFilter"
                      id="cmbWorkTimeFilter"
                      style={{ height: "95px" }}
                      className="hspInfofieldinput Infoinputwidthdoctr"
                    >
                      {this.state.timeFilterData.map((timeObj) => {
                        return (
                          <option
                            key={timeObj.timeFilterId}
                            value={timeObj.timeFilterId}
                          >
                            {timeObj.timeFilterName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}></Suspense>
      </div>
    );
  }
}
