import React, { Component, Suspense } from "react";
import Header from "../../Reliance/header";
import Footer from "../../Reliance/footer";

class productDashboard extends Component {
  state = {};
  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />

            <div className="container">
              <div>
                <ul className="breadcrum-adminMangmnt">
                  <li>
                    <a href="/admin/dashboard">Dashboard</a>
                  </li>
                  <li>
                    <a
                      href="/adminManagement/productDashboard"
                      className="current"
                    >
                      Product Dashboard
                    </a>
                  </li>
                </ul>
              </div>
              <div className="head-hospMangmntList mt-4">Product Dashboard</div>

              <div className="sectionItemDiv siteset-sectnDivBorder sectionBorderadminMangmnt mt-0">
                <div className="adminMangemnt-dashboard">
                  <div className="admin-dc-dashBtns">
                    <a href="/adminManagement/hospitalProducts">
                      <div>Hospital Products</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}

export default productDashboard;
