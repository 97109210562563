import React, { Component } from "react";
import Header from "../Reliance/header";
import Footer from "../Reliance/footer";
import calendar from "../image/media/calendar.png";
import vitalsign from "../image/media/vitalsign.png";
import casesheet from "../image/media/case-sheet.png";
import treatmentplan from "../image/media/treatment-plan.png";
import invoice from "../image/media/invoice.png";
import compplans from "../image/media/comp-plans.png";
import rx from "../image/media/rx.png";
import cash from "../image/media/cash.png";
import $ from "jquery";
import moment from "moment";
import { apiCalling, awsAPIUrlcommon } from "../apiService";

export default class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      historyIdsList: [
        "appointments",
        "caseSheet",
        "vitalSigns",
        "treatmentPlans",
        "completedPlans",
        "files",
        "prescriptions",
        "labOrder",
        "invoices",
        "payments",
        "testResult",
        "referADoctor",
        "admit",
        "discharge",
      ],
      newIdsList: [],
      historyData: [],
      dateList: [],
      subFlag: false,
      subFlagId: "",
    };
  }
  componentDidMount() {
    this.getHistoryDetails();
    $("#appointmentsDataNotAvail").attr("hidden",true);
    $("#completedPlansDataNotAvail").attr("hidden",true);
    $("#paymentsDataNotAvail").attr("hidden",true);
    $("#treatmentPlanDataNotAvail").attr("hidden",true);
  }
  getHistoryDetails() {
    var dateList = [];
    const userAPI = {
      method: "POST",
      body: JSON.stringify({
        functionName: "patientHistory",
        memberId: window.sessionStorage.getItem("userId"),
      }),
    };
    awsAPIUrlcommon(userAPI).then((data) => {
      if (data.success === "1") {
        this.setState({
          historyData: data.result.historyData,
        });
      } else {
        this.setState({
          historyData: [],
        });
      }
      // for (let i = 0; i < data.result.historyData.length; i++) {
      //   let tempDate = moment(data.result.historyData[i].historyDate).format("YYYY,MM,DD")
      //   console.log("tempDate", tempDate);
      //   this.state.dateList.push(tempDate);
      //   // console.log("hiiiiiiiiiiii",(moment(data.result.historyData[i].historyDate).format("YYYY,MM,DD")).split(","))
      // }
    });
    // console.log("dateList", this.state.dateList);
  }
  changeHandler(e) {
    // $(".appointments").hide()
    //console.log("valuuuuu",e.target.value)

    if (e.target.checked === true) {
      $("#selectAll").attr("hidden", true);
      $("#deselectAll").attr("hidden", false);
      this.state.newIdsList.push(e.target.id.toString());
      this.hideDivs();
    } else {
      const index = this.state.newIdsList.indexOf(e.target.id);
      this.state.newIdsList.splice(index, 1);

      this.hideDivs();
    }
    if (this.state.newIdsList.length === 0) {
      $("#selectAll").attr("hidden", false);
      $("#deselectAll").attr("hidden", true);
      for (let i = 0; i < this.state.historyIdsList.length; i++) {
        $("." + this.state.historyIdsList[i]).show();
      }
    }
  }
  hideDivs() {
    for (let i = 0; i < this.state.historyIdsList.length; i++) {
      for (let j = 0; j < this.state.newIdsList.length; j++) {
        if (this.state.newIdsList[j] === this.state.historyIdsList[i]) {
          $("." + this.state.newIdsList[j]).show();
          // $("#" + this.state.newIdsList[j] + "DataNotAvail").attr("hidden",false);
          break;
        } else {
          //console.log("false....",this.state.historyIdsList[i])
          $("." + this.state.historyIdsList[i]).hide();
          $("#"+this.state.historyIdsList[i]+"DataNotAvail").attr("hidden",true);
          
        }
      }
    }
    for (let j = 0; j < this.state.newIdsList.length; j++) {
      if (this.state.newIdsList[j] === "treatmentPlans"){
        // alert("hi")
      }
    }
    // console.log("...............................");
  }
  selectDeselect(e) {
    if (e.target.id === "selectAllClick") {
      $("#selectAll").attr("hidden", true);
      $("#deselectAll").attr("hidden", false);
      this.setState({
        newIdsList: [
          "appointments",
          "caseSheet",
          "vitalSigns",
          "treatmentPlans",
          "completedPlans",
          "files",
          "prescriptions",
          "labOrder",
          "invoices",
          "payments",
          "testResult",
          "referADoctor",
          "admit",
          "dischargeNote",
        ],
      });
      $("#appointments").prop("checked", true);
      $("#caseSheet").prop("checked", true);
      $("#vitalSigns").prop("checked", true);
      $("#treatmentPlans").prop("checked", true);
      $("#completedPlans").prop("checked", true);
      $("#files").prop("checked", true);
      $("#prescriptions").prop("checked", true);
      $("#labOrder").prop("checked", true);
      $("#invoices").prop("checked", true);
      $("#payments").prop("checked", true);
      $("#testResult").prop("checked", true);
      $("#referADoctor").prop("checked", true);
      $("#admit").prop("checked", true);
      $("#dischargeNote").prop("checked", true);
      this.hideDivs();
    } else if (e.target.id === "deselectAllClick") {
      $("#selectAll").attr("hidden", false);
      $("#deselectAll").attr("hidden", true);
      this.setState({
        newIdsList: [],
      });
      $("#appointments").prop("checked", false);
      $("#caseSheet").prop("checked", false);
      $("#vitalSigns").prop("checked", false);
      $("#treatmentPlans").prop("checked", false);
      $("#completedPlans").prop("checked", false);
      $("#files").prop("checked", false);
      $("#prescriptions").prop("checked", false);
      $("#labOrder").prop("checked", false);
      $("#invoices").prop("checked", false);
      $("#payments").prop("checked", false);
      $("#testResult").prop("checked", false);
      $("#referADoctor").prop("checked", false);
      $("#admit").prop("checked", false);
      $("#dischargeNote").prop("checked", false);
      // this.hideDivs()
      $(".appointments").show();
      $(".caseSheet").show();
      $(".vitalSigns").show();
      $(".treatmentPlans").show();
      $(".completedPlans").show();
      $(".files").show();
      $(".prescriptions").show();
      $(".labOrder").show();
      $(".invoices").show();
      $(".payments").show();
      $(".testResult").show();
      $(".referADoctor").show();
      $(".admit").show();
      $(".dischargeNote").show();
    }
  }

  render() {
    return (
      <div class="purpleWrap">
        <Header></Header>
        <div class="container container-auto">
          <div class="main-wrapper1">
            <h4 class="color-maintext">History</h4>
            <div class="form-section mt-2 form-section-padng">
              <div class="row">
                <div className="col-6">
                  <h6> Types </h6>
                </div>
                <div className="col-6">
                  <div id="deselectAll" hidden class="pendingLink">
                    <a
                      id="deselectAllClick"
                      onClick={(e) => this.selectDeselect(e)}
                    >
                      Deselect All
                    </a>
                  </div>
                  <div id="selectAll" class="pendingLink">
                    <a
                      id="selectAllClick"
                      onClick={(e) => this.selectDeselect(e)}
                    >
                      Select All
                    </a>
                  </div>
                </div>
                <div class="col-6 col-lg-3 col-md-4 mb-2">
                  <input
                    id="appointments"
                    type="checkbox"
                    class="mr-2"
                    onChange={(e) => this.changeHandler(e)}
                  />{" "}
                  Appointments{" "}
                </div>
                {/* <div class="col-6 col-lg-3 col-md-4 mb-2">
                  <input
                    id="caseSheet"
                    type="checkbox"
                    class="mr-2"
                    onChange={(e) => this.changeHandler(e)}
                  />{" "}
                  Case Sheet{" "}
                </div> */}
                {/* <div class="col-6 col-lg-3 col-md-4 mb-2">
                  <input
                    id="vitalSigns"
                    type="checkbox"
                    class="mr-2"
                    onChange={(e) => this.changeHandler(e)}
                  />{" "}
                  Vital Signs{" "}
                </div> */}
                <div class="col-6 col-lg-3 col-md-4 mb-2">
                  <input
                    id="treatmentPlans"
                    type="checkbox"
                    class="mr-2"
                    onChange={(e) => this.changeHandler(e)}
                  />{" "}
                  Treatment Plans{" "}
                </div>
                <div class="col-6 col-lg-3 col-md-4 mb-2">
                  <input
                    id="completedPlans"
                    type="checkbox"
                    class="mr-2"
                    onChange={(e) => this.changeHandler(e)}
                  />{" "}
                  Completed Plans{" "}
                </div>
                {/* <div class="col-6 col-lg-3 col-md-4 mb-2">
                  <input
                    id="files"
                    type="checkbox"
                    class="mr-2"
                    onChange={(e) => this.changeHandler(e)}
                  />{" "}
                  Files{" "}
                </div> */}
                {/* <div class="col-6 col-lg-3 col-md-4 mb-2">
                  <input
                    id="prescriptions"
                    type="checkbox"
                    class="mr-2"
                    onChange={(e) => this.changeHandler(e)}
                  />{" "}
                  Prescriptions{" "}
                </div> */}
                {/* <div class="col-6 col-lg-3 col-md-4 mb-2">
                  <input
                    id="labOrder"
                    type="checkbox"
                    class="mr-2"
                    onChange={(e) => this.changeHandler(e)}
                  />{" "}
                  Lab Order{" "}
                </div> */}
                <div class="col-6 col-lg-3 col-md-4 mb-2">
                  <input
                    id="payments"
                    type="checkbox"
                    class="mr-2"
                    onChange={(e) => this.changeHandler(e)}
                  />{" "}
                  Payments{" "}
                </div>
                <div class="col-6 col-lg-3 col-md-4 mb-2">
                  <input
                    id="invoices"
                    type="checkbox"
                    class="mr-2"
                    onChange={(e) => this.changeHandler(e)}
                  />{" "}
                  Invoices{" "}
                </div>
                {/* <div class="col-6 col-lg-3 col-md-4 mb-2">
                  <input
                    id="testResult"
                    type="checkbox"
                    class="mr-2"
                    onChange={(e) => this.changeHandler(e)}
                  />{" "}
                  Test Result{" "}
                </div> */}
                {/* <div class="col-6 col-lg-3 col-md-4 mb-2">
                  <input
                    id="referADoctor"
                    type="checkbox"
                    class="mr-2"
                    onChange={(e) => this.changeHandler(e)}
                  />{" "}
                  Refer A Doctor{" "}
                </div> */}
                {/* <div class="col-6 col-lg-3 col-md-4 mb-2">
                  <input
                    id="admit"
                    type="checkbox"
                    class="mr-2"
                    onChange={(e) => this.changeHandler(e)}
                  />{" "}
                  Admit{" "}
                </div> */}
                {/* <div class="col-6 col-lg-3 col-md-4 mb-2">
                  <input
                    id="dischargeNote"
                    type="checkbox"
                    class="mr-2"
                    onChange={(e) => this.changeHandler(e)}
                  />{" "}
                  Discharge Note{" "}
                </div> */}
              </div>

              {this.state.historyData.length > 0 ? (
                <>
                  <div
                    id="appointmentsDataNotAvail"
                    hidden
                    class="treatmentCommonTxtWrap"
                  >
                    Appointments Not Available
                  </div>
                  <div
                    id="paymentsDataNotAvail"
                    hidden
                    class="treatmentCommonTxtWrap"
                  >
                    Payment Data Not Available
                  </div>
                  <div
                    id="completedPlansDataNotAvail"
                    hidden
                    class="treatmentCommonTxtWrap"
                  >
                    Completed Plan Data Not Available
                  </div>
                  <div
                    id="treatmentPlansDataNotAvail"
                    hidden
                    class="treatmentCommonTxtWrap"
                  >
                    Treatment Plan Data Not Available
                  </div>
                  {this.state.historyData.map((data, i) => (
                    <>
                      <>
                        {data.appointmentData ? (
                          <div class="treatmentTimelineBlockMain appointments">
                            {data.appointmentData.map((apptData, i) => (
                              <>
                                <div class="timelineList ">
                                  <div class="timelineListDate">
                                    <span class="timelineDate">
                                      {moment(apptData.appointmentDate).format(
                                        "DD"
                                      )}
                                    </span>
                                    <div class="timelineDateCvr">
                                      <span class="timelineMnthDate">
                                        {moment(
                                          apptData.appointmentDate
                                        ).format("YYYY")}
                                      </span>
                                      <span class="timelineDay">
                                        {moment(
                                          apptData.appointmentDate
                                        ).format("MMMM")}
                                      </span>
                                    </div>
                                  </div>
                                  <div class="treatmentTimelineBlock ">
                                    <div class="timelineListContent appointments-div ">
                                      <span class="timelineArrow"></span>{" "}
                                      <span class="timelineCircle"></span>
                                      <div class="timelineListName">
                                        <img alt="" src={calendar} />{" "}
                                        <span>APPOINTMENTS</span>
                                      </div>
                                      {apptData.appointmentDetails.map(
                                        (apptDetails, i) => (
                                          <div class="appoinmentDateDetail">
                                            <div class="appoinmentLeftDiv">
                                              <span class="appoinmentReason">
                                                <span>Reason:</span>
                                                <span class="elipsis">
                                                  {apptDetails.visitReason}
                                                </span>
                                              </span>
                                            </div>
                                            <div class="appoinmentSatusWrap">
                                              <span class="appoinmentTYpe">
                                                Type :{" "}
                                                {apptDetails.appointmentType}
                                              </span>
                                            </div>
                                            <div class="appoinmentSatusWrap">
                                              <span class="appoinmentSTatus">
                                                Status : {apptDetails.appStatus}
                                              </span>
                                            </div>
                                            <div class="appoinmentRightDiv">
                                              <span class="appoinmentDrName">
                                                {apptDetails.doctorName}
                                              </span>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                      <>
                        {data.paymentData ? (
                          <div class="treatmentTimelineBlockMain payments">
                            <div class="timelineList">
                              <div class="timelineListDate">
                                <span class="timelineDate">
                                  {moment(data.historyDate).format("DD")}
                                </span>
                                <div class="timelineDateCvr">
                                  <span class="timelineMnthDate">
                                    {moment(data.historyDate).format("YYYY")}
                                  </span>
                                  <span class="timelineDay">
                                    {moment(data.historyDate).format("MMMM")}
                                  </span>
                                </div>
                              </div>
                              {data.paymentData.map((paymentData, i) => (
                                <div class="treatmentTimelineBlock ">
                                  <div class="timelineListContent treatmentplan-div">
                                    <span class="timelineArrow"></span>{" "}
                                    <span class="timelineCircle"></span>
                                    <div class="timelineListName">
                                      <img alt="" src={cash} />{" "}
                                      <span>Payment</span>
                                    </div>
                                    <div class="treatmentTable treatmentpadZero">
                                      <div class="treatmentPlanList">
                                        <div class="treatmentTable ">
                                          <table>
                                            <thead>
                                              <tr>
                                                <th
                                                  class="col-md-3"
                                                  style={{ minWidth: "200px" }}
                                                >
                                                  <div class="dentalProName">
                                                    RECEIPT NUMBER{" "}
                                                  </div>
                                                </th>
                                                <th class="col-md-2">
                                                  <div class="dentalProCost">
                                                    AMOUNT PAID
                                                  </div>
                                                </th>
                                                <th class="col-md-2">
                                                  <div class="dentalProDis">
                                                    INVOICES NUMBER
                                                  </div>
                                                </th>
                                                <th class="col-md-2">
                                                  <div class="dentalProDis">
                                                    TOWARDS
                                                  </div>
                                                </th>
                                                <th class="col-md-2">
                                                  <div class="dentalProTotal">
                                                    MODE OF PAYMENT
                                                  </div>
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr class="groupBrdr">
                                                <td class="col-md-3">
                                                  <div class="dentalProName">
                                                    {
                                                      paymentData
                                                        .paymentDetails[0]
                                                        .receipt_no
                                                    }
                                                  </div>
                                                </td>
                                                <td class="col-md-2">
                                                  <div
                                                    class="dentalProCost"
                                                    style={{
                                                      whiteSpace: "nowrap",
                                                    }}
                                                  >
                                                    {
                                                      paymentData
                                                        .paymentDetails[0]
                                                        .receipt_amount
                                                    }
                                                  </div>
                                                </td>
                                                <td class="col-md-2">
                                                  <div
                                                    class="dentalProDis"
                                                    style={{
                                                      whiteSpace: "nowrap",
                                                    }}
                                                  >
                                                    {
                                                      paymentData
                                                        .paymentDetails[0]
                                                        .invoice_no
                                                    }
                                                  </div>
                                                </td>
                                                <td class="col-md-2">
                                                  <div
                                                    class="dentalProDis"
                                                    style={{
                                                      whiteSpace: "nowrap",
                                                    }}
                                                  ></div>
                                                </td>
                                                <td class="col-md-2">
                                                  <div class="dentalProTotal">
                                                    {
                                                      paymentData
                                                        .paymentDetails[0]
                                                        .payment_mode
                                                    }
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                      <>
                        {data.completedPlanData ? (
                          <div class="treatmentTimelineBlockMain completedPlans">
                            <div class="timelineList">
                              <div class="timelineListDate">
                                <span class="timelineDate">
                                  {moment(data.historyDate).format("DD")}
                                </span>
                                <div class="timelineDateCvr">
                                  <span class="timelineMnthDate">
                                    {moment(data.historyDate).format("YYYY")}
                                  </span>
                                  <span class="timelineDay">
                                    {moment(data.historyDate).format("MMMM")}
                                  </span>
                                </div>
                              </div>
                              {data.completedPlanData.map(
                                (cmpltdPlanData, i) => (
                                  <div class="treatmentTimelineBlock ">
                                    <div class="timelineListContent treatmentplan-div">
                                      <span class="timelineArrow"></span>{" "}
                                      <span class="timelineCircle"></span>
                                      <div class="timelineListName">
                                        <img alt="" src={compplans} />{" "}
                                        <span>COMPLETED PLANS</span>
                                      </div>
                                      <div class="treatmentTable treatmentpadZero">
                                        <div class="treatmentPlanList">
                                          <div class="treatmentTable ">
                                            <table>
                                              <thead>
                                                <tr>
                                                  <th
                                                    class="col-md-3"
                                                    style={{
                                                      minWidth: "200px",
                                                    }}
                                                  >
                                                    <div class="dentalProName">
                                                      PROCEDURE
                                                    </div>
                                                  </th>
                                                  <th class="col-md-2">
                                                    <div class="dentalProCost">
                                                      COST
                                                    </div>
                                                  </th>
                                                  <th class="col-md-2">
                                                    <div class="dentalProDis">
                                                      DISCOUNT
                                                    </div>
                                                  </th>
                                                  <th class="col-md-2">
                                                    <div class="dentalProDis">
                                                      TAX
                                                    </div>
                                                  </th>
                                                  <th class="col-md-2">
                                                    <div class="dentalProTotal">
                                                      TOTAL
                                                    </div>
                                                  </th>
                                                  <th class="col-md-3">
                                                    <div class="dentalProNote">
                                                      NOTES
                                                    </div>
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr class="groupBrdr">
                                                  <td class="col-md-3">
                                                    <div class="dentalProName">
                                                      <div class="optionWrapper">
                                                        <img
                                                          src={invoice}
                                                          title="Invoice Generated"
                                                        ></img>
                                                        <label for="cool011"></label>
                                                      </div>
                                                      <span class="elipsis">
                                                        {
                                                          cmpltdPlanData
                                                            .completedPlanDetails[0]
                                                            .procedure_code
                                                        }
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td class="col-md-2">
                                                    <div class="dentalProCost">
                                                      {
                                                        cmpltdPlanData
                                                          .completedPlanDetails[0]
                                                          .amount
                                                      }
                                                    </div>
                                                  </td>
                                                  <td class="col-md-2">
                                                    <div class="dentalProDis">
                                                      {
                                                        cmpltdPlanData
                                                          .completedPlanDetails[0]
                                                          .discount
                                                      }
                                                    </div>
                                                  </td>
                                                  <td class="col-md-2">
                                                    <div class="dentalProDis">
                                                      {
                                                        cmpltdPlanData
                                                          .completedPlanDetails[0]
                                                          .total_tax
                                                      }
                                                    </div>
                                                  </td>
                                                  <td class="col-md-2">
                                                    <div class="dentalProTotal">
                                                      {
                                                        cmpltdPlanData
                                                          .completedPlanDetails[0]
                                                          .total_net_amount
                                                      }
                                                    </div>
                                                  </td>
                                                  <td class="col-md-3">
                                                    <div class="dentalProNote">
                                                      <span
                                                        class="elipsis"
                                                        title=""
                                                      >
                                                        &nbsp;
                                                        {
                                                          cmpltdPlanData
                                                            .completedPlanDetails[0]
                                                            .notes
                                                        }
                                                      </span>
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr class="dentalProPlaned">
                                                  <input
                                                    type="hidden"
                                                    name="hidTreatmentIds00"
                                                    id="hidTreatmentIds00"
                                                    value=""
                                                  />
                                                  <td colspan="6">
                                                    <div class="plannedBy">
                                                      Planned by{" "}
                                                      <span>
                                                        {
                                                          cmpltdPlanData
                                                            .completedPlanDetails[0]
                                                            .doctor_name
                                                        }
                                                      </span>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                      <>
                        {data.treatmentPlanData ? (
                          <div class="treatmentTimelineBlockMain treatmentPlans">
                            <div class="timelineList">
                              <div class="timelineListDate">
                                <span class="timelineDate">
                                  {moment(data.historyDate).format("DD")}
                                </span>
                                <div class="timelineDateCvr">
                                  <span class="timelineMnthDate">
                                    {moment(data.historyDate).format("YYYY")}
                                  </span>
                                  <span class="timelineDay">
                                    {moment(data.historyDate).format("MMMM")}
                                  </span>
                                </div>
                              </div>
                              {data.treatmentPlanData.map(
                                (tremntPlanData, i) => (
                                  <div class="treatmentTimelineBlock">
                                    <div class="timelineListContent treatmentplan-div">
                                      <span class="timelineArrow"></span>{" "}
                                      <span class="timelineCircle"></span>
                                      <div class="timelineListName">
                                        <img alt="" src={treatmentplan} />{" "}
                                        <span>Treatment plan</span>
                                      </div>
                                      <div class="treatmentTable treatmentpadZero">
                                        <div class="treatmentPlanList">
                                          <div class="treatmentTable ">
                                            <table>
                                              <thead>
                                                <tr>
                                                  <th
                                                    class="col-md-3"
                                                    style={{
                                                      minWidth: "200px",
                                                    }}
                                                  >
                                                    <div class="dentalProName">
                                                      PROCEDURE
                                                    </div>
                                                  </th>
                                                  <th class="col-md-2">
                                                    <div class="dentalProCost">
                                                      COST
                                                    </div>
                                                  </th>
                                                  <th class="col-md-2">
                                                    <div class="dentalProDis">
                                                      TAX
                                                    </div>
                                                  </th>
                                                  <th class="col-md-2">
                                                    <div class="dentalProTotal">
                                                      TOTAL
                                                    </div>
                                                  </th>
                                                  <th class="col-md-3">
                                                    <div class="dentalProNote">
                                                      NOTES
                                                    </div>
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr class="groupBrdr">
                                                  <td class="col-md-3">
                                                    <div class="dentalProName">
                                                      <div class="optionWrapper">
                                                        <img
                                                          src={invoice}
                                                          title="Invoice Generated"
                                                        ></img>
                                                        <label for="cool011"></label>
                                                      </div>
                                                      <span class="elipsis">
                                                        {
                                                          tremntPlanData
                                                            .treatmentPlanDetails[0]
                                                            .procedure_code
                                                        }
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td class="col-md-2">
                                                    <div class="dentalProCost">
                                                      {
                                                        tremntPlanData
                                                          .treatmentPlanDetails[0]
                                                          .total_cost
                                                      }
                                                    </div>
                                                  </td>
                                                  <td class="col-md-2">
                                                    <div class="dentalProDis">
                                                      {
                                                        tremntPlanData
                                                          .treatmentPlanDetails[0]
                                                          .total_tax
                                                      }
                                                    </div>
                                                  </td>
                                                  <td class="col-md-2">
                                                    <div class="dentalProTotal">
                                                      {
                                                        tremntPlanData
                                                          .treatmentPlanDetails[0]
                                                          .amount
                                                      }
                                                    </div>
                                                  </td>
                                                  <td class="col-md-3">
                                                    <div class="dentalProNote">
                                                      <span
                                                        class="elipsis"
                                                        title=""
                                                      >
                                                        &nbsp;
                                                        {
                                                          tremntPlanData
                                                            .treatmentPlanDetails[0]
                                                            .notes
                                                        }
                                                      </span>
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr class="dentalProPlaned">
                                                  <input
                                                    type="hidden"
                                                    name="hidTreatmentIds00"
                                                    id="hidTreatmentIds00"
                                                    value=""
                                                  />
                                                  <td>
                                                    <div class="plannedBy">
                                                      Planned by{" "}
                                                      <span>
                                                        {
                                                          tremntPlanData.doctor_name
                                                        }
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td colspan="3">
                                                    <div class="estimatedPrice txtRight">
                                                      Estimated amount :{" "}
                                                      {
                                                        tremntPlanData.total_net_amount
                                                      }
                                                    </div>
                                                  </td>
                                                  <td colspan="1">
                                                    <div class="estimatedPrice">
                                                      &nbsp;
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    </>
                  ))}

                  {/* <div class="timelineList vitalSigns">
                    <div class="timelineListDate">
                      <span class="timelineDate">08</span>
                      <div class="timelineDateCvr">
                        <span class="timelineMnthDate"> 2020</span>
                        <span class="timelineDay">July</span>
                      </div>
                    </div>
                    <div class="treatmentTimelineBlock">
                      <div class="timelineListContent vitalSign-div">
                        <span class="timelineArrow"></span>{" "}
                        <span class="timelineCircle"></span>
                        <div class="timelineListName">
                          <img alt="" src={vitalsign} />{" "}
                          <span>Vital Signs</span>
                        </div>
                        <div class="treatmentTable prescriptionTable ">
                          <div class="treatmentComplaintWrap">
                            <div class="treatmentComplaintRowWrap treatmentComplaintRowWrap">
                              <div class="treatmentComplaintRow">
                                <div class="treatmentComplaintLeft">
                                  <span>Time</span>
                                </div>
                                <div class="treatmentComplaintRight">
                                  <span>15:58</span>
                                </div>
                              </div>
                              <div class="treatmentComplaintRow">
                                <div class="treatmentComplaintLeft">
                                  <span>Weight (Kg)</span>
                                </div>
                                <div class="treatmentComplaintRight">
                                  <span>74</span>
                                </div>
                              </div>
                              <div class="treatmentComplaintRow">
                                <div class="treatmentComplaintLeft">
                                  <span>Blood Pressure SYS/DIA (mmHg)</span>
                                </div>
                                <div class="treatmentComplaintRight">
                                  <span>110/90 Sitting</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="treatmentComplaintNoted">
                            Noted by <span>Weekend doctor</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="timelineList caseSheet">
                    <div class="timelineListDate">
                      <span class="timelineDate">08</span>
                      <div class="timelineDateCvr">
                        <span class="timelineMnthDate"> 2020</span>
                        <span class="timelineDay">July</span>
                      </div>
                    </div>
                    <div class="treatmentTimelineBlock">
                      <div class="timelineListContent caseSheet-div">
                        <span class="timelineArrow"></span>
                        <span class="timelineCircle"></span>
                        <div class="timelineListName">
                          <img src={casesheet}></img> <span>Case Sheet</span>
                        </div>
                        <div class="treatmentPlanListWrap">
                          <div class="treatmentComplaintWrap">
                            <div class="treatmentComplaintRowWrap">
                              <div class="treatmentComplaintRow">
                                <div class="treatmentComplaintLeft">
                                  {" "}
                                  Chief presenting complaint{" "}
                                </div>
                                <div class="treatmentComplaintRight">
                                  <span class="treatmentTAgName elipsis">
                                    Chief Complaint
                                  </span>
                                  <span class="treatmentTAgDate">
                                    13,Jul 2020
                                  </span>
                                </div>
                              </div>
                              <div class="treatmentComplaintRow">
                                <div class="treatmentComplaintLeft">
                                  Associated complaints
                                </div>
                                <div class="treatmentComplaintRight">
                                  <span class="treatmentTAgName elipsis">
                                    Associated Complaint<br></br>Known Case of
                                  </span>
                                  <span class="treatmentTAgDate">
                                    13,Jul 2020
                                  </span>
                                </div>
                              </div>
                              <div class="treatmentComplaintRow">
                                <div class="treatmentComplaintLeft">
                                  {" "}
                                  Observations{" "}
                                </div>
                                <div class="treatmentComplaintRight">
                                  <span class="treatmentTAgName elipsis"></span>
                                  <span class="treatmentTAgDate"></span>
                                  <span style={{ fontWeight: "bold" }}>
                                    Description :
                                  </span>
                                  <span>Observations</span>
                                </div>
                              </div>
                              <div class="treatmentComplaintNoted">
                                Noted by <span>Dr.Ortho Paid Doc (Ortho)</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  

                  <div class="timelineList prescriptions">
                    <div class="timelineListDate">
                      <span class="timelineDate">08</span>
                      <div class="timelineDateCvr">
                        <span class="timelineMnthDate"> 2020</span>
                        <span class="timelineDay">July</span>
                      </div>
                    </div>
                    <div class="treatmentTimelineBlock">
                      <div class="timelineListContent treatmentplan-div">
                        <span class="timelineArrow"></span>{" "}
                        <span class="timelineCircle"></span>
                        <div class="timelineListName">
                          <img alt="" src={rx} /> <span>Prescriptions</span>
                        </div>
                        <div class="treatmentTable treatmentpadZero">
                          <div class="treatmentPlanList">
                            <div class="treatmentTable ">
                              <table>
                                <thead>
                                  <tr>
                                    <th
                                      class="col-md-3"
                                      style={{ minWidth: "200px" }}
                                    >
                                      <div class="dentalProName">Medicine</div>
                                    </th>
                                    <th class="col-md-2">
                                      <div class="dentalProCost">
                                        Frequency & Dosage{" "}
                                      </div>
                                    </th>
                                    <th class="col-md-2">
                                      <div class="dentalProCost">Intake</div>
                                    </th>
                                    <th class="col-md-2">
                                      <div class="dentalProCost">Qty</div>
                                    </th>
                                    <th class="col-md-2">
                                      <div class="dentalProCost">Days</div>
                                    </th>
                                    <th class="col-md-3">
                                      <div class="dentalProCost">
                                        Start Date
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr class="groupBrdr">
                                    <td class="col-md-3">
                                      <div class="dentalProName">
                                        {" "}
                                        CINCLOX-S inj vial{" "}
                                      </div>
                                    </td>
                                    <td class="col-md-2">
                                      <div
                                        class="dentalProCost"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        Three Times A Day - 0 - 0 - 0
                                      </div>
                                    </td>
                                    <td class="col-md-2">
                                      <div
                                        class="dentalProCost"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        Before Food
                                      </div>
                                    </td>
                                    <td class="col-md-2">
                                      <div class="dentalProCost"></div>
                                    </td>
                                    <td class="col-md-2">
                                      <div class="dentalProCost"></div>
                                    </td>
                                    <td class="col-md-2">
                                      <div
                                        class="dentalProCost"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        13-Jul-2020
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div class="treatmentPlanDate">
                                <div class="treatmentComplaintNoted ">
                                  Noted by{" "}
                                  <span>Dr.Ortho Paid Doc (Ortho)</span>
                                </div>
                                <div class="dentalSelect">
                                  <div class="dentalProNote">
                                    Lorem Ipsum is simply dummy text of the
                                    printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy
                                    text ever since the 1500s, when an unknown
                                    printer took a galley of type and scrambled
                                    it to make a type specimen book. It has
                                    survived not only five centuries
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="timelineList labOrder">
                    <div class="timelineListDate">
                      <span class="timelineDate">08</span>
                      <div class="timelineDateCvr">
                        <span class="timelineMnthDate"> 2020</span>
                        <span class="timelineDay">July</span>
                      </div>
                    </div>
                    <div class="treatmentTimelineBlock">
                      <div class="timelineListContent treatmentplan-div">
                        <span class="timelineArrow"></span>{" "}
                        <span class="timelineCircle"></span>
                        <div class="timelineListName">
                          <img alt="" src={treatmentplan} />{" "}
                          <span>Lab Order</span>
                        </div>
                        <div class="treatmentTable treatmentpadZero">
                          <div class="treatmentPlanList">
                            <div class="treatmentTable ">
                              <table>
                                <thead>
                                  <tr>
                                    <th
                                      class="col-md-3"
                                      style={{ minWidth: "200px" }}
                                    >
                                      <div class="dentalProName">
                                        TEST NAME{" "}
                                      </div>
                                    </th>
                                    <th class="col-md-2">
                                      <div class="dentalProCost">SPECIMEN</div>
                                    </th>
                                    <th class="col-md-2">
                                      <div class="dentalProDis">TEST TYPE</div>
                                    </th>
                                    <th class="col-md-2">
                                      <div class="dentalProDis">AMOUNT</div>
                                    </th>
                                    <th class="col-md-2">
                                      <div class="dentalProTotal">NOTES</div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr class="groupBrdr">
                                    <td class="col-md-3">
                                      <div class="dentalProName">ECG </div>
                                    </td>
                                    <td class="col-md-2">
                                      <div
                                        class="dentalProCost"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        blood
                                      </div>
                                    </td>
                                    <td class="col-md-2">
                                      <div
                                        class="dentalProDis"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        Hepatitis
                                      </div>
                                    </td>
                                    <td class="col-md-2">
                                      <div class="dentalProDis">1500.00</div>
                                    </td>
                                    <td class="col-md-2">
                                      <div class="dentalProTotal"></div>
                                    </td>
                                  </tr>
                                  <tr class="dentalProPlaned">
                                    <input
                                      type="hidden"
                                      name="hidTreatmentIds01"
                                      id="hidTreatmentIds01"
                                      value=""
                                    />
                                    <td colspan="2">
                                      <div class="plannedBy">
                                        Noted by{" "}
                                        <span>Dr. Ortho Paid Doc (Ortho)</span>
                                      </div>
                                    </td>
                                    <td colspan="3">
                                      <div class="estimatedPrice">&nbsp;</div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </>
              ) : (
                <>
                  <div class="treatmentCommonTxtWrap">
                    History Not Available
                  </div>
                  {/* {this.state.subFlag === true &&
                  this.state.subFlagId === "treatmentPlan" ? (
                    <div class="treatmentCommonTxtWrap">
                      Treatment Plans Not Available
                    </div>
                  ) : this.state.subFlag === true &&
                    this.state.subFlagId === "paymentData" ? (
                    <div class="treatmentCommonTxtWrap">
                      Payment Plans Not Available
                    </div>
                  ) : this.state.subFlag === true &&
                    this.state.subFlagId === "paymentData" ? (
                    <div class="treatmentCommonTxtWrap">
                      Payment Plans Not Available
                    </div>
                  ) : this.state.subFlag === true &&
                    this.state.subFlagId === "paymentData" ? (
                    <div class="treatmentCommonTxtWrap">
                      Payment Plans Not Available
                    </div>
                  ) : (
                    <div class="treatmentCommonTxtWrap">
                      History Not Available
                    </div>
                  )} */}
                </>
              )}
            </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    );
  }
}
