import React, { Component } from 'react';
import { apiCalling } from "../apiService";
import $ from "jquery";

export default class ExperienceDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {

      categoryData: [],

    }
    this.saveHandle = this.saveHandle.bind(this);
  }

  saveHandle() {

    let errormessage="";
    var experience = "";
    var catAppnddData = []
    for (var j = 0; j < this.state.categoryData.length; j++) {
      var a = this.state.categoryData[j].description
      catAppnddData.push(a)
      experience = catAppnddData.toString().replace(/[\[\]']+/g, '')
    }

      if($("#experience").val() === "" && this.state.categoryData.length === 0 ){
        errormessage="Please Enter Experience Description"
        $("#experience").focus();
      }else{  
      }
      if(errormessage==""){
      const saveData = {
        method: 'POST',
        body: JSON.stringify({
          functionName: "updateDoctorDetails",
          siteId: window.sessionStorage.getItem("siteId"),
          doctorId: window.sessionStorage.getItem("userId"),
          emailId: window.sessionStorage.getItem("emailId"),
          subFunctionName: "updateExperienceDetails",
          description: experience.toString()
        })
      }
      $(".loader").show();
      apiCalling(saveData).then((data) => {
        $(".loader").hide();
        if (data.success == "1") {
          alert("Details updated successfully")
          window.location.reload(false);
        }
      })
    }else{
      $("#messageExperienceEdit").text(errormessage);
      $("#messageExperienceEdit").css("color", "red");
        }
  }

  componentDidMount() {
    this.getDetails();
  }

  getDetails() {

    const getDetails = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDoctorDetails",
        siteId: window.sessionStorage.getItem("siteId"),
        doctorId: window.sessionStorage.getItem("userId"),
      }),
    }
    apiCalling(getDetails).then((data) => {
      if (data.success === "1" && data.doctorData[0].experienceData.length > 0) {
        this.setState({ categoryData: data.doctorData[0].experienceData });
      }
    });
  }

  handleAppend() {
    let errormessage="";
    if($("#experience").val() === ""){
      errormessage="Please Enter Experience Description"
      $("#experience").focus();
    }else{  
    }
    if(errormessage==""){
      var text = {
        "description": $("#experience").val()
      }
      $('#experience').val("");
      this.state.categoryData.push(text)
      this.setState({ categoryData: this.state.categoryData })
    }else{
      $("#messageExperienceEdit").text(errormessage);
      $("#messageExperienceEdit").css("color", "red");
        }
  }

  categoryRemove(index) {
    const list = this.state.categoryData;
    list.splice(index, 1)
    this.setState({ categoryData: list })
  }

  // RemoveData(index){
  //   const data = this.state.experienceList;
  //   data.splice(index,0)
  //   alert(data)
  //   this.setState({categoryData:data})
  //   console.log("tttttt",this.state.categoryData)
  // }

  render() {
    return (
      <div class="row px-2">
        <div class="col-sm-12">
        <div class="col-sm-12 my-2">
          <p id="messageExperienceEdit" style={{textAlign:'center'}}></p>
          </div>
      </div>
        <div class="col-sm-12  px-0  px-sm-3">
          <div class="row my-2">
            <div class="col-sm-7 mb-2">
               <input type="text" class="input-design w-75" placeholder="Experience Description" id="experience" name="experience" /> 
               <button class="btn-icons" id="btnSearch"><span class=" fa fa-plus" onClick={() => this.handleAppend()}></span></button> 
              </div>
          </div>
        </div>
        <div class="col-sm-12  px-0  px-sm-3">
          <h6 class="border-bottom pb-2">Experience In</h6>
        </div>
        <div class="col-sm-12  px-0  px-sm-3">
          <div class="row my-2">
            {this.state.categoryData.map((obj, index) => (
              <div class="col-sm-7 mb-2" id={index}>
                <input disabled="disabled" type="text" class="input-design w-75" value={obj.description} />
                <span class=" fa fa-trash color-maintext ml-5" onClick={(e) => this.categoryRemove(index)} ></span>
                </div>
            ))}
          </div>
        </div>
        <div className="col-sm-12">
          <input type="submit" value="Save" className="formButton" onClick={() => this.saveHandle()}></input>
        </div>
      </div>
    )
  }
}
