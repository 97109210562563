import React, { Component, Suspense } from "react";
import $ from "jquery";

export default class HospitalInfomation extends Component {
  handleImageUpload(e, mode) {
    if (typeof e.target.files[0] !== "undefined") {
      let file = e.target.files[0];
      let fileType = file["type"];
      let validImageTypes = ["image/jpg", "image/jpeg", "image/png"];

      if (!validImageTypes.includes(fileType)) {
        alert("Invalid file type");
        $("#" + mode + "File").val(null);
        if (mode === "attachImg") {
          $("#image").attr("src", "");
          $("#imgDiv").hide();
        }
      } else {
        var fileObj = e.target.files[0];
        let reader = new FileReader();
        var img = new Image();
        img.src = window.URL.createObjectURL(fileObj);
        img.onload = function () {
          reader.onload = function (e) {
            if (mode === "attachImg") {
              $("#image").attr("src", e.target.result);
              $("#imgDiv").show();
            }
          };

          reader.readAsDataURL(fileObj);
        };

        if (mode === "attachImg") {
          this.props.setFileSignature(fileObj);
          //this.setState({ fileSignature: fileObj });
        }
      }
    }
  }

  displayEmail() {
    var name = $("#hospitalEmail").val();
    $("#primaryEmailIds").val(name);
    $("#adminUserName").val(name);
  }
  hospitalName() {
    var name2 = $("#hospitalName").val();
    $("#adminDisplayName").val(name2);
  }
  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead clear_float">
        <Suspense fallback={<div></div>}>
          <section>
            <div className="container mb-5 p-0">
              <div>
                <div className="hspInformation-wraper">
                  <h2 className="hspInfoSubhead">Hospital Information</h2>

                  <div className="hspinfo-contentdiv">
                    <label>Hospital Id</label>

                    <input
                      type="text"
                      name="hospitalId"
                      id="hospitalId"
                      value={this.props.hospitalData.hospitalId}
                      className="hspInfofieldinput"
                      readOnly
                    />
                  </div>

                  <div className="hspinfo-contentdiv">
                    <label>
                      Hospital Name <span className="hspifoMantory">*</span>
                    </label>

                    <input
                      type="text"
                      name="hospitalName"
                      id="hospitalName"
                      className="hspInfofieldinput"
                      placeholder="Hospital Name"
                      value={this.props.hospitalData.hospitalName}
                      onInput={this.props.changeHandler}
                      onKeyUp={() => this.hospitalName()}
                    />
                  </div>

                  {/* <div className="superadminDivBoxcover"> */}

                  <div className="hspinfo-contentdiv">
                    <label>Image(jpeg,png)</label>

                    <div className="hspinfoimgaeuploadDiv">
                      <div className="hspinfo-btnupload">
                        <span for="attachImg">Upload</span>

                        <input
                          type="file"
                          id="attachImg"
                          accept="image/*"
                          name="attachImg"
                          className="hspinfoInputfieldupload"
                          onChange={(e) =>
                            this.handleImageUpload(e, "attachImg")
                          }
                        />
                      </div>

                      <div id="imgDiv" style={{ display: "block" }}>
                        <img height="30" width="35" id="image" src={this.props.hospitalImage}></img>
                      </div>
          
                      <hidden
                        id="hidSIgnatureName"
                        name="hidSIgnatureName"
                      ></hidden>
                    </div>
                  </div>

                  <div className="hspinfo-contentdiv">
                    <label>
                      Email<span className="hspifoMantory">*</span>
                    </label>

                    <input
                      type="text"
                      id="hospitalEmail"
                      name="hospitalEmail"
                      className="hspInfofieldinput"
                      placeholder="Email"
                      value={this.props.hospitalData.hospitalEmail}
                      onKeyUp={() => this.displayEmail()}
                      onInput={this.props.changeHandler}
                    />
                  </div>

                  <div className="hspinfo-contentdiv">
                    <label>
                      Address<span className="hspifoMantory">*</span>
                    </label>

                    <input
                      type="text"
                      id="hospitalAddress"
                      name="hospitalAddress"
                      value={this.props.hospitalData.hospitalAddress}
                      onInput={this.props.changeHandler}
                      className="hspInfofieldinput"
                      placeholder="Address"
                    />
                  </div>

                  <div className="hspinfo-contentdiv">
                    <label>
                      Country<span className="hspifoMantory">*</span>
                    </label>

                    <select
                      name="cmbCountry"
                      id="cmbCountry"
                      className="hspInfofieldinput Infoinputwidthdoctr"
                      value={this.props.hospitalData.cmbCountry}
                      onChange={(e) => {
                        this.props.changeHandler(e);
                        this.props.getCountryList();
                      }}
                    >
                      <option value="1">India </option>
                    </select>
                  </div>

                  <div className="hspinfo-contentdiv">
                    <label>
                      State<span className="hspifoMantory">*</span>
                    </label>

                    <select
                      name="stateId"
                      id="stateId"
                      className="hspInfofieldinput Infoinputwidthdoctr"
                      value={this.props.hospitalData.stateId}
                      onChange={(e) => {
                        this.props.changeHandler(e);
                        this.props.getCountryList(e.target.value, "0", "0");
                      }}
                    >
                      <option value="0">Select </option>

                      {this.props.hospitalData.stateListData.map(
                        (stateListDataObj, i) => {
                          return (
                            <option
                              key={stateListDataObj.stateId}
                              value={stateListDataObj.stateId}
                            >
                              {stateListDataObj.stateName}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>

                  <div className="hspinfo-contentdiv">
                    <label>
                      City<span className="hspifoMantory">*</span>
                    </label>

                    <select
                      name="cityId"
                      id="cityId"
                      value={this.props.hospitalData.cityId}
                      className="hspInfofieldinput Infoinputwidthdoctr"
                      onChange={(e) => {
                        this.props.changeHandler(e);
                        this.props.getCountryList("", "", "0");
                      }}
                    >
                      <option value="0">Select </option>

                      {this.props.hospitalData.cityListData.map(
                        (cityListDataObj, i) => {
                          return (
                            <option
                              key={cityListDataObj.cityId}
                              value={cityListDataObj.cityId}
                            >
                              {cityListDataObj.cityName}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>

                  <div className="hspinfo-contentdiv">
                    <label>
                      Sub City<span className="hspifoMantory"></span>
                    </label>

                    <select
                      name="cmbSubCity"
                      id="cmbSubCity"
                      value={this.props.hospitalData.subCity}
                      onChange={this.props.changeHandler}
                      className="hspInfofieldinput Infoinputwidthdoctr"
                    >
                      <option value="0">Select </option>

                      {this.props.hospitalData.subCitylistData.map(
                        (subCitylistDataObj, i) => {
                          return (
                            <option
                              key={subCitylistDataObj.subCityId}
                              value={subCitylistDataObj.subCityId}
                            >
                              {subCitylistDataObj.subCityName}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>

                  <div className="hspinfo-contentdiv">
                    <label>
                      Pin Code<span className="hspifoMantory">*</span>
                    </label>

                    <input
                      type="text"
                      id="pinCode"
                      name="pinCode"
                      value={this.props.hospitalData.pinCode}
                      onInput={this.props.changeHandler}
                      className="hspInfofieldinput"
                      placeholder="Pin Code"
                    />
                  </div>

                  <div className="hspinfo-contentdiv">
                    <label>
                      Phone<span className="hspifoMantory">*</span>
                    </label>

                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      value={this.props.hospitalData.phone}
                      onInput={this.props.changeHandler}
                      className="hspInfofieldinput"
                      placeholder="Phone"
                    />
                  </div>

                  <div className="hspinfo-contentdiv">
                    <label>
                      Primary Email Ids<span className="hspifoMantory">*</span>
                    </label>

                    <input
                      type="text"
                      id="primaryEmailIds"
                      name="primaryEmailIds"
                      value={this.props.hospitalData.primaryEmailIds}
                      onInput={this.props.changeHandler}
                      className="hspInfofieldinput"
                      placeholder="Primary Email Ids"
                    />
                  </div>

                  <div className="hspinfo-contentdiv">
                    <label>
                      Category<span className="hspifoMantory">*</span>
                    </label>

                    <select
                      name="categoryId"
                      id="categoryId"
                      value={this.props.hospitalData.categoryId}
                      onChange={this.props.changeHandler}
                      className="hspInfofieldinput Infoinputwidthdoctr"
                    >
                      <option value="0">Select </option>

                      {this.props.hospitalData.categoryData.map(
                        (categoryDataObj, i) => {
                          return (
                            <option
                              key={categoryDataObj.categoryId}
                              value={categoryDataObj.categoryId}
                            >
                              {categoryDataObj.categoryName}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>

                  <div className="hspinfo-contentdiv">
                    <label>
                      Credential<span className="hspifoMantory">*</span>
                    </label>

                    <select
                      name="credentialId"
                      id="credentialId"
                      className="hspInfofieldinput Infoinputwidthdoctr"
                      value={this.props.hospitalData.credentialId}
                      onChange={this.props.changeHandler}
                    >
                      <option value="0">Select </option>

                      {this.props.hospitalData.CredentialData.map(
                        (CredentialDataObj, i) => {
                          return (
                            <option
                              key={CredentialDataObj.credentialId}
                              value={CredentialDataObj.credentialId}
                            >
                              {CredentialDataObj.credentialName}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>

                  <div className="hspinfo-contentdiv">
                    <label>
                      Type<span className="hspifoMantory">*</span>
                    </label>

                    <select
                      name="typeId"
                      id="typeId"
                      className="hspInfofieldinput Infoinputwidthdoctr"
                      value={this.props.hospitalData.typeId}
                      onChange={this.props.changeHandler}
                    >
                      <option value="0">Select </option>

                      {this.props.hospitalData.getTypeData.map(
                        (getTypeDataObj, i) => {
                          return (
                            <option
                              key={getTypeDataObj.typeId}
                              value={getTypeDataObj.typeId}
                            >
                              {getTypeDataObj.typeDescription}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>

                  <div className="hspinfo-contentdiv">
                    <label>
                      Classification<span className="hspifoMantory">*</span>
                    </label>

                    <select
                      multiple
                      name="classificationIds"
                      id="classificationIds"
                      className="hspInfofieldinput Infoinputwidthdoctr"
                      value={this.props.hospitalData.classificationIds}
                      onChange={this.props.changeHandlerMulti}
                    >
                      {this.props.hospitalData.ClassificataionData.map(
                        (ClassificataionDataObj, i) => {
                          return (
                            <option
                              key={ClassificataionDataObj.classificationId}
                              value={ClassificataionDataObj.classificationId}
                            >
                              {ClassificataionDataObj.classificationName}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>

                  <div className="hspinfo-contentdiv">
                    <label>
                      Payment<span className="hspifoMantory">*</span>
                    </label>

                    <select
                      multiple
                      name="paymentTypeIds"
                      id="paymentTypeIds"
                      className="hspInfofieldinput Infoinputwidthdoctr"
                      value={this.props.hospitalData.paymentTypeIds}
                      onChange={this.props.changeHandlerMulti}
                    >
                      {this.props.hospitalData.getPaymentTypeData.map(
                        (getPaymentTypeDataObj, i) => {
                          return (
                            <option
                              key={getPaymentTypeDataObj.paymentTypeId}
                              value={getPaymentTypeDataObj.paymentTypeId}
                            >
                              {getPaymentTypeDataObj.paymentType}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>

                  <div className="hspinfo-contentdiv">
                    <label>
                      Worktime Filter<span className="hspifoMantory">*</span>
                    </label>

                    <select
                      multiple
                      name="workTimeFilter"
                      id="workTimeFilter"
                      className="hspInfofieldinput Infoinputwidthdoctr"
                      value={this.props.hospitalData.workTimeFilter}
                      onChange={this.props.changeHandlerMulti}
                    >
                      {this.props.hospitalData.timeFilterData.map(
                        (timeFilterDataObj, i) => {
                          return (
                            <option
                              key={timeFilterDataObj.timeFilterId}
                              value={timeFilterDataObj.timeFilterId}
                            >
                              {timeFilterDataObj.timeFilterName}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Suspense>

        <Suspense fallback={<div></div>}></Suspense>
      </div>
    );
  }
}
