import React, { Component, Suspense } from "react";
import Header from "../Reliance/header";
import Footer from "../Reliance/footer";

export default class Pharmadashboard extends Component {
  state = {};
  render() {
    return (
      <div className="dashboard-doctorOuter">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />

            <div className="container mt-5">
              <div>
                <ul className="breadcrum-adminMangmnt">
                  <li>
                    <a href="/admin/dashboard">Dashboard</a>
                  </li>
                  <li>
                    <a href="" className="current">Pharma Ad Approval</a>
                  </li>
                 
                </ul>
              </div>
             
              <div className="doctorWork-dashboard">
                    <div className="adminMangemnt-dashboard">
                        <div className="admin-dc-dashBtns">
                            <a href="/adminManagement/pharmadashboardList/repApprovalList"><div>Rep Approval</div></a>
                        </div>        
                    </div>
                </div>
            </div>
                      
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}


