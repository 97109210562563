import React, { Component, Suspense } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import { apiCalling } from "../apiService";
import { getSiteData } from "../layout/getSiteDetails";
import bolgtemp from "../image/icons/4.jpg";
import renderHTML from "react-render-html";
import { Helmet } from "react-helmet";
const folderName = window.sessionStorage.getItem("homeName");

const Home = React.lazy(() => import("../" + folderName + "/home"));
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class HealthblogPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectCategotyList: [],
      resultData: [],
      showMessage: [],
    };
  }

  getSiteDataFunction() {
    const loginRequest = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getSiteDetails",
        siteId: "",
        siteUrl: window.location.host,
      }),
    };
    apiCalling(loginRequest).then((data) => {
      
       if (data.success === "1") { 
         var location = data.siteDetailsData[0].siteData[0].siteName;
         let is_theme_based = data.siteDetailsData[0].siteData[0].is_theme_based;
         let project_folder = data.siteDetailsData[0].siteData[0].project_folder;
         var folder = "purpleHealth";
         if (is_theme_based === false && project_folder !== "") {
           folder = project_folder;
         } else {
           folder = data.siteDetailsData[0].siteData[0].themeName;
         }        
         window.sessionStorage.setItem("siteId", data.siteDetailsData[0].siteData[0].id);
         window.sessionStorage.setItem("themeId", data.siteDetailsData[0].siteData[0].themeId);        
         window.sessionStorage.setItem("themeVersion", "0");
         window.sessionStorage.setItem("homeName", folder);
         window.sessionStorage.setItem("reload", "1");
         window.sessionStorage.setItem("theme_custom_color", data.siteDetailsData[0].siteData[0].theme_custom_color);
         window.sessionStorage.setItem("contact_emailId", data.siteDetailsData[0].siteData[0].emailId);
         window.sessionStorage.setItem("contact_no", data.siteDetailsData[0].siteData[0].contact_no);
         window.sessionStorage.setItem("fav_icon",data.siteDetailsData[0].siteData[0].fav_icon);
         window.sessionStorage.setItem("seo_description",data.siteDetailsData[0].siteData[0].seo_description);
         window.sessionStorage.setItem("seo_keywords",data.siteDetailsData[0].siteData[0].seo_keywords);
         window.sessionStorage.setItem("seo_title",data.siteDetailsData[0].siteData[0].seo_title);
         window.sessionStorage.setItem("slug",data.siteDetailsData[0].siteData[0].slug);
         window.sessionStorage.setItem("googleId",data.siteDetailsData[0].siteData[0].google_analytics_script_id);
         window.sessionStorage.setItem("siteName", data.siteDetailsData[0].siteData[0].siteName);
         window.sessionStorage.setItem("book_appointment_terms", data.siteDetailsData[0].siteData[0].book_appointment_terms);
         window.sessionStorage.setItem("video_chat_terms", data.siteDetailsData[0].siteData[0].video_chat_terms);
         window.sessionStorage.setItem("hospital_working_time", data.siteDetailsData[0].siteData[0].hospital_working_time);
         window.sessionStorage.setItem("site_url", data.siteDetailsData[0].siteData[0].site_url);
         window.sessionStorage.setItem("title", data.siteDetailsData[0].siteData[0].title);
         window.sessionStorage.setItem("fbPixel",data.siteDetailsData[0].siteData[0].fbPixel);
         window.sessionStorage.setItem("hasHealthTracker",data.siteDetailsData[0].siteData[0].hasHealthTracker);
         window.sessionStorage.setItem("siteLogo",data.siteDetailsData[0].siteData[0].logo_path);
         window.sessionStorage.setItem(          "groupHospitalAdminId",
           data.siteDetailsData[0].groupHospitalAdminData[0].groupHospitalAdminId
         );
         window.sessionStorage.setItem(
           "headerData",
           JSON.stringify(data.siteDetailsData[0].headerData)
         );
        
           window.sessionStorage.setItem(
             "footerData",JSON.stringify(data.siteDetailsData[0].footerData));
       
 
         const hosDataVar = JSON.stringify(data.siteDetailsData[0].hospitalData);
         window.sessionStorage.setItem(
           "siteServices",
           JSON.stringify(data.siteDetailsData[0].services)
         );
         if (hosDataVar) {
           window.sessionStorage.setItem(
             "footerhospitalData",
             JSON.stringify(data.siteDetailsData[0].hospitalData)
           );
 
           window.sessionStorage.setItem(
             "footerHospitalName",
             data.siteDetailsData[0].hospitalData.hospitalName
           );
           window.sessionStorage.setItem(
             "footerHospitalcity",
             data.siteDetailsData[0].hospitalData.city
           );
           window.sessionStorage.setItem(
             "footerHospitaladdress",
             data.siteDetailsData[0].hospitalData.address
           );
           window.sessionStorage.setItem(
             "footerHospitalstateName",
             data.siteDetailsData[0].hospitalData.stateName
           );
           window.sessionStorage.setItem(
             "footerHospitalzipCode",
             data.siteDetailsData[0].hospitalData.zipCode
           );
          
           
         }
         if (data.siteDetailsData[0].themeSectionList) {
           for (
             let i = 0;
             i < data.siteDetailsData[0].themeSectionList.length;
             i++
           ) {
             window.sessionStorage.setItem(
               data.siteDetailsData[0].themeSectionList[i].themeSectionName,
               JSON.stringify(
                 data.siteDetailsData[0].themeSectionList[i].themeSectionContent
               )
             );
           } 
         }       
         window.sessionStorage.setItem(
           "doctorDetails",
           JSON.stringify(data.siteDetailsData[0].doctorDetails)
         );
 
        // alert("root")
         window.location.reload();
        //window.location.href="/maintenance"
         
       }
     });
  }

  componentDidMount() {
    
    if (folderName === null) {    
      this.getSiteDataFunction();    
    
      }
    this.categorySelectList();

    if (this.props.match.params.categoryId !== "0") {
      this.categorySearchByID(this.props.match.params.categoryId);
    } else {
      this.loadBlogs();
    }
  }

  categorySelectList() {
    $(".loader").show();
    const saveField = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHealthInfoCommonData",
        siteId: window.sessionStorage.getItem("siteId"),
      }),
    };
    apiCalling(saveField).then((data) => {
      $(".loader").hide();
      if (data.success == "1") {
        this.setState({
          selectCategotyList: data.result,
        });
      }
    });
  }
  loadBlogs() {
    const dataList = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDoctorHealthArticles",
        siteId: window.sessionStorage.getItem("siteId"),
        categoryId: "",
      }),
    };
    $(".loader").show();
    apiCalling(dataList).then((data) => {
      $(".loader").hide();
      if (data.success == "1" && data.result.length > 0) {
        this.setState({ resultData: data.result });
      } else if (data.success == "0") {
        this.setState({ showMessage: data.errorMessage });
      }
    });
  }
  categorySearch(obj) {
    const dataList = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDoctorHealthArticles",
        siteId: window.sessionStorage.getItem("siteId"),
        categoryId: obj.categoryId,
      }),
    };
    $(".loader").show();

    apiCalling(dataList).then((data) => {
      $(".loader").hide();
      if (data.success == "1" && data.result.length > 0) {
        this.setState({ resultData: data.result });
      } else if (data.success == "0") {
        this.setState({ showMessage: data.errorMessage });
      }
    });
    $(".category-sub").removeClass("category-sub-active");
    $("#healthBlogListId" + obj.categoryId).addClass("category-sub-active");
    // $("html,body").animate({"scrollTop": $("#blog-detailsId").offset().top})
  }
  categorySearchByID(id) {
    const dataList = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDoctorHealthArticles",
        siteId: window.sessionStorage.getItem("siteId"),
        categoryId: id,
      }),
    };
    $(".loader").show();
    apiCalling(dataList).then((data) => {
      $(".loader").hide();
      if (data.success == "1" && data.result.length > 0) {
        this.setState({ resultData: data.result });
      } else if (data.success == "0") {
        this.setState({ showMessage: data.errorMessage });
      }
    });
  }
  blogCategoryList() {
    $(".category-list").toggleClass("category-list-active");
  }
  srcTypeImage(ev) {
    ev.target.src = bolgtemp;
  }

  render() {
    const urlPath = window.location.origin.toString();
    return (
      <div className="pamba-wrapper">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <Helmet>
          <title>
            {"HealthInfo | " + window.sessionStorage.getItem("siteName")=== null ? "" :window.sessionStorage.getItem("siteName")}
          </title>
          <meta
            name="description"
            content={
              "HealthInfo | " + window.sessionStorage.getItem("siteName")=== null ? "" :window.sessionStorage.getItem("siteName")
            }
          />
        </Helmet>
        <div className="container blog-wrapper">
          <h1 className="telehealthfeaturehead text-center"> Blog </h1>
          <div className="row mt-1">
            <div className="col-lg-3 col-md-4">
              <div className="category-main">
                <div className="category-list">
                  {this.state.selectCategotyList.map((obj) => (
                    <a href="#">
                      <div
                        className="category-sub"
                        id={"healthBlogListId" + obj.categoryId}
                        onClick={() => this.categorySearch(obj)}
                      >
                        {obj.categoryName}
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            </div>
            {this.state.resultData.length > 0 ? (
              <div className="col-lg-9 col-md-8" id="blog-detailsId">
                <div className="row mt-1 mt-md-0">
                  {this.state.resultData.map((data) => {
                    var slug = data.slug;
                    slug = slug
                      .replace(/\s+/g, "-")
                      .replace("/", "-")
                      .replace("|", "-");
                    slug = slug.replace("--", "-").replace("---", "-");
                    return (
                      <div className="blog-listbox">
                        <div className="blog-listImgDiv">
                          <img
                            onError={this.srcTypeImage}
                            src={data.imageName}
                            alt="Blog Image Name"
                            className="img-fluid blog-listImg"
                          ></img>
                        </div>
                        <div className="blog-list-detils">
                          <div className="blogtag-name">
                            {data.healthInfoTitle}
                          </div>
                          <div className="bloguserList-text">
                            {data.shortDescription}{" "}
                          </div>
                          {/* <div className="blogtag-main">
                          <div className="blogtag-sub"> Remote work</div>
                          <div className="blogtag-sub"> Webinar</div>
                        </div> */}
                          <Link
                            to={"/HealthBlog/" + slug + "/" + data.healthInfoId}
                            className="readmoreBlog"
                          >
                            Read more
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div
                className="col-lg-12 col-md-12 py-5"
                id="blog-detailsId"
                style={{ textAlign: "center" }}
              >
                <p className="error-msg text-center mb-0">
                  {" "}
                  {this.state.showMessage}{" "}
                </p>
              </div>
            )}
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
