import React, { Component, Suspense } from "react";
import $ from "jquery";
import { apiCalling } from "../../apiService";
import Edit from "../../image/icons/edit.png";
import IcoAddFooterLink from "../../image/icons/plus.png";
import IcoAddHeaderLink from "../../image/icons/plus.png";
import IcoAddPromoCode from "../../image/icons/plus.png";
import Pagination from "react-js-pagination";

class siteSettingsSiteList extends Component {
  state = {
    addOrEditSite: [],
    totalCount: 0,
    activePage: 1,
    offset: "0",
    maxRow: "10",
    cmbSelect: "",
    searchKeyWord: "",
  };

  componentDidMount() {
    this.getSiteList();
  }

  async handlePageChange(pageNumber) {
    await this.setState({
      activePage: pageNumber,
      offset: (parseInt(pageNumber) - 1) * 10,
    });

    this.getSiteList();
  }

  handleCmbSerchChange = () => {
    var cmbSelectVal = $("#cmbSerch").val() !== "0" ? $("#cmbSerch").val() : "";
    // $("#searchKeyWord").val("")
    $("#searchKeyWord").prop("disabled", cmbSelectVal !== "" ? false : true);
    this.setState({ cmbSelect: cmbSelectVal, searchKeyWord: "" });
    $("#searchKeyWord").focus();
  };

  changeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  getSiteList() {
    $(".loader").show();
    const addOrEditSite = {
      method: "POST",
      body: JSON.stringify({
        functionName: "addOrEditSite",
        cmbSelect: this.state.cmbSelect,
        searchKeyWord: this.state.searchKeyWord,
        max: this.state.maxRow,
        Offset: this.state.offset,
      }),
    };

    apiCalling(addOrEditSite).then((data) => {
      if (data.success === "1") {
        var siteData = data.siteData;
        this.setState({ addOrEditSite: siteData, totalCount: data.totalCount });
      } else if (data.success === "0") {
        this.setState({ addOrEditSite: [], totalCount: 0 });
      }
      $(".loader").hide();
    });
  }

  renderSiteData() {
    const navigation = [
      { pageName: "Dashboard", url: "/admin/dashboard" },
      // { pageName: "Admin Management", url: "/admin/dashboardAdmin" },
      { pageName: "Site Settings", url: "/adminManagement/SiteSettings" },
      {
        pageName: "Site Settings List",
        url: "/adminManagement/SiteSettings-AddEdit",
        current: true,
      },
    ];

    const navigationPromoCode = [
      { pageName: "Dashboard", url: "/admin/dashboard" },
      // { pageName: "Admin Management", url: "/admin/dashboardAdmin" },
      { pageName: "Site Settings", url: "/adminManagement/SiteSettings" },
      {
        pageName: "Site Settings List",
        url: "/adminManagement/SiteSettings-AddEdit",
      },
      { pageName: "Add Promo Code", url: "#", current: true },
    ];

    const navigationFooter = [
      { pageName: "Dashboard", url: "/admin/dashboard" },
      // { pageName: "Admin Management", url: "/admin/dashboardAdmin" },
      { pageName: "Site Settings", url: "/adminManagement/SiteSettings" },
      {
        pageName: "Site Settings List",
        url: "/adminManagement/SiteSettings-AddEdit",
      },
      { pageName: "Add Footer", url: "#", current: true },
    ];

    const navigationHeader = [
      { pageName: "Dashboard", url: "/admin/dashboard" },
      // { pageName: "Admin Management", url: "/admin/dashboardAdmin" },
      { pageName: "Site Settings", url: "/adminManagement/SiteSettings" },
      {
        pageName: "Site Settings List",
        url: "/adminManagement/SiteSettings-AddEdit",
      },
      { pageName: "Add Header", url: "#", current: true },
    ];

    if (this.state.addOrEditSite.length > 0) {
      return this.state.addOrEditSite.map((site) => (
        <tr key={site.siteId}>
          <td>{site.siteId}</td>
          <td>{site.siteName}</td>
          <td>
            <a href={site.siteUrl} target="_blank">{site.siteUrl}</a>
          </td>
          <td>{site.siteTitle}</td>
          <td>{site.contactNo}</td>
          <td>{site.email}</td>
          <td style={{ width: "10vw" }} className="nowrap ph_siteedit">
            <div>
              <span>
                <a
                  className="mr-2"
                  onClick={() =>
                    this.props.changeMode(
                      "edit",
                      navigation,
                      site.siteId,
                      "Site Settings List"
                    )
                  }
                >
                  <img src={Edit} alt="Edit" width="18" className="img-fluid" />
                </a>
              </span>
              <span>
                <a
                  className="mr-2"
                  onClick={() =>
                    this.props.changeMode(
                      "addNewHeaderLink",
                      navigationHeader,
                      site.siteId,
                      "Header Link",
                      site.groupHospitalAdminId
                    )
                  }
                  title="Add Header Links"
                >
                  <img
                    src={IcoAddHeaderLink}
                    alt="Add Header Links"
                    width="22"
                    className="img-fluid"
                  />
                </a>
              </span>
              <span>
                <a
                  className="mr-2"
                  onClick={() =>
                    this.props.changeMode(
                      "addNewFooterLink",
                      navigationFooter,
                      site.siteId,
                      "Footer Link",
                      site.groupHospitalAdminId
                    )
                  }
                  title="Add Footer Link"
                >
                  <img
                    src={IcoAddFooterLink}
                    alt="Add Footer Link"
                    width="22"
                    className="img-fluid"
                  />
                </a>
              </span>
              <span>
                <a
                  className="mr-2"
                  onClick={() =>
                    this.props.changeMode(
                      "addNewPromoCode",
                      navigationPromoCode,
                      site.siteId,
                      "Promo Code",
                      site.groupHospitalAdminId
                    )
                  }
                  title="Add Promo Code"
                >
                  <img
                    src={IcoAddPromoCode}
                    alt="Add Promo Code"
                    width="22"
                    className="img-fluid"
                  />
                </a>
              </span>
            </div>
          </td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan="7" className="text-center">
            No Data Found
          </td>
        </tr>
      );
    }
  }

  render() {
    const navigationEdit = [
      { pageName: "Dashboard", url: "/admin/dashboard" },
      // { pageName: "Admin Management", url: "/admin/dashboardAdmin" },
      { pageName: "Site Settings", url: "/adminManagement/SiteSettings" },
      {
        pageName: "Site Settings List",
        url: "/adminManagement/SiteSettings-AddEdit",
        current: true,
      },
    ];

    return (
      <div className="HosptlMangemntTable">
        <div className="AddNewHspbtnDiv">
          <a
            className="addnewhspbtn"
            onClick={() =>
              this.props.changeMode(
                "addNew",
                navigationEdit,
                0,
                "Site Settings List"
              )
            }
          >
            Add New Site
          </a>
        </div>

        <div className="tableHeader-HspMangemnt">
          <div className="selectField-HspMangmnt">
            <select
              className="slecthsp"
              id="cmbSerch"
              onChange={() => this.handleCmbSerchChange()}
            >
              <option value="0">Search In</option>
              <option value="siteId">Site Id</option>
              <option value="siteName">Site Name</option>
              <option value="siteUrl">Site URL</option>
              <option value="siteTitle">Title</option>
              <option value="contactNo">Contact Number</option>
              <option value="email">Email</option>
            </select>
          </div>
          <div className="selectField-HspMangmnt">
            <input
              type="text"
              className="hspmngInput"
              name="searchKeyWord"
              id="searchKeyWord"
              disabled
              value={this.state.searchKeyWord}
              onInput={this.changeHandler}
              tabIndex="0"
              onKeyPress={(event) => {
                // debugger;
                if (event.key === "Enter" || event.key === "NumpadEnter") {
                  event.preventDefault();
                  this.getSiteList();
                }
              }}
            />
          </div>
          <div className="selectField-HspMangmnt">
            <button
              className="searchbtn-hspMng"
              onClick={() => this.getSiteList()}
            >
              Search
            </button>
          </div>
        </div>

        <table className="table table-bordered-hsp table-responsive">
          <thead>
            <tr>
              <th className="tablehead-hspmng">ID</th>
              <th className="tablehead-hspmng">Site Name</th>
              <th className="tablehead-hspmng">Site URL</th>
              <th className="tablehead-hspmng">Title</th>
              <th className="tablehead-hspmng">Contact Number</th>
              <th className="tablehead-hspmng">Email</th>
              <th className="tablehead-hspmng">Actions</th>
            </tr>
          </thead>
          <tbody>{this.renderSiteData()}</tbody>
        </table>
        {this.state.totalCount > 5 ? (
          <div className="paginationSection orderm-paginationSection pagination-HspList pagination-HspListwidth">
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={10}
              totalItemsCount={this.state.totalCount}
              pageRangeDisplayed={10}
              onChange={(pageNumber) => this.handlePageChange(pageNumber)}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default siteSettingsSiteList;
