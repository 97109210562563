import React, { Component, Suspense } from "react";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import { apiCalling,apiUrlRelianceFunction } from "../apiService";
import Pagination from "react-js-pagination";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class DoctorRegistrationReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportData: [],
            totalCount: 0,
            activePage: 1,
            showMessage: "Waiting for data... "
        };
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.registrationReport();
    }

    handlePageChange(pageNumber) {
        window.scrollTo(0, 0);
        this.setState({ activePage: pageNumber });
        var offset;
        var max;
        if (pageNumber === "1") {
            offset = "0";
            max = "10";
            this.setState({
                activePage: pageNumber,
                isLoading: '0'
            })
        }
        else {
            offset = parseInt(pageNumber - 1) * 10;
            max = "10";
            this.setState({
                activePage: pageNumber,
                isLoading: '0'
            })
        }
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "doctorRegistrationReport",
                siteId:window.sessionStorage.getItem("siteId"),
                offset: JSON.stringify(offset),
                max:max
            }),
        };
        $(".loader").show();
        let apiFunction=folderName!== null ? apiCalling : apiUrlRelianceFunction
        apiFunction(apiJson).then((data) => {

            if (data.success == "1") {
                this.setState({
                    reportData: data.result,
                    totalCount: data.resultCount,
                });
                $(".loader").hide();
            }
        });
    }

    registrationReport() {
        $(".loader").show();
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "doctorRegistrationReport",
                siteId:window.sessionStorage.getItem("siteId"), //window.sessionStorage.getItem("siteId"),
                offset: "0",
                max: "10",
            }),
        };
        let apiFunction=folderName!= null ? apiCalling : apiUrlRelianceFunction
        apiFunction(apiJson).then((data) => {
            $(".loader").hide();
            if (data.success === "1") {
                this.setState({
                    reportData: data.result,
                    totalCount: data.resultCount,
                    showMessage:data.result.length > 0 ? "" : "No data found"
                });
            }
            if (data.success === "0") {
                $(".loader").hide();
                this.setState({
                    reportData: [],
                    showMessage: data.errorMessage
                });
            }
        });
    }

    render() {
        return (
            <div class="purpleWrap">
                <Suspense fallback={<div >Waiting...</div>}>
                    <section>
                    {folderName!=null &&
                        <Header />
                    }
                        <div className='loader'></div>
                        <div class="container">
                            <div class="main-wrapper1">
                                <div class="col-md-12 p-0">
                                    <ul class="breadcrumb">
                                        <li>
                                        <a href={"/dashboard/reportDashboard/"+this.props.match.params.groupHospitalId}>Reports</a>
                                            <i class="fa fa-chevron-right"></i>
                                        </li>
                                        <li>Doctor Registration Report</li>
                                    </ul>
                                </div>
                                <div class="form-head"> Doctor Registration Report </div>

                                {this.state.reportData.length > 0 ?
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>Doctor Name</th>
                                                    <th>Mobile Number</th>
                                                    <th>Email-Id</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ fontSize: "12px" }}>
                                                {this.state.reportData.map((data) => {
                                                    return (
                                                        <tr>
                                                            <td>{data.displayName}</td>
                                                            <td >{data.mobileNumber}</td>
                                                            <td>{data.emailId}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        {this.state.totalCount > 5 ?
                                            <div className="paginationSection orderm-paginationSection">
                                                <Pagination
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={10}
                                                    totalItemsCount={this.state.totalCount}
                                                    pageRangeDisplayed={10}
                                                    onChange={this.handlePageChange}
                                                />
                                            </div>
                                            : ""}
                                    </div>
                                    :
                                    <p className="error-msg text-center mb-0"> {this.state.showMessage} </p>
                                }
                            </div>
                        </div>
                        {folderName!==null &&
                        <Footer />
    }
                    </section>
                </Suspense>
            </div>
        );
    }
}
