import React, { Component, Suspense } from "react";
import { apiCalling } from "../../apiService";
import Select from "react-select";
import $ from "jquery";
import DatePicker from "react-datepicker";

const selectStyles = {
  control: (provided) => ({
    ...provided,
    width: 260,
    minWidth: 240,
    margin: "0 0 5px",
    padding: "1px",
  }),
  
};

export default class HospitalAdmindetails extends Component {
  // constructor(props) {
  //   super(props);

    //this.
    state = {
      associationData: [],
      associationId: {},
      deactiveDate: new Date().setDate(new Date().getDate() + 1),
      activeDate: new Date(),
      registrationDate: new Date(),
      createdOnDate: new Date(),
    };

    //this.handleChangeDateActive = this.handleChangeDateActive.bind(this);
    //this.handleChangeDateDeactive = this.handleChangeDateDeactive.bind(this);
    //this.handleChangeRegistration = this.handleChangeRegistration.bind(this);
  //}

  onSelectChange = (data) => {
    this.setState({ associationId: data }, () => {
      if (this.props.onChange) {
        this.props.onChange(data.value);
      }
    });
  };

  handleChangeDateActive=(date)=> {
    this.setState({
      activeDate: date,
    });
  }

  handleChangeCreatedDate=(date) =>{
    this.setState({
      createdOnDate: date,
    });
  }

  disableDateField() {
    $("#activeDate").attr("readonly", "readonly");

    $("#deactiveDate").attr("readonly", "readonly");

    this.setState({
      activeDate: null,

      deactiveDate: null,

      registrationDate: null,
    });
  }

  handleChangeDateDeactive = (date) => {
    this.setState({
      deactiveDate: date,
    });
  }

  handleChangeRegistration = (date) => {
    this.setState({
      registrationDate: date,
    });
  }

  componentDidMount() {

    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHospitalDropdown",
      }),
    };

    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        let associationOptions = [];
        data.hospitalData[0].associationData.map((a) => {
          associationOptions.push({
            label: a.associationName+" - "+a.associationId,
            value: a.associationId,
          });
        });
        
        let associationIds = { value: "" };

        if (this.props.hospitalData1.length > 0) {
          associationIds =
            this.props.hospitalData1[0].associationId != undefined
              ? associationOptions.find(
                  (dd) => dd.value == this.props.hospitalData1[0].associationId
                )
              : { value: "" };
        }

        this.setState(
          {
            associationData: associationOptions,
            associationId: associationIds,
          },
          () => {
            if (this.props.hospitalData1.length > 0) {
              if (this.props.onChange) {
                this.props.onChange(this.props.hospitalData1[0].associationId);
              }

              $("#fax").val(this.props.hospitalData1[0].fax);
              $("#adminDisplayName").val(
                this.props.hospitalData1[0].hospitalAdminDisplayName
              );
              $("#secondaryEmailIds").val(
                this.props.hospitalData1[0].secondaryEmailIds
              );
              $("#adminUserTitle").val(
                this.props.hospitalData1[0].hospitalAdminUserTitle
              );
              $("#adminUserName").val(
                this.props.hospitalData1[0].hospitalAdminUserName
              );
              $("#adminPassword").val(
                this.props.hospitalData1[0].hospitalAdminPassword
              );

              $("#isAccountExpired").val(
                this.props.hospitalData1[0].isAccountExpired === true
                  ? "true"
                  : "false"
              );
              $("#isAccountLocked").val(
                this.props.hospitalData1[0].isAccountLocked === true
                  ? "true"
                  : "false"
              );
              $("#isPasswordExpired").val(
                this.props.hospitalData1[0].isPasswordExpired === true
                  ? "true"
                  : "false"
              );

              $("#isEnabled").val(
                this.props.hospitalData1[0].status === true ? "true" : "false"
              );

              $("#isEHREnabled").val(
                this.props.hospitalData1[0].isEHREnabled === true
                  ? "true"
                  : "false"
              );
              $("#websiteUrl").val(this.props.hospitalData1[0].webSite);
              $("#isVideoRecordingAvailable").val(
                this.props.hospitalData1[0].videoRecordingAvailable === true
                  ? "true"
                  : "false"
              );
              $("#rating").val(this.props.hospitalData1[0].rating);
              $("#sortId").val(this.props.hospitalData1[0].sortId);
              $("#advertismentId").val(
                this.props.hospitalData1[0].advertisementId
              );
              $("#beds").val(this.props.hospitalData1[0].beds);
              $("#isMedicalTourism").val(
                this.props.hospitalData1[0].medicalTourism === true
                  ? "true"
                  : "false"
              );
              $("#isRegistered").val(
                this.props.hospitalData1[0].isRegistered === true
                  ? "true"
                  : "false"
              );
              $("#isShowHospitallogoassitelogo").val(
                this.props.hospitalData1[0].showHospitalLogoasSiteLogo === true
                  ? "true"
                  : "false"
              );
              $("#isShowHospitalMedicineOnly").val(
                this.props.hospitalData1[0].showHospitalMedicinesOnly === true
                  ? "true"
                  : "false"
              );
              $("#isPrepaidCardEnabled").val(
                this.props.hospitalData1[0].isPrepaidCardEnabled === true
                  ? "true"
                  : "false"
              );
              $("#isDoctorInitiatedChat").val(
                this.props.hospitalData1[0].isDoctorInitiatedChat === true
                  ? "true"
                  : "false"
              );
              $("#isCashback").val(
                this.props.hospitalData1[0].isCashback === true
                  ? "true"
                  : "false"
              );
              $("#hasDiscount").val(
                this.props.hospitalData1[0].hasDiscount === true
                  ? "true"
                  : "false"
              );
              $("#hasPmWaterMark").val(
                this.props.hospitalData1[0].hasPMWatermark === true
                  ? "true"
                  : "false"
              );
              $("#isAcceptOnlineAppointment").val(
                this.props.hospitalData1[0].acceptOnlineAppointment === true
                  ? "true"
                  : "false"
              );
              $("#isverified").val(
                this.props.hospitalData1[0].isVerified === true
                  ? "true"
                  : "false"
              );
              $("#isVideoEnabled").val(
                this.props.hospitalData1[0].isVideoEnabled === true
                  ? "true"
                  : "false"
              );
              $("#isPRNumberEnabled").val(
                this.props.hospitalData1[0].hasPrNoEnabled === true
                  ? "true"
                  : "false"
              );
              $("#cashbackPercent").val(
                this.props.hospitalData1[0].cashbackPercent
              );
              $("#isPromoCode").val(
                this.props.hospitalData1[0].isPromoCode === true
                  ? "true"
                  : "false"
              );
              $("#createdBy").val(this.props.hospitalData1[0].createdBy);
              $("#txtCreatedOn").val(this.props.hospitalData1[0].createdOn);
              $("#modifiedBy").val(this.props.hospitalData1[0].modifiedBy);
              $("#modifiedOn").val(this.props.hospitalData1[0].modifiedOn);

              $("#activeDate").val(this.props.hospitalData1[0].activateDate);
              $("#deactiveDate").val(
                this.props.hospitalData1[0].deactivateDate
              );
              let newDate=(this.props.hospitalData1[0].deactivateDate).split('/');
              newDate=new Date(newDate[2],newDate[1]-1,newDate[0])
              this.setState({deactiveDate:(newDate)})
              $("#registrationDate").val(
                this.props.hospitalData1[0].registrationDate
              );
            }
          }
        );
      }
    });
  }

  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <div className="hspAdmindetails-wrapper pb-4 pt-3">
              <h2 className="hspInfoSubhead pb-3">Hospital Admin Details</h2>

              <div className="hspinfo-contentdiv">
                <label>Created By</label>

                <input
                  type="text"
                  id="createdBy"
                  name="createdBy"
                  className="hspInfofieldinput"
                />
              </div>

              <div className="hspinfo-contentdiv react-date-dctr-width react_datepicker">
                <label>Created On</label>

                <DatePicker
                  placeholderText="Active Date"
                  disabled
                  id="txtCreatedOn"
                  name="txtCreatedOn"
                  // maxDate={new Date()}
                  showMonthDropdown
                  className="doctrHsp-dateinput"
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  dropdownMode="scroll"
                  peekNextMonth
                  disabledKeyboardNavigation
                  selected={this.state.createdOnDate}
                  onSelect={this.handleChangeCreatedDate}
                  dateFormat="dd/MM/yyyy"
                  calendarClassName="react-date-picker"
                  autoComplete="off"
                  onKeyDown={() => this.disableDateField()}
                />
              </div>

              <div className="hspinfo-contentdiv">
                <label>Modified By</label>

                <input
                  type="text"
                  id="modifiedBy"
                  name="modifiedBy"
                  className="hspInfofieldinput"
                  placeholder="Modified  By"
                />
              </div>

              <div className="hspinfo-contentdiv">
                <label>Modified On</label>

                <input
                  type="text"
                  id="modifiedOn"
                  name="modifiedOn"
                  className="hspInfofieldinput"
                  placeholder="Modified On"
                />
              </div>

              <div className="hspinfo-contentdiv association-input-hspMangmnt">
                <label>Association Name
                <span className="hspifoMantory" >*</span>
                </label>
                {/* <SelectSearch options={this.state.associationData} value="22" name="associationIds" id="associationIds" placeholder="Select Association" /> */}

                <Select
                  options={this.state.associationData}
                  value={this.state.associationId}
                  styles={selectStyles}
                  onChange={this.onSelectChange}
                  id="associationIds"
                />
               
              </div>

              <div className="hspinfo-contentdiv">
                <label>Fax</label>

                <input
                  type="text"
                  id="fax"
                  name="fax"
                  className="hspInfofieldinput"
                  placeholder="Fax"
                />

                <div className="mt-2">
                  <label>Secondary Email Ids</label>

                  <input
                    type="text"
                    id="secondaryEmailIds"
                    name="secondaryEmailIds"
                    className="hspInfofieldinput"
                    placeholder="Secondary Email Ids"
                  />
                </div>
              </div>

              <div className="hspinfo-contentdiv">
                <label>
                  Display Name<span className="hspifoMantory">*</span>
                </label>

                <input
                  type="text"
                  id="adminDisplayName"
                  name="adminDisplayName"
                  className="hspInfofieldinput"
                />
              </div>

              <div className="hspinfo-contentdiv">
                <label>User Title</label>

                <input
                  type="text"
                  id="adminUserTitle"
                  name="adminUserTitle"
                  className="hspInfofieldinput"
                  placeholder="Mr/Ms/Mrs"
                />
              </div>

              <div className="hspinfo-contentdiv">
                <label>
                  User Name<span className="hspifoMantory">*</span>
                </label>

                <input
                  type="text"
                  id="adminUserName"
                  name="adminUserName"
                  className="hspInfofieldinput"
                  placeholder="Email Id Needed"
                />
              </div>

              <div className="hspinfo-contentdiv">
                <label>
                  Password<span className="hspifoMantory">*</span>
                </label>

                <input
                  type="password"
                  id="adminPassword"
                  name="adminPassword"
                  className="hspInfofieldinput"
                  placeholder="Password"
                />
              </div>

              <div className="hspinfo-contentdiv hspinfo-adminselct-mob">
                <label>Is Account Expired</label>

                <select
                  name="isAccountExpired"
                  id="isAccountExpired"
                  className="hspInfofieldinput hspInfofieldinputWidth"
                >
                  <option value="false">No</option>

                  <option value="true">Yes</option>
                </select>
              </div>

              <div className="hspinfo-contentdiv hspinfo-adminselct-mob">
                <label>Is Account Locked</label>

                <select
                  name="isAccountLocked"
                  id="isAccountLocked"
                  className="hspInfofieldinput hspInfofieldinputWidth"
                >
                  <option value="false">No</option>

                  <option value="true">Yes</option>
                </select>
              </div>

              <div className="hspinfo-contentdiv hspinfo-adminselct-mob">
                <label>Is Password Expired</label>

                <select
                  name="isPasswordExpired"
                  id="isPasswordExpired"
                  className="hspInfofieldinput hspInfofieldinputWidth"
                >
                  <option value="false">No</option>

                  <option value="true">Yes</option>
                </select>
              </div>

              <div className="hspinfo-contentdiv hspinfo-adminselct-mob">
                <label>Status</label>

                <select
                  name="isEnabled"
                  id="isEnabled"
                  className="hspInfofieldinput hspInfofieldinputWidth"
                >
                  <option value="true">Enabled</option>
                  <option value="false">Disabled</option>
                </select>
              </div>

              <div className="hspinfo-contentdiv hspinfo-adminselct-mob">
                <label>Is EHR Enabled</label>

                <select
                  name="isEHREnabled"
                  id="isEHREnabled"
                  className="hspInfofieldinput hspInfofieldinputWidth"
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>

                <div className="mt-2 hspinfo-adminselct-mob">
                  <label>Video Recording Available</label>

                  <select
                    name="isVideoRecordingAvailable"
                    id="isVideoRecordingAvailable"
                    className="hspInfofieldinput hspInfofieldinputWidth"
                  >
                    <option value="false">No</option>

                    <option value="true">Yes</option>
                  </select>
                </div>
              </div>

              <div className="hspinfo-contentdiv">
                <label>Website</label>

                <input
                  type="text"
                  id="websiteUrl"
                  name="websiteUrl"
                  className="hspInfofieldinput"
                  placeholder="Website"
                />
              </div>

              <div className="hspinfo-contentdiv">
                <label>Rating</label>

                <input
                  type="text"
                  id="rating"
                  name="rating"
                  className="hspInfofieldinput"
                />
              </div>

              <div className="hspinfo-contentdiv">
                <label>Sort ID</label>

                <input
                  type="text"
                  id="sortId"
                  name="sortId"
                  className="hspInfofieldinput"
                />
              </div>

              <div className="hspinfo-contentdiv">
                <label>Advertisement ID</label>

                <input
                  type="text"
                  id="advertismentId"
                  name="advertismentId"
                  className="hspInfofieldinput"
                />
              </div>

              <div className="hspinfo-contentdiv">
                <label>
                  Beds<span className="hspifoMantory"></span>
                </label>

                <input
                  type="text"
                  id="beds"
                  name="beds"
                  className="hspInfofieldinput"
                />
              </div>

              <div className="hspinfo-contentdiv">
                <label>Doctor Count</label>

                <input type="text" className="hspInfofieldinput" />
              </div>

              <div className="hspinfo-contentdiv react-date-dctr-width react_datepicker react-date-dctr-widthiput">
                <label>Activate Date</label>

                <DatePicker
                  placeholderText="Active Date"
                  id="activeDate"
                  name="activeDate"
                  // maxDate={new Date()}

                  showMonthDropdown
                  className="hspInfofieldinput Infoinputwidthdoctr hspinputborderrad"
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  dropdownMode="scroll"
                  peekNextMonth
                  disabledKeyboardNavigation
                  selected={this.state.activeDate}
                  onSelect={this.handleChangeDateActive}
                  dateFormat="dd/MM/yyyy"
                  calendarClassName="react-date-picker"
                  autoComplete="off"
                  onKeyDown={() => this.disableDateField()}
                />
              </div>

              <div className="hspinfo-contentdiv react-date-dctr-width react_datepicker react-date-dctr-widthiput">
                <label>
                  Deactivate Date<span className="hspifoMantory">*</span>
                </label>

                <DatePicker
                  placeholderText="Deactive Date "
                  id="deactiveDate"
                  name="deactiveDate"
                  // maxDate={new Date()}

                  showMonthDropdown
                  className="hspInfofieldinput Infoinputwidthdoctr hspinputborderrad"
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  dropdownMode="scroll"
                  peekNextMonth
                  disabledKeyboardNavigation
                  selected={this.state.deactiveDate}
                  onSelect={this.handleChangeDateDeactive}
                  dateFormat="dd/MM/yyyy"
                  calendarClassName="react-date-picker"
                  autoComplete="off"
                  onKeyDown={() => this.disableDateField()}
                />
              </div>

              <div className="superadminDivBoxcover react-date-dctr-width">
                <div className="hspinfo-contentdiv react-date-dctr-width react_datepicker">
                  <label>Registration Date</label>

                  <DatePicker
                    placeholderText="Active Date"
                    id="registrationDate"
                    name="registrationDate"
                    // maxDate={new Date()}

                    showMonthDropdown
                    className="hspInfofieldinput Infoinputwidthdoctr hspinputborderrad"
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={100}
                    dropdownMode="scroll"
                    peekNextMonth
                    disabledKeyboardNavigation
                    selected={this.state.registrationDate}
                    onSelect={this.handleChangeRegistration}
                    dateFormat="dd/MM/yyyy"
                    calendarClassName="react-date-picker"
                    autoComplete="off"
                    onKeyDown={() => this.disableDateField()}
                  />
                </div>
              </div>

              <div className="hspinfo-contentdiv hspinfo-adminselct-mob">
                <label>Medical Tourism</label>

                <select
                  name="isMedicalTourism"
                  id="isMedicalTourism"
                  className="hspInfofieldinput hspInfofieldinputWidth"
                >
                  <option value="false">Disabled</option>

                  <option value="true">Enabled</option>
                </select>
              </div>

              <div className="hspinfo-contentdiv hspinfo-adminselct-mob">
                <label>Accept online appointment</label>

                <select
                  name="isAcceptOnlineAppointment"
                  id="isAcceptOnlineAppointment"
                  className="hspInfofieldinput hspInfofieldinputWidth"
                >
                  <option value="false">No</option>

                  <option value="true">Yes</option>
                </select>
              </div>

              <div className="hspinfo-contentdiv hspinfo-adminselct-mob">
                <label>Is Registred</label>

                <select
                  name="isRegistered"
                  id="isRegistered"
                  className="hspInfofieldinput hspInfofieldinputWidth"
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>

              <div className="hspinfo-contentdiv hspinfo-adminselct-mob">
                <label>Is Verified</label>

                <select
                  name="isverified"
                  id="isverified"
                  className="hspInfofieldinput hspInfofieldinputWidth"
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>

              <div className="superadminDivBoxcover">
                <div className="superadminDivBox hspinfo-adminselct-mob">
                  <label className="pt-0">
                    Show Hospital logo as site logo
                  </label>

                  <select
                    name="isShowHospitallogoassitelogo"
                    id="isShowHospitallogoassitelogo"
                    className="hspInfofieldinput hspInfofieldinputWidth"
                  >
                    <option value="false">No</option>

                    <option value="true">Yes</option>
                  </select>
                </div>
              </div>

              <div className="superadminDivBoxcover pt-0">
                <div className="superadminDivBox hspinfo-adminselct-mob">
                  <label className="pt-0">Show Hospital Medicine Only</label>

                  <select
                    name="isShowHospitalMedicineOnly"
                    id="isShowHospitalMedicineOnly"
                    className="hspInfofieldinput hspInfofieldinputWidth"
                  >
                    <option value="false">No</option>

                    <option value="true">Yes</option>
                  </select>
                </div>
              </div>

              <div className="hspinfo-contentdiv mt-0 hspinfo-adminselct-mob">
                <label>Is Prepaid Card Enabled</label>

                <select
                  name="isPrepaidCardEnabled"
                  id="isPrepaidCardEnabled"
                  className="hspInfofieldinput hspInfofieldinputWidth"
                >
                  <option value="false">No</option>

                  <option value="true">Yes</option>
                </select>
              </div>

              <div className="hspinfo-contentdiv mt-0 hspinfo-adminselct-mob">
                <label>Is Video Enabled</label>

                <select
                  name="isVideoEnabled"
                  id="isVideoEnabled"
                  className="hspInfofieldinput hspInfofieldinputWidth"
                >
                  <option value="false">No</option>

                  <option value="true">Yes</option>
                </select>
              </div>
              <div className="hspinfo-contentdiv mt-0 hspinfo-adminselct-mob">
                <label>Is PR Number Enabled</label>

                <select
                  name="isPRNumberEnabled"
                  id="isPRNumberEnabled"
                  className="hspInfofieldinput hspInfofieldinputWidth"
                >
                  <option value="false">No</option>

                  <option value="true">Yes</option>
                </select>
              </div>

              <div className="superadminDivBoxcover">
                <div className="superadminDivBox hspinfo-adminselct-mob">
                  <label className="pt-0">Doctor Initiated Chat</label>

                  <select
                    name="isDoctorInitiatedChat"
                    id="isDoctorInitiatedChat"
                    className="hspInfofieldinput hspInfofieldinputWidth"
                  >
                    <option value="false">No</option>

                    <option value="true">Yes</option>
                  </select>
                </div>
              </div>

              <div className="hspinfo-contentdiv hspinfo-adminselct-mob">
                <label>Is Cashback</label>

                <select
                  name="isCashback"
                  id="isCashback"
                  className="hspInfofieldinput hspInfofieldinputWidth"
                >
                  <option value="false">No</option>

                  <option value="true">Yes</option>
                </select>
              </div>

              <div className="hspinfo-contentdiv hspinfo-adminselct-mob">
                <label>Cashback %</label>

                <input
                  type="text"
                  id="cashbackPercent"
                  name="cashbackPercent"
                  className="hspInfofieldinput hspInfofieldinputWidth"
                />
              </div>

              <div className="hspinfo-contentdiv hspinfo-adminselct-mob">
                <label>Has Discount</label>

                <select
                  name="hasDiscount"
                  id="hasDiscount"
                  className="hspInfofieldinput hspInfofieldinputWidth"
                >
                  <option value="false">No</option>

                  <option value="true">Yes</option>
                </select>
              </div>

              <div className="hspinfo-contentdiv hspinfo-adminselct-mob">
                <label>Is Promocode</label>

                <select
                  name="isPromoCode"
                  id="isPromoCode"
                  className="hspInfofieldinput hspInfofieldinputWidth"
                >
                  <option value="false">No</option>

                  <option value="true">Yes</option>
                </select>
              </div>

              <div className="superadminDivBoxcover">
                <div className="superadminDivBox hspinfo-adminselct-mob">
                  <label className="pt-0">Has PmWaterMark</label>

                  <select
                    name="hasPmWaterMark"
                    id="hasPmWaterMark"
                    className="hspInfofieldinput hspInfofieldinputWidth"
                  >
                    <option value="false">No</option>

                    <option value="true">Yes</option>
                  </select>
                </div>
              </div>
            </div>
          </section>
        </Suspense>

        <Suspense fallback={<div></div>}></Suspense>
      </div>
    );
  }
}
