import React, { Component, Suspense } from "react";
import deactvate from "../../image/icons/deactvate.png";
import plus from "../../image/icons/plus.png";
import { apiCalling } from "../../apiService";
import $ from "jquery";
import tick from "../../image/icons/tick.png";
import Pagination from "react-js-pagination";
import Header from "../../Reliance/header";
import Footer from "../../Reliance/footer";
import Close from "../../image/icons/close-mark.png";
import moment from "moment";
import { includes } from "lodash";
import { Link } from "react-router-dom";
let timeSlotArray = [];
var result = [];
let scheduledData = [];
let doctorSpecialityData = [];
let isValid = true;
let desiredIndex;
const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
for (var i = 0; i < 7; i++) {
  var d = new Date();
  d.setDate(d.getDate() + i);
  result.push({
    dateMilli: d.getTime(),
    dateFull: moment(d).format("YYYY-MM-DD"),
    date: moment(d).format("DD-MMM-YYYY"),
    day: weekday[d.getDay()],
  });
  result.join(",");
}
export default class Qualification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hospitalId: "",
      specialityList: [],
      totalCount: 0,
      activePage: 1,
      showMessage: "Waiting for data... ",
      appType: "",
      appTypeId: "",
      doctorName: "",
      doctorSpeciality: "",
      HoursValue: "select",
      Hours: "",
      HoursData: [],
      Minute: "",
      MinuteData: [],
      AmPm: "",
      AmPmData: [],
      ToHours: "",
      ToMinute: "",
      ToAmPm: "",
      doctorScheduleData: [],
      notExistsDoctorData: [],
      doctorScheduleDataNew: [],
      addNewFlag: false,
      scheduleDataOld: [],
      addFlag: [],
      addNewDiv: [],
      docSpeciality: "",
      doctorSpecialityData: [],
      isChangeVisible: false,
      VideoAdd: true,
      AudioAdd: true,
      isScheduleCreated: 0,
      isSpanVisible: false,
      clickedIndexes: []
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleVideoChange = this.handleVideoChange.bind(this);
    this.handleAudioChange = this.handleAudioChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.scheduleEdit = this.scheduleEdit.bind(this);
    this.submitSchedule = this.submitSchedule.bind(this);
    this.deleteTimeSchedule = this.deleteTimeSchedule.bind(this);
    this.submitNewSchedule = this.submitNewSchedule.bind(this);
    this.handleVideoAdd = this.handleVideoAdd.bind(this);
    this.handleAudioAdd = this.handleAudioAdd.bind(this);
  }

  componentDidMount() {
    this.getHospitalSpecialityList();
    $('#plusImage1').parent('.editbtn-qual').hide();
    this.setState({clickedIndexes:[]});
  }

  getHospitalSpecialityList() {
    // debugger;
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHospitalSpecialityList",
        hospitalId: this.props.match.params.hospitalId,
      }),
    };
    apiCalling(apiJson).then((data) => {
      // console.log("kiljoy", data);
      if (data.success === "1") {
        // console.log("aaaa", data);
        $(".loader").hide();
        let notExistsDoctorData = [];
        if (data.result.notExistsDoctorData.length > 0) {
          notExistsDoctorData = data.result.notExistsDoctorData.map((item) => ({
            label: item.doctorName,
            value: item.doctorId,
            speciality: item.doctorSpeciality,
          }));
        }
        this.setState({
          specialityList: data.result.doctorScheduleData,
          notExistsDoctorData: notExistsDoctorData,
          totalCount: data.result.doctorScheduleData.length,
        });
      } else {
        $(".loader").hide();
        this.setState({
          specialityList: [],
          showMessage: data.errorMessage,
        });
      }
    });
  }
  handleInputChange = (event, data, index) => {
    this.setState({
      isChangeVisible: true,
    });
  };
  handleVideoChange = (event, data, index) => {
    this.setState({
      isChangeVisible: true,
    });
    $("#ratePerSession" + index).prop(
      "disabled",
      $("#videoType" + index).prop("checked") == true ? false : true
    );
  };
  handleAudioChange = (event, data, index) => {
    this.setState({
      isChangeVisible: true,
    });
    $("#ratePerAudioSession" + index).prop(
      "disabled",
      $("#audioType" + index).prop("checked") == true ? false : true
    );
  };
  handleVideoAdd = () => {
    this.setState({
      VideoAdd: $("#videoType").prop("checked") == true ? false : true,
    });
  };
  handleAudioAdd = () => {
    this.setState({
      AudioAdd: $("#audioType").prop("checked") == true ? false : true,
    });
  };
  scheduleValidation = () => {
    var regex = /^[0-9]+$/;
    let doctorSpecialityArrayNew = [];
    let specialityList = this.state.specialityList;
    for (let i = 0; i < specialityList.length; i++) {
      let ratePerSession = $("#ratePerSession" + i).val();
      let videoType = $("#videoType" + i).prop("checked") == true ? 1 : 0;
      let averageSessionTime = $("#averageSessionTime" + i).val();
      let audioType = $("#audioType" + i).prop("checked") == true ? 1 : 0;
      let ratePerAudioSession = $("#ratePerAudioSession" + i).val();
      if (
        ratePerSession !== "" &&
        !parseFloat(ratePerSession).toFixed(0).match(regex)
      ) {
        alert("Please enter valid amount");
        //$("#ratePerSession"+i).focus()
        isValid = false;
        break;
      } else if (
        averageSessionTime !== "" &&
        !parseFloat(averageSessionTime).toFixed(0).match(regex)
      ) {
        alert("Please enter valid amount");
        //$("#averageSessionTime"+i).focus()
        isValid = false;
        break;
      } else if (
        ratePerAudioSession !== "" &&
        !parseFloat(ratePerAudioSession).toFixed(0).match(regex)
      ) {
        alert("Please enter valid amount");
        //$("#ratePerAudioSession"+i).focus()
        isValid = false;
        break;
      } else {
        isValid = true;
        doctorSpecialityArrayNew.push({
          doctorId: specialityList[i].doctorId,
          ratePerSession:
            ratePerSession == ""
              ? ratePerSession
              : parseFloat(ratePerSession).toFixed(2),
          averageSessionTime:
            averageSessionTime == ""
              ? averageSessionTime
              : parseFloat(averageSessionTime).toFixed(2),
          audioRatePerSession:
            ratePerAudioSession == ""
              ? ratePerAudioSession
              : parseFloat(ratePerAudioSession).toFixed(2),
          audioEnabled: audioType == true ? 1 : 0,
          videoEnabled: videoType == true ? 1 : 0,
          hospitalDoctorVideoScheduleId:
            specialityList[i].hospitalDoctorVideoScheduleId,
        });
      }
      doctorSpecialityData = doctorSpecialityArrayNew;
    }
  };
  submitNewSchedule = () => {
    this.scheduleValidation();
    if (isValid == true) {
      $(".loader").show();
      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "updateHospitalDoctorSchedule",
          hospitalId: this.props.match.params.hospitalId,
          drScheduleDetailsList: doctorSpecialityData,
        }),
      };
      apiCalling(apiJson).then((data) => {
        $(".loader").hide();
        if (data.success === "1") {
          this.getHospitalSpecialityList();
        }
        if (data.success === "0") {
          $(".loader").hide();
          alert(data.errorMessage);
        }
      });
    }
  };
  addScheduleNew = () => {
    let doctorSpecialityArray = [];
    let ratePerSession = $("#ChargeSession").val();
    let videoType = $("#videoType").prop("checked") == true ? 1 : 0;
    let averageSessionTime = $("#averagePrice").val();
    let audioType = $("#audioType").prop("checked") == true ? 1 : 0;
    let ratePerAudioSession = $("#AudioChatPrice").val();
    let doctorId = $("#selectDoctor").val().split("_")[0];
    if (doctorId == null || doctorId == undefined || doctorId == 0) {
      alert("Please select doctor");
    } else {
      //$(".loader").show();
      doctorSpecialityArray.push({
        doctorId: doctorId,
        ratePerSession:
          ratePerSession == ""
            ? ratePerSession
            : parseFloat(ratePerSession).toFixed(2),
        averageSessionTime:
          averageSessionTime == ""
            ? 15
            : parseFloat(averageSessionTime).toFixed(2),
        audioRatePerSession:
          ratePerAudioSession == ""
            ? ratePerAudioSession
            : parseFloat(ratePerAudioSession).toFixed(2),
        audioEnabled: audioType == true ? 1 : 0,
        videoEnabled: videoType == true ? 1 : 0,
        hospitalDoctorVideoScheduleId: "",
      });
      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "updateHospitalDoctorSchedule",
          hospitalId: this.props.match.params.hospitalId,
          drScheduleDetailsList: doctorSpecialityArray,
        }),
      };
      apiCalling(apiJson).then((data) => {
        if (data.success === "1") {
          $("#ChargeSession").val("");
          $("#videoType").prop("checked", false);
          $("#averagePrice").val("");
          $("#audioType").prop("checked", false);
          $("#AudioChatPrice").val("");
          $("#selectDoctor").val(0);
          this.setState({
            docSpeciality: "",
            AudioAdd: true,
            VideoAdd: true,
          });
          this.getHospitalSpecialityList();
        }
        if (data.success === "0") {
          $(".loader").hide();
          alert(data.errorMessage);
        }
      });
    }
  };

  doctorChange = (e) => {
    let speciality = e.target.value.split("_")[1];
    this.setState({
      docSpeciality: speciality,
    });
  };

  deleteSchedule = (id, doctorId) => {
    var x = false;
    x = window.confirm("Do you want to delete this?");
    if (x === true) {
      $(".loader").show();
      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "deleteHospitalDoctorVideoSchedule",
          hospitalDoctorVideoScheduleId: id,
          hospitalId: this.props.match.params.hospitalId,
          doctorId: doctorId,
        }),
      };
      apiCalling(apiJson).then((data) => {
        if (data.success === "1") {
          this.getHospitalSpecialityList();
        } else {
          $(".loader").hide();
          alert(data.errorMessage);
        }
      });
    }
  };

  createSchedule(data) {
    this.setState({ isScheduleCreated: 0 });
    $(".loader").show();
    $("#popup1").show();
    $(".popOverlay").css("background-color", "rgba(0, 0, 0, 0.6)");
    this.setState({
      doctorName: data.doctorName,
      doctorSpeciality: data.speciality,
    });
    scheduledData = data;
    this.editScheduleData(data);
  }
  closepopup() {
    $("#popup1").hide();
    $(".popOverlay").css("background-color", "transparent");
    timeSlotArray = [];
    scheduledData = [];
    this.setState({
      doctorScheduleDataNew: [],
    });
    $(".table-doctrworkListBorder .doctroffceInput").val("0");
    $(".table-doctrworkListBorder .doctroffceInputCheck").prop(
      "checked",
      false
    );
    this.setState({
      addNewDiv: [],
    });
    this.getHospitalSpecialityList();
  }

  handleSelectChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleCheckChange = (e, index, check) => {
    // this.dataPush(index)
  };
  scheduleEdit = (index, listitem, itemLength, date) => {
    // console.log(index);
    if (itemLength >= 2 && this.state.isSpanVisible=== false) {
      $("#plusImage" + index).prop("hidden", true);
      return false;
    } else {
      let pagePreLength =
        $("#dataSet" + index).val() === "0" ? 1 : $("#dataSet" + index).val();
      //let pagePreLength = $('#dataSet'+index).val()
      let dataSetLength = parseInt(pagePreLength) + 1;
      let Hours = $(
        "#tableRow" + index + "_" + pagePreLength + " " + "#Hours" + index
      ).val();
      let Minute = $(
        "#tableRow" + index + "_" + pagePreLength + " " + "#Minute" + index
      ).val();
      let AmPm = $(
        "#tableRow" + index + "_" + pagePreLength + " " + "#AmPm" + index
      ).val();
      let ToHours = $(
        "#tableRow" + index + "_" + pagePreLength + " " + "#ToHours" + index
      ).val();
      let ToMinute = $(
        "#tableRow" + index + "_" + pagePreLength + " " + "#ToMinute" + index
      ).val();
      let ToAmPm = $(
        "#tableRow" + index + "_" + pagePreLength + " " + "#ToAmPm" + index
      ).val();
      let startTime = this.formatAMPM(date, Hours, Minute, AmPm);
      startTime = new Date(startTime).getTime();
      let endTime = this.formatAMPM(date, ToHours, ToMinute, ToAmPm);
      endTime = new Date(endTime).getTime();
      if (Hours == "0") {
        alert("Please select From Hour");
      } else if (Minute == "0") {
        alert("Please select From Minute");
      } else if (AmPm == "0") {
        alert("Please select From AM/PM");
      } else if (ToHours == "0") {
        alert("Please select To Hour");
      } else if (ToMinute == "0") {
        alert("Please select To Minute");
      } else if (ToAmPm == "0") {
        alert("Please select To AM/PM");
      } else if (startTime > endTime) {
        alert("Please enter correct To Time");
      } else {
        this.saveHandle();
        let validationFlag = true;
        let timeSlotArrayFilter = [];
        timeSlotArrayFilter = timeSlotArray
          .filter(
            (data) => date == moment(data.thresholdLimit).format("DD-MMM-YYYY")
          )
          .map((item) => item);
        for (let i = 0; i < timeSlotArrayFilter.length; i++) {
          let scheduleStartTime = timeSlotArrayFilter[i].scheduleStartTime;
          let scheduleEndTime = timeSlotArrayFilter[i].scheduleEndTime;
          // debugger
          if (
            validationFlag == true &&
            ((startTime > scheduleStartTime && startTime < scheduleEndTime) ||
              (endTime > scheduleStartTime && endTime < scheduleEndTime))
          ) {
            alert("Please enter correct To Time");
            validationFlag = false;
            break;
          } else if (
            validationFlag == true &&
            startTime < scheduleStartTime &&
            endTime > scheduleEndTime
          ) {
            alert("Please enter correct To Time");
            validationFlag = false;
            break;
          } else {
            validationFlag = true;
          }
        }
        if (validationFlag == true) {
          $("#dataSet" + index).val(dataSetLength);
          //alert('aaaaaaaaaaaaaa'+$('#dataSet'+index).val())
          let newArray = [...this.state.addNewDiv];
          let dataArray = [];
          dataArray.push(
            ...this.state.addNewDiv,
            (newArray[index] = [
              this.addNewTemplate(index, listitem, dataSetLength),
            ])
          );
          this.setState({
            addNewDiv: dataArray,
          });
        }
        if(this.state.isSpanVisible == true){
          $("#plusImage1" + index).prop("hidden", true);        }
      }
      itemLength = itemLength + 1;
      if (itemLength === 2) {
        $("#plusImage" + index).prop("hidden", true);
      }
    }
  };

  deleteTimeSchedule = (index, listitem, parentIndex, Name, item) => {
    //debugger;
    // console.log(this.state.doctorScheduleDataNew);
    var x = false;
    x = window.confirm("Do you want to delete this?");
    if (x === true) {
      // if (Name !== "NewDelete") {
      //   // let limit = $('#dataSet'+index).val()
      //   // let indexOne = parseInt(index) + 1;
      //   debugger;
      //   // var array = this.state.doctorScheduleDataNew;
      //   const newArray = this.state.doctorScheduleDataNew.map((item) => {
      //     const updatedDataSet = item.dataSet.filter(
      //       (data) =>
      //         data.doctorMasterVideoScheduleId !=
      //         item.doctorMasterVideoScheduleId
      //     );
      //     return { ...item, dataSet: updatedDataSet };
      //   });
      //   console.log("listlist", newArray);
      //   this.setState({ doctorScheduleDataNew: newArray }, () => {
      //     const apiJson = {
      //       method: "POST",
      //       body: JSON.stringify({
      //         functionName: "deleteDoctorVideoAudioSchedule",
      //         doctorMasterVideoScheduleId: item.doctorMasterVideoScheduleId,
      //         // doctorVideoscheduleId: item.doctorVideoscheduleId,
      //         // doctorId: item.doctorId,
      //         // hospitalId: item.hospitalId,
      //         // fromTime: item.fromTime,
      //         // toTime: item.toTime,
      //       }),
      //     };
      //     // console.log(
      //     // "🚀 ~ file: hospitalDoctorSchedule.jsx:498 ~ Qualification ~ deleteTimeSchedule ~ apiJson:",
      //     // apiJson.body
      //     // );
      //     apiCalling(apiJson).then((data) => {
      //       $(".loader").hide();
      //       if (data.success === "0") {
      //         alert(data.errorMessage);
      //       } else {
      //         alert("Deleted Successfully");
      //       }
      //     });
      //   });
      // }
      if (Name !== "NewDelete") {
        // let limit = $('#dataSet'+index).val()
        // let indexOne = parseInt(index) + 1;
        // debugger;

        const apiJson = {
          method: "POST",
          body: JSON.stringify({
            functionName: "deleteDoctorVideoAudioSchedule",
            doctorMasterVideoScheduleId: item.doctorMasterVideoScheduleId,
            // doctorVideoscheduleId: item.doctorVideoscheduleId,
            // doctorId: item.doctorId,
            // hospitalId: item.hospitalId,
            // fromTime: item.fromTime,
            // toTime: item.toTime,
          }),
        };
        // console.log(
        //   "🚀 ~ file: hospitalDoctorSchedule.jsx:498 ~ Qualification ~ deleteTimeSchedule ~ apiJson:",
        //   apiJson.body
        // );
        apiCalling(apiJson).then((data) => {
          $(".loader").hide();
          if (data.success === "0") {
            alert(data.errorMessage);
          } else {
            // alert("Deleted Successfully");
            $("#tableRow" + parentIndex + "_" + index).remove();
            this.setState({ isSpanVisible: true });
            this.setState((prevState) => ({
              clickedIndexes: [...prevState.clickedIndexes, listitem],
            }));
            // var array = this.state.doctorScheduleDataNew;
            // array[listitem].dataSet.splice(index, 1);
            // this.setState({ doctorScheduleDataNew: array });
          }
        });
      } else {
        $("#tableRow" + index + "_" + parentIndex).remove();
        var array = timeSlotArray;
        let indexOne = parseInt(listitem) - 1;
        array.splice(indexOne, 1);
        timeSlotArray = array;
        $("#plusImage" + index).prop("hidden", false);
        $("#plusImage1" + index).prop("hidden", false);
      }
      if (listitem === 2) {
        $("#plusImage" + index).prop("hidden", false);
      }
    }
  };

  formatAMPM = (date, hours, minutes, ampm) => {
    var hours = parseInt(hours);
    var minutes = parseInt(minutes);
    var AMPM = ampm;
    var date = date;
    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    let time = sHours + ":" + sMinutes + ":" + "00";
    date = date + " " + time;
    return date;
  };
  saveHandle = () => {
    let timeSlotArrayOne = [];
    // console.log("resultsjdfbgsdf", result);
    for (let i = 0; i < result.length; i++) {
      let index = i + "" + result[i].day;
      let dayId;
      if (result[i].day === "Sun") {
        dayId = 0;
      }
      if (result[i].day === "Mon") {
        dayId = 1;
      }
      if (result[i].day === "Tue") {
        dayId = 2;
      }
      if (result[i].day === "Wed") {
        dayId = 3;
      }
      if (result[i].day === "Thu") {
        dayId = 4;
      }
      if (result[i].day === "Fri") {
        dayId = 5;
      }
      if (result[i].day === "Sat") {
        dayId = 6;
      }
      let jmax =
        $("#dataSet" + index).val() === "0" ? 1 : $("#dataSet" + index).val();
      // console.log("jmaxjmaxjmaxjmax", jmax);
      //let jmax = $('#dataSet'+index).val()
      for (let j = 1; j <= jmax; j++) {
        //alert("#tableRow" + index + "_" + j + " " + "#Hours" + index);
        let fromHH = $(
          "#tableRow" + index + "_" + j + " " + "#Hours" + index
        ).val();
        //alert(fromHH);
        let fromMM = $(
          "#tableRow" + index + "_" + j + " " + "#Minute" + index
        ).val();
        let FromAMPM = $(
          "#tableRow" + index + "_" + j + " " + "#AmPm" + index
        ).val();
        let ToHH = $(
          "#tableRow" + index + "_" + j + " " + "#ToHours" + index
        ).val();
        let ToMM = $(
          "#tableRow" + index + "_" + j + " " + "#ToMinute" + index
        ).val();
        let ToAMPM = $(
          "#tableRow" + index + "_" + j + " " + "#ToAmPm" + index
        ).val();
        let startTime = this.formatAMPM(
          result[i].date,
          fromHH,
          fromMM,
          FromAMPM
        );
        let endTime = this.formatAMPM(result[i].date, ToHH, ToMM, ToAMPM);
        if (
          fromHH !== "0" &&
          fromHH != null &&
          fromMM !== "0" &&
          fromMM != null &&
          FromAMPM !== "0" &&
          FromAMPM != null &&
          ToHH !== "0" &&
          ToHH != null &&
          ToMM !== "0" &&
          ToMM != null &&
          ToAMPM !== "0" &&
          ToAMPM != null
        ) {
          timeSlotArrayOne.push({
            apply:
              $("#tableRow" + index + "_" + j + " " + "#apply" + index).prop(
                "checked"
              ) == true
                ? true
                : false,
            scheduleStartTime: new Date(startTime).getTime(),
            scheduleEndTime: new Date(endTime).getTime(),
            thresholdLimit: result[i].dateFull,
            dayId:
              moment(result[i].dateFull, "YYYY-MM-DD").weekday() === 0
                ? 6
                : moment(result[i].dateFull, "YYYY-MM-DD").weekday() - 1,
          });
        }
      }
    }
    // console.log("timeSlotArrayOne", timeSlotArrayOne);
    timeSlotArray = timeSlotArrayOne;
  };

  submitSchedule = () => {
    this.saveHandle();
    // debugger;

    if (timeSlotArray.length > 0) {
      let validationFlag = true;
      for (let i = 0; i < timeSlotArray.length; i++) {
        if (
          timeSlotArray[i].scheduleStartTime > timeSlotArray[i].scheduleEndTime
        ) {
          alert("Please Enter From and To Time");
          validationFlag = false;
          break;
        } else {
          validationFlag = true;
        }
      }
      if (validationFlag == true) {
        for (let i = 0; i < timeSlotArray.length; i++) {
          // console.log(i, "value of i");
          for (let j = i + 1; j < timeSlotArray.length; j++) {
            // console.log(j, "value of j");
            if (timeSlotArray[i].dayId === timeSlotArray[j].dayId) {
              if (
                (timeSlotArray[i].scheduleStartTime > timeSlotArray[j].scheduleStartTime &&
                timeSlotArray[i].scheduleStartTime < timeSlotArray[j].scheduleEndTime) || 
                (timeSlotArray[j].scheduleStartTime > timeSlotArray[i].scheduleStartTime &&
                  timeSlotArray[j].scheduleStartTime < timeSlotArray[i].scheduleEndTime)
              ) {
                alert("Please select appropriate time for schedules");
                return false;
              }
            }
          }
        }
        $(".loader").show();

        const apiJson = {
          method: "POST",
          body: JSON.stringify({
            functionName: "saveDoctorVideoAudioSchedule",
            doctorId: scheduledData.doctorId,
            hospitalId: this.props.match.params.hospitalId,
            scheduleList: timeSlotArray,
          }),
        };
        // console.log(
        //   "🚀 ~ file: hospitalDoctorSchedule.jsx:672 ~ Qualification ~ apiJson:",
        //   apiJson.body
        // );
        // return false
        apiCalling(apiJson).then((data) => {
          if (data.success === "1") {
            $("#popup1").hide();
            $(".popOverlay").css("background-color", "transparent");
            this.getHospitalSpecialityList();
            this.setState({ doctorScheduleDataNew: [], addNewDiv: [] });
            timeSlotArray = [];
            scheduledData = [];
            // console.log(this.state.isScheduleCreated);
            if (this.state.isScheduleCreated === 0) {
              alert("Doctor Schedule Saved Successfully.");
            } else {
              alert("Doctor Schedule Updated Successfully.");
            }
            $(".table-doctrworkListBorder .doctroffceInput").val("0");
            $(".table-doctrworkListBorder .doctroffceInputCheck").prop(
              "checked",
              false
            );
            this.setState({clickedIndexes:[]});
          }
          if (data.success === "0") {
            $(".loader").hide();
            // this.setState({doctorScheduleDataNew:[], addNewDiv:[]})
            // timeSlotArray = []
            // scheduledData = []
            alert(data.errorMessage);

          }
        });
      }
    } else {
      alert("Please enter correct To Time");
    }
  };

  handleTimeChange = (event, index, name) => {
    //this.dataPush(index)
  };
  editScheduleData = (data) => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDoctorVideoAudioSchedule",
        hospitalId: this.props.match.params.hospitalId,
        doctorId: data.doctorId,
      }),
    };
    // console.log(
    //   "🚀 ~ file: hospitalDoctorSchedule.jsx:730 ~ Qualification ~ apiJson:",
    //   apiJson.body
    // );
    apiCalling(apiJson).then((data) => {
      // console.log(
      //   "🚀 ~ file: hospitalDoctorSchedule.jsx:721 ~ Qualification ~ apiCalling ~ data:",
      //   data
      // );
      if (data.success === "1") {
        // console.log("vang",)
        let dataSet = [];
        let dataSetData = [];
        if (data.result.doctorScheduleData.length > 0) {
          for (i = 0; i < result.length; i++) {
            dataSet = data.result.doctorScheduleData
              .filter((date) => {
                if (date.applyAll == false) {
                  return (
                    result[i].date ==
                    moment(date.fromTime).format("DD-MMM-YYYY")
                  );
                } else {
                  return result[i].day == date.scheduleDayName.slice(0, 3);
                }
              })
              .map((item) => item);
            dataSetData.push({
              date: result[i].date,
              day: result[i].day,
              dateMilli: result[i].dateMilli,
              dataSet,
            });
          }
          this.setState({
            doctorScheduleDataNew: dataSetData,
          });
          dataSetData = [];
        } else {
          for (i = 0; i < result.length; i++) {
            dataSetData.push({
              date: result[i].date,
              day: result[i].day,
              dateMilli: result[i].dateMilli,
              dataSet,
            });
          }
          this.setState({
            doctorScheduleDataNew: dataSetData,
          });
          dataSetData = [];
        }
        $(".loader").hide();
      }
      if (data.success === "0") {
        this.setState({
          doctorScheduleDataNew: [],
        });
        $(".loader").hide();
        //alert(data.errorMessage)
      }
    });
  };
  editSchedule = (data) => {
    this.setState({ isScheduleCreated: 1 });
    $(".loader").show();
    $("#popup1").show();
    $(".popOverlay").css("background-color", "rgba(0, 0, 0, 0.6)");
    this.setState({
      doctorName: data.doctorName,
      doctorSpeciality: data.speciality,
    });
    scheduledData = data;
    // console.log("data......", data);
    this.editScheduleData(data);
  };
  submitScheduleCancel() {}
  addNewTemplate(index, listItem, dataSetLength) {
    // console.log("listItem",listItem)
    return (
      <div
        className={"table_inside tableRow" + index + "_" + dataSetLength}
        id={"tableRow" + index + "_" + dataSetLength}
      >
        <div className="tablehead-hspmng col-md-5">
          <div className="doctroffcehrDayRow">
            <div className="doctroffcehrDay">
              <select
                className="doctroffceInput"
                id={"Hours" + index}
                name={"Hours" + index}
                onChange={() => this.handleTimeChange(index, "Hours")}
              >
                <option value="0">HH</option>
                {(() => {
                  const options = [];
                  for (let i = 1; i <= 12; i++) {
                    options.push(<option value={i}>{i}</option>);
                  }
                  return options;
                })()}
              </select>
            </div>
            <div className="doctroffcehrDay">
              <select
                className="doctroffceInput"
                id={"Minute" + index}
                name={"Minute" + index}
                onChange={() => this.handleTimeChange(index, "Minute")}
              >
                <option value="0">MM</option>
                <option value="00">00</option>
                <option value="30">30</option>
              </select>
            </div>
            <div className="doctroffcehrDay">
              <select
                className="doctroffceInput"
                id={"AmPm" + index}
                name={"AmPm" + index}
                onChange={() => this.handleTimeChange(index, "AmPm")}
              >
                <option value="0">AM/PM</option>
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </div>
          </div>
        </div>
        <div className="tablehead-hspmng col-md-4">
          <div className="doctroffcehrDayRow">
            <div className="doctroffcehrDay">
              <select
                className="doctroffceInput"
                id={"ToHours" + index}
                name={"ToHours" + index}
                onChange={() => this.handleTimeChange(index, "ToHours")}
              >
                <option value="0">HH</option>
                {(() => {
                  const options = [];
                  for (let i = 1; i <= 12; i++) {
                    options.push(<option value={i}>{i}</option>);
                  }
                  return options;
                })()}
              </select>
            </div>
            <div className="doctroffcehrDay">
              <select
                className="doctroffceInput"
                id={"ToMinute" + index}
                name={"ToMinute" + index}
                onChange={() => this.handleTimeChange(index, "ToMinute")}
              >
                <option value="0">MM</option>
                <option value="00">00</option>
                <option value="30">30</option>
              </select>
            </div>
            <div className="doctroffcehrDay">
              <select
                className="doctroffceInput"
                id={"ToAmPm" + index}
                name={"ToAmPm" + index}
                onChange={() => this.handleTimeChange(index, "ToAmPm")}
              >
                <option value="0">AM/PM</option>
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </div>
          </div>
        </div>
        <div className="tablehead-hspmng textCenter col-md-1">
          <div className="doctrcheckboxDivhsp">
            <input
              className="doctroffceInputCheck"
              type="checkbox"
              id={"apply" + index}
              name="appType"
              //onChange={this.handleInputChange}
            />
            <label className="pt-0"></label>
          </div>
        </div>
        <div className="tablehead-hspmng col-md-2">
          <div className="textCenter">
            <span className="mr-2 editbtn-qual">
              <img
                src={deactvate}
                alt=""
                width="18"
                className="img-fluid"
                onClick={() =>
                  this.deleteTimeSchedule(
                    index,
                    listItem,
                    dataSetLength,
                    "NewDelete",
                    ""
                  )
                }
              />
            </span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header /> <div className="loader"></div>
          </section>
        </Suspense>

        <div className="container mb-5">
          <div>
            <ul className="breadcrum-adminMangmnt">
              <li>
                <a href="/admin/dashboard">Dashboard</a>
              </li>
              <li>
                <a href="/adminManagement/hospitalManagementList/hospitalManagementList">
                  Hospital Management
                </a>
              </li>
              <li>
                <a href="" className="current">
                  Doctor Video/Audio Schedule
                </a>
              </li>
            </ul>
          </div>

          <div className="DoctorworkListTable">
            {this.state.specialityList.length > 0 ? (
              <div class="table-responsive">
                <table className="table table-bordered-hsp table-doctrworkList">
                  <thead>
                    <tr>
                      <th className="tablehead-hspmng">Doctor Name</th>
                      <th className="tablehead-hspmng">Speciality </th>
                      <th className="tablehead-hspmng">Video Chat</th>
                      <th className="tablehead-hspmng">Charge/Session</th>
                      <th className="tablehead-hspmng">
                        Avg Session time (Minute)
                      </th>
                      <th className="tablehead-hspmng">Audio Chat</th>
                      <th className="tablehead-hspmng">Audio Chat Price</th>
                      <th className="tablehead-hspmng">Schedule</th>
                      <th className="tablehead-hspmng">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.specialityList.map((data, index) => {
                      return (
                        <tr>
                          <td>{data.doctorName}</td>
                          <td>{data.speciality}</td>
                          <td className="textCenter">
                            <div className="doctrcheckboxDivhsp">
                              <input
                                type="checkbox"
                                id={"videoType" + index}
                                name="appType"
                                onChange={(e) =>
                                  this.handleVideoChange(e, data, index)
                                }
                                defaultChecked={data.hasVideoChat}
                                defaultValue={data.hasVideoChat}
                              />
                              <label className="pt-0"></label>
                            </div>
                          </td>
                          <td>
                            <input
                              type="text"
                              className="hspInfofieldinput hspInfofieldinputSmall"
                              defaultValue={data.ratePerSession}
                              onChange={(e) =>
                                this.handleInputChange(e, data, index)
                              }
                              id={"ratePerSession" + index}
                              disabled={
                                data.hasVideoChat == true ? false : true
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="hspInfofieldinput hspInfofieldinputSmall"
                              defaultValue={data.averageSessionTime}
                              onChange={(e) =>
                                this.handleInputChange(e, data, index)
                              }
                              id={"averageSessionTime" + index}
                            />
                          </td>
                          <td className="textCenter">
                            <div className="doctrcheckboxDivhsp">
                              <input
                                type="checkbox"
                                id={"audioType" + index}
                                name="appType"
                                onChange={(e) =>
                                  this.handleAudioChange(e, data, index)
                                }
                                defaultChecked={data.hasAudioChat}
                                defaultValue={data.hasAudioChat}
                              />
                              <label className="pt-0"></label>
                            </div>
                          </td>
                          <td>
                            <input
                              type="text"
                              className="hspInfofieldinput hspInfofieldinputSmall"
                              defaultValue={data.ratePerSessionAudio}
                              onChange={(e) =>
                                this.handleInputChange(e, data, index)
                              }
                              id={"ratePerAudioSession" + index}
                              disabled={
                                data.hasAudioChat == true ? false : true
                              }
                            />
                          </td>
                          <td>
                            {data.isScheduleCreated === 0 ? (
                              <span
                                className="listLink-hspmangmnt"
                                onClick={() => this.createSchedule(data)}
                              >
                                Create Schedule
                              </span>
                            ) : (
                              <span
                                className="listLink-hspmangmnt"
                                onClick={() => this.editSchedule(data)}
                              >
                                Edit Schedule
                              </span>
                            )}
                          </td>
                          <td className="nowrap">
                            <div className="textCenter">
                              <span className="mr-2 editbtn-qual">
                                <img
                                  src={deactvate}
                                  alt=""
                                  width="18"
                                  className="img-fluid"
                                  onClick={() =>
                                    this.deleteSchedule(
                                      data.hospitalDoctorVideoScheduleId,
                                      data.doctorId
                                    )
                                  }
                                />
                              </span>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>
                        <select
                          className="hspInfofieldinput hspInfofieldinputSmall"
                          id="selectDoctor"
                          name="selectDoctor"
                          onChange={(e) => this.doctorChange(e)}
                        >
                          <option value="0">Select Doctor</option>
                          {this.state.notExistsDoctorData.map((item) => (
                            <option value={item.value + "_" + item.speciality}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>{this.state.docSpeciality}</td>
                      <td className="textCenter">
                        <div className="doctrcheckboxDivhsp">
                          <input
                            type="checkbox"
                            id={"videoType"}
                            name="videoType"
                            onChange={this.handleVideoAdd}
                          />
                          <label className="pt-0"></label>
                        </div>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="hspInfofieldinput hspInfofieldinputSmall"
                          id="ChargeSession"
                          disabled={this.state.VideoAdd}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="hspInfofieldinput hspInfofieldinputSmall"
                          id="averagePrice"
                        />
                      </td>
                      <td className="textCenter">
                        <div className="doctrcheckboxDivhsp">
                          <input
                            type="checkbox"
                            id={"audioType"}
                            name="audioType"
                            onChange={this.handleAudioAdd}
                          />
                          <label className="pt-0"></label>
                        </div>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="hspInfofieldinput hspInfofieldinputSmall"
                          id="AudioChatPrice"
                          disabled={this.state.AudioAdd}
                        />
                      </td>
                      <td></td>
                      <td className="nowrap">
                        <div className="textCenter">
                          <span
                            className="butnsave-qual addButtonSec  smssetng-svebtn d-block"
                            onClick={() => this.addScheduleNew()}
                          >
                            Add
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <div class="table-responsive">
                <table className="table table-bordered-hsp table-doctrworkList">
                  <thead>
                    <tr>
                      <th className="tablehead-hspmng">Doctor Name</th>
                      <th className="tablehead-hspmng">Speciality </th>
                      <th className="tablehead-hspmng">Video Chat</th>
                      <th className="tablehead-hspmng">Charge/Session</th>
                      <th className="tablehead-hspmng">
                        Avg Session time (Minute)
                      </th>
                      <th className="tablehead-hspmng">Audio Chat</th>
                      <th className="tablehead-hspmng">Audio Chat Price</th>
                      <th className="tablehead-hspmng">Schedule</th>
                      <th className="tablehead-hspmng">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <select
                          className="hspInfofieldinput hspInfofieldinputSmall"
                          id="selectDoctor"
                          name="selectDoctor"
                          onChange={(e) => this.doctorChange(e)}
                        >
                          <option value="0">Select Doctor</option>
                          {this.state.notExistsDoctorData.map((item) => (
                            <option value={item.value + "_" + item.speciality}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>{this.state.docSpeciality}</td>
                      <td className="textCenter">
                        <div className="doctrcheckboxDivhsp">
                          <input
                            type="checkbox"
                            id={"videoType"}
                            name="videoType"
                            //onChange={this.handleInputChange}
                          />
                          <label className="pt-0"></label>
                        </div>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="hspInfofieldinput hspInfofieldinputSmall"
                          id="ChargeSession"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="hspInfofieldinput hspInfofieldinputSmall"
                          id="averagePrice"
                        />
                      </td>
                      <td className="textCenter">
                        <div className="doctrcheckboxDivhsp">
                          <input
                            type="checkbox"
                            id={"audioType"}
                            name="audioType"
                            //onChange={this.handleInputChange}
                          />
                          <label className="pt-0"></label>
                        </div>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="hspInfofieldinput hspInfofieldinputSmall"
                          id="AudioChatPrice"
                        />
                      </td>
                      <td></td>
                      <td className="nowrap">
                        <div className="textCenter">
                          <span
                            className="butnsave-qual addButtonSec  smssetng-svebtn"
                            onClick={() => this.addScheduleNew()}
                          >
                            Add
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            {this.state.isChangeVisible == true ? (
              <div className="popupbutndiv">
                <button
                  className="butnsave-qual smssetng-svebtn"
                  id="addQualificationSave"
                  onClick={() => this.submitNewSchedule()}
                >
                  Save
                </button>
                <Link
                  to={{
                    pathname:
                      "/adminManagement/hospitalManagementList/hospitalManagementList",
                  }}
                >
                  <button className="butncancel-qual smssetng-cancelbtn">
                    Cancel
                  </button>
                </Link>
              </div>
            ) : null}
          </div>
        </div>
        <div className="popBack" id="popup1">
          <div className="popOverlay" onClick={this.category1PopClose}></div>
          <div className="customizationPop customizationPopLarge">
            <div className="popuphead-qualifctn popuphead-qualifctn-bottom">
              <h2>
                Create Schedule- {this.state.doctorName},{" "}
                {this.state.doctorSpeciality}
              </h2>
            </div>
            <span
              className="closepopupqual"
              type="button"
              onClick={() => this.closepopup()}
            >
              <img src={Close} alt="Close" className="img-fluid" width="25" />
            </span>
            <div className="popupdiv">
              <div class="table-responsive">
                <div className="table table-bordered-hsp table-doctrworkListBorder">
                  <div className="table table-bordered-Head">
                    <div className="tablehead-hspmng col-md-1">Date</div>
                    <div className="tablehead-hspmng col-md-1">Day</div>
                    <div className="tablehead-hspmng col-md-1">&nbsp;</div>
                    <div className="tablehead-hspmng col-md-9">
                      <div className="table_inside">
                        <div className="tablehead-hspmng col-md-5">From</div>
                        <div className="tablehead-hspmng col-md-4">To</div>
                        <div className="tablehead-hspmng col-md-1">
                          Apply All
                        </div>
                        <div className="tablehead-hspmng col-md-2">Actions</div>
                      </div>
                    </div>
                  </div>

                  <div className="table table-bordered-body">
                    {this.state.doctorScheduleDataNew.length > 0 ? (
                      <div>
                        {this.state.doctorScheduleDataNew.map((item, index) => {
                          let dayValue = item.day;
                          let listitem = index;
                          let parentIndex = index + dayValue;
                          index = index + dayValue;
                          return (
                            <div className="table table-bordered-row">
                              <div className="tablehead-hspmng col-md-1">
                                {item.date}
                              </div>
                              <div className="tablehead-hspmng col-md-1">
                                {item.day}
                              </div>
                              <div className="tablehead-hspmng col-md-1">
                                <div className="textCenter">
                                  {item.dataSet.length < 2 ? (
                                    <span className="mr-2 editbtn-qual">
                                      <img
                                        src={plus}
                                        alt=""
                                        id={"plusImage" + index}
                                        width="18"
                                        className="img-fluid"
                                        onClick={() =>
                                          this.scheduleEdit(
                                            index,
                                            listitem,
                                            item.dataSet.length,
                                            item.date
                                          )
                                        }
                                      />
                                    </span>
                                   ) : (
                                    <span className="mr-2 editbtn-qual">
                                    {(this.state.isSpanVisible && this.state.clickedIndexes.includes(listitem)) && (
                                      <img
                                        src={plus}
                                        alt=""
                                        id={"plusImage1" + index}
                                        width="18"
                                        className="img-fluid"
                                        onClick={() =>
                                          this.scheduleEdit(
                                            index,
                                            listitem,
                                            item.dataSet.length,
                                            item.date
                                          )
                                        }
                                      />
                                    )}
                                  </span>
                                    )}
                                </div>
                              </div>
                              <div className="tablehead-hspmng col-md-9">
                                <input
                                  type="text"
                                  id={"dataSet" + index}
                                  defaultValue={item.dataSet.length}
                                  style={{ display: "none" }}
                                />
                                {item.dataSet.length > 0 ? (
                                  <div className="table_insideInner">
                                    {item.dataSet.map((item, index) => {
                                      let hours = moment(item.fromTime).format(
                                        "h"
                                      );
                                      let minute = moment(item.fromTime).format(
                                        "mm"
                                      );
                                      let Am = moment(item.fromTime).format(
                                        "A"
                                      );
                                      let tohours = moment(item.toTime).format(
                                        "h"
                                      );
                                      let tominute = moment(item.toTime).format(
                                        "mm"
                                      );
                                      let toAm = moment(item.toTime).format(
                                        "A"
                                      );
                                      let index1 = index + 1;
                                      index1 = index1;
                                      return (
                                        <div
                                          className="table_inside"
                                          id={
                                            "tableRow" +
                                            parentIndex +
                                            "_" +
                                            index1
                                          }
                                        >
                                          <input
                                            type="hidden"
                                            id={"fromTime" + index}
                                            value={item.fromTime}
                                          />
                                          <div className="tablehead-hspmng col-md-5">
                                            <div className="doctroffcehrDayRow">
                                              <div className="doctroffcehrDay">
                                                <select
                                                  defaultValue={hours}
                                                  className="doctroffceInput"
                                                  id={"Hours" + parentIndex}
                                                  name={"Hours" + index}
                                                  onChange={(e) =>
                                                    this.handleTimeChange(
                                                      e,
                                                      index,
                                                      "Hours"
                                                    )
                                                  }
                                                >
                                                  <option value="0">HH</option>
                                                  {(() => {
                                                    const options = [];
                                                    for (
                                                      let i = 1;
                                                      i <= 12;
                                                      i++
                                                    ) {
                                                      options.push(
                                                        <option value={i}>
                                                          {i}
                                                        </option>
                                                      );
                                                    }
                                                    return options;
                                                  })()}
                                                </select>
                                              </div>
                                              <div className="doctroffcehrDay">
                                                <select
                                                  className="doctroffceInput"
                                                  defaultValue={minute}
                                                  id={"Minute" + parentIndex}
                                                  name={"Minute" + index}
                                                  onChange={(e) =>
                                                    this.handleTimeChange(
                                                      e,
                                                      index,
                                                      "Minute"
                                                    )
                                                  }
                                                >
                                                  <option value="0">MM</option>
                                                  <option value="00">00</option>
                                                  <option value="30">30</option>
                                                </select>
                                              </div>
                                              <div className="doctroffcehrDay">
                                                <select
                                                  className="doctroffceInput"
                                                  defaultValue={Am}
                                                  id={"AmPm" + parentIndex}
                                                  name={"AmPm" + index}
                                                  onChange={(e) =>
                                                    this.handleTimeChange(
                                                      e,
                                                      index,
                                                      "AmPm"
                                                    )
                                                  }
                                                >
                                                  <option value="0">
                                                    AM/PM
                                                  </option>
                                                  <option value="AM">AM</option>
                                                  <option value="PM">PM</option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="tablehead-hspmng col-md-4">
                                            <div className="doctroffcehrDayRow">
                                              <div className="doctroffcehrDay">
                                                <select
                                                  className="doctroffceInput"
                                                  defaultValue={tohours}
                                                  id={"ToHours" + parentIndex}
                                                  name={"ToHours" + index}
                                                  onChange={(e) =>
                                                    this.handleTimeChange(
                                                      e,
                                                      index,
                                                      "ToHours"
                                                    )
                                                  }
                                                >
                                                  <option value="0">HH</option>
                                                  {(() => {
                                                    const options = [];
                                                    for (
                                                      let i = 1;
                                                      i <= 12;
                                                      i++
                                                    ) {
                                                      options.push(
                                                        <option value={i}>
                                                          {i}
                                                        </option>
                                                      );
                                                    }
                                                    return options;
                                                  })()}
                                                </select>
                                              </div>
                                              <div className="doctroffcehrDay">
                                                <select
                                                  className="doctroffceInput"
                                                  defaultValue={tominute}
                                                  id={"ToMinute" + parentIndex}
                                                  name={"ToMinute" + index}
                                                  onChange={(e) =>
                                                    this.handleTimeChange(
                                                      e,
                                                      index,
                                                      "ToMinute"
                                                    )
                                                  }
                                                >
                                                  <option value="0">MM</option>
                                                  <option value="00">00</option>
                                                  <option value="30">30</option>
                                                </select>
                                              </div>
                                              <div className="doctroffcehrDay">
                                                <select
                                                  className="doctroffceInput"
                                                  defaultValue={toAm}
                                                  id={"ToAmPm" + parentIndex}
                                                  name={"ToAmPm" + index}
                                                  onChange={(e) =>
                                                    this.handleTimeChange(
                                                      e,
                                                      index,
                                                      "ToAmPm"
                                                    )
                                                  }
                                                >
                                                  <option value="0">
                                                    AM/PM
                                                  </option>
                                                  <option value="AM">AM</option>
                                                  <option value="PM">PM</option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="tablehead-hspmng textCenter col-md-1">
                                            <div className="doctrcheckboxDivhsp">
                                              <input
                                                className="doctroffceInputCheck"
                                                type="checkbox"
                                                id={"apply" + parentIndex}
                                                name="apply"
                                                onChange={(e) =>
                                                  this.handleCheckChange(
                                                    e,
                                                    index,
                                                    item.applyAll
                                                  )
                                                }
                                                defaultChecked={item.applyAll}
                                                defaultValue={item.applyAll}
                                              />
                                              <label className="pt-0"></label>
                                            </div>
                                          </div>
                                          <div className="tablehead-hspmng col-md-2">
                                            <div className="textCenter">
                                              <span className="mr-2 editbtn-qual">
                                                <img
                                                  src={deactvate}
                                                  alt=""
                                                  width="18"
                                                  className="img-fluid"
                                                  onClick={() =>
                                                    this.deleteTimeSchedule(
                                                      index1,
                                                      listitem,
                                                      parentIndex,
                                                      "ExistingDelete",
                                                      item
                                                    )
                                                  }
                                                />
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                    {this.state.addNewDiv
                                      .filter(
                                        (data) =>
                                          "tableRow" + index ==
                                          data[0].props.id.split("_")[0]
                                      )
                                      .map((item) => {
                                        return item;
                                      })}
                                  </div>
                                ) : (
                                  <div className="table_insideInner">
                                    {this.addNewTemplate(
                                      index,
                                      listitem,
                                      item.dataSet.length === 0
                                        ? 1
                                        : item.dataSet.length
                                    )}
                                    {this.state.addNewDiv
                                      .filter(
                                        (data) =>
                                          "tableRow" + index ==
                                          data[0].props.id.split("_")[0]
                                      )
                                      .map((item) => {
                                        return item;
                                      })}
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div>No Data Found</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="popupbutndiv">
                {this.state.isScheduleCreated === 0 ? (
                  <button
                    className="butnsave-qual smssetng-svebtn"
                    id="addQualificationSave"
                    onClick={() => this.submitSchedule()}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    className="butnsave-qual smssetng-svebtn"
                    id="UpdateDetails"
                    onClick={() => this.submitSchedule()}
                  >
                    Update
                  </button>
                )}
                <button
                  className="butncancel-qual smssetng-cancelbtn"
                  onClick={() => this.closepopup()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
