import React, { Component, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../css/style.css";
import "../css/purpleresponsive.css";
import renderHTML from "react-render-html";
import $ from "jquery";
import { apiUrlRelianceFunction, awsAPIUrlcommon } from "../apiService";
import "react-datepicker/dist/react-datepicker.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import SlotList from "../home/slotList"
import { Helmet } from "react-helmet";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
const inperson = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/theme/inperson.png";
const chatg = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/theme/chatg.png";
const phoneg = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/theme/phoneg.png";
const videog = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/theme/videog.png";
const info = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/theme/infosquare.png";
const speciality = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/theme/stethoscope.png";
const service = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/theme/checklist1.png";
const hospital = "https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/image/theme/hospitals.png";
export default class DoctorDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      professionalStatement: "",
      doctorBD: [],
      drSpecialization: [],
      hospitalDetails: [],
      drServices: [],
      appointmentSlotDay: [],
      hospitalImages: [],
      serviceOffered: [],
      Specialities: [],
      officeDetails: [],
      testing: "yyy",
      calFlag: "",
    };
  }

  isLoginCheck() {
    let flag = true;
    if (!window.sessionStorage.getItem("isLogin")) {
      $("#ForgotPasswordWrap").hide();
      $("#SignupWrap").hide();
      $("#signInWrapDiv").show();
      flag = false;
    }
    return flag;
  }

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
    $(".react-datepicker__time-container").show();
    $(".react-datepicker__navigation").hide();
    $(".timeClose").show();
  };
  addToFavourites(e) {
    if (this.isLoginCheck() === true) {

      if(e.target.checked){
        const jsonData = {
          method: "POST",
          body: JSON.stringify({
            functionName: "addToFavourites",
            siteId: window.sessionStorage.getItem("siteId"),
            userId: window.sessionStorage.getItem("userId"),
            favouriteUserId: window.sessionStorage.getItem("doctorPID"),
          }),
        };
        apiUrlRelianceFunction(jsonData).then((data) => {
          if (data.success === "1") {
            alert(data.successMessage);
          }
        });
        $("#fav").attr('disabled',true)
      }
      
    }
  }
  DrDetailLiked() {
    $("#dr-detail-liked .imgdislike").toggle();
    $("#dr-detail-liked .imgliked").toggle();
  }


  componentDidMount() {
    let slug = this.props.match.params.slug;
    slug = slug.split("-");
    let doctorId = slug[0];
    if (folderName === null) {
      window.sessionStorage.setItem("pathname", window.location.pathname)
      window.location.href = "/"
    }
    $(".loader").show();
    $(".generalFlag").hide();
    let self = this;
    window.sessionStorage.setItem("AppFlag", "NA");

    $("#callLink").hide();
    $("#chatLink").hide();
    $("#videoLink").hide();
    $("#emailLink").hide();
    $("#bookLink").hide();
    //let doctorId;

    // if (self.props.location.doctorId) {
    //   window.sessionStorage.setItem("doctorPID", self.props.location.doctorId);
    //   doctorId = self.props.location.doctorId;
    // } else {
    //   doctorId = window.sessionStorage.getItem("doctorPID");
    // }
    if (doctorId) {
      window.sessionStorage.setItem("doctorPID", doctorId);
      doctorId = doctorId;
    } else {
      doctorId = window.sessionStorage.getItem("doctorPID");
    }

    const doctorDetails = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorProfile",
        siteId: window.sessionStorage.getItem("siteId"),
        doctorId: doctorId,
      }),
    };
    // console.log(doctorDetails.body);
   apiUrlRelianceFunction(doctorDetails).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        this.setState({
          doctorBD: data.doctorData[0],
        });

        this.setState({
          officeDetails: data.doctorData[0].officeDetails,
        });
        this.setState({
          serviceOffered: data.doctorData[0].serviceOffered,
        });
        this.setState({
          Specialities: data.doctorData[0].specialization,
        });

        this.setState({
          hospitalDetails: data.doctorData[0].hospitalDetails,
        });
        this.setState({
          hospitalImages: data.doctorData[0].galleryImages,
        });

        // this.getCalenderData(data.doctorData[0].hospitalDetails[0].hospitalId);
        data.doctorData[0].specialization.map((sp) => {
          this.setState({
            drSpecialization: this.state.drSpecialization.concat(
              sp.specialization
            ),
          });
        });

        data.doctorData[0].services.map((sp) => {
          //alert(sp);
          if (sp === "Call a Doctor") {
            $("#callLink").show();
          }
          if (sp === "Messaging") {
            $("#chatLink").show();
          }
          if (sp === "Video Chat") {
            $("#videoLink").show();
          }
          if (sp === "Email Consult") {
            $("#emailLink").show();
          }
          if (sp === "Book Appointment") {
            $("#bookLink").show();
          }
        });
        // });
        // alert(data.doctorData[0].Favouriteflag);
        if (data.doctorData[0].Favouriteflag === "1") {
          $("#dr-detail-liked .imgdislike").toggle();
          $("#dr-detail-liked .imgliked").toggle();
        }
      } else {
        alert(data.errorMessage);
      }
    });
    // $("#dr-detail-liked .imgliked").hide();
    // $(".timeClose").on("click", function () {
    //   $(this).hide();
    //  $(".react-datepicker__time-container").hide();
    //   $(".react-datepicker__navigation").show();
    //  });
    // self.showService();
    if(this.props.location.doctorList){
      for(let i=0;i<this.props.location.doctorList.length;i++){
        if(this.props.location.doctorList[i].doctorId.toString()=== doctorId){
          $("#fav").attr("checked",true)
          break;
        }
      }
    }
  }
  componentWillMount() { }

  showService() {
    alert(this.state.hospitalDetails);
    this.state.hospitalDetails.map((hos) => {
      alert(hos.phoneNumber);
    });
  }

  isLoginValidate(doctorId, hospitalId, flagOption, bySlot, selDate, selTime) {
    //alert("inside" + hospitalId);
    if (this.isLoginCheck() === true) {
      if (doctorId) {
        // alert('ok')
        window.sessionStorage.setItem("loginValidate", "");
        window.sessionStorage.setItem("appDocId", doctorId);
        window.sessionStorage.setItem("appHosId", hospitalId);
        window.sessionStorage.setItem("flagOption", flagOption);
        window.sessionStorage.setItem("bySlot", bySlot);
        window.sessionStorage.setItem("selDate", selDate);
        window.sessionStorage.setItem("selTime", selTime);
        window.sessionStorage.setItem("doctor_details", "1");
        //alert();
        window.location.href = "/patient/bookappointment";
      } else if (window.sessionStorage.getItem("appDocId")) {
        window.sessionStorage.setItem("appHosId", hospitalId);
        window.sessionStorage.setItem("flagOption", flagOption);
        window.location.href = "/patient/bookappointment";
      } else {
      }
    } else {

      window.sessionStorage.setItem("loginValidate", "book");
      window.sessionStorage.setItem("appDocId", doctorId);
      window.sessionStorage.setItem("appHosId", hospitalId);
      window.sessionStorage.setItem("flagOption", flagOption);
      window.sessionStorage.setItem("bySlot", bySlot);
      window.sessionStorage.setItem("selDate", selDate);
      window.sessionStorage.setItem("selTime", selTime);
      window.sessionStorage.setItem("doctor_details", "1");
      $("#ForgotPasswordWrap").hide();
      $("#SignupWrap").hide();
      $("#signInWrapDiv").show();
    }
  }
  changeHospital() {
    let hosid = $("#hospitalDrop").val();
    $(".bookLink").hide();
    $(".videoLink").hide();
    $(".callLink").hide();
    $(".callLinkChat").hide();

    $("#bookLink" + hosid).show();
    $("#videoLink" + hosid).show();
    $("#callLink" + hosid).show();
    $("#callLinkChat" + hosid).show();
  }
  chatmessageClickValidate(
    doctorId,
    hospitalId,
    chatDoctorPanelId,
    textChatPrice
  ) {
    $(".loader").show();
    const jsonData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "messageChatFree",
        chatDoctorPanelId: chatDoctorPanelId,
        memberId: window.sessionStorage.getItem("userId"),
        doctorId: doctorId,
        hospitalId: hospitalId,
      }),
    };
   // console.log(jsonData.body);
   apiUrlRelianceFunction(jsonData).then((data) => {
      if (data.success === "1") {
        $(".loader").show();
        window.sessionStorage.setItem("API_KEY", data.API_KEY);
        window.sessionStorage.setItem("sessionId", data.sessionId);
        window.sessionStorage.setItem("token", data.token);
        window.sessionStorage.setItem("appDocId", doctorId);
        window.sessionStorage.setItem("appHosId", hospitalId);
        window.sessionStorage.setItem("chatDoctorPanelId", data.chatGroupId); //chatDoctorPanelId);
        window.location.href = "/chat/chatmessage";
      } else {
        // this.chatmessageClickValidate(
        //   doctorId,
        //   hospitalId,
        //   chatDoctorPanelId,
        //   textChatPrice
        // );
        alert(data.errorMessage);
      }
    });
  }
  chatmessageClick(doctorId, hospitalId, chatDoctorPanelId, textChatPrice) {
    if (this.isLoginCheck() === true) {
      this.chatmessageClickValidate(
        doctorId,
        hospitalId,
        chatDoctorPanelId,
        textChatPrice
      );
    } else {
      window.sessionStorage.setItem("loginValidate", "TextChatFree");
      window.sessionStorage.setItem("appDocId", doctorId);
      window.sessionStorage.setItem("appHosId", hospitalId);
      window.sessionStorage.setItem("chatDoctorPanelId", chatDoctorPanelId);
      $("#ForgotPasswordWrap").hide();
      $("#SignupWrap").hide();
      $("#signInWrapDiv").show();
    }
  }
  isLoginValidateTextChatWithPayment(
    doctorId,
    hospitalId,
    chatdoctorpanelid,
    messaging_cost
  ) {
    if (this.isLoginCheck() === true) {
      window.sessionStorage.setItem("loginValidate", "");
      // alert(doctorId);
      // alert(hospitalId);
      // alert(chatdoctorpanelid);
      // alert(messaging_cost);
      window.sessionStorage.setItem("appDocId", doctorId);
      window.sessionStorage.setItem("appHosId", hospitalId);
      window.sessionStorage.setItem("chatDoctorPanelId", chatdoctorpanelid);
      window.sessionStorage.setItem("messaging_cost", messaging_cost);
      window.location.href = "/patient/textchatpayment";
    } else {
      window.sessionStorage.setItem("loginValidate", "TextChatPay");
      window.sessionStorage.setItem("appDocId", doctorId);
      window.sessionStorage.setItem("appHosId", hospitalId);
      window.sessionStorage.setItem("chatDoctorPanelId", chatdoctorpanelid);
      window.sessionStorage.setItem("messaging_cost", messaging_cost);
      $("#ForgotPasswordWrap").hide();
      $("#SignupWrap").hide();
      $("#signInWrapDiv").show();
    }
  }
  render() {
    var dateSettings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4,
            swipeToSlide: true,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            swipeToSlide: true,
          },
        },
      ],
    };
    return (
      <div className="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <Helmet>
          <title>{this.props.match.params.slug}</title>
          <meta
            name="description"
            content={this.props.match.params.slug}
          />
        </Helmet>
        <div className="container">
          <div className="doctorDetailMain">
            <div className="doctorDetailProfile mt-5">
              <div className="doctorDetailLeft">
                <label className="drListHeart imgshowhide" >
                  <input id="fav" type="checkbox" onClick={(e) =>
                      this.addToFavourites(e)
                    }/>
                  <span
                    class="checkmark imgliked"
                  ></span>
                </label>
                <div className="doctorDetailProfileImg">
                  <img
                    src={this.state.doctorBD.image}
                    width="110"
                    height="110"
                    alt=""
                  ></img>
                </div>
                <h5 class="drName">
                  {this.state.doctorBD.firstName}{" "}
                  {this.state.doctorBD.lastName} {"   "}
                  {this.state.doctorBD.doctorEducation}
                </h5>
                <span class="drspecialist">
                  {this.state.doctorBD.primarySpeciality}
                </span>
                <p class="drspecialist-text mt-2 mb-0"> </p>
                {/* <p class="drspecialist-text mb-0">
                  {this.state.doctorBD.address}
                </p>
                <p class="drspecialist-text mb-0">{this.state.doctorBD.city}</p> */}
                {this.state.hospitalDetails.length !== 1 && (
                  <p className="mb-0">
                    <select
                      class="drspecialist-hospital"
                      onClick={(e) => this.changeHospital()}
                      id="hospitalDrop"
                    >
                      {this.state.hospitalDetails.map((hospial) => {
                        return (
                          <option value={hospial.hospitalId}>
                            {hospial.name}
                          </option>
                        );
                      })}
                    </select>
                  </p>
                )}
                {/* <span class="profileAvailable">
                  <img src={correct} width="17"></img>Available
                </span> */}
              </div>
              <div
                className="drServiceMain"
                id={"drServiceMain" + this.props.consti}
              >
                <div className="drService-subhead">SERVICES:</div>

                {this.state.hospitalDetails.map((hos, index) => {
                  let services = hos.hospitalServices;
                  //let priceDetails = hos.priceDetails;
                  // alert(services);
                  let bookShow = "none";
                  let vcShow = "none";
                  let auShow = "none";
                  let chat = "none";
                  let show = index === 0 ? "block" : "none";
                  services.map((it) => {
                    // alert(it.serviceName)
                    if (
                      it.serviceName === "Book Appointment" &&
                      it.status === 1
                    ) {
                      bookShow = "Book Appointment";
                    }
                    if (it.serviceName === "Video Chat" && it.status === 1) {
                      vcShow = "Video Chat";
                    }
                    if (it.serviceName === "Call a Doctor" && it.status === 1) {
                      auShow = "Call a Doctor";
                    }
                    if (it.serviceName === "Messaging" && it.status === 1) {
                      chat = "Messaging";
                    }
                  });
                  // alert(hos.priceDetails[0].textChatPrice);
                  return (
                    <div>
                      {bookShow === "Book Appointment" && (
                        <div
                          className="drServiceSp drServiceSp-book generalFlag bookLink"
                          id={"bookLink" + hos.hospitalId}
                          style={{ display: show }}
                        >
                          <span
                            className="text-decoration-none drServiceSpSpan"
                            return
                            onClick={(e) => {
                              this.isLoginValidate(
                                this.props.location.doctorId,
                                hos.hospitalId,
                                "p",
                                "No",
                                "",
                                ""
                              );
                            }}
                          >
                            <img src={inperson} alt=""></img> In-Person
                            <span className="drService-rate">
                              {hos.priceDetails[0].inPersonprice != "0.00" &&
                                "₹" + hos.priceDetails[0].inPersonprice}
                            </span>
                          </span>
                        </div>
                      )}
                      {vcShow === "Video Chat" && (
                        <div
                          className="drServiceSp drServiceSp-videochat generalFlag videoLink"
                          id={"videoLink" + hos.hospitalId}
                          style={{ display: show }}
                        >
                          <span
                            className="text-decoration-none drServiceSpSpan"
                            onClick={(e) => {
                              this.isLoginValidate(
                                this.props.location.doctorId,
                                hos.hospitalId,
                                "v",
                                "No",
                                "",
                                ""
                              );
                            }}
                          >
                            <img src={videog} alt=""></img> Video Chat
                            <span className="drService-rate">
                              ₹ {hos.priceDetails[0].videoChatPrice}
                            </span>
                          </span>
                        </div>
                      )}
                      {auShow === "Call a Doctor" && (
                        <div
                          className="drServiceSp drServiceSp-call generalFlag callLink"
                          id={"callLink" + hos.hospitalId}
                          style={{ display: show }}
                        >
                          <span
                            className="text-decoration-none drServiceSpSpan"
                            onClick={(e) => {
                              this.isLoginValidate(
                                this.props.location.doctorId,
                                hos.hospitalId,
                                "c",
                                "No",
                                "",
                                ""
                              );
                            }}
                          >
                            <img src={phoneg} alt=""></img> Audio Chat
                            <span className="drService-rate">
                              ₹ {hos.priceDetails[0].audioPrice}
                            </span>
                          </span>
                        </div>
                      )}
                      { chat === "Messaging" &&
                        <div className="drServiceSp drServiceSp-chat callLinkChat" 
                          id={"callLinkChat" + hos.hospitalId}
                          style={{ display: show }}
                        >
                          {hos.priceDetails[0].textChatPrice !== "0.00" ? (
                            <span
                              onClick={(e) => {
                                this.isLoginValidateTextChatWithPayment(
                                  this.props.location.doctorId,
                                  hos.hospitalId,
                                  hos.chatDoctorPanelId,
                                  hos.priceDetails[0].textChatPrice
                                );
                              }}
                              className="text-decoration-none drServiceSpSpan"
                            >
                              <img src={chatg} alt=""></img> Text Chat
                              <span className="drService-rate">
                                ₹{hos.priceDetails[0].textChatPrice}
                              </span>
                              <span className="expireDay">
                                {this.state.doctorBD.daysOfExpiry &&
                                  "Days of Expiry :" + this.state.doctorBD.daysOfExpiry}
                              </span>
                            </span>
                          ) : (
                            <span
                              onClick={() =>
                                this.chatmessageClick(
                                  this.props.location.doctorId,
                                  hos.hospitalId,
                                  hos.chatDoctorPanelId,
                                  hos.priceDetails[0].textChatPrice
                                )
                              }
                              className="text-decoration-none drServiceSpSpan"
                            >
                              <img src={chatg} alt=""></img> Text Chat
                              <span className="drService-rate">
                                {" "}
                        ₹{hos.priceDetails[0].textChatPrice}
                              </span>
                              <span className="expireDay">
                                {this.state.doctorBD.daysofexpiry &&
                                  "Days of Expiry : " +
                                  this.state.doctorBD.daysofexpiry}
                              </span>
                            </span>
                          )}
                        </div>}
                    </div>
                  );
                })}


              </div>
            </div>
            <div className="doctorDetailStatement mt-lg-5 mt-md-5 mt-sm-2">
              <h4 style={{ position: "relative" }}>
                Professional Statement
                <div class="iconUl icon-head-text">
                  <div id="iconOuterSocial">
                    {/*<span class="iconOuter">
                     <img src={upload}></img>
                    </span>*/}
                    {/*  <span class="iconSocial">
                      <img src={fb}></img>
                      <img src={insta}></img>
                      <img src={twitter}></img>
                      <img src={pintr}></img>
                    </span>*/}
                  </div>
                </div>
              </h4>
              <p>{this.state.doctorBD.professionalStatement}</p>
              {/*<ul class="iconUl">
                <li>
                  <span
                    class="iconOuter imgshowhide"
                    id="dr-detail-liked"
                    onClick={() => this.DrDetailLiked()}
                  >
                    <img
                      src={heartLine}
                      class="imgdislike"
                      onClick={() => this.addToFavourites("1")}
                    ></img>
                    <img
                      src={heartBg}
                      class="imgliked"
                      onClick={() => this.addToFavourites("0")}
                    ></img>
                  </span>
                </li>
                 <li id="iconOuterSocial">
                  <span class="iconOuter">
                    <img src={upload}></img>
                  </span>
                  <span class="iconSocial">
                    <img src={fb}></img>
                    <img src={insta}></img>
                    <img src={twitter}></img>
                    <img src={pintr}></img>
                  </span>
                </li> 
              </ul>*/}
            </div>
          </div>
          <div className="drDetailServicemain text-left">
            <span className="drDetailServiceSpan drDetailServiceSpan-active drDetSpanUnderline">
              <img src={info} class="" width="20"></img>
              <span className="">doctor info</span>
            </span>

            {/*   <span className="drDetailServiceSpan imgshowhide">
              <img src={comment} class="imgshow" width="22"></img>
              <img src={comment1} class="imghide" width="22"></img> comments
            </span>

            <span className="drDetailServiceSpan imgshowhide">
              <img src={blog} class="imgshow" width="22"></img>
              <img src={blog1} class="imghide" width="22"></img> health blog
            </span>
            <span className="drDetailServiceSpan imgshowhide">
              <img src={question} class="imgshow" width="22"></img>
              <img src={question1} class="imghide" width="22"></img> q & a
            </span>
            <a href="../patient/bookappointment" id="bookLink">
              <span className="drDetailServiceSpan imgshowhide">
                <img src={booking} class="imgshow" width="22"></img>
                <img src={booking1} class="imghide" width="22"></img> Book
              </span>
            </a>
            <a href="#" id="callLink">
              <span className="drDetailServiceSpan imgshowhide">
                <img src={phone} class="imgshow" width="22"></img>
                <img src={phone1} class="imghide" width="22"></img> call
              </span>
            </a>
            <a href="#" id="chatLink">
              <span className="drDetailServiceSpan imgshowhide">
                <img src={chat} class="imgshow" width="22"></img>
                <img src={chat1} class="imghide" width="22"></img> chat
              </span>
            </a>
            <a href="#" id="emailLink">
              <span className="drDetailServiceSpan imgshowhide">
                <img src={mail} class="imgshow" width="22"></img>
                <img src={mail1} class="imghide" width="22"></img> email
              </span>
            </a>
            <a href="#" id="videoLink">
              <span className="drDetailServiceSpan imgshowhide">
                <img src={video} class="imgshow" width="22"></img>
                <img src={video1} class="imghide" width="22"></img> video chat
              </span>
            </a>
           <span className="drDetailServiceSpan imgshowhide">
              <img src={info} class="imgshow" width="22"></img>
              <img src={info1} class="imghide" width="22"></img> doctor info
            </span>
            <span className="drDetailServiceSpan imgshowhide">
              <img src={speciality} class="imgshow" width="22"></img>
              <img src={speciality1} class="imghide" width="22"></img>{" "}
              specialities
            </span>
            <span className="drDetailServiceSpan imgshowhide">
              <img src={service} class="imgshow" width="22"></img>
              <img src={service1} class="imghide" width="22"></img> service
              offered
            </span>
            <span className="drDetailServiceSpan imgshowhide">
              <img src={hospital} class="imgshow" width="22"></img>
              <img src={hospital1} class="imghide" width="22"></img> office
              details
            </span>
            
            <span className="drDetailServiceSpan imgshowhide">
              <img src={blog} class="imgshow" width="22"></img>
              <img src={blog1} class="imghide" width="22"></img> health blog
            </span>
            <span className="drDetailServiceSpan imgshowhide">
              <img src={question} class="imgshow" width="22"></img>
              <img src={question1} class="imghide" width="22"></img> q & a
            </span>*/}
          </div>
          {this.state.hospitalDetails.map((hdata) => {
            let place = [hdata.city, hdata.state, hdata.country];
            var i = 0;
            // this.getCalenderData(hdata.hospitalId);

            return (
              <div class="drDetailHospital row drDetailHospitalPadding mx-0">
                <div class="col-lg-6 drDetHosptl">
                  <div class="col-lg-5 drDetailHospitalImg">
                    <img src={hdata.hospitalImage}></img>
                  </div>

                  <div class="col-lg-7 drDetailHospitalDet drDetailHospitalContent">
                    <h5 class="drDetailHospitalHead drDetailHospitalTitle">
                      {hdata.name}
                    </h5>
                    <p>{hdata.address}</p>
                    <p>{place.join(", ")}</p>

                    <p>{hdata.zip === "" ? "" : "ZIP - " + hdata.zip}</p>
                    <p>Phone - {hdata.phoneNumber}</p>
                  </div>
                </div>
                <div class="col-lg-6 calendarDiv">
                  <SlotList hospialId={hdata.hospitalId} doctorId={window.sessionStorage.getItem("doctorPID")}></SlotList>
                  {/*<span class="timeClose">
                    <img src={close} width="20"></img>
                  </span>
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={this.handleChange}
                    showTimeSelect
                    timeIntervals={15}
                    timeCaption="Select the hour"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    inline
                  />*/}
                </div>
              </div>
            );
          })}
          {/* <div className="row text-center mx-0">
            {this.state.hospitalImages.map((img) => {
              return (
                <div className="drDetImgBtm">
                  <img src={img.imageName} className="img-fluid"></img>
                </div>
              );
            })}
          </div> */}

          <div
            className="drDetailServicemainTab my-4"
            style={{ float: "none" }}
          >
            <Tabs>
              <TabList>
                <Tab>
                  <img
                    src={speciality}
                    class="imgshow-act mr-2"
                    width="20"
                  ></img>
                  <span className="drDetSpanUnderline">specialities</span>
                </Tab>
                <Tab>
                  <img src={service} class="imgshow-act mr-2" width="20"></img>
                  <span className="drDetSpanUnderline">service offered</span>
                </Tab>
                <Tab>
                  <img src={hospital} class="imgshow-act mr-2" width="20"></img>
                  <span className="drDetSpanUnderline">office details</span>
                </Tab>
              </TabList>

              <TabPanel>
                <div class="form-head"> </div>
                {this.state.Specialities.map((s) => {
                  return (
                    <ul className="pl-3">
                      <li>{s.specialization ? s.specialization : ""}</li>
                    </ul>
                  );
                })}
                {this.state.Specialities.length === 0 && "No Data Available"}
              </TabPanel>
              <TabPanel>
                <div class="form-head"> </div>
                {this.state.serviceOffered.map((s) => {
                  return <div>{renderHTML(s.serviceOffered)}</div>;
                })}
                {this.state.serviceOffered.length === 0 && "No Data Available"}
              </TabPanel>
              <TabPanel>
                <div class="form-head"> </div>
                {this.state.officeDetails.map((s) => {
                  return <div>{renderHTML(s.officeDetails)}</div>;
                })}
                {this.state.officeDetails.length === 0 && "No Data Available"}
              </TabPanel>
            </Tabs>
          </div>
          {/* <div
            className="drDetailServicemain py-3 text-left"
            style={{ float: "none" }}
          >
            <a href="#">
              <span className="drDetailServiceSpan">
                <img src={speciality} class="imgshow-act" width="22"></img>
                <span className="drDetSpanUnderline">specialities</span>
              </span>
            </a>
            <a href="#">
              <span className="drDetailServiceSpan">
                <img src={service} class="imgshow-act" width="22"></img>
                <span className="drDetSpanUnderline">service offered</span>
              </span>
            </a>
            <a href="#">
              <span className="drDetailServiceSpan">
                <img src={hospital} class="imgshow-act" width="22"></img>
                <span className="drDetSpanUnderline">office details</span>
              </span>
            </a>
          </div> */}
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
